import { Box, Fade, styled, type BoxProps } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import CircularProgress from '../CircularProgress'

const AbsoluteBox = styled(Box)({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 10,
})

interface DisabledOverlayProps extends BoxProps {
  disabled: boolean
  /**
   * You need to ensure that when `showSpinner` is true `<DisabledOverlay />`
   * is inside a container with the `position` CSS attribute set or set it
   * on the component itself
   */
  showSpinner?: boolean
  innerBoxSx?: BoxProps['sx']
}

export const DisabledOverlay = ({
  disabled,
  children,
  showSpinner,
  innerBoxSx,
  ...props
}: DisabledOverlayProps) => (
  <Box {...props}>
    {showSpinner && (
      <Fade in={disabled}>
        <AbsoluteBox
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </AbsoluteBox>
      </Fade>
    )}
    <Box sx={mergeSx({ position: 'relative', minHeight: '100%' }, innerBoxSx)}>
      <Fade in={disabled}>
        <AbsoluteBox sx={{ background: 'rgba(0, 0, 0, 0.25)' }} />
      </Fade>
      {children}
    </Box>
  </Box>
)
