import { type TeamMember } from 'src/entities/team-member/types/teamMember'
import TeamMemberButton from './TeamMemberButton'

interface TeamMembersListProps {
  selectedTeamMember?: TeamMember | null
  members: TeamMember[]
  onSelect: (member: TeamMember) => void
}

const TeamMembersList = ({
  selectedTeamMember,
  members,
  onSelect,
}: TeamMembersListProps) => (
  <>
    {members.map(tm => (
      <TeamMemberButton
        key={tm.id}
        onClick={() => onSelect(tm)}
        teamMember={tm}
        showPin={selectedTeamMember?.id === tm.id}
      />
    ))}
  </>
)

export default TeamMembersList
