import type { TFunction } from 'i18next'

import type { ReservationGroup } from 'src/entities/reservation/services/reservationGroupFactory'
import type { TimeRangeInterface } from 'src/shared/lib/range/types/timeRange'

export const getGroupSerialString =
  (
    t: TFunction,
    formatDateRange: (range: TimeRangeInterface) => string,
    isSingleDay: (range: TimeRangeInterface) => boolean,
  ) =>
  (reservationGroup: ReservationGroup | undefined | null) => {
    if (!reservationGroup) return ''

    const dateRange = [
      reservationGroup.startDate,
      reservationGroup.endDate,
    ] as TimeRangeInterface
    const range = formatDateRange(dateRange)

    if (isSingleDay(dateRange)) return range

    const isDaily = reservationGroup.pattern.unit === 'daily'
    const isWeekly = reservationGroup.pattern.unit === 'weekly'
    const isMonthly = reservationGroup.pattern.unit === 'monthly'

    return (
      (isDaily &&
        t('reservation_drawer.serial_reservations.pattern.daily', {
          defaultValue: '{{ range }}, daily',
          range,
        })) ||
      (isWeekly &&
        t('reservation_drawer.serial_reservations.pattern.weekly', {
          defaultValue: '{{ range }}, weekly',
          range,
        })) ||
      (isMonthly &&
        t('reservation_drawer.serial_reservations.pattern.monthly', {
          defaultValue: '{{ range }}, monthly',
          range,
        }))
    )
  }
