import React from 'react'
import { complement, equals } from 'ramda'
import {
  Box,
  Collapse,
  collapseClasses,
  Stack,
  type BoxProps,
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import { TransitionGroup } from 'react-transition-group'

import { type PersistedLabelInterface } from 'src/entities/label/types/label'
import CollapsibleButton from 'src/shared/components/buttons/CollapsibleButton'
import { type UsePopover } from 'src/shared/components/dialogs/hooks/usePopover'
import PlusIcon from 'src/shared/components/icons/PlusIcon'
import SmallLabel from 'src/widgets/Label/SmallLabel'

const List = ({
  selectedLabels,
  onSelectedLabelsChange,
  popover,
  toggleButtonContainerProps,
}: {
  selectedLabels: PersistedLabelInterface[]
  onSelectedLabelsChange: (labels: PersistedLabelInterface[]) => void
  popover: UsePopover
  toggleButtonContainerProps?: BoxProps
}) => {
  const deselectHandler = React.useCallback(
    (label: PersistedLabelInterface) =>
      onSelectedLabelsChange(selectedLabels.filter(complement(equals(label)))),
    [onSelectedLabelsChange, selectedLabels],
  )

  const labelsListContainerRef = React.useRef<HTMLDivElement>(null)

  const { t } = useTranslation('', { keyPrefix: 'serial_reservations' })

  return (
    <Stack
      direction="row"
      spacing={0.5}
      ref={labelsListContainerRef}
      alignItems="center"
      sx={{
        overflow: 'hidden',
      }}
    >
      <TransitionGroup
        component={Stack}
        direction="row"
        sx={{
          overflow: 'hidden',
          flexWrap: 'wrap',
        }}
      >
        {selectedLabels.map(label => (
          <Collapse
            orientation="horizontal"
            key={label.id}
            sx={{
              flexShrink: 0,
              maxWidth: 1,
              [`.${collapseClasses.wrapperInner}`]: {
                overflow: 'hidden',
              },
            }}
          >
            <SmallLabel label={label} onDelete={() => deselectHandler(label)} />
          </Collapse>
        ))}
      </TransitionGroup>
      <Box {...toggleButtonContainerProps}>
        <CollapsibleButton
          onClick={() =>
            labelsListContainerRef.current &&
            popover.setAnchorEl(labelsListContainerRef.current)
          }
          collapsed={!!selectedLabels?.length}
          label={t('addLabel')}
          startIcon={<PlusIcon size="small" />}
        />
      </Box>
    </Stack>
  )
}

export default List
