import { type ButtonProps } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import TrashIcon from '../../icons/TrashIcon'
import IconButton from '../IconButton'

const TrashButton = (props: ButtonProps) => (
  <IconButton
    size="medium"
    variant="text"
    color="neutral"
    {...props}
    {...createTestIdProps('remove-button')}
  >
    <TrashIcon variant="filled" />
  </IconButton>
)

export default TrashButton
