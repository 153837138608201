import React, { forwardRef } from 'react'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  type RadioProps,
} from '@mui/material'
import { type FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel'
import { type RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup'

import { useTranslation, type WithTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { mergeSx } from 'src/app/theme/helpers'
import OutlinedList from '../../../common/OutlinedList'

interface RadioInputProps
  extends Omit<FormControlLabelProps, 'control' | 'size'> {
  size?: RadioProps['size']
}

export const RadioOption = forwardRef<HTMLInputElement, RadioInputProps>(
  ({ label, size = 'medium', sx, ...props }, ref) => (
    <FormControlLabel
      size={size}
      control={<Radio size={size} />}
      label={label}
      sx={mergeSx({ maxWidth: 'max-content' }, sx)}
      ref={ref}
      {...props}
    />
  ),
)

type RadioChoiceProps<T> = RadioGroupProps & {
  items: T[]
  label: React.ReactNode
  disabled: boolean
} & Pick<WithTranslation, 't'>

const RadioChoice = React.forwardRef<
  HTMLInputElement,
  RadioChoiceProps<string>
>(({ items, label, disabled = false, t, ...props }, ref) => {
  const { t: commonT } = useTranslation('', { keyPrefix: 'common' })

  return (
    <OutlinedList label={label} disabled={disabled}>
      <RadioGroup ref={ref} {...props} sx={{ gap: 2 }}>
        {items.map(item => (
          <RadioOption
            value={item}
            label={t(item)}
            key={item}
            disabled={disabled}
            size="small"
            {...createTestIdProps(`radio-${item}`)}
          />
        ))}
        <RadioOption
          sx={{ mr: 0 }}
          value=""
          label={commonT('radio_button.none')}
          disabled={disabled}
          size="small"
          {...createTestIdProps(`radio-none`)}
        />
      </RadioGroup>
    </OutlinedList>
  )
})

export default RadioChoice
