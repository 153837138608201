import type React from 'react'
import { Stack, Typography } from '@mui/material'
import {
  dayCalendarClasses,
  pickersSlideTransitionClasses,
} from '@mui/x-date-pickers'
import {
  DayCalendar,
  type DayCalendarProps,
} from '@mui/x-date-pickers/internals'

import dayjs, { type Dayjs } from 'dayjs'

import { getRestaurantTimeZone } from 'src/shared/lib/range/services/date'

const MonthPane = ({
  leftIcon,
  rightIcon,
  ...props
}: Partial<DayCalendarProps<Dayjs>> &
  Pick<
    DayCalendarProps<Dayjs>,
    | 'selectedDays'
    | 'onSelectedDaysChange'
    | 'currentMonth'
    | 'isMonthSwitchingAnimating'
    | 'onMonthSwitchingAnimationEnd'
    | 'slideDirection'
    | 'slots'
  > & {
    leftIcon?: React.ReactNode
    rightIcon?: React.ReactNode
  }) => (
  <Stack
    direction="column"
    sx={{
      [`.${dayCalendarClasses.weekDayLabel}`]: {
        m: 0,
        width: 32,
        height: 16,
        typography: 'labelExtrasmall',
        color: 'grey.300',
      },
      [`.${dayCalendarClasses.weekContainer}`]: {
        m: 0,
      },
      [`.${pickersSlideTransitionClasses.root}`]: {
        minHeight: 192,
      },
    }}
    spacing={2}
  >
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      {leftIcon}
      <Typography variant="labelRegular">
        {props.currentMonth.format('MMMM YYYY')}
      </Typography>
      {rightIcon}
    </Stack>
    <DayCalendar
      focusedDay={null}
      onFocusedDayChange={() => {}}
      reduceAnimations={false}
      disablePast={false}
      disableFuture={false}
      maxDate={dayjs('2099-12-31')}
      minDate={dayjs('1990-01-01')}
      timezone={getRestaurantTimeZone()}
      {...props}
    />
  </Stack>
)

export default MonthPane
