import { Button, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import IconButton from '../../../buttons/IconButton'

export const Header = ({
  title,
  onClose,
  onReset,
  closeIcon,
}: {
  title: string | undefined
  onClose: React.ReactEventHandler
  onReset: (() => void) | undefined
  closeIcon: React.ReactNode
}) => {
  const { t } = useTranslation()

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        p: 1,
        background: 'white',
        position: 'sticky',
        zIndex: 1,
        top: 0,
      }}
    >
      <Stack direction="row" alignItems="center">
        <IconButton
          size="medium"
          onClick={onClose}
          variant="text"
          color="neutral"
        >
          {closeIcon}
        </IconButton>
        {title && <Typography variant="labelBig">{title}</Typography>}
      </Stack>
      {onReset && (
        <Button variant="text" color="primary" onClick={onReset}>
          {t('filters.reset', {
            tDescription: 'Mobile view filters reset text button label',
            defaultValue: 'Clear filters',
          })}
        </Button>
      )}
    </Stack>
  )
}
