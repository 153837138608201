import { useTranslation } from 'react-i18next'

import Toggle from 'src/shared/components/form/inputs/Toggle'
import { useBaseFormContext } from '../../../../hooks/form'

const PacingToggle = () => {
  const { t } = useTranslation()

  const { register, watch } = useBaseFormContext()

  const hasArrivalTimes = Boolean(
    watch('firstArrivalTime') && watch('lastArrivalTime'),
  )

  return (
    <Toggle
      {...register('customPacing.isActive')}
      disabled={!hasArrivalTimes}
      label={t(
        'schedule.shifts.guests_flow_section.custom_pacing_toggle_label',
        'Set custom pacing',
      )}
    />
  )
}

export default PacingToggle
