import { compose, descend, head, map, mean, prop, propEq, sort } from 'ramda'

import { type FeedbackInterface } from 'src/entities/feedback/types/feedbackApi'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { getDifference } from 'src/shared/lib/range/services/date'

export const calculateMeanRating = compose(
  mean,
  map<FeedbackInterface, FeedbackInterface['rating']>(prop('rating')),
)

export const getRecentFeedback = compose(
  head<FeedbackInterface>,
  sort<FeedbackInterface>(descend(prop('date'))),
)

export const isForReservation: (
  reservation: ReservationInterface,
) => (feedback: FeedbackInterface) => boolean = reservation =>
  propEq(reservation.feedbackId, 'id')

const getMonthlyDifference = (dateA: Date) => {
  const getDiff = getDifference('month')
  return (dateB: Date) => Math.abs(getDiff(dateA, dateB))
}

export const isRecent = (date: Date) => {
  const getDiff = getMonthlyDifference(date)

  return (feedback: FeedbackInterface) => getDiff(feedback.date) < 1
}
