import React from 'react'

import { atom, useAtomValue, useSetAtom } from 'jotai'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import type { ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useServiceTimesQuery } from 'src/entities/service-time/queries/serviceTime'
import {
  getReservationServiceTimes,
  intersectsServiceTime,
} from 'src/pages/Reservations/service/reservation'

const idAtom = atom<ServiceTimeInterface['id'] | null>(null)

export const serviceTimeAtom = atom(
  get => get(idAtom),
  (_get, set, serviceTime: ServiceTimeInterface | null) =>
    set(idAtom, serviceTime?.id ?? null),
)

export const useSetSelectedServiceTime = () => useSetAtom(serviceTimeAtom)

export const getReservationServiceTime =
  (
    serviceTimes: ServiceTimeInterface[],
    currentServiceTimeId: ServiceTimeInterface['id'] | null,
  ) =>
  (reservation: ReservationInterface, reservationIsSelected: boolean) => {
    const currentSt =
      serviceTimes.find(st => st.id === currentServiceTimeId) ?? null

    if (!reservationIsSelected || !currentSt) return currentSt

    const currentStContainsReservation =
      intersectsServiceTime(reservation)(currentSt)

    if (currentStContainsReservation) return currentSt

    const reservationSt =
      getReservationServiceTimes(serviceTimes)(reservation)[0]

    return reservationSt ?? currentSt
  }

export const useSelectedServiceTime = () => {
  const { data: serviceTimes } = useServiceTimesQuery()
  const currentStId = useAtomValue(idAtom)

  return React.useMemo(
    () => serviceTimes.find(st => st.id === currentStId) ?? null,
    [serviceTimes, currentStId],
  )
}
