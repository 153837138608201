import React, { type Ref } from 'react'
import {
  Stack,
  Typography,
  type PaletteOptions,
  type SxProps,
} from '@mui/material'

import CloseIcon from '../../../../icons/CloseIcon'
import ItemContainer from '../ItemContainer'

const Item = (
  {
    children,
    onRemove,
    disabled = false,
    sx,
    palette = 'primaryPalette',
  }: {
    children: React.ReactNode
    onRemove?: () => void
    disabled?: boolean
    sx?: SxProps
    palette?: keyof PaletteOptions
  },
  ref: Ref<HTMLDivElement>,
) => (
  <ItemContainer ref={ref} sx={sx} disabled={disabled} palette={palette}>
    <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
      <Typography>{children}</Typography>
      {onRemove && (
        <CloseIcon
          size="small"
          sx={{ cursor: 'pointer' }}
          onClick={onRemove}
          disabled={disabled}
        />
      )}
    </Stack>
  </ItemContainer>
)

export default React.forwardRef(Item)
