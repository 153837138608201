import { type PropsWithChildren, type ReactNode } from 'react'
import {
  Box,
  Button,
  Stack,
  Typography,
  type ButtonProps,
  type TypographyTypeMap,
} from '@mui/material'
import type { OverrideProps } from '@mui/material/OverridableComponent'

import { useTranslation } from 'react-i18next'

import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import { type IconProps } from 'src/shared/components/icons/IconContainer'
import PencilIcon from 'src/shared/components/icons/PencilIcon'
import PlusIcon from 'src/shared/components/icons/PlusIcon'
import TrashIcon from 'src/shared/components/icons/TrashIcon'
import { type RangeInterface } from 'src/shared/lib/range/types/range'
import { useExceptionDateRangeFormatter } from '../service'

interface ScheduleCategoryWrapperProps {
  title: string
  description?: string
  enabled?: boolean
  onSwitch?: () => void
  children: ReactNode
}

export const ScheduleGrid = ({ children }: PropsWithChildren) => (
  <Box
    sx={theme => ({
      display: 'grid',
      gap: 3,
      ...theme.typography.body2,
      fontSize: '.75rem',
      lineHeight: '1.0625rem',
      gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
    })}
  >
    {children}
  </Box>
)

export const ScheduleColumn = ({ children }: PropsWithChildren) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
    {children}
  </Box>
)

export const ScheduleCategoryWrapper = ({
  children,
  title,
  enabled,
  onSwitch,
  description,
}: ScheduleCategoryWrapperProps) => {
  const toggleable = typeof enabled === 'boolean'
  const isToggleableFromHere = !!onSwitch

  const showSection = !toggleable || enabled || isToggleableFromHere

  if (!showSection) return null

  const showChildren = !toggleable || enabled

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={description ? 3 : 2}
      >
        <Typography
          component="h3"
          sx={{
            fontSize: '0.9375rem',
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
        {toggleable && onSwitch && (
          <Toggle label={null} checked={enabled} onChange={onSwitch} />
        )}
      </Stack>
      {description && <Typography mb={2}>{description}</Typography>}
      {showChildren && children}
    </Box>
  )
}

export const CreateScheduleButton = (props: ButtonProps) => (
  <Button
    variant="contained"
    size="small"
    color="neutral"
    startIcon={<PlusIcon size="small" />}
    sx={{
      mt: 1,
      bgcolor: '#546068',
      borderRadius: '4px',
      fontWeight: 500,
      lineHeight: 1,
      fontSize: '.875rem',
    }}
    fullWidth
    {...props}
  />
)

type TextLinkProps = TypographyTypeMap['props'] &
  OverrideProps<TypographyTypeMap, 'a'>

const TextLink = (props: TextLinkProps) => (
  <Typography
    component="a"
    sx={{
      display: 'flex',
      maxWidth: 'max-content',
      alignItems: 'center',
      color: 'inherit',
      textDecoration: 'underline',
      ':hover': {
        textDecoration: 'underline',
      },
    }}
    {...props}
  />
)

export const CreateScheduleExceptionButton = ({
  onClick,
  ...rest
}: Omit<TextLinkProps, 'onClick'> & { onClick: () => void }) => {
  const { t } = useTranslation()

  return (
    <TextLink
      href="#"
      onClick={e => {
        e.preventDefault()
        onClick()
      }}
      {...rest}
    >
      {t('schedule.overview.common.create_exception', '+ Create exception')}
    </TextLink>
  )
}

interface ScheduleCardActionsProps {
  onEdit: () => void
  onDelete?: () => void
}

export const ScheduleCardActions = ({
  onEdit,
  onDelete,
}: ScheduleCardActionsProps) => {
  const { t } = useTranslation()
  const { showSimpleDialog, simpleDialogProps } = useSimpleDialog()

  return (
    <Box sx={{ display: 'flex', gap: '.5rem', float: 'right' }}>
      <PencilIcon
        size="small"
        sx={{ cursor: 'pointer' }}
        onClick={onEdit}
        variant="filled"
      />
      {onDelete && (
        <TrashIcon
          size="small"
          sx={{ cursor: 'pointer' }}
          onClick={() => showSimpleDialog()}
          variant="filled"
        />
      )}
      <SimpleDialog
        color="error"
        title={t('common.caution', 'Caution')}
        message={t(
          'schedule.common.delete_item_disclaimer',
          'Are you sure you want to delete this item? This action cannot be reversed.',
        )}
        onConfirmation={onDelete}
        {...simpleDialogProps}
      />
    </Box>
  )
}

interface ScheduleExceptionItemProps {
  dateRange: RangeInterface<Date>
  icon?: React.FC<IconProps>
  onEdit?: () => void
  onDelete?: () => void
}

export const ScheduleExceptionItem = ({
  dateRange,
  icon: Icon,
  onEdit,
  onDelete,
}: ScheduleExceptionItemProps) => {
  const formatDateRange = useExceptionDateRangeFormatter()

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap={1}
      alignItems="center"
    >
      <Stack direction="row" gap={1}>
        {Icon && <Icon size="small" />}
        <Typography>{formatDateRange(dateRange)}</Typography>
      </Stack>
      {onEdit && <ScheduleCardActions onEdit={onEdit} onDelete={onDelete} />}
    </Stack>
  )
}
