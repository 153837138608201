import { Box } from '@mui/material'

const Label = ({ color }: { color: string }) => (
  <Box
    sx={{
      backgroundColor: color,
      borderRadius: 0.5,
      height: 5,
      width: 20,
      overflow: 'hidden',
      flexShrink: 0,
      boxSizing: 'border-box',
    }}
  />
)

export default Label
