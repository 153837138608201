import { type TFunction } from 'i18next'

import { ReservationActions } from 'src/pages/ReservationDrawer/Footer/reservationActions'
import {
  getNowInRestaurantTimezone,
  isInFuture,
  isInPast,
} from 'src/shared/lib/range/services/date'
import {
  type ReservationInterface,
  type ReservationPayment,
} from '../types/reservation'

export const getPaymentStatusText = (
  status: ReservationPayment['status'],
  t: TFunction,
) => {
  switch (status) {
    case 'pending':
      return t('payment_plan.status.pending', { defaultValue: 'Pending' })
    case 'expired':
      return t('payment_plan.status.expired', { defaultValue: 'Expired' })
    case 'provided':
      return t('payment_plan.status.provided', { defaultValue: 'Provided' })
    case 'charged':
      return t('payment_plan.status.provided', { defaultValue: 'Charged' })
    default:
      return ''
  }
}

export type PaymentActions =
  | ReservationActions.Cancel
  | ReservationActions.Noshow

export const isPaymentAction = (
  action: ReservationActions,
): action is PaymentActions =>
  action === ReservationActions.Cancel || action === ReservationActions.Noshow

export const couldBeCharged =
  (action: ReservationActions) => (reservation: ReservationInterface) => {
    if (!isPaymentAction(action)) return false

    if (
      action === ReservationActions.Cancel &&
      isInFuture('minute')(
        getNowInRestaurantTimezone(),
        reservation.cancellationDeadline,
      )
    )
      return false

    return reservation.payment?.status === 'provided'
  }

export const isExpired =
  (getNow: () => Date) => (payment: ReservationInterface['payment']) => {
    if (!payment) return false

    return (
      payment.status === 'expired' ||
      (!!payment.expirationDate &&
        isInPast('seconds')(getNow(), payment.expirationDate) &&
        payment.status === 'pending')
    )
  }
