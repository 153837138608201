import { useTranslation } from 'react-i18next'

import { DateRangeInput } from 'src/shared/components/form/inputs/DateRangeInput/DateRangeInput'
import { useExceptionEventFormController } from '../../../hooks/form'

interface PeriodProps {
  label: string
}

const Period = ({ label }: PeriodProps) => {
  const { t } = useTranslation()

  const {
    field,
    fieldState: { error },
  } = useExceptionEventFormController('effectivePeriod')

  return (
    <DateRangeInput
      inputRef={field.ref}
      label={label}
      minValue={new Date()}
      value={field.value}
      setValue={field.onChange}
      placeholder={t('schedule.common.pick_dates', 'Pick dates')}
      error={!!error}
      helperText={
        error &&
        (error.type === 'custom'
          ? t(
              error.message ??
                'schedule.exceptions.general_section.validation.effective_period_weekdays_no_overlap',
              'Selected dates don’t align with shift weekdays',
            )
          : t('common.validation.required', 'This field cannot be empty'))
      }
      sx={{ maxWidth: 360 }}
    />
  )
}

export default Period
