import { Box } from '@mui/material'

import {
  getColumnsWidth,
  getStartColumn,
  isClosed,
  isLocked,
  type TimelineShift,
} from '../../../../../../timelineShift'

interface ShiftItemProps {
  gridStart: Date
  shift: TimelineShift
}

const getShiftColor = (shift: TimelineShift) => {
  if (isLocked(shift))
    return {
      backgroundColor: 'errorPalette.100',
      borderColor: 'errorPalette.300',
      color: 'text.secondary',
    }

  if (isClosed(shift))
    return {
      backgroundColor: 'grey.100',
      borderColor: 'grey.200',
      color: 'text.quaternary',
    }

  return {
    backgroundColor: 'white',
    borderColor: 'grey.200',
    color: 'text.secondary',
  }
}

const ShiftItem = ({ gridStart, shift }: ShiftItemProps) => {
  const startColumn = getStartColumn({ getFirstHour: () => gridStart })(shift)
  const columnSpan = getColumnsWidth(shift)

  return (
    <Box
      sx={{
        gridColumn: `${startColumn} / span ${columnSpan}`,
        gridRow: 1,
        border: '1px solid',
        borderRadius: 2,
        pl: 1,
        py: 0.125,
        typography: 'labelExtrasmall',
        ...getShiftColor(shift),
      }}
    >
      {shift.occupiedPax}/{shift.totalPax}
    </Box>
  )
}

export default ShiftItem
