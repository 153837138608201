import { Alert, Button } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useGlobalModalContext } from '../../../lib/context/global/useGlobalModalContext'
import Modal from '../../common/Modal'

export interface PermissionDialogProps {
  message?: string
  onClose: () => void
  open: boolean
}

export const PermissionDialog = ({
  message,
  onClose,
  open,
}: PermissionDialogProps) => {
  const { t } = useTranslation()
  const { showTeamMembersModal } = useGlobalModalContext()

  return (
    <Modal
      title={t('permissions.access_denied', 'Access denied')}
      actions={
        <Button
          variant="outlined"
          color="neutral"
          size="large"
          sx={{ minWidth: 'fit-content' }}
          fullWidth
          onClick={() => {
            onClose()
            showTeamMembersModal()
          }}
        >
          {t(
            'permissions.pick_another_team_member',
            'Pick another team member',
          )}
        </Button>
      }
      onClose={onClose}
      open={open}
      showCloseButton
    >
      <Alert severity="error">{message}</Alert>
    </Modal>
  )
}
