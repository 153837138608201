import type React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  type TableProps,
} from '@mui/material'

import { flexRender, type HeaderGroup } from '@tanstack/react-table'
import { type VirtualItem } from '@tanstack/react-virtual'

export interface VirtualTableProps<T> extends TableProps {
  totalSize: number
  items: VirtualItem[]
  headers: HeaderGroup<T>[]
  children: React.ReactNode
}

const VirtualTable = <T,>({
  totalSize,
  items,
  headers,
  children,
  ...props
}: VirtualTableProps<T>) => {
  const paddingTop = items?.[0]?.start ?? 0
  const paddingBottom = totalSize - (items.at(-1)?.end ?? 0)

  return (
    <Table stickyHeader {...props}>
      <TableHead sx={{ borderColor: 'inherit' }}>
        {headers.map(headerGroup => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <TableCell
                key={header.id}
                colSpan={header.colSpan}
                sx={{
                  width: header.getSize() === 0 ? 'auto' : header.getSize(),
                  ...header.column.columnDef?.meta?.header?.sx,
                }}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody sx={{ borderColor: 'inherit' }}>
        {paddingTop > 0 && (
          <TableRow>
            <TableCell sx={{ height: paddingTop }} />
          </TableRow>
        )}
        {children}
        {paddingBottom > 0 && (
          <TableRow>
            <TableCell sx={{ height: paddingBottom }} />
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export default VirtualTable
