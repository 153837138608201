import React from 'react'
import { Box, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type PhoneCallNotificationInterface } from 'src/entities/notification/types/notification'
import DateFromNow from 'src/shared/components/common/DateFromNow'
import { formatPhoneNumber } from 'src/shared/lib/string/services/phoneNumber'
import Customer from './Customer'
import Loader from '../TodoNotification/Body/Loader'

const PhoneCallNotification = ({
  notification,
  onBodyClick,
}: {
  notification: PhoneCallNotificationInterface
  onBodyClick: (notification: PhoneCallNotificationInterface) => void
}) => {
  const { t } = useTranslation('', { keyPrefix: 'inbox' })

  return (
    <Stack
      sx={{
        flexGrow: 1,
        alignSelf: 'stretch',
        p: 1,
        cursor: 'pointer',
        flexDirection: 'column',
      }}
      onClick={() => onBodyClick(notification)}
    >
      <Typography
        variant="body1"
        fontWeight={500}
        sx={{ textTransform: 'capitalize' }}
      >
        {t('phoneCall')}
      </Typography>
      <Typography variant="body2">
        {formatPhoneNumber(notification.payload.number)}
      </Typography>
      <React.Suspense fallback={<Loader />}>
        <Customer phoneNumber={notification.payload.number} />
      </React.Suspense>
      <Box sx={{ alignSelf: 'flex-end' }}>
        <DateFromNow date={notification.date} />
      </Box>
    </Stack>
  )
}

export default PhoneCallNotification
