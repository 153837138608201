import React from 'react'

import { type Group } from 'konva/lib/Group'
import { type KonvaEventObject } from 'konva/lib/Node'

import { limitDrag } from 'src/entities/table/services/tableGrid'
import { useCanvasViewportContext } from 'src/shared/lib/context/state/CanvasViewportContext'
import FloorPlanElement, {
  type FloorPlanElementProps,
} from '../FloorPlanElement'

const TransformableElement = React.forwardRef<Group, FloorPlanElementProps>(
  (props, elementRef) => {
    const { offset, padding } = useCanvasViewportContext()

    const dragHandler = ({ target }: KonvaEventObject<DragEvent>) =>
      limitDrag(offset, padding)(target)

    return (
      <FloorPlanElement
        ref={elementRef}
        draggable
        onDragMove={dragHandler}
        onTransform={dragHandler}
        {...props}
      />
    )
  },
)

export default TransformableElement
