import type React from 'react'
import {
  InputAdornment,
  type InputAdornmentProps,
  type TextFieldProps,
} from '@mui/material'

import IconButton from '../IconButton'

const preventDefault: React.MouseEventHandler = event => event.preventDefault()

const ButtonAdornment = ({
  children,
  disabled = false,
  size,
  ...props
}: InputAdornmentProps & {
  disabled?: boolean
  size: TextFieldProps['size']
}) => (
  <InputAdornment onMouseDown={preventDefault} {...props}>
    <IconButton
      disabled={disabled}
      variant="text"
      color="neutral"
      size={size}
      sx={{
        color: 'currentColor',
      }}
    >
      {children}
    </IconButton>
  </InputAdornment>
)

export default ButtonAdornment
