import { z } from 'zod'

import {
  numberValueCheckboxArraySchema,
  stringValueCheckboxArraySchema,
} from 'src/shared/lib/zod/zod'

export const formValidityScopeSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('all'),
  }),
  z.object({
    type: z.literal('shift'),
    shiftIds: stringValueCheckboxArraySchema,
  }),
  z.object({
    type: z.literal('room'),
    roomIds: numberValueCheckboxArraySchema,
  }),
])

export type FormValidityScope = z.infer<typeof formValidityScopeSchema>
