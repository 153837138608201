import React, { useDeferredValue } from 'react'

import { useTranslation } from 'react-i18next'

import { useHolidaysQuery } from 'src/entities/holidays/queries/holidays'
import { useCurrentDate } from 'src/shared/lib/context/state/atoms/currentDate'
import { useSelectedAreaAtom } from 'src/shared/lib/context/state/atoms/selectedArea'
import { areOnSameDay, formatDate } from 'src/shared/lib/range/services/date'
import { includesTime } from 'src/shared/lib/range/services/timeRange'
import * as holidaysService from '../../../../service/holidays'

export const useHolidayMessage = () => {
  const { t } = useTranslation()

  const date = useDeferredValue(useCurrentDate())
  const [selectedArea] = useSelectedAreaAtom()
  const { data: holidays } = useHolidaysQuery()

  return React.useMemo(
    () =>
      holidaysService.getHolidaysMessage({
        getCurrentHoliday: holidaysService.getCurrentHoliday({
          getSelectedRoomId: () => selectedArea?.roomId,
          isOnSelectedDate: r => includesTime(r, date),
        }),
        formatDate,
        getTranslationKey: holidaysService.getHolidayTranslationKey({
          isSingleDayRange: r => areOnSameDay(r[0], r[1]),
        }),
        t,
      })(holidays),
    [holidays, t, date, selectedArea],
  )
}
