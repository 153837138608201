import { addToDate, getDifference } from 'src/shared/lib/range/services/date'
import { interpolate } from 'src/shared/lib/range/services/timeRange'

export const generateDurationChoices = () => {
  const start = new Date()
  const end = addToDate(24, 'hour', start)

  return interpolate([start, end], 'minutes', 15, true)
    .map(d => getDifference('minutes')(start, d))
    .slice(1)
}
