import { createFileRoute, redirect } from '@tanstack/react-router'

import { ensureMe } from 'src/entities/me/queries/me'

export const Route = createFileRoute('/')({
  component: () => 'hello',
  beforeLoad: async ({ context: { queryClient }, location: { href } }) => {
    try {
      const { defaultRestaurant } = await ensureMe(queryClient)
      return redirect({
        to: '/restaurants/$restaurantId/reservations',
        params: { restaurantId: defaultRestaurant.id },
      })
    } catch {
      throw redirect({ to: '/login', search: { redirect: href } })
    }
  },
})
