export const priorityComparator =
  <T, K extends keyof T>(
    priorityArray: T[K][],
    compareFieldGetter: (input: T) => T[K],
  ) =>
  (itemA: T, itemB: T) => {
    const fieldA = compareFieldGetter(itemA)
    const fieldB = compareFieldGetter(itemB)

    const aPriority = priorityArray.includes(fieldA)
    const bPriority = priorityArray.includes(fieldB)
    if (aPriority && bPriority) {
      return priorityArray.indexOf(fieldA) - priorityArray.indexOf(fieldB)
    }

    return aPriority ? -1 : 0
  }
