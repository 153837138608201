import React from 'react'

import { FullScreenModal } from 'src/shared/components/dialogs/FullScreenModal/FullScreenModal'
import { type CustomerPreviewViewProps } from './Preview'

const Preview = React.lazy(() => import('./Preview'))

export const CustomerPreviewModal = (
  props: Omit<CustomerPreviewViewProps, 'customerId'> & {
    customerId: number | undefined
    isOpen: boolean
    hasBeenOpened?: boolean
  },
) => {
  const { customerId, isOpen, hasBeenOpened = true } = props
  if (!customerId) return null

  return (
    <FullScreenModal isOpen={isOpen}>
      {hasBeenOpened && <Preview {...props} customerId={customerId} />}
    </FullScreenModal>
  )
}
