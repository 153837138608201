import { useSuspenseQuery } from '@tanstack/react-query'

import { useRestaurantCacheKeyFactory } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import {
  getScheduleActivityLog,
  useScheduleApiClient,
  type GetScheduleActivityLogOptions,
} from '../api/scheduleApi'

const SCHEDULE_ACTIVITY_LOG_CACHE_KEY = ['scheduleActivityLog']
const useScheduleCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(SCHEDULE_ACTIVITY_LOG_CACHE_KEY)

export const useScheduleActivityLogQuery = (
  options: GetScheduleActivityLogOptions,
) => {
  const apiClient = useScheduleApiClient()
  const getCacheKey = useScheduleCacheKeyFactory()

  return useSuspenseQuery({
    queryKey: getCacheKey([
      options.dateFrom,
      options.dateTo,
      options.eventTypes,
    ]),
    queryFn: () => getScheduleActivityLog(apiClient)(options),
  })
}
