import { complement, compose, intersection, length } from 'ramda'

import { type DefaultValues } from 'react-hook-form'
import { z } from 'zod'

import {
  type DisabledShiftException,
  type EnabledShiftException,
  type NotificationTemplate,
  type Shift,
  type ShiftException,
} from 'src/entities/schedule/types/scheduleApi'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import * as zod from 'src/shared/lib/zod/zod'
import {
  baseFormSchema,
  channelLimitMaximumEnforcer,
  createArrivalTimeMessage,
  fromBase,
  toBase,
  type BaseFormSchemaDeps,
} from './formSchema'
import { getWeekdaysInRange } from './intersectionValidation'
import {
  getPeriodAwareIntersection,
  type PeriodAware,
} from './periodAwareValidation'

const exceptionFormSchema = (d: BaseFormSchemaDeps) =>
  baseFormSchema(d).extend({
    effectivePeriod: z
      .tuple([z.date(), z.date()])
      .transform(r => r as DefinedRangeInterface<Date>),
    shiftDisabled: z.boolean(),
  })

export type FormException = z.infer<ReturnType<typeof exceptionFormSchema>>

export const toException =
  (shift: Shift) =>
  (
    exception: FormException,
  ):
    | Omit<DisabledShiftException, 'id'>
    | Omit<EnabledShiftException, 'id'> => ({
    effectivePeriod: {
      start: exception.effectivePeriod[0],
      end: exception.effectivePeriod[1],
    },
    shiftId: shift.id,
    ...(exception.shiftDisabled
      ? {
          shiftDisabled: true,
          shiftConfig: null,
        }
      : {
          shiftDisabled: false,
          shiftConfig: toBase(exception),
        }),
  })

export const fromException =
  (shift: Shift, templates: NotificationTemplate[]) =>
  (exception: Omit<ShiftException, 'id'>): FormException => ({
    ...fromBase(shift.weekdays, templates)(exception.shiftConfig ?? shift),
    effectivePeriod: [
      exception.effectivePeriod.start,
      exception.effectivePeriod.end,
    ],
    shiftDisabled: exception.shiftDisabled,
  })

export const newException = (shift: Shift, templates: NotificationTemplate[]) =>
  ({
    ...fromBase(shift.weekdays, templates)(shift),
    shiftDisabled: false,
    effectivePeriod: undefined,
  }) satisfies DefaultValues<FormException>

export const getIntersections =
  (shifts: Shift[]) =>
  (exceptions: PeriodAware[]) =>
  (formData: Partial<FormException>) =>
    exceptions
      .map(getPeriodAwareIntersection(shifts)(formData))
      .filter(zod.isNotFalsy)

const weekdaysEffectivePeriodOverlapCheck = ({
  effectivePeriod,
  weekdays,
}: Pick<FormException, 'effectivePeriod' | 'weekdays'>) =>
  !!effectivePeriod &&
  intersection(getWeekdaysInRange(effectivePeriod), weekdays).length > 0

export const validatedExceptionSchema =
  (baseDeps: BaseFormSchemaDeps) =>
  (shifts: Shift[]) =>
  (periodAware: PeriodAware[]) => {
    const createIntersections = getIntersections(shifts)(periodAware)

    return zod
      .zodAlwaysRefine(exceptionFormSchema(baseDeps))
      .refine(
        compose(complement(Boolean), length, createIntersections),
        compose(
          createArrivalTimeMessage(
            'schedule.exceptions.general_section.validation.exception_overlap',
          ),
          createIntersections,
        ),
      )
      .refine(weekdaysEffectivePeriodOverlapCheck, {
        path: ['effectivePeriod' satisfies keyof FormException],
        message:
          'schedule.exceptions.general_section.validation.effective_period_weekdays_no_overlap',
        params: {
          i18n: 'schedule.exceptions.general_section.validation.effective_period_weekdays_no_overlap',
        },
      })
      .transform(channelLimitMaximumEnforcer)
  }
