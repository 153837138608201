import { Button, type ButtonProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import PlusIcon from 'src/shared/components/icons/PlusIcon'

const NewReservationButton = (props: ButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      variant="outlined"
      color="neutral"
      fullWidth
      startIcon={<PlusIcon />}
      {...props}
    >
      {t('angular.new_reservation')}
    </Button>
  )
}

export default NewReservationButton
