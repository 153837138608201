import { useMemo } from 'react'
import { Box, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useLabelsQuery } from 'src/entities/label/queries/label'
import type { PersistedLabelInterface } from 'src/entities/label/types/label'
import Item from 'src/pages/SerialReservations/ReservationsTable/TableCells/LabelsCell/LabelPicker/Selection/Item'
import { useBaseFormController } from '../../../../hooks/form'

const LabelsChoice = () => {
  const { t } = useTranslation()

  const { data: allLabels } = useLabelsQuery()

  const labels = useMemo(
    () => allLabels.filter(l => !l.systemLabel),
    [allLabels],
  )

  const { field } = useBaseFormController('labels.labelIds')

  const selectedLabels = labels.filter(l => field.value.includes(l.id))

  const changeHandler = (newLabels: PersistedLabelInterface[]) => {
    field.onChange(newLabels.map(l => l.id))
  }

  if (!labels.length)
    return (
      <Typography>
        {t(
          'settings.labels.no_labels_defined',
          'No labels defined in the system.',
        )}
      </Typography>
    )

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 168px)',
        gap: 2,
      }}
    >
      {labels.map(label => (
        <Item
          key={label.id}
          label={label}
          selectedLabels={selectedLabels}
          onLabelsChange={changeHandler}
        />
      ))}
    </Box>
  )
}

export default LabelsChoice
