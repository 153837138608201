import { Box, Stack, styled } from '@mui/material'

import { ReservationNotesEnum } from 'src/entities/reservation/types/reservation'
import NoteIcon from 'src/shared/components/icons/NoteIcon'
import { NOTES_COLOURS } from 'src/widgets/Inbox/Lists/List/TodoNotification/Body/Details/Reservation/Labels'

export interface ReservationListItemNotesProps {
  displayNote: boolean
  displayMessage: boolean
  displayChoices: boolean
}

const NoteIndicator = styled(Box)(() => ({ flex: 1 }))

const ReservationListItemNotes = ({
  displayNote,
  displayMessage,
  displayChoices,
}: ReservationListItemNotesProps) => (
  <Stack direction="column">
    <NoteIcon variant="filled" size="small" justifyContent="center" />
    <Stack
      direction="row"
      spacing={0.125}
      sx={{
        height: 4,
        borderRadius: 0.5,
        overflow: 'hidden',
        width: 20,
      }}
    >
      {displayMessage && (
        <NoteIndicator
          bgcolor={NOTES_COLOURS[ReservationNotesEnum.GuestMessage]}
        />
      )}
      {displayNote && (
        <NoteIndicator
          bgcolor={NOTES_COLOURS[ReservationNotesEnum.RestaurantNote]}
        />
      )}
      {displayChoices && (
        <NoteIndicator bgcolor={NOTES_COLOURS[ReservationNotesEnum.Choices]} />
      )}
    </Stack>
  </Stack>
)

export default ReservationListItemNotes
