export interface State<T> {
  sources: T[]
  target: T | null
}

export interface DefinedState<T> {
  sources: T[]
  target: T
}

export const isDefinedState = <T>(
  maybeDefinedState: State<T>,
): maybeDefinedState is DefinedState<T> => !!maybeDefinedState.target

export interface MergeService<T> {
  setTarget: (target: T) => State<T>
  toggleSource: (source: T) => State<T>
  clearSources: () => State<T>
  clear: () => State<T>
}

const mergeReducer = <T>(state: State<T>): MergeService<T> => {
  const removeSource = (source: T) => {
    const { sources } = state

    if (!sources.includes(source)) return state

    return {
      ...state,
      sources: sources.filter(testSource => testSource !== source),
    }
  }

  const setTarget = (newTarget: T) => {
    const { target } = state

    if (target === newTarget) return state

    return {
      ...removeSource(newTarget),
      target: newTarget,
    }
  }

  const addSource = (newSource: T) => {
    const { sources, target } = state

    return {
      target: target === newSource ? null : target,
      sources: [...sources, newSource],
    }
  }

  const toggleSource = (source: T) => {
    const { sources } = state

    return sources.includes(source) ? removeSource(source) : addSource(source)
  }

  const clearSources = () => ({
    ...state,
    sources: [],
  })

  const clear = () => ({
    sources: [],
    target: null,
  })

  return {
    setTarget,
    toggleSource,
    clearSources,
    clear,
  }
}

export default mergeReducer
