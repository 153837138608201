import { useCallback, useMemo } from 'react'

import { useMatchRoute } from '@tanstack/react-router'

import useFeature from 'src/entities/info/hooks/useFeature'
import { SettingsScreensEnum } from 'src/entities/setting/types/settings'
import { useHasPermission } from 'src/entities/team-member/hooks/useCheckPermissions'

export const useSettingsHelpers = () => {
  const matchRoute = useMatchRoute()

  const canUseConfiguration = useHasPermission('configuration')
  const showTeamMembersTab = useHasPermission('manage_team_members')
  const permissionsFeatureEnabled = useFeature('pinEnabled')
  const showPermissionsTab =
    useHasPermission('permissions_tab') && permissionsFeatureEnabled
  const showRoomsTab =
    useHasPermission('manage_schedule_rooms') && permissionsFeatureEnabled
  const showScheduleTab =
    useHasPermission('manage_schedule') && permissionsFeatureEnabled
  const hasIntegrationPermission = useHasPermission('manage_integrations')
  const showIntegrationTab =
    useFeature('integrationLightspeed') && hasIntegrationPermission

  const shouldShowTab = useCallback(
    (screen: SettingsScreensEnum) => {
      if (screen === SettingsScreensEnum.TeamMembers && !showTeamMembersTab)
        return false

      if (screen === SettingsScreensEnum.Permissions && !showPermissionsTab)
        return false

      if (screen === SettingsScreensEnum.Schedule && !showScheduleTab)
        return false

      if (screen === SettingsScreensEnum.Rooms && !showRoomsTab) return false

      if (screen === SettingsScreensEnum.Integrations && !showIntegrationTab)
        return false

      return true
    },
    [
      showIntegrationTab,
      showPermissionsTab,
      showRoomsTab,
      showScheduleTab,
      showTeamMembersTab,
    ],
  )

  const shouldRedirect = useCallback(() => {
    if (!canUseConfiguration) return true

    if (
      matchRoute({ to: '/restaurants/$restaurantId/config/employees' }) &&
      !showTeamMembersTab
    )
      return true

    if (
      matchRoute({ to: '/restaurants/$restaurantId/config/permissions' }) &&
      !showPermissionsTab
    )
      return true

    if (
      matchRoute({ to: '/restaurants/$restaurantId/config/schedule' }) &&
      !showScheduleTab
    )
      return true

    if (
      matchRoute({ to: '/restaurants/$restaurantId/config/rooms' }) &&
      !showRoomsTab
    )
      return true

    if (
      matchRoute({ to: '/restaurants/$restaurantId/config/integrations' }) &&
      !showIntegrationTab
    )
      return true

    return false
  }, [
    matchRoute,
    canUseConfiguration,
    showIntegrationTab,
    showPermissionsTab,
    showRoomsTab,
    showScheduleTab,
    showTeamMembersTab,
  ])

  return useMemo(
    () => ({ shouldShowTab, shouldRedirect }),
    [shouldRedirect, shouldShowTab],
  )
}
