import { createFileRoute } from '@tanstack/react-router'

import Labels from 'src/pages/Settings/Labels'
import { ConfigScroll } from './-ConfigScroll'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/config/labels',
)({
  component: RouteComponent,
})

function RouteComponent() {
  return (
    <ConfigScroll>
      <Labels />
    </ConfigScroll>
  )
}
