import { useMemo } from 'react'
import {
  Box,
  Button,
  Divider,
  InputLabel,
  Stack,
  styled,
  Typography,
  type StackProps,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useCustomers } from 'src/entities/customer/queries/customer'
import { getDisplayName } from 'src/entities/customer/services/customer'
import {
  MonthlyPatternEnum,
  ReservationGroupPatternUnitEnum,
} from 'src/entities/reservation/services/reservationGroupFactory'
import {
  translateGroupPattern,
  translateMonthlyPattern,
} from 'src/entities/reservation/services/serialReservations'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useServiceTimesQuery } from 'src/entities/service-time/queries/serviceTime'
import { createFallbackServiceTime } from 'src/entities/service-time/types/serviceTime'
import { ChipChoice } from 'src/shared/components/form/inputs/ChipChoice/ChipChoice'
import { DateRangeInput } from 'src/shared/components/form/inputs/DateRangeInput/DateRangeInput'
import SingleSelectDropdown from 'src/shared/components/form/inputs/SingleSelectDropdown'
import GuestsIcon from 'src/shared/components/icons/GuestsIcon'
import SerialCalendarIcon from 'src/shared/components/icons/SerialCalendarIcon'
import SerialRulesIcon from 'src/shared/components/icons/SerialRulesIcon'
import {
  getWeekdayName,
  getWeekdaysNumbers,
} from 'src/shared/lib/range/services/date'
import { formatPhoneNumber } from 'src/shared/lib/string/services/phoneNumber'
import Pillboxes from '../../../CustomerPreview/CustomerData/Pillboxes'
import {
  ArrivalTimeCell,
  SeatCountCell,
} from '../../../SerialReservations/ReservationsTable/TableCells'
import { type useSerialReservations } from '../hooks/useSerialReservations'

interface ColumnHeaderProps extends StackProps {
  icon: typeof GuestsIcon
  label: string
}

const ColumnHeader = ({ icon: Icon, label, ...rest }: ColumnHeaderProps) => (
  <Stack direction="row" gap={1} alignItems="center" {...rest}>
    <Icon variant="filled" />
    <Typography variant="labelRegular">{label}</Typography>
  </Stack>
)

const ColumnWrapper = styled(Stack)({ gap: 20, flex: 1 })

interface SerialReservationsHeaderProps {
  selectedReservation: ReservationInterface
  serialReservationProps: ReturnType<typeof useSerialReservations>
}

const weekdayNumbers = getWeekdaysNumbers()

export const SerialReservationsHeader = ({
  selectedReservation,
  serialReservationProps: {
    date,
    setDate,
    interval,
    setInterval,
    monthday,
    setMonthday,
    unit,
    setUnit,
    timeRange,
    setTimeRange,
    selectedWeekdays,
    setSelectedWeekdays,
  },
}: SerialReservationsHeaderProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { data: remoteServiceTimes } = useServiceTimesQuery()

  const serviceTimes = useMemo(() => {
    if (remoteServiceTimes.length) return remoteServiceTimes

    return [createFallbackServiceTime()]
  }, [remoteServiceTimes])

  const [customer] = useCustomers(
    selectedReservation.guest.customerId
      ? [selectedReservation.guest.customerId]
      : [],
  )

  return (
    <Stack direction={['column', 'row']} sx={{ gap: 2, p: 2 }}>
      <ColumnWrapper>
        <ColumnHeader
          icon={SerialCalendarIcon}
          label={t('serial_reservations.dates_and_location', {
            defaultValue: 'Dates',
          })}
        />
        <Stack gap={1.5}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr', '1fr', 'auto 140px'],
              gap: [1.5, 1.5, 1.5, 1],
              alignItems: 'end',
            }}
          >
            <DateRangeInput
              value={date}
              setValue={newValue => newValue && setDate(newValue)}
              label={t('serial_reservations.date_and_time', {
                defaultValue: 'Date & time',
              })}
              showFooter={false}
            />
            <ArrivalTimeCell
              serviceTimes={serviceTimes}
              timeRange={timeRange}
              onChange={setTimeRange}
              size="medium"
            />
          </Box>
        </Stack>
      </ColumnWrapper>
      <Divider flexItem orientation="vertical" />
      <ColumnWrapper>
        <ColumnHeader
          icon={SerialRulesIcon}
          label={t('serial_reservations.rules', {
            defaultValue: 'Rules',
          })}
        />
        <Stack gap={1.5}>
          <Stack gap={0.5}>
            <InputLabel sx={{ typography: 'labelSmall' }}>
              {t('angular.repeat_every')}
            </InputLabel>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '100px auto',
                gap: 1,
                alignItems: 'end',
              }}
            >
              <SeatCountCell
                seatCount={interval}
                onChange={setInterval}
                size="medium"
              />
              <SingleSelectDropdown
                value={unit}
                options={Object.values(ReservationGroupPatternUnitEnum)}
                onSelect={setUnit}
                renderOption={o => translateGroupPattern(o, t)}
                fullWidth
              />
            </Box>
          </Stack>
          {unit === ReservationGroupPatternUnitEnum.Month && (
            <SingleSelectDropdown
              value={monthday}
              options={[
                MonthlyPatternEnum.DayOfMonth,
                MonthlyPatternEnum.WeekFromStart,
                MonthlyPatternEnum.WeekFromEnd,
              ]}
              renderOption={o =>
                translateMonthlyPattern(o, date[0], language, t)
              }
              onSelect={setMonthday}
              fullWidth
            />
          )}
          {unit === ReservationGroupPatternUnitEnum.Week && (
            <ChipChoice
              value={selectedWeekdays}
              onChange={setSelectedWeekdays}
              availableSelection={weekdayNumbers}
              itemToString={getWeekdayName}
            />
          )}
        </Stack>
      </ColumnWrapper>
      <Divider flexItem orientation="vertical" />
      <ColumnWrapper>
        <ColumnHeader icon={GuestsIcon} label={t('angular.guest')} />
        <Stack gap={1.5}>
          <Button
            variant="color-outlined"
            fullWidth
            sx={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              height: 'auto',
              px: 1,
              py: 0.5,
              pointerEvents: 'none',
              flexDirection: 'column',
              textAlign: 'left',
              minHeight: 68,
            }}
          >
            <Typography component="span" display="block">
              {getDisplayName(customer ?? selectedReservation.guest)}
            </Typography>
            <Typography
              variant="labelSmall"
              component="span"
              display="block"
              color="grey.400"
              fontWeight="normal"
            >
              {[
                selectedReservation.guest.email,
                formatPhoneNumber(selectedReservation.guest.phoneNumber),
                selectedReservation.guest.companyName,
              ]
                .filter(Boolean)
                .join(' | ')}
            </Typography>
          </Button>
          {customer && <Pillboxes customer={customer} />}
        </Stack>
      </ColumnWrapper>
    </Stack>
  )
}
