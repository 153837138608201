import { forwardRef } from 'react'
import { Button, type ButtonProps } from '@mui/material'

const SquareIconButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ sx, ...props }, ref) => (
    <Button
      sx={{
        ...sx,
        minWidth: 0,
        p: 1,
      }}
      ref={ref}
      {...props}
    />
  ),
)

export default SquareIconButton
