import { atom, useAtom, useSetAtom } from 'jotai'

import type { ReservationInterface } from 'src/entities/reservation/types/reservation'

type AtomContent = ReservationInterface['id'] | null
const primitive = atom<AtomContent>(null)

const reservationIdAtom = atom(
  get => get(primitive),
  (_get, set, newId: AtomContent) =>
    set(primitive, oldId => (newId === oldId ? null : newId)),
)

export const useReservationIdForTableAssignmentAtom = () =>
  useAtom(reservationIdAtom)

export const useSetReservationIdForTableAssignment = () =>
  useSetAtom(reservationIdAtom)
