import { InputAdornment, TextField, type TextFieldProps } from '@mui/material'

import type IconContainer from '../../../icons/IconContainer'
import { type IconProps } from '../../../icons/IconContainer'
import FormErrorText from '../../common/FormErrorText/FormErrorText'

const SelectInput = ({
  IconComponent,
  iconProps,
  onClick,
  disabled,
  ...props
}: {
  IconComponent: typeof IconContainer
  iconProps?: Partial<IconProps>
} & Partial<TextFieldProps>) => (
  <TextField
    variant="outlined"
    fullWidth={false}
    disabled={disabled}
    onClick={!disabled ? onClick : undefined}
    slotProps={{
      input: {
        sx: {
          cursor: disabled ? 'not-allowed !important' : 'pointer',
        },
        inputProps: {
          style: {
            boxSizing: 'border-box',
            cursor: disabled ? 'not-allowed' : 'pointer',
          },
        },
        readOnly: true,
        endAdornment: (
          <InputAdornment
            position="end"
            disablePointerEvents
            sx={{
              m: 0,
              mr: 1,
            }}
          >
            <IconComponent size={props.size} {...iconProps} />
          </InputAdornment>
        ),
      },

      inputLabel: {
        shrink: true,
        error: false,
      },
    }}
    {...props}
    error={props.error && !!props.helperText}
    helperText={
      props.helperText && (
        <FormErrorText error={props.error} helperText={props.helperText} />
      )
    }
  />
)

export default SelectInput
