import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/reservations/list',
)({
  component: RouteComponent,
})

function RouteComponent() {
  return 'Hello /restaurants/$restaurantId/reservations/list!'
}
