import { Box, Stack } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { type AreaInterface } from 'src/entities/area/types/area'
import { type LabelInterface } from 'src/entities/label/types/label'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { type RoomInterface } from 'src/entities/room/types/room'
import { type TableInterface } from 'src/entities/table/types/table'
import LinkIcon from 'src/shared/components/icons/LinkIcon'
import ExtraSmallLabel from 'src/widgets/Label/ExtraSmallLabel'
import ReservationListItemNotes from './ReservationListItemNotes'
import { TableOrRoomName } from './TableOrRoomName'

export const ReservationDetails = ({
  tables,
  restaurantAreas,
  room,
  labels,
  displayLink,
  guestMessage,
  restaurantNote,
  choices,
}: {
  room: RoomInterface | undefined
  labels: LabelInterface[]
  tables: TableInterface[]
  restaurantAreas: AreaInterface[]
  displayLink: boolean
} & Pick<
  ReservationInterface,
  'restaurantNote' | 'choices' | 'guestMessage'
>) => {
  const displayLabels = !!labels?.length
  const displayNote = !!restaurantNote || choices.length > 0 || !!guestMessage
  const displayIcons = displayNote || displayLink
  const displayIndicators = displayIcons || displayLabels

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      sx={{
        height: '1.25rem',
        gap: 0.5,
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
      }}
    >
      {displayIndicators && (
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          {displayIcons && (
            <Stack
              direction="row"
              spacing={0.25}
              {...createTestIdProps('reservation-notes')}
            >
              {displayNote && (
                <ReservationListItemNotes
                  displayMessage={!!guestMessage}
                  displayNote={!!restaurantNote}
                  displayChoices={!!choices.length}
                />
              )}
              {displayLink && <LinkIcon variant="filled" size="small" />}
            </Stack>
          )}
          {displayLabels && (
            <Stack
              direction="row"
              spacing={0.125}
              {...createTestIdProps('reservation-labels')}
            >
              {labels.map(l => (
                <ExtraSmallLabel key={l.id} label={l} />
              ))}
            </Stack>
          )}
        </Stack>
      )}
      <Box ml="auto" overflow="hidden">
        <TableOrRoomName
          restaurantAreas={restaurantAreas}
          tables={tables}
          roomName={room?.name}
        />
      </Box>
    </Stack>
  )
}
