import React from 'react'
import { type Breakpoint } from '@mui/material'

import { type BreakpointsMap } from 'src/shared/lib/style/hooks/useBreakpointCallback'
import { useBreakpointsCallback } from 'src/shared/lib/style/hooks/useBreakpointsCallback'

const ForcedLayoutContext = React.createContext<Breakpoint | null>(null)
const useForcedLayoutContext = () => React.useContext(ForcedLayoutContext)
export const withForcedLayout =
  (breakpoint: Breakpoint) =>
  <T extends object>(
    Component: React.FunctionComponent<T>,
  ): React.FunctionComponent<T> =>
  props => (
    <ForcedLayoutContext.Provider value={breakpoint}>
      <Component {...props} />
    </ForcedLayoutContext.Provider>
  )

const withLayoutBreakpoints = <T,>(components: BreakpointsMap<T>) =>
  ((props: object) => {
    const forcedBreakpoint = useForcedLayoutContext()

    const Component = useBreakpointsCallback(
      components,
      forcedBreakpoint,
    ) as React.FC

    if (!Component) return null

    return <Component {...props} />
  }) as T | (() => null)

export default withLayoutBreakpoints
