import { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import useFeature from 'src/entities/info/hooks/useFeature'
import { usePaymentPlanQuery } from 'src/entities/payment/queries/paymentPlan'
import { usePaymentProviderQuery } from 'src/entities/payment/queries/paymentProvider'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import {
  type NoPaymentPlan,
  type PaymentPlan,
} from 'src/entities/schedule/types/scheduleApi'
import ReservationDrawerSection from 'src/shared/components/common/ReservationDrawerSection'
import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import { type DrawerColor } from 'src/widgets/SidebarDrawer/drawerColor'
import { PaymentPlanDisplay } from './components/PaymentPlanDisplay'
import { PaymentPlanSelection } from './components/PaymentPlanSelection'
import { useCheckPaymentPlanValidity } from './hooks/useCheckPaymentPlanValidity'

interface PaymentPlanSelectionProps {
  selectedReservation: ReservationInterface
  onPaymentPlanChange: (newPlan: PaymentPlan | NoPaymentPlan) => void
  open: boolean
  onOpen: () => void
  onClose: () => void
  drawerColor: DrawerColor
}

export const PaymentPlanSection = ({
  selectedReservation,
  onPaymentPlanChange,
  ...rest
}: PaymentPlanSelectionProps) => {
  const { t } = useTranslation()

  const { data: actualPaymentPlans } = usePaymentPlanQuery()
  const { data: paymentProvider } = usePaymentProviderQuery()
  const paymentPlanSmsEnabled = useFeature('actionRequiredSms')

  const paymentPlans = useMemo(
    () =>
      [{ id: null }, ...actualPaymentPlans] satisfies (
        | PaymentPlan
        | NoPaymentPlan
      )[],
    [actualPaymentPlans],
  )

  const { showSimpleDialog, simpleDialogProps } = useSimpleDialog()

  const handlePaymentPlanChange = useCallback(
    (newPlan: PaymentPlan | NoPaymentPlan) => {
      const {
        guest: { email, phoneNumber },
        sendEmail,
        sendSms,
      } = selectedReservation

      if (newPlan.id === null) return onPaymentPlanChange(newPlan)

      if (!paymentPlanSmsEnabled && !email)
        return showSimpleDialog({
          message: t(
            'payment_plan.guest_email_required_when_sms_feature_disabled',
            'A guest with an email address is required in order to send the Credit Card confirmation.',
          ),
        })

      if (!email && !phoneNumber)
        return showSimpleDialog({
          message: t(
            'payment_plan.guest_contact_info_required',
            'A guest email or phone number is required in order to send the Credit Card confirmation.',
          ),
        })

      if (!paymentPlanSmsEnabled && !sendEmail)
        return showSimpleDialog({
          message: t(
            'payment_plan.email_notification_required_when_sms_feature_disabled',
            'E-mail notification must be selected in order to send the Credit Card confirmation',
          ),
        })

      if (!sendEmail && !sendSms)
        return showSimpleDialog({
          message: t(
            'payment_plan.notification_required',
            'Select E-mail or SMS notification',
          ),
        })

      return onPaymentPlanChange(newPlan)
    },
    [
      selectedReservation,
      onPaymentPlanChange,
      paymentPlanSmsEnabled,
      showSimpleDialog,
      t,
    ],
  )

  const setNoPlan = useCallback(
    () => onPaymentPlanChange(paymentPlans[0]!),
    [onPaymentPlanChange, paymentPlans],
  )

  useCheckPaymentPlanValidity({
    reservation: selectedReservation,
    onInvalid: setNoPlan,
    showSimpleDialog,
  })

  const featureEnabled = useFeature('manualResPayment')
  const isNewReservation = !selectedReservation.id

  if (!isNewReservation && !selectedReservation?.payment) return null
  if (isNewReservation && !featureEnabled) return null
  if (isNewReservation && paymentPlans.length < 2) return null
  if (isNewReservation && !selectedReservation.guest.customerId) return null

  return (
    <ReservationDrawerSection
      title={t('payment_plan.payment_plan', { defaultValue: 'Payment plan' })}
    >
      {!selectedReservation.id && (
        <PaymentPlanSelection
          reservation={selectedReservation}
          currency={paymentProvider?.currency ?? ''}
          paymentPlans={paymentPlans}
          onPaymentPlanChange={handlePaymentPlanChange}
          {...rest}
        />
      )}
      {!!selectedReservation.id &&
        selectedReservation?.payment?.paymentPlanId && (
          <PaymentPlanDisplay
            paymentPlans={actualPaymentPlans}
            reservation={selectedReservation}
          />
        )}
      <SimpleDialog
        variant="dismissible"
        title={t('payment_plan.error', {
          defaultValue: 'Payment plan error',
        })}
        {...simpleDialogProps}
      />
    </ReservationDrawerSection>
  )
}
