import { useCallback, useMemo } from 'react'

import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { fromIsoDuration } from './date'
import { type IsoDuration } from '../../zod/zod'

const timeUnitFormatter = (
  locale: Intl.LocalesArgument,
  unit: string,
  unitDisplay: Intl.NumberFormatOptions['unitDisplay'],
) => Intl.NumberFormat(locale, { style: 'unit', unit, unitDisplay })

const hourMinuteFormatterFactory = (
  locale: Intl.LocalesArgument,
  unitDisplay: Intl.NumberFormatOptions['unitDisplay'] = 'long',
) => {
  const hourFormatter = timeUnitFormatter(locale, 'hour', unitDisplay)
  const minuteFormatter = timeUnitFormatter(locale, 'minute', unitDisplay)
  const fmtList = new Intl.ListFormat(locale, {
    style: 'long',
    type: 'conjunction',
  })

  return (minutes: number) => {
    const hours = Math.floor(minutes / 60)
    const mins = minutes % 60

    return fmtList.format(
      [
        hours ? hourFormatter.format(hours) : null,
        mins ? minuteFormatter.format(mins) : null,
      ].filter(v => v !== null),
    )
  }
}

interface FromToDeps {
  to: Date
  from?: Date
}

interface DurationDeps {
  duration: IsoDuration
}

const isMinutesOption = (
  deps: FromToDeps | DurationDeps,
): deps is DurationDeps => Object.hasOwn(deps, 'duration')

export const useHourMinuteDurationFormatter = () => {
  const {
    i18n: { language },
  } = useTranslation()

  const format = useMemo(() => hourMinuteFormatterFactory(language), [language])

  return useCallback(
    (deps: FromToDeps | DurationDeps) => {
      const minutes = isMinutesOption(deps)
        ? fromIsoDuration(deps.duration, 'minutes')
        : Math.round(
            dayjs
              .duration(deps.to.getTime() - (deps.from ?? new Date()).getTime())
              .asMinutes(),
          )

      return format(minutes)
    },
    [format],
  )
}
