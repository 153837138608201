import { Card, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  getItemLabel,
  setItemValue,
} from 'src/entities/setting/services/settings'
import { type Setting } from 'src/entities/setting/types/setting'
import Toggle from 'src/shared/components/form/inputs/Toggle'

const Section = ({
  settings,
  label,
  onSettingChange,
}: {
  settings: Setting[]
  label: string
  onSettingChange: (configItem: Setting) => void
}) => {
  const { t } = useTranslation('', { keyPrefix: 'settings.general' })

  return (
    <Card sx={{ p: 0.5, flex: 1 }} variant="outlined">
      <Stack direction="column" spacing={1}>
        <Typography variant="h6">{label}</Typography>
        {settings.map(configItem => (
          <Toggle
            key={configItem.name}
            label={t(getItemLabel(configItem))}
            value={configItem.name}
            onChange={(_e, checked) =>
              onSettingChange(setItemValue(configItem, checked))
            }
            checked={configItem.value}
          />
        ))}
      </Stack>
    </Card>
  )
}

export default Section
