import { useCallback } from 'react'

import { useInfoQuery } from 'src/entities/info/queries/info'
import { useApiClient } from './useApiClient'
import { convertDatesToNormalized } from '../services/api'
import { type ApiRequest } from '../types/api'

interface BetterApiClientOptions {
  restaurantHash?: string
  prefix?: string
  baseEntity?: string
}

export const useBetterApiClient = ({
  restaurantHash,
  prefix,
  baseEntity = 'restaurant',
}: BetterApiClientOptions = {}) => {
  const apiClient = useApiClient()
  const { data: restaurant } = useInfoQuery(restaurantHash)

  return useCallback(
    (req: ApiRequest) => {
      const reqWithBaseUrl = {
        ...req,
        json: req.json ? convertDatesToNormalized(req.json) : undefined,
        url: `${baseEntity ?? 'restaurant'}/${restaurant.id}/${prefix ? `${prefix}/` : ''}${req.url}`,
      } satisfies ApiRequest

      return req.method === 'GET'
        ? apiClient.get(reqWithBaseUrl)
        : apiClient.post(reqWithBaseUrl)
    },
    [apiClient, baseEntity, prefix, restaurant.id],
  )
}
