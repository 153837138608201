import { Divider, Stack } from '@mui/material'

import { type PersistedLabelInterface } from 'src/entities/label/types/label'
import Item from '../../SerialReservations/ReservationsTable/TableCells/LabelsCell/LabelPicker/Selection/Item'

const LabelsPicker = ({
  onChange,
  value = [],
  labels,
}: {
  onChange: (labels: PersistedLabelInterface[]) => void
  value: PersistedLabelInterface[]
  labels: PersistedLabelInterface[]
}) => (
  <Stack
    direction="column"
    spacing={1.5}
    sx={{
      p: 1,
      pb: 2,
    }}
    divider={<Divider sx={{ borderColor: 'white' }} />}
  >
    {labels.map(label => (
      <Item
        label={label}
        key={label.id}
        selectedLabels={value}
        onLabelsChange={onChange}
        sx={{ m: 0 }}
      />
    ))}
  </Stack>
)

export default LabelsPicker
