import type React from 'react'
import { Button, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Modal from 'src/shared/components/common/Modal'

interface DetailsModalProps {
  open: boolean
  onClose: () => void
  payload: string
}

const DetailsModal: React.FC<DetailsModalProps> = ({
  open,
  onClose,
  payload,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('schedule.activity_log.details', 'Details')}
      showCloseButton
      content={
        <Typography
          variant="body2"
          component="pre"
          sx={{
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            maxHeight: 300,
            overflowY: 'auto',
            bgcolor: '#ffffff',
            borderRadius: 1,
            border: '1px solid #ccc',
            p: 1,
          }}
        >
          {JSON.stringify(JSON.parse(payload), null, 2)}
        </Typography>
      }
      actions={
        <Button
          variant="outlined"
          onClick={onClose}
          sx={{
            bgcolor: 'white',
            color: 'black',
            textTransform: 'none',
            borderColor: 'grey',
            '&:hover': {
              bgcolor: '#f0f0f0',
            },
          }}
        >
          {t('modal.button.dismiss', 'Dismiss')}
        </Button>
      }
    />
  )
}

export default DetailsModal
