import { Box } from '@mui/material'

import { useTranslation } from 'react-i18next'

import ToggleWithSubtitle from './components/ToggleWithSubtitle'

const AfterDeadline = () => {
  const { t } = useTranslation()

  return (
    <Box position="relative">
      <ToggleWithSubtitle
        fieldName="reservationConfirmation.afterDeadline"
        label={t(
          'schedule.shifts.confirmations_section.confirmations.after_deadline.label',
          'Allow bookings with acceptance after booking deadline',
        )}
        subtitle=""
      />
    </Box>
  )
}

export default AfterDeadline
