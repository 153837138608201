import { Tooltip, type TooltipProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

const LockTooltip = ({ children, ...props }: Omit<TooltipProps, 'title'>) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      title={t(
        'reservation_drawer.footer.action_button.locked_tooltip',
        'The reservation is locked',
      )}
      enterTouchDelay={0}
      placement="top"
      {...props}
    >
      {children}
    </Tooltip>
  )
}

export { LockTooltip }
