import React from 'react'

import { useAvailabilitiesQuery } from 'src/entities/availability/queries/availability'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useRoomShiftsQuery } from 'src/entities/shift-instance/queries/shift'
import { compensatePristineReservation } from './service/availability'

export const useAvailabilities = (
  currentDate: Date,
  roomId: number,
  initialReservation: ReservationInterface,
) => {
  const { data: shifts } = useRoomShiftsQuery(currentDate, roomId)
  const { data: availabilities } = useAvailabilitiesQuery(currentDate, roomId)

  return React.useMemo(
    () =>
      compensatePristineReservation(initialReservation, shifts)(availabilities),
    [availabilities, initialReservation, shifts],
  )
}
