import { complement, either, isEmpty, isNil, prop } from 'ramda'

import {
  futureFactory,
  pastFactory,
} from 'src/pages/Search/Reservation/Filters/Date/services/datePicker'
import { convertToTimezone } from 'src/shared/lib/range/services/date'
import {
  defaultApiFilters,
  FiltersEnum,
  isPredefinedDateFilter,
  type ReservationApiFilters,
} from 'src/shared/lib/reducer/service/reservationsFilter'
import { ReservationStatusEnum } from '../types/reservation'

const paxFilterFactory = ({
  [FiltersEnum.pax]: range,
}: ReservationApiFilters) => {
  if (!range) return null

  return {
    min: range[0] ?? range[1],
    max: range[1] ?? range[0],
  }
}

const statusFilterToApiStatus = (
  status: ReservationApiFilters['status'][number],
) =>
  ({
    [ReservationStatusEnum.Cancelled]: 'cancelled',
    [ReservationStatusEnum.Confirmed]: 'confirmed',
    [ReservationStatusEnum.Expired]: 'expired',
    [ReservationStatusEnum.NoShow]: 'no-show',
    [ReservationStatusEnum.Pending]: 'pending',
  })[status]

const statusFilterFactory = ({
  [FiltersEnum.status]: statuses,
}: ReservationApiFilters) => statuses.map(statusFilterToApiStatus)

export const dateFilterFactory = ({
  [FiltersEnum.dateTime]: dateFilter,
}: Partial<ReservationApiFilters>) => {
  if (!dateFilter) return null

  const predefinedFactory =
    dateFilter === 'future' ? futureFactory : pastFactory

  const range = isPredefinedDateFilter(dateFilter)
    ? predefinedFactory()
    : dateFilter

  return {
    ...(range[0] && { from: convertToTimezone(range[0]) }),
    ...(range[1] && { to: convertToTimezone(range[1]) }),
    type: 'startDateTime',
  }
}

const timeFilterFactory = ({
  [FiltersEnum.time]: range,
}: ReservationApiFilters) => {
  if (!range) return null

  return {
    ...(range[0] && { from: convertToTimezone(range[0]) }),
    ...(range[1] && { to: convertToTimezone(range[1]) }),
    type: 'startTime',
  }
}

const getId = prop('id')
const getHash = prop('hash')

const labelsFilterFactory = ({
  [FiltersEnum.label]: labels,
}: ReservationApiFilters) => labels.map(getId).filter(Boolean) as number[]

const restaurantsFilterFactory = ({
  [FiltersEnum.restaurant]: restaurants,
}: ReservationApiFilters) => restaurants.map(getHash)

const customerFilterFactory = ({
  [FiltersEnum.customer]: customer,
}: ReservationApiFilters) => customer?.id ?? null

export const createFilters = (
  partialFilters: Partial<ReservationApiFilters>,
) => {
  const filters = {
    ...defaultApiFilters,
    ...partialFilters,
  }

  return Object.fromEntries(
    Object.entries({
      hotelStayId: filters[FiltersEnum.hotelStay],
      pax: paxFilterFactory(filters),
      timeFilters: [
        dateFilterFactory(filters),
        timeFilterFactory(filters),
      ].filter(Boolean),
      labels: labelsFilterFactory(filters),
      restaurants: restaurantsFilterFactory(filters),
      customerId: customerFilterFactory(filters),
      wholeGroup: filters[FiltersEnum.wholeGroup],
      searchPhrase: filters[FiltersEnum.searchPhrase] || null,
      groupId: filters[FiltersEnum.serial],
      statuses: statusFilterFactory(filters),
      hasFeedback: filters[FiltersEnum.hasFeedback],
      payment: filters[FiltersEnum.payment],
    }).filter(([_key, value]) => complement(either(isNil, isEmpty))(value)),
  )
}
