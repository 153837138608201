import {
  always,
  append,
  compose,
  cond,
  either,
  head,
  identical,
  ifElse,
  isNil,
  join,
  last,
  map,
  max,
  min,
  prepend,
  T,
  uniq,
} from 'ramda'

import { type DefinedRangeInterface, type RangeInterface } from '../types/range'

const getLowerRange = <T extends number | string>(
  rangeA: DefinedRangeInterface<T>,
  rangeB: DefinedRangeInterface<T>,
) => {
  if (rangeA[0] < rangeB[0]) return rangeA
  if (rangeB[0] < rangeA[0]) return rangeB

  return rangeA[1] < rangeB[1] ? rangeA : rangeB
}

const isSinglePoint =
  <T, U>(identityGetter: (item: T | null) => U) =>
  (range: RangeInterface<T>) =>
    uniq(range.map(identityGetter)).length === 1

export const isSinglePointRange = <T extends number | string>(
  range: RangeInterface<T>,
) => range[0] === range[1]

const getTouchPoint = <T extends number | string>(
  rangeA: RangeInterface<T>,
  rangeB: RangeInterface<T>,
) => {
  if (rangeA[1] === rangeB[0]) return rangeA[1]
  if (rangeA[0] === rangeB[1]) return rangeA[0]

  return null
}

export const rangesTouch = <T extends number | string>(
  rangeA: RangeInterface<T>,
  rangeB: RangeInterface<T>,
) => !!getTouchPoint(rangeA, rangeB)

const isBoundaryPoint = <T extends number | string>(
  lowerRange: RangeInterface<T>,
  upperRange: RangeInterface<T>,
) => isSinglePointRange(lowerRange) && rangesTouch(lowerRange, upperRange)

export const getOverlap = <T extends number | string>(
  rangeA: DefinedRangeInterface<T>,
  rangeB: DefinedRangeInterface<T>,
): DefinedRangeInterface<T> | null => {
  const lowerRange = getLowerRange(rangeA, rangeB)
  const upperRange = lowerRange === rangeA ? rangeB : rangeA

  if (isBoundaryPoint(lowerRange, upperRange)) return lowerRange

  if (isBoundaryPoint(upperRange, lowerRange)) return upperRange

  const lowerRangeEndsBeforeUpperStart = lowerRange[1] <= upperRange[0]
  if (lowerRangeEndsBeforeUpperStart) return null

  const highestCommonPoint = min(lowerRange[1], upperRange[1])
  return [upperRange[0], highestCommonPoint] as DefinedRangeInterface<T>
}

export const getIntersection = <T extends number | string>(
  rangeA: DefinedRangeInterface<T>,
  rangeB: DefinedRangeInterface<T>,
): DefinedRangeInterface<T> | null => {
  const overlap = getOverlap(rangeA, rangeB)

  if (overlap) return overlap

  const touchPoint = getTouchPoint(rangeA, rangeB)
  if (touchPoint) return [touchPoint, touchPoint] as DefinedRangeInterface<T>

  return null
}

export const rangesOverlap = compose(Boolean, getOverlap)

export const rangesIntersect = either(rangesOverlap, rangesTouch)

export const isWithinRange =
  (testNumber: number | string) =>
  (range: DefinedRangeInterface<number | string>): boolean =>
    range[0] <= testNumber && testNumber <= range[1]

const attachSign =
  (sign: string, attacher: typeof prepend<string>) => (text: string) =>
    attacher(sign, [text]).join(' ')

const isNotNil = <T>(value: T | null): value is T => value !== null

export const rangeToString = <U, V>(
  formatter: (item: U | null) => string,
  comparator: (item: U | null) => V | string = formatter,
): ((range: RangeInterface<U> | null) => string) =>
  ifElse(
    isNotNil,
    cond([
      [compose(isNil, last), compose(attachSign('+', append), formatter, head)],
      [
        compose(isNil, head),
        compose(attachSign('-', prepend), formatter, last),
      ],
      [isSinglePoint(comparator), compose(formatter, head)],
      [T, compose(join(' - '), map(formatter))],
    ]),
    always(''),
  )

export const toString = rangeToString(String)

export const overwriteFrom =
  <T>(value: T) =>
  <R extends RangeInterface<T>>(range: R | null) =>
    [value, range?.[1] ?? null] as R

export const overwriteTo =
  <T>(value: T) =>
  <R extends RangeInterface<T>>(range: R | null) =>
    [range?.[0] ?? null, value] as R

export const areRangesEqual =
  <T>(comparator: (itemA: T, itemB: T) => boolean = identical<T>) =>
  (
    rangeA: DefinedRangeInterface<T>,
    rangeB: DefinedRangeInterface<T>,
  ): boolean =>
    comparator(rangeA[0], rangeB[0]) && comparator(rangeA[1], rangeB[1])

export const joinRanges = <T extends number | string | Date>(
  rangeA: DefinedRangeInterface<T>,
  rangeB: DefinedRangeInterface<T>,
): DefinedRangeInterface<T> => [
  min(rangeA[0], rangeB[0]),
  max(rangeA[1], rangeB[1]),
]
