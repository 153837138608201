import React, { type Ref } from 'react'
import { Typography, type PaletteOptions, type SxProps } from '@mui/material'

import ItemContainer from '../ItemContainer'

const MoreIndicator = (
  {
    visibleItemsCount,
    totalItemsCount,
    disabled = false,
    sx,
    palette,
  }: {
    visibleItemsCount: number
    totalItemsCount: number
    disabled?: boolean
    sx?: SxProps
    palette: keyof PaletteOptions
  },
  ref: Ref<HTMLDivElement>,
) => {
  const remainingItemsCount = totalItemsCount - visibleItemsCount

  return (
    <ItemContainer ref={ref} sx={sx} disabled={disabled} palette={palette}>
      <Typography>{`+${remainingItemsCount}`}</Typography>
    </ItemContainer>
  )
}

export default React.forwardRef(MoreIndicator)
