import { createFileRoute, useRouter } from '@tanstack/react-router'
import { z } from 'zod'

import { LoginScreen } from 'src/pages/Login/LoginScreen'

export const Route = createFileRoute('/login')({
  validateSearch: z.object({
    redirect: z.string().optional(),
  }),
  component: RouteComponent,
})

function RouteComponent() {
  const { redirect } = Route.useSearch()
  const router = useRouter()
  const navigate = Route.useNavigate()

  return (
    <LoginScreen
      onLogin={r =>
        redirect
          ? router.history.replace(redirect)
          : navigate({
              to: '/restaurants/$restaurantId/reservations',
              params: { restaurantId: r.id },
            })
      }
    />
  )
}
