import React from 'react'
import { useTheme } from '@mui/material'

import type Konva from 'konva'
import { Group, Rect, Text } from 'react-konva'

import neutral from 'src/app/theme/colors/neutral'
import { getRelativeCenter } from 'src/entities/floor-plan/services/geometry'
import { useKonvaShapeSize } from 'src/pages/Settings/Tables/FloorPlan/useKonvaShapeSize'
import { useCapacityPosition } from './useCapacityPosition'
import { type FloorPlanTableInterface } from '../types/floorPlanElement'

export const CAPACITY_BG = neutral['500']
const CAPACITY_FONT_COLOR = '#fff'

const CapacityIndicator = ({ table }: { table: FloorPlanTableInterface }) => {
  const capacityRef = React.useRef<Konva.Text | null>(null)
  const capacitySize = useKonvaShapeSize(capacityRef, table.capacity)

  const relativeCenter = getRelativeCenter(capacitySize)

  const position = useCapacityPosition(table)

  const theme = useTheme()

  return (
    <Group {...capacitySize} {...position} listening={false}>
      <Rect
        {...capacitySize}
        x={-relativeCenter.x}
        y={-relativeCenter.y}
        fill={CAPACITY_BG}
        cornerRadius={Number(theme.shape.borderRadius)}
      />
      <Text
        text={table.capacity.toString(10)}
        ref={capacityRef}
        padding={parseInt(theme.spacing(0.75), 10)}
        fill={CAPACITY_FONT_COLOR}
        x={-relativeCenter.x}
        y={-relativeCenter.y}
      />
    </Group>
  )
}

export default CapacityIndicator
