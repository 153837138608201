import { type TFunction } from 'i18next'
import { z, type CustomErrorParams } from 'zod'

import { labelSchema } from 'src/entities/config/types/configApi'
import {
  emptyTranslatedSchema,
  getTranslatedStringSchema,
  limitableChannelsSchema,
  paymentPlanSchema,
  weekdaySchema,
  type BookingOptions,
  type EnabledShiftException,
  type Notification,
  type NotificationTemplate,
  type ScheduleLanguage,
  type Shift,
} from 'src/entities/schedule/types/scheduleApi'
import { durationSchema, timeSchema } from 'src/shared/lib/zod/zod'

export const BookingDeadlineTypes = {
  DayOfReservation: 'day-of-reservation',
  DayBeforeReservation: 'day-before-reservation',
  TimeBeforeReservation: 'time-before-reservation',
} as const

export const formBookingSetupSchema = z.object({
  bookingDuration: durationSchema,
  timeInterval: durationSchema,
  earliestTime: durationSchema,
  bookingDeadline: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(BookingDeadlineTypes.DayOfReservation),
      time: timeSchema,
    }),
    z.object({
      type: z.literal(BookingDeadlineTypes.DayBeforeReservation),
      time: timeSchema,
    }),
    z.object({
      type: z.literal(BookingDeadlineTypes.TimeBeforeReservation),
      interval: durationSchema,
    }),
  ]),
})

export const formReservationGuestCountLimitSchema = z.object({
  min: z.number(),
  max: z.number(),
})

export const formReservationConfirmationSchema = z.object({
  afterDeadline: z.boolean(),
  beyondPaxLimit: z.boolean(),
})

const enabledNotificationsSchema = z.object({
  isActive: z.literal(true),
  templates: z.array(
    z.object({
      type: z.string(),
      channel: z.string(),
      templateId: z.discriminatedUnion('type', [
        z.object({
          type: z.literal('custom'),
          id: z.number(),
        }),
        z.object({ type: z.literal('default') }),
      ]),
    }),
  ),
})

const defaultNotificationTemplateSchema = z.object({
  type: z.string(),
  channel: z.string(),
  templateId: z.unknown().transform(() => ({ type: 'default' as const })),
})

export type DefaultNotificationTemplate = z.infer<
  typeof defaultNotificationTemplateSchema
>

const disabledNotificationsSchema = z.object({
  isActive: z.literal(false),
  templates: z.array(defaultNotificationTemplateSchema),
})

const enabledLabelsSchema = z.object({
  isActive: z.literal(true),
  labelIds: z.array(labelSchema.shape.id),
})

const disabledLabelsSchema = z.object({
  isActive: z.literal(false),
  labelIds: z.unknown().transform(() => [] as number[]),
})

const enabledNoShowFeeSchema = z.object({
  isActive: z.literal(true),
  paymentPlanId: paymentPlanSchema.shape.id,
})

const disabledNoShowFeeSchema = z.object({
  isActive: z.literal(false),
  paymentPlanId: z.unknown().transform(() => null),
})

const channelAllocationSchema = z.object({
  active: z.boolean(),
  limit: z.number(),
  channel: limitableChannelsSchema,
})

export type FormChannelAllocation = z.infer<typeof channelAllocationSchema>

export interface BaseFormSchemaDeps {
  t: TFunction
  defaultLang: ScheduleLanguage
}

export const baseFormSchema = ({ defaultLang, t }: BaseFormSchemaDeps) =>
  z.object({
    labels: z.discriminatedUnion('isActive', [
      enabledLabelsSchema,
      disabledLabelsSchema,
    ]),
    noShowFee: z.discriminatedUnion('isActive', [
      enabledNoShowFeeSchema,
      disabledNoShowFeeSchema,
    ]),
    notifications: z.discriminatedUnion('isActive', [
      enabledNotificationsSchema,
      disabledNotificationsSchema,
    ]),
    customPacing: z.object({
      isActive: z.boolean(),
      pacings: z.array(
        z.object({
          time: timeSchema,
          allocation: z.number(),
        }),
      ),
    }),
    shiftMessage: z.discriminatedUnion('isActive', [
      z.object({
        isActive: z.literal(true),
        message: getTranslatedStringSchema(defaultLang),
      }),
      z.object({
        isActive: z.literal(false),
        message: emptyTranslatedSchema,
      }),
    ]),
    reservationConfirmation: formReservationConfirmationSchema,
    shiftGuestCountLimit: z.number(),
    channelAllocations: z.array(channelAllocationSchema),
    bookingSetup: formBookingSetupSchema,
    reservationGuestCountLimit: formReservationGuestCountLimitSchema,
    weekdays: z
      .array(weekdaySchema)
      .nonempty(
        t(
          'schedule.shifts.general_section.validation.non_empty_weekdays',
          'Please select at least one weekday.',
        ),
      ),
    firstArrivalTime: timeSchema,
    lastArrivalTime: timeSchema,
  })

export type BaseForm = z.infer<ReturnType<typeof baseFormSchema>>

export const channelLimitMaximumEnforcer = <T extends BaseForm>(a: T) => ({
  ...a,
  channelAllocations: a.channelAllocations.map(all => ({
    ...all,
    limit: Math.min(all.limit, a.shiftGuestCountLimit),
  })),
})

const fromFormBookingDeadline = (
  fd: BaseForm['bookingSetup']['bookingDeadline'],
): BookingOptions['deadline'] => {
  if (fd.type === 'day-of-reservation') {
    return { type: 'day-of-reservation', timeOrInterval: fd.time }
  }

  if (fd.type === 'day-before-reservation') {
    return { type: 'day-before-reservation', timeOrInterval: fd.time }
  }

  return { type: fd.type, timeOrInterval: fd.interval }
}

const toFormBookingDeadline = (
  d: BookingOptions['deadline'],
): BaseForm['bookingSetup']['bookingDeadline'] => {
  if (d.type === 'day-of-reservation') {
    return {
      type: 'day-of-reservation',
      time: d.timeOrInterval,
    }
  }

  if (d.type === 'day-before-reservation') {
    return {
      type: 'day-before-reservation',
      time: d.timeOrInterval,
    }
  }

  return {
    type: d.type,
    interval: d.timeOrInterval,
  }
}

export const toBase = <T extends BaseForm>(
  formBase: T,
): EnabledShiftException['shiftConfig'] => ({
  labelIds: formBase.labels.isActive ? formBase.labels.labelIds : [],
  paymentPlanId: formBase.noShowFee.isActive
    ? formBase.noShowFee.paymentPlanId
    : null,
  arrivalTimes: {
    interval: formBase.bookingSetup.timeInterval,
    firstArrivalTime: formBase.firstArrivalTime,
    lastArrivalTime: formBase.lastArrivalTime,
  },
  duration: formBase.bookingSetup.bookingDuration,
  bookingOptions: {
    bookingWindowStart: formBase.bookingSetup.earliestTime,
    deadline: fromFormBookingDeadline(formBase.bookingSetup.bookingDeadline),
  },
  limits: {
    paxLimitPerChannel: Object.fromEntries(
      formBase.channelAllocations.map(a => [
        a.channel,
        { active: a.active, limit: a.limit },
      ]),
    ),
    maxPaxPerReservation: formBase.reservationGuestCountLimit.max,
    maxPaxPerShift: formBase.shiftGuestCountLimit,
    minPaxPerReservation: formBase.reservationGuestCountLimit.min,
  },
  asyncReservationConfirmation: {
    afterDeadline: formBase.reservationConfirmation.afterDeadline,
    beyondPaxLimit: formBase.reservationConfirmation.beyondPaxLimit,
  },
  notifications: formBase.notifications.templates.map(n => ({
    ...n,
    templateId:
      formBase.notifications.isActive && n.templateId.type !== 'default'
        ? n.templateId.id
        : 'default',
  })),
  customPacing: {
    setCustomPacing: formBase.customPacing.isActive,
    slots: formBase.customPacing.pacings.map(p => ({
      time: p.time,
      limit: p.allocation,
    })),
  },
  shiftMessage: formBase.shiftMessage.isActive
    ? {
        displayOnline: true,
        ...formBase.shiftMessage.message,
      }
    : {
        displayOnline: false,
        de: null,
        en: null,
        fr: null,
        it: null,
      },
})

const createNotificationTemplateKey = (t: { type: string; channel: string }) =>
  `${t.type}_${t.channel}`

const hasDifferentNotificationSettings = (
  notifications: Notification[],
  templates: NotificationTemplate[],
) => {
  const templateMap = new Map(
    notifications.map(n => [createNotificationTemplateKey(n), n.templateId]),
  )

  return templates.some(
    n => templateMap.get(createNotificationTemplateKey(n)) !== 'default',
  )
}

export const fromBase =
  (
    weekdays: Shift['weekdays'],
    notificationTemplates: NotificationTemplate[],
  ) =>
  <T extends EnabledShiftException['shiftConfig']>(base: T): BaseForm => {
    const channelAllocationsLimit = Object.entries(
      base.limits.paxLimitPerChannel,
    )
      .map(([channel, { active, limit }]) => ({
        channel: channel as keyof typeof base.limits.paxLimitPerChannel,
        active,
        limit,
      }))
      .sort((a, b) => b.channel.localeCompare(a.channel))

    return {
      weekdays,
      firstArrivalTime: base.arrivalTimes.firstArrivalTime,
      lastArrivalTime: base.arrivalTimes.lastArrivalTime,
      notifications: hasDifferentNotificationSettings(
        base.notifications,
        notificationTemplates,
      )
        ? {
            isActive: true,
            templates: base.notifications.map(n => ({
              ...n,
              templateId:
                n.templateId === 'default'
                  ? { type: 'default' as const }
                  : {
                      type: 'custom' as const,
                      id: n.templateId,
                    },
            })),
          }
        : {
            isActive: false,
            templates: base.notifications.map(n => ({
              ...n,
              templateId: { type: 'default' as const },
            })),
          },
      bookingSetup: {
        timeInterval: base.arrivalTimes.interval,
        bookingDuration: base.duration,
        earliestTime: base.bookingOptions.bookingWindowStart,
        bookingDeadline: toFormBookingDeadline(base.bookingOptions.deadline),
      },
      reservationConfirmation: base.asyncReservationConfirmation,
      channelAllocations: channelAllocationsLimit,
      reservationGuestCountLimit: {
        min: base.limits.minPaxPerReservation,
        max: base.limits.maxPaxPerReservation,
      },
      shiftGuestCountLimit: base.limits.maxPaxPerShift,
      customPacing: {
        isActive: base.customPacing.setCustomPacing,
        pacings: base.customPacing.slots.map(s => ({
          time: s.time,
          allocation: s.limit,
        })),
      },
      shiftMessage: base.shiftMessage.displayOnline
        ? { isActive: true, message: base.shiftMessage }
        : {
            isActive: false,
            message: { de: null, en: null, fr: null, it: null },
          },
      labels: base.labelIds.length
        ? { isActive: true, labelIds: base.labelIds }
        : { isActive: false, labelIds: [] },
      noShowFee: base.paymentPlanId
        ? { isActive: true, paymentPlanId: base.paymentPlanId }
        : { isActive: false, paymentPlanId: null },
    }
  }

export const createArrivalTimeMessage =
  (translationKey: string) => (): CustomErrorParams => ({
    path: ['firstArrivalTime'],
    message: translationKey,
    params: {
      i18n: translationKey,
    },
  })
