import { Chip } from '@mui/material'

import {
  type CustomerInterface,
  type FallbackCustomer,
} from 'src/entities/customer/types/customer'
import CakeIcon from 'src/shared/components/icons/CakeIcon'
import { formatDate } from 'src/shared/lib/range/services/date'

const Birthday = ({
  customer,
}: {
  customer: CustomerInterface | FallbackCustomer
}) => {
  if (!customer.birthdate) return null

  return (
    <Chip
      color="light"
      label={formatDate(customer.birthdate)}
      icon={<CakeIcon size="small" variant="filled" />}
    />
  )
}

export default Birthday
