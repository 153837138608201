import React, { useDeferredValue, useEffect, useMemo } from 'react'
import { isEmpty, juxt, min } from 'ramda'
import { Box, Divider, useMediaQuery, type Theme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useAreasQuery } from 'src/entities/area/queries/area'
import { type ApiAreaInterface } from 'src/entities/area/types/area'
import { useHolidaysQuery } from 'src/entities/holidays/queries/holidays'
import { useRoomStaysPrefetch } from 'src/entities/hotel-stay/queries/hotelStay'
import { useLabelsQuery } from 'src/entities/label/queries/label'
import { useEditReservation } from 'src/entities/reservation/hooks/useEditReservation'
import { useReservation } from 'src/entities/reservation/queries/reservation'
import { useReservationCheckInStatusMutation } from 'src/entities/reservation/queries/reservationsMutations'
import { getCheckInStatus } from 'src/entities/reservation/services/checkInStatus'
import { newOccupancyFromTable } from 'src/entities/reservation/services/occupancyFactory'
import { isInFuture } from 'src/entities/reservation/services/reservation'
import {
  ReservationCheckInStatusEnum,
  ReservationStatusEnum,
  type ReservationInterface,
} from 'src/entities/reservation/types/reservation'
import { useRoomsQuery } from 'src/entities/room/queries/room'
import {
  useTablesNotesQuery,
  useTablesQuery,
} from 'src/entities/table/queries/table'
import { type TableInterface } from 'src/entities/table/types/table'
import CircularProgress from 'src/shared/components/common/CircularProgress'
import { showToast } from 'src/shared/components/common/toast'
import CenteredBox from 'src/shared/components/containers/CenteredBox'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import { IsDateDeferredContext } from 'src/shared/lib/context/global/useIsDateDeferredContext'
import { useCurrentDate } from 'src/shared/lib/context/state/atoms/currentDate'
import { useFilterPresetAtom } from 'src/shared/lib/context/state/atoms/reservationFilters'
import {
  useReservationIdForTableAssignmentAtom,
  useSetReservationIdForTableAssignment,
} from 'src/shared/lib/context/state/atoms/reservationIdForTableAssignment'
import { useSelectedAreaAtom } from 'src/shared/lib/context/state/atoms/selectedArea'
import {
  useIsReservationSelectedAtom,
  useSelectedReservationAtom,
  useSetOpenReservationOccupancies,
} from 'src/shared/lib/context/state/atoms/selectedReservation'
import { useSelectedServiceTime } from 'src/shared/lib/context/state/atoms/selectedServiceTime'
import { useSelectedTableAtom } from 'src/shared/lib/context/state/atoms/selectedTable'
import { useSelectedViewAtom } from 'src/shared/lib/context/state/atoms/selectedView'
import { SidebarDrawer } from 'src/widgets/SidebarDrawer/SidebarDrawer'
import { useAddOccupancy } from './components/FloorPlanView/occupancies'
import {
  useIsTableLockedCallback,
  useTableLocks,
} from './components/FloorPlanView/useTableLocks'
import ModifySerieModal from './components/ModifySerieModal/ModifySerieModal'
import ReservationsContainer from './components/ReservationsContainer'
import { ServiceTimeBar } from './components/ServiceTimeBar'
import { TableAssignDrawer } from './components/TableAssignDrawer/TableAssignDrawer'
import TableDrawer from './components/TableDrawer/TableDrawer'
import TableOccupiedModal from './components/TableOccupiedModal/TableOccupiedModal'
import ViewBar from './components/ViewBar/ViewBar'
import { useAreaChangeEffect } from './hook/useAreaChangeEffect'
import { useGroupedReservations } from './hook/useGroupedReservations'
import { getOccupancy } from './service/occupancy'
import { overlapDateRange } from './service/reservation'
import {
  ReservationActions,
  useReservationActionPermissionChecker,
} from '../ReservationDrawer/Footer/reservationActions'
import { useReservationReactivation } from '../ReservationDrawer/Footer/useReservationReactivation'
import {
  reservationNotSavedDialog,
  tableLockedDialog,
} from '../ReservationDrawer/Occupancies/occupanciesDialogs'
import { ReservationSidebarDrawer } from '../ReservationDrawer/ReservationSidebarDrawer'

export interface ReservationsProps {
  onCreateNewReservation: (slot?: string) => void
  assignSerie: boolean | undefined
  onAssignSerieChange: (assignSerie: boolean) => void
  onReservationDrawerClose: () => void
  reservationChanged: boolean
  restaurantId: number
}

const LEFT_PANEL_WIDTH = 255
const DEFAULT_NEW_RESERVATION_SEAT_COUNT = 2

const Reservations = ({
  onCreateNewReservation,
  onReservationDrawerClose,
  assignSerie,
  onAssignSerieChange,
  reservationChanged,
  restaurantId,
}: ReservationsProps) => {
  const { data: areas } = useAreasQuery()
  const { data: tables } = useTablesQuery()
  const { data: labels } = useLabelsQuery()
  const { data: rooms } = useRoomsQuery()
  const { data: holidays } = useHolidaysQuery()

  const displayDate = useCurrentDate()

  const [selRes] = useSelectedReservationAtom()
  const selectedReservation =
    useIsReservationSelectedAtom() || !isEmpty(selRes) ? selRes : undefined

  const [reservationIdForTableAssignment] =
    useReservationIdForTableAssignmentAtom()
  const { data: reservationForTableAssignment } = useReservation(
    reservationIdForTableAssignment,
  )

  const date = useDeferredValue(displayDate)
  const isDeferred = date !== displayDate
  const serviceTime = useSelectedServiceTime()
  const [rawSelectedArea, setArea] = useSelectedAreaAtom()
  const [view, setView] = useSelectedViewAtom()

  const area = useMemo(
    () => areas.find(a => a.id === rawSelectedArea?.id),
    [areas, rawSelectedArea?.id],
  )

  const {
    activeOccupancies,
    activeReservationsByServiceTime,
    reservationsByFilterPreset,
    shownOccupancies,
    shownOccupanciesByAreaId,
  } = useGroupedReservations(date, selectedReservation)

  useEffect(() => {
    setArea(a => {
      if (a && areas.includes(a as ApiAreaInterface)) return a

      return areas[0]
    })
  }, [areas, setArea])

  const { data: tablesNotes } = useTablesNotesQuery(
    date,
    React.useDeferredValue(serviceTime),
  )

  useRoomStaysPrefetch(date)

  useAreaChangeEffect(selectedReservation, setArea)

  const [filterPreset, setFilterPreset] = useFilterPresetAtom()

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))

  const { t } = useTranslation()

  const [selectedTable, setSelectedTable] = useSelectedTableAtom()
  const handleTableDrawerClose = React.useCallback(
    () => setSelectedTable(undefined),
    [setSelectedTable],
  )

  const tableLocks = useTableLocks(date, serviceTime)
  const isTableLocked = useIsTableLockedCallback(date, serviceTime)

  const tableIsLocked = React.useMemo(
    () => !!selectedTable && isTableLocked(selectedTable),
    [isTableLocked, selectedTable],
  )

  const assignSerieDialog = useDialog()
  const tableOccupiedDialog = useDialog()

  const { showSimpleDialog, simpleDialogProps } = useSimpleDialog()

  const openTableLockedDialog = React.useCallback(() => {
    const { message } = tableLockedDialog(t)
    if (!message) return

    showToast(message, 'error')
  }, [t])

  const openReservationNotSavedDialog = React.useCallback(
    () => showSimpleDialog(reservationNotSavedDialog(t)),
    [showSimpleDialog, t],
  )

  const openReservationLockedDialog = React.useCallback(() => {
    showToast(
      t(
        'reservation_drawer.footer.action_button.locked_tooltip',
        'The reservation is locked',
      ),
      'error',
    )
  }, [t])

  const setReservationIdForTableAssignment =
    useSetReservationIdForTableAssignment()

  const onTablePickerClose = React.useCallback(
    () => setReservationIdForTableAssignment(null),
    [setReservationIdForTableAssignment],
  )

  const addOccupancy = useAddOccupancy({
    onReservationNotCreated: openReservationNotSavedDialog,
    onTableOccupied: tableOccupiedDialog.handleOpen,
    onTableLocked: openTableLockedDialog,
    onReservationLocked: openReservationLockedDialog,
    onAssignSerie: assignSerieDialog.handleOpen,
    getIntersectingOccupancies: React.useCallback(
      (r: ReservationInterface) =>
        activeOccupancies
          .filter(o => overlapDateRange(r, o.reservation))
          .map(getOccupancy),
      [activeOccupancies],
    ),
    tables,
    isTableLocked,
    assignSerie,
    onReservationDrawerClose,
    reservationChanged,
    onTablePickerClose,
  })

  const { init: initAddOccupancy } = addOccupancy

  const tableClickHandler = React.useCallback(
    (table: TableInterface | undefined) => {
      const reservation = reservationForTableAssignment ?? selectedReservation

      if (!reservation) {
        setSelectedTable(table)
        return
      }

      if (!table) return

      if (
        reservation.status === ReservationStatusEnum.Expired ||
        reservation.status === ReservationStatusEnum.Cancelled ||
        reservation.status === ReservationStatusEnum.NoShow
      ) {
        showToast(
          t(
            'reservation_drawer.errors.cancelled_assign_table',
            'You cannot assign a table to a cancelled reservation',
          ),
          'warning',
        )
        return
      }

      void initAddOccupancy(reservation, table).catch(() => {})
    },
    [
      reservationForTableAssignment,
      selectedReservation,
      initAddOccupancy,
      setSelectedTable,
      t,
    ],
  )

  const setOpenReservationOccupancies = useSetOpenReservationOccupancies()

  const createNewReservationHandler = React.useCallback<
    (table?: TableInterface, slot?: string, locked?: boolean) => void
  >(
    (table, slot, locked) => {
      if (locked) {
        openTableLockedDialog()
        return
      }

      handleTableDrawerClose()

      const newOccupancies = table
        ? [
            newOccupancyFromTable(
              table,
              min(DEFAULT_NEW_RESERVATION_SEAT_COUNT, table.capacity),
            ),
          ]
        : []
      setOpenReservationOccupancies(newOccupancies)

      onCreateNewReservation(slot)
    },
    [
      handleTableDrawerClose,
      onCreateNewReservation,
      openTableLockedDialog,
      setOpenReservationOccupancies,
    ],
  )

  const isTableAssignDrawerOpen =
    !!reservationForTableAssignment &&
    (isMobile || view === 'reservations_table')

  const {
    init: initReactivation,
    abandon: abandonReactivation,
    resume: resumeReactivation,
  } = useReservationReactivation({
    onAssignSerie: assignSerieDialog.handleOpen,
  })

  const { mutate: mutateStatus } = useReservationCheckInStatusMutation()

  const editReservation = useEditReservation(restaurantId)

  const checkPermissions = useReservationActionPermissionChecker()

  const reservationButtonHandler = React.useCallback(
    (r: ReservationInterface) => {
      const checkInStatus = getCheckInStatus(r)

      if (checkInStatus === ReservationCheckInStatusEnum.Cancelled) {
        const action = ReservationActions.Reactivate
        if (!checkPermissions(action)) return
        void initReactivation(r, action)
        return
      }
      if (checkInStatus === ReservationCheckInStatusEnum.New) {
        if (r.locked) {
          openReservationLockedDialog()
          return
        }

        setOpenReservationOccupancies(r.occupancies)
        setReservationIdForTableAssignment(r.id)
        return
      }
      if (
        checkInStatus === ReservationCheckInStatusEnum.CheckedIn ||
        checkInStatus === ReservationCheckInStatusEnum.Pending ||
        checkInStatus === ReservationCheckInStatusEnum.CheckedOut
      ) {
        if (isInFuture(r, 'day')) return

        const newStatus =
          checkInStatus === ReservationCheckInStatusEnum.CheckedIn
            ? ReservationCheckInStatusEnum.CheckedOut
            : ReservationCheckInStatusEnum.CheckedIn

        mutateStatus({ reservation: r, status: newStatus })
        return
      }

      void editReservation(r)
    },
    [
      editReservation,
      checkPermissions,
      initReactivation,
      setOpenReservationOccupancies,
      setReservationIdForTableAssignment,
      openReservationLockedDialog,
      mutateStatus,
    ],
  )

  return (
    <>
      <Divider />
      <ServiceTimeBar reservations={activeReservationsByServiceTime} />
      {!view && (
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      )}
      {!!view && (
        <IsDateDeferredContext.Provider value={isDeferred}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: [
                'auto 0',
                `${LEFT_PANEL_WIDTH}px calc(100% - ${LEFT_PANEL_WIDTH}px)`,
              ],
              gridTemplateRows: 'auto 1fr',
              width: 1,
              height: 1,
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <TableDrawer
              occupancies={shownOccupancies}
              table={selectedTable}
              locked={tableIsLocked}
              tableNotes={tablesNotes}
              onClose={handleTableDrawerClose}
              areas={areas}
              tables={tables}
              labels={labels}
              rooms={rooms}
              onReservationClick={editReservation}
              onReservationButtonClick={reservationButtonHandler}
              open={!!selectedTable}
              onCreateNewReservation={createNewReservationHandler}
              date={date}
            />
            <SidebarDrawer
              onClose={onTablePickerClose}
              open={isTableAssignDrawerOpen}
              PaperProps={{ sx: { backgroundColor: 'grey.25' } }}
            >
              <TableAssignDrawer
                onClose={onTablePickerClose}
                areas={areas}
                tables={tables}
                occupancies={activeOccupancies}
                tableLocks={tableLocks}
                selectedReservation={reservationForTableAssignment ?? undefined}
                onTableSelect={tableClickHandler}
              />
            </SidebarDrawer>
            <ReservationSidebarDrawer />
            <ViewBar
              areas={areas}
              area={area}
              currentDate={displayDate}
              holidays={holidays}
              reservationsByFilterPreset={reservationsByFilterPreset}
              occupanciesByArea={shownOccupanciesByAreaId}
              onAreaChange={setArea}
              view={view}
              onViewChange={setView}
              preset={filterPreset}
              onPresetChange={setFilterPreset}
              onCreateNewReservation={createNewReservationHandler}
            />
            <ReservationsContainer
              reservations={activeReservationsByServiceTime}
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              occupancies={shownOccupanciesByAreaId.get(area?.id!) ?? []}
              filteredReservations={
                reservationsByFilterPreset.get(filterPreset) ?? []
              }
              selectedReservation={selectedReservation}
              tables={tables}
              date={displayDate}
              onReservationClick={editReservation}
              onCreateNewReservation={createNewReservationHandler}
              area={area}
              currentView={view}
              areas={areas}
              onReservationButtonClick={reservationButtonHandler}
              filterPreset={filterPreset}
              onTableSelect={tableClickHandler}
              selectedTableId={selectedTable?.id}
              tableLocks={tableLocks}
            />
          </Box>
        </IsDateDeferredContext.Provider>
      )}
      <TableOccupiedModal
        open={tableOccupiedDialog.open}
        onClose={tableOccupiedDialog.handleClose}
        onOverbook={() => addOccupancy.resume({ overbook: true })}
      />
      <ModifySerieModal
        open={assignSerieDialog.open}
        onClose={assignSerieDialog.handleClose}
        onDismiss={juxt([abandonReactivation, addOccupancy.abandon])}
        onAssign={decision => {
          onAssignSerieChange(decision)
          juxt([resumeReactivation, addOccupancy.resume])({
            wholeSerie: decision,
          })
        }}
      />
      <SimpleDialog {...simpleDialogProps} />
    </>
  )
}

export default Reservations
