import { compose, nthArg } from 'ramda'
import { Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  hasEmail,
  hasPhoneNumber,
} from 'src/entities/reservation/services/reservation'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import ReservationDrawerCheckbox from 'src/shared/components/form/inputs/ReservationDrawerCheckbox'

interface CommunicationCheckboxesProps {
  reservation: ReservationInterface
  emailChecked: boolean
  onEmailCheckedChange: (checked: boolean) => void
  smsChecked: boolean
  onSmsCheckedChange: (checked: boolean) => void
}

const CommunicationCheckboxes = ({
  onSmsCheckedChange,
  onEmailCheckedChange,
  smsChecked,
  emailChecked,
  reservation,
}: CommunicationCheckboxesProps) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" spacing={1}>
      {hasEmail(reservation) && (
        <ReservationDrawerCheckbox
          label={t('angular.email')}
          checked={emailChecked}
          onChange={compose(onEmailCheckedChange, nthArg(1))}
        />
      )}
      {hasPhoneNumber(reservation) && (
        <ReservationDrawerCheckbox
          label={t('angular.sms')}
          checked={smsChecked}
          onChange={compose(onSmsCheckedChange, nthArg(1))}
        />
      )}
    </Stack>
  )
}

export { CommunicationCheckboxes }
