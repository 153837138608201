/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from 'react'

import { useParams } from '@tanstack/react-router'

import { isBackoffice } from 'src/app/apps/backoffice/isBackoffice'
import { useMeQuery } from 'src/entities/me/queries/me'

export const useRestaurantHash = () => {
  if (isBackoffice()) return window.appConfig!.restaurantHash ?? ''

  const {
    data: { restaurants },
  } = useMeQuery()
  const { restaurantId } = useParams({ strict: false })

  return useMemo(
    () => restaurants.find(r => r.id === restaurantId)?.hash ?? '',
    [restaurantId, restaurants],
  )
}
