import { Grid2 } from '@mui/material'

import { useTranslation } from 'react-i18next'

import IconButton from 'src/shared/components/buttons/IconButton'
import SearchInput from 'src/shared/components/form/inputs/SearchInput'
import CloseIcon from 'src/shared/components/icons/CloseIcon'

const Header = ({
  onSearchPhraseChange,
  initialSearchPhrase,
  onClose,
}: {
  onSearchPhraseChange: (searchPhrase: string) => void
  initialSearchPhrase?: string
  onClose: () => void
}) => {
  const { t } = useTranslation()

  const placeholder = t('search.placeholder', {
    defaultValue: 'Find guests or reservations',
    tDescription: 'Searchbox placeholder',
  })
  return (
    <Grid2
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid2 size="grow">
        <SearchInput
          onSearch={onSearchPhraseChange}
          initialValue={initialSearchPhrase}
          placeholder={placeholder}
        />
      </Grid2>
      <Grid2
        size="grow"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton
          size="medium"
          variant="text"
          color="neutral"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Grid2>
    </Grid2>
  )
}

export default Header
