import { type ScheduleEvent } from 'src/entities/schedule/types/scheduleApi'
import { type useShortDateRangeFormatter } from 'src/shared/lib/range/hooks/useFormattedDate'
import { isNotFalsy } from 'src/shared/lib/zod/zod'
import {
  shiftLikePropertyGetters as slg,
  type GetShiftLikePropertiesParams,
} from '../ShiftSection/service'

interface GetScheduleEventPropertiesParams
  extends Omit<GetShiftLikePropertiesParams, 'shiftLike'> {
  shiftLike: ScheduleEvent
  formatDateRange: ReturnType<typeof useShortDateRangeFormatter>
}

export const getScheduleEventProperties = (
  p: GetScheduleEventPropertiesParams,
) =>
  [
    slg.getRoomName(p),
    p.formatDateRange([
      p.shiftLike.effectivePeriod.start,
      p.shiftLike.effectivePeriod.end,
    ]),
    slg.getWeekdays(p),
    slg.getArrivalTimes(p),
    slg.getBookingDeadline(p),
    slg.getMaxAllocationsPerShift(p),
    slg.getCustomPacing(p),
    slg.getIvrActive(p),
    slg.getGuestLimit(p),
    slg.getPaymentMethod(p),
  ].filter(isNotFalsy)
