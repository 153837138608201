/* eslint-disable react-hooks/rules-of-hooks */
import { compose, identical, useWith } from 'ramda'

import { type TimeRangeAware } from 'src/shared/lib/range/types/timeRange'
import { getServiceTimeOfTime } from './serviceTime'
import { type SlotInterface } from '../../availability/types/availability'

const getSlotTime = (slot: SlotInterface | undefined) => slot?.time

export const getShiftOfSlot = <T extends TimeRangeAware>(shifts: T[]) =>
  compose(getServiceTimeOfTime(shifts), getSlotTime)

export const haveTheSameShift = <T extends TimeRangeAware>(shifts: T[]) =>
  useWith<SlotInterface, T | undefined, SlotInterface, T | undefined, boolean>(
    identical,
    [getShiftOfSlot(shifts), getShiftOfSlot(shifts)],
  )
