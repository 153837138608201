import React from 'react'
import { append, prop } from 'ramda'

import { useMutation, useSuspenseQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { crudQueriesFactory } from 'src/shared/lib/api/queries/crudQueriesFactory'
import { useOptimisticUpdate } from 'src/shared/lib/api/queries/optimisticUpdate'
import { useRestaurantCacheKeyFactory } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import {
  crudApiFactory,
  type RequestUrlsInterface,
  type ResponseHandlersInterface,
} from 'src/shared/lib/api/services/crudApiFactory'
import { replaceBy } from 'src/shared/lib/common/services/functional/functional'
import {
  formatTime,
  getNowInRestaurantTimezone,
} from 'src/shared/lib/range/services/date'
import { type TimeRangeAware } from 'src/shared/lib/range/types/timeRange'
import { CONFIG_CACHE_KEY, useConfigQuery } from '../../config/queries/config'
import type {
  ApiConfig,
  ServiceTimeInterface,
} from '../../config/types/configApi'
import {
  getServiceTimesMessages,
  setServiceTimeMessage,
} from '../api/serviceTimeApi'
import { getServiceTimeOfTime } from '../services/serviceTime'
import { type ServiceTimeMessage } from '../types/serviceTime'

const urls: RequestUrlsInterface = {
  set: 'addTimeRange',
  delete: 'deleteTimeRange',
}

const payloadFactory = (serviceTime: ServiceTimeInterface) => ({
  time_range_id: serviceTime.id,
  name: serviceTime.name,
  start_time: formatTime(serviceTime.timeRange[0]),
  end_time: formatTime(serviceTime.timeRange[1]),
})

const handlers: ResponseHandlersInterface<
  ServiceTimeInterface,
  ReturnType<typeof payloadFactory>
> = {
  set: prop('time_range_id'),
}

const areaApi = crudApiFactory(urls, handlers, payloadFactory)
const queryHooks = crudQueriesFactory(CONFIG_CACHE_KEY, areaApi)

const serviceTimesQueryOptions = {
  select: (d: ApiConfig) => d.time_ranges,
}

export const useServiceTimesQuery = () =>
  useConfigQuery(serviceTimesQueryOptions)

export const useServiceTimeDeleteMutation = queryHooks.useItemDeleteMutation
export const useServiceTimeUpdateMutation = queryHooks.useItemUpdateMutation

const defaultDate = undefined
export const useCurrentServiceTime = <T extends TimeRangeAware>(
  serviceTimes: T[],
  date: Date | undefined = defaultDate,
) =>
  React.useMemo(
    () =>
      getServiceTimeOfTime(serviceTimes)(
        date ?? getNowInRestaurantTimezone(),
      ) ?? null,
    [serviceTimes, date],
  )

const SERVICE_TIME_MESSAGES_CACHE_KEY = ['serviceTimeMessages']
const useServiceTimeMessagesCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(SERVICE_TIME_MESSAGES_CACHE_KEY)

export const useServiceTimeMessagesCacheKey = () =>
  useServiceTimeMessagesCacheKeyFactory()()

export const useServiceTimeMessageMutation = () => {
  const apiClient = useApiClient()
  const optimisticUpdate = useOptimisticUpdate<ServiceTimeMessage[]>()
  const cacheKeyFactory = useServiceTimeMessagesCacheKeyFactory()

  return useMutation({
    mutationFn: setServiceTimeMessage(apiClient.post),
    onSuccess: (_data, payload) =>
      optimisticUpdate(cacheKeyFactory([payload.date]), messages =>
        replaceBy(
          m => m.id,
          payload.message,
          messages,
          append(payload.message),
        ),
      ),
  })
}

export const useServiceTimeMessagesQuery = (date: Date) => {
  const apiClient = useApiClient()
  const cacheKeyFactory = useServiceTimeMessagesCacheKeyFactory()

  return useSuspenseQuery({
    queryKey: cacheKeyFactory([date]),
    queryFn: () => getServiceTimesMessages(apiClient.post)(date),
    staleTime: 15 * 60 * 1000,
    refetchInterval: 15 * 60 * 1000,
  })
}
