import { Button, Stack } from '@mui/material'

import { getTableColor } from 'src/entities/floor-plan/services/floorPlanElement'
import { type getTableStatusFlags } from 'src/entities/reservation/services/checkInStatus'
import { sumSeatCount } from 'src/entities/reservation/services/occupancy'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { occupies } from 'src/entities/table/services/table'
import { type TableInterface } from 'src/entities/table/types/table'
import CheckmarkIcon from 'src/shared/components/icons/CheckmarkIcon'
import { ReservationSeatCount } from 'src/widgets/Tables/SlotInfo/ReservationSeatCount'
import TableCapacity from 'src/widgets/TabularTables/TableItemInfo/TableCapacity'
import EllipsisTypography from '../../../../Settings/Areas/EllipsisTypography'
import pattern from '../../TabularView/Item/pattern.png'

interface TableAssignDrawerTableProps {
  table: TableInterface
  flags: ReturnType<ReturnType<typeof getTableStatusFlags>>
  selectedReservation: ReservationInterface | undefined
  onTableSelect: (tableId: TableInterface) => void
}

export const TableAssignDrawerTable = ({
  table,
  flags,
  selectedReservation,
  onTableSelect,
}: TableAssignDrawerTableProps) => {
  const { isSelected, isLocked } = flags
  const assignedPaxCount = (selectedReservation?.occupancies ?? [])
    .filter(occupies(table))
    .reduce(sumSeatCount, 0)

  return (
    <Button
      onClick={() => onTableSelect(table)}
      variant="outlined"
      sx={{
        backgroundColor: getTableColor(flags),
        justifyContent: 'space-between',
        typography: 'labelSmall',
        fontWeight: 'normal',
        px: 0.5,
        height: 40,
        ...(isLocked && {
          backgroundImage: `url('${pattern}')`,
          backgroundSize: 300,
        }),
      }}
      size="small"
    >
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Stack
          direction="row"
          spacing={0.5}
          sx={{ overflow: 'hidden', alignItems: 'center' }}
        >
          {isSelected && <CheckmarkIcon />}
          {isSelected && (
            <ReservationSeatCount color="neutral" label={assignedPaxCount} />
          )}
          <EllipsisTypography sx={{ minWidth: 0 }}>
            {table.name}
          </EllipsisTypography>
        </Stack>
        <TableCapacity capacity={table.capacity} />
      </Stack>
    </Button>
  )
}
