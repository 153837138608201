import React from 'react'

import type Konva from 'konva'
import { Text } from 'react-konva'

import { getNameLabelPosition } from 'src/entities/table/services/tableGrid'
import { type FloorPlanTableInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'
import { useKonvaShapeSize } from '../../useKonvaShapeSize'

const TEXT_COLOR = '#212529'

const NameLabel = ({ table }: { table: FloorPlanTableInterface }) => {
  const labelRef = React.useRef<Konva.Text>(null)
  const labelSize = useKonvaShapeSize(labelRef, table.name)

  return (
    <Text
      listening={false}
      text={table.name}
      fill={TEXT_COLOR}
      ref={labelRef}
      {...getNameLabelPosition(table, labelSize)}
    />
  )
}

export default NameLabel
