import { Suspense } from 'react'
import { Stack } from '@mui/material'

import { type ReactNode } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import { useIsLatestVersion } from 'src/entities/version/queries/version'
import { PrintPreviewHeader } from 'src/pages/Reservations/components/PrintPreviewHeader'
import { TopBar } from 'src/pages/Reservations/components/TopBar/TopBar'
import AppUpdateIcon from 'src/shared/components/icons/AppUpdateIcon'
import NoConnectionIcon from 'src/shared/components/icons/NoConnectionIcon'
import { useIsOnline } from 'src/shared/lib/context/state/atoms/isOnline'
import { NotifyBar } from 'src/widgets/NotifyBar/NotifyBar'

interface ReservationsLayoutProps {
  restaurantId: number
  children: ReactNode
}

export const ReservationsLayout = ({
  children,
  restaurantId,
}: ReservationsLayoutProps) => {
  const { t } = useTranslation()

  const isOnline = useIsOnline()
  const isLatestVersion = useIsLatestVersion()

  return (
    <Stack direction="column" sx={{ width: 1 }}>
      <Suspense>
        <PrintPreviewHeader />
      </Suspense>
      <NotifyBar
        isVisible={!isOnline}
        variant="error"
        icon={NoConnectionIcon}
        messageI18nKey="common.errors.offline"
        messageI18nDefaultValue="<0>No internet connection:</0> It looks like your device is currently offline. Any actions you perform will be lost."
      />
      <NotifyBar
        isVisible={!isLatestVersion}
        variant="info"
        icon={AppUpdateIcon}
        messageI18nKey="outdated_app_version.disclaimer"
        messageI18nDefaultValue="<0>Application update:</0> A new version of the application is available. Reload the app to install the update."
        buttonText={t('outdated_app_version.button', 'Update & reload')}
        onButtonClick={() => window.location.reload()}
      />
      <TopBar restaurantId={restaurantId} />
      {children}
    </Stack>
  )
}
