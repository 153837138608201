import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type PersistedLabelInterface } from 'src/entities/label/types/label'
import Item from './Item'

const Selection = ({
  labels,
  selectedLabels,
  onSelectedLabelsChange,
}: {
  labels: PersistedLabelInterface[]
  selectedLabels: PersistedLabelInterface[]
  onSelectedLabelsChange: (labels: PersistedLabelInterface[]) => void
}) => {
  const { t } = useTranslation('', { keyPrefix: 'serial_reservations' })

  return (
    <Stack direction="column" spacing={2}>
      <Typography typography="labelExtrasmall" color="grey.300">
        {t('availableLabels')}
      </Typography>
      {labels.map(label => (
        <Item
          label={label}
          key={label.id}
          selectedLabels={selectedLabels}
          onLabelsChange={onSelectedLabelsChange}
        />
      ))}
    </Stack>
  )
}

export default Selection
