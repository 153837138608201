import {
  Chip,
  chipClasses,
  Stack,
  type ChipProps,
  type StackProps,
} from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import { toggleBy } from '../../../../lib/common/services/functional/functional'
import CheckmarkIcon from '../../../icons/CheckmarkIcon'
import { FakeLabel } from '../../common/FakeLabel/FakeLabel'
import FormErrorText from '../../common/FormErrorText/FormErrorText'
import type { MultiSelectDropdownProps } from '../MultiSelectDropdown/props'

interface ChipChoiceProps<T>
  extends Omit<
    MultiSelectDropdownProps<T>,
    'open' | 'onOpen' | 'onClose' | 'palette' | 'renderItem'
  > {
  disabledItems?: T[]
}

const ChipChoice = <T,>({
  value,
  availableSelection,
  disabledItems,
  itemToString,
  onChange,
  label,
  disabled,
  error,
  chipSx,
  ...props
}: ChipChoiceProps<T> &
  Omit<StackProps, 'onChange'> & {
    error?: string
    chipSx?: ChipProps['sx']
  }) => (
  <Stack direction="column" spacing={0.5} {...props}>
    <Stack direction="column">
      {label && <FakeLabel>{label}</FakeLabel>}
      <Stack
        direction="row"
        gap={0.5}
        sx={{ alignItems: 'center', flexWrap: 'wrap' }}
      >
        {availableSelection.map(valueItem => {
          const selected = value.includes(valueItem)

          return (
            <Chip
              key={itemToString(valueItem)}
              disabled={!!disabled || disabledItems?.includes(valueItem)}
              color={selected ? 'primary' : 'paper'}
              label={itemToString(valueItem)}
              icon={selected ? <CheckmarkIcon size="small" /> : undefined}
              onClick={() => onChange(toggleBy(itemToString, valueItem, value))}
              sx={mergeSx(
                {
                  textTransform: 'capitalize',
                  flex: 1,
                  maxWidth: 'max-content',
                  [`&.${chipClasses.filled}`]: {
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: selected ? 'primaryPalette.500' : 'border',
                    [`&.${chipClasses.disabled}`]: {
                      borderColor: 'border',
                      backgroundColor: selected ? 'grey.400' : 'grey.100',
                      color: selected ? 'grey.100' : 'grey.400',
                    },
                  },
                },
                chipSx,
              )}
            />
          )
        })}
      </Stack>
    </Stack>
    <FormErrorText error={!!error} helperText={error} />
  </Stack>
)

export { ChipChoice }
