import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'
import { type FloorPlanTableInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'

const Name = ({
  onChange,
}: {
  onChange: (table: FloorPlanTableInterface) => void
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useFormContext<FloorPlanTableInterface>()

  const { t } = useTranslation('', { keyPrefix: 'settings.tables' })

  return (
    <FormTextInput
      required
      {...register('name', {
        onChange: handleSubmit(onChange),
        required: true,
      })}
      errorText={errors?.name?.message}
      error={!!errors?.name}
      label={t('tableName')}
    />
  )
}

export default Name
