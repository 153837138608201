import type { PhoneCode } from 'src/entities/locale/types/localeApi'
import { stripNonDigits } from 'src/shared/lib/string/services/phoneNumber'

export const getFullValue = (phoneCode: PhoneCode) =>
  `${phoneCode.code} (${phoneCode.country})`
export const formatLabel = (phoneCode: PhoneCode) => phoneCode.code
export const filterOptions = (options: PhoneCode[], inputValue: string) => {
  const digitInput = stripNonDigits(inputValue)
  const lowerCaseInput = inputValue.toLowerCase()

  return options.filter(option => {
    const digitCode = stripNonDigits(option.code)
    const lowerCaseCountry = option.country.toLowerCase()

    return (
      lowerCaseCountry.startsWith(lowerCaseInput) ||
      (digitInput.length && digitCode.startsWith(digitInput))
    )
  })
}
