import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type TeamMemberUpdateInterface } from 'src/entities/team-member/types/teamMember'
import { PasswordInput } from 'src/shared/components/form/inputs/PasswordInput/PasswordInput'

const Pin = ({
  required,
  name,
  label,
}: {
  required: boolean
  name: keyof TeamMemberUpdateInterface
  label: string
}) => {
  const { t } = useTranslation()

  const {
    register,
    formState: { errors, defaultValues },
  } = useFormContext<TeamMemberUpdateInterface>()

  return (
    <PasswordInput
      required={required}
      error={!!errors[name]}
      errorText={errors[name]?.message}
      label={label}
      autoComplete="one-time-code"
      slotProps={{
        htmlInput: {
          inputMode: 'numeric',
          pattern: '[0-9]{6}',
        },
      }}
      {...register(name, {
        required: {
          value: !defaultValues?.pinRequired,
          message: t('form_validation.required'),
        },
        pattern: {
          value: /^\d{6}$/,
          message: t(
            'settings.team_members.form.errors.pin_stylistic_error',
            'PIN code has to be a 6-digit number',
          ),
        },
        setValueAs: (v: string) => v || undefined,
      })}
    />
  )
}

export default Pin
