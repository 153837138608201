import { DragIndicator } from '@mui/icons-material'
import { Paper, Stack } from '@mui/material'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { type ApiAreaInterface } from 'src/entities/area/types/area'
import Area from './Area'

interface Props {
  area: ApiAreaInterface
  onSelect: (area: ApiAreaInterface) => void
}

const Item = ({ area, onSelect }: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: area.id })

  return (
    <Paper
      variant="outlined"
      sx={{
        opacity: isDragging ? 0.5 : 1,
        p: 1,
        cursor: 'pointer',
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      onClick={() => onSelect(area)}
      ref={setNodeRef}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Stack sx={{ cursor: 'move' }} {...attributes} {...listeners}>
          <DragIndicator />
        </Stack>
        <Area area={area} />
      </Stack>
    </Paper>
  )
}

export default Item
