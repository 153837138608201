import { z } from 'zod'

import { restaurantSchema } from 'src/entities/restaurant/types/restaurant'

export const meSchema = z.object({
  defaultRestaurant: restaurantSchema,
  restaurants: z.array(restaurantSchema),
  superadmin: z.boolean(),
})

export type Me = z.infer<typeof meSchema>
