import { useEffect } from 'react'

import { setUser } from '@sentry/react'

import { useRestaurantHash } from './useRestaurantHash'

export const useSetSentryUserEffect = () => {
  const restaurantHash = useRestaurantHash()

  useEffect(() => {
    if (!restaurantHash) return

    setUser({
      id: restaurantHash,
    })
  }, [restaurantHash])
}
