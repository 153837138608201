import { useActiveTeamMemberAtom } from 'src/shared/lib/context/state/atoms/activeTeamMember'
import { useTeamMembersQuery } from '../queries/teamMember'

const useCurrentTeamMember = () => {
  const { data: teamMembers } = useTeamMembersQuery()
  const [teamMemberId] = useActiveTeamMemberAtom()

  return teamMembers.find(teamMember => teamMember.id === teamMemberId)
}

export default useCurrentTeamMember
