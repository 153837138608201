import { Button, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import NoteIcon from 'src/shared/components/icons/NoteIcon'

interface NoteProps {
  note: string
  onClick: () => void
}

const NotePreview = ({ note, onClick }: NoteProps) => {
  const { t } = useTranslation()

  const buttonLabel = note
    ? t('common.form_buttons.editButtonLabel', {
        defaultValue: 'Edit',
        tDescription: 'Edit button label',
      })
    : t('common.form_buttons.addButtonLabel', {
        defaultValue: 'Add',
        tDescription: 'Add button label',
      })

  return (
    <Stack direction="column" spacing={1} sx={{ color: 'grey.700' }}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={0.5} alignItems="center">
          <NoteIcon variant="filled" />
          <Typography variant="labelSmall">
            {t('angular.table_note')}
          </Typography>
        </Stack>
        <Button color="primary" variant="text" onClick={onClick}>
          {buttonLabel}
        </Button>
      </Stack>
      {!!note && <Typography variant="body2">{note}</Typography>}
    </Stack>
  )
}

export default NotePreview
