import ClipboardIcon from 'src/shared/components/icons/ClipboardIcon'
import Preference from './Preference'

const Comment = ({ preference }: { preference: string }) => (
  <Preference
    text={preference}
    icon={<ClipboardIcon size="small" variant="filled" />}
  />
)

export default Comment
