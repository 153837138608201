import React from 'react'
import { juxt } from 'ramda'

import { type CustomerInterface } from 'src/entities/customer/types/customer'
import { useNewReservation } from 'src/entities/reservation/hooks/useNewReservation'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { type SelectedReservation } from 'src/shared/lib/context/state/atoms/baseSelectedReservation'
import { useSetSelectedReservationGuest } from 'src/shared/lib/context/state/atoms/selectedReservation'
import Guest from './Guest'
import { CustomerEdit } from '../../CustomerEdit'
import { CustomerPreviewModal } from '../../CustomerPreview/PreviewModal'

const GuestView = ({
  selectedReservation,
}: {
  selectedReservation: SelectedReservation
}) => {
  const onCustomerSelect = useSetSelectedReservationGuest()
  const onCreateReservation = useNewReservation()

  const editDialog = useDialog()
  const previewDialog = useDialog()

  const [searchPhrase, setSearchPhrase] = React.useState('')

  const [editCustomerId, setEditCustomerId] =
    React.useState<CustomerInterface['id']>()

  if (!selectedReservation) return null

  const {
    guest: { customerId },
  } = selectedReservation

  return (
    <>
      <Guest
        reservation={selectedReservation}
        onCustomerChange={onCustomerSelect}
        onPreviewOpen={previewDialog.handleOpen}
        onCreateNewCustomer={juxt([editDialog.handleOpen, setSearchPhrase])}
        onEditCustomer={c => {
          setEditCustomerId(c.id)
          editDialog.handleOpen()
        }}
      />
      <CustomerEdit
        customerId={editCustomerId ?? customerId ?? undefined}
        onCustomerSaved={juxt([
          onCustomerSelect,
          () => setEditCustomerId(undefined),
          editDialog.handleClose,
        ])}
        hasBeenOpened={editDialog.hasBeenOpened}
        isOpen={editDialog.open}
        onClose={juxt([
          () => setEditCustomerId(undefined),
          editDialog.handleClose,
        ])}
        initPhrase={searchPhrase}
      />
      {customerId && (
        <CustomerPreviewModal
          onCustomerEdit={juxt([
            editDialog.handleOpen,
            previewDialog.handleClose,
          ])}
          onAddReservation={c => onCreateReservation({ customer: c })}
          onClose={previewDialog.handleClose}
          customerId={customerId}
          isOpen={previewDialog.open}
          hasBeenOpened={previewDialog.hasBeenOpened}
        />
      )}
    </>
  )
}

export { GuestView }
