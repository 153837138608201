import { styled } from '@mui/material'
import { pickersDayClasses } from '@mui/x-date-pickers'

import RangePickerItem from '../../RangePickerItem'

const Day = styled(RangePickerItem)(({ theme }) =>
  theme.unstable_sx({
    height: 32,
    width: 32,
    [`&.${pickersDayClasses.hiddenDaySpacingFiller}`]: {
      m: 0,
      borderWidth: 0,
      height: 32,
      width: 32,
      [`&:not(.${pickersDayClasses.selected})`]: {
        borderWidth: 0,
      },
    },
  }),
)
export default Day
