import React from 'react'
import { Alert } from '@mui/material'

import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  useDefaultSettingsQuery,
  useEditDefaultSettings,
} from 'src/entities/schedule/queries/schedule'
import {
  defaultSettingsFormSchema,
  fromFormDefaultSettings,
  toFormDefaultSettings,
  type FormDefaultSettings,
} from './defaultSettingsFormSchema'
import FormContainer from '../FormContainer'
import FormSection from '../FormSection'
import { useDefaultMutationHandlers } from '../hooks/useDefaultMutationHandlers'
import EarliestTime from '../ShiftForm/components/BookingSection/components/EarliestTime'
import { sectionKeyToLabel } from '../ShiftForm/components/FormNavigation/FormNavigation'
import Duration from '../ShiftForm/components/GeneralSection/components/Duration'
import Interval from '../ShiftForm/components/GeneralSection/components/Interval'
import IvrTemplateSection from '../ShiftForm/components/Ivr/IvrTemplateSection'
import GuestRange from '../ShiftForm/components/LimitsSection/components/GuestRange/GuestRange'

interface ShiftTemplateFormProps {
  onNavigateBack: () => void
}

const ShiftTemplateForm = ({ onNavigateBack }: ShiftTemplateFormProps) => {
  const { t } = useTranslation()

  const { data: defaultSettings } = useDefaultSettingsQuery()

  const handlers = useDefaultMutationHandlers(onNavigateBack)

  const { mutateAsync: edit } = useEditDefaultSettings(handlers)

  const formRef = React.useRef<HTMLDivElement | null>(null)

  const submitHandler = (formShift: FormDefaultSettings) =>
    edit(fromFormDefaultSettings(formShift))

  const form = useForm({
    shouldFocusError: false,
    defaultValues: toFormDefaultSettings(defaultSettings),
    resolver: zodResolver(defaultSettingsFormSchema),
  })

  return (
    <FormContainer
      title={t(
        'schedule.shift_template_settings.title',
        'Shift template settings',
      )}
      onNavigateBack={onNavigateBack}
      onSave={form.handleSubmit(submitHandler, () =>
        formRef.current?.scrollIntoView(),
      )}
      error={!!Object.keys(form.formState.errors).length}
    >
      <Alert severity="info">
        {t(
          'schedule.shift_template_settings.disclaimer',
          'These settings will apply to newly created shifts. Any changes made here will not affect already created shifts.',
        )}
      </Alert>
      <FormProvider {...form}>
        <FormSection
          title={t(
            'schedule.shift_template_settings.time_settings',
            'Time settings',
          )}
        >
          <Interval />
          <Duration />
          <EarliestTime />
        </FormSection>
        <FormSection title={sectionKeyToLabel(t)('limits')}>
          <GuestRange />
        </FormSection>
        <IvrTemplateSection />
      </FormProvider>
    </FormContainer>
  )
}

export default ShiftTemplateForm
