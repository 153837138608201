import { type PropsWithChildren } from 'react'
import { Box, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

export const SelectableResolutionGrid = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation()
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '256px auto',
        gap: 2,
        py: 1.5,
        px: 2,
        borderRadius: 1,
        bgcolor: 'grey.25',
      }}
    >
      <Typography variant="labelExtrasmall" color="grey.500">
        {t(
          'schedule_conflict_resolution.resolutions.conflicts_with',
          'Conflicts with:',
        )}
      </Typography>
      <Typography variant="labelExtrasmall" color="grey.500">
        {t('schedule_conflict_resolution.resolutions.solution', 'Solution:')}
      </Typography>
      {children}
    </Box>
  )
}
