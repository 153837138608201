import { type GuestInterface } from '../../reservation/types/reservation'
import {
  type CustomerInterface,
  type FallbackCustomer,
} from '../types/customer'

export const getDisplayName = (
  customer: CustomerInterface | FallbackCustomer | GuestInterface | undefined,
): string =>
  [
    (customer as CustomerInterface)?.title,
    customer?.lastName,
    customer?.firstName,
  ]
    .filter(Boolean)
    .join(' ')
