import { parsePhoneNumberFromString } from 'libphonenumber-js/max'

const fallbackCountryCode = 'CH'

const parsePhoneNumber = (phoneNumber: string) =>
  parsePhoneNumberFromString(phoneNumber, fallbackCountryCode)

export const formatPhoneNumber = (phoneNumber: string) => {
  const number = parsePhoneNumberFromString(phoneNumber, fallbackCountryCode)

  if (!number) return undefined

  return number.formatInternational()
}

export const isValidPhoneNumber = (phoneNumber: string) => {
  const number = parsePhoneNumber(phoneNumber)

  if (!number) return undefined

  return number.isValid()
}

export const getCountryCode = (phoneNumber: string) => {
  const number = parsePhoneNumber(phoneNumber)

  if (!number) return undefined

  return number.countryCallingCode
}

export const getNationalNumber = (phoneNumber: string) => {
  const number = parsePhoneNumber(phoneNumber)

  if (!number) return undefined

  return number.nationalNumber
}

export const stripNonDigits = (string: string) => string.replaceAll(/\D/g, '')

export const isInternational = (phoneNo: string | undefined) =>
  phoneNo?.startsWith?.('+')

const phoneRegex = /^(\d\s?){5,14}$/
const internationalPhoneRegex = /^\+(\d\s?)*$/

export const couldBecomePhoneNumber = (stringToTest: string) =>
  phoneRegex.test(stringToTest) || internationalPhoneRegex.test(stringToTest)
