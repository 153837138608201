import { Button, ButtonGroup, Stack } from '@mui/material'

import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { areOccupyingTable } from 'src/entities/table/services/table'
import {
  type TableInterface,
  type TableOccupancyInterface,
} from 'src/entities/table/types/table'
import EllipsisTypography from 'src/pages/Settings/Areas/EllipsisTypography'
import IconButton from 'src/shared/components/buttons/IconButton'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import CloseIcon from 'src/shared/components/icons/CloseIcon'
import PencilIcon from 'src/shared/components/icons/PencilIcon'
import { ReservationSeatCount } from 'src/widgets/Tables/SlotInfo/ReservationSeatCount'
import ExactModeModal from './ExactModeModal/ExactModeModal'

interface OccupanciesItemProps {
  occupancy: TableOccupancyInterface
  tables: TableInterface[]
  onOccupancyRemoval: (occupancy: TableOccupancyInterface) => void
  onOccupancyChange: (table: TableInterface, seatCount: number) => void
  reservation: ReservationInterface
}

const OccupanciesItem = ({
  occupancy,
  tables,
  onOccupancyRemoval,
  onOccupancyChange,
  reservation,
}: OccupanciesItemProps) => {
  const exactModeDialog = useDialog()

  const table = tables.find(areOccupyingTable([occupancy]))

  if (!table) return null

  return (
    <>
      <ButtonGroup
        variant="outlined"
        sx={{
          bgcolor: 'white',
          ...(reservation.locked && { cursor: 'not-allowed' }),
        }}
        size="small"
        disabled={reservation.locked}
      >
        {reservation.exactMode && (
          <IconButton onClick={exactModeDialog.handleOpen} size="small">
            <PencilIcon variant="filled" />
          </IconButton>
        )}
        <Button
          sx={{ pointerEvents: 'none', justifyContent: 'flex-start', px: 1 }}
          size="small"
          fullWidth
        >
          <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
            <ReservationSeatCount label={occupancy.seatCount} color="neutral" />
            <EllipsisTypography>{table.name}</EllipsisTypography>
          </Stack>
        </Button>
        <IconButton onClick={() => onOccupancyRemoval(occupancy)} size="small">
          <CloseIcon />
        </IconButton>
      </ButtonGroup>
      <ExactModeModal
        open={exactModeDialog.open}
        onClose={exactModeDialog.handleClose}
        onSeatCountSave={seatCount => onOccupancyChange(table, seatCount)}
        seatCount={occupancy.seatCount}
        table={table}
        reservation={reservation}
      />
    </>
  )
}

export default OccupanciesItem
