import React, { type ComponentProps } from 'react'

import type NumericInput from 'src/shared/components/form/inputs/NumericInput/NumericInput'
import StepInput from 'src/shared/components/form/inputs/StepInput'

const SeatCountCell = ({
  seatCount,
  onChange,
  size = 'small',
}: {
  seatCount: number
  onChange: (seatCount: number) => void
  size?: ComponentProps<typeof NumericInput>['size']
}) => (
  <StepInput
    size={size}
    value={seatCount}
    onChange={onChange}
    fullWidth
    maxValue={99}
    minValue={1}
  />
)

export default React.memo(
  SeatCountCell,
  (prevProps, nextProps) => prevProps.seatCount === nextProps.seatCount,
)
