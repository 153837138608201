import VirtualTable, {
  type VirtualTableProps,
} from 'src/shared/components/common/VirtualTable/VirtualTable'

const Table = <T,>({ children, ...props }: VirtualTableProps<T>) => (
  <VirtualTable size="small" {...props}>
    {children}
  </VirtualTable>
)

export default Table
