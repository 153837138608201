import { always, assocPath, compose, when } from 'ramda'

import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { resizeTable } from 'src/entities/table/services/tableGrid'
import StepInput from 'src/shared/components/form/inputs/StepInput'
import { type FloorPlanTableInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'

const Capacity = ({
  onChange,
  autosize,
}: {
  onChange: (table: FloorPlanTableInterface) => void
  autosize: boolean
}) => {
  const { handleSubmit } = useFormContext<FloorPlanTableInterface>()

  const { t } = useTranslation('', { keyPrefix: 'settings.tables' })

  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'capacity',
    rules: {
      onChange: handleSubmit(table =>
        compose(
          onChange,
          assocPath(['capacity'], table.capacity),
          when(always(autosize), resizeTable(table.capacity)),
        )(table),
      ),
    },
  })

  return (
    <StepInput
      required
      {...field}
      minValue={1}
      helperText={error?.message}
      error={!!error?.message}
      label={t('tableCapacity')}
    />
  )
}

export default Capacity
