import type React from 'react'
import { useLayoutEffect, useRef } from 'react'

import type Konva from 'konva'
import { Layer, Stage, type StageProps } from 'react-konva'

import { useCanvasViewportContext } from 'src/shared/lib/context/state/CanvasViewportContext'
import { type FloorPlanTableInterface } from '../types/floorPlanElement'

const FloorPlanStage = ({
  onSelect,
  children,
  offset: _offset,
  ...props
}: {
  onSelect: (element: FloorPlanTableInterface | undefined) => void
  children: React.ReactNode
} & StageProps) => {
  const { zoom, offset, size, padding } = useCanvasViewportContext()

  const clearSelection = (
    event: Konva.KonvaEventObject<MouseEvent | TouchEvent>,
  ) => {
    const clickedOnEmpty = event.target === event.target.getStage()
    if (clickedOnEmpty) onSelect(undefined)
  }

  const stageRef = useRef<Konva.Stage>(null)

  useLayoutEffect(() => {
    const xOffset = offset.x - padding
    const yOffset = offset.y - padding

    if (!stageRef.current) return

    stageRef.current.x(-xOffset)
    stageRef.current.y(-yOffset)
    stageRef.current.container().style.transform = `translate(${xOffset}px, ${yOffset}px)`

    stageRef.current.scaleX(zoom)
    stageRef.current.scaleY(zoom)

    stageRef.current.width(size.width + padding * 2)
    stageRef.current.height(size.height + padding * 2)
  }, [offset, padding, zoom, size])

  return (
    <Stage
      ref={stageRef}
      onMouseDown={clearSelection}
      onTouchStart={clearSelection}
      {...props}
    >
      <Layer>{children}</Layer>
    </Stage>
  )
}

export default FloorPlanStage
