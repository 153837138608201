import { useCallback } from 'react'

import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'

import { useBetterApiClient } from 'src/shared/lib/api/hooks/useBetterApiClient'
import { useRestaurantCacheKey } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import {
  addTeamMember,
  deleteTeamMember,
  editTeamMember,
  getTeamMembers,
  getTeamRoles,
  updateTeamRoles,
} from '../api/teamMemberApi'

const TEAM_MEMBERS_CACHE_KEY = ['team', 'members']
const TEAM_ROLES_CACHE_KEY = ['team', 'roles']

const useTeamMembersCacheKey = () =>
  useRestaurantCacheKey(TEAM_MEMBERS_CACHE_KEY)
const useRolesCacheKey = () => useRestaurantCacheKey(TEAM_ROLES_CACHE_KEY)

export const useTeamApiClient = (restaurantHash?: string) =>
  useBetterApiClient({ restaurantHash, prefix: 'team' })

export const useTeamMembersQuery = () => {
  const apiClient = useTeamApiClient()
  const queryKey = useTeamMembersCacheKey()

  return useSuspenseQuery({
    queryKey,
    queryFn: getTeamMembers(useCallback(apiClient, [apiClient])),
    refetchInterval: false,
    staleTime: Infinity,
  })
}

export const useAddTeamMemberMutation = () => {
  const apiClient = useTeamApiClient()
  const queryClient = useQueryClient()
  const queryKey = useTeamMembersCacheKey()

  return useMutation({
    mutationFn: addTeamMember(apiClient),
    onMutate: () => queryClient.cancelQueries({ queryKey }),
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  })
}

export const useEditTeamMemberMutation = () => {
  const apiClient = useTeamApiClient()
  const queryClient = useQueryClient()
  const queryKey = useTeamMembersCacheKey()

  return useMutation({
    mutationFn: editTeamMember(apiClient),
    onMutate: () => queryClient.cancelQueries({ queryKey }),
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  })
}

export const useDeleteTeamMemberMutation = () => {
  const apiClient = useTeamApiClient()
  const queryClient = useQueryClient()
  const queryKey = useTeamMembersCacheKey()

  return useMutation({
    mutationFn: deleteTeamMember(apiClient),
    onMutate: () => queryClient.cancelQueries({ queryKey }),
    onSettled: () => queryClient.invalidateQueries({ queryKey }),
  })
}

export const useTeamRolesQuery = () => {
  const apiClient = useTeamApiClient()
  const queryKey = useRolesCacheKey()

  return useSuspenseQuery({
    queryKey,
    queryFn: getTeamRoles(useCallback(apiClient, [apiClient])),
    refetchInterval: false,
    staleTime: Infinity,
  })
}

export const useUpdateTeamRolesMutation = () => {
  const apiClient = useTeamApiClient()
  const queryClient = useQueryClient()
  const queryKey = useRolesCacheKey()

  return useMutation({
    mutationFn: updateTeamRoles(apiClient),
    onMutate: () => {
      void queryClient.cancelQueries({ queryKey })
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey })
    },
  })
}
