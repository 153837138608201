import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'

import { markDone, markOpen } from 'src/entities/notification/api/todoApi'
import { TodoItemStatus } from 'src/entities/notification/types/notification'
import IconButton from 'src/shared/components/buttons/IconButton'
import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'

interface TodoStatusButtonProps {
  doneStatus: TodoItemStatus
  notificationId: string
}

const Button = ({ doneStatus, notificationId }: TodoStatusButtonProps) => {
  const apiClient = useApiClient()

  const isDone = doneStatus === TodoItemStatus.Done

  const clickHandler = () => {
    const toggleDone = isDone ? markOpen : markDone
    return toggleDone(apiClient.post)(notificationId)
  }

  const Icon = isDone ? CheckCircleRoundedIcon : CheckCircleOutlineRoundedIcon

  return (
    <IconButton
      onClick={clickHandler}
      color={isDone ? 'lunchgateSuccess' : 'neutral'}
      variant="text"
      size="large"
      sx={{ '>svg': { fontSize: '2rem' }, alignSelf: 'center' }}
    >
      <Icon />
    </IconButton>
  )
}

export default Button
