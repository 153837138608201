import { type Room } from 'src/entities/schedule/types/scheduleApi'
import { type useFormNavigation } from '../../hooks/formNaviagtion'
import { type ShiftOverlap } from '../../services/shiftValidation'
import BookingSection from '../BookingSection/BookingSection'
import { type FormSection } from '../FormNavigation/FormNavigation'
import ShiftGeneralSection from '../GeneralSection/ShiftGeneralSection'
import LabelsSection from '../LabelsSection/LabelsSection'
import LimitsSection from '../LimitsSection/LimitsSection'
import MessageSection from '../MessageSection/MessageSection'
import NotificationsSection from '../NotificationsSection/NotificationsSection'
import PaymentsSection from '../PaymentsSection/PaymentsSection'

interface AdvancedShiftFormSectionsProps {
  intersections: ShiftOverlap[]
  navItem: ReturnType<typeof useFormNavigation<FormSection>>['NavigationItem']
  rooms: Room[]
  roomsEnabled: boolean
  showLabelsSection: boolean
  showPaymentsSection: boolean
}

export const AdvancedShiftFormSections = ({
  navItem: NavigationItem,
  intersections,
  rooms,
  roomsEnabled,
  showLabelsSection,
  showPaymentsSection,
}: AdvancedShiftFormSectionsProps) => (
  <>
    <NavigationItem sectionKey="general">
      <ShiftGeneralSection
        intersections={intersections}
        rooms={rooms}
        roomsEnabled={roomsEnabled}
      />
    </NavigationItem>
    <NavigationItem sectionKey="booking">
      <BookingSection />
    </NavigationItem>
    <NavigationItem sectionKey="limits">
      <LimitsSection />
    </NavigationItem>
    <NavigationItem sectionKey="message">
      <MessageSection />
    </NavigationItem>
    <NavigationItem sectionKey="notifications">
      <NotificationsSection />
    </NavigationItem>
    <NavigationItem sectionKey="labels" show={showLabelsSection}>
      <LabelsSection />
    </NavigationItem>
    <NavigationItem sectionKey="payments" show={showPaymentsSection}>
      <PaymentsSection />
    </NavigationItem>
  </>
)
