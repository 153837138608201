import React from 'react'

import EllipsisTypography from 'src/pages/Settings/Areas/EllipsisTypography'
import type { WithRequired } from '../../../../lib/common/types/common'
import { SelectContainer, type SelectContainerProps } from '../SelectContainer'
import SelectDropdownItem from '../SelectDropdownItem'

interface BaseProps<U, V> {
  options: U[] | readonly U[]
  renderOption?: (option: U) => V
  renderKey?: (option: U) => string | number
}

export type SingleSelectDropdownProps<
  T,
  V extends React.ReactNode,
  U extends T = T,
> = SelectContainerProps<U> &
  (T extends string | number
    ? V extends string | number
      ? BaseProps<U, V>
      : WithRequired<BaseProps<U, React.ReactNode>, 'renderKey'>
    : V extends string | number
      ? WithRequired<BaseProps<U, V>, 'renderOption'>
      : WithRequired<
          BaseProps<U, React.ReactNode>,
          'renderOption' | 'renderKey'
        >)

const SingleSelectDropdown = <T, V extends React.ReactNode>(
  {
    options,
    size,
    renderOption,
    renderKey,
    ...rest
  }: SingleSelectDropdownProps<T, V>,
  ref: React.ForwardedRef<HTMLInputElement>,
) => (
  <SelectContainer {...rest} size={size} ref={ref}>
    {options.map(option => {
      const optionDisplay =
        renderOption?.(option) ?? (option as string | number)

      return (
        <SelectDropdownItem
          key={renderKey?.(option) ?? (optionDisplay as string | number)}
          value={option}
          size={size}
        >
          {typeof optionDisplay === 'string' ? (
            <EllipsisTypography>{optionDisplay}</EllipsisTypography>
          ) : (
            optionDisplay
          )}
        </SelectDropdownItem>
      )
    })}
  </SelectContainer>
)

export default React.forwardRef(SingleSelectDropdown)
