import { z } from 'zod'

import { shiftSchema } from 'src/entities/schedule/types/scheduleApi'
import { dateSchema } from 'src/shared/lib/zod/zod'

export const formBookingLockSchema = z.object({
  day: dateSchema,
  shiftId: shiftSchema.shape.id,
  bookingChannels: z.enum(['online', 'online-offline']),
})

export type FormBookingLock = z.infer<typeof formBookingLockSchema>
