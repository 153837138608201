import { Stack } from '@mui/material'

import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { type EmptyServiceTime } from 'src/entities/service-time/types/serviceTime'
import FormButtons from 'src/shared/components/buttons/FormButtons'
import Name from './Name'
import Time from './Time'

const EditForm = ({
  serviceTime,
  serviceTimes,
  onSubmit,
  onDelete,
  onCancel,
}: {
  serviceTime: ServiceTimeInterface | EmptyServiceTime | undefined
  serviceTimes: ServiceTimeInterface[]
  onSubmit: (label: ServiceTimeInterface) => void
  onDelete: (label: ServiceTimeInterface) => void
  onCancel: () => void
}) => {
  const form = useForm<ServiceTimeInterface | EmptyServiceTime>({
    defaultValues: serviceTime,
  })

  const { handleSubmit } = form

  const { t } = useTranslation('', { keyPrefix: 'settings.service_times' })

  if (!serviceTime) return null

  const otherServiceTimes = serviceTimes.filter(
    testServiceTime => testServiceTime.id !== serviceTime.id,
  )

  return (
    <FormProvider {...form}>
      <Stack direction="column" spacing={2}>
        <Name />
        <Time
          label={t('startTimeLabel')}
          name="timeRange.0"
          serviceTimes={otherServiceTimes}
        />
        <Time
          label={t('endTimeLabel')}
          name="timeRange.1"
          serviceTimes={otherServiceTimes}
        />
        <FormButtons
          onCancel={onCancel}
          onDelete={() => serviceTime.id && onDelete(serviceTime)}
          onSave={handleSubmit(d => onSubmit(d as ServiceTimeInterface))}
          showDeleteButton={!!serviceTime.id}
        />
      </Stack>
    </FormProvider>
  )
}

export default EditForm
