import { useMemo } from 'react'
import { Divider, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type Room, type Shift } from 'src/entities/schedule/types/scheduleApi'
import GridRow from './components/GridRow/GridRow'
import GridTicks from './components/GridTicks/GridTicks'
import ShiftRow from './components/ShiftRow/ShiftRow'
import {
  getGridBoundaries,
  getRangeWidth,
  type TimelineShift,
} from '../../timelineShift'

interface ShiftGridProps {
  shifts: Shift[]
  rooms: Room[]
  dailyShifts: TimelineShift[]
  weeklyShifts: TimelineShift[][]
}

const ShiftGrid = ({
  shifts,
  rooms,
  dailyShifts,
  weeklyShifts,
}: ShiftGridProps) => {
  const { t } = useTranslation()

  const gridBoundaries = useMemo(
    () => getGridBoundaries({ dailyShifts, weeklyShifts }),
    [dailyShifts, weeklyShifts],
  )

  if (!dailyShifts.length || !gridBoundaries)
    return (
      <>
        <Typography textAlign="center" variant="body3" py={2}>
          {t('schedule.timeline.no_availability', 'Availability not defined.')}
        </Typography>
        <Divider />
      </>
    )

  const { minDate, maxDate } = gridBoundaries

  const columns = getRangeWidth([minDate, maxDate])

  return (
    <Stack direction="column">
      {dailyShifts.map(shift => (
        <ShiftRow
          key={shift.id}
          shift={shift}
          columns={columns}
          gridStart={minDate}
          shifts={shifts}
          rooms={rooms}
        />
      ))}
      <GridRow header={null} columns={columns}>
        <GridTicks columns={columns} gridStart={minDate} />
      </GridRow>
    </Stack>
  )
}

export default ShiftGrid
