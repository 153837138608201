import React from 'react'

import { useTranslation } from 'react-i18next'

import StepInput from 'src/shared/components/form/inputs/StepInput'
import {
  fromIsoDuration,
  toIsoDuration,
} from 'src/shared/lib/range/services/date'
import { useBaseFormController } from '../../../hooks/form'

const EarliestTime = () => {
  const { t } = useTranslation()

  const {
    field: { value, onChange },
  } = useBaseFormController('bookingSetup.earliestTime')
  const duration = fromIsoDuration(value, 'days')

  const onDurationChange = React.useCallback(
    (newDuration: number) => {
      onChange(toIsoDuration(newDuration, 'days'))
    },
    [onChange],
  )

  return (
    <StepInput
      onChange={onDurationChange}
      value={duration}
      unit={t('common.days', { count: duration, defaultValue: 'days' })}
      minValue={1}
      label={t(
        'schedule.shifts.booking_section.duration.booking_window.earliest_possible_time',
        'Earliest time (days)',
      )}
      sx={{ maxWidth: 168 }}
    />
  )
}

export default EarliestTime
