import { type ReactNode } from 'react'
import { Button, Grid2, type SxProps } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import { DisabledOverlay } from '../common/DisabledOverlay/DisabledOverlay'

interface PickerGridProps<T> {
  items: T[]
  onItemClick: (item: T) => void
  renderKey?: (item: T) => string
  renderItem?: (item: T) => ReactNode
  createButtonStyle?: (item: T) => SxProps
  disabled?: boolean
}

const PickerGrid = <T,>({
  items,
  onItemClick,
  renderKey = String,
  renderItem = String,
  createButtonStyle = () => null,
  disabled = false,
}: PickerGridProps<T>) => (
  <DisabledOverlay overflow="auto" disabled={disabled} showSpinner>
    <Grid2 container columns={4} sx={{ border: '1px solid white' }}>
      {items.map(item => (
        <Grid2
          size={{ xs: 1 }}
          key={renderKey(item)}
          sx={{ aspectRatio: '10/8' }}
        >
          <Button
            variant="outlined"
            color="neutral"
            sx={mergeSx(
              {
                typography: 'body3',
                height: 1,
                width: 1,
                borderColor: 'white',
              },
              createButtonStyle(item),
            )}
            onClick={() => onItemClick(item)}
          >
            {renderItem(item)}
          </Button>
        </Grid2>
      ))}
    </Grid2>
  </DisabledOverlay>
)

export default PickerGrid
