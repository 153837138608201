import type React from 'react'
import { Box, Stack } from '@mui/material'

interface GridRowProps {
  header: React.ReactNode
  columns: number
}

const GridRow = ({
  header,
  children,
  columns,
}: React.PropsWithChildren<GridRowProps>) => (
  <Stack direction="row">
    <Stack
      direction="row"
      sx={{
        width: 208,
        justifyContent: 'space-between',
        alignItems: 'center',
        pr: 1,
        py: 0.5,
        gap: 0.5,
      }}
    >
      {header}
    </Stack>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
        width: '100%',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  </Stack>
)

export default GridRow
