import { useCallback } from 'react'
import { allPass, both, complement, either } from 'ramda'

import { type TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import type { CommunicationTemplate } from 'src/entities/communication-template/types/communicationTemplates'
import { type SettingsKeys } from 'src/entities/config/types/configApi'
import {
  isInFuture,
  reservationExists,
} from 'src/entities/reservation/services/reservation'
import {
  ReservationStatusEnum,
  type ReservationInterface,
} from 'src/entities/reservation/types/reservation'
import { useCheckPermissions } from 'src/entities/team-member/hooks/useCheckPermissions'
import { type Permission } from 'src/entities/team-member/types/teamMember'
import { useGlobalModalContext } from 'src/shared/lib/context/global/useGlobalModalContext'

export enum ReservationActions {
  Recurring = 'recurring',
  Create = 'create',
  Update = 'update',
  Cancel = 'cancel',
  Reactivate = 'reactivate',
  Message = 'message',
  Noshow = 'noshow',
  Reshow = 'reshow',
}

const defaultAction = ReservationActions.Update

const isNotLocked = (r: ReservationInterface) => !r.locked

const isCancelled = (r: ReservationInterface) =>
  r.status === ReservationStatusEnum.Cancelled

const isNoShow = (r: ReservationInterface) =>
  r.status === ReservationStatusEnum.NoShow

const hasExpiredPayment = (r: ReservationInterface) =>
  r.payment?.status === 'expired'

export const getNextAction = (
  action: ReservationActions,
): ReservationActions => {
  if (action === ReservationActions.Cancel) return ReservationActions.Reactivate

  if (action === ReservationActions.Noshow) return ReservationActions.Reshow

  return defaultAction
}

export const translateAction = (t: TFunction) => (action: ReservationActions) =>
  ({
    [ReservationActions.Recurring]: t('angular.serial_reservation'),
    [ReservationActions.Create]: t('angular.save'),
    [ReservationActions.Update]: t('angular.save'),
    [ReservationActions.Cancel]: t('angular.cancel'),
    [ReservationActions.Reactivate]: t('angular.reactivate'),
    [ReservationActions.Message]: t('angular.message'),
    [ReservationActions.Noshow]: t('angular.no_show'),
    [ReservationActions.Reshow]: t('angular.re_show'),
  })[action]

const isActive = complement(either(isCancelled, isNoShow))

const canBeMessaged = (messagingEnabled: boolean) =>
  both(reservationExists, _r => messagingEnabled)

const canBeCreated = complement(reservationExists)
const hasPayment = (r: ReservationInterface) => !!r.payment

const canRecur = (r: ReservationInterface) => {
  if (!canBeCreated(r)) return false

  if (hasPayment(r)) return 'has_payment'

  return true
}

const canBeUpdated = (reservationChanged: boolean) =>
  allPass([reservationExists, isActive, () => reservationChanged, isNotLocked])

const canBeNoshowed = allPass([
  reservationExists,
  isActive,
  complement(isInFuture),
  isNotLocked,
])

const canBeReshowed = allPass([reservationExists, isNoShow, isNotLocked])

const canBeCancelled = allPass([reservationExists, isActive, isNotLocked])

const canBeReactivated = allPass([
  reservationExists,
  isCancelled,
  complement(hasExpiredPayment),
  isNotLocked,
])

export const isActionPossible =
  (
    reservationChanged: boolean,
    messagingEnabled: boolean,
    reservation: ReservationInterface,
  ) =>
  (action: ReservationActions) =>
    ({
      [ReservationActions.Message]: canBeMessaged(messagingEnabled),
      [ReservationActions.Recurring]: canRecur,
      [ReservationActions.Create]: canBeCreated,
      [ReservationActions.Update]: canBeUpdated(reservationChanged),
      [ReservationActions.Noshow]: canBeNoshowed,
      [ReservationActions.Reshow]: canBeReshowed,
      [ReservationActions.Cancel]: canBeCancelled,
      [ReservationActions.Reactivate]: canBeReactivated,
    })[action](reservation)

const actionToSmsSettingMap: { [key in ReservationActions]?: SettingsKeys } = {
  [ReservationActions.Create]: 'newReservationGuestSms',
  [ReservationActions.Update]: 'mutationGuestSms',
  [ReservationActions.Noshow]: 'noshowGuestSms',
  [ReservationActions.Cancel]: 'cancelGuestSms',
}

const actionToEmailSettingMap: { [key in ReservationActions]?: SettingsKeys } =
  {
    [ReservationActions.Create]: 'newReservationGuestEmail',
    [ReservationActions.Update]: 'mutationGuestEmail',
    [ReservationActions.Noshow]: 'noshowGuestEmail',
    [ReservationActions.Cancel]: 'cancelGuestEmail',
  }

const staticFlags = (value: boolean) => ({
  smsEnabled: value,
  emailEnabled: value,
})

export const getCommunicationFlags =
  (isSettingOn: (setting: SettingsKeys) => boolean) =>
  (action: ReservationActions) => {
    if (action === ReservationActions.Message) return staticFlags(true)

    const smsSetting = actionToSmsSettingMap[action]
    const emailSetting = actionToEmailSettingMap[action]

    return {
      smsEnabled: !!smsSetting && isSettingOn(smsSetting),
      emailEnabled: !!emailSetting && isSettingOn(emailSetting),
    }
  }

export const areCommsHidden = (
  reservation: ReservationInterface,
  action: ReservationActions,
  relevantChanged: boolean,
) =>
  reservation.status === ReservationStatusEnum.Cancelled ||
  reservation.status === ReservationStatusEnum.NoShow ||
  reservation.serial ||
  action === ReservationActions.Message ||
  action === ReservationActions.Recurring ||
  (action === ReservationActions.Update && !relevantChanged)

interface ActionGroup {
  actions: ReservationActions[]
  type: 'messaging' | 'noshow' | 'cancel' | 'save' | 'other'
}

export const actionGroups: ActionGroup[] = [
  {
    actions: [ReservationActions.Recurring],
    type: 'other',
  },
  {
    actions: [ReservationActions.Message],
    type: 'messaging',
  },
  {
    actions: [ReservationActions.Noshow, ReservationActions.Reshow],
    type: 'noshow',
  },
  {
    actions: [ReservationActions.Cancel, ReservationActions.Reactivate],
    type: 'cancel',
  },
  {
    actions: [ReservationActions.Create, ReservationActions.Update],
    type: 'save',
  },
]

export const getActionGroups = (reservation: ReservationInterface) =>
  actionGroups.map(group => {
    if (group.type !== 'save') return group

    return {
      ...group,
      actions: canBeCreated(reservation)
        ? [ReservationActions.Create]
        : [ReservationActions.Update],
    }
  })

export const reservationToTemplateAction = {
  [ReservationActions.Cancel]: 'cancel',
  [ReservationActions.Create]: 'create',
  [ReservationActions.Message]: 'message',
  [ReservationActions.Update]: 'update',
  [ReservationActions.Noshow]: 'noshow',
} as const satisfies {
  [key in ReservationActions]?: CommunicationTemplate['action']
}

const supportsCommunication = (
  action: ReservationActions,
): action is keyof typeof reservationToTemplateAction =>
  Object.keys(reservationToTemplateAction).includes(action)

export const getCommunicationAction = (action: ReservationActions) => {
  if (!supportsCommunication(action)) return undefined

  return reservationToTemplateAction[action]
}

const actionToPermissions = {
  [ReservationActions.Cancel]: ['cancel_or_reactivate_reservations'],
  [ReservationActions.Reactivate]: ['cancel_or_reactivate_reservations'],
  [ReservationActions.Noshow]: ['no_show_or_re_show_reservations'],
  [ReservationActions.Reshow]: ['no_show_or_re_show_reservations'],
} as const satisfies {
  [key in ReservationActions]?: Permission[]
}

const getActionPermissionMessage = (
  action: keyof typeof actionToPermissions,
  t: TFunction,
) => {
  if (action === ReservationActions.Cancel)
    return t(
      'permissions.errors.cancel_reservation',
      "You don't have permission to cancel reservation.",
    )
  if (action === ReservationActions.Reactivate)
    return t(
      'permissions.errors.reactivate_reservation',
      "You don't have permission to reactivate reservation.",
    )
  if (action === ReservationActions.Noshow)
    return t(
      'permissions.errors.no_show_reservation',
      "You don't have permission to set no-show.",
    )
  if (action === ReservationActions.Reshow)
    return t(
      'permissions.errors.re_show_reservation',
      "You don't have permission to set re-show.",
    )

  action satisfies never
  return ''
}

const needsPermissions = (
  action: ReservationActions,
): action is keyof typeof actionToPermissions => action in actionToPermissions

export const useReservationActionPermissionChecker = () => {
  const { t } = useTranslation()

  const checkPermissions = useCheckPermissions()
  const { showPermissionModal } = useGlobalModalContext()

  return useCallback(
    (selectedAction: ReservationActions) => {
      if (!needsPermissions(selectedAction)) return true

      const requiredPermissions = actionToPermissions[selectedAction]
      const hasPermission = checkPermissions(requiredPermissions)
      if (hasPermission) return true

      showPermissionModal({
        message: getActionPermissionMessage(selectedAction, t),
      })

      return false
    },
    [checkPermissions, showPermissionModal, t],
  )
}
