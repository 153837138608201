import { type MouseEventHandler } from 'react'
import { Box, Stack, Typography } from '@mui/material'

import CloseButton from '../../../buttons/CloseButton'

interface FullScreenModalHeaderProps {
  title: string
  onClose: MouseEventHandler<unknown>
}

export const FullScreenModalHeader = ({
  title,
  onClose,
}: FullScreenModalHeaderProps) => (
  <Stack direction="row" sx={{ alignItems: 'center', p: 1 }}>
    <Box flex={1} />
    <Typography variant="labelHuge">{title}</Typography>
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        p: 1,
        pb: 0,
      }}
    >
      <CloseButton onClick={onClose} />
    </Box>
  </Stack>
)
