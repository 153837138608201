import React from 'react'
import { over, type Lens } from 'ramda'

import { type WithRequired } from '@tanstack/react-query'

import { replaceById } from 'src/shared/lib/common/services/functional/functional'
import { useConfigUpdate } from '../../config/queries/config'
import { type ApiConfig, type ApiElement } from '../../config/types/configApi'

export const useElementUpdate = <T extends ApiElement>(
  lens: Lens<ApiConfig, T[]>,
) => {
  const updateConfig = useConfigUpdate()

  return React.useCallback(
    (element: T) => updateConfig(over(lens, replaceById(element))),
    [lens, updateConfig],
  )
}

export const useElementRemoval = <T extends ApiElement>(
  lens: Lens<ApiConfig, T[]>,
) => {
  const updateConfig = useConfigUpdate()

  return React.useCallback(
    (element: WithRequired<Partial<T>, 'id'>) =>
      updateConfig(
        over(lens, oldElements => {
          if (!oldElements) return oldElements

          return oldElements.filter(
            testElement => testElement.id !== element.id,
          )
        }),
      ),
    [lens, updateConfig],
  )
}
