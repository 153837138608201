import type React from 'react'
import { Box, Stack, Typography, type TypographyProps } from '@mui/material'

const ChoiceGrid = ({
  label,
  typography = 'labelTiny',
  color = 'text.secondary',
  children,
}: {
  label?: string | null
  typography?: TypographyProps['variant']
  color?: TypographyProps['color']
  children: React.ReactNode
}) => (
  <Stack direction="column" spacing={1}>
    {label && (
      <Typography variant={typography} color={color}>
        {label}
      </Typography>
    )}
    <Box
      color="text.tertiary"
      sx={{
        display: 'grid',
        gridAutoColumns: 'min-content',
        gridTemplateColumns: 'repeat(4, 1fr)',
      }}
    >
      {children}
    </Box>
  </Stack>
)

export default ChoiceGrid
