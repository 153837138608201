import { useMemo, type ComponentProps } from 'react'
import { Box, Chip } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { type AreaInterface } from 'src/entities/area/types/area'
import { getTableArea } from 'src/entities/table/services/table'
import { type TableInterface } from 'src/entities/table/types/table'
import { CAPACITY_BG } from 'src/widgets/FloorPlan/CapacityIndicator'
import EllipsisTypography from '../../../../Settings/Areas/EllipsisTypography'

interface TableOrRoomNameProps {
  roomName: string | undefined
  tables: TableInterface[]
  restaurantAreas: AreaInterface[]
}

const TableChip = ({ sx, ...props }: ComponentProps<typeof Chip>) => (
  <Chip
    size="small"
    sx={{
      background: CAPACITY_BG,
      color: 'white',
      height: 'auto',
      borderRadius: 2,
      p: 0.5,
      lineHeight: 1,
      whiteSpace: 'nowrap',
      ...sx,
    }}
    {...props}
  />
)

export const TableOrRoomName = ({
  roomName = '',
  tables,
  restaurantAreas,
}: TableOrRoomNameProps) => {
  const area = useMemo(() => {
    if (!tables[0]) return undefined

    return getTableArea(restaurantAreas)(tables[0])
  }, [tables, restaurantAreas])

  if (!tables.length)
    return (
      <EllipsisTypography
        sx={{
          fontSize: '0.8125rem',
          fontWeight: 400,
          lineHeight: '1rem',
        }}
        {...createTestIdProps('room-name')}
      >
        {roomName}
      </EllipsisTypography>
    )

  const label = area?.name
    ? `${area.name.at(0)}-${tables[0]?.name}`
    : `${tables[0]?.name}`

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 0.25,
        flex: '0 1 auto',
        minWidth: 0,
      }}
    >
      <TableChip
        label={label}
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          flex: 1,
          minWidth: 0,
        }}
        {...createTestIdProps('table-name')}
      />
      {tables.length > 1 && <TableChip label={`+${tables.length - 1}`} />}
    </Box>
  )
}
