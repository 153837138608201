import { assocPath, compose } from 'ramda'
import { FormControl, InputLabel, Select } from '@mui/material'

import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  getApiDimension,
  resizeTable,
} from 'src/entities/table/services/tableGrid'
import SelectDropdownItem from 'src/shared/components/form/inputs/SelectDropdownItem'
import {
  FloorPlanElementShape,
  type FloorPlanTableInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'

const size = 'medium'

const Shape = ({
  onChange,
}: {
  onChange: (table: FloorPlanTableInterface) => void
}) => {
  const { t } = useTranslation('', { keyPrefix: 'settings.tables' })
  const label = t('tableType')

  const { handleSubmit } = useFormContext<FloorPlanTableInterface>()
  const { field } = useController<
    FloorPlanTableInterface,
    keyof FloorPlanTableInterface
  >({
    name: 'shape',
    rules: {
      onChange: handleSubmit(table =>
        compose(
          onChange,
          compose(resizeTable, getApiDimension)(table.size.width),
          assocPath(['shape'], table.shape),
        )(table),
      ),
    },
  })

  return (
    <FormControl fullWidth size={size}>
      <InputLabel>{label}</InputLabel>
      <Select label={label} {...field} size={size}>
        {[
          FloorPlanElementShape.Square,
          FloorPlanElementShape.Elliptic,
          FloorPlanElementShape.Rectangular,
        ].map(tableType => (
          <SelectDropdownItem
            disableGutters
            key={tableType}
            value={tableType}
            size={size}
          >
            {t(`types.${tableType}`)}
          </SelectDropdownItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default Shape
