import { Tab, Tabs } from '@mui/material'

import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import type { ScheduleLanguage } from 'src/entities/schedule/types/scheduleApi'

const languages: ScheduleLanguage[] = ['de', 'en', 'fr', 'it']

export const translateLanguage =
  (t: TFunction) => (language: ScheduleLanguage) =>
    ({
      de: t('language.german', 'German'),
      en: t('language.english', 'English'),
      fr: t('language.french', 'French'),
      it: t('language.italian', 'Italian'),
    })[language]

interface LanguageTabsProps {
  selectedLanguage: ScheduleLanguage
  onLanguageChange: (language: ScheduleLanguage) => void
}

const LanguageTabs = ({
  selectedLanguage,
  onLanguageChange,
}: LanguageTabsProps) => {
  const { t } = useTranslation()

  const changeHandler = (_e: unknown, value: ScheduleLanguage) => {
    onLanguageChange(value)
  }

  return (
    <Tabs value={selectedLanguage} onChange={changeHandler}>
      {languages.map(lng => (
        <Tab value={lng} label={translateLanguage(t)(lng)} key={lng} />
      ))}
    </Tabs>
  )
}

export default LanguageTabs
