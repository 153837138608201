import { type TFunction } from 'i18next'

import {
  type Schedule,
  type Shift,
} from 'src/entities/schedule/types/scheduleApi'
import {
  formatDuration,
  fromTime,
  getWeekdayName,
} from 'src/shared/lib/range/services/date'
import { timeRangeToString } from 'src/shared/lib/range/services/timeRange'
import { isNotFalsy } from 'src/shared/lib/zod/zod'

export interface GetShiftLikePropertiesParams {
  shiftLike: Shift
  schedule: Schedule
  t: TFunction
}

export const getShiftLikeWithRoomName = ({
  shiftLike: s,
  schedule,
}: Omit<GetShiftLikePropertiesParams, 't'>) => {
  if (!s.roomId) return s.name

  const roomName = schedule.rooms.find(r => r.id === s.roomId)?.name

  if (!roomName) return s.name

  return `${s.name} (${roomName})`
}

const getRoomName = ({
  shiftLike: s,
  schedule,
}: GetShiftLikePropertiesParams) => {
  if (!s.roomId) return undefined

  return schedule.rooms.find(r => r.id === s.roomId)?.name
}

const getWeekdays = ({ shiftLike: s, t }: GetShiftLikePropertiesParams) => {
  if (s.weekdays.length !== 7) return s.weekdays.map(getWeekdayName).join(', ')

  return t('schedule.common.every_day', 'Every day')
}

const getArrivalTimes = ({ shiftLike: s }: GetShiftLikePropertiesParams) =>
  timeRangeToString([
    fromTime(s.arrivalTimes.firstArrivalTime),
    fromTime(s.arrivalTimes.lastArrivalTime),
  ])

const getBookingDeadline = ({
  shiftLike: s,
  t,
}: GetShiftLikePropertiesParams) => {
  const { deadline } = s.bookingOptions

  if (deadline.type === 'day-before-reservation') {
    return t(
      'schedule.overview.shift.properties.booking_deadline.day_before_reservation',
      {
        defaultValue: `Deadline: {{bookingDeadlineTime}} (day before res.)`,
        bookingDeadlineTime: deadline.timeOrInterval,
      },
    )
  }

  if (deadline.type === 'day-of-reservation') {
    return t(
      'schedule.overview.shift.properties.booking_deadline.day_of_reservation',
      {
        defaultValue: `Deadline: {{bookingDeadlineTime}}`,
        bookingDeadlineTime: deadline.timeOrInterval,
      },
    )
  }

  return t(
    'schedule.overview.shift.properties.booking_deadline.time_before reservation',
    {
      defaultValue: `Deadline: {{bookingDeadlineTime}} before res.`,
      bookingDeadlineTime: formatDuration(deadline.timeOrInterval),
    },
  )
}

const getMaxAllocationsPerShift = ({
  shiftLike: s,
  t,
}: GetShiftLikePropertiesParams) =>
  t('schedule.overview.shift.properties.max_allocations', {
    maxPerShift: s.limits.maxPaxPerShift,
    defaultValue: `{{maxPerShift}} allocations`,
  })

const getCustomPacing = ({ shiftLike: s, t }: GetShiftLikePropertiesParams) => {
  if (!s.customPacing.setCustomPacing) return undefined

  return t('schedule.overview.shift.properties.pacing_active', 'Pacing active')
}

const getIvrActive = ({ shiftLike: s, t }: GetShiftLikePropertiesParams) => {
  if (
    !s.asyncReservationConfirmation.afterDeadline ||
    !s.asyncReservationConfirmation.beyondPaxLimit
  ) {
    return undefined
  }

  return t('schedule.overview.shift.properties.ivr_active', 'IVR active')
}

const getGuestLimit = ({ shiftLike: s, t }: GetShiftLikePropertiesParams) =>
  t('schedule.overview.shift.properties.guest_limit', {
    min: s.limits.minPaxPerReservation,
    max: s.limits.maxPaxPerReservation,
    defaultValue: `Limit {{min}} - {{max}} guests`,
  })

const getPaymentMethod = ({
  shiftLike: s,
  schedule,
  t,
}: GetShiftLikePropertiesParams) => {
  if (!s.paymentPlanId) return undefined
  const paymentPlanName = schedule.paymentPlans.find(
    pp => pp.id === s.paymentPlanId,
  )?.name

  if (!paymentPlanName) return undefined

  return t('schedule.overview.shift.properties.payment_plan', {
    paymentPlanName,
    defaultValue: `Plan: {{paymentPlanName}}`,
  })
}

export const shiftLikePropertyGetters = {
  getRoomName,
  getWeekdays,
  getArrivalTimes,
  getBookingDeadline,
  getMaxAllocationsPerShift,
  getCustomPacing,
  getIvrActive,
  getGuestLimit,
  getPaymentMethod,
}

export const getShiftProperties = (p: GetShiftLikePropertiesParams) =>
  [
    getWeekdays(p),
    getArrivalTimes(p),
    getBookingDeadline(p),
    getMaxAllocationsPerShift(p),
    getCustomPacing(p),
    getIvrActive(p),
    getGuestLimit(p),
    getPaymentMethod(p),
  ].filter(isNotFalsy)
