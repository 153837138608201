import React from 'react'
import { filter } from 'ramda'

import { type AreaInterface } from 'src/entities/area/types/area'
import { isInArea } from 'src/entities/floor-plan/services/floorPlanElement'
import {
  type FloorPlanTableInterface,
  type FurnitureInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'

export const useAreaTables = (
  tables: FloorPlanTableInterface[],
  area?: AreaInterface,
) =>
  React.useMemo(() => {
    if (!area) return []

    return filter(isInArea(area), tables)
  }, [tables, area])

export const useAreaFurniture = (
  furniture: FurnitureInterface[],
  area?: AreaInterface,
) =>
  React.useMemo(() => {
    if (!area) return []

    return filter(isInArea(area), furniture)
  }, [furniture, area])
