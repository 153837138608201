import { any, complement, compose, filter, map, prop } from 'ramda'

import { type ReservationOccupancy } from 'src/pages/Reservations/service/occupancy'
import { getOccupancies, occupiesTable } from './occupancy'
import { areOccupyingTable } from '../../table/services/table'
import {
  TableCheckInStatusEnum,
  type TableInterface,
  type TableLockInterface,
  type TableOccupancyInterface,
} from '../../table/types/table'
import {
  ReservationCheckInStatusEnum,
  ReservationStatusEnum,
  type ReservationInterface,
} from '../types/reservation'

export const occupancyHasStatus =
  (status: TableCheckInStatusEnum) => (occupancy: TableOccupancyInterface) =>
    occupancy.checkInStatus === status

const hasOccupanciesWithStatus =
  (status: TableCheckInStatusEnum) =>
  (reservation: ReservationInterface): boolean =>
    reservation.occupancies.some(occupancyHasStatus(status))

const isPending = hasOccupanciesWithStatus(TableCheckInStatusEnum.Pending)
export const isCheckedIn = hasOccupanciesWithStatus(
  TableCheckInStatusEnum.CheckedIn,
)
export const isCheckedOut = hasOccupanciesWithStatus(
  TableCheckInStatusEnum.CheckedOut,
)

export const tableStatusFromCheckInStatus = (
  status: ReservationCheckInStatusEnum,
) =>
  ({
    [ReservationCheckInStatusEnum.New]: TableCheckInStatusEnum.Pending,
    [ReservationCheckInStatusEnum.Pending]: TableCheckInStatusEnum.Pending,
    [ReservationCheckInStatusEnum.CheckedIn]: TableCheckInStatusEnum.CheckedIn,
    [ReservationCheckInStatusEnum.CheckedOut]:
      TableCheckInStatusEnum.CheckedOut,
    [ReservationCheckInStatusEnum.Cancelled]: TableCheckInStatusEnum.CheckedOut,
    [ReservationCheckInStatusEnum.NoShow]: TableCheckInStatusEnum.CheckedOut,
  })[status]

export const getCheckInStatus = (
  reservation: ReservationInterface,
): ReservationCheckInStatusEnum => {
  if (reservation.status === ReservationStatusEnum.Cancelled)
    return ReservationCheckInStatusEnum.Cancelled
  if (reservation.status === ReservationStatusEnum.NoShow)
    return ReservationCheckInStatusEnum.NoShow
  if (!reservation.occupancies?.length) return ReservationCheckInStatusEnum.New
  if (isCheckedOut(reservation)) return ReservationCheckInStatusEnum.CheckedOut
  if (isCheckedIn(reservation)) return ReservationCheckInStatusEnum.CheckedIn
  if (isPending(reservation)) return ReservationCheckInStatusEnum.Pending

  return ReservationCheckInStatusEnum.CheckedIn
}

export const hasCheckInStatus =
  (statuses: ReservationCheckInStatusEnum[]) =>
  (reservation: ReservationInterface): boolean =>
    statuses.includes(getCheckInStatus(reservation))

export const getTableStatusFlags = (
  reservations: ReservationInterface[],
  reservationOccupancies: ReservationOccupancy[],
  tableLocks: TableLockInterface[],
  selectedTable: TableInterface | undefined,
  selectedReservation: ReservationInterface | undefined,
) => {
  const getOccupancy = prop('occupancy')
  const occupancies = map(getOccupancy, reservationOccupancies)

  const getCheckedIn = filter(
    occupancyHasStatus(TableCheckInStatusEnum.CheckedIn),
  )
  const excludeCheckedOut = filter(
    complement(occupancyHasStatus(TableCheckInStatusEnum.CheckedOut)),
  )

  const notCheckedOutOccupancies = excludeCheckedOut(occupancies)
  const checkedInOccupancies = getCheckedIn(occupancies)

  const sharesReservationWithSelectedTable = compose(
    areOccupyingTable,
    excludeCheckedOut,
    getOccupancies,
    filter(occupiesTable(selectedTable)),
  )(reservations)

  const selectedReservationOccupancies = selectedReservation?.occupancies ?? []
  const hasSelectedReservationOccupancies = areOccupyingTable(
    selectedReservationOccupancies,
  )

  return (table: TableInterface) => ({
    hasSelectedTableOccupancies: sharesReservationWithSelectedTable(table),
    hasOccupancies: areOccupyingTable(notCheckedOutOccupancies)(table),
    hasCheckedInOccupancies: areOccupyingTable(checkedInOccupancies)(table),
    isLocked: any(tl => tl.tableId === table.id, tableLocks),
    isSelected:
      table === selectedTable || hasSelectedReservationOccupancies(table),
  })
}
