import { forwardRef } from 'react'
import {
  Autocomplete,
  Fade,
  InputBase,
  Paper,
  type PaperProps,
} from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { DropdownPaperProps } from 'src/app/theme/theme'
import { type PhoneCode } from 'src/entities/locale/types/localeApi'
import SelectDropdownItem from 'src/shared/components/form/inputs/SelectDropdownItem'
import { Chevron } from 'src/shared/components/icons/ChevronIcon'
import { filterOptions, formatLabel, getFullValue } from './service'
import useExistingSelectionEffect from './useExistingSelectionEffect'

interface PhoneCodeSelectProps {
  value?: PhoneCode
  onChange: (value: PhoneCode) => void
  options: PhoneCode[]
}

const AnimatedPaper = forwardRef<HTMLDivElement, PaperProps>((props, ref) => (
  <Fade in>
    <Paper {...props} ref={ref} />
  </Fade>
))

const PhoneCodeSelect = ({
  value,
  onChange,
  options,
}: PhoneCodeSelectProps) => {
  useExistingSelectionEffect(options, value, onChange)

  return value && options.includes(value) ? (
    <Autocomplete
      {...createTestIdProps(`phone-code`)}
      disablePortal
      ListboxProps={{ sx: { overflow: 'visible' } }}
      slotProps={{
        popper: {
          placement: 'bottom-start',
          sx: { width: 'initial', minWidth: 200 },
        },
        paper: DropdownPaperProps,
      }}
      PaperComponent={AnimatedPaper}
      options={options}
      value={value}
      disableClearable
      popupIcon={<Chevron size="medium" sx={{ color: 'grey.800' }} />}
      sx={{ width: 75, height: 1 }}
      onChange={(_, newValue) => newValue && onChange(newValue)}
      renderOption={({ className: _className, ...props }, phoneCode) => (
        <SelectDropdownItem
          {...props}
          key={getFullValue(phoneCode)}
          value={phoneCode}
          selected={value === phoneCode}
        >
          {getFullValue(phoneCode)}
        </SelectDropdownItem>
      )}
      filterOptions={(allOptions, { inputValue }) =>
        filterOptions(allOptions, inputValue)
      }
      getOptionLabel={formatLabel}
      isOptionEqualToValue={(option, phoneCode) =>
        option.code === phoneCode.code
      }
      renderInput={({ fullWidth, ...props }) => (
        <InputBase
          fullWidth={fullWidth}
          sx={{ typography: 'body3', px: 1.5, height: 1 }}
          inputProps={props.inputProps}
          {...props.InputProps}
        />
      )}
    />
  ) : null
}

export default PhoneCodeSelect
