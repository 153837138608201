import { useQuery, useQueryClient } from '@tanstack/react-query'

import { fetchVersion } from '../api/versionApi'

const ONE_HOUR_MS = 60 * 60 * 1000

const QUERY_KEY = ['fat-version']

const isLatestVersion = (latestVersion: string | undefined | null) =>
  !latestVersion || latestVersion === import.meta.env.PUBLIC_RELEASE_VERSION

const useLatestVersionQuery = () => {
  const queryClient = useQueryClient()

  const latestVersion = queryClient.getQueryData<string>(QUERY_KEY)

  return useQuery({
    queryKey: QUERY_KEY,
    queryFn: fetchVersion,
    enabled:
      import.meta.env.MODE === 'production' && isLatestVersion(latestVersion),
    refetchInterval: ONE_HOUR_MS,
    staleTime: ONE_HOUR_MS,
  })
}

export const useIsLatestVersion = () => {
  const { data: latestVersion } = useLatestVersionQuery()

  return isLatestVersion(latestVersion)
}
