import React, { useCallback } from 'react'

import {
  queryOptions,
  useSuspenseQueries,
  useSuspenseQuery,
  type DefaultError,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { useOptimisticUpdate } from 'src/shared/lib/api/queries/optimisticUpdate'
import {
  getRestaurantCacheKey,
  useRestaurantCacheKey,
} from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import { type ApiClient } from 'src/shared/lib/api/services/api'
import { useRestaurantHash } from 'src/shared/lib/context/global/useRestaurantHash'
import { useChainRestaurantHashes } from '../../restaurant/queries/restaurants'
import { type RestaurantInterface } from '../../restaurant/types/restaurant'
import { getConfig } from '../api/configApi'
import { type ApiConfig } from '../types/configApi'

export const CONFIG_CACHE_KEY = ['settingsConfig']

export const useConfigCacheKey = () => useRestaurantCacheKey(CONFIG_CACHE_KEY)

const getConfigQueryOptions =
  <T>(apiClient: ApiClient) =>
  (restaurantHash: string) =>
    queryOptions<ApiConfig, DefaultError, T>({
      queryKey: getRestaurantCacheKey(restaurantHash, CONFIG_CACHE_KEY),
      queryFn: () => getConfig(apiClient)(restaurantHash),
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000,
    })

const useConfigQueryOptionsFactory = <T>() => {
  const hash = useRestaurantHash()
  const apiClient = useApiClient()

  return React.useCallback(
    (restaurantId?: RestaurantInterface['hash']) =>
      getConfigQueryOptions<T>(apiClient.post)(restaurantId ?? hash),
    [apiClient.post, hash],
  )
}

export const useConfigQuery = <T = ApiConfig>(
  options: Partial<UseSuspenseQueryOptions<ApiConfig, DefaultError, T>>,
  restaurantId?: RestaurantInterface['hash'],
) =>
  useSuspenseQuery({
    ...options,
    ...useConfigQueryOptionsFactory<T>()(restaurantId),
  })

export const useConfigRestaurantsQueries = <T>(
  optionsFactory: (
    restaurantId: RestaurantInterface['hash'],
  ) => Partial<UseSuspenseQueryOptions<ApiConfig, DefaultError, T>>,
) => {
  const restaurantHashes = useChainRestaurantHashes()
  const configOptionsFactory = useConfigQueryOptionsFactory<T>()

  const createQuery = useCallback(
    (
      restaurantId: RestaurantInterface['hash'],
    ): UseSuspenseQueryOptions<ApiConfig, DefaultError, T> => ({
      ...configOptionsFactory(restaurantId),
      ...optionsFactory(restaurantId),
    }),
    [configOptionsFactory, optionsFactory],
  )

  return useSuspenseQueries({
    queries: restaurantHashes.map(createQuery),
    combine: results => results.flatMap(query => query.data).flat(),
  })
}

export const useConfigUpdate = () => {
  const update = useOptimisticUpdate()
  const cacheKey = useConfigCacheKey()

  return React.useCallback(
    (updater: (config: ApiConfig) => ApiConfig) => update(cacheKey, updater),
    [update, cacheKey],
  )
}
