import {
  Button,
  TableCell as MuiTableCell,
  Stack,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import { type Room } from 'src/entities/schedule/types/scheduleApi'
import SquareIconButton from 'src/shared/components/buttons/SquareIconButton'
import { showToast } from 'src/shared/components/common/toast'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import CopyIcon from 'src/shared/components/icons/CopyIcon'
import DragIcon from 'src/shared/components/icons/DragIcon'
import ExternalLinkIcon from 'src/shared/components/icons/ExternalLinkIcon'
import PencilIcon from 'src/shared/components/icons/PencilIcon'
import TrashIcon from 'src/shared/components/icons/TrashIcon'
import { getApiUrl } from 'src/shared/lib/api/services/apiUrl'

interface DraggableRoomItemProps {
  room: Room
  onEdit: () => void
  onDelete: () => void
  onSwitchClick: () => void
  isNotInShift: boolean
  onlineSwitchDisabled: boolean
  isBeingReordered: boolean
  isReorderingDisabled: boolean
  showWidgetLink: boolean
}

const TableCell = styled(MuiTableCell)({ verticalAlign: 'middle' })

const reserveUrl = import.meta.env.PUBLIC_RESERVE_URL
const isDev = !!reserveUrl?.includes('test')

const url = new URL(getApiUrl())

const widgetApiUrl = encodeURIComponent(
  url.origin + url.pathname.replace('/reservationbookapi/', ''),
)

export const DraggableRoomItem = ({
  room,
  isNotInShift,
  onlineSwitchDisabled,
  isBeingReordered,
  isReorderingDisabled,
  showWidgetLink,
  onSwitchClick,
  onEdit,
  onDelete,
}: DraggableRoomItemProps) => {
  const { t } = useTranslation()

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: room.id })

  const { showSimpleDialog, simpleDialogProps } = useSimpleDialog()
  const deleteTooltip = useDialog()

  const roomWidgetHref = `${reserveUrl}?restaurantHash=${room.hash}${
    isDev ? `&apiUrl=${widgetApiUrl}` : ''
  }`

  return (
    <>
      <TableRow
        ref={setNodeRef}
        sx={{ transform: CSS.Translate.toString(transform), transition }}
      >
        <TableCell>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <DragIcon
              sx={{ cursor: 'move' }}
              disabled={isReorderingDisabled}
              {...attributes}
              {...listeners}
            />
            <Stack>
              <Typography variant="labelSmall">{room.name}</Typography>
              {isNotInShift && (
                <Typography variant="labelTiny">
                  {t(
                    'schedule.rooms.not_assigned_to_shift',
                    'Not assigned to any shift.',
                  )}
                </Typography>
              )}
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Toggle
            label=""
            checked={room.selectable}
            onChange={onSwitchClick}
            disabled={onlineSwitchDisabled}
          />
        </TableCell>
        {showWidgetLink && (
          <TableCell>
            <Stack direction="row" gap={0} alignItems="center">
              <Button
                href={roomWidgetHref}
                target="_blank"
                rel="noopener"
                endIcon={<ExternalLinkIcon />}
                disabled={!room.selectable}
              >
                {t('schedule.rooms.room_widget', 'Room widget')}
              </Button>
              <SquareIconButton
                color="neutral"
                disabled={!room.selectable}
                sx={{ ml: 0, position: 'relative', top: '-1px', left: '-5px' }}
                onClick={async () => {
                  await navigator.clipboard.writeText(roomWidgetHref)
                  showToast(
                    t('common.copy_success', 'Link copied to clipboard!'),
                    'success',
                  )
                }}
              >
                <CopyIcon />
              </SquareIconButton>
            </Stack>
          </TableCell>
        )}

        <TableCell>
          <Stack direction="row" gap={0.25} justifyContent="flex-end">
            <SquareIconButton
              color="neutral"
              variant="outlined"
              onClick={onEdit}
              disabled={isBeingReordered}
            >
              <PencilIcon variant="filled" />
            </SquareIconButton>
            <Tooltip
              title={t(
                'schedule.rooms.errors.cannot_delete_room_in_shift',
                "Room cannot be deleted because it's assigned to a shift.",
              )}
              placement="top-end"
              arrow
              open={!isNotInShift && deleteTooltip.open}
            >
              <SquareIconButton
                color="neutral"
                variant="outlined"
                onClick={() => showSimpleDialog()}
                onMouseOver={
                  !isNotInShift ? deleteTooltip.handleOpen : undefined
                }
                onMouseLeave={
                  !isNotInShift ? deleteTooltip.handleClose : undefined
                }
                disabled={isBeingReordered || !isNotInShift}
                sx={{
                  ':disabled': { pointerEvents: 'all', cursor: 'not-allowed' },
                }}
              >
                <TrashIcon variant="filled" />
              </SquareIconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <SimpleDialog
        color="error"
        title={t('common.caution', 'Caution')}
        message={t(
          'schedule.rooms.delete_disclaimer',
          'Are you sure you want to delete this room? This action cannot be reversed.',
        )}
        onConfirmation={onDelete}
        {...simpleDialogProps}
      />
    </>
  )
}
