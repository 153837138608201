import { useCallback } from 'react'
import { Divider } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  useDeleteShiftExceptionMutation,
  useDeleteShiftMutation,
} from 'src/entities/schedule/queries/schedule'
import {
  type Schedule,
  type Shift,
  type ShiftException,
} from 'src/entities/schedule/types/scheduleApi'
import { useHasPermission } from 'src/entities/team-member/hooks/useCheckPermissions'
import { useGlobalModalContext } from 'src/shared/lib/context/global/useGlobalModalContext'
import { getShiftLikeWithRoomName, getShiftProperties } from './service'
import { useDefaultMutationHandlers } from '../../ShiftConfiguration/hooks/useDefaultMutationHandlers'
import {
  CreateScheduleButton,
  CreateScheduleExceptionButton,
  ScheduleExceptionItem,
} from '../components'
import { ScheduleCard, ScheduleCardWrapper } from '../components/ScheduleCard'

interface ShiftSectionProps {
  schedule: Schedule
  onEditShift: (shift?: Shift) => void
  onEditException: (shift: Shift, shiftException?: ShiftException) => void
}

export const ShiftSection = ({
  schedule,
  onEditShift,
  onEditException,
}: ShiftSectionProps) => {
  const { t } = useTranslation()

  const { mutateAsync: deleteShift } = useDeleteShiftMutation()
  const { mutateAsync: deleteException } = useDeleteShiftExceptionMutation()
  const { onError } = useDefaultMutationHandlers()

  const { showPermissionModal } = useGlobalModalContext()
  const canManageShifts = useHasPermission('manage_schedule_shifts')

  const withCheck = useCallback(
    (callback: () => void) => {
      if (canManageShifts) return callback
      return () =>
        showPermissionModal({
          message: t(
            'permissions.errors.manage_schedule_shifts',
            "You don't have permission to set manage schedule shifts.",
          ),
        })
    },
    [canManageShifts, showPermissionModal, t],
  )

  return (
    <>
      <ScheduleCardWrapper>
        {schedule.shifts.map(s => (
          <ScheduleCard
            key={s.id}
            title={getShiftLikeWithRoomName({ shiftLike: s, schedule })}
            onEdit={withCheck(() => onEditShift(s))}
            onDelete={withCheck(() => deleteShift(s, { onError }))}
            properties={getShiftProperties({ shiftLike: s, schedule, t })}
            isInactive={!s.isActive}
          >
            <Divider sx={{ my: 1 }} />
            {schedule.shiftExceptions
              .filter(se => se.shiftId === s.id)
              .map(se => (
                <ScheduleExceptionItem
                  key={se.id}
                  dateRange={[se.effectivePeriod.start, se.effectivePeriod.end]}
                  onEdit={() => onEditException(s, se)}
                  onDelete={() => deleteException(se, { onError })}
                />
              ))}
            <CreateScheduleExceptionButton onClick={() => onEditException(s)} />
          </ScheduleCard>
        ))}
      </ScheduleCardWrapper>
      <CreateScheduleButton onClick={withCheck(() => onEditShift())}>
        {t('schedule.overview.shift.create', 'Create shift')}
      </CreateScheduleButton>
    </>
  )
}
