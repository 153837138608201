import React from 'react'
import { andThen, compose, composeWith } from 'ramda'
import { Stack } from '@mui/material'

import { DndContext, type DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import { useTranslation } from 'react-i18next'

import {
  useAreaDeleteMutation,
  useAreasOrderUpdate,
  useAreasQuery,
  useAreaUpdateMutation,
} from 'src/entities/area/queries/area'
import { type ApiAreaInterface } from 'src/entities/area/types/area'
import {
  emptyArea,
  order,
  type EmptyArea,
} from 'src/entities/area/types/areaApi'
import CreateButton from 'src/shared/components/buttons/CreateButton'
import Modal from 'src/shared/components/common/Modal'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { useBasicDndSensors } from 'src/shared/lib/common/hooks/useDnd'
import EditForm from './EditForm'
import Item from './Item'

const Areas = () => {
  const { t } = useTranslation('', { keyPrefix: 'settings.areas' })

  const { data: areas } = useAreasQuery()

  const [editedArea, setEditedArea] = React.useState<
    ApiAreaInterface | EmptyArea | null
  >(null)

  const { handleClose, handleOpen, open } = useDialog()

  const { mutateAsync: updateArea } = useAreaUpdateMutation()
  const { mutate: deleteArea } = useAreaDeleteMutation()
  const { mutate: setAreasOrder } = useAreasOrderUpdate()

  const [orderedAreas, setOrderedAreas] = React.useState(
    areas.sort((a, b) => a.position - b.position),
  )

  React.useEffect(() => {
    setOrderedAreas(areas.sort((a, b) => a.position - b.position))
  }, [areas])

  const handleDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (!over || active.id === over.id) return

      setOrderedAreas(oldAreas => {
        const oldIndex = oldAreas.findIndex(area => area.id === active.id)
        const newIndex = oldAreas.findIndex(area => area.id === over.id)

        const newAreas = arrayMove(oldAreas, oldIndex, newIndex)

        setAreasOrder(order(newAreas))

        return newAreas
      })
    },
    [setAreasOrder],
  )

  const sensors = useBasicDndSensors()

  const handleEdit = compose(handleOpen, setEditedArea)
  const handleSubmit = composeWith(andThen)([handleClose, updateArea])
  const handleDelete = compose(handleClose, deleteArea)

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        p: 2,
        alignItems: 'baseline',
        width: 1,
        maxWidth: 600,
      }}
    >
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <CreateButton onClick={() => handleEdit(emptyArea(areas))}>
          {t('addArea')}
        </CreateButton>
        <Stack direction="column" spacing={1} sx={{ width: 1 }}>
          <SortableContext items={orderedAreas}>
            {orderedAreas.map(area => (
              <Item key={area.id} area={area} onSelect={handleEdit} />
            ))}
          </SortableContext>
        </Stack>
      </DndContext>
      {editedArea && (
        <Modal open={open} onClose={handleClose} title={t('modalTitle')}>
          <EditForm
            area={editedArea}
            onCancel={handleClose}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
          />
        </Modal>
      )}
    </Stack>
  )
}

export default Areas
