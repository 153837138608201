import {
  Button,
  Collapse,
  Stack,
  Typography,
  type IconProps,
} from '@mui/material'

import { Trans } from 'react-i18next'

interface NotifyBarProps {
  isVisible: boolean
  variant: 'error' | 'info'
  icon: React.FC<IconProps>
  messageI18nKey: string
  messageI18nDefaultValue: string
  buttonText?: string
  onButtonClick?: () => void
}

const variantToColors = (variant: NotifyBarProps['variant']) => {
  switch (variant) {
    case 'error':
      return {
        color: 'errorPalette.500',
        bgcolor: 'errorPalette.100',
      }
    case 'info':
      return {
        color: 'primaryPalette.500',
        bgcolor: 'primaryPalette.100',
      }
    default:
      variant satisfies never
      return null
  }
}

export const NotifyBar = ({
  isVisible,
  variant,
  icon: Icon,
  buttonText,
  onButtonClick,
  messageI18nDefaultValue,
  messageI18nKey,
}: NotifyBarProps) => (
  <Collapse in={isVisible} sx={{ width: '100%', flexShrink: 0 }}>
    <Stack
      sx={{
        py: buttonText ? 0.25 : 1,
        px: 2,
      }}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={1.5}
      {...variantToColors(variant)}
    >
      <Stack direction="row" alignItems="center" gap={1.5}>
        <Icon />
        <Typography variant="labelSmall" fontWeight="normal" lineHeight={1}>
          <Trans
            i18nKey={messageI18nKey}
            defaults={messageI18nDefaultValue}
            components={[<Typography component="span" fontWeight={500} />]}
          />
        </Typography>
      </Stack>
      {buttonText && (
        <Button
          variant="contained"
          onClick={onButtonClick}
          sx={{ flexShrink: 0 }}
        >
          {buttonText}
        </Button>
      )}
    </Stack>
  </Collapse>
)
