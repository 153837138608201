import { andThen, composeWith, map, prop } from 'ramda'

import { type ApiClient } from 'src/shared/lib/api/services/api'
import { type RoomInterface } from '../../room/types/room'
import { availabilitySchema } from '../types/availabilityApi'

export const getAvailabilities =
  (httpClient: ApiClient) => (date: Date, roomId?: RoomInterface['id']) =>
    composeWith(andThen)([
      map(a => availabilitySchema.parse(a)),
      prop('available_time_slots'),
      httpClient,
    ])({
      url: 'availabilities/get',
      json: {
        date: date.toISOString(),
        ...(roomId && {
          room_id: roomId,
        }),
      },
    })
