import React from 'react'
import { compose } from 'ramda'
import { Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { getServiceTimeRange } from 'src/entities/service-time/services/serviceTime'
import ChoiceGrid from 'src/shared/components/containers/ChoiceGrid'
import StepInput from 'src/shared/components/form/inputs/StepInput'
import { fromMilliseconds } from 'src/shared/lib/range/services/date'
import { getDuration } from 'src/shared/lib/range/services/timeRange'
import { fromDuration } from 'src/shared/lib/range/services/timeRangeFactory'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import TimeChoice from './TimeChoice'

const Selection = ({
  timeRange,
  serviceTimes,
  onTimeRangeChange,
  onStartDateChange,
}: {
  timeRange: DefinedRangeInterface<Date>
  serviceTimes: ServiceTimeInterface[]
  onTimeRangeChange: (timeRange: DefinedRangeInterface<Date>) => void
  onStartDateChange?: (date: Date) => void
}) => {
  const { t } = useTranslation('', { keyPrefix: 'serial_reservations' })

  const [duration, setDuration] = React.useState(
    fromMilliseconds('minutes')(getDuration(timeRange)),
  )
  const [selectedTime, setSelectedTime] = React.useState<Date>(timeRange[0])

  const timeRangeChangeHandler = React.useMemo(
    () => compose(onTimeRangeChange, fromDuration),
    [onTimeRangeChange],
  )

  const durationChangeHandler = React.useCallback(
    (newDuration: number) => {
      setDuration(newDuration)
      timeRangeChangeHandler(selectedTime, newDuration)
    },
    [timeRangeChangeHandler, selectedTime],
  )

  const timeChangeHandler = React.useCallback(
    (newTime: Date) => {
      setSelectedTime(newTime)
      onStartDateChange?.(newTime)
      timeRangeChangeHandler(newTime, duration)
    },
    [timeRangeChangeHandler, duration, onStartDateChange],
  )

  const reservationDate = timeRange[0]
  return (
    <Stack direction="column" spacing={3} sx={{ width: 'min-content' }}>
      <StepInput
        value={duration}
        onChange={durationChangeHandler}
        label={t('duration')}
        fullWidth
        step={15}
        minValue={15}
      />
      <Stack direction="column" spacing={2} sx={{ width: 'min-content' }}>
        {serviceTimes.map((serviceTime, i) => (
          <ChoiceGrid label={serviceTime.name} key={serviceTime.id}>
            <TimeChoice
              timeRange={getServiceTimeRange(reservationDate)(serviceTime)}
              nextTimeRange={getServiceTimeRange(reservationDate)(
                serviceTimes[i + 1],
              )}
              selectedTime={selectedTime}
              onTimeSelect={timeChangeHandler}
            />
          </ChoiceGrid>
        ))}
      </Stack>
    </Stack>
  )
}

export default Selection
