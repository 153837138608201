import { Box, Popover, type BoxProps } from '@mui/material'

import { type PersistedLabelInterface } from 'src/entities/label/types/label'
import { usePopover } from 'src/shared/components/dialogs/hooks/usePopover'
import List from './List'
import Selection from './Selection'

const LabelPicker = ({
  labels,
  selectedLabels,
  onSelectedLabelsChange,
  toggleButtonContainerProps,
}: {
  labels: PersistedLabelInterface[]
  selectedLabels: PersistedLabelInterface[]
  onSelectedLabelsChange: (newLabels: PersistedLabelInterface[]) => void
  toggleButtonContainerProps?: BoxProps
}) => {
  const popover = usePopover()

  return (
    <>
      <List
        popover={popover}
        selectedLabels={selectedLabels}
        onSelectedLabelsChange={onSelectedLabelsChange}
        toggleButtonContainerProps={toggleButtonContainerProps}
      />
      <Popover
        disablePortal
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.handleClose}
      >
        <Box sx={{ p: 2 }}>
          <Selection
            selectedLabels={selectedLabels}
            labels={labels}
            onSelectedLabelsChange={onSelectedLabelsChange}
          />
        </Box>
      </Popover>
    </>
  )
}

export default LabelPicker
