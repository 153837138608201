import { Rect } from 'react-konva'

import { type FloorPlanShapeComponentProps } from '../types/floorPlanElement'

const RectangularElement = ({
  element,
  shapeProps,
}: FloorPlanShapeComponentProps) => (
  <Rect
    {...element.size}
    cornerRadius={4}
    strokeScaleEnabled={false}
    {...shapeProps}
  />
)

export default RectangularElement
