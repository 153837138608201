import { Link, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type ReservationLink } from 'src/entities/reservation/types/reservationApi'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import LinkIcon from 'src/shared/components/icons/LinkIcon'
import LinkEditDialog from './LinkEditDialog'

interface LinkSectionProps {
  link: ReservationLink
  onLinkChange: (link: ReservationLink) => void
}

const LinkSection = ({ link, onLinkChange }: LinkSectionProps) => {
  const { t } = useTranslation()

  const { open, handleOpen, handleClose } = useDialog()

  const linkExists = !!link.url

  return (
    <>
      <Stack direction="column" gap={0.5}>
        <Stack
          onClick={handleOpen}
          direction="row"
          justifyContent="space-between"
          sx={{ cursor: 'pointer' }}
        >
          <Typography
            component="label"
            variant="labelTiny"
            sx={{ cursor: 'pointer' }}
          >
            {t('angular.link')}
          </Typography>
          <LinkIcon size="small" />
        </Stack>
        {linkExists && (
          <Link
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {link.name || link.url}
          </Link>
        )}
      </Stack>
      <LinkEditDialog
        open={open}
        onClose={handleClose}
        onSave={onLinkChange}
        initValue={link}
      />
    </>
  )
}

export default LinkSection
