import React from 'react'
import { clamp, compose, juxt } from 'ramda'

import debounce from 'debounce'

import { roundToNumber } from '../../../../lib/common/services/math/math'

export interface UseStepPicker {
  value?: number
  onChange: (newValue: number) => void
  step?: number
  minValue?: number
  maxValue?: number
}

const ROUND_TO_STEP_DELAY = 1000

export const useStepPicker = ({
  value = 0,
  onChange,
  step = 1,
  minValue = 0,
  maxValue = Number.MAX_SAFE_INTEGER,
}: UseStepPicker) => {
  const [displayedValue, setDisplayedValue] = React.useState(
    compose(roundToNumber(step))(value),
  )

  React.useEffect(() => {
    setDisplayedValue(compose(roundToNumber(step))(value))
  }, [step, value])

  const debouncedSetValue = React.useMemo(
    () => debounce(juxt([setDisplayedValue, onChange]), ROUND_TO_STEP_DELAY),
    [onChange],
  )

  const sanitizeValue = React.useMemo(
    () => compose(clamp(minValue, maxValue), roundToNumber(step), Number),
    [minValue, maxValue, step],
  )

  const forceUpdate = React.useCallback(() => {
    setDisplayedValue(oldValue => {
      const newValue = sanitizeValue(oldValue)
      if (oldValue === newValue) return oldValue
      onChange(newValue)
      return newValue
    })
  }, [onChange, sanitizeValue])

  React.useEffect(() => {
    // run later compared to the `setDisplayedValue` effect above
    const timeout = setTimeout(() => forceUpdate(), 0)

    return () => clearTimeout(timeout)
  }, [forceUpdate])

  const inputHandler = (newValue: number) => {
    const sanitizedValue = sanitizeValue(newValue)

    setDisplayedValue(newValue)
    debouncedSetValue(sanitizedValue)
  }

  const stepHandler = (newValue: number) => {
    debouncedSetValue.clear()
    const sanitizedValue = sanitizeValue(newValue)

    juxt([setDisplayedValue, onChange])(sanitizedValue)
  }

  const changeHandler = (newValue: number) => {
    if (Math.abs(newValue - value) !== step || (!newValue && newValue !== 0))
      return inputHandler(newValue)

    return stepHandler(newValue)
  }

  const inc = () => changeHandler(value + step)
  const dec = () => changeHandler(value - step)

  return {
    changeHandler,
    inc,
    dec,
    displayedValue,
    forceUpdate,
  }
}
