import { andThen, composeWith } from 'ramda'

import {
  convertKeysToCamelCase,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import { meSchema } from '../types/me'

export const getMe = (httpClient: ApiClient) => () =>
  composeWith(andThen)([
    (d: unknown) => meSchema.parse(d),
    convertKeysToCamelCase,
    httpClient,
  ])({
    url: 'users/me',
  })
