import { forwardRef, type ComponentProps } from 'react'
import { Stack, Typography, type StackProps } from '@mui/material'

import Toggle from 'src/shared/components/form/inputs/Toggle'
import { useBaseFormController } from '../../../hooks/form'

interface ToggleWithSubtitleProps extends ComponentProps<typeof Toggle> {
  fieldName:
    | 'reservationConfirmation.afterDeadline'
    | 'reservationConfirmation.beyondPaxLimit'
  subtitle: string
  stackProps?: StackProps
}

const ToggleWithSubtitle = forwardRef<
  HTMLInputElement,
  ToggleWithSubtitleProps
>(({ fieldName, subtitle, disabled, stackProps, ...props }, ref) => {
  const {
    field: { onChange, value },
  } = useBaseFormController(fieldName)

  return (
    <Stack direction="column" spacing={0.5} {...stackProps}>
      <Toggle
        disabled={disabled}
        checked={disabled ? false : value}
        onChange={(_e, checked) => onChange(checked)}
        ref={ref}
        {...props}
      />
      <Typography variant="body3" sx={{ ml: 6 }}>
        {subtitle}
      </Typography>
    </Stack>
  )
})

export default ToggleWithSubtitle
