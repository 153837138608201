import React from 'react'

import { type QueryKey } from '@tanstack/react-query'

import { type RestaurantInterface } from 'src/entities/restaurant/types/restaurant'
import { useRestaurantHash } from '../../context/global/useRestaurantHash'

const getRestaurantCacheKeyFactory =
  (restaurantHash: string, queryKey: QueryKey = []) =>
  (entityKey: QueryKey = []) =>
    [restaurantHash, ...queryKey, ...entityKey.filter(Boolean)] as QueryKey

export const getRestaurantCacheKey = (
  restaurantHash: string,
  entityKey: QueryKey = [],
) => getRestaurantCacheKeyFactory(restaurantHash, entityKey)()

export const useRestaurantCacheKeyFactory = (queryKey: QueryKey = []) => {
  const restaurantHash = useRestaurantHash()

  return React.useCallback(
    (
      entityKey: QueryKey = [],
      restaurantId?: RestaurantInterface['hash'],
    ): QueryKey =>
      getRestaurantCacheKeyFactory(
        restaurantId ?? restaurantHash,
        queryKey,
      )(entityKey),
    [queryKey, restaurantHash],
  )
}

export const useRestaurantCacheKey = (queryKey: QueryKey = []): QueryKey => {
  const cacheKeyFactory = useRestaurantCacheKeyFactory(queryKey)

  return React.useMemo(() => cacheKeyFactory([]), [cacheKeyFactory])
}
