import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { type Room } from 'src/entities/schedule/types/scheduleApi'
import SingleSelectDropdown from 'src/shared/components/form/inputs/SingleSelectDropdown'
import { useShiftEventFormController } from '../../hooks/form'

interface RoomsSectionSelectProps {
  rooms: Room[]
  roomsEnabled: boolean
}

export const RoomsSectionSelect = ({
  rooms,
  roomsEnabled,
}: RoomsSectionSelectProps) => {
  const { t } = useTranslation()

  const { field } = useShiftEventFormController('roomId')

  const roomIds = useMemo(() => [0, ...rooms.map(({ id }) => id)], [rooms])
  const roomMap = useMemo(() => new Map(rooms.map(r => [r.id, r])), [rooms])

  if (!roomsEnabled) return null

  return (
    <SingleSelectDropdown
      options={roomIds}
      value={field.value}
      renderOption={id =>
        roomMap.get(id)?.name ??
        t('schedule.shifts.rooms_section.all_rooms', 'All rooms')
      }
      onChange={field.onChange}
      sx={{ maxWidth: 360 }}
      label={t('schedule.shifts.rooms_section.title', 'Rooms')}
    />
  )
}
