import { andThen, composeWith, prop } from 'ramda'

import { timelineShiftsSchema } from 'src/pages/Schedule/Timeline/timelineShift'
import { useBetterApiClient } from 'src/shared/lib/api/hooks/useBetterApiClient'
import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import {
  getUtcDayStart,
  toApiDateString,
} from 'src/shared/lib/range/services/date'
import {
  notificationTemplatesResponseSchema,
  scheduleActivityLogEntrySchema,
  scheduleSchema,
  type BookingLock,
  type Closure,
  type PaymentPlan,
  type PhoneAcceptanceTimes,
  type PhoneAcceptanceTimesException,
  type ReservationQuestion,
  type Room,
  type Schedule,
  type ScheduleAction,
  type ScheduleActivityLogEntry,
  type ScheduleDefaultSettings,
  type ScheduleEvent,
  type Shift,
  type ShiftException,
} from '../types/scheduleApi'

export const useScheduleApiClient = (restaurantHash?: string) =>
  useBetterApiClient({ restaurantHash, baseEntity: 'schedule' })

// SCHEDULE
export const getSchedule =
  (httpClient: ApiClient) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async (): Promise<Schedule> =>
    composeWith(andThen)([
      (d: unknown) => scheduleSchema.parse(d),
      convertKeysToCamelCase,
      prop('schedule'),
      httpClient,
    ])({
      method: 'GET',
      url: ``,
    })

// SCHEDULE ACTIVITY
export interface GetScheduleActivityLogOptions {
  dateFrom?: Date
  dateTo?: Date
  eventTypes?: ScheduleAction[]
}

export const getScheduleActivityLog =
  (httpClient: ApiClient) =>
  async ({
    dateFrom,
    dateTo,
    eventTypes,
  }: GetScheduleActivityLogOptions): // eslint-disable-next-line @typescript-eslint/require-await
  Promise<ScheduleActivityLogEntry[]> => {
    const formattedParams = {
      ...(dateFrom && { dateFrom: toApiDateString(dateFrom) }),
      ...(dateTo && { dateTo: toApiDateString(dateTo) }),
      ...(eventTypes?.length && { eventTypes: eventTypes.join(',') }),
    }

    return composeWith(andThen)([
      (d: unknown) => scheduleActivityLogEntrySchema.array().parse(d),
      prop('events'),
      prop('history'),
      httpClient,
    ])({
      method: 'GET',
      url: `history`,
      searchParams: convertKeysToSnakeCase(formattedParams),
    })
  }

// BATCH
interface Command {
  name: string
  command: Record<string, unknown>
  objectId?: string | number
}

export const batchEditSchedule =
  (httpClient: ApiClient) => (commands: Command[]) =>
    httpClient({
      method: 'POST',
      url: `batch`,
      json: convertKeysToSnakeCase({ commands }),
    }) as Promise<{ status: string }>

// DEFAULT SETTINGS
export const editDefaultSettings =
  (httpClient: ApiClient) => (defaultSettings: ScheduleDefaultSettings) =>
    httpClient({
      method: 'PUT',
      url: `default_settings`,
      json: convertKeysToSnakeCase(defaultSettings),
    }) as Promise<{ status: string }>

// SHIFT
export const addShift = (httpClient: ApiClient) => (shift: Omit<Shift, 'id'>) =>
  httpClient({
    method: 'POST',
    url: `shift`,
    json: convertKeysToSnakeCase({ shiftConfig: shift }),
  }) as Promise<{ status: string }>

export const editShift =
  (httpClient: ApiClient) =>
  ({ id, ...data }: Shift) =>
    httpClient({
      method: 'PUT',
      url: `shift/${id}`,
      json: convertKeysToSnakeCase({ shiftConfig: data }),
    }) as Promise<{ status: string }>

export const deleteShift =
  (httpClient: ApiClient) =>
  ({ id }: Shift) =>
    httpClient({
      method: 'DELETE',
      url: `shift/${id}`,
    }) as Promise<{ status: string }>

// SHIFT EXCEPTION
export const addShiftException =
  (httpClient: ApiClient) => (exception: Omit<ShiftException, 'id'>) =>
    httpClient({
      method: 'POST',
      url: `shift_exception`,
      json: convertKeysToSnakeCase(exception),
    }) as Promise<{ status: string }>

export const editShiftException =
  (httpClient: ApiClient) =>
  ({ id, ...data }: ShiftException) =>
    httpClient({
      method: 'PUT',
      url: `shift_exception/${id}`,
      json: convertKeysToSnakeCase(data),
    }) as Promise<{ status: string }>

export const deleteShiftException =
  (httpClient: ApiClient) =>
  ({ id }: ShiftException) =>
    httpClient({
      method: 'DELETE',
      url: `shift_exception/${id}`,
    }) as Promise<{ status: string }>

// EVENT
export const addEvent =
  (httpClient: ApiClient) => (event: Omit<ScheduleEvent, 'id'>) =>
    httpClient({
      method: 'POST',
      url: `event`,
      json: convertKeysToSnakeCase({
        effectivePeriod: {
          start: event.effectivePeriod.start,
          end: event.effectivePeriod.end,
        },
        shiftConfig: event,
      }),
    }) as Promise<{ status: string }>

export const editEvent =
  (httpClient: ApiClient) =>
  ({ id, ...data }: ScheduleEvent) =>
    httpClient({
      method: 'PUT',
      url: `event/${id}`,
      json: convertKeysToSnakeCase({
        effectivePeriod: {
          start: data.effectivePeriod.start,
          end: data.effectivePeriod.end,
        },
        shiftConfig: data,
      }),
    }) as Promise<{ status: string }>

export const deleteEvent =
  (httpClient: ApiClient) =>
  ({ id }: ScheduleEvent) =>
    httpClient({
      method: 'DELETE',
      url: `event/${id}`,
    }) as Promise<{ status: string }>

// PAYMENT PLAN
export const addPaymentPlan =
  (httpClient: ApiClient) => (paymentPlan: Omit<PaymentPlan, 'id'>) =>
    httpClient({
      method: 'POST',
      url: `payment_plan`,
      json: convertKeysToSnakeCase(paymentPlan),
    }) as Promise<{ status: string }>

export const editPaymentPlan =
  (httpClient: ApiClient) =>
  ({ id, ...data }: PaymentPlan) =>
    httpClient({
      method: 'PUT',
      url: `payment_plan/${id}`,
      json: convertKeysToSnakeCase(data),
    }) as Promise<{ status: string }>

export const deletePaymentPlan =
  (httpClient: ApiClient) =>
  ({ id }: PaymentPlan) =>
    httpClient({
      method: 'DELETE',
      url: `payment_plan/${id}`,
    }) as Promise<{ status: string }>

// RESERVATION QUESTION
export const addReservationQuestion =
  (httpClient: ApiClient) =>
  (reservationQuestion: Omit<ReservationQuestion, 'id'>) =>
    httpClient({
      method: 'POST',
      url: `reservation_question`,
      json: convertKeysToSnakeCase(reservationQuestion),
    }) as Promise<{ status: string }>

export const editReservationQuestion =
  (httpClient: ApiClient) =>
  ({ id, ...data }: ReservationQuestion) =>
    httpClient({
      method: 'PUT',
      url: `reservation_question/${id}`,
      json: convertKeysToSnakeCase(data),
    }) as Promise<{ status: string }>

export const deleteReservationQuestion =
  (httpClient: ApiClient) =>
  ({ id }: ReservationQuestion) =>
    httpClient({
      method: 'DELETE',
      url: `reservation_question/${id}`,
    }) as Promise<{ status: string }>

// PHONE ACCEPTANCE TIME
export const editPhoneAcceptanceTimes =
  (httpClient: ApiClient) => (phoneAcceptanceTimes: PhoneAcceptanceTimes) =>
    httpClient({
      method: 'PUT',
      url: `phone_acceptance_times`,
      json: convertKeysToSnakeCase(phoneAcceptanceTimes),
    }) as Promise<{ status: string }>

// PHONE ACCEPTANCE TIME EXCEPTION
export const addPhoneAcceptanceTimesException =
  (httpClient: ApiClient) =>
  (phoneAcceptanceTimes: Omit<PhoneAcceptanceTimesException, 'id'>) =>
    httpClient({
      method: 'POST',
      url: `phone_acceptance_times_exception`,
      json: convertKeysToSnakeCase(phoneAcceptanceTimes),
    }) as Promise<{ status: string }>

export const editPhoneAcceptanceTimesException =
  (httpClient: ApiClient) =>
  ({ id, ...data }: PhoneAcceptanceTimesException) =>
    httpClient({
      method: 'PUT',
      url: `phone_acceptance_times_exception/${id}`,
      json: convertKeysToSnakeCase(data),
    }) as Promise<{ status: string }>

export const deletePhoneAcceptanceTimesException =
  (httpClient: ApiClient) =>
  ({ id }: PhoneAcceptanceTimesException) =>
    httpClient({
      method: 'DELETE',
      url: `phone_acceptance_times_exception/${id}`,
    }) as Promise<{ status: string }>

// CLOSE
export const addClosure =
  (httpClient: ApiClient) => (lock: Omit<Closure, 'id'>) =>
    httpClient({
      method: 'POST',
      url: `close`,
      json: convertKeysToSnakeCase(lock),
    }) as Promise<{ status: string }>

export const editClosure =
  (httpClient: ApiClient) =>
  ({ id, ...data }: Closure) =>
    httpClient({
      method: 'PUT',
      url: `close/${id}`,
      json: convertKeysToSnakeCase(data),
    }) as Promise<{ status: string }>

export const deleteClosure =
  (httpClient: ApiClient) =>
  ({ id }: Closure) =>
    httpClient({
      method: 'DELETE',
      url: `close/${id}`,
    }) as Promise<{ status: string }>

// LOCK

/**
 * This is a magic endpoint that allows *creation*, *editing*,
 * and *deletion* of booking locks.
 *
 * It works by you providing a `date` and a `shiftId` and the `channels`
 * on which you want the shift to be locked on the specified date.
 *
 * To *delete* you just provide an empty array as channels.
 *
 * The response says nothing, you need to invalidate and refresh
 * schedule and/or shift instances
 */
export const upsertBookingLock =
  (httpClient: ApiClient) => (lock: Omit<BookingLock, 'id'>) =>
    httpClient({
      method: 'POST',
      url: `lock/resolve`,
      json: convertKeysToSnakeCase(lock),
    }) as Promise<{ status: string }>

export const deleteBookingLock =
  (httpClient: ApiClient) =>
  (lock: Omit<BookingLock, 'id' | 'bookingChannels'>) =>
    upsertBookingLock(httpClient)({ ...lock, bookingChannels: [] })

export const editBookingLock =
  (httpClient: ApiClient) =>
  async ({
    oldLock,
    newLock,
  }: {
    oldLock: Omit<BookingLock, 'id' | 'bookingChannels'>
    newLock: Omit<BookingLock, 'id'>
  }) => {
    await deleteBookingLock(httpClient)(oldLock)
    return upsertBookingLock(httpClient)(newLock)
  }

// ROOM
export const addRoom =
  (httpClient: ApiClient) => (room: Omit<Room, 'id' | 'hash'>) =>
    httpClient({
      method: 'POST',
      url: `room`,
      json: room,
    }) as Promise<{ status: string }>

export const editRoom =
  (httpClient: ApiClient) =>
  ({ id, ...data }: Room) =>
    httpClient({
      method: 'PUT',
      url: `room/${id}`,
      json: data,
    }) as Promise<{ status: string }>

export const deleteRoom =
  (httpClient: ApiClient) =>
  ({ id }: Room) =>
    httpClient({
      method: 'DELETE',
      url: `room/${id}`,
    }) as Promise<{ status: string }>

export const batchEditRooms = (httpClient: ApiClient) => (rooms: Room[]) =>
  batchEditSchedule(httpClient)(
    rooms.map(r => ({
      name: 'modify_room',
      objectId: r.id,
      command: {
        name: r.name,
        selectable: r.selectable,
        position: r.position,
      },
    })),
  )

// NOTIFICATION TEMPLATES
export const getNotificationsTemplates = (httpClient: ApiClient) => () =>
  composeWith(andThen)([
    (d: unknown) => notificationTemplatesResponseSchema.parse(d).templates,
    convertKeysToCamelCase,
    httpClient,
  ])({
    method: 'GET',
    url: `notifications/templates`,
  })

export const getDailyPax = (httpClient: ApiClient) => (date: Date) =>
  composeWith(andThen)([
    (d: unknown) => timelineShiftsSchema.parse(d),
    convertKeysToCamelCase,
    httpClient,
  ])({
    method: 'GET',
    url: 'overview',
    searchParams: { date: getUtcDayStart(date).toJSON() },
  })
