import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useInfoQuery } from 'src/entities/info/queries/info'
import { type ScheduleLanguage } from 'src/entities/schedule/types/scheduleApi'
import FormErrorText from 'src/shared/components/form/common/FormErrorText/FormErrorText'
import { TextArea } from 'src/shared/components/form/inputs/TextArea'
import { useBaseFormContext } from '../../../../../../hooks/form'
import { translateLanguage } from '../LanguageTabs/LanguageTabs'

interface MessageInputProps {
  selectedLanguage: ScheduleLanguage
}

const MessageInput = ({ selectedLanguage }: MessageInputProps) => {
  const { t } = useTranslation()

  const {
    data: { language: restaurantLang },
  } = useInfoQuery()

  const {
    register,
    watch,
    formState: { errors },
  } = useBaseFormContext()

  const message = watch(`shiftMessage.message.${selectedLanguage}`) ?? ''

  return (
    <Stack direction="column" spacing={0.5}>
      <TextArea
        key={selectedLanguage}
        slotProps={{ htmlInput: { maxLength: 255 } }}
        minRows={3}
        placeholder={t(
          'schedule.shifts.message_section.message_placeholder',
          'Your message...',
        )}
        {...register(`shiftMessage.message.${selectedLanguage}`, {
          setValueAs: (v: string) => v || null,
        })}
      />
      <Typography variant="labelTiny" color="grey.500">
        {t('common.characterLimit', {
          limit: 255 - message.length,
          defaultValue: 'Characters left: {{ limit }}',
        })}
      </Typography>
      {errors.shiftMessage && (
        <FormErrorText
          errorText={t(
            'schedule.shifts.message_section.errors.no_default_empty',
            {
              defaultValue: `Restaurant language field can't be empty ({{language}})`,
              language: translateLanguage(t)(restaurantLang),
            },
          )}
        />
      )}
    </Stack>
  )
}

export default MessageInput
