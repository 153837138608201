import { useEffect } from 'react'

import { type TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { type Room } from 'src/entities/schedule/types/scheduleApi'
import { useEventShiftFormContext } from '../../hooks/form'
import {
  type EventOverlap,
  type ExceptionOverlap,
} from '../../services/periodAwareValidation'
import { type ShiftOverlap } from '../../services/shiftValidation'
import Deadline from '../BookingSection/components/Deadline/Deadline'
import ArrivalTime from '../GeneralSection/components/ArrivalTime/ArrivalTime'
import GeneralSectionContainer from '../GeneralSection/components/GeneralSectionContainer'
import Name from '../GeneralSection/components/Name'
import Period from '../GeneralSection/components/Period'
import ShiftDisable from '../GeneralSection/components/ShiftDisable'
import Weekdays from '../GeneralSection/components/Weekdays'
import ShiftLimit from '../LimitsSection/components/ShiftLimit/ShiftLimit'
import { RoomsSectionSelect } from '../RoomsSection/RoomsSectionSelect'

type FormType = 'shift' | 'event' | 'exception'

interface ShiftOrEventProps {
  type: 'shift' | 'event'
  intersections: (ShiftOverlap | EventOverlap | ExceptionOverlap)[]
  rooms: Room[]
  roomsEnabled: boolean
}

interface ExceptionProps {
  type: 'exception'
  intersections: (ShiftOverlap | EventOverlap | ExceptionOverlap)[]
  shiftDisabled: boolean
}

type SimpleFormSectionProps = ShiftOrEventProps | ExceptionProps

const getNameLabel = (formType: FormType, t: TFunction) => {
  switch (formType) {
    case 'shift':
      return t('schedule.shifts.general_section.name.label', 'Shift name')
    case 'event':
      return t('schedule.events.general_section.name.label', 'Event name')
    default:
      return ''
  }
}

const getPeriodLabel = (formType: FormType, t: TFunction) => {
  switch (formType) {
    case 'exception':
      return t(
        'schedule.exceptions.general_section.period.label',
        'Exception valid dates',
      )
    case 'event':
      return t('schedule.events.general_section.period.label', 'Event dates')
    default:
      return ''
  }
}

const useInheritShiftLimitToChannelsEffect = () => {
  const { watch, setValue, getValues } = useEventShiftFormContext()
  const shiftLimit = watch('shiftGuestCountLimit')

  useEffect(() => {
    setValue(
      'channelAllocations',
      getValues('channelAllocations').map(l => ({ ...l, limit: shiftLimit })),
    )
  }, [getValues, setValue, shiftLimit])
}

export const SimpleFormSection = (props: SimpleFormSectionProps) => {
  const { intersections, type, rooms, shiftDisabled, roomsEnabled } = {
    rooms: undefined,
    shiftDisabled: undefined,
    roomsEnabled: false,
    ...props,
  }
  const { t } = useTranslation()

  const hasName = type === 'shift' || type === 'event'
  const hasEffectivePeriod = type === 'exception' || type === 'event'
  const hasRooms = type !== 'exception'
  const hasDisableShift = type === 'exception'

  useInheritShiftLimitToChannelsEffect()

  return (
    <GeneralSectionContainer>
      {hasName && <Name label={getNameLabel(type, t)} />}
      {hasRooms && (
        <RoomsSectionSelect rooms={rooms} roomsEnabled={roomsEnabled} />
      )}
      {hasEffectivePeriod && <Period label={getPeriodLabel(type, t)} />}
      <Weekdays
        disabled={type === 'exception'}
        checkEffectivePeriod={type === 'event'}
      />
      {hasDisableShift && <ShiftDisable />}
      {!shiftDisabled && (
        <>
          <ArrivalTime intersections={intersections} />
          <Deadline />
          <ShiftLimit />
        </>
      )}
    </GeneralSectionContainer>
  )
}
