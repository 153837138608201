import { complement } from 'ramda'

import { type CSSObject } from '@emotion/react'

import { includesTime } from 'src/shared/lib/range/services/timeRange'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'

export interface Holiday {
  range: DefinedRangeInterface<Date>
  roomId: number
}

const hasRoom = (holiday: Holiday) => !!holiday.roomId

export const getRoomHolidays = (holidays: Holiday[]) => holidays.filter(hasRoom)

export const getGlobalHolidays = (holidays: Holiday[]) =>
  holidays.filter(complement(hasRoom))

interface GetBackgroundParams {
  day: Date
  globalHolidays: Holiday[]
  roomHolidays: Holiday[]
  overrideHover?: boolean
}

const holidayBgColor = '#f7d8d9'

const halfHolidayGradient = `repeating-linear-gradient(
  45deg,
  #f7d8d9,
  #f7d8d9 10px,
  #e0e0e0 10px,
  #e0e0e0 20px
)`

export const getHolidayBackground = ({
  day,
  globalHolidays,
  roomHolidays,
  overrideHover = false,
}: GetBackgroundParams): CSSObject => {
  if (globalHolidays.some(h => includesTime(h.range, day))) {
    return {
      backgroundColor: holidayBgColor,
      ...(overrideHover && {
        [`&:hover, &[aria-selected='true']`]: {
          backgroundColor: holidayBgColor,
        },
      }),
    }
  }

  if (roomHolidays.some(h => includesTime(h.range, day))) {
    return {
      backgroundImage: halfHolidayGradient,
      [`&:hover, &[aria-selected='true']`]: {
        backgroundImage: 'none',
      },
      ...(overrideHover && {
        [`&:hover, &[aria-selected='true']`]: {
          backgroundImage: halfHolidayGradient,
        },
      }),
    }
  }

  return {}
}
