import React from 'react'
import { always, anyPass, cond, T } from 'ramda'
import { Stack, type StackProps, type SxProps } from '@mui/material'
import { pickersDayClasses } from '@mui/x-date-pickers'

import {
  isFromSelection,
  isModifiedRangeSelection,
  isRangeSelection,
} from '../DateRangePicker/service/dateRangePicker'
import { type SelectionState } from '../DateRangePicker/types/selectionState'
import { inRangeClass } from '../RangePickerItem'

const item = `.${pickersDayClasses.root}:not(.${pickersDayClasses.hiddenDaySpacingFiller})`
const hoveredItem = `${item}:hover:not(${pickersDayClasses.selected})`
const selectedItem = `${item}.${pickersDayClasses.selected}`

const previewItemStyle: SxProps = {
  [`&:not(.${inRangeClass}):not(:hover)`]: {
    '&:focus': {
      backgroundColor: 'grey.50',
    },
    backgroundColor: 'grey.50',
    borderRadius: 0,
  },
}

const applyStyleBetweenItems =
  (style: SxProps) =>
  (startItem: string, endItem: string): SxProps => ({
    [`*:has(${startItem}) ~ * ${item}`]: {
      [`&:not(:has(${endItem}) ~ * ${item})`]: style,
    },
  })

export type RangePickerContainerProps = StackProps & {
  selectionState: SelectionState
}

const RangePickerContainer = ({
  selectionState,
  ...props
}: RangePickerContainerProps) => {
  const style: SxProps = React.useMemo(
    () =>
      cond([
        [
          isRangeSelection,
          always({
            [`&:has(${selectedItem})`]: applyStyleBetweenItems(
              previewItemStyle,
            )(hoveredItem, selectedItem),
            [`&:not(:has(${selectedItem}))`]: {
              [`*:has(${hoveredItem}) ~ * ${item}`]: previewItemStyle,
            },
          }),
        ],
        [
          anyPass([isFromSelection, isModifiedRangeSelection]),
          always({
            [`&:has(${selectedItem})`]: applyStyleBetweenItems(
              previewItemStyle,
            )(selectedItem, hoveredItem),
            [`&:not(:has(${selectedItem}))`]: {
              [`*:has(~ * ${hoveredItem}) ${item}`]: previewItemStyle,
            },
          }),
        ],
        [T, always({})],
      ])(selectionState),
    [selectionState],
  )

  return (
    <Stack
      sx={{
        [`.${pickersDayClasses.disabled}`]: {
          color: 'grey.300',
        },
        [`&:hover:has(${hoveredItem})`]: React.useDeferredValue(style),
      }}
      {...props}
    />
  )
}

export default RangePickerContainer
