import { reject, uniq } from 'ramda'
import { Box } from '@mui/material'

import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { useNotificationsTemplatesQuery } from 'src/entities/schedule/queries/schedule'
import { replaceBy } from 'src/shared/lib/common/services/functional/functional'
import NotificationItem from './Notifications/components/NotificationItem/NotificationItem'
import { InputGroupColumnHeader } from '../../../../InputGroupColumnHeader/InputGroupColumnHeader'
import { useBaseFormController } from '../../../hooks/form'
import type { BaseForm } from '../../../services/formSchema'

type Notification = BaseForm['notifications']['templates'][number]

const translateChannel = (t: TFunction) => (channel: Notification['channel']) =>
  t(
    `schedule.shifts.notifications_section.notification_channel.${channel}`,
    channel,
  )

const Notifications = () => {
  const { t } = useTranslation()

  const { data: templates } = useNotificationsTemplatesQuery()

  const channels = uniq(templates.map(template => template.channel))
  const types = uniq(templates.map(template => template.type))

  const { field } = useBaseFormController('notifications.templates')

  const onNotificationChange = (
    channel: Notification['channel'],
    type: Notification['type'],
    notification: Notification | null,
  ) => {
    if (!notification) {
      field.onChange(
        reject(
          (n: Notification) => n.channel === channel && n.type === type,
          field.value,
        ),
      )
      return
    }

    field.onChange(
      replaceBy(n => `${n.channel}_${n.type}`, notification, field.value),
    )
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `repeat(${channels.length + 1}, minmax(0, 168px))`,
        rowGap: 1,
        columnGap: 2,
        alignItems: 'center',
      }}
    >
      <InputGroupColumnHeader>
        {t(
          'schedule.shifts.notifications_section.notification_header',
          'Notification',
        )}
      </InputGroupColumnHeader>
      {channels.map(channel => (
        <InputGroupColumnHeader key={channel}>
          {translateChannel(t)(channel)}
        </InputGroupColumnHeader>
      ))}
      {types.map(type => (
        <NotificationItem
          key={type}
          type={type}
          notifications={field.value}
          templates={templates}
          channels={channels}
          onNotificationChange={onNotificationChange}
        />
      ))}
    </Box>
  )
}

export default Notifications
