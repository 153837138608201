import {
  Checkbox,
  Stack,
  useFormControl,
  useThemeProps,
  type CheckboxProps,
} from '@mui/material'

import { getGapSize } from 'src/app/theme/theme'
import SizeableMenuItem, {
  type SizeableMenuItemProps,
} from '../../../common/SizeableMenuItem'

const MultiselectDropdownItem = ({
  forceSelected,
  ...inProps
}: SizeableMenuItemProps & { forceSelected?: boolean }) => {
  const formControlState = useFormControl() ?? {}

  const { children, ...props } = useThemeProps({
    props: { ...formControlState, ...inProps },
    name: 'SizeableMenuItem',
  })

  return (
    <SizeableMenuItem {...props} disableGutters>
      <Stack
        direction="row"
        sx={{ flex: 1, alignItems: 'center' }}
        spacing={getGapSize(props.size)}
      >
        <Checkbox
          checked={!!forceSelected || props.selected}
          size={props.size as CheckboxProps['size']}
        />
        {children}
      </Stack>
    </SizeableMenuItem>
  )
}

export default MultiselectDropdownItem
