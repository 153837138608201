import { Button, type ButtonProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import CheckmarkIcon from 'src/shared/components/icons/CheckmarkIcon'

const SavedButton = (props: ButtonProps) => {
  const { t } = useTranslation('', { keyPrefix: 'serial_reservations' })

  return (
    <Button
      {...props}
      color="success"
      variant="contained"
      size="small"
      startIcon={<CheckmarkIcon />}
    >
      {t('saved')}
    </Button>
  )
}

export default SavedButton
