import { Box, styled } from '@mui/material'

const NotificationBadge = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'primaryPalette.50',
    color: 'primaryPalette.600',
    typography: 'labelSmall',
    px: 1,
    py: '1px',
    borderRadius: 3,
  }),
)

export default NotificationBadge
