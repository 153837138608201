import { getForTime } from 'src/entities/availability/services/availability'
import {
  type AvailabilityInterface,
  type SlotInterface,
} from 'src/entities/availability/types/availability'
import { getAvailabilityStatus } from './availability'

export const createSlot =
  (availabilities: AvailabilityInterface[], seatCount: number) =>
  (time: Date): SlotInterface => {
    const availability = getForTime(time)(availabilities)

    return {
      time,
      availability,
      status: getAvailabilityStatus(seatCount)(availability),
    }
  }
