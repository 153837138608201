import { useCallback, useMemo, useRef } from 'react'
import { Box, Button, ButtonGroup, Popover } from '@mui/material'

import { type Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'

import { useHolidaysQuery } from 'src/entities/holidays/queries/holidays'
import { TopBarDatePickerDay } from 'src/pages/Reservations/components/TopBar/components/TopBarDatePicker'
import {
  getGlobalHolidays,
  getRoomHolidays,
} from 'src/pages/Reservations/components/TopBar/service/holidays'
import IconButton from 'src/shared/components/buttons/IconButton'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { DatePickerCalendar } from 'src/shared/components/form/inputs/DatePicker/components/DatePickerCalendar'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'src/shared/components/icons/ChevronIcon'
import dayjs, {
  addToDate,
  formatShortDate,
  getNowInRestaurantTimezone,
} from 'src/shared/lib/range/services/date'

interface DateSelectionProps {
  date: Date
  onDateChange: (date: Date) => void
}

export const DateSelection = ({ date, onDateChange }: DateSelectionProps) => {
  const { t } = useTranslation()

  const { data: holidays } = useHolidaysQuery()

  const globalHolidays = useMemo(() => getGlobalHolidays(holidays), [holidays])
  const roomHolidays = useMemo(() => getRoomHolidays(holidays), [holidays])
  const pickerDate = useMemo(() => dayjs(date), [date])

  const ref = useRef<HTMLDivElement | null>(null)
  const { open, handleClose, handleOpen } = useDialog()

  const pickerDateChangeHandler = useCallback(
    (d: Dayjs | null) => {
      if (!d) return
      onDateChange(d.toDate())
    },
    [onDateChange],
  )

  return (
    <>
      <ButtonGroup
        variant="outlined"
        sx={{
          width: 1,
          backgroundColor: 'white',
        }}
        ref={ref}
      >
        <IconButton onClick={() => onDateChange(addToDate(-1, 'day', date))}>
          <ChevronLeftIcon />
        </IconButton>
        <Button sx={{ flex: 1 }} onClick={handleOpen}>
          {formatShortDate(date)}
        </Button>
        <IconButton onClick={() => onDateChange(addToDate(1, 'day', date))}>
          <ChevronRightIcon />
        </IconButton>
      </ButtonGroup>
      <Popover
        open={open}
        anchorEl={ref.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DatePickerCalendar
          displayWeekNumber
          value={pickerDate}
          onChange={pickerDateChangeHandler}
          slotProps={{
            day: {
              globalHolidays,
              roomHolidays,
              onClick: handleClose,
            },
          }}
          slots={{
            day: TopBarDatePickerDay,
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            onClick={() => {
              onDateChange(getNowInRestaurantTimezone())
              handleClose()
            }}
          >
            {t('angular.today')}
          </Button>
          <Button onClick={handleClose}>{t('angular.close')}</Button>
        </Box>
      </Popover>
    </>
  )
}
