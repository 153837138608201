import React from 'react'
import { curry, pluck } from 'ramda'
import { TableCell, type TableCellProps } from '@mui/material'

import {
  createColumnHelper,
  type CellContext,
  type ColumnDef,
} from '@tanstack/react-table'
import { useTranslation, withTranslation } from 'react-i18next'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { useLabelsQuery } from 'src/entities/label/queries/label'
import {
  ReservationColumnsIdsEnum,
  type ReservationInterface,
} from 'src/entities/reservation/types/reservation'
import { useRestaurantsInChain } from 'src/entities/restaurant/queries/restaurants'
import { useRestaurantsRooms } from 'src/entities/room/queries/room'
import { useServiceTimesQuery } from 'src/entities/service-time/queries/serviceTime'
import { createFallbackServiceTime } from 'src/entities/service-time/types/serviceTime'
import SortHeader from 'src/shared/components/common/Table/SortHeader'
import {
  combineDateWithTime,
  toTimestamp,
  type IsoDateString,
} from 'src/shared/lib/range/services/date'
import { moveToDate } from 'src/shared/lib/range/services/timeRange'
import {
  ArrivalTimeCell,
  LabelsCell,
  NotesCell,
  RoomCell,
  SeatCountCell,
  StartDateCell,
} from './TableCells'

const reservationColumnHelper = createColumnHelper<ReservationInterface>()
const Cell = ({ sx, ...props }: Partial<TableCellProps>) => (
  <TableCell
    sx={{
      verticalAlign: 'middle',
      typography: 'labelTiny',
      ...sx,
    }}
    {...props}
  />
)
/* prettier-ignore */
const SerialReservationsSortHeader =
  withTranslation('', { keyPrefix: 'serial_reservations' })(SortHeader<ReservationInterface>)

export const useColumns = (
  onReservationChange: (
    reservationIndex: number,
    value: Partial<ReservationInterface>,
  ) => void,
  serialDates: IsoDateString[],
  toggleDateSelection: (date: IsoDateString) => void,
) => {
  const { t } = useTranslation('', { keyPrefix: 'serial_reservations' })

  const { data: remoteServiceTimes } = useServiceTimesQuery()
  const restaurants = useRestaurantsInChain()
  const { data: labels } = useLabelsQuery()

  const rooms = useRestaurantsRooms()

  const serviceTimes = React.useMemo((): ServiceTimeInterface[] => {
    if (remoteServiceTimes.length) return remoteServiceTimes

    return [createFallbackServiceTime()]
  }, [remoteServiceTimes])

  return React.useMemo(() => {
    const updateByIndex = (
      cellContext: CellContext<ReservationInterface, unknown>,
    ) => curry(onReservationChange)(cellContext.row.index)

    return [
      reservationColumnHelper.display({
        id: ReservationColumnsIdsEnum.Number,
        header: () => null,
        cell: props => (
          <Cell
            sx={{
              minWidth: 24,
              width: 24,
            }}
          >
            {`${props.row.index + 1}.`}
          </Cell>
        ),
      }),
      reservationColumnHelper.accessor(
        (_, index) => new Date(serialDates[index]!),
        {
          id: ReservationColumnsIdsEnum.StartDate,
          header: header => <SerialReservationsSortHeader {...header} />,
          cell: cellContext => {
            const startDate = cellContext.getValue()
            const reservation = cellContext.row.original

            const startTime = cellContext.row.original.dateRange[0]

            return (
              <Cell
                sx={{
                  minWidth: 170,
                  width: 170,
                }}
              >
                <StartDateCell
                  reservation={reservation}
                  startTime={combineDateWithTime(startDate, startTime)}
                  selected={!cellContext.row.getIsSelected()}
                  toggleSelection={() =>
                    toggleDateSelection(startDate.toISOString())
                  }
                />
              </Cell>
            )
          },
          sortingFn: (rowA, rowB, columnId) =>
            toTimestamp(rowA.getValue(columnId)) -
            toTimestamp(rowB.getValue(columnId)),
          enableSorting: true,
        },
      ),
      reservationColumnHelper.accessor('dateRange', {
        id: ReservationColumnsIdsEnum.ArrivalTime,
        header: () => null,
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 150,
              width: 150,
            }}
          >
            <ArrivalTimeCell
              timeRange={cellContext.getValue()}
              serviceTimes={serviceTimes}
              onChange={dateRange =>
                updateByIndex(cellContext)({
                  dateRange: moveToDate(
                    dateRange,
                    new Date(serialDates[cellContext.row.index]!),
                  ),
                })
              }
            />
          </Cell>
        ),
      }),
      reservationColumnHelper.accessor('room', {
        id: ReservationColumnsIdsEnum.Rooms,
        header: t('room'),
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 120,
              width: 256,
            }}
          >
            <RoomCell
              roomId={cellContext.getValue()}
              restaurantId={cellContext.row.original.restaurantId}
              rooms={rooms}
              restaurants={restaurants}
              onChange={room =>
                updateByIndex(cellContext)({
                  room: room.id,
                  restaurantId: room.restaurantId,
                })
              }
            />
          </Cell>
        ),
      }),
      reservationColumnHelper.accessor('seatCount', {
        id: ReservationColumnsIdsEnum.SeatCount,
        header: t('seatCount'),
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 100,
              width: 100,
            }}
          >
            <SeatCountCell
              seatCount={cellContext.getValue()}
              onChange={seatCount =>
                updateByIndex(cellContext)({
                  seatCount,
                })
              }
            />
          </Cell>
        ),
      }),
      reservationColumnHelper.accessor('labels', {
        id: ReservationColumnsIdsEnum.Labels,
        header: t('labels'),
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 120,
              width: 320,
            }}
          >
            <LabelsCell
              selectedLabelsIds={cellContext.getValue()}
              labels={labels}
              onChange={newLabels =>
                updateByIndex(cellContext)({
                  labels: pluck('id', newLabels),
                })
              }
            />
          </Cell>
        ),
      }),
      reservationColumnHelper.accessor('restaurantNote', {
        id: ReservationColumnsIdsEnum.Notes,
        header: t('restaurantNote'),
        cell: cellContext => (
          <Cell
            sx={{
              minWidth: 120,
            }}
          >
            <NotesCell
              note={cellContext.getValue()}
              onChange={restaurantNote =>
                updateByIndex(cellContext)({
                  restaurantNote,
                })
              }
            />
          </Cell>
        ),
      }),
    ] as ColumnDef<ReservationInterface>[]
  }, [
    t,
    serialDates,
    toggleDateSelection,
    serviceTimes,
    onReservationChange,
    rooms,
    restaurants,
    labels,
  ])
}
