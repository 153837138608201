import { Button, type ButtonProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import PlusIcon from 'src/shared/components/icons/PlusIcon'

const AddNoteButton = (props: ButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      variant="text"
      size="small"
      startIcon={<PlusIcon size="small" />}
      sx={{ ml: 'auto' }}
      {...props}
    >
      {t('service_time_bar.add_message_button', 'Add service note')}
    </Button>
  )
}

export default AddNoteButton
