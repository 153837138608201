const getQueryParam = (fieldName: string) => {
  const currentUrl = new URL(window.location.href)
  const fieldValue = currentUrl.searchParams.get(fieldName)

  if (!fieldValue) return sessionStorage.getItem(fieldName)

  sessionStorage.setItem(fieldName, fieldValue)

  return fieldValue
}

const useDynamicConfig = import.meta.env.MODE !== 'production'

const stripTrailingSlash = (url: string) => url.replace(/\/*$/, '')
const appendTrailingSlash = (url: string) => `${url}/`
const ensureTrailingSlash = (url: string) =>
  appendTrailingSlash(stripTrailingSlash(url))

export const getApiUrl = () => {
  const queryParamUrl =
    window.appConfig?.apiUrl ??
    getQueryParam('apiUrl') ??
    import.meta.env.PUBLIC_API_URL

  const apiUrl = ensureTrailingSlash(
    useDynamicConfig ? queryParamUrl : import.meta.env.PUBLIC_API_URL,
  )

  return apiUrl
}
