import { type ReactNode } from 'react'
import { Popover, Stack, type TextFieldProps } from '@mui/material'
import type { SelectInputProps } from '@mui/material/Select/SelectInput'

import { useTranslation } from 'react-i18next'

import { shortDateFormatter } from '../../../../lib/range/services/date'
import { type DefinedRangeInterface } from '../../../../lib/range/types/range'
import { usePopover } from '../../../dialogs/hooks/usePopover'
import CalendarIcon from '../../../icons/CalendarIcon'
import DateRangePicker from '../DateRangePicker'
import DropdownFooter from '../DropdownFooter'
import SelectInput from '../SelectInput'

interface DateRangeInputProps {
  value: DefinedRangeInterface<Date> | null
  setValue: (value: DefinedRangeInterface<Date> | null) => void
  showFooter?: boolean
  minValue?: Date
  error?: boolean
  helperText?: ReactNode
  size?: TextFieldProps['size']
  label?: string
  sx?: SelectInputProps['sx']
  inputRef?: SelectInputProps['inputRef']
  placeholder?: string
}

export const DateRangeInput = ({
  value,
  setValue,
  minValue,
  error,
  helperText,
  size = 'medium',
  label,
  placeholder,
  showFooter = true,
  sx,
  inputRef,
}: DateRangeInputProps) => {
  const {
    i18n: { language },
  } = useTranslation()

  const { open, setAnchorEl, handleClose, anchorEl } = usePopover()

  return (
    <>
      <SelectInput
        value={
          !!value?.[0] && !!value[1]
            ? shortDateFormatter(language).formatRange(...value)
            : ''
        }
        placeholder={placeholder}
        onClick={event => setAnchorEl(event.currentTarget)}
        IconComponent={CalendarIcon}
        error={error}
        helperText={helperText}
        size={size}
        label={label}
        sx={sx}
        inputRef={inputRef}
      />
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Stack direction="column">
          <Stack direction="column" sx={{ p: 2 }} spacing={1}>
            <DateRangePicker
              value={value}
              onChange={setValue}
              minValue={minValue}
            />
          </Stack>
          {showFooter && (
            <DropdownFooter
              onClear={() => setValue(null)}
              onDone={handleClose}
            />
          )}
        </Stack>
      </Popover>
    </>
  )
}
