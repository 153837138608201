import { append, complement, equals, filter } from 'ramda'
import { formControlLabelClasses } from '@mui/material'
import { type FormControlLabelProps } from '@mui/material/FormControlLabel/FormControlLabel'

import { type PersistedLabelInterface } from 'src/entities/label/types/label'
import Checkbox from 'src/shared/components/form/inputs/Checkbox'
import SmallLabel from 'src/widgets/Label/SmallLabel'

const Item = ({
  label,
  selectedLabels,
  onLabelsChange,
  ...props
}: {
  label: PersistedLabelInterface
  selectedLabels: PersistedLabelInterface[]
  onLabelsChange: (labels: PersistedLabelInterface[]) => void
} & Partial<Omit<FormControlLabelProps, 'label'>>) => (
  <Checkbox
    label={
      <SmallLabel
        label={label}
        sx={{ cursor: 'pointer', overflow: 'hidden' }}
      />
    }
    checked={selectedLabels.includes(label)}
    onChange={event => {
      if (event.target.checked) {
        onLabelsChange(append(label, selectedLabels))
        return
      }

      onLabelsChange(filter(complement(equals(label)), selectedLabels))
    }}
    labelProps={{
      ...props,
      sx: {
        ...props.sx,
        [`.${formControlLabelClasses.label}`]: { minWidth: 0 },
      },
    }}
  />
)

export default Item
