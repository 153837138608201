import { useTranslation } from 'react-i18next'

import ArrivalTime from './components/ArrivalTime/ArrivalTime'
import Duration from './components/Duration'
import GeneralSectionContainer from './components/GeneralSectionContainer'
import Interval from './components/Interval'
import Period from './components/Period'
import ShiftDisable from './components/ShiftDisable'
import Weekdays from './components/Weekdays'
import { useExceptionFormContext } from '../../hooks/form'
import type {
  EventOverlap,
  ExceptionOverlap,
} from '../../services/periodAwareValidation'
import GuestRange from '../LimitsSection/components/GuestRange/GuestRange'

interface ExceptionGeneralSectionProps {
  intersections: (EventOverlap | ExceptionOverlap)[]
}

const ExceptionGeneralSection = ({
  intersections,
}: ExceptionGeneralSectionProps) => {
  const { t } = useTranslation()

  const { watch } = useExceptionFormContext()
  const shiftDisabled = watch('shiftDisabled')

  return (
    <GeneralSectionContainer>
      <Period
        label={t(
          'schedule.exceptions.general_section.period.label',
          'Exception valid dates',
        )}
      />
      <Weekdays disabled />
      <ShiftDisable />
      {!shiftDisabled && (
        <>
          <ArrivalTime intersections={intersections} />
          <Interval />
          <Duration />
          <GuestRange />
        </>
      )}
    </GeneralSectionContainer>
  )
}

export default ExceptionGeneralSection
