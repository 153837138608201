import { Box, type BoxProps } from '@mui/material'

const Badge = ({
  size = 'small',
  ...props
}: { size: 'small' | 'medium' } & BoxProps) => {
  const ifSmallElse = <T,>(small: T, otherwise: T): T =>
    size === 'small' ? small : otherwise

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'primaryPalette.100',
        color: 'primaryPalette.800',
        typography: ifSmallElse('labelExtrasmall', 'labelSmall'),
        px: ifSmallElse(1.5, 2),
        py: ifSmallElse(0.5, 1),
        borderRadius: 12,
      }}
      {...props}
    />
  )
}

export default Badge
