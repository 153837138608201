export const FULL_VISIBILITY_RATIO = 1

export const countVisibleItemsOnce =
  (
    setCount: (count: number) => void,
    intersectionRatio = FULL_VISIBILITY_RATIO,
  ) =>
  (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    const visibleItems = entries.filter(
      entry => entry.intersectionRatio === intersectionRatio,
    ).length

    entries.forEach(entry => observer.unobserve(entry.target))

    setCount(visibleItems)
  }

export const observeItem =
  (observer: IntersectionObserver | undefined | null) =>
  (item: HTMLElement | undefined | null) => {
    if (!item || !observer) return

    observer.observe(item)
  }
