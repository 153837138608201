import { type ButtonProps } from '@mui/material'

import CloseIcon from '../../icons/CloseIcon'
import IconButton from '../IconButton'

const CloseButton = (props: ButtonProps) => (
  <IconButton size="medium" variant="text" color="neutral" {...props}>
    <CloseIcon />
  </IconButton>
)

export default CloseButton
