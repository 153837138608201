import React from 'react'

import { useTranslation } from 'react-i18next'

import { FullScreenModal } from 'src/shared/components/dialogs/FullScreenModal/FullScreenModal'

const ActivityLog = React.lazy(() => import('./ActivityLog'))

interface ActivityLogModalProps {
  reservationId: number | undefined
  isOpen: boolean
  onClose: () => void
  hasBeenOpened?: boolean
}

export const ActivityLogModal = ({
  reservationId,
  isOpen,
  onClose,
  hasBeenOpened = true,
}: ActivityLogModalProps) => {
  const { t } = useTranslation()

  return (
    <FullScreenModal
      title={t('angular.activities')}
      isOpen={isOpen}
      onClose={onClose}
    >
      {hasBeenOpened && <ActivityLog reservationId={reservationId} />}
    </FullScreenModal>
  )
}
