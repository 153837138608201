import { type ReactNode } from 'react'
import { Stack, Typography, type StackProps } from '@mui/material'

import Tooltip from 'src/shared/components/common/Tooltip'
import FormErrorText from 'src/shared/components/form/common/FormErrorText/FormErrorText'

interface FormSectionProps extends Omit<StackProps, 'title'> {
  title: ReactNode
  icon?: ReactNode
  tooltipText?: ReactNode
  children: ReactNode
  error?: boolean
  errorText?: string
}

const FormSection = ({
  title,
  icon,
  tooltipText,
  children,
  error,
  errorText,
  ...props
}: FormSectionProps) => (
  <Stack direction="column" spacing={3} {...props}>
    <Stack gap={0.5}>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        {icon}
        {typeof title === 'string' ? (
          <Typography variant="labelBig">{title}</Typography>
        ) : (
          title
        )}
        <Tooltip text={tooltipText} />
      </Stack>
      <FormErrorText error={error} errorText={errorText} />
    </Stack>
    {children}
  </Stack>
)

export default FormSection
