import { Box } from '@mui/material'

import IconButton from 'src/shared/components/buttons/IconButton'
import GuestsIcon from 'src/shared/components/icons/GuestsIcon'

export const GuestsButton = () => (
  <IconButton
    variant="text"
    size="medium"
    color="neutral"
    disableRipple
    component={Box}
    sx={{ color: 'inherit' }}
  >
    <GuestsIcon variant="outlined" />
  </IconButton>
)
