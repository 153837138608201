import Filled from './filled.svg'
import FilledSmall from './filledSmall.svg'
import Outlined from './outlined.svg'
import { withIconContainer } from '../IconContainer'

const LocationIcon = withIconContainer(Outlined, Filled, {
  filled: FilledSmall,
})

export default LocationIcon
