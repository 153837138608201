import { pipe } from 'ramda'

import { create } from '@yornaath/batshit'

import { type ApiClient } from './api'

interface BatchedFetchInput<T> {
  httpClient: ApiClient
  identifier: T
}

type FetchAllFn<T, U> = (
  httpClient: ApiClient,
) => (identifiers: T[]) => Promise<U>

const convert =
  <T, U>(fetchAll: FetchAllFn<T, U>) =>
  (input: BatchedFetchInput<T>[]) =>
    fetchAll(input[0]!.httpClient)(input.map(({ identifier }) => identifier))

const keyResolver =
  <T>(key: keyof T) =>
  (responseData: T[], { identifier }: BatchedFetchInput<T[keyof T]>) =>
    responseData.find(d => identifier === d[key])!

const createBatcher = <T, U extends keyof T>(
  fetchAll: FetchAllFn<T[U], T[]>,
  key: U,
) =>
  create<T[], BatchedFetchInput<T[U]>, T>({
    fetcher: convert(fetchAll),
    resolver: keyResolver(key),
    // 100ms buffer with max 500ms
    scheduler: (start, latest) => {
      const spent = latest - start

      return Math.min(500 - spent, 100)
    },
  })

const toBatchedFetch =
  <T, U extends keyof T>(fn: ReturnType<typeof createBatcher<T, U>>) =>
  (httpClient: ApiClient) =>
  (identifier: T[U]) =>
    fn.fetch({ httpClient, identifier })

export const createBatchedFetch = pipe(createBatcher, toBatchedFetch)
