import React from 'react'
import { pluck } from 'ramda'
import { Skeleton, Stack, Typography } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { useReservationCustomerQuery } from 'src/entities/customer/queries/customer'
import { useReservationRoomStays } from 'src/entities/hotel-stay/queries/hotelStay'
import { formatRoomStaysRoomName } from 'src/entities/hotel-stay/services/hotelRoomStay'
import { formatGuestName } from 'src/entities/reservation/services/reservation'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import BlockedIcon from 'src/shared/components/icons/BlockedIcon'
import PendingIcon from 'src/shared/components/icons/PendingIcon'
import { isBirthday } from 'src/shared/lib/range/services/date'
import { ReservationListItemIndicators } from './ReservationListItemIndicators'
import EllipsisTypography from '../../../../Settings/Areas/EllipsisTypography'

const Indicators = ({
  reservation,
  reservationDate,
}: {
  reservation: ReservationInterface
  reservationDate: Date
}) => {
  const { data: customer } = useReservationCustomerQuery(reservation)

  const { birthdate, notes, vip, externalNotes } = customer

  const noteTypes = pluck('type', notes)

  return (
    <ReservationListItemIndicators
      showBirthdayIcon={isBirthday(birthdate, reservationDate)}
      showCommentIcon={noteTypes.includes('general')}
      showExtNotesIcon={!!externalNotes.length}
      showFoodIcon={noteTypes.includes('food')}
      showPlaceIcon={noteTypes.includes('seating')}
      showVipIcon={vip}
    />
  )
}

const typographySx = {
  lineHeight: '1rem',
  fontSize: '0.8125rem',
  fontWeight: 700,
}

export const GuestData = ({
  reservation,
  arrival,
}: {
  reservation: ReservationInterface
  arrival: Date
}) => {
  const roomStays = useReservationRoomStays(reservation)

  return (
    <Stack
      direction="row"
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 0.5,
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          gap: 0.5,
          overflow: 'hidden',
        }}
      >
        {(reservation.payment?.status === 'pending' ||
          reservation.payment?.status === 'expired') && (
          <PendingIcon size="small" />
        )}
        {!!roomStays.length && (
          <Typography sx={{ ...typographySx, flexShrink: 0 }}>
            {formatRoomStaysRoomName(roomStays)}
          </Typography>
        )}
        <EllipsisTypography
          sx={typographySx}
          {...createTestIdProps('guest-name')}
        >
          {formatGuestName(reservation.guest)}
        </EllipsisTypography>
        {reservation.guest.ban && <BlockedIcon />}
      </Stack>
      {!!reservation.guest.customerId && (
        <React.Suspense fallback={<Skeleton variant="text" sx={{ w: 1 }} />}>
          <Indicators reservation={reservation} reservationDate={arrival} />
        </React.Suspense>
      )}
    </Stack>
  )
}
