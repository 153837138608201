import { type TFunction } from 'i18next'

import { ReservationActions } from 'src/pages/ReservationDrawer/Footer/reservationActions'
import { type FeatureName } from '../../info/types/info'
import {
  hasEmail,
  hasPhoneNumber,
} from '../../reservation/services/reservation'
import type { ReservationInterface } from '../../reservation/types/reservation'
import {
  type Shift,
  type ShiftCommunicationTemplate,
} from '../../shift-instance/types/shift'
import { type CommunicationTemplate } from '../types/communicationTemplates'

const shiftActionToTemplateAction: {
  [key in ShiftCommunicationTemplate['action']]:
    | CommunicationTemplate['action']
    | null
} = {
  mutation: 'update',
  confirmation: 'create',
  cancel: 'cancel',
  cancelByGuest: null,
  reminder: null,
  feedback: null,
}

export const noTemplate: CommunicationTemplate = {
  id: -1,
  action: 'all',
  name: '',
  default: false,
}

export const shouldBeSent = (
  template: CommunicationTemplate | undefined,
): template is CommunicationTemplate => !!template && template !== noTemplate

export const standardTemplate: CommunicationTemplate = {
  id: 0,
  action: 'all',
  name: '',
  default: true,
}

type NonEmptyArray<T> = [T, ...T[]]

export type Templates = NonEmptyArray<CommunicationTemplate>

export const getCustomTemplatesFeature = (
  channel: ShiftCommunicationTemplate['channel'],
): FeatureName => (channel === 'sms' ? 'customSms' : 'emailText')

const isShiftChannelTemplate =
  (channel: ShiftCommunicationTemplate['channel']) =>
  (action: CommunicationTemplate['action']) =>
  (communicationTemplate: ShiftCommunicationTemplate) =>
    channel === communicationTemplate.channel &&
    action === shiftActionToTemplateAction[communicationTemplate.action]

export const getDefaultTemplate = (
  actionTemplates: Templates,
  reservationShift: Shift | null,
  channel: ShiftCommunicationTemplate['channel'],
  communicationEnabled: boolean,
) => {
  const defaultTemplate =
    actionTemplates.find(t => !communicationEnabled && t === noTemplate) ??
    actionTemplates.find(t => t.default) ??
    actionTemplates.find(t => t === standardTemplate) ??
    actionTemplates[0]

  if (!reservationShift) return () => defaultTemplate

  const isShiftTemplate = isShiftChannelTemplate(channel)

  return (action?: CommunicationTemplate['action']) => {
    if (!action) return defaultTemplate

    const shiftTemplateId = reservationShift.defaultCommunicationTemplates.find(
      isShiftTemplate(action),
    )?.id

    return communicationEnabled
      ? (actionTemplates.find(t => t.id === shiftTemplateId) ?? defaultTemplate)
      : (defaultTemplate ?? noTemplate)
  }
}

const getActionTemplates =
  (templates: CommunicationTemplate[]) =>
  (action: CommunicationTemplate['action']) =>
    templates.filter(t => t.action === action)

export const hasTemplateChoice = (templates: Templates) =>
  templates.filter(t => t !== noTemplate && !t.default).length > 0

export const getPossibleTemplates = (
  templates: CommunicationTemplate[],
  customTemplatesEnabled: boolean,
) => {
  const getCustomTemplates = getActionTemplates(templates)

  return (action?: CommunicationTemplate['action']): Templates => {
    if (!action) return [noTemplate]

    const messageAction = action === 'message'

    const customTemplates =
      messageAction || customTemplatesEnabled ? getCustomTemplates(action) : []

    const includeStandardTemplate =
      !messageAction && !customTemplates.some(t => t.default)

    return [
      noTemplate,
      includeStandardTemplate && standardTemplate,
      ...customTemplates,
    ].filter(Boolean) as Templates
  }
}

export const getTemplateName =
  (t: TFunction) => (template: CommunicationTemplate) => {
    if (template === noTemplate)
      return t('communication_templates_modal.name.no_communication', 'None')
    if (template === standardTemplate)
      return t(
        'communication_templates_modal.name.standard_template',
        'Standard',
      )
    if (template.default)
      return t('communication_templates_modal.name.standard_suffix', {
        templateName: template.name,
        defaultValue: '{{templateName}} (standard)',
      })

    return template.name
  }

const canChooseCommunicationTemplate = (reservation: ReservationInterface) => {
  const reservationHasEmail = hasEmail(reservation)
  const reservationHasPhoneNumber = hasPhoneNumber(reservation)

  return (smsTemplates: Templates, emailTemplates: Templates) =>
    (hasTemplateChoice(emailTemplates) && reservationHasEmail) ||
    (hasTemplateChoice(smsTemplates) && reservationHasPhoneNumber)
}

interface ShouldShowTemplateChoice {
  reservation: ReservationInterface
  smsEnabled: boolean
  emailEnabled: boolean
  relevantChanged: boolean
  action: ReservationActions
  smsTemplates: Templates
  emailTemplates: Templates
}

const relevantActions = [ReservationActions.Cancel, ReservationActions.Noshow]

export const shouldShowTemplateChoice = ({
  reservation,
  smsEnabled,
  emailEnabled,
  relevantChanged,
  action,
  smsTemplates,
  emailTemplates,
}: ShouldShowTemplateChoice) =>
  canChooseCommunicationTemplate(reservation)(smsTemplates, emailTemplates) &&
  (emailEnabled || smsEnabled) &&
  (relevantChanged ||
    relevantActions.includes(action) ||
    action === ReservationActions.Message)
