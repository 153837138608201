import React from 'react'
import { juxt } from 'ramda'
import { Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useAreasQuery } from 'src/entities/area/queries/area'
import { useSetExactModeMutation } from 'src/entities/reservation/queries/reservationsMutations'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useTablesQuery } from 'src/entities/table/queries/table'
import { type TableOccupancyInterface } from 'src/entities/table/types/table'
import ReservationDrawerSection from 'src/shared/components/common/ReservationDrawerSection'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import ReservationDrawerCheckbox from 'src/shared/components/form/inputs/ReservationDrawerCheckbox'
import OccupanciesList from './OccupanciesList/OccupanciesList'
import {
  useRemoveOccupancy,
  useUpdateOccupancy,
} from '../../Reservations/components/FloorPlanView/occupancies'
import ModifySerieModal from '../../Reservations/components/ModifySerieModal/ModifySerieModal'

interface OccupanciesProps {
  reservation: ReservationInterface
  onExactModeChange: (exactMode: boolean) => void
  assignSerie: boolean | undefined
  onAssignSerieChange: (assignSerie: boolean) => void
}

const Occupancies = ({
  reservation,
  onExactModeChange,
  assignSerie,
  onAssignSerieChange,
}: OccupanciesProps) => {
  const { t } = useTranslation()

  const { data: areas } = useAreasQuery()
  const { data: tables } = useTablesQuery()

  const modifySerieDialog = useDialog()

  const {
    init: initUpdate,
    resume: resumeUpdate,
    abandon: abandonUpdate,
  } = useUpdateOccupancy({
    onAssignSerie: modifySerieDialog.handleOpen,
    assignSerie,
  })

  const {
    init: initRemoval,
    resume: resumeRemoval,
    abandon: abandonRemoval,
  } = useRemoveOccupancy({
    onAssignSerie: modifySerieDialog.handleOpen,
    assignSerie,
  })

  const handleOccupancyRemoval = React.useCallback(
    (occupancy: TableOccupancyInterface) => initRemoval(reservation, occupancy),
    [reservation, initRemoval],
  )

  const { mutate: setExactMode } = useSetExactModeMutation()

  return (
    <>
      <ReservationDrawerSection title={t('angular.table')}>
        <Stack direction="column" spacing={1}>
          <ReservationDrawerCheckbox
            label={t('angular.exact_mode')}
            checked={reservation.exactMode}
            onChange={(_e, checked) => {
              onExactModeChange(checked)
              setExactMode({ reservation, exactMode: checked })
            }}
          />
          <OccupanciesList
            reservation={reservation}
            tables={tables}
            areas={areas}
            onOccupancyRemoval={handleOccupancyRemoval}
            onOccupancyChange={(table, seatCount) => {
              void initUpdate(reservation, table, seatCount)
            }}
          />
        </Stack>
      </ReservationDrawerSection>
      <ModifySerieModal
        open={modifySerieDialog.open}
        onClose={juxt([
          modifySerieDialog.handleClose,
          abandonRemoval,
          abandonUpdate,
        ])}
        onAssign={decision => {
          onAssignSerieChange(decision)
          resumeRemoval?.({ wholeSerie: decision })
          resumeUpdate?.({ wholeSerie: decision })
        }}
      />
    </>
  )
}

export default Occupancies
