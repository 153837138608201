import { useRoomsQuery } from 'src/entities/room/queries/room'
import type { Shift } from 'src/entities/shift-instance/types/shift'
import LockedPadlockIcon from 'src/shared/components/icons/LockedPadlockIcon'
import ShiftsChip from './components/ShiftsChip/ShiftsChip'
import { LockedShiftItem } from './components/ShiftsTooltip/components/TooltipItem'
import ShiftsTooltip from './components/ShiftsTooltip/ShiftsTooltip'

const LockedShiftsIndicator = ({ shifts }: { shifts: Shift[] }) => {
  const { data: rooms } = useRoomsQuery()

  if (!shifts) return null

  return (
    <ShiftsTooltip
      tooltipToggle={<ShiftsChip shifts={shifts} icon={LockedPadlockIcon} />}
    >
      {shifts.map(shift => (
        <LockedShiftItem key={shift.id} shift={shift} rooms={rooms} />
      ))}
    </ShiftsTooltip>
  )
}

export default LockedShiftsIndicator
