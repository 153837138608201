import dayjs from 'src/shared/lib/range/services/date'
import { type TodoItemsRead } from '../types/notification'

interface RawEventData {
  itemId: number
  occuredOn: string
}

export const fromEventData = (data: RawEventData): TodoItemsRead => ({
  id: data.itemId.toString(),
  date: dayjs(data.occuredOn).toDate(),
})
