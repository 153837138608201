import React from 'react'
import { type PickersDayProps } from '@mui/x-date-pickers'

import { type Dayjs } from 'dayjs'

import Container from './Container'
import Day from './Day'
import MonthPane from './MonthPane'
import { useDateRangePickerState } from './useDateRangePickerState'
import { useMonthChange } from './useMonthChange'
import dayjs from '../../../../lib/range/services/date'
import { type DefinedRangeInterface } from '../../../../lib/range/types/range'
import ChevronButton from '../../../buttons/ChevronButton'

const DateRangePicker = ({
  value: timeRange,
  onChange,
  minValue,
}: {
  value: DefinedRangeInterface<Date> | null
  onChange: (newValue: DefinedRangeInterface<Date> | null) => void
  minValue?: Date
}) => {
  const {
    monthChangeHandler,
    onMonthAnimationEnd,
    monthIsAnimating,
    slideDirection,
    nextMonth,
    month,
  } = useMonthChange(dayjs(timeRange?.[0] ?? new Date()))

  const { selectionState, selectionHandler, selectedValues } =
    useDateRangePickerState(timeRange, onChange, 'day')

  const DayComponent = React.useCallback(
    (props: PickersDayProps<Dayjs>) => (
      <Day {...props} selectedTimeRange={timeRange} />
    ),
    [timeRange],
  )

  const minDate = React.useMemo(
    () => (minValue ? dayjs(minValue) : null),
    [minValue],
  )

  return (
    <Container selectionState={selectionState}>
      <MonthPane
        leftIcon={
          <ChevronButton
            onClick={() => monthChangeHandler(-1)}
            orientation="left"
          />
        }
        rightIcon={
          <ChevronButton
            sx={{
              visibility: 'hidden',
            }}
          />
        }
        selectedDays={selectedValues}
        onSelectedDaysChange={selectionHandler}
        currentMonth={month}
        isMonthSwitchingAnimating={monthIsAnimating}
        onMonthSwitchingAnimationEnd={onMonthAnimationEnd}
        slideDirection={slideDirection}
        minDate={minDate ?? undefined}
        slots={{
          day: DayComponent,
        }}
      />
      <MonthPane
        rightIcon={
          <ChevronButton
            onClick={() => monthChangeHandler(1)}
            orientation="right"
          />
        }
        leftIcon={
          <ChevronButton
            sx={{
              visibility: 'hidden',
            }}
          />
        }
        selectedDays={selectedValues}
        onSelectedDaysChange={selectionHandler}
        currentMonth={nextMonth}
        isMonthSwitchingAnimating={monthIsAnimating}
        onMonthSwitchingAnimationEnd={onMonthAnimationEnd}
        slideDirection={slideDirection}
        minDate={minDate ?? undefined}
        slots={{
          day: DayComponent,
        }}
      />
    </Container>
  )
}

export default DateRangePicker
