import { useCallback } from 'react'

import { type TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { SlotStatus } from 'src/entities/availability/types/availability'
import { useHasPermission } from 'src/entities/team-member/hooks/useCheckPermissions'
import { type SimpleDialogProps } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useGlobalModalContext } from 'src/shared/lib/context/global/useGlobalModalContext'

export const slotInClosedShiftDialog = (
  t: TFunction,
  onConfirmation?: SimpleDialogProps['onConfirmation'],
): Partial<SimpleDialogProps> => ({
  title: t(
    'reservation_edit.time_selection.shift_closed.title',
    'Shift is locked',
  ),
  message: t('angular.message_shift_closed'),
  cancelButtonText: t('modal.button.dismiss'),
  confirmButtonText: t(
    'reservation_edit.time_selection.shift_closed.confirm',
    'Yes, ignore shift lock',
  ),
  color: 'error',
  onConfirmation,
})

export const slotNotInServiceTimeDialog = (
  t: TFunction,
  onConfirmation?: SimpleDialogProps['onConfirmation'],
): Partial<SimpleDialogProps> => ({
  message: t('angular.message_not_in_time_range'),
  title: t(
    'reservation_edit.time_selection.errors.not_in_service_time.title',
    'Time slot outside service times',
  ),
  confirmButtonText: t(
    'reservation_edit.time_selection.errors.confirm_time_slot',
    'Confirm selected time slot',
  ),
  color: 'error',
  onConfirmation,
})

export const slotNotInShiftDialog = (
  t: TFunction,
  onConfirmation?: SimpleDialogProps['onConfirmation'],
): Partial<SimpleDialogProps> => ({
  title: t(
    'reservation_edit.time_selection.errors.outside_availability.title',
    'Time slot outside availability',
  ),
  message: t('angular.message_out_of_shift_slot_choice'),
  confirmButtonText: t(
    'reservation_edit.time_selection.errors.confirm_time_slot',
    'Confirm selected time slot',
  ),
  color: 'error',
  onConfirmation,
})

export const slotNotInAvailabilitiesDialog = (
  t: TFunction,
  onConfirmation?: SimpleDialogProps['onConfirmation'],
): Partial<SimpleDialogProps> => ({
  title: t(
    'reservation_edit.time_selection.errors.not_available.title',
    'Time slot not available',
  ),
  message: t('angular.message_unavailable_slot_choice'),
  confirmButtonText: t(
    'reservation_edit.time_selection.errors.confirm_time_slot',
    'Confirm selected time slot',
  ),
  color: 'error',
  onConfirmation,
})

export const slotStatusToDialog =
  (t: TFunction, onConfirmation?: SimpleDialogProps['onConfirmation']) =>
  (slotStatus: SlotStatus) =>
    ({
      [SlotStatus.NotInServiceTime]: () =>
        slotNotInServiceTimeDialog(t, onConfirmation),
      [SlotStatus.NotInShift]: () => slotNotInShiftDialog(t, onConfirmation),
      [SlotStatus.Unavailable]: () =>
        slotNotInAvailabilitiesDialog(t, onConfirmation),
      [SlotStatus.InClosedShift]: () =>
        slotInClosedShiftDialog(t, onConfirmation),
      [SlotStatus.Available]: () => null,
    })[slotStatus]()

const slotStatusToOverbookMessage = (status: SlotStatus, t: TFunction) => {
  if (status === SlotStatus.InClosedShift)
    return t(
      'permissions.errors.overbooking.closed_shift',
      "You don't have permission to select time slot within locked shift.",
    )

  if (status === SlotStatus.NotInServiceTime)
    return t(
      'permissions.errors.overbooking.outside_service_time',
      "You don't have permission to select time slot outside of service times.",
    )

  if (status === SlotStatus.NotInShift)
    return t(
      'permissions.errors.overbooking.outside_shift',
      "You don't have permission to pick time slot outside shifts.",
    )

  if (status === SlotStatus.Unavailable)
    return t(
      'permissions.errors.overbooking.no_capacity',
      "You don't have permission to overbook.",
    )

  if (status === SlotStatus.Available) return ''

  status satisfies never
  return ''
}

export const useOverbookingCheck = () => {
  const { t } = useTranslation()
  const canOverbook = useHasPermission('overbooking')
  const { showPermissionModal } = useGlobalModalContext()

  return useCallback(
    (status: SlotStatus) => {
      if (status === SlotStatus.Available || canOverbook) return true

      showPermissionModal({
        message: slotStatusToOverbookMessage(status, t),
      })
      return false
    },
    [canOverbook, showPermissionModal, t],
  )
}
