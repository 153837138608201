import { Stack } from '@mui/material'

import { ErrorBoundary } from 'react-error-boundary'

import { type TodoItemTypeEnum } from 'src/entities/notification/types/notification'
import { useReservation } from 'src/entities/reservation/queries/reservation'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import GuestName from './GuestName'
import IndicatorsLoader from './IndicatorsLoader'
import Reservation from './Reservation'
import Title from './Title'

const Details = ({
  reservationId,
  notificationType,
}: {
  reservationId: ReservationInterface['id']
  notificationType: TodoItemTypeEnum
}) => {
  const { data: reservation } = useReservation(reservationId)

  return (
    <>
      <Title source={reservation.source} type={notificationType} />
      <Reservation reservation={reservation} />
      <Stack direction="row" spacing={1} alignItems="center">
        <GuestName
          firstName={reservation.guest.firstName}
          lastName={reservation.guest.lastName}
        />
        {!!reservation.guest.customerId && (
          <ErrorBoundary fallbackRender={() => null}>
            <IndicatorsLoader
              reservation={reservation}
              reservationDate={reservation.dateRange[0]}
            />
          </ErrorBoundary>
        )}
      </Stack>
    </>
  )
}

export default Details
