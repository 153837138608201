import { type PropsWithChildren } from 'react'
import { Stack } from '@mui/material'

import PhoneCheckIcon from 'src/shared/components/icons/PhoneCheckIcon'

export const IvrContainer = ({ children }: PropsWithChildren) => (
  <Stack
    direction="row"
    sx={{
      backgroundColor: 'grey.25',
      p: 1.5,
      gap: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    {children}
    <PhoneCheckIcon />
  </Stack>
)
