import { MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'

export const useBasicDndSensors = () =>
  useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  )
