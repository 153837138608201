import StepInput from 'src/shared/components/form/inputs/StepInput'

const MIN_DIMENSION = 10
const Dimension = ({
  value,
  label,
  onChange,
}: {
  value: number
  label: string
  onChange: (dimension: number) => void
}) => {
  const changeHandler = (dimension: number) => {
    if (dimension < MIN_DIMENSION) return
    onChange(Math.round(dimension))
  }

  return (
    <StepInput
      required
      label={label}
      minValue={MIN_DIMENSION}
      step={10}
      onChange={changeHandler}
      value={Math.max(value, MIN_DIMENSION)}
    />
  )
}

export default Dimension
