import React, { useEffect, useState } from 'react'

import { useUnreadCountQuery } from 'src/entities/notification/queries/notification'
import { NotificationTypeEnum } from 'src/entities/notification/types/notification'
import useSseEventListenerEffect from 'src/shared/lib/api/sse/useSseEventListenerEffect'

const useUnreadCount = () => {
  const { data: fetchedUnreadCount } = useUnreadCountQuery()

  const [unreadCount, setUnreadCount] = useState(fetchedUnreadCount)

  useEffect(() => {
    setUnreadCount(fetchedUnreadCount || 0)
  }, [fetchedUnreadCount])

  useSseEventListenerEffect(
    NotificationTypeEnum.NotificationsRead,
    React.useCallback(() => setUnreadCount(0), []),
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.TodoItemCreated,
    React.useCallback(() => setUnreadCount((count: number) => count + 1), []),
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.PhoneCall,
    React.useCallback(() => setUnreadCount((count: number) => count + 1), []),
  )

  return unreadCount
}

export default useUnreadCount
