import { type FloorPlanComponentProps } from 'src/widgets/FloorPlan/types/floorPlanElement'
import FurnitureElement from './FurnitureElement'
import FurnitureTransformer from './FurnitureTransformer'
import { useKonvaTransformerEffect } from '../useKonvaTransformerEffect'

const Furniture = ({
  element,
  onSelect,
  isSelected,
  onChange,
}: FloorPlanComponentProps) => {
  const { transformerRef, elementRef } = useKonvaTransformerEffect(isSelected)

  return (
    <>
      <FurnitureElement
        ref={elementRef}
        element={element}
        onSelect={onSelect}
        onChange={onChange}
        shapeProps={{
          fill: element.color,
        }}
      />
      {isSelected && <FurnitureTransformer ref={transformerRef} />}
    </>
  )
}

export default Furniture
