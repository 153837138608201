import { Typography } from '@mui/material'

import { getDisplayName } from 'src/entities/customer/services/customer'
import { type CustomerInterface } from 'src/entities/customer/types/customer'

const GuestName = ({
  firstName,
  lastName,
}: {
  firstName: string
  lastName: string
}) => (
  <Typography variant="body3">
    {getDisplayName({
      firstName,
      lastName,
    } as CustomerInterface)}
  </Typography>
)

export default GuestName
