import { Chip } from '@mui/material'

import {
  type CustomerInterface,
  type FallbackCustomer,
} from 'src/entities/customer/types/customer'
import FlagIcon from 'src/shared/components/icons/FlagIcon'

const Language = ({
  customer,
}: {
  customer: CustomerInterface | FallbackCustomer
}) => {
  if (!customer.languageCode) return null

  return (
    <Chip
      color="light"
      label={customer.languageCode}
      icon={<FlagIcon size="small" variant="filled" />}
      sx={{
        textTransform: 'uppercase',
      }}
    />
  )
}

export default Language
