import { useMemo } from 'react'
import { Stack, Typography } from '@mui/material'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import {
  useAddRoomMutation,
  useEditRoomMutation,
} from 'src/entities/schedule/queries/schedule'
import { type Room } from 'src/entities/schedule/types/scheduleApi'
import CancelButton from 'src/shared/components/buttons/FormButtons/CancelButton'
import SaveButton from 'src/shared/components/buttons/FormButtons/SaveButton'
import Checkbox from 'src/shared/components/form/inputs/Checkbox'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'
import { useDefaultMutationHandlers } from '../../ShiftConfiguration/hooks/useDefaultMutationHandlers'

interface RoomFormProps {
  rooms: Room[]
  room: Room | null
  onlineDeselectionDisabled: boolean
  onClose: () => void
}

const schema = z.object({
  name: z.string().trim().min(1),
  selectable: z.boolean(),
})

type FormValues = z.infer<typeof schema>

export const RoomForm = ({
  rooms,
  room,
  onlineDeselectionDisabled,
  onClose,
}: RoomFormProps) => {
  const { t } = useTranslation()

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      name: room?.name ?? '',
      selectable: room?.selectable ?? true,
    },
    resolver: zodResolver(schema),
  })

  const selectable = watch('selectable')

  const { mutateAsync: addRoom, isPending: addPending } = useAddRoomMutation()
  const { mutateAsync: editRoom, isPending: editPending } =
    useEditRoomMutation()

  const handlers = useDefaultMutationHandlers(onClose)

  const onSubmit = useMemo(
    () =>
      handleSubmit(formValues => {
        if (room) return editRoom({ ...room, ...formValues }, handlers)

        return addRoom(
          {
            ...formValues,
            position: Math.max(0, ...rooms.map(r => r.position)) + 1,
          },
          handlers,
        )
      }),
    [addRoom, editRoom, handleSubmit, handlers, room, rooms],
  )

  return (
    <Stack gap={3}>
      <Stack gap={2}>
        <FormTextInput
          label={t('schedule.rooms.name', 'Room name')}
          errorText={errors.name && t('form_validation.required')}
          {...register('name')}
        />
        <Checkbox
          label={t(
            'schedule.rooms.online_availability',
            'Online availability (Widget, API, Google)',
          )}
          disabled={onlineDeselectionDisabled && selectable}
          checked={selectable}
          onChange={e => setValue('selectable', e.target.checked)}
        />
        {onlineDeselectionDisabled && (
          <Typography variant="labelTiny">
            {t(
              'schedule.rooms.online_switch_disclaimer',
              'Option is available only when more than one room is configured and enabled for online channels.',
            )}
          </Typography>
        )}
      </Stack>
      <Stack direction="row" gap={1} justifyContent="flex-end">
        <CancelButton
          onClick={onClose}
          fullWidth
          disabled={addPending || editPending}
        />
        <SaveButton fullWidth onClick={onSubmit} />
      </Stack>
    </Stack>
  )
}
