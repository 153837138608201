import { createFileRoute } from '@tanstack/react-router'

import Integrations from 'src/pages/Settings/Integrations'
import { ConfigScroll } from './-ConfigScroll'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/config/integrations',
)({
  component: RouteComponent,
})

function RouteComponent() {
  return (
    <ConfigScroll>
      <Integrations />
    </ConfigScroll>
  )
}
