import { type FC } from 'react'
import { Chip, Stack, Typography } from '@mui/material'

import type { Shift } from 'src/entities/shift-instance/types/shift'
import type { IconProps } from 'src/shared/components/icons/IconContainer'

const ShiftsChip = ({
  icon: Icon,
  shifts,
}: {
  shifts: Shift[]
  icon: FC<IconProps>
}) => (
  <Chip
    color="error"
    sx={{ px: 1 }}
    label={
      <Stack direction="row" alignItems="center" gap={0.5}>
        <Icon color="error" variant="filled" size="small" />
        <Typography variant="labelTiny">{shifts.length}</Typography>
      </Stack>
    }
  />
)

export default ShiftsChip
