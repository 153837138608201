import { type ApiClient } from 'src/shared/lib/api/services/api'
import { type AreasOrderUpdateInterface } from '../types/area'

export const setAreasOrder =
  (httpClient: ApiClient) => (areasOrder: AreasOrderUpdateInterface) =>
    httpClient({
      url: 'setareaPositions',
      json: {
        positions: areasOrder,
      },
    })
