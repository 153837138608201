import { Stack } from '@mui/material'

import AfterDeadline from './AfterDeadline'
import AfterLimit from './AfterLimit'
import SectionTitle from './components/SectionTitle'
import ConfirmationSummary from './ConfirmationsSummary'
import FormSection from '../../../FormSection'

const IvrTemplateSection = () => (
  <FormSection
    title={<SectionTitle />}
    sx={{ p: 1.5, backgroundColor: 'grey.25' }}
  >
    <Stack gap={1} ml={4}>
      <AfterDeadline />
      <AfterLimit neverDisable />
    </Stack>
    <ConfirmationSummary variant="template" />
  </FormSection>
)

export default IvrTemplateSection
