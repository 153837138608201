import type React from 'react'
import { Stack, Typography } from '@mui/material'

interface ListSectionProps {
  title: string
  children: React.ReactNode
}

const ListSection = ({ title, children }: ListSectionProps) => (
  <Stack direction="column" spacing={0.5} useFlexGap>
    <Typography variant="labelExtrasmall" sx={{ px: 1, color: 'grey.500' }}>
      {title}
    </Typography>
    {children}
  </Stack>
)

export default ListSection
