import { type ActivityLogEntryInterface } from './activityLog'

export const activityLogFactory = (
  rawActivityLogEntry: ActivityLogEntryInterface,
): ActivityLogEntryInterface => ({
  action: rawActivityLogEntry.action,
  who: rawActivityLogEntry.who,
  message: rawActivityLogEntry.message,
  reservation: rawActivityLogEntry.reservation,
  date: rawActivityLogEntry.date,
})
