import { useCallback } from 'react'

import { useNavigate } from '@tanstack/react-router'

import { useRestaurantsInChain } from 'src/entities/restaurant/queries/restaurants'
import { useRestaurantHash } from 'src/shared/lib/context/global/useRestaurantHash'
import { useSetReservationIdForTableAssignment } from 'src/shared/lib/context/state/atoms/reservationIdForTableAssignment'
import {
  useSetOpenReservationOccupancies,
  useSetSelectedReservation,
} from 'src/shared/lib/context/state/atoms/selectedReservation'
import { useNavigateToReservations } from './useNavigateToReservations'
import { type ReservationInterface } from '../types/reservation'

export const useEditReservation = (restaurantId: number) => {
  const hash = useRestaurantHash()
  const restaurants = useRestaurantsInChain()

  const navigate = useNavigate()
  const navigateToReservations = useNavigateToReservations(restaurantId)

  const setReservation = useSetSelectedReservation()
  const setOccupancies = useSetOpenReservationOccupancies()
  const setReservationIdForTableAssignment =
    useSetReservationIdForTableAssignment()

  return useCallback(
    async (reservation: ReservationInterface) => {
      await navigateToReservations()

      setReservationIdForTableAssignment(null)
      setOccupancies(reservation.occupancies)

      if (hash === reservation.restaurantId) {
        setReservation(reservation)
        return
      }

      const newRestaurant = restaurants.find(
        r => r.hash === reservation.restaurantId,
      )
      if (!newRestaurant) return

      await navigate({
        to: '/restaurants/$restaurantId/reservations',
        params: { restaurantId: newRestaurant.id },
      })
      setReservation(reservation)
    },
    [
      hash,
      navigate,
      navigateToReservations,
      restaurants,
      setOccupancies,
      setReservation,
      setReservationIdForTableAssignment,
    ],
  )
}
