import React from 'react'
import { andThen, compose, composeWith } from 'ramda'
import { Box, Grid2, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import {
  useServiceTimeDeleteMutation,
  useServiceTimesQuery,
  useServiceTimeUpdateMutation,
} from 'src/entities/service-time/queries/serviceTime'
import {
  emptyServiceTime,
  type EmptyServiceTime,
} from 'src/entities/service-time/types/serviceTime'
import CreateButton from 'src/shared/components/buttons/CreateButton'
import Modal from 'src/shared/components/common/Modal'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import EditForm from './EditForm'
import ServiceTime from './ServiceTime'

const ServiceTimes = () => {
  const { t } = useTranslation('', { keyPrefix: 'settings.service_times' })

  const [editedServiceTime, setEditedServiceTime] = React.useState<
    ServiceTimeInterface | EmptyServiceTime | undefined
  >(undefined)

  const { handleClose, handleOpen, open } = useDialog()

  const { data: serviceTimes } = useServiceTimesQuery()
  const { mutateAsync: updateServiceTime } = useServiceTimeUpdateMutation()
  const { mutate: deleteServiceTime } = useServiceTimeDeleteMutation()

  const handleSubmit = composeWith(andThen)([handleClose, updateServiceTime])
  const handleEdit = compose(handleOpen, setEditedServiceTime)
  const handleDelete = compose(handleClose, deleteServiceTime)

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        p: 2,
        alignItems: 'baseline',
      }}
    >
      <CreateButton onClick={() => handleEdit(emptyServiceTime)}>
        {t('addLabel')}
      </CreateButton>
      <Box>
        <Grid2 container spacing={2} justifyContent="center">
          {serviceTimes.map(serviceTime => (
            <Grid2 key={serviceTime.id}>
              <ServiceTime serviceTime={serviceTime} onClick={handleEdit} />
            </Grid2>
          ))}
        </Grid2>
      </Box>
      <Modal open={open} onClose={handleClose} title={t('modalTitle')}>
        <EditForm
          serviceTime={editedServiceTime}
          serviceTimes={serviceTimes}
          onCancel={handleClose}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </Modal>
    </Stack>
  )
}

export default ServiceTimes
