import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Divider, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import useFeature from 'src/entities/info/hooks/useFeature'
import { useScheduleQuery } from 'src/entities/schedule/queries/schedule'
import { InputGroupColumnHeader } from 'src/pages/ShiftConfiguration/InputGroupColumnHeader/InputGroupColumnHeader'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import { replaceBy } from 'src/shared/lib/common/services/functional/functional'
import { useBaseFormContext, useBaseFormController } from '../../hooks/form'
import { type FormChannelAllocation } from '../../services/formSchema'
import AfterLimit from '../Ivr/AfterLimit'
import { IvrContainer } from '../Ivr/IvrContainer'
import ChannelLimit from '../LimitsSection/components/ChannelLimit'

export const BookingChannelsSection = () => {
  const { t } = useTranslation()

  const { data: schedule } = useScheduleQuery()

  const defaultBeyondPaxLimit =
    schedule.defaultSettings.asyncReservationConfirmation.beyondPaxLimit

  const rwgEnabled = useFeature('reserveWithGoogle')
  const widgetBookingEnabled = useFeature('widgetBooking')

  const {
    field: { value, onChange },
  } = useBaseFormController('channelAllocations')
  const { watch, getValues, setValue } = useBaseFormContext()

  const limit = watch('shiftGuestCountLimit')

  const allocationChangeHandler = useCallback(
    (allocation: FormChannelAllocation) => {
      onChange(
        replaceBy(a => a.channel, allocation, getValues('channelAllocations')),
      )
    },
    [getValues, onChange],
  )

  const allocations = useMemo<FormChannelAllocation[]>(
    () =>
      value.filter(({ channel }) => {
        if (channel !== 'google' && channel !== 'online') return true
        if (channel === 'google' && rwgEnabled) return true
        if (channel === 'online' && widgetBookingEnabled) return true

        return false
      }),
    [rwgEnabled, value, widgetBookingEnabled],
  )

  const [isEnabled, setIsEnabled] = useState(
    allocations.some(a => a.limit !== limit) ||
      defaultBeyondPaxLimit !==
        getValues('reservationConfirmation.beyondPaxLimit'),
  )

  useEffect(() => {
    if (isEnabled) return

    setValue('reservationConfirmation.beyondPaxLimit', defaultBeyondPaxLimit)
    onChange(
      getValues('channelAllocations').map(a => ({ ...a, active: true, limit })),
    )
  }, [defaultBeyondPaxLimit, getValues, isEnabled, limit, onChange, setValue])

  if (!allocations.length) return null

  return (
    <Stack gap={2}>
      <Toggle
        label={t(
          'schedule.shifts.booking_channels_section.channel_limits_toggle_label',
          'Set custom online channels settings',
        )}
        checked={isEnabled}
        onChange={() => setIsEnabled(v => !v)}
      />
      {isEnabled && (
        <Stack pl={6} gap={2}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              alignItems: 'center',
              gap: 1.5,
              maxWidth: 312,
            }}
          >
            <InputGroupColumnHeader>
              {t(
                'schedule.shifts.limits_section.channels.guest_limits.channel_header',
                'Channel',
              )}
            </InputGroupColumnHeader>
            <InputGroupColumnHeader>
              {t(
                'schedule.shifts.limits_section.channels.guest_limits.limit_header',
                'Guest limit',
              )}
            </InputGroupColumnHeader>
            {allocations.map(allocation => (
              <Fragment key={allocation.channel}>
                <ChannelLimit
                  allocation={allocation}
                  onAllocationChange={allocationChangeHandler}
                  limit={limit}
                />
                <Divider sx={{ gridColumn: 'span 2' }} />
              </Fragment>
            ))}
          </Box>
          <IvrContainer>
            <AfterLimit />
          </IvrContainer>
        </Stack>
      )}
    </Stack>
  )
}
