import { Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type useCommunicationTemplates } from 'src/entities/communication-template/queries/useCommunicationTemplates'
import { getTemplateName } from 'src/entities/communication-template/services/communicationTemplates'
import type { CommunicationTemplate } from 'src/entities/communication-template/types/communicationTemplates'
import SingleSelectDropdown from 'src/shared/components/form/inputs/SingleSelectDropdown'

interface TemplateChoiceModalInterface {
  smsTemplates: ReturnType<typeof useCommunicationTemplates>
  emailTemplates: ReturnType<typeof useCommunicationTemplates>
  smsEnabled: boolean
  emailEnabled: boolean
  smsHelperText?: string
  emailHelperText?: string
}

const CommunicationTemplateChoice = ({
  smsTemplates,
  emailTemplates,
  smsEnabled,
  emailEnabled,
  smsHelperText,
  emailHelperText,
}: TemplateChoiceModalInterface) => {
  const { t } = useTranslation()

  const renderOption = getTemplateName(t)

  const renderKey = (template: CommunicationTemplate) => template.id

  return (
    <Stack direction="column" spacing={3}>
      <SingleSelectDropdown
        label={t('angular.sms')}
        options={smsTemplates.templates}
        value={smsTemplates.selectedTemplate}
        onSelect={smsTemplates.setSelectedTemplate}
        renderOption={renderOption}
        renderKey={renderKey}
        disabled={!smsEnabled}
        helperText={smsHelperText}
      />
      <SingleSelectDropdown
        label={t('angular.email')}
        options={emailTemplates.templates}
        value={emailTemplates.selectedTemplate}
        onSelect={emailTemplates.setSelectedTemplate}
        renderOption={renderOption}
        renderKey={renderKey}
        disabled={!emailEnabled}
        helperText={emailHelperText}
      />
    </Stack>
  )
}

export { CommunicationTemplateChoice }
