import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded'
import { Button, type ButtonProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

const MarkAllDone = (props: ButtonProps) => {
  const { t } = useTranslation('', { keyPrefix: 'inbox' })

  return (
    <Button
      color="lunchgateSuccess"
      startIcon={<DoneAllRoundedIcon />}
      size="large"
      variant="text"
      {...props}
    >
      {t('markAllDone')}
    </Button>
  )
}

export default MarkAllDone
