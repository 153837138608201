import { andThen, composeWith, map, prop } from 'ramda'

import {
  convertKeysToSnakeCase,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import { createBatchedFetch } from 'src/shared/lib/api/services/createBatcher'
import { type TimeRangeInterface } from 'src/shared/lib/range/types/timeRange'
import { type CustomerInterface } from '../../customer/types/customer'
import { type HotelStayInterface } from '../types/hotelStay'
import { hotelStaySchema, mapRawHotelStays } from '../types/hotelStayApi'

export const searchHotelStays =
  (httpClient: ApiClient) =>
  async ({
    customerId,
    dateRange,
  }: {
    customerId?: CustomerInterface['id']
    dateRange?: TimeRangeInterface
    // eslint-disable-next-line @typescript-eslint/require-await
  }) => {
    if (!customerId && !dateRange) return []

    const createTimeFilter = () => {
      if (!dateRange) return {}

      return {
        from: dateRange[0]!,
        to: dateRange[1]!,
      }
    }

    return composeWith(andThen)([
      prop('data')<{ data: HotelStayInterface['uuid'][] }>,
      httpClient,
    ])({
      url: 'hotelstayapi/search',
      json: {
        ...(dateRange && { time_filter: createTimeFilter() }),
        customer_id: customerId,
      },
    })
  }
const fetchAllHotelStays =
  (httpClient: ApiClient) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async (hotelStayIds: HotelStayInterface['uuid'][]) => {
    if (!hotelStayIds.length) return []

    return composeWith(andThen)([
      map(mapRawHotelStays),
      map((payload: unknown) => hotelStaySchema.parse(payload)),
      prop('data'),
      httpClient,
    ])({
      url: 'hotelstayapi/getAll',
      json: convertKeysToSnakeCase({ hotelStayIds }),
    })
  }

export const fetchHotelStay = createBatchedFetch(fetchAllHotelStays, 'uuid')
