import { useMemo, useState, type Dispatch, type SetStateAction } from 'react'
import { Button, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  type BookingLock,
  type Closure,
  type PaymentPlan,
  type PhoneAcceptanceTimes,
  type ReservationQuestion,
  type Schedule,
  type ScheduleEvent,
  type Shift,
  type ShiftException,
} from 'src/entities/schedule/types/scheduleApi'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import HistoryIcon from 'src/shared/components/icons/HistoryIcon'
import SettingsIcon from 'src/shared/components/icons/SettingsIcon'
import { withTargetChecked } from 'src/shared/lib/common/services/helpers/helpers'
import { BookingLockSection } from './BookingLockSection/BookingLockSection'
import {
  ScheduleCategoryWrapper,
  ScheduleColumn,
  ScheduleGrid,
} from './components'
import { CustomScheduleTypography } from './components/CustomScheduleTypography'
import { EventSection } from './EventSection/EventSection'
import { PaymentPlanSection } from './PaymentPlanSection/PaymentPlanSection'
import { PhoneConfirmationsSection } from './PhoneConfirmationsSection/PhoneConfirmationsSection'
import { PlaySampleMessage } from './PhoneConfirmationsSection/PlaySampleMessage'
import { ReservationQuestionsSection } from './ReservationQuestionsSection/ReservationQuestionsSection'
import { RestaurantClosureSection } from './RestaurantClosureSection/RestaurantClosureSection'
import { ShiftSection } from './ShiftSection/ShiftSection'
import Timeline from './Timeline/Timeline'

export type RouteState =
  | { route: 'overview' }
  | { route: 'default-settings' }
  | { route: 'activity-log' }
  | { route: 'shift'; shift?: Shift }
  | { route: 'shift-exception'; shift: Shift; exception?: ShiftException }
  | { route: 'event'; event?: ScheduleEvent }
  | { route: 'payment-plan'; paymentPlan?: PaymentPlan }
  | { route: 'reservation-question'; reservationQuestion?: ReservationQuestion }
  | { route: 'phone-confirmations' }
  | {
      route: 'phone-confirmations-exception'
      phoneConfirmations?: PhoneAcceptanceTimes
    }
  | { route: 'booking-lock'; lock?: BookingLock }
  | { route: 'closure'; closure?: Closure }
  | { route: 'week-overview' }

interface ScheduleOverviewProps {
  setRouteState: Dispatch<SetStateAction<RouteState>>
  schedule: Schedule
  currency: string
}

const ScheduleOverview = ({
  setRouteState,
  schedule: fullSchedule,
  currency,
}: ScheduleOverviewProps) => {
  const { t } = useTranslation()

  const [showInactive, setShowInactive] = useState(false)

  const activeSchedule = useMemo(
    () =>
      ({
        ...fullSchedule,
        shifts: fullSchedule.shifts.filter(s => s.isActive),
        events: fullSchedule.events.filter(e => e.isActive),
        reservationQuestions: fullSchedule.reservationQuestions.filter(
          rq => rq.active,
        ),
      }) satisfies Schedule,
    [fullSchedule],
  )

  const schedule = showInactive ? fullSchedule : activeSchedule

  const {
    paymentPlan: paymentPlanEnabled,
    phoneAcceptance: phoneAcceptanceEnabled,
  } = schedule.scheduleFeatures

  return (
    <Stack gap={3}>
      <Timeline
        onWeekOverviewOpen={() => setRouteState({ route: 'week-overview' })}
      />
      <Stack gap={2}>
        <Stack
          direction="row"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <CustomScheduleTypography>
            {t('schedule.overview.title', 'Schedule settings')}
          </CustomScheduleTypography>
          <Stack direction="row" gap={1} alignItems="center">
            <Button
              startIcon={<SettingsIcon />}
              variant="text"
              sx={{ px: 1 }}
              onClick={() => setRouteState({ route: 'default-settings' })}
            >
              {t(
                'schedule.shift_template_settings.title',
                'Shift template settings',
              )}
            </Button>
            <Button
              startIcon={<HistoryIcon />}
              variant="text"
              sx={{ px: 1 }}
              onClick={() => setRouteState({ route: 'activity-log' })}
            >
              {t('schedule.activity_log.title', 'Activity log')}
            </Button>
          </Stack>
        </Stack>
        <Toggle
          value={showInactive}
          onChange={withTargetChecked(setShowInactive)}
          label={t(
            'schedule.overview.show_inactive_instances',
            'Show inactive instances',
          )}
        />
      </Stack>
      <ScheduleGrid>
        <ScheduleColumn>
          <ScheduleCategoryWrapper
            title={t('schedule.overview.shifts.title', 'Shifts')}
          >
            <ShiftSection
              schedule={schedule}
              onEditShift={shift => setRouteState({ route: 'shift', shift })}
              onEditException={(shift, exception) =>
                setRouteState({ route: 'shift-exception', shift, exception })
              }
            />
          </ScheduleCategoryWrapper>
          <ScheduleCategoryWrapper
            title={t('schedule.overview.events.title', 'Events')}
          >
            <EventSection
              schedule={schedule}
              onEditEvent={e => setRouteState({ route: 'event', event: e })}
            />
          </ScheduleCategoryWrapper>
        </ScheduleColumn>

        <ScheduleColumn>
          <ScheduleCategoryWrapper
            title={t(
              'schedule.overview.phone_confirmations.title',
              'Phone Confirmations',
            )}
            description={t(
              'schedule.overview.phone_confirmations.description',
              'Manage your IVR (Interactive Voice Response) settings to automate reservation confirmations.',
            )}
            enabled={phoneAcceptanceEnabled}
          >
            <PlaySampleMessage sx={{ mb: 2 }} />
            <PhoneConfirmationsSection
              schedule={schedule}
              onEditPhoneTimes={() =>
                setRouteState({ route: 'phone-confirmations' })
              }
              onEditPhoneTimesException={pt =>
                setRouteState({
                  route: 'phone-confirmations-exception',
                  phoneConfirmations: pt,
                })
              }
            />
          </ScheduleCategoryWrapper>

          <ScheduleCategoryWrapper
            title={t(
              'schedule.overview.reservation_questions.title',
              'Reservation Questions',
            )}
            description={t(
              'schedule.overview.reservation_questions.description',
              'Only one selection window will be shown per shift. Selection windows are not displayed on Google.',
            )}
          >
            <ReservationQuestionsSection
              schedule={schedule}
              onEditQuestion={reservationQuestion =>
                setRouteState({
                  route: 'reservation-question',
                  reservationQuestion,
                })
              }
            />
          </ScheduleCategoryWrapper>
          <ScheduleCategoryWrapper
            title={t('schedule.overview.payment_plans.title', 'Payment Plans')}
            description={t(
              'schedule.overview.payment_plans.description',
              'Payment plans are only available for bookings made via Widget or offline. This option is not provided for bookings made via Google.',
            )}
            enabled={paymentPlanEnabled}
          >
            <PaymentPlanSection
              schedule={schedule}
              currency={currency}
              onEditPaymentPlan={paymentPlan =>
                setRouteState({ route: 'payment-plan', paymentPlan })
              }
            />
          </ScheduleCategoryWrapper>
        </ScheduleColumn>

        <ScheduleColumn>
          <ScheduleCategoryWrapper
            title={t('schedule.overview.closure.title', 'Close restaurant')}
            description={t(
              'schedule.overview.closure.description',
              'Set closure dates for the entire restaurant or specific areas.',
            )}
          >
            <RestaurantClosureSection
              schedule={schedule}
              onEditClosure={closure =>
                setRouteState({ route: 'closure', closure })
              }
            />
          </ScheduleCategoryWrapper>
          <ScheduleCategoryWrapper
            title={t('schedule.overview.booking_lock.title', 'Lock booking')}
          >
            <BookingLockSection
              schedule={schedule}
              onEditLock={lock =>
                setRouteState({ route: 'booking-lock', lock })
              }
            />
          </ScheduleCategoryWrapper>
        </ScheduleColumn>
      </ScheduleGrid>
    </Stack>
  )
}

export default ScheduleOverview
