import { find } from 'ramda'

import { hasIdRelationBy } from 'src/shared/lib/common/services/functional/functional'
import { areInsideRestaurant } from '../../restaurant/services/restaurant'
import { type RestaurantInterface } from '../../restaurant/types/restaurant'
import { type RoomInterface } from '../types/room'

export const areInsideRoom = hasIdRelationBy('roomId')

areInsideRoom([{ roomId: '' }])({ id: 1 })

export const getRoomRestaurant =
  (restaurants: RestaurantInterface[]) => (room: RoomInterface) =>
    find(areInsideRestaurant([room]), restaurants)
