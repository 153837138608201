import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { type IntegrationName } from 'src/entities/integration/api/integrationsApi'
import { useIntegrationFlowQuery } from 'src/entities/integration/queries/integrationFlows'
import { type useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import { useHandleSuccessFlow } from './useHandleSuccessFlow'
import { IntegrationErrorInlay } from '../components/IntegrationErrorInlay'

interface Deps {
  popup: WindowProxy | null
  setPopup: (newProxy: WindowProxy | null) => void
  setupDialog: ReturnType<typeof useSimpleDialog>
  integrationName: IntegrationName
}

export const useIntegrationFlowEffect = ({
  popup,
  setPopup,
  setupDialog,
  integrationName,
}: Deps) => {
  const { t } = useTranslation()

  const { refetch: refetchFlow } = useIntegrationFlowQuery(integrationName)

  const { handleSuccessFlow, isLoading: isSuccessFlowPending } =
    useHandleSuccessFlow(setupDialog.showSimpleDialog)

  const [isInFlow, setIsInFlow] = useState(false)

  useEffect(() => {
    if (!popup) return undefined
    if (!popup.closed) {
      setIsInFlow(true)
    }

    const interval = setInterval(async () => {
      if (!popup.closed) return

      setPopup(null)
      const { data: flow } = await refetchFlow()

      if (!flow) return

      const { authorizationUrl } = flow.configuration

      if (flow.status === 'unauthenticated') {
        setupDialog.showSimpleDialog({
          onConfirmation: () =>
            setPopup(window.open(authorizationUrl, '_blank', 'popup')),
          confirmButtonText: t('common.retry', 'Retry'),
          children: (
            <IntegrationErrorInlay
              integrationName={flow.integrationName}
              status={flow.status}
            />
          ),
        })
      }
      if (flow.status === 'unauthorized') {
        setupDialog.showSimpleDialog({
          onConfirmation: () =>
            setPopup(window.open(authorizationUrl, '_blank', 'popup')),
          confirmButtonText: t('common.retry', 'Retry'),
          children: (
            <IntegrationErrorInlay
              integrationName={flow.integrationName}
              status={flow.status}
            />
          ),
        })
      }

      if (flow.status === 'success') handleSuccessFlow(flow)

      setIsInFlow(false)
    }, 500)

    return () => clearInterval(interval)
  }, [handleSuccessFlow, popup, refetchFlow, setPopup, setupDialog, t])

  return { isLoading: isSuccessFlowPending || isInFlow }
}
