import { z } from 'zod'

import {
  TableCheckInStatusEnum,
  type TableInterface,
  type TableOccupancyInterface,
  type TableOccupancyStatusUpdateInterface,
} from '../../table/types/table'

const CheckInStatusMap = {
  0: TableCheckInStatusEnum.Pending,
  1: TableCheckInStatusEnum.CheckedIn,
  2: TableCheckInStatusEnum.CheckedOut,
}

export interface RawApiAssignableTable {
  table_id: number
  tableId: number
  placed: number
  check_in_status: keyof typeof CheckInStatusMap
  checkInStatus: keyof typeof CheckInStatusMap
  walk_in_status: keyof typeof CheckInStatusMap
}

const occupancyCheckInStatusFactory = (
  table: RawApiAssignableTable,
): TableCheckInStatusEnum =>
  CheckInStatusMap[
    table.check_in_status || table.checkInStatus || table.walk_in_status
  ] || TableCheckInStatusEnum.Pending

export const fromApi = (
  table: RawApiAssignableTable,
): TableOccupancyInterface => ({
  tableId: table.table_id || table.tableId,
  checkInStatus: occupancyCheckInStatusFactory(table),
  seatCount: table.placed || 0,
})

export const newOccupancyFromTable = (
  table: TableInterface,
  seatCount?: number,
): TableOccupancyInterface => ({
  tableId: table.id,
  checkInStatus: TableCheckInStatusEnum.Pending,
  seatCount: seatCount ?? table.capacity,
})

interface RawEventData {
  reservationId: string | number
  tableId: string | number
  status: unknown
}

export const tableOccupancyStatusNotificationPayloadFactory = (
  eventData: RawEventData,
): TableOccupancyStatusUpdateInterface => ({
  reservationId: Number(eventData.reservationId),
  tableId: Number(eventData.tableId),
  checkInStatus:
    CheckInStatusMap[eventData.status as keyof typeof CheckInStatusMap] ||
    TableCheckInStatusEnum.Pending,
})

const occupanciesUpdatePayloadSchema = z
  .object({
    reservationId: z.number(),
    placements: z.array(
      z.object({
        tableId: z.number(),
        seats: z.number(),
      }),
    ),
  })
  .transform(payload => ({
    reservationId: payload.reservationId,
    occupancies: payload.placements.map(p => ({
      tableId: p.tableId,
      seatCount: p.seats,
      checkInStatus: TableCheckInStatusEnum.Pending,
    })),
  }))

export const occupanciesUpdatedNotificationPayloadFactory = (data: unknown) =>
  occupanciesUpdatePayloadSchema.parse(data)
