import React from 'react'

export const useStateTransaction = <T>(
  originalState: T,
  setOriginalState: (newState: T) => void,
) => {
  const [state, setState] = React.useState<T>(originalState)

  const commit = React.useCallback(
    () => setOriginalState(state),
    [setOriginalState, state],
  )
  const rollback = React.useCallback(
    () => setState(originalState),
    [originalState, setState],
  )

  React.useEffect(() => {
    setState(originalState)
  }, [originalState])

  return {
    state,
    setState,
    commit,
    rollback,
  }
}
