import { SidebarDrawer } from 'src/widgets/SidebarDrawer/SidebarDrawer'
import TableInfo, { type TableInfoProps } from './TableInfo/TableInfo'

export interface TableDrawerProps extends TableInfoProps {
  open: boolean
}

const TableDrawer = ({ open, table, onClose, ...props }: TableDrawerProps) => (
  <SidebarDrawer open={open} onClose={onClose}>
    <TableInfo table={table} onClose={onClose} {...props} />
  </SidebarDrawer>
)

export default TableDrawer
