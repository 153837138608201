import React, { useCallback, useDeferredValue, useMemo } from 'react'
import { andThen, composeWith, without } from 'ramda'
import { Stack } from '@mui/material'

import { useAddReservationGroupMutation } from 'src/entities/reservation/queries/reservationGroup'
import { useAddReservationsMutation } from 'src/entities/reservation/queries/reservationsMutations'
import {
  appendSerialId,
  getIsoDate,
} from 'src/entities/reservation/services/reservation'
import type { ReservationGroup } from 'src/entities/reservation/services/reservationGroupFactory'
import { includesReservationDate } from 'src/entities/reservation/services/serialReservations'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { type IsoDateString } from 'src/shared/lib/range/services/date'
import ReservationsTable from './ReservationsTable'
import TableSummary from './TableSummary'
import { useTableReservations } from './useTableReservations'

export const SerialReservations = ({
  dates: rawDates,
  reservationTemplate,
  onReservationsSave,
  serialTemplate,
}: {
  dates: Date[]
  reservationTemplate: ReservationInterface
  onReservationsSave: () => void
  serialTemplate: ReservationGroup
}) => {
  const dates = useMemo<IsoDateString[]>(
    () => rawDates.map(date => date.toISOString()),
    [rawDates],
  )

  const {
    updateReservation,
    reservations,
    toggleDateSelection,
    deselectedDates,
    modifiedReservations,
  } = useTableReservations(reservationTemplate, dates, serialTemplate)

  const deferredDates = useDeferredValue(dates)

  const { mutateAsync: addReservations } = useAddReservationsMutation()
  const { mutateAsync: addReservationsGroup } = useAddReservationGroupMutation()

  const saveReservationsHandler = useCallback(async () => {
    const reservationGroup = await addReservationsGroup(serialTemplate)

    const appendSerialTemplateId = appendSerialId(reservationGroup.id)

    const serialReservationTemplate =
      appendSerialTemplateId(reservationTemplate)

    const selectedDates = without(deselectedDates, deferredDates)

    const serialReservations = modifiedReservations
      .filter(includesReservationDate(selectedDates))
      .map(appendSerialTemplateId)

    const templateDates = without(
      modifiedReservations.map(getIsoDate),
      selectedDates,
    ).map(date => new Date(date))

    await addReservations({
      reservations: serialReservations,
      template: {
        dates: templateDates,
        reservation: serialReservationTemplate,
      },
    })

    onReservationsSave()
  }, [
    serialTemplate,
    addReservations,
    addReservationsGroup,
    deferredDates,
    modifiedReservations,
    reservationTemplate,
    deselectedDates,
    onReservationsSave,
  ])

  const [reservationsPending, setReservationsPending] = React.useState(false)

  React.useEffect(() => {
    if (!deferredDates.length) return
    setReservationsPending(true)
  }, [deferredDates])

  if (!deferredDates.length) return null

  return (
    <Stack
      direction="column"
      spacing={2}
      overflow="hidden"
      sx={{
        background: 'white',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        borderTop: '1px solid',
        borderColor: 'grey.50',
        p: 2,
      }}
    >
      <TableSummary
        reservationsCount={deferredDates.length}
        reservationsPending={reservationsPending}
        onSave={composeWith(andThen)([
          () => setReservationsPending(false),
          saveReservationsHandler,
        ])}
      />
      <ReservationsTable
        serialDates={deferredDates}
        reservations={reservations}
        updateReservation={updateReservation}
        toggleDateSelection={toggleDateSelection}
        datesDeselection={deselectedDates}
      />
    </Stack>
  )
}
