import React, { useState } from 'react'

import { getCapacityPosition } from 'src/entities/table/services/tableGrid'
import {
  type FloorPlanTableInterface,
  type PositionInterface,
} from '../types/floorPlanElement'

export const useCapacityPosition = (table: FloorPlanTableInterface) => {
  const [capacityPosition, setCapacityPosition] = useState<
    PositionInterface | undefined
  >(undefined)

  React.useEffect(() => {
    setCapacityPosition(getCapacityPosition(table))
  }, [table])

  return capacityPosition
}
