import { __, complement, equals, includes } from 'ramda'

export const PRESENTATION_VIEWS = {
  timeline: 'tables_timeline',
  reservationsTable: 'reservations_table',
  tablesTable: 'tables_table',
  floorplan: 'floorplan',
} as const

export type PresentationView =
  (typeof PRESENTATION_VIEWS)[keyof typeof PRESENTATION_VIEWS]

export const isListDisplayed = complement(
  equals<PresentationView>('reservations_table'),
)

export const isAreaPickerDisplayed = includes<PresentationView>(__, [
  PRESENTATION_VIEWS.floorplan,
  PRESENTATION_VIEWS.tablesTable,
])
