import { juxt } from 'ramda'
import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import ReservationDrawerInlineSection from 'src/shared/components/common/ReservationDrawerInlineSection'
import PickerGrid from 'src/shared/components/containers/PickerGrid'
import StepPicker from 'src/shared/components/form/inputs/StepPicker/StepPicker'
import { type DrawerColor } from 'src/widgets/SidebarDrawer/drawerColor'
import { SidebarDrawer } from 'src/widgets/SidebarDrawer/SidebarDrawer'
import SidebarHeader from 'src/widgets/SidebarDrawer/SidebarHeader'
import { generateDurationChoices } from './durationChoices'

interface DurationProps {
  duration: number
  onDurationChange: (duration: number) => void
  open: boolean
  onOpen: () => void
  onClose: () => void
  drawerColor: DrawerColor
}

const durationOptions = generateDurationChoices()

const Duration = ({
  duration,
  onDurationChange,
  open,
  onOpen: handleOpen,
  onClose: handleClose,
  drawerColor,
}: DurationProps) => {
  const { t } = useTranslation()

  return (
    <>
      <ReservationDrawerInlineSection title={t('angular.duration')}>
        <StepPicker
          onChange={onDurationChange}
          value={duration}
          minValue={durationOptions[0]}
          maxValue={durationOptions.at(-1)}
          step={15}
          sx={{ bgcolor: 'white', maxWidth: ['none', 160] }}
          rendererFactory={() => ({ buttonProps: { onClick: handleOpen } })}
        />
      </ReservationDrawerInlineSection>
      <SidebarDrawer
        open={open}
        onClose={handleClose}
        level={1}
        color={drawerColor}
        sx={{ overflow: 'hidden' }}
      >
        <SidebarHeader onClose={handleClose} color={drawerColor}>
          <Typography variant="labelBig">
            {t('angular.minutes_duration')}
          </Typography>
        </SidebarHeader>
        <PickerGrid
          items={durationOptions}
          onItemClick={juxt([handleClose, onDurationChange])}
        />
      </SidebarDrawer>
    </>
  )
}

export { Duration }
