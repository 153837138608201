import React from 'react'
import { compose } from 'ramda'
import { Box, Stack, Typography } from '@mui/material'

import type Konva from 'konva'
import { useTranslation } from 'react-i18next'
import { Group, Layer, Stage } from 'react-konva'

import {
  getTableBorder,
  PRIMARY_TABLE_BG,
} from 'src/entities/floor-plan/services/floorPlanElement'
import {
  getRealDimension,
  GRID_STEP,
  toggleCursor,
} from 'src/entities/table/services/tableGrid'
import {
  newFurniture,
  newRectangularTable,
  newRoundTable,
  newSquareTable,
} from 'src/entities/table/types/tableApi'
import CircularProgress from 'src/shared/components/common/CircularProgress'
import CenteredBox from 'src/shared/components/containers/CenteredBox'
import OutlinedContainer from 'src/shared/components/containers/OutlinedContainer'
import EllipticElement from 'src/widgets/FloorPlan/EllipticElement'
import RectangularElement from 'src/widgets/FloorPlan/RectangularElement'
import {
  type FloorPlanElementInterface,
  type FloorPlanTableInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'
import { useMaxPosition } from 'src/widgets/FloorPlan/useMaxPosition'

const TABLE_SIZE = getRealDimension(2)
const TABLE_SPACING = getRealDimension(0.75)

const TABLE_PROPS: Konva.ShapeConfig = {
  fill: PRIMARY_TABLE_BG,
  ...getTableBorder(false),
  onMouseEnter: toggleCursor,
  onMouseLeave: toggleCursor,
}

const squareTable: FloorPlanTableInterface = {
  ...newSquareTable(),
  position: {
    x: 0,
    y: 0,
  },
}

const roundTable: FloorPlanTableInterface = {
  ...newRoundTable(),
  position: {
    x: TABLE_SIZE / 2,
    y: TABLE_SIZE + TABLE_SIZE / 2 + TABLE_SPACING,
  },
}
const rectangularTable: FloorPlanTableInterface = {
  ...newRectangularTable(),
  position: {
    x: 0,
    y: (TABLE_SIZE + TABLE_SPACING) * 2,
  },
}

const createTableProps = (
  table: FloorPlanTableInterface,
  newTableFactory: () => void,
) => ({
  ...table.position,
  ...TABLE_PROPS,
  onClick: newTableFactory,
  onTap: newTableFactory,
})

const elements = [newFurniture()] as const
const tables = [squareTable, roundTable, rectangularTable]

const TableSelection = ({
  isElementPending,
  onCreateNewTable,
}: {
  isElementPending: boolean
  onCreateNewTable: (table: FloorPlanElementInterface) => void
}) => {
  const { t } = useTranslation('', { keyPrefix: 'settings.tables' })

  const tableCanvasContainerRef = React.useRef()
  const tableCanvasSize = useMaxPosition(tables)

  const elementsCanvasContainerRef = React.useRef()
  const elementCanvasSize = useMaxPosition(elements)

  if (isElementPending)
    return (
      <CenteredBox>
        <CircularProgress />
      </CenteredBox>
    )

  return (
    <OutlinedContainer label={t('elementChoice')}>
      <Stack direction="column" spacing={2}>
        <Stack direction="column">
          <Typography variant="body3">{t('tableChoice')}</Typography>
          <Box ref={tableCanvasContainerRef}>
            <Stage {...tableCanvasSize}>
              <Layer>
                <Group x={1} y={GRID_STEP / 2}>
                  <RectangularElement
                    element={squareTable}
                    shapeProps={createTableProps(
                      squareTable,
                      compose(onCreateNewTable, newSquareTable),
                    )}
                  />
                  <EllipticElement
                    element={roundTable}
                    shapeProps={createTableProps(
                      roundTable,
                      compose(onCreateNewTable, newRoundTable),
                    )}
                  />
                  <RectangularElement
                    element={rectangularTable}
                    shapeProps={createTableProps(
                      rectangularTable,
                      compose(onCreateNewTable, newRectangularTable),
                    )}
                  />
                </Group>
              </Layer>
            </Stage>
          </Box>
        </Stack>
        <Stack direction="column">
          <Typography variant="body3">{t('elementChoice')}</Typography>
          <Box ref={elementsCanvasContainerRef}>
            <Stage {...elementCanvasSize}>
              <Layer>
                <Group x={1} y={GRID_STEP / 2}>
                  <RectangularElement
                    element={elements[0]}
                    shapeProps={{
                      onClick: () => onCreateNewTable(newFurniture()),
                      onTap: () => onCreateNewTable(newFurniture()),
                      onMouseEnter: toggleCursor,
                      onMouseLeave: toggleCursor,
                      fill: elements[0].color,
                    }}
                  />
                </Group>
              </Layer>
            </Stage>
          </Box>
        </Stack>
      </Stack>
    </OutlinedContainer>
  )
}

export default TableSelection
