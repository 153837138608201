import { useCallback } from 'react'

import { useHandleIncomingNotification } from 'src/entities/notification/queries/notification'
import { NotificationTypeEnum } from 'src/entities/notification/types/notification'
import useSseEventListenerEffect from 'src/shared/lib/api/sse/useSseEventListenerEffect'

const useTodoItemCreatedSseEffect = () => {
  const handleNotification = useHandleIncomingNotification()

  useSseEventListenerEffect(
    NotificationTypeEnum.TodoItemCreated,
    useCallback(({ data }) => handleNotification(data), [handleNotification]),
  )
}

export default useTodoItemCreatedSseEffect
