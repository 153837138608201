import React, { useCallback, useMemo } from 'react'
import { compose, sortBy } from 'ramda'

import {
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'

import { useServiceTimeMessagesCacheKey } from 'src/entities/service-time/queries/serviceTime'
import { showToast } from 'src/shared/components/common/toast'
import { useBetterApiClient } from 'src/shared/lib/api/hooks/useBetterApiClient'
import { useOptimisticUpdate } from 'src/shared/lib/api/queries/optimisticUpdate'
import { useRestaurantCacheKeyFactory } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import useSseEventListenerEffect from 'src/shared/lib/api/sse/useSseEventListenerEffect'
import { updateBy } from 'src/shared/lib/common/services/functional/functional'
import {
  convertToTimezone,
  getStartOf,
} from 'src/shared/lib/range/services/date'
import { NotificationTypeEnum } from '../../notification/types/notification'
import { type RoomInterface } from '../../room/types/room'
import { useScheduleApiClient } from '../../schedule/api/scheduleApi'
import {
  useDeleteBookingLockMutation,
  usePaxCacheKeyFactory,
  useScheduleCacheKey,
  useUpsertBookingLockMutation,
} from '../../schedule/queries/schedule'
import { getShifts, setBadWeather } from '../api/shiftApi'
import { getRoomShifts } from '../services/shift'
import { type Shift } from '../types/shift'

export const SHIFT_CACHE_KEY = ['shifts']
const useShiftsCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(SHIFT_CACHE_KEY)

const useShiftsQueryOptions = (date?: Date) => {
  const apiClient = useBetterApiClient()
  const cacheKeyFactory = useShiftsCacheKeyFactory()

  return React.useMemo(() => {
    const dayStart = date && convertToTimezone(getStartOf('day')(date))

    return queryOptions({
      queryFn: () => {
        if (!dayStart) return [] as Shift[]

        return getShifts(apiClient)(dayStart)
      },
      queryKey: cacheKeyFactory([dayStart]),
      staleTime: 15 * 60 * 1000,
      refetchInterval: 15 * 60 * 1000,
    })
  }, [apiClient, cacheKeyFactory, date])
}

export const useRoomShiftsQuery = (date: Date, roomId: RoomInterface['id']) =>
  useSuspenseQuery({
    ...useShiftsQueryOptions(date),
    select: compose(
      getRoomShifts(roomId),
      sortBy(s => s.timeRange[0]),
    ),
  })

export const useShiftsQuery = (date?: Date) =>
  useSuspenseQuery({
    ...useShiftsQueryOptions(date),
    select: sortBy(s => s.timeRange[0]),
  })

export const useSetShiftLockStatus = () => {
  const queryClient = useQueryClient()
  const updateOptimistically = useOptimisticUpdate()
  const cacheKeyFactory = useShiftsCacheKeyFactory()

  const { mutate: upsertLock, isPending: upsertPending } =
    useUpsertBookingLockMutation()
  const { mutate: deleteLock, isPending: deletePending } =
    useDeleteBookingLockMutation()

  const isPending = useMemo(
    () => upsertPending || deletePending,
    [deletePending, upsertPending],
  )

  const setShiftLockStatus = useCallback(
    ({
      shiftId,
      date,
      lockStatus,
    }: {
      shiftId: Shift['id']
      date: Date
      lockStatus: Shift['lockStatus']
    }) => {
      const day = convertToTimezone(getStartOf('day')(date))
      const queryKey = cacheKeyFactory([day])

      updateOptimistically(queryKey, (shifts: Shift[]) =>
        updateBy(
          (s: Shift) => s.id === shiftId,
          s => ({ ...s, lockStatus }),
          shifts,
        ),
      )

      const onError = () => queryClient.invalidateQueries({ queryKey })

      if (lockStatus === 'open') {
        return deleteLock({ shiftId, day }, { onError })
      }

      return upsertLock(
        {
          shiftId,
          day,
          bookingChannels:
            lockStatus === 'locked' ? ['offline', 'online'] : ['online'],
        },
        { onError },
      )
    },
    [
      cacheKeyFactory,
      deleteLock,
      queryClient,
      updateOptimistically,
      upsertLock,
    ],
  )

  return useMemo(
    () => ({
      isPending,
      setShiftLockStatus,
    }),
    [isPending, setShiftLockStatus],
  )
}

export const useSetBadWeatherMutation = () => {
  const apiClient = useScheduleApiClient()

  const updateOptimistically = useOptimisticUpdate()
  const cacheKeyFactory = useShiftsCacheKeyFactory()

  const updateCache = useCallback(
    ({
      date,
      shiftId,
      badWeather,
    }: Parameters<ReturnType<typeof setBadWeather>>[0]) => {
      const dateStart = convertToTimezone(getStartOf('day')(date))

      return updateOptimistically(
        cacheKeyFactory([dateStart]),
        (shifts: Shift[]) =>
          updateBy(
            (s: Shift) => s.id === shiftId,
            s => ({ ...s, badWeatherMode: badWeather }),
            shifts,
          ),
      )
    },
    [cacheKeyFactory, updateOptimistically],
  )

  return useMutation({
    mutationFn: setBadWeather(apiClient),
    onMutate: updateCache,
    onError: (error, data) => {
      showToast('Something went wrong', 'error', error.message)

      updateCache({ ...data, badWeather: !data.badWeather })
    },
  })
}

export const useShiftsChangedSseEffect = () => {
  const queryClient = useQueryClient()
  const shiftInstanceCacheKey = useShiftsCacheKeyFactory()()
  const scheduleCacheKey = useScheduleCacheKey()
  const paxCacheKey = usePaxCacheKeyFactory()()

  useSseEventListenerEffect(NotificationTypeEnum.ScheduleUpdated, () => {
    void queryClient.invalidateQueries({ queryKey: shiftInstanceCacheKey })
    void queryClient.invalidateQueries({ queryKey: scheduleCacheKey })
    void queryClient.invalidateQueries({ queryKey: paxCacheKey })
  })
}

export const useServiceTimeNoteUpdatedSseEffect = () => {
  const queryClient = useQueryClient()
  const queryKey = useServiceTimeMessagesCacheKey()

  useSseEventListenerEffect(NotificationTypeEnum.ServiceTimeNoteUpdated, () => {
    void queryClient.invalidateQueries({ queryKey })
  })
}
