import { type useFormNavigation } from '../../hooks/formNaviagtion'
import {
  type EventOverlap,
  type ExceptionOverlap,
} from '../../services/periodAwareValidation'
import BookingSection from '../BookingSection/BookingSection'
import { type FormSection } from '../FormNavigation/FormNavigation'
import ExceptionGeneralSection from '../GeneralSection/ExceptionGeneralSection'
import LabelsSection from '../LabelsSection/LabelsSection'
import LimitsSection from '../LimitsSection/LimitsSection'
import MessageSection from '../MessageSection/MessageSection'
import NotificationsSection from '../NotificationsSection/NotificationsSection'
import PaymentsSection from '../PaymentsSection/PaymentsSection'

interface AdvancedExceptionFormSectionProps {
  intersections: (EventOverlap | ExceptionOverlap)[]
  navItem: ReturnType<typeof useFormNavigation<FormSection>>['NavigationItem']
  shiftDisabled: boolean
  showLabelsSection: boolean
  showPaymentsSection: boolean
}

export const AdvancedExceptionFormSection = ({
  navItem: NavigationItem,
  intersections,
  shiftDisabled,
  showLabelsSection,
  showPaymentsSection,
}: AdvancedExceptionFormSectionProps) => (
  <>
    <NavigationItem sectionKey="general">
      <ExceptionGeneralSection intersections={intersections} />
    </NavigationItem>
    {!shiftDisabled && (
      <>
        <NavigationItem sectionKey="booking">
          <BookingSection />
        </NavigationItem>
        <NavigationItem sectionKey="limits">
          <LimitsSection />
        </NavigationItem>
        <NavigationItem sectionKey="message">
          <MessageSection />
        </NavigationItem>
        <NavigationItem sectionKey="notifications">
          <NotificationsSection />
        </NavigationItem>
        <NavigationItem sectionKey="labels" show={showLabelsSection}>
          <LabelsSection />
        </NavigationItem>
        <NavigationItem sectionKey="payments" show={showPaymentsSection}>
          <PaymentsSection />
        </NavigationItem>
      </>
    )}
  </>
)
