import React from 'react'

import { type RestaurantInterface } from 'src/entities/restaurant/types/restaurant'
import { type RoomInterface } from 'src/entities/room/types/room'
import RoomSelect from './RoomSelect'

const RoomCell = ({
  roomId,
  restaurantId,
  restaurants,
  onChange,
  rooms,
}: {
  roomId: RoomInterface['id']
  restaurantId: RestaurantInterface['hash']
  restaurants: RestaurantInterface[]
  onChange: (room: RoomInterface) => void
  rooms: RoomInterface[]
}) => {
  const selectedRoom = rooms.find(
    room => room.restaurantId === restaurantId && room.id === roomId,
  )

  return (
    <RoomSelect
      selectedRoom={selectedRoom}
      onRoomSelect={onChange}
      rooms={rooms}
      size="small"
      restaurants={restaurants}
      fullWidth
    />
  )
}

export default React.memo(
  RoomCell,
  (prevProps, nextProps) =>
    prevProps.roomId === nextProps.roomId &&
    prevProps.restaurantId === nextProps.restaurantId &&
    prevProps.rooms === nextProps.rooms &&
    prevProps.restaurants === nextProps.restaurants,
)
