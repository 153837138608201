import { useCallback, useEffect, useMemo } from 'react'

import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { create } from 'mutative'

import { type PhoneCall } from 'src/entities/notification/types/notification'
import { useTickingCurrentTime } from 'src/pages/Reservations/components/Timeline/hooks/useTickingCurrentTime'
import {
  addToDate,
  getNowInRestaurantTimezone,
  isInFuture,
} from '../../../range/services/date'

export interface PhoneCallAtomValue {
  phoneCall: PhoneCall
  isOpen: boolean
  wasOpened: boolean
  visible: boolean
  createdAt: Date
  modifiedAt: Date
}

const phoneCallsAtom = atom([] as PhoneCallAtomValue[])

export const usePhoneCalls = () => useAtomValue(phoneCallsAtom)
export const useResetPhoneCalls = () => {
  const set = useSetAtom(phoneCallsAtom)

  return useCallback(() => set([]), [set])
}

export const usePhoneCallHandlers = () => {
  const setPhoneCalls = useSetAtom(phoneCallsAtom)

  const onOpen = useCallback(
    (phoneCall: PhoneCall) =>
      setPhoneCalls(prev =>
        create(prev, dr => {
          const call = dr.find(p => p.phoneCall.number === phoneCall.number)
          if (call) {
            call.modifiedAt = getNowInRestaurantTimezone()
            call.wasOpened = true
            call.isOpen = true
          }
        }),
      ),
    [setPhoneCalls],
  )

  const onClose = useCallback(
    (phoneCall: PhoneCall) =>
      setPhoneCalls(prev =>
        create(prev, dr => {
          const call = dr.find(p => p.phoneCall.number === phoneCall.number)
          if (call) {
            call.modifiedAt = getNowInRestaurantTimezone()
            call.isOpen = false
          }
        }),
      ),
    [setPhoneCalls],
  )

  return useMemo(() => ({ onOpen, onClose }), [onClose, onOpen])
}

export const useAddPhoneCall = () => {
  const setPhoneCalls = useSetAtom(phoneCallsAtom)

  return useCallback(
    (phoneCall: PhoneCall) =>
      setPhoneCalls(prev =>
        create(prev, dr => {
          const duplicate = dr.find(
            c => c.phoneCall.number === phoneCall.number,
          )

          if (duplicate) {
            duplicate.createdAt = getNowInRestaurantTimezone()
            duplicate.modifiedAt = getNowInRestaurantTimezone()
            duplicate.visible = true
            return
          }

          dr.push({
            phoneCall,
            createdAt: getNowInRestaurantTimezone(),
            modifiedAt: getNowInRestaurantTimezone(),
            visible: true,
            isOpen: false,
            wasOpened: false,
          })
        }),
      ),
    [setPhoneCalls],
  )
}

export const usePhoneCallAtomGlobalEffect = () => {
  const [calls, setCalls] = useAtom(phoneCallsAtom)

  const time = useTickingCurrentTime(5000)

  useEffect(() => {
    if (!calls.length) return

    const fiveSecondsAgo = addToDate(-5, 'seconds', time)
    const tenSecondsAgo = addToDate(-10, 'seconds', time)
    const sixtySecondsAgo = addToDate(-60, 'seconds', time)
    const sixtyFiveSecondsAgo = addToDate(-65, 'seconds', time)

    setCalls(prev => {
      const filtered = prev.filter(
        c =>
          c.isOpen ||
          isInFuture('second')(
            c.wasOpened ? tenSecondsAgo : sixtyFiveSecondsAgo,
            c.modifiedAt,
          ),
      )

      return create(filtered, dr => {
        dr.forEach(c => {
          if (
            !c.isOpen &&
            !isInFuture('second')(
              c.wasOpened ? fiveSecondsAgo : sixtySecondsAgo,
              c.modifiedAt,
            )
          ) {
            // eslint-disable-next-line no-param-reassign
            c.visible = false
          }
        })
      })
    })
  }, [calls.length, setCalls, time])
}
