import React, { useCallback, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import useCurrentTeamMember from 'src/entities/team-member/hooks/useCurrentTeamMember'
import { useTeamMembersQuery } from 'src/entities/team-member/queries/teamMember'
import { type TeamMember } from 'src/entities/team-member/types/teamMember'
import CreateButton from 'src/shared/components/buttons/CreateButton'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import TeamMembersList from 'src/widgets/TeamMembers/List'
import { TeamMemberGrid } from 'src/widgets/TeamMembers/List/TeamMemberGrid'
import { EditTeamMemberModal } from './components/EditTeamMemberModal'

const TeamMembers = () => {
  const [editedTeamMember, setEditedTeamMember] =
    React.useState<TeamMember | null>(null)

  const { data: teamMembers } = useTeamMembersQuery()

  const currentTeamMember = useCurrentTeamMember()

  const isAdmin = currentTeamMember?.role === 'admin'

  const { t } = useTranslation()
  const dialogProps = useDialog()

  const handleEdit = useCallback(
    (teamMember: TeamMember) => {
      setEditedTeamMember(teamMember)
      dialogProps.handleOpen()
    },
    [dialogProps],
  )

  const handleAdd = useCallback(() => {
    setEditedTeamMember(null)
    dialogProps.handleOpen()
  }, [dialogProps])

  const admins = useMemo(
    () => teamMembers.filter(member => member.role === 'admin'),
    [teamMembers],
  )

  const others = useMemo(
    () => teamMembers.filter(member => member.role !== 'admin'),
    [teamMembers],
  )

  return (
    <Stack sx={{ py: 4 }}>
      {isAdmin && admins.length > 0 && (
        <Stack gap={3}>
          <Typography variant="labelBig">{t('admins', 'Admins')}</Typography>
          <TeamMemberGrid>
            <TeamMembersList members={admins} onSelect={handleEdit} />
          </TeamMemberGrid>
        </Stack>
      )}

      <Stack gap={3}>
        <Typography variant="labelBig">
          {t('settings.team_members.team_members', 'Team members')}
        </Typography>

        <CreateButton
          id="settings_team_members_add_new"
          onClick={handleAdd}
          sx={{ alignSelf: 'flex-start' }}
        >
          {t('settings.team_members.addTeamMember')}
        </CreateButton>

        <TeamMemberGrid>
          <TeamMembersList members={others} onSelect={handleEdit} />
        </TeamMemberGrid>
      </Stack>

      <EditTeamMemberModal
        dialogProps={dialogProps}
        currentTeamMember={currentTeamMember}
        editedTeamMember={editedTeamMember}
      />
    </Stack>
  )
}

export default TeamMembers
