import { andThen, compose, composeWith, map, prop } from 'ramda'

import { z } from 'zod'

import {
  convertKeysToCamelCase,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import { createBatchedFetch } from 'src/shared/lib/api/services/createBatcher'
import { addCursorPagination } from 'src/shared/lib/api/services/reservationsBookApiClient'
import { type Page } from 'src/shared/lib/api/types/api'
import {
  createPlaceholderCustomer,
  customerPayloadFactory,
  getCustomerFactory,
} from '../services/customerFactory'
import { type CustomerInterface } from '../types/customer'
import {
  customerPaginatedSearchResponseSchema,
  customerSchema,
  statsSchema,
  type ApiCustomer,
} from '../types/customerApi'

const GET_ALL_CUSTOMER_API_PATH = 'customersapi/getAll'

// eslint-disable-next-line @typescript-eslint/require-await
const fetchAllCustomers = (httpClient: ApiClient) => async (ids: number[]) =>
  composeWith(andThen)([
    (c: ApiCustomer[]) => c.map(getCustomerFactory),
    data => z.array(customerSchema).parse(data),
    convertKeysToCamelCase,
    prop('data'),
    httpClient,
  ])({
    url: GET_ALL_CUSTOMER_API_PATH,
    json: { customer_ids: ids },
  })

const fetchBatchedCustomers = createBatchedFetch(fetchAllCustomers, 'id')

export const fetchCustomer: typeof fetchBatchedCustomers =
  apiClient => async id => {
    const customer = await fetchBatchedCustomers(apiClient)(id)

    if (!customer) {
      // eslint-disable-next-line no-console
      console.log(`Unbekannt customer of ID ${id}`)
    }

    return customer ?? createPlaceholderCustomer(id)
  }

export const searchCustomers =
  (httpClient: ApiClient) => (searchPhrase: string, all: boolean, page: Page) =>
    composeWith(andThen)([
      (payload: unknown) =>
        customerPaginatedSearchResponseSchema.parse(payload),
      compose(addCursorPagination(page))(httpClient),
    ])({
      url: 'customersapi/search',
      json: {
        search_term: searchPhrase.replace(/^\+/, '00'),
        all: all ? 1 : 0,
      },
    })

const MERGE_CUSTOMERS_API_PATH = 'merge'
export const mergeCustomers =
  (httpClient: ApiClient) =>
  (target: CustomerInterface, sources: CustomerInterface[]) =>
    httpClient({
      url: MERGE_CUSTOMERS_API_PATH,
      json: {
        target: target.id,
        sources: sources.map(prop('id')),
      },
    })

const ADD_CUSTOMER_API_PATH = 'postCustomer'
export const addCustomer =
  (httpClient: ApiClient) => (customer: CustomerInterface) =>
    httpClient({
      url: ADD_CUSTOMER_API_PATH,
      json: {
        customer: customerPayloadFactory(customer),
      },
    }) as Promise<{ customer_id: number }>

const UPDATE_CUSTOMER_API_PATH = 'putCustomer'
export const putCustomer =
  (httpClient: ApiClient) => (customer: CustomerInterface) =>
    httpClient({
      url: UPDATE_CUSTOMER_API_PATH,
      json: {
        customer: customerPayloadFactory(customer),
      },
    })

const getAllStats =
  // eslint-disable-next-line @typescript-eslint/require-await
  (httpClient: ApiClient) => async (customerIds: CustomerInterface['id'][]) =>
    composeWith(andThen)([
      map(d => statsSchema.parse(d)),
      prop('data'),
      httpClient,
    ])({
      url: 'customersapi/statsAll',
      json: {
        customer_ids: customerIds,
      },
    })

export const fetchStats = createBatchedFetch(getAllStats, 'customerId')
