import { useCallback, useState } from 'react'

import { HTTPError } from 'ky'

import {
  conflictResolutionResponseSchema,
  type ConflictResolution,
} from 'src/entities/schedule/types/scheduleApi'
import { convertKeysToCamelCase } from 'src/shared/lib/api/services/api'

export const useConflictResolutionModal = (onError: (error: Error) => void) => {
  const [resolutions, setResolutions] = useState<ConflictResolution[] | null>(
    null,
  )

  return {
    modalProps: {
      open: !!resolutions,
      onClose: useCallback(() => setResolutions(null), []),
      resolutions,
    },
    onError: useCallback(
      async (error: Error) => {
        if (!(error instanceof HTTPError)) return onError(error)

        try {
          const data = conflictResolutionResponseSchema.parse(
            convertKeysToCamelCase(await error.response.json()),
          )

          return setResolutions(data.resolutions)
        } catch (e) {
          return onError(error)
        }
      },
      [onError],
    ),
  }
}
