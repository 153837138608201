import React from 'react'
import { juxt } from 'ramda'
import { Button, Stack } from '@mui/material'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  emptyLink,
  reservationLinkSchema,
  type ReservationLink,
} from 'src/entities/reservation/types/reservationApi'
import Modal from 'src/shared/components/common/Modal'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'

interface LinkEditDialogProps {
  open: boolean
  onClose: () => void
  onSave: (link: ReservationLink) => void
  initValue: ReservationLink
}

const LinkEditDialog = ({
  open,
  onClose,
  onSave,
  initValue,
}: LinkEditDialogProps) => {
  const { t } = useTranslation()

  const linkExists = !!initValue.url

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ReservationLink>({
    resolver: zodResolver(reservationLinkSchema),
    defaultValues: initValue,
  })

  React.useEffect(() => {
    reset(initValue)
  }, [initValue, reset])

  const title = linkExists
    ? t('reservation_edit.link.dialog_title.edit', {
        tDescription: 'Reservation edit drawer, existing link dialog title',
        defaultValue: 'Edit link',
      })
    : t('reservation_edit.link.dialog_title.edit', {
        tDescription: 'Reservation edit drawer, new link dialog title',
        defaultValue: 'Add link',
      })

  React.useEffect(
    () => () => {
      if (open) return

      reset()
    },
    [reset, open],
  )

  const deleteHandler = () => onSave(emptyLink)

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      content={
        <Stack direction="column" spacing={1}>
          <FormTextInput
            {...register('name')}
            errorText={errors?.name?.message}
            label={t('reservation_edit.link.name.label', {
              defaultValue: 'Link text',
              tDescription: 'Reservation edit drawer, link text label',
            })}
          />
          <FormTextInput
            {...register('url')}
            errorText={errors?.url?.message}
            label={t('reservation_edit.link.url.label', {
              defaultValue: 'Link URL',
              tDescription: 'Reservation edit drawer, link URL label',
            })}
          />
        </Stack>
      }
      actions={
        <>
          <Button
            variant="outlined"
            color="neutral"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {t('common.form_buttons.cancelButtonLabel')}
          </Button>
          {linkExists && (
            <Button
              variant="color-outlined"
              aria-checked
              color="error"
              size="large"
              fullWidth
              onClick={juxt([onClose, deleteHandler])}
            >
              {t('common.form_buttons.deleteButtonLabel')}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={handleSubmit(juxt([onClose, onSave]))}
          >
            {t('common.form_buttons.saveButtonLabel')}
          </Button>
        </>
      }
    />
  )
}

export default LinkEditDialog
