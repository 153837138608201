import React, { Suspense } from 'react'
import { Box, Skeleton, Stack, useMediaQuery } from '@mui/material'

import { useNavigate } from '@tanstack/react-router'

import { SearchTabsEnum } from 'src/pages/Search/SearchTabs'
import IconButton from 'src/shared/components/buttons/IconButton'
import SearchIcon from 'src/shared/components/icons/SearchIcon'
import NotificationsInbox from './components/NotificationsInbox/NotificationsInbox'
import { TopBarPhoneCalls } from './components/PhoneCalls/TopBarPhoneCalls'
import { RestaurantSelect } from './components/RestaurantSelect'
import { TeamMemberToggle } from './components/TeamMemberToggle'
import { TopBarDate } from './components/TopBarDate'
import { TopBarMenu } from './components/TopBarMenu'
import { useRestaurantSelectFits } from './hooks/useRestaurantSelectFits'
import logo from '../../../Login/foratable-logo-dark.png'

interface TopBarProps {
  restaurantId: number
}

const FatLogo = () => (
  <Box
    component="img"
    src={logo}
    sx={theme => ({
      width: 120,
      objectFit: 'contain',
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    })}
  />
)

export const TopBar = ({ restaurantId }: TopBarProps) => {
  const navigate = useNavigate()

  const restaurantSelectFits = useRestaurantSelectFits()
  const isPrintPreview = useMediaQuery('print')

  const phoneCallNotificationClickHandler = React.useCallback(
    (phoneNumber: string) =>
      navigate({
        to: '/restaurants/$restaurantId/search',
        params: { restaurantId },
        search: { tab: SearchTabsEnum.Customers, q: phoneNumber },
      }),
    [navigate, restaurantId],
  )

  if (isPrintPreview) return null

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 1,
        background: 'white',
        px: [0, 1],
        py: 0.5,
      }}
    >
      <Stack
        flexDirection="row"
        gap={1}
        sx={theme => ({
          flex: '1 1 10%',
          maxWidth: 440,
          [theme.breakpoints.down('lg')]: {
            maxWidth: 320,
          },
          ...(!restaurantSelectFits ? { flex: 'initial' } : undefined),
        })}
      >
        <FatLogo />
        <RestaurantSelect />
      </Stack>
      <TopBarDate />
      <Stack gap={0.5} direction="row" alignItems="center">
        <Suspense>
          <TopBarPhoneCalls
            restaurantId={restaurantId}
            onShowAllClick={phoneCallNotificationClickHandler}
          />
        </Suspense>
        <IconButton
          color="neutral"
          variant="outlined"
          onClick={() =>
            navigate({
              to: '/restaurants/$restaurantId/search',
              params: { restaurantId },
            })
          }
        >
          <SearchIcon />
        </IconButton>

        <Suspense
          fallback={<Skeleton variant="rectangular" width={42} height={40} />}
        >
          <NotificationsInbox
            restaurantId={restaurantId}
            onPhoneCallClick={phoneCallNotificationClickHandler}
          />
        </Suspense>
        <Suspense
          fallback={<Skeleton variant="circular" width={36} height={36} />}
        >
          <TeamMemberToggle />
        </Suspense>
        <Suspense
          fallback={<Skeleton variant="rectangular" width={42} height={40} />}
        >
          <TopBarMenu restaurantId={restaurantId} />
        </Suspense>
      </Stack>
    </Box>
  )
}
