import { Button, Link, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  type Integration,
  type IntegrationName,
} from 'src/entities/integration/api/integrationsApi'
import { SpinnerOverlay } from 'src/shared/components/common/SpinnerOverlay/SpinnerOverlay'
import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import { IntegrationIcons } from '../constants/IntegrationIconMap'

interface IntegrationCardProps {
  integrationName: IntegrationName
  status: Integration['status']
  isLoading: boolean
  onConnect: () => void
  onDisconnect: () => void
}

export const IntegrationCard = ({
  integrationName,
  status,
  isLoading,
  onConnect,
  onDisconnect,
}: IntegrationCardProps) => {
  const { t } = useTranslation()

  const disconnectDialog = useSimpleDialog()

  const isConnected = status === 'connected'
  const Icon = IntegrationIcons[integrationName]

  return (
    <Stack
      gap={3}
      p={3}
      border="1px solid"
      borderColor="grey.100"
      borderRadius={16}
      alignItems="flex-start"
      justifyContent="space-between"
      position="relative"
      overflow="hidden"
    >
      <Stack gap={2} alignItems="flex-start">
        <Stack
          p={1.5}
          border="1px solid"
          borderColor="grey.100"
          borderRadius={8}
        >
          <Icon />
        </Stack>
        <Stack gap={1}>
          <Typography variant="labelBig">
            {t(
              `settings.integrations.${integrationName}.title`,
              integrationName,
            )}
          </Typography>
          <Typography>
            {t(
              `settings.integrations.${integrationName}.description`,
              `${integrationName} description`,
            )}
          </Typography>
        </Stack>

        <Link
          component="a"
          target="_blank"
          rel="noopener noreferrer"
          href={t(
            `settings.integrations.${integrationName}.read_more_link`,
            `https://www.google.com/search?q=${integrationName}+description`,
          )}
          sx={{ textDecoration: 'none' }}
        >
          {t('settings.integrations.read_all_features', 'Read all features')}
        </Link>
      </Stack>
      <Button
        variant={isConnected ? 'text' : 'outlined'}
        color={isConnected ? 'error' : 'neutral'}
        onClick={
          isConnected ? () => disconnectDialog.showSimpleDialog() : onConnect
        }
        sx={{ ...(isConnected && { p: 0 }) }}
      >
        {isConnected
          ? t('settings.integrations.disconnect', 'Disconnect')
          : t('settings.integrations.connect', 'Connect')}
      </Button>
      {isLoading && <SpinnerOverlay />}
      <SimpleDialog
        color="error"
        title={t('common.caution', 'Caution')}
        message={t(
          'settings.integrations.disconnect_disclaimer',
          'The integration will no longer work after disconnecting.',
        )}
        confirmButtonText={t('settings.integrations.disconnect', 'Disconnect')}
        onConfirmation={onDisconnect}
        {...disconnectDialog.simpleDialogProps}
      />
    </Stack>
  )
}
