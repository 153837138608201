/* eslint-disable react-hooks/rules-of-hooks */
import {
  compose,
  curry,
  find,
  identity,
  lte,
  partition,
  prop,
  propSatisfies,
  useWith,
} from 'ramda'

import { areDatesEqual, isDateSame } from 'src/shared/lib/range/services/date'
import { type TimeRangeAware } from 'src/shared/lib/range/types/timeRange'
import { type ReservationInterface } from '../../reservation/types/reservation'
import { overlapsTime } from '../../service-time/services/serviceTime'
import { type AvailabilityInterface } from '../types/availability'

export const hasEnoughSeats = (
  seatCount: number,
): ((availability: AvailabilityInterface) => boolean) =>
  propSatisfies(lte(seatCount), 'remainingCapacity')

export const adjustCapacityBySlotReservation =
  (reservation: ReservationInterface | null) =>
  (availability: AvailabilityInterface) => {
    if (!reservation) return availability
    return {
      ...availability,
      remainingCapacity: availability.remainingCapacity + reservation.seatCount,
    }
  }

const calculateAdjustedAvailability = (
  availability: AvailabilityInterface,
  reservation: ReservationInterface,
) => {
  const isLimitedByShift =
    availability.remainingCapacity === availability.remainingShiftCapacity
  if (!isLimitedByShift) return availability.remainingCapacity

  const adjustedAvailability =
    adjustCapacityBySlotReservation(reservation)(availability)

  return Math.min(
    adjustedAvailability.totalCapacity,
    adjustedAvailability.remainingCapacity,
  )
}

const getTime: (availability: AvailabilityInterface) => Date = prop('time')

export const adjustCapacityByShiftReservation =
  (reservation: ReservationInterface | null) =>
  (availability: AvailabilityInterface) => {
    if (!reservation) return availability

    return {
      ...availability,
      remainingCapacity: calculateAdjustedAvailability(
        availability,
        reservation,
      ),
    }
  }

const isAvailabilityInShift =
  (st: TimeRangeAware) => (a: AvailabilityInterface) =>
    overlapsTime([st], getTime(a))

export const partitionByShift = (shift: TimeRangeAware) =>
  partition<AvailabilityInterface>(curry(isAvailabilityInShift)(shift))

const isAvailabilityInTime = useWith<
  Date,
  Date,
  AvailabilityInterface,
  Date,
  boolean
>(areDatesEqual, [identity, getTime])
export const partitionByTime = (time: Date) =>
  partition<AvailabilityInterface>(curry(isAvailabilityInTime)(time))

export const getForTime = (time: Date) => {
  const isTimeSame = isDateSame(time)
  const isAvailabilityDateSame = compose(isTimeSame, getTime)

  return find<AvailabilityInterface>(isAvailabilityDateSame)
}
