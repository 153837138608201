import { Fragment } from 'react'
import { assocPath } from 'ramda'
import { Divider, Stack, Typography } from '@mui/material'

import { Trans, useTranslation } from 'react-i18next'

import {
  type ConflictResolution,
  type SelectableConflictCommand,
} from 'src/entities/schedule/types/scheduleApi'
import { RadioOption } from 'src/shared/components/form/inputs/RadioChoice'
import { useShortDateRangeFormatter } from 'src/shared/lib/range/hooks/useFormattedDate'
import { fromTime } from 'src/shared/lib/range/services/date'
import { timeRangeToString } from 'src/shared/lib/range/services/timeRange'

interface SelectableResolutionsProps {
  resolutions: ConflictResolution[] | null
  selectedResolutions: ConflictResolution['commands'] | null
  setSelectedResolutions: (
    newSelectedResolutions: ConflictResolution['commands'],
  ) => void
}

export const SelectableResolutions = ({
  resolutions,
  selectedResolutions,
  setSelectedResolutions,
}: SelectableResolutionsProps) => {
  const { t } = useTranslation()

  const formatDateRange = useShortDateRangeFormatter({ weekday: undefined })

  if (!resolutions || !selectedResolutions) return null

  return (
    <>
      {resolutions.map((r, idx) => {
        if (!r.conflict) return null

        const affectedDateRange = formatDateRange([
          r.conflict.startDate,
          r.conflict.endDate,
        ])

        return (
          <Fragment key={r.conflict.id}>
            {idx > 0 && <Divider sx={{ gridColumn: 'span 2' }} />}
            <Stack gap={0.5} pt={1.5}>
              <Typography component="p" variant="labelSmall" color="grey.900">
                {r.conflict.name}
              </Typography>
              <Typography
                component={Stack}
                variant="labelTiny"
                color="grey.700"
              >
                <Typography>{affectedDateRange}</Typography>
                <Typography>
                  {timeRangeToString([
                    fromTime(r.conflict.startTime),
                    fromTime(r.conflict.endTime),
                  ])}
                </Typography>
              </Typography>
            </Stack>
            <Stack
              sx={{
                bgcolor: 'white',
                px: 2,
                py: 1.5,
                borderRadius: 1,
                gap: 0.75,
              }}
              border="1px solid"
              borderColor="grey.100"
            >
              <Typography component="p" variant="labelSmall" fontWeight={400}>
                <Trans
                  i18nKey="schedule.conflict_resolution.solution_title"
                  defaults="<span>{{conflictName}}:</span> exception on <span>{{affectedDateRange}}</span>"
                  components={{
                    span: (
                      <Typography
                        fontWeight={500}
                        component="span"
                        color="grey.900"
                      />
                    ),
                  }}
                  values={{
                    conflictName: r.conflict.name,
                    affectedDateRange,
                  }}
                />
              </Typography>
              <Stack gap={1}>
                {r.commands.map(c => (
                  <RadioOption
                    key={c.displayName}
                    value={c.displayName}
                    disabled={r.commands.length < 2}
                    size="small"
                    label={`${t(
                      `schedule.conflict_resolution.solutions.${c.displayName}`,
                      c.displayName,
                    )} ${
                      c.displayName === 'shorten_shift'
                        ? `(${timeRangeToString([
                            fromTime(c.additionalDisplayData.startTime),
                            fromTime(c.additionalDisplayData.endTime),
                          ])})`
                        : ''
                    }`}
                    onClick={() =>
                      setSelectedResolutions(
                        assocPath([idx], c, selectedResolutions),
                      )
                    }
                    checked={
                      c.displayName ===
                      (selectedResolutions[idx] as SelectableConflictCommand)
                        ?.displayName
                    }
                  />
                ))}
              </Stack>
            </Stack>
          </Fragment>
        )
      })}
    </>
  )
}
