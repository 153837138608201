import { type FormEvent } from 'react'
import { isEmpty } from 'ramda'

import { diff } from 'deep-object-diff'
import { type DefaultValues } from 'react-hook-form'

import { fromIsoDuration } from 'src/shared/lib/range/services/date'
import { type IsoDuration } from 'src/shared/lib/zod/zod'
import { type FormException } from './exceptionFormSchema'
import { type FormShift } from './shiftFormSchema'

type ShiftLike = Partial<
  DefaultValues<FormShift> &
    DefaultValues<FormEvent> &
    DefaultValues<FormException>
>

const getNotIgnoredKeys = (shiftLike: ShiftLike) => {
  const {
    weekdays,
    name,
    roomId,
    effectivePeriod,
    shiftDisabled,
    firstArrivalTime,
    lastArrivalTime,
    shiftGuestCountLimit,
    notifications,
    channelAllocations,
    bookingSetup,
    customPacing,
    ...notIgnoredKeys
  } = shiftLike

  const { bookingDeadline, ...notIgnoredBookingSetupIntervals } =
    bookingSetup ?? {}

  return {
    notifications,
    channelAllocations,
    customPacing,
    bookingSetup,
    notIgnoredBookingSetupIntervals,
    notIgnoredKeys,
  }
}

const createKey = (t: { type?: string; channel?: string }) =>
  `${t.type}_${t.channel}`

const areDifferentNotifications = (
  notifications1: DefaultValues<FormShift>['notifications'],
  notifications2: DefaultValues<FormShift>['notifications'],
) => {
  const n1 = notifications1 as FormShift['notifications']
  const n2 = notifications2 as FormShift['notifications']

  const map1 = new Map(n1.templates.map(t => [createKey(t), t.templateId]))

  return n2.templates.some(t => {
    const itemInMap = map1.get(createKey(t))

    if (!itemInMap) return true

    if (itemInMap.type !== t.templateId.type) return true

    if (
      itemInMap.type === 'custom' &&
      t.templateId.type === 'custom' &&
      itemInMap.id !== t.templateId.id
    ) {
      return true
    }

    return false
  })
}

export const shouldShowAdvancedForm = (
  defaultShiftLike: ShiftLike,
  currentShiftLike: ShiftLike,
) => {
  const {
    notIgnoredKeys: defaultNotIgnoredKeys,
    notIgnoredBookingSetupIntervals: defaultIntervals,
    channelAllocations: defaultAllocations,
    notifications: defaultNotifications,
    customPacing: defaultPacing,
  } = getNotIgnoredKeys(defaultShiftLike)

  const {
    notIgnoredKeys: currentNotIgnoredKeys,
    notIgnoredBookingSetupIntervals: currentIntervals,
    channelAllocations: currentAllocations,
    notifications: currentNotifications,
    customPacing: currentPacing,
  } = getNotIgnoredKeys(currentShiftLike)

  if (!!defaultPacing?.isActive || !!currentPacing?.isActive) return true

  if (
    !!defaultAllocations?.some(
      c => !c?.active || c.limit !== defaultShiftLike.shiftGuestCountLimit,
    ) ||
    !!currentAllocations?.some(
      c => !c?.active || c.limit !== currentShiftLike.shiftGuestCountLimit,
    )
  ) {
    return true
  }

  if (
    defaultNotifications?.isActive !== currentNotifications?.isActive ||
    areDifferentNotifications(defaultNotifications, currentNotifications)
  ) {
    return true
  }

  const areBookingSetupIntervalsDifferent = Object.keys(defaultIntervals).some(
    k => {
      const key = k as keyof typeof defaultIntervals
      return (
        fromIsoDuration(defaultIntervals[key] as IsoDuration, 'seconds') !==
        fromIsoDuration(currentIntervals[key] as IsoDuration, 'seconds')
      )
    },
  )

  return (
    areBookingSetupIntervalsDifferent ||
    !isEmpty(diff(defaultNotIgnoredKeys, currentNotIgnoredKeys))
  )
}
