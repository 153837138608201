import { useMemo } from 'react'
import { Button, ButtonGroup, Typography } from '@mui/material'

import { useRestaurantsInChain } from 'src/entities/restaurant/queries/restaurants'
import IconButton from 'src/shared/components/buttons/IconButton'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'src/shared/components/icons/ChevronIcon'
import { cycleArray } from 'src/shared/lib/common/services/array/array'

interface RoomRestaurantSelectorProps {
  selectedRestaurantHash: string
  setSelectedRestaurantHash: (hash: string) => void
}

export const RoomRestaurantSelector = ({
  selectedRestaurantHash,
  setSelectedRestaurantHash,
}: RoomRestaurantSelectorProps) => {
  const restaurants = useRestaurantsInChain()

  const moveRestaurantBy = useMemo(
    () =>
      cycleArray(
        restaurants,
        r => r.hash,
        r => setSelectedRestaurantHash(r.hash),
      )(selectedRestaurantHash),
    [restaurants, selectedRestaurantHash, setSelectedRestaurantHash],
  )

  if (!restaurants.length) return null

  return (
    <ButtonGroup
      variant="outlined"
      sx={{
        width: 1,
        backgroundColor: 'white',
      }}
    >
      <IconButton onClick={() => moveRestaurantBy(-1)}>
        <ChevronLeftIcon />
      </IconButton>
      <Button sx={{ flex: 1 }}>
        <Typography
          component="span"
          sx={{
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            lineHeight: '1',
            wordBreak: 'break-word',
          }}
        >
          {
            restaurants.find(({ hash }) => hash === selectedRestaurantHash)
              ?.name
          }
        </Typography>
      </Button>
      <IconButton onClick={() => moveRestaurantBy(1)}>
        <ChevronRightIcon />
      </IconButton>
    </ButtonGroup>
  )
}
