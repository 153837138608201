import type React from 'react'

import { useNonIntersectingItemsCount } from './useNonIntersectingItemsCount'

export const useItemVisibility = (
  containerRef: React.RefObject<HTMLElement>,
) => {
  const { itemRef, itemsCount } = useNonIntersectingItemsCount(containerRef)

  return {
    isVisible: !!itemsCount,
    itemRef,
  }
}
