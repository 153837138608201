import { ErrorOutline } from '@mui/icons-material'
import { Box } from '@mui/material'

import { type AreaInterface } from 'src/entities/area/types/area'
import { includesTime } from 'src/shared/lib/range/services/timeRange'
import CounterTabs from '../../../Reservations/components/CounterTabs'
import { type Holiday } from '../../../Reservations/components/TopBar/service/holidays'

interface AreaTabsProps {
  areas: AreaInterface[]
  selectedArea: AreaInterface | undefined
  setSelectedArea: (area: AreaInterface) => void
  counters?: Map<AreaInterface['id'], number>
  currentDate?: Date
  holidays?: Holiday[]
}

const AreaTabs = ({
  areas,
  selectedArea,
  setSelectedArea,
  counters,
  currentDate,
  holidays,
}: AreaTabsProps) => {
  if (!areas.length) return null

  return (
    <CounterTabs
      value={selectedArea ?? areas[0]!}
      onChange={setSelectedArea}
      variant="scrollable"
      renderItem={area => {
        const isHoliday = holidays?.some(
          ({ roomId, range }) =>
            !!currentDate &&
            includesTime(range, currentDate) &&
            (!roomId || area.roomId === roomId),
        )

        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              lineHeight: 1,
              whiteSpace: 'nowrap',
              color: isHoliday ? 'errorPalette.500' : undefined,
            }}
          >
            {isHoliday && <ErrorOutline sx={{ fontSize: 16 }} />}
            {area.name}
          </Box>
        )
      }}
      getCount={area => counters && (counters.get(area.id) ?? 0)}
      getKey={area => area.id}
      items={areas}
      selectedColor="white"
    />
  )
}

export default AreaTabs
