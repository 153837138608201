import { useCallback, useMemo } from 'react'
import { Stack, type StackProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { mergeSx } from 'src/app/theme/helpers'
import { type ScheduleLanguage } from 'src/entities/schedule/types/scheduleApi'
import PlayPauseIcon from 'src/shared/components/icons/PlayPauseIcon'

const supportedLanguages = ['en', 'fr', 'de', 'it'] satisfies ScheduleLanguage[]

const langToAudioUrl = (lang: string) => {
  if (!supportedLanguages.includes(lang as ScheduleLanguage))
    return 'https://cdn.foratable.com/backend/images/sounds/audio_en.mp3'

  return `https://cdn.foratable.com/backend/images/sounds/audio_${lang}.mp3`
}

export const PlaySampleMessage = ({ sx, ...props }: StackProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const audio = useMemo(() => new Audio(langToAudioUrl(language)), [language])

  const toggleAudio = useCallback(
    () => (audio.paused ? audio.play() : audio.pause()),
    [audio],
  )

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.5}
      sx={mergeSx(sx, { cursor: 'pointer', color: '#42A6D5' })}
      onClick={toggleAudio}
      {...props}
    >
      <PlayPauseIcon size="small" />
      {t('schedule.overview.play_sample_message', 'Play sample message')}
    </Stack>
  )
}
