import { assocPath } from 'ramda'
import { Stack } from '@mui/material'

import debounce from 'debounce'
import { useTranslation } from 'react-i18next'

import IconFormButtons from 'src/shared/components/buttons/IconFormButtons'
import { type FurnitureInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'
import Color from './Color'
import Dimension from './Dimension'

const FurnitureEditForm = ({
  value,
  furniture,
  onDelete,
  onChange,
  onCancel,
}: {
  value: FurnitureInterface
  furniture: FurnitureInterface[]
  onChange: (element: FurnitureInterface) => void
  onDelete: (element: FurnitureInterface) => void
  onCancel: () => void
}) => {
  const { t } = useTranslation('', { keyPrefix: 'settings.tables' })

  const heightChangeHandler = (height: number) =>
    onChange(assocPath(['size', 'height'], height, value))

  const widthChangeHandler = (width: number) =>
    onChange(assocPath(['size', 'width'], width, value))

  const colorChangeHandler = debounce(
    (color: string) => onChange(assocPath(['color'], color, value)),
    50,
  )

  return (
    <Stack spacing={2} direction="column">
      <Dimension
        value={value.size.height}
        onChange={heightChangeHandler}
        label={t('elementHeight')}
      />
      <Dimension
        value={value.size.width}
        onChange={widthChangeHandler}
        label={t('elementWidth')}
      />
      <Color
        value={value.color}
        onChange={colorChangeHandler}
        furniture={furniture}
      />
      <IconFormButtons onDelete={() => onDelete(value)} onSave={onCancel} />
    </Stack>
  )
}

export default FurnitureEditForm
