import { styled, Typography } from '@mui/material'

export const InputGroupColumnHeader = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    typography: 'labelExtrasmall',
    color: 'primaryPalette.700',
    textWrap: 'nowrap',
    zIndex: 1,
  }),
)
