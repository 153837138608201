import { identity } from 'ramda'

import dayjs, { convertFromTimezone } from 'src/shared/lib/range/services/date'
import { fromEventData as phoneCallFromEventData } from './phoneCallFactory'
import {
  fromApi as todoItemFromApi,
  fromEventData as todoItemFromEventData,
} from './todoItemFactory'
import { fromEventData as readFromEventData } from './todoItemsReadFactory'
import { fromEventData as statusChangeFromEventData } from './todoItemStatusChangeFactory'
import {
  occupanciesUpdatedNotificationPayloadFactory,
  tableOccupancyStatusNotificationPayloadFactory,
} from '../../reservation/services/occupancyFactory'
import {
  exactModeChangedPayloadFactory,
  reservationCreateNotificationPayloadFactory,
  reservationNotificationPayloadFactory,
  reservationStatusNotificationPayloadFactory,
} from '../../reservation/services/reservationFactory'
import {
  tableFromEventData,
  tableNoteUpdateFromEventData,
  tableRemovedEventFromData,
} from '../../table/types/tableApi'
import {
  NotificationTypeEnum,
  type NotificationInterface,
  type SseEventMap,
  type TodoItemNotificationInterface,
  type TodoItemStatus,
  type TodoItemTypeEnum,
} from '../types/notification'

export const PayloadFromEventFactoryMap: {
  [key in NotificationInterface['type']]: (
    data: RawEventData,
  ) => SseEventMap[key]['payload']
} = {
  [NotificationTypeEnum.TodoItemCreated]: todoItemFromEventData,
  [NotificationTypeEnum.NotificationsRead]: readFromEventData,
  [NotificationTypeEnum.TodoItemStatusChange]: statusChangeFromEventData,
  [NotificationTypeEnum.PhoneCall]: phoneCallFromEventData,
  [NotificationTypeEnum.TableUpdated]: tableFromEventData,
  [NotificationTypeEnum.TableRemoved]: tableRemovedEventFromData,
  [NotificationTypeEnum.TableNoteSet]: tableNoteUpdateFromEventData,
  [NotificationTypeEnum.ReservationCreated]:
    reservationCreateNotificationPayloadFactory,
  [NotificationTypeEnum.ReservationUpdated]:
    reservationNotificationPayloadFactory,
  [NotificationTypeEnum.ReservationCancelled]:
    reservationStatusNotificationPayloadFactory,
  [NotificationTypeEnum.ReservationReactivated]:
    reservationStatusNotificationPayloadFactory,
  [NotificationTypeEnum.ReservationNoShowed]:
    reservationStatusNotificationPayloadFactory,
  [NotificationTypeEnum.ReservationReShowed]:
    reservationStatusNotificationPayloadFactory,
  [NotificationTypeEnum.GuestCheckedIn]:
    tableOccupancyStatusNotificationPayloadFactory,
  [NotificationTypeEnum.GuestCheckedOut]:
    tableOccupancyStatusNotificationPayloadFactory,
  [NotificationTypeEnum.OccupanciesUpdated]:
    occupanciesUpdatedNotificationPayloadFactory,
  [NotificationTypeEnum.ReservationExactModeChanged]:
    exactModeChangedPayloadFactory,
  [NotificationTypeEnum.ScheduleUpdated]: identity,
  [NotificationTypeEnum.ServiceTimeNoteUpdated]: identity,
}

/**
 * TODO Make these unknown and parse them in the factories
 */
export interface RawEventData {
  itemId: number
  id?: number
  aggregateId: string
  eventType: TodoItemTypeEnum
  occuredOn: string
  status: TodoItemStatus
  createdAt: string
  withHistory: boolean
  date: string
  serviceTimeId: number
  content: string
  tableId: number
  phoneNumber: string
  reservationId: string | number
}

interface RawApiData {
  itemId: number
  createdAt: string
  aggregateId: string
  status: TodoItemStatus
  eventType: TodoItemTypeEnum
}

export const fromEventData = <T extends NotificationInterface['type']>(
  notificationType: T,
  data: RawEventData,
): SseEventMap[T] =>
  ({
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    id: (data.itemId?.toString?.() || data.id?.toString?.())!,
    date: convertFromTimezone(dayjs(data.occuredOn).toDate()),
    payload: PayloadFromEventFactoryMap[notificationType](data),
    type: notificationType,
  }) as SseEventMap[T]

export const fromApi = (data: RawApiData): TodoItemNotificationInterface => ({
  id: data.itemId.toString(),
  date: convertFromTimezone(dayjs(data.createdAt).toDate()),
  payload: todoItemFromApi(data),
  type: NotificationTypeEnum.TodoItemCreated,
})
