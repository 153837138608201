import React, { useDeferredValue } from 'react'
import { Button, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  useSetShiftLockStatus,
  useShiftsQuery,
} from 'src/entities/shift-instance/queries/shift'
import type { Shift } from 'src/entities/shift-instance/types/shift'
import { useHasPermission } from 'src/entities/team-member/hooks/useCheckPermissions'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { useGlobalModalContext } from 'src/shared/lib/context/global/useGlobalModalContext'
import { useCurrentDate } from 'src/shared/lib/context/state/atoms/currentDate'
import BadWeatherIndicator from './components/ShiftsIndicators/BadWeatherShiftsIndicator'
import LockedShiftsIndicator from './components/ShiftsIndicators/LockedShiftsIndicator'
import { ShiftManagementDialog } from './ShiftManagementDialog'

const ShiftManagement = () => {
  const { t } = useTranslation()
  const date = useDeferredValue(useCurrentDate())

  const shiftManagementDialog = useDialog()
  const canManageShift = useHasPermission('manage_shifts')
  const { showPermissionModal } = useGlobalModalContext()

  const { data: shifts } = useShiftsQuery(date)
  const { setShiftLockStatus, isPending } = useSetShiftLockStatus()

  const shiftStatusChangeHandler = React.useCallback(
    (shiftId: Shift['id'], lockStatus: Shift['lockStatus']) =>
      setShiftLockStatus({
        date,
        lockStatus,
        shiftId,
      }),
    [date, setShiftLockStatus],
  )

  const lockedShifts = React.useMemo(
    () => shifts.filter(shift => shift.lockStatus !== 'open'),
    [shifts],
  )

  const badWeatherShifts = React.useMemo(
    () => shifts.filter(shift => shift.badWeatherMode),
    [shifts],
  )

  const showChips = !!lockedShifts.length || !!badWeatherShifts.length

  return (
    <>
      <Stack direction="row" alignItems="center">
        {showChips && (
          <Stack direction="row" gap={0.5} alignItems="center" sx={{ ml: 1 }}>
            <BadWeatherIndicator shifts={badWeatherShifts} />
            <LockedShiftsIndicator shifts={lockedShifts} />
          </Stack>
        )}
        <Button
          variant="text"
          onClick={() => {
            if (canManageShift) return shiftManagementDialog.handleOpen()

            return showPermissionModal({
              message: t(
                'permissions.errors.manage_shifts',
                "You don't have permission to manage shifts.",
              ),
            })
          }}
          size="small"
        >
          {t('angular.lock_shift')}
        </Button>
      </Stack>
      <ShiftManagementDialog
        dialogProps={shiftManagementDialog}
        shifts={shifts}
        onShiftStatusChange={shiftStatusChangeHandler}
        isPending={isPending}
      />
    </>
  )
}

export default ShiftManagement
