import React from 'react'
import { Stack } from '@mui/material'

import { FormProvider, useForm } from 'react-hook-form'

import IconFormButtons from 'src/shared/components/buttons/IconFormButtons'
import { type FloorPlanTableInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'
import Capacity from './Capacity'
import Name from './Name'
import Rotation from './Rotation'
import Shape from './Shape'
import Size from './Size'

const GridTableEditForm = ({
  value,
  onChange,
  onDelete,
  onCancel,
}: {
  value: FloorPlanTableInterface
  onChange: (table: FloorPlanTableInterface) => void
  onDelete: (table: FloorPlanTableInterface) => void
  onCancel: () => void
}) => {
  const form = useForm<FloorPlanTableInterface>({
    defaultValues: value,
  })

  const { reset } = form

  React.useEffect(() => {
    const { name, ...updateValues } = value
    reset(updateValues)
  }, [value, reset])

  React.useEffect(() => {
    reset(value)
  }, [value, reset])

  const [autosizeEnabled, setAutosizeEnabled] = React.useState(true)

  return (
    <FormProvider {...form}>
      <Stack spacing={2} direction="column">
        <Name onChange={onChange} />
        <Capacity onChange={onChange} autosize={autosizeEnabled} />
        <Shape onChange={onChange} />
        <Size
          onChange={onChange}
          autosize={autosizeEnabled}
          setAutosize={setAutosizeEnabled}
        />
        <Rotation onChange={onChange} />
        <IconFormButtons onDelete={() => onDelete(value)} onSave={onCancel} />
      </Stack>
    </FormProvider>
  )
}

export default GridTableEditForm
