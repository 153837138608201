import { type AvailabilityInterface } from './availabilityApi'

export enum SlotStatus {
  Available = 'available',
  NotInShift = 'notInShift',
  Unavailable = 'unavailable',
  NotInServiceTime = 'notInServiceTime',
  InClosedShift = 'inClosedShift',
}

export const availabilitiesSlotStatuses = [
  SlotStatus.NotInShift,
  SlotStatus.Unavailable,
]

export interface SlotInterface {
  time: Date
  status: SlotStatus
  availability?: AvailabilityInterface
}

export { type AvailabilityInterface }
