import { useCallback } from 'react'

import { atom, useAtom, useSetAtom } from 'jotai'

import { SORT_FIELDS } from 'src/entities/reservation/services/reservationTableSort'
import { type FilterPreset } from 'src/pages/Reservations/service/filterPreset'

const filterPresetAtom = atom('unplaced' as FilterPreset)
const sortFieldAtom = atom<(typeof SORT_FIELDS)[number]>(SORT_FIELDS[0])
const sortDirectionAtom = atom<'asc' | 'desc'>('asc')

export const useFilterPresetAtom = () => useAtom(filterPresetAtom)
export const useSortFieldAtom = () => useAtom(sortFieldAtom)
export const useSortDirectionAtom = () => useAtom(sortDirectionAtom)

export const useSetFilterPreset = () => useSetAtom(filterPresetAtom)
export const useSetSortField = () => useSetAtom(sortFieldAtom)
export const useSetSortDirection = () => useSetAtom(sortDirectionAtom)

export const useResetResetvationFilters = () => {
  const setFilterPreset = useSetFilterPreset()
  const setSortField = useSetSortField()
  const setSortDirection = useSetSortDirection()

  return useCallback(() => {
    setFilterPreset('unplaced')
    setSortField(SORT_FIELDS[0])
    setSortDirection('asc')
  }, [setFilterPreset, setSortDirection, setSortField])
}
