import { Box, Stack, useMediaQuery, type Theme } from '@mui/material'

import { FaPlus } from 'react-icons/fa'

import { type AreaInterface } from 'src/entities/area/types/area'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import SquareIconButton from 'src/shared/components/buttons/SquareIconButton'
import AreaPicker from './AreaPicker/AreaPicker'
import FilterPresetPicker from './FilterPresetPicker/FilterPresetPicker'
import ViewPicker from './ViewPicker/ViewPicker'
import { type FilterPreset } from '../../service/filterPreset'
import { type ReservationOccupancy } from '../../service/occupancy'
import {
  isAreaPickerDisplayed,
  type PresentationView,
} from '../../service/presentationView'
import { TOP_BAR_BG } from '../CounterTabs'
import { type Holiday } from '../TopBar/service/holidays'

interface ViewBarProps {
  areas: AreaInterface[]
  area: AreaInterface | undefined
  occupanciesByArea: Map<AreaInterface['id'], ReservationOccupancy[]>
  reservationsByFilterPreset: Map<FilterPreset, ReservationInterface[]>
  onAreaChange: (area: AreaInterface) => void
  view: PresentationView
  onViewChange: (view: PresentationView) => void
  preset: FilterPreset
  onPresetChange: (preset: FilterPreset) => void
  onCreateNewReservation: () => void
  currentDate: Date
  holidays: Holiday[]
}

const ViewBar = ({
  area,
  onAreaChange,
  preset,
  onPresetChange,
  areas,
  occupanciesByArea,
  view,
  onViewChange,
  reservationsByFilterPreset,
  onCreateNewReservation,
  currentDate,
  holidays,
}: ViewBarProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))
  const isPrintPreview = useMediaQuery('print')

  if (isPrintPreview) return null

  return (
    <>
      <Stack
        direction="row"
        sx={{
          gridColumn: '1',
          borderBottom: 1,
          borderColor: 'border',
          alignItems: 'end',
          backgroundColor: ['white', TOP_BAR_BG],
          justifyContent: 'space-between',
        }}
      >
        <FilterPresetPicker
          onPresetChange={onPresetChange}
          preset={preset}
          reservationsByPreset={reservationsByFilterPreset}
        />
        <SquareIconButton
          color="lunchgateSuccess"
          variant="contained"
          onClick={() => onCreateNewReservation()}
          sx={{
            flexShrink: 0,
            width: 64,
            height: 64,
            mr: [1, 0],
          }}
        >
          <FaPlus size={32} />
        </SquareIconButton>
      </Stack>
      {!isMobile && (
        <Stack
          direction="row"
          sx={{
            flex: 1,
            gridColumn: '2',
            borderBottom: 1,
            borderColor: 'divider',
            alignItems: 'end',
            backgroundColor: TOP_BAR_BG,
          }}
        >
          {isAreaPickerDisplayed(view) && (
            <AreaPicker
              areas={areas}
              currentDate={currentDate}
              holidays={holidays}
              area={area}
              onAreaChange={onAreaChange}
              occupancies={occupanciesByArea}
            />
          )}
          <Box sx={{ ml: 'auto' }}>
            <ViewPicker onViewChange={onViewChange} view={view} />
          </Box>
        </Stack>
      )}
    </>
  )
}

export default ViewBar
