import { Button, type ButtonProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import PencilIcon from 'src/shared/components/icons/PencilIcon'

const PacingButton = (props: ButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button
      variant="outlined"
      color="neutral"
      startIcon={<PencilIcon variant="filled" />}
      {...props}
    >
      {t(
        'schedule.shifts.guests_flow_section.custom_pacing_button_label',
        'Custom pacing',
      )}
    </Button>
  )
}

export default PacingButton
