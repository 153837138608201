import React from 'react'
import { juxt } from 'ramda'
import { Button, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { sumOccupanciesSeatCount } from 'src/entities/reservation/services/occupancy'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { type TableInterface } from 'src/entities/table/types/table'
import Modal from 'src/shared/components/common/Modal'
import StepInput from 'src/shared/components/form/inputs/StepInput'

interface ExactModeModalProps {
  open: boolean
  onClose: () => void
  onSeatCountSave: (seatCount: number) => void
  seatCount: number
  table: TableInterface
  reservation: ReservationInterface
}

const ExactModeModal = ({
  open,
  onClose,
  onSeatCountSave,
  seatCount: initSeatCount,
  table,
  reservation,
}: ExactModeModalProps) => {
  const { t } = useTranslation()

  const [seatCount, setSeatCount] = React.useState(initSeatCount)

  const remainingSeatCount = Math.max(
    0,
    reservation.seatCount -
      sumOccupanciesSeatCount(reservation) +
      initSeatCount,
  )

  return (
    <Modal
      title={t(
        'reservation_drawer.overlappingOccupancies.exact_mode_modal.title',
        {
          tDescription: 'Exact mode modal title',
          defaultValue: 'Table occupancy',
        },
      )}
      onClose={onClose}
      open={open}
      showCloseButton
      actions={
        <>
          <Button
            variant="outlined"
            color="neutral"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {t('modal.button.dismiss', { defaultValue: 'Dismiss' })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={juxt([onClose, () => onSeatCountSave(seatCount)])}
          >
            {t('modal.button.save', { defaultValue: 'Save' })}
          </Button>
        </>
      }
    >
      <Stack direction="column" spacing={3}>
        <Typography color="grey.700" variant="body2">
          {t(
            'reservation_drawer.overlappingOccupancies.exact_mode_modal.body',
            {
              tDescription: 'Exact mode modal body',
              defaultValue:
                'Please select the number of guests for table "{{ tableName }}"',
              tableName: table.name,
            },
          )}
        </Typography>
        <StepInput
          fullWidth
          inputSx={{ width: 128 }}
          onChange={setSeatCount}
          minValue={0}
          maxValue={remainingSeatCount}
          value={seatCount}
          label={t(
            'reservation_drawer.overlappingOccupancies.exact_mode_modal.seat_count_input_label',
            {
              tDescription:
                'Exact mode modal "seat count" input label (number of guests)',
              defaultValue: 'Number of guests',
            },
          )}
        />
      </Stack>
    </Modal>
  )
}

export default ExactModeModal
