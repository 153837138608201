import { Skeleton } from '@mui/material'

const Loader = () => (
  <>
    <Skeleton variant="text" />
    <Skeleton variant="text" />
    <Skeleton variant="text" />
  </>
)

export default Loader
