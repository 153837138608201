import { useCallback } from 'react'

import { atom, useAtom, useSetAtom } from 'jotai'

import { type TableInterface } from 'src/entities/table/types/table'

const selectedTableAtom = atom<TableInterface>()

export const useSelectedTableAtom = () => useAtom(selectedTableAtom)

export const useResetSelectedTable = () => {
  const set = useSetAtom(selectedTableAtom)

  return useCallback(() => set(undefined), [set])
}
