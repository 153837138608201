import { Suspense } from 'react'
import { Box, Stack, useMediaQuery } from '@mui/material'

import withLayoutBreakpoints from 'src/app/hoc/withLayoutBreakpoints'
import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { useSelectedServiceTime } from 'src/shared/lib/context/state/atoms/selectedServiceTime'
import { TOP_BAR_BG } from './CounterTabs'
import EditNoteButton from './ServiceTimeBar/components/ServiceTimeInfo/components/EditNoteButton'
import ServiceTimeInfo from './ServiceTimeBar/components/ServiceTimeInfo/ServiceTimeInfo'
import { ServiceTimeInfoDialog } from './ServiceTimeBar/ServiceTimeInfoDialog'
import ServiceTimePicker from './ServiceTimeBar/ServiceTimePicker'
import ShiftManagement from './ServiceTimeBar/ShiftManagement'

interface ContainerProps {
  reservations: Map<
    ServiceTimeInterface['id'] | null,
    ReservationInterface[]
  > | null
  onOpenEditModal: () => void
}

const XsContainer = ({ reservations, onOpenEditModal }: ContainerProps) => (
  <Stack direction="column" gap={1} pb={0.5} useFlexGap>
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'border',
        backgroundColor: TOP_BAR_BG,
      }}
    >
      <ServiceTimePicker reservations={reservations} selectedColor="white" />
    </Box>
    <Suspense>
      <ServiceTimeInfo onOpenEditModal={onOpenEditModal} />
    </Suspense>
    <Stack direction="row" justifyContent="space-between">
      <Suspense>
        <EditNoteButton onOpenEditModal={onOpenEditModal} />
      </Suspense>
      <Box sx={{ ml: 'auto' }}>
        <Suspense>
          <ShiftManagement />
        </Suspense>
      </Box>
    </Stack>
  </Stack>
)

const SmContainer = ({ reservations, onOpenEditModal }: ContainerProps) => (
  <Stack
    direction="row"
    sx={{
      justifyContent: 'space-between',
      alignItems: 'center',
      mr: 0.5,
    }}
  >
    <Box
      sx={{
        flexShrink: 1,
        minWidth: 0,
        maxWidth: 'max-content',
      }}
    >
      <ServiceTimePicker
        reservations={reservations}
        selectedColor={TOP_BAR_BG}
      />
    </Box>
    <Stack
      direction="row"
      sx={{
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexGrow: 1,
        height: 1,
      }}
    >
      <Suspense>
        <ServiceTimeInfo onOpenEditModal={onOpenEditModal} />
      </Suspense>
      <Suspense>
        <ShiftManagement />
      </Suspense>
    </Stack>
  </Stack>
)

const Container = withLayoutBreakpoints({ sm: SmContainer, xs: XsContainer })

interface ServiceTimeBarProps {
  reservations: Map<
    ServiceTimeInterface['id'] | null,
    ReservationInterface[]
  > | null
}

export const ServiceTimeBar = ({ reservations }: ServiceTimeBarProps) => {
  const isPrintPreview = useMediaQuery('print')

  const serviceTime = useSelectedServiceTime()
  const editServiceTimeInfoDialog = useDialog()

  if (isPrintPreview) return null

  return (
    <>
      <Container
        reservations={reservations}
        onOpenEditModal={editServiceTimeInfoDialog.handleOpen}
      />
      <Suspense>
        {serviceTime && (
          <ServiceTimeInfoDialog dialogProps={editServiceTimeInfoDialog} />
        )}
      </Suspense>
    </>
  )
}
