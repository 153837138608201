import React, { useCallback } from 'react'

import { useCurrentDateAtom } from 'src/shared/lib/context/state/atoms/currentDate'
import { useSelectedAreaAtom } from 'src/shared/lib/context/state/atoms/selectedArea'
import { useSetSelectedReservation } from 'src/shared/lib/context/state/atoms/selectedReservation'
import { useSelectedServiceTime } from 'src/shared/lib/context/state/atoms/selectedServiceTime'
import { getNowInRestaurantTimezone } from 'src/shared/lib/range/services/date'
import { getAreaRoom } from '../../area/services/area'
import { useFeedbackEnabledCallback } from '../../customer/queries/customer'
import { useFeatureCallback } from '../../info/hooks/useFeature'
import { useInfoQuery } from '../../info/queries/info'
import { useCurrentRestaurant } from '../../restaurant/queries/restaurants'
import { useRoomsQuery } from '../../room/queries/room'
import { useSettingsCallback } from '../../setting/queries/settings'
import { initializeReservation } from '../services/editReservation'

export const useNewReservation = () => {
  const { data: rooms } = useRoomsQuery()
  const { data: info } = useInfoQuery()

  const isSettingOn = useSettingsCallback()
  const isFeatureOn = useFeatureCallback()
  const hasFeedbackEnabled = useFeedbackEnabledCallback()

  const restaurant = useCurrentRestaurant()

  const [selectedArea] = useSelectedAreaAtom()
  const [currentDate] = useCurrentDateAtom()

  const selectedServiceTime = useSelectedServiceTime()
  const set = useSetSelectedReservation()

  const createNewReservation = React.useMemo(
    () =>
      initializeReservation({
        isFeatureOn,
        isSettingOn,
        hasFeedbackEnabled,
        getNow: getNowInRestaurantTimezone,
        getReminderSettings: () => info.reminderData,
        getAreaRoom: getAreaRoom(rooms),
        getSelectedArea: () => selectedArea,
        getSelectedServiceTime: () => selectedServiceTime ?? undefined,
        getRestaurantHash: () => restaurant.hash,
        getSelectedDate: () => currentDate,
        getDefaultDuration: () => info.defaultReservationDuration,
      }),
    [
      currentDate,
      hasFeedbackEnabled,
      info.reminderData,
      info.defaultReservationDuration,
      isFeatureOn,
      isSettingOn,
      restaurant.hash,
      rooms,
      selectedArea,
      selectedServiceTime,
    ],
  )

  return useCallback(
    async (...args: Parameters<typeof createNewReservation>) => {
      const newReservation = await createNewReservation(...args)
      set(newReservation)
    },
    [set, createNewReservation],
  )
}
