import { styled } from '@mui/material'

import ItemsContainer from '../ItemsContainer'

const HiddenItemsContainer = styled(ItemsContainer)({
  opacity: 0,
  pointerEvents: 'none',
})

export default HiddenItemsContainer
