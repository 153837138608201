import React, { useMemo } from 'react'

import { useSettingsQuery } from 'src/entities/setting/queries/settings'
import { useIdleEffect } from 'src/shared/lib/common/hooks/useIdleListener'
import { useGlobalModalContext } from 'src/shared/lib/context/global/useGlobalModalContext'
import { useRestaurantHash } from 'src/shared/lib/context/global/useRestaurantHash'
import { useSetActiveTeamMemberAtom } from 'src/shared/lib/context/state/atoms/activeTeamMember'
import useAvailableTeamMembers from './useAvailableTeamMembers'

export const useTeamMembersDialogEffect = () => {
  const restaurantHash = useRestaurantHash()
  const setActiveTeamMember = useSetActiveTeamMemberAtom()
  const { showTeamMembersModal } = useGlobalModalContext()

  const hasTeamMembers = !!useAvailableTeamMembers().length

  const { data: settings } = useSettingsQuery()
  const autoLogoutEnabled = useMemo(
    () => settings.find(({ name }) => name === 'autoLogout')!.value,
    [settings],
  )

  const openHandler = React.useCallback(() => {
    setActiveTeamMember(null)

    if (!hasTeamMembers) return

    showTeamMembersModal()
  }, [setActiveTeamMember, hasTeamMembers, showTeamMembersModal])

  React.useEffect(() => {
    openHandler()
  }, [openHandler, restaurantHash])

  const autoLogoutHandler = React.useCallback(() => {
    if (!autoLogoutEnabled) return

    openHandler()
  }, [openHandler, autoLogoutEnabled])

  useIdleEffect(180_000, autoLogoutHandler)
}
