import { andThen, composeWith, prop } from 'ramda'

import { z } from 'zod'

import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
  type ApiClient,
} from 'src/shared/lib/api/services/api'

const integrationNameSchema = z.enum(['lightspeed'])

const integrationSchema = z.object({
  integrationName: z.enum(['lightspeed']),
  status: z.enum(['connected', 'disconnected']),
})

export type IntegrationName = z.infer<typeof integrationNameSchema>
export type Integration = z.infer<typeof integrationSchema>

export const getIntegrations = (httpClient: ApiClient) => () =>
  composeWith(andThen)([
    (d: unknown) => integrationSchema.array().parse(d),
    prop('integrations'),
    convertKeysToCamelCase,
    httpClient,
  ])({
    method: 'GET',
    url: '',
  })

interface SetupIntegrationDto {
  integrationFlowId: string
  businessLocationId: string
}

export const setupIntegration =
  (httpClient: ApiClient) => (dto: SetupIntegrationDto) =>
    composeWith(andThen)([httpClient])({
      method: 'POST',
      url: '',
      json: convertKeysToSnakeCase(dto),
    })

export const disconnectIntegration =
  (httpClient: ApiClient) => (integrationName: IntegrationName) =>
    composeWith(andThen)([httpClient])({
      method: 'DELETE',
      url: integrationName,
    })
