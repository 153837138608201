import { Button, type ButtonProps } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'

const SelectionButton = ({
  label,
  onClick,
  selected,
  sx,
  ...props
}: {
  label: string
  onClick: ButtonProps['onClick']
  selected: boolean
} & Partial<ButtonProps>) => (
  <Button
    variant="text"
    color="neutral"
    size="large"
    onClick={onClick}
    sx={mergeSx(
      {
        borderRadius: 1,
        typography: 'labelSmall',
        color: 'currentColor',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'transparent',
        p: 1.5,
        ...(selected && {
          borderColor: 'grey.200',
        }),
      },
      sx,
    )}
    {...props}
  >
    {label}
  </Button>
)

export default SelectionButton
