import type { ComponentProps, FC } from 'react'
import { compose, juxt } from 'ramda'
import { Alert, Button, Stack, Typography } from '@mui/material'

import { type TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import type { PaymentActions } from 'src/entities/reservation/services/paymentStatus'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import Modal from 'src/shared/components/common/Modal'
import { useCurrencyFormatter } from 'src/shared/lib/string/hooks/useCurrencyFormatter'
import { ReservationActions } from '../reservationActions'

interface CancelConfirmationModalProps
  extends Omit<ComponentProps<typeof Modal>, 'title' | 'actions'> {
  reservation: ReservationInterface | undefined
  action: PaymentActions
  onConfirm: () => void
  onDismiss?: () => void
}

const getModalText = (t: TFunction) => (action: PaymentActions) =>
  ({
    [ReservationActions.Cancel]: {
      title: t('reservation.cancellation.dialog.title', 'Cancel reservation'),
      message: t(
        'reservation.cancellation.dialog.body',
        'Are you sure you want to cancel this reservation?',
      ),
      confirmButton: t(
        'reservation.cancellation.dialog.confirm_button',
        'Cancel reservation',
      ),
    },
    [ReservationActions.Noshow]: {
      title: t('reservation.no_show.dialog.title', 'Confirm no-show'),
      message: t(
        'reservation.no_show.dialog.body',
        'Are you sure you want to mark this reservation as no-show?',
      ),
      confirmButton: t(
        'reservation.no_show.dialog.confirm_button',
        'Confirm no-show',
      ),
    },
  })[action]

const CancelConfirmationModal: FC<CancelConfirmationModalProps> = ({
  reservation,
  action,
  onConfirm,
  open,
  onClose,
  children,
  onDismiss,
  ...props
}) => {
  const { t } = useTranslation()

  const currencyFormatter = useCurrencyFormatter()

  const modalTexts = getModalText(t)(action)

  return (
    <Modal
      open={open}
      onClose={juxt([() => onDismiss?.(), onClose])}
      showCloseButton
      title={modalTexts.title}
      actions={
        <>
          <Button
            variant="outlined"
            color="neutral"
            size="large"
            sx={{ minWidth: 'fit-content' }}
            fullWidth
            onClick={juxt([() => onDismiss?.(), onClose])}
          >
            {t('modal.button.dismiss')}
          </Button>

          <Button
            variant="contained"
            color="error"
            size="large"
            sx={{ minWidth: 'fit-content' }}
            fullWidth
            onClick={compose(onClose, onConfirm)}
          >
            {modalTexts.confirmButton}
          </Button>
        </>
      }
      {...props}
    >
      <Stack direction="column" spacing={3}>
        {reservation?.payment?.type === 'pre_payment' && (
          <Alert severity="warning">
            {t(
              'reservation.cancellation.dialog.pre_payment_disclaimer',
              "Reservation was pre-paid. The pre_payment ({{amount}}) won't be refunded automatically.",
              {
                amount: currencyFormatter(reservation.payment.currency)(
                  reservation.payment.amountPerPerson * reservation.seatCount,
                ),
              },
            )}
          </Alert>
        )}
        <Typography variant="body2" color="grey.700">
          {modalTexts.message}
        </Typography>
        {children}
      </Stack>
    </Modal>
  )
}

export { CancelConfirmationModal }
