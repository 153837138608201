import Toggle from 'src/shared/components/form/inputs/Toggle'
import { useEventShiftFormContext } from '../../../hooks/form'

interface IsActiveProps {
  label: string
}

const IsActive = ({ label }: IsActiveProps) => {
  const { register } = useEventShiftFormContext()

  return <Toggle label={label} {...register('enabled')} />
}

export default IsActive
