import { compose, map, prop } from 'ramda'

import {
  type DefaultError,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { type FurnitureInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'
import { useConfigQuery } from '../../config/queries/config'
import { type ApiConfig } from '../../config/types/configApi'
import { furnitureFromApi } from '../../table/types/tableApi'

const furnitureQueryOptions: Partial<
  UseSuspenseQueryOptions<ApiConfig, DefaultError, FurnitureInterface[]>
> = {
  select: compose(map(furnitureFromApi), prop('room_elements')),
}

export const useFurnitureQuery = () => useConfigQuery(furnitureQueryOptions)
