import { Box, darken } from '@mui/material'

import { SlotStatus } from 'src/entities/availability/types/availability'
import SelectionButton from 'src/shared/components/buttons/SelectionButton'
import { formatTime, getMinutes } from 'src/shared/lib/range/services/date'
import { BackgroundColors, TextColors } from '../../../colors'

export const getColumnNumber = (date: Date): number =>
  Math.floor(getMinutes(date) / 15) + 1

const TimeslotChoiceItem = ({
  time,
  onSelect,
  selected,
  status,
}: {
  time: Date
  onSelect: () => void
  selected: boolean
  status: SlotStatus
}) => {
  const itemColumn = getColumnNumber(time)

  const backgroundColor = BackgroundColors[status]

  return (
    <Box
      sx={{
        gridColumn: itemColumn,
        color: TextColors[status],
        p: 0.25,
      }}
    >
      <SelectionButton
        color={
          status === SlotStatus.Unavailable ? 'attention' : 'lunchgateSuccess'
        }
        label={formatTime(time)}
        onClick={onSelect}
        selected={selected}
        sx={{
          borderRadius: 0,
          borderColor: 'white',
          px: 1,
          py: 1.25,
          minWidth: 58,
          borderWidth: 2,
          backgroundColor,
          ...(selected && {
            borderColor: TextColors[status],
          }),
          '&:hover': {
            backgroundColor: backgroundColor
              ? darken(backgroundColor, 0.05)
              : undefined,
          },
        }}
      />
    </Box>
  )
}

export default TimeslotChoiceItem
