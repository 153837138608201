import React from 'react'

import {
  useMutation,
  useQueryClient,
  useSuspenseQueries,
  useSuspenseQuery,
  type DefaultError,
  type UseMutationOptions,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import {
  useRestaurantCacheKey,
  useRestaurantCacheKeyFactory,
} from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import { type ApiClient } from 'src/shared/lib/api/services/api'
import { isNotFalsy } from 'src/shared/lib/zod/zod'
import {
  addClosure,
  addEvent,
  addPaymentPlan,
  addPhoneAcceptanceTimesException,
  addReservationQuestion,
  addRoom,
  addShift,
  addShiftException,
  batchEditRooms,
  batchEditSchedule,
  deleteBookingLock,
  deleteClosure,
  deleteEvent,
  deletePaymentPlan,
  deletePhoneAcceptanceTimesException,
  deleteReservationQuestion,
  deleteRoom,
  deleteShift,
  deleteShiftException,
  editBookingLock,
  editClosure,
  editDefaultSettings,
  editEvent,
  editPaymentPlan,
  editPhoneAcceptanceTimes,
  editPhoneAcceptanceTimesException,
  editReservationQuestion,
  editRoom,
  editShift,
  editShiftException,
  getDailyPax,
  getNotificationsTemplates,
  getSchedule,
  upsertBookingLock,
  useScheduleApiClient,
} from '../api/scheduleApi'
import { type Schedule } from '../types/scheduleApi'

export const SCHEDULE_CACHE_KEY = ['schedule']
export const useScheduleCacheKey = () =>
  useRestaurantCacheKey(SCHEDULE_CACHE_KEY)

const PAX_CACHE_KEY = ['pax']
export const usePaxCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(PAX_CACHE_KEY)

interface MutationFactoryOptions<T, U>
  extends Omit<UseMutationOptions<U, Error, T, void>, 'mutationFn'> {
  restaurantHash?: string
}

const createMutationHook =
  <T, U>(mutationFn: (httpClient: ApiClient) => (...args: T[]) => Promise<U>) =>
  ({
    restaurantHash,
    onMutate,
    onSettled,
    ...rest
  }: MutationFactoryOptions<T, U> = {}) => {
    const apiClient = useScheduleApiClient(restaurantHash)
    const queryClient = useQueryClient()
    const scheduleCacheKey = useScheduleCacheKey()
    const getPaxCacheKey = usePaxCacheKeyFactory()

    return useMutation({
      mutationFn: mutationFn(apiClient),
      onMutate: (...args) => {
        void queryClient.cancelQueries({ queryKey: scheduleCacheKey })
        void queryClient.cancelQueries({ queryKey: getPaxCacheKey() })
        void onMutate?.(...args)
      },
      onSettled: (...args) => {
        void queryClient.invalidateQueries({ queryKey: scheduleCacheKey })
        void queryClient.invalidateQueries({ queryKey: getPaxCacheKey() })
        void onSettled?.(...args)
      },
      ...rest,
    })
  }

const useScheduleQueryOptions = (restaurantHash?: string) => {
  const apiClient = useScheduleApiClient(restaurantHash)
  const queryKey = useScheduleCacheKey()

  return React.useMemo(
    () =>
      ({
        queryKey,
        queryFn: getSchedule(apiClient),
        staleTime: 0,
        refetchInterval: false,
        refetchOnMount: false,
      }) satisfies UseSuspenseQueryOptions<Schedule>,
    [queryKey, apiClient],
  )
}

export const useScheduleQuery = <T = Schedule>(
  options?: Partial<UseSuspenseQueryOptions<Schedule, DefaultError, T>>,
  restaurantHash?: string,
) =>
  useSuspenseQuery({ ...options, ...useScheduleQueryOptions(restaurantHash) })

export const useBatchEditSchedule = createMutationHook(batchEditSchedule)

// DEFAULT SETTINGS
export const useDefaultSettingsQuery = (restaurantHash?: string) =>
  useSuspenseQuery({
    ...useScheduleQueryOptions(restaurantHash),
    select: data => data.defaultSettings,
  })

export const useEditDefaultSettings = createMutationHook(editDefaultSettings)

// SHIFT
export const useShiftsQuery = (restaurantHash?: string) =>
  useSuspenseQuery({
    ...useScheduleQueryOptions(restaurantHash),
    select: data => data.shifts,
  })

export const useAddShiftMutation = createMutationHook(addShift)

export const useEditShiftMutation = createMutationHook(editShift)

export const useDeleteShiftMutation = createMutationHook(deleteShift)

// SHIFT EXCEPTION
export const useShiftExceptionsQuery = (restaurantHash?: string) =>
  useSuspenseQuery({
    ...useScheduleQueryOptions(restaurantHash),
    select: data => data.shiftExceptions,
  })

export const useAddShiftExceptionMutation =
  createMutationHook(addShiftException)

export const useEditShiftExceptionMutation =
  createMutationHook(editShiftException)

export const useDeleteShiftExceptionMutation =
  createMutationHook(deleteShiftException)

// EVENT
export const useShiftEventsQuery = (restaurantHash?: string) =>
  useSuspenseQuery({
    ...useScheduleQueryOptions(restaurantHash),
    select: data => data.events,
  })

export const useAddEventMutation = createMutationHook(addEvent)

export const useEditEventMutation = createMutationHook(editEvent)

export const useDeleteEventMutation = createMutationHook(deleteEvent)

// PAYMENT PLAN
export const useAddPaymentPlanMutation = createMutationHook(addPaymentPlan)

export const useEditPaymentPlanMutation = createMutationHook(editPaymentPlan)

export const useDeletePaymentPlanMutation =
  createMutationHook(deletePaymentPlan)

// RESERVATION QUESTION
export const useAddReservationQuestionMutation = createMutationHook(
  addReservationQuestion,
)

export const useEditReservationQuestionMutation = createMutationHook(
  editReservationQuestion,
)

export const useDeleteReservationQuestionMutation = createMutationHook(
  deleteReservationQuestion,
)

// PHONE ACCEPTANCE TIME
export const useEditPhoneAcceptanceTimesMutation = createMutationHook(
  editPhoneAcceptanceTimes,
)

// PHONE ACCEPTANCE TIME EXCEPTION
export const useAddPhoneAcceptanceTimesExceptionMutation = createMutationHook(
  addPhoneAcceptanceTimesException,
)

export const useEditPhoneAcceptanceTimesExceptionMutation = createMutationHook(
  editPhoneAcceptanceTimesException,
)

export const useDeletePhoneAcceptanceTimesExceptionMutation =
  createMutationHook(deletePhoneAcceptanceTimesException)

// Closure
export const useAddClosureMutation = createMutationHook(addClosure)

export const useEditClosureMutation = createMutationHook(editClosure)

export const useDeleteClosureMutation = createMutationHook(deleteClosure)

// LOCK
export const useUpsertBookingLockMutation =
  createMutationHook(upsertBookingLock)

export const useEditBookingLockMutation = createMutationHook(editBookingLock)

export const useDeleteBookingLockMutation =
  createMutationHook(deleteBookingLock)

// ROOM
export const useRoomsQuery = (restaurantHash?: string) =>
  useSuspenseQuery({
    ...useScheduleQueryOptions(restaurantHash),
    select: data => data.rooms,
  })

export const useAddRoomMutation = createMutationHook(addRoom)

export const useEditRoomMutation = createMutationHook(editRoom)

export const useDeleteRoomMutation = createMutationHook(deleteRoom)

export const useBatchEditRoomMutation = createMutationHook(batchEditRooms)

// NOTIFICATIONS TEMPLATES
export const TEMPLATES_CACHE_KEY = ['notifications_templates']
const useTemplatesCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(TEMPLATES_CACHE_KEY)

export const useNotificationsTemplatesQuery = (restaurantHash?: string) => {
  const apiClient = useScheduleApiClient(restaurantHash)
  const cacheKey = useTemplatesCacheKeyFactory()()

  return useSuspenseQuery({
    queryKey: cacheKey,
    queryFn: getNotificationsTemplates(apiClient),
  })
}

// PAX
export const useDailyPaxQuery = (date: Date) => {
  const apiClient = useScheduleApiClient()
  const cacheKeyFactory = usePaxCacheKeyFactory()

  return useSuspenseQuery({
    queryKey: cacheKeyFactory([date]),
    queryFn: () => getDailyPax(apiClient)(date),
    staleTime: 0,
    refetchInterval: 30 * 60 * 1000,
  })
}

export const usePaxQueries = (dates: Date[]) => {
  const apiClient = useScheduleApiClient()
  const cacheKeyFactory = usePaxCacheKeyFactory()

  return useSuspenseQueries({
    queries: dates.map(
      date =>
        ({
          queryKey: cacheKeyFactory([date]),
          queryFn: () => getDailyPax(apiClient)(date),
          staleTime: Infinity,
          refetchInterval: false,
        }) as const,
    ),
    combine: res => res.map(r => r.data).filter(isNotFalsy),
  })
}
