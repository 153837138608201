import { useTranslation } from 'react-i18next'

import Deadline from './components/Deadline/Deadline'
import EarliestTime from './components/EarliestTime'
import FormSection from '../../../FormSection'
import { sectionKeyToLabel } from '../FormNavigation/FormNavigation'
import AfterDeadline from '../Ivr/AfterDeadline'
import { IvrContainer } from '../Ivr/IvrContainer'

const BookingSection = () => {
  const { t } = useTranslation()

  return (
    <FormSection title={sectionKeyToLabel(t)('booking')}>
      <Deadline />
      <IvrContainer>
        <AfterDeadline />
      </IvrContainer>
      <EarliestTime />
    </FormSection>
  )
}

export default BookingSection
