import { Tab, Tabs } from '@mui/material'

import { useTranslation } from 'react-i18next'

export const LISTS = {
  ALL: 'alle',
  UNDONE: 'undone',
}

interface TabChoiceProps {
  currentItem: string
  setItem: (newItem: string) => void
}

const TabChoice = ({ currentItem, setItem }: TabChoiceProps) => {
  const { t } = useTranslation('', { keyPrefix: 'inbox' })

  return (
    <Tabs
      value={currentItem}
      onChange={(_, value) => setItem(value as string)}
      variant="fullWidth"
    >
      <Tab label={t('notDone')} value={LISTS.UNDONE} />
      <Tab label={t('all')} value={LISTS.ALL} />
    </Tabs>
  )
}

export default TabChoice
