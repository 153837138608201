import type { TFunction } from 'i18next'

import { type FormChannelAllocation } from '../../../services/formSchema'
import { translateChannelName } from '../../LimitsSection/components/ChannelLimit'

export const isAllocationActive = (a: FormChannelAllocation) => a.active

export const hasActiveLimits = (allocations: FormChannelAllocation[]) =>
  !!allocations.filter(isAllocationActive).length

export const formatAllocationsLimits =
  (t: TFunction) => (allocations: FormChannelAllocation[]) =>
    allocations
      .filter(isAllocationActive)
      .map(a => [translateChannelName(t)(a.channel), a.limit].join(' - '))
      .join(', ')
