import { useCallback, useMemo } from 'react'

import {
  queryOptions,
  useQueryClient,
  useSuspenseQuery,
  type DefaultError,
  type QueryClient,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'
import { noop } from '@tanstack/react-table'

import { useApiClientWithHash } from 'src/shared/lib/api/hooks/useApiClientWithHash'
import { type ApiClient } from 'src/shared/lib/api/services/api'
import { getAuthenticatedApiClient } from 'src/shared/lib/api/services/reservationsBookApiClient'
import { getMe } from '../api/meApi'
import { type Me } from '../types/me'

const CACHE_KEY = ['me'] as const

const getMeQueryOptions = <T>(apiClient: ApiClient) =>
  queryOptions<Me, DefaultError, T>({
    queryKey: CACHE_KEY,
    queryFn: getMe(apiClient),
    staleTime: Infinity,
    refetchInterval: false,
  })

const useMeQueryOptions = <T>(preventLogout = false) => {
  const apiClient = useApiClientWithHash({ handleLogout: !preventLogout })

  return useMemo(() => getMeQueryOptions<T>(apiClient.get), [apiClient.get])
}

export const ensureMe = (queryClient: QueryClient) =>
  queryClient.ensureQueryData({
    ...getMeQueryOptions(getAuthenticatedApiClient(noop)),
    retry: false,
  })

export const useEnsureMe = (preventLogout = false) => {
  const queryClient = useQueryClient()
  const options = useMeQueryOptions(preventLogout)

  return useCallback(
    () => queryClient.ensureQueryData({ ...options, retry: false }),
    [queryClient, options],
  )
}

export const useMeQuery = <T = Me>(
  options: Partial<UseSuspenseQueryOptions<Me, DefaultError, T>> = {},
) =>
  useSuspenseQuery({
    ...useMeQueryOptions<T>(),
    ...options,
  })
