import { tap } from 'ramda'

import { noop } from '@tanstack/react-table'

import { type ApiClient } from 'src/shared/lib/api/services/api'
import {
  handleApiError,
  isAuthenticationError,
} from 'src/shared/lib/api/types/api'
import { refreshToken, saveToken } from './token'
import { login } from '../api/authApi'

export { AUTHENTICATION_ERROR_HTTP_CODE } from '../api/authApi'
export { refreshToken, getToken, TokenRefreshError } from './token'

export const authFailureHandler = (httpClient: ApiClient) => (error: Error) => {
  if (isAuthenticationError(error)) return refreshToken(httpClient)

  throw error
}

export const authenticate =
  (httpClient: ApiClient) => (username: string, password: string) =>
    login(httpClient)(username, password)
      .then(tap(r => saveToken(r.jwt)))
      .catch(handleApiError(noop))
