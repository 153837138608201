import { Divider } from '@mui/material'

import RangePickerContainer, {
  type RangePickerContainerProps,
} from '../../RangePickerContainer'

const Container = (props: RangePickerContainerProps) => (
  <RangePickerContainer
    {...props}
    direction="row"
    spacing={2}
    divider={<Divider orientation="vertical" flexItem />}
  />
)

export default Container
