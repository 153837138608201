import React from 'react'

import { useFeatureCallback } from 'src/entities/info/hooks/useFeature'
import ViewTabs from '../../../../Settings/Tables/ViewTabs'
import {
  PRESENTATION_VIEWS,
  type PresentationView,
} from '../../../service/presentationView'

interface ViewPickerProps {
  view: PresentationView
  onViewChange: (view: PresentationView) => void
}

const ViewPicker = ({ view, onViewChange }: ViewPickerProps) => {
  const isFeatureEnabled = useFeatureCallback()

  const viewsChoice = React.useMemo(
    () =>
      [
        isFeatureEnabled('viewTimeline') && PRESENTATION_VIEWS.timeline,
        isFeatureEnabled('viewList') && PRESENTATION_VIEWS.reservationsTable,
        isFeatureEnabled('viewTable') && PRESENTATION_VIEWS.tablesTable,
        isFeatureEnabled('viewVisuell') && PRESENTATION_VIEWS.floorplan,
      ].filter(Boolean) as PresentationView[],
    [isFeatureEnabled],
  )

  if (!viewsChoice.length) return null

  return (
    <ViewTabs views={viewsChoice} view={view} onViewChange={onViewChange} />
  )
}

export default ViewPicker
