import { createFileRoute, Navigate } from '@tanstack/react-router'

export const Route = createFileRoute('/restaurants/$restaurantId/config/')({
  component: RouteComponent,
})

function RouteComponent() {
  const { restaurantId } = Route.useParams()

  return (
    <Navigate
      to="/restaurants/$restaurantId/config/general"
      params={{ restaurantId }}
    />
  )
}
