import { Stack } from '@mui/material'

import {
  type LabelInterface,
  type PersistedLabelInterface,
} from 'src/entities/label/types/label'
import Item from './Item/Item'

interface LabelsGridProps {
  labels: PersistedLabelInterface[]
  onLabelClick: (label: LabelInterface) => void
}

const LabelsGrid = ({ labels, onLabelClick }: LabelsGridProps) => (
  <Stack
    direction="row"
    spacing={1}
    useFlexGap
    justifyContent="center"
    flexWrap="wrap"
  >
    {labels.map(label => (
      <Item label={label} onLabelClick={onLabelClick} key={label.id} />
    ))}
  </Stack>
)

export default LabelsGrid
