import React from 'react'
import { juxt } from 'ramda'
import { Button, RadioGroup, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Modal from 'src/shared/components/common/Modal'
import { RadioOption } from 'src/shared/components/form/inputs/RadioChoice'

interface ModifySerieModalProps {
  onAssign: (assignSerie: boolean) => void
  open: boolean
  onClose: () => void
  onDismiss?: () => void
}

const ModifySerieModal = ({
  onAssign,
  onClose,
  open,
  onDismiss,
}: ModifySerieModalProps) => {
  const { t } = useTranslation()

  const [wholeSerie, setWholeSerie] = React.useState(false)

  return (
    <Modal
      title={t('modify_serie_modal.title', {
        defaultValue: 'Edit recurring reservation',
      })}
      onClose={juxt([() => onDismiss?.(), onClose])}
      open={open}
      showCloseButton
      actions={
        <>
          <Button
            variant="outlined"
            color="neutral"
            size="large"
            onClick={juxt([() => onDismiss?.(), onClose])}
            fullWidth
          >
            {t('modal.button.dismiss', { defaultValue: 'Dismiss' })}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={juxt([onClose, () => onAssign(wholeSerie)])}
            fullWidth
          >
            {t('modal.button.save', { defaultValue: 'Save' })}
          </Button>
        </>
      }
    >
      <Stack direction="column" spacing={2}>
        {t('modify_serie_modal.body', {
          defaultValue:
            'This is a recurring reservation. Please choose which reservations you wish to edit.',
        })}
        <RadioGroup
          sx={{ gap: 1 }}
          value={wholeSerie ? 'whole_serie' : 'single_date'}
          onChange={(_event, value) => setWholeSerie(value === 'whole_serie')}
        >
          <RadioOption
            size="small"
            label={t('modify_serie_modal.choice.single_date', {
              defaultValue: 'Only this date',
            })}
            value="single_date"
          />
          <RadioOption
            size="small"
            label={t('modify_serie_modal.choice.whole_serie', {
              defaultValue: 'This date and all following',
            })}
            value="whole_serie"
          />
        </RadioGroup>
      </Stack>
    </Modal>
  )
}

export default ModifySerieModal
