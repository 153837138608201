import { Stack } from '@mui/material'

import { Controller, FormProvider, useForm } from 'react-hook-form'

import { type ApiAreaInterface } from 'src/entities/area/types/area'
import { type EmptyArea } from 'src/entities/area/types/areaApi'
import FormButtons from 'src/shared/components/buttons/FormButtons'
import AreaName from './AreaName'
import RoomChoice from './RoomChoice'

const EditForm = ({
  area,
  onSubmit,
  onDelete,
  onCancel,
}: {
  area: ApiAreaInterface | EmptyArea
  onSubmit: (area: ApiAreaInterface | EmptyArea) => void
  onDelete: (area: ApiAreaInterface) => void
  onCancel: () => void
}) => {
  const form = useForm<ApiAreaInterface | EmptyArea>({
    defaultValues: area,
  })

  const { handleSubmit } = form

  return (
    <FormProvider {...form}>
      <Stack direction="column" spacing={2}>
        <AreaName />
        <Controller
          name="roomId"
          render={({ field }) => <RoomChoice {...field} />}
        />
        <FormButtons
          onCancel={onCancel}
          onDelete={() => area.id && onDelete(area)}
          onSave={handleSubmit(onSubmit)}
          showDeleteButton={!!area.id}
        />
      </Stack>
    </FormProvider>
  )
}

export default EditForm
