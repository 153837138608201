import { andThen, compose, composeWith, map, prop } from 'ramda'

import { ReservationActions } from 'src/pages/ReservationDrawer/Footer/reservationActions'
import {
  convertKeysToSnakeCase,
  dateSerializer,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import { createBatchedFetch } from 'src/shared/lib/api/services/createBatcher'
import {
  addCursorPagination,
  addSortDirection,
  type SortDirection,
} from 'src/shared/lib/api/services/reservationsBookApiClient'
import { handleApiError, type Page } from 'src/shared/lib/api/types/api'
import { type SelectedReservation } from 'src/shared/lib/context/state/atoms/baseSelectedReservation'
import {
  formatTime,
  toApiDateString,
  toTimestamp,
} from 'src/shared/lib/range/services/date'
import { getDuration } from 'src/shared/lib/range/services/timeRange'
import { type ReservationApiFilters } from 'src/shared/lib/reducer/service/reservationsFilter'
import { createFilters } from './apiFilters'
import { getStartDate } from './reservation'
import { payloadFactory, reservationFactory } from './reservationFactory'
import { shouldBeSent } from '../../communication-template/services/communicationTemplates'
import type { CommunicationTemplate } from '../../communication-template/types/communicationTemplates'
import { type TableOccupancyInterface } from '../../table/types/table'
import {
  ReservationCheckInStatusEnum,
  type ReservationInterface,
} from '../types/reservation'
import {
  reservationCreatedResponseSchema,
  reservationPaginatedSearchResponseSchema,
  type ReservationLink,
} from '../types/reservationApi'

export const notificationProps =
  (smsField = 'sms', emailField = 'gemail') =>
  (data: {
    smsTemplate: CommunicationTemplate | undefined
    emailTemplate: CommunicationTemplate | undefined
  }) => ({
    [smsField]: 0,
    [emailField]: 0,
    ...(shouldBeSent(data.smsTemplate) && {
      [smsField]: 1,
      custom_sms: data.smsTemplate.id,
    }),
    ...(shouldBeSent(data.emailTemplate) && {
      [emailField]: 1,
      custom_email: data.emailTemplate.id,
    }),
  })

export const searchPaginatedReservations =
  (httpClient: ApiClient) =>
  (
    filters: Partial<ReservationApiFilters>,
    sortDirection: SortDirection,
    page: Page,
  ) =>
    composeWith(andThen)([
      (payload: unknown) =>
        reservationPaginatedSearchResponseSchema.parse(payload),
      compose(
        addSortDirection(sortDirection),
        addCursorPagination(page),
      )(httpClient),
    ])({
      method: 'POST',
      url: 'reservationsapi/search',
      json: convertKeysToSnakeCase(createFilters(filters)),
    })

export const searchReservations =
  // eslint-disable-next-line @typescript-eslint/require-await
  (httpClient: ApiClient) => async (filters: Partial<ReservationApiFilters>) =>
    composeWith(andThen)([prop('data')<{ data: number[] }>, httpClient])({
      method: 'POST',
      url: 'reservationsapi/search',
      json: convertKeysToSnakeCase(createFilters(filters)),
    })

export const fetchAllReservations =
  // eslint-disable-next-line @typescript-eslint/require-await
  (httpClient: ApiClient) => async (reservationIds: number[]) =>
    composeWith(andThen)([map(reservationFactory), prop('data'), httpClient])({
      url: 'reservationsapi/getAll',
      json: {
        reservation_ids: reservationIds,
      },
    })

const fetchBatchedReservations = createBatchedFetch(fetchAllReservations, 'id')

export const fetchReservation: typeof fetchBatchedReservations =
  apiClient => async id => {
    const reservation = await fetchBatchedReservations(apiClient)(id)

    if (!reservation) {
      // eslint-disable-next-line no-console
      console.log('Reservation not found')
    }

    return reservation ?? null
  }

export interface AddReservationsPayload {
  reservations: ReservationInterface[]
  template?: {
    reservation: ReservationInterface
    dates: Date[]
  }
}

export const addReservations =
  (httpClient: ApiClient) => async (payload: AddReservationsPayload) =>
    httpClient({
      url: 'reservationsapi/add',
      json: {
        reservations: (payload.reservations || []).map(payloadFactory),
        ...(payload.template && {
          template: {
            reservation: payloadFactory(payload.template.reservation),
            dates: payload.template.dates.map(dateSerializer),
          },
        }),
      },
    })

export const setReservationLink =
  (httpClient: ApiClient) =>
  async ({
    reservationId,
    link,
  }: {
    reservationId: ReservationInterface['id']
    link: ReservationLink
  }) => {
    await httpClient({
      url: 'setLink',
      json: {
        link_url: link.url,
        link_text: link.name,
        reservation_id: reservationId,
      },
    })
  }

interface Label {
  /** Origin label ID */
  from: number
  /** Destination label ID if exists */
  to?: number
}

export interface RestaurantChangeData {
  reservationId: number
  /**
   * *NOT* the restaurant hash but the ID
   */
  destinationRestaurantId: number
  destinationRestaurantHash: string
  labels: Label[]
  destinationRoomId?: number
  notifyRestaurant: boolean
  sendGuestEmail: boolean
  sendGuestSms: boolean
  customSmsTemplateId?: number
}

export const changeRestaurantOfReservation =
  (httpClient: ApiClient) => (data: RestaurantChangeData) =>
    httpClient({
      url: 'changeRestaurantOfReservation',
      json: {
        reservation_id: data.reservationId,
        labels: data.labels,
        to_restaurant: data.destinationRestaurantId,
        room_id: data.destinationRoomId,
        remail: +data.notifyRestaurant,
        gemail: +data.sendGuestEmail,
        sms: +data.sendGuestSms,
        custom_sms: data.customSmsTemplateId,
      },
    })

export const saveOccupancies =
  (httpClient: ApiClient) =>
  (
    reservation: ReservationInterface,
    occupancies: TableOccupancyInterface[],
    wholeGroup: boolean,
  ) =>
    httpClient({
      url: 'reservationsapi/place',
      json: {
        reservation_id: reservation.id,
        occupancies: occupancies.map(o => ({
          table_id: o.tableId,
          seat_count: o.seatCount,
        })),
        whole_group: wholeGroup,
      },
    })

type SupportedStatuses =
  | ReservationCheckInStatusEnum.CheckedIn
  | ReservationCheckInStatusEnum.CheckedOut
const checkInStatusToApiStatus = (status: SupportedStatuses) =>
  ({
    [ReservationCheckInStatusEnum.CheckedIn]: 1,
    [ReservationCheckInStatusEnum.CheckedOut]: 2,
  })[status]

interface SetCheckInStatusPayload {
  reservation: ReservationInterface
  status: SupportedStatuses
}

export const setReservationCheckInStatus =
  (httpClient: ApiClient) =>
  ({ reservation, status }: SetCheckInStatusPayload) =>
    httpClient({
      url: 'walkIn',
      json: {
        reservation_id: reservation.id,
        status: checkInStatusToApiStatus(status),
      },
    })

export const setExactMode =
  (httpClient: ApiClient) =>
  (reservation: ReservationInterface, exactMode: boolean) =>
    httpClient({
      url: 'exactMode',
      json: {
        reservation_id: reservation.id,
        exact_mode: Number(exactMode),
      },
    })

interface CancelReservation {
  reservation: ReservationInterface
  processPayments: boolean
  treatAsSerial: boolean | undefined
  smsTemplate: CommunicationTemplate | undefined
  emailTemplate: CommunicationTemplate | undefined
  action: ReservationActions.Cancel | ReservationActions.Noshow
  notifyRestaurant: boolean
}

export const cancelReservation =
  (httpClient: ApiClient) => async (data: CancelReservation) =>
    httpClient({
      url:
        data.action === ReservationActions.Cancel
          ? 'cancelReservation'
          : 'noShow',
      json: {
        reservation_id: data.reservation.id,
        process_payments: data.processPayments,
        remail: +data.notifyRestaurant,
        ...(data.treatAsSerial &&
          data.reservation.serialId && {
            serial_id: data.reservation.serialId,
          }),
        ...notificationProps()(data),
      },
    })

export type ReactivationAction =
  | ReservationActions.Reactivate
  | ReservationActions.Reshow

interface ReactivateReservation {
  reservation: ReservationInterface
  action: ReactivationAction
  treatAsSerial?: boolean
}

export const reactivateReservation =
  (httpClient: ApiClient) => async (data: ReactivateReservation) =>
    httpClient({
      url: data.action === ReservationActions.Reshow ? 'reshow' : 'reactivate',
      json: {
        reservation_id: data.reservation.id,
        ...(data.action === ReservationActions.Reactivate &&
          data.treatAsSerial &&
          data.reservation.serialId && {
            serial_id: data.reservation.serialId,
          }),
      },
    })

export interface CreateReservation {
  reservation: SelectedReservation
  smsTemplate: CommunicationTemplate | undefined
  emailTemplate: CommunicationTemplate | undefined
  notifyRestaurant: boolean
}

export const createReservation =
  (httpClient: ApiClient) =>
  async ({ reservation, ...data }: CreateReservation) => {
    const startDate = getStartDate(reservation)

    return httpClient({
      url: 'addReservation',
      json: {
        date: toApiDateString(startDate),
        time: formatTime(startDate),
        places: reservation.seatCount,
        company_name: reservation.guest.companyName,
        lastname: reservation.guest.lastName,
        firstname: reservation.guest.firstName,
        notice: reservation.restaurantNote,
        mobile: reservation.guest.phoneNumber,
        email: reservation.guest.email,
        customer_id: reservation.guest.customerId,
        timestamp: toTimestamp(new Date()) / 1000,
        duration: getDuration(reservation.dateRange, 'minute'),
        payment_plan_id: reservation.payment?.paymentPlanId,
        room_id: reservation.room,
        labels: reservation.labels.map(l => ({ id: l })),
        feedback: reservation.sendFeedback,
        reminder: reservation.sendReminder,
        remail: +data.notifyRestaurant,
        ...notificationProps()(data),
      },
    })
      .then(r => reservationCreatedResponseSchema.parse(r))
      .catch(handleApiError())
  }

export interface UpdateReservation {
  reservation: ReservationInterface
  smsTemplate: CommunicationTemplate | undefined
  emailTemplate: CommunicationTemplate | undefined
  notifyRestaurant: boolean
  treatAsSerie?: boolean
}

export const editReservation =
  (httpClient: ApiClient) =>
  async ({ reservation, ...data }: UpdateReservation) => {
    const startDate = getStartDate(reservation)

    return httpClient({
      url: 'mutateReservation',
      json: {
        reservation_id: reservation.id,
        date: toApiDateString(startDate),
        time: formatTime(startDate),
        places: reservation.seatCount,
        company_name: reservation.guest.companyName,
        lastname: reservation.guest.lastName,
        firstname: reservation.guest.firstName,
        comment: reservation.guest.comment,
        notice: reservation.restaurantNote,
        mobile: reservation.guest.phoneNumber,
        email: reservation.guest.email,
        customer_id: reservation.guest.customerId,
        duration: getDuration(reservation.dateRange, 'minute'),
        room_id: reservation.room,
        labels: reservation.labels.map(l => ({ id: l })),
        feedback: reservation.sendFeedback,
        reminder: reservation.sendReminder,
        remail: +data.notifyRestaurant,
        ...(data.treatAsSerie &&
          reservation.serialId && {
            serial_id: reservation.serialId,
          }),
        ...notificationProps()(data),
      },
    })
      .then(() => undefined)
      .catch(handleApiError())
  }

export interface SendMessage {
  reservation: ReservationInterface
  smsTemplate: CommunicationTemplate | undefined
  emailTemplate: CommunicationTemplate | undefined
}

export const sendMessage =
  (httpClient: ApiClient) => async (data: SendMessage) =>
    httpClient({
      url: 'sendMessage',
      json: {
        reservation_id: data.reservation.id,
        ...notificationProps()(data),
      },
    })

interface LockReservation {
  reservation: ReservationInterface
  locked: boolean
}

export const lockReservation =
  (httpClient: ApiClient) => async (data: LockReservation) =>
    httpClient({
      url: 'lockReservation',
      json: {
        reservation_id: data.reservation.id,
        lock: Number(data.locked),
      },
    })
