import type React from 'react'
import { forwardRef, type ForwardedRef } from 'react'
import {
  Button,
  ButtonGroup,
  type ButtonGroupProps,
  type ButtonProps,
} from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import IconButton from '../../../buttons/IconButton'
import MinusIcon from '../../../icons/MinusIcon'
import PlusIcon from '../../../icons/PlusIcon'
import { useStepPicker, type UseStepPicker } from '../StepInput/useStepPicker'

type StepPickerProps<T extends ButtonProps> = {
  prevIcon?: React.ReactNode | null
  nextIcon?: React.ReactNode | null
  rendererFactory: (displayedValue: number) => {
    buttonProps: T
    ButtonComponent?: React.FC<T>
  }
} & Omit<ButtonGroupProps, 'onClick' | 'onChange'> &
  UseStepPicker

const defaultPrevIcon = <MinusIcon />
const defaultNextIcon = <PlusIcon />

const StepPicker = <T extends ButtonProps>(
  {
    step = 1,
    prevIcon = defaultPrevIcon,
    nextIcon = defaultNextIcon,
    minValue = 0,
    maxValue = Number.MAX_SAFE_INTEGER,
    value = 0,
    onChange,
    rendererFactory,
    sx,
    ...props
  }: StepPickerProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const { inc, dec, displayedValue } = useStepPicker({
    step,
    minValue,
    maxValue,
    value,
    onChange,
  })

  const { ButtonComponent = Button, buttonProps } =
    rendererFactory(displayedValue)

  const button = (
    <ButtonComponent fullWidth variant="outlined" {...buttonProps}>
      {buttonProps.children ?? displayedValue}
    </ButtonComponent>
  )

  if (!prevIcon && !nextIcon) return button

  return (
    <ButtonGroup
      variant="outlined"
      sx={mergeSx({ flex: 1 }, sx)}
      ref={ref}
      {...props}
    >
      {prevIcon && (
        <IconButton onClick={dec} disabled={value <= minValue}>
          {prevIcon}
        </IconButton>
      )}
      {button}
      {nextIcon && (
        <IconButton onClick={inc} disabled={value >= maxValue}>
          {nextIcon}
        </IconButton>
      )}
    </ButtonGroup>
  )
}

export default forwardRef(StepPicker)
