import React from 'react'
import { type SlideDirection } from '@mui/x-date-pickers/DateCalendar/PickersSlideTransition'

import { type Dayjs } from 'dayjs'

import { modifyMonth } from './service/dateRangePicker'

export const useMonthChange = (
  initialMonth: Dayjs,
): {
  month: Dayjs
  nextMonth: Dayjs
  monthChangeHandler: (offset: number) => void
  monthIsAnimating: boolean
  onMonthAnimationEnd: () => void
  slideDirection: SlideDirection
} => {
  const [month, setMonth] = React.useState<Dayjs>(initialMonth)
  const [nextMonth, setNextMonth] = React.useState<Dayjs>(
    modifyMonth(1)(initialMonth),
  )

  const [monthIsAnimating, setMonthIsAnimating] = React.useState(false)

  const [slideDirection, setSlideDirection] =
    React.useState<SlideDirection>('left')

  const monthChangeHandler = React.useCallback((offset: number) => {
    const direction = offset > 0 ? 'left' : ('right' as SlideDirection)
    setSlideDirection(direction)
    setMonthIsAnimating(true)
    setMonth(modifyMonth(offset))
    setNextMonth(modifyMonth(offset))
  }, [])

  const onMonthAnimationEnd = React.useCallback(
    () => setMonthIsAnimating(false),
    [],
  )

  return {
    month,
    nextMonth,
    monthChangeHandler,
    monthIsAnimating,
    onMonthAnimationEnd,
    slideDirection,
  }
}
