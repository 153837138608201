import React from 'react'
import { Stack, Typography } from '@mui/material'

import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { CustomerBirthdayIndicator } from 'src/shared/components/common/BirthdayIndicator'
import Checkbox from 'src/shared/components/form/inputs/Checkbox'
import { useShortDateFormatter } from 'src/shared/lib/range/hooks/useFormattedDate'
import { areDatesEqual } from 'src/shared/lib/range/services/date'

const StartDateCell = ({
  reservation,
  startTime,
  selected,
  toggleSelection,
}: {
  reservation: ReservationInterface
  startTime: Date
  selected: boolean
  toggleSelection: (event: unknown) => void
}) => (
  <Stack
    direction="row"
    sx={{
      alignItems: 'center',
      justifyContent: 'space-between',
    }}
  >
    <Checkbox
      label={
        <Typography sx={{ mr: 0 }}>
          {useShortDateFormatter()(startTime)}
        </Typography>
      }
      onChange={toggleSelection}
      value={selected}
      checked={selected}
      size="small"
    />
    {!!reservation.guest.customerId && (
      <CustomerBirthdayIndicator date={startTime} reservation={reservation} />
    )}
  </Stack>
)

export default React.memo(
  StartDateCell,
  (prevProps, nextProps) =>
    areDatesEqual(prevProps.startTime, nextProps.startTime) &&
    prevProps.selected === nextProps.selected,
)
