import { Box, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  formatGuestCount,
  type RoomStayInterface,
} from 'src/entities/hotel-stay/services/hotelRoomStay'
import { useShortDateRangeFormatter } from 'src/shared/lib/range/hooks/useFormattedDate'

interface SectionProps {
  title: string
  children: React.ReactNode
}

const Section = ({ title, children }: SectionProps) => (
  <Stack direction="column">
    <Typography variant="labelSmall">{`${title}:`}</Typography>
    <Box sx={{ typography: 'body3' }}>{children}</Box>
  </Stack>
)

interface RoomStayProps {
  roomStay: RoomStayInterface | null
}

const RoomStay = ({ roomStay }: RoomStayProps) => {
  const { t } = useTranslation()
  const dateRangeFormatter = useShortDateRangeFormatter()

  if (!roomStay) return null

  return (
    <>
      <Section
        title={t('reservation_edit.hotel_stay.status', {
          tDescription: 'Hotel stay - title of status',
          defaultValue: 'Status',
        })}
      >
        {roomStay.hotelStay.status}
      </Section>
      <Section
        title={t('reservation_edit.hotel_stay.dates', {
          tDescription: 'Hotel stay - title of dates',
          defaultValue: 'Dates',
        })}
      >
        {dateRangeFormatter(roomStay.hotelStay.dateRange)}
      </Section>
      <Section
        title={t('reservation_edit.hotel_stay.room', {
          tDescription: 'Hotel stay - title of room',
          defaultValue: 'Room',
        })}
      >
        {roomStay.room.name}
      </Section>
      <Section
        title={t('customer.hotel_stay.body.guest_counts.title', {
          tDescription: 'Customer preview hotel stay guest counts title',
          defaultValue: 'Guests',
        })}
      >
        {roomStay.room.guestCounts.map(formatGuestCount(t)).join(', ')}
      </Section>
      <Section
        title={t('customer.hotel_stay.body.rate.title', {
          tDescription: 'Customer preview hotel stay rate title',
          defaultValue: 'Rate Category/Plan',
        })}
      >
        {roomStay.room.rateCategories.join(', ')}
      </Section>
      <Section
        title={t('customer.hotel_stay.body.comment.title', {
          tDescription: 'Customer preview hotel stay comment title',
          defaultValue: 'Hotel note',
        })}
      >
        {roomStay.hotelStay.comment}
      </Section>
    </>
  )
}

export { RoomStay }
