import { compose, map } from 'ramda'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import {
  type FloorPlanTableInterface,
  type FurnitureInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'
import { useConfigCacheKey } from '../../config/queries/config'
import { deleteTable, setTable } from '../../table/api/tableApi'
import { furnitureToApi, tableToApi } from '../../table/types/tableApi'
import { deleteFurniture, setFurniture } from '../api/furnitureApi'
import {
  getDeletedElements,
  getModifiedElements,
} from '../services/floorPlanElement'

const SET_FLOOR_PLAN_MUTATION_KEY = ['setFloorPlan']

interface MutationParams {
  tables: FloorPlanTableInterface[]
  originalTables: FloorPlanTableInterface[]
  furniture: FurnitureInterface[]
  originalFurniture: FurnitureInterface[]
}

export const useSetFloorPlanMutation = () => {
  const { post: apiClient } = useApiClient()
  const configCacheKey = useConfigCacheKey()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({
      tables,
      originalTables,
      furniture,
      originalFurniture,
    }: MutationParams) => {
      const deletedTables = getDeletedElements(originalTables, tables)
      const modifiedTables = getModifiedElements(tables, originalTables)

      const deletedFurniture = getDeletedElements(originalFurniture, furniture)
      const modifiedFurniture = getModifiedElements(
        furniture,
        originalFurniture,
      )

      await Promise.all([
        ...map(compose(deleteTable(apiClient), tableToApi), deletedTables),
        ...map(compose(setTable(apiClient), tableToApi), modifiedTables),
        ...map(
          compose(deleteFurniture(apiClient), furnitureToApi),
          deletedFurniture,
        ),
        ...map(
          compose(setFurniture(apiClient), furnitureToApi),
          modifiedFurniture,
        ),
      ])
    },
    mutationKey: SET_FLOOR_PLAN_MUTATION_KEY,
    onMutate: () => queryClient.cancelQueries({ queryKey: configCacheKey }),
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: configCacheKey }),
  })
}
