import { complement, prop } from 'ramda'

import dayjs, { fromApiString } from 'src/shared/lib/range/services/date'
import {
  getCountryCode,
  getNationalNumber,
} from 'src/shared/lib/string/services/phoneNumber'
import { type GuestInterface } from '../../reservation/types/reservation'
import {
  type AddressInterface,
  type CustomerInterface,
  type CustomerNoteInterface,
  type EmailInterface,
  type FallbackCustomer,
  type PhoneInterface,
} from '../types/customer'
import {
  type ApiAddress,
  type ApiCustomer,
  type ApiEmail,
  type ApiNote,
  type ApiPhone,
} from '../types/customerApi'
import { type PreferencesInterface } from '../types/preferences'

export const addressFactory = (address: ApiAddress): AddressInterface => ({
  id: address.id,
  street: address.addressLine1,
  city: address.city,
  zip: address.postalCode,
  countryCode: address.country,
  external: address.isExternal,
})

const emailFactory = (email: ApiEmail): EmailInterface => ({
  email: email.email,
  newsletter: email.newsletter,
  id: email.id,
  external: email.isExternal,
  feedback: email.feedback,
})

const phoneFactory = (phone: ApiPhone): PhoneInterface => ({
  phone: phone.phoneNumber,
  id: phone.id,
  external: phone.isExternal,
})

const noteFactory = (note: ApiNote): CustomerNoteInterface => ({
  text: note.note,
  type: note.type,
})

const preferenceFactory = (notes: ApiNote[]): PreferencesInterface => ({
  food: notes.find(note => note.type === 'food')?.note,
  place: notes.find(note => note.type === 'seating')?.note,
})

const commentFactory = (notes: ApiNote[]) =>
  notes.find(note => note.type === 'general')?.note

const isExternal = prop('isExternal')

export const getCustomerFactory = (
  customer: ApiCustomer,
): CustomerInterface => {
  const internalNotes = customer.notes.filter(complement(isExternal))

  return {
    id: customer.id,
    firstName: customer.personalData.name.firstName,
    lastName: customer.personalData.name.lastName,
    companyName: customer.personalData.companyName,
    emails: customer.emailAddresses.map(emailFactory),
    phoneNumbers: customer.phoneNumbers.map(phoneFactory),
    vip: customer.vip,
    ban: customer.blacklisted,
    addresses: customer.addresses.map(addressFactory),
    birthdate: fromApiString(customer.personalData.dateOfBirth),
    languageCode: customer.personalData.language,
    newsletter: customer.emailAddresses.some(email => !!email.newsletter),
    title: customer.personalData.title,
    academicTitle: customer.personalData.academicTitle,
    external: customer.isExternal,
    notes: internalNotes.map(noteFactory),
    externalNotes: customer.notes.filter(isExternal).map(noteFactory),
    preferences: preferenceFactory(internalNotes),
    comment: commentFactory(internalNotes),
    type: 'actual',
  }
}

export const customerPayloadFactory = (customer: CustomerInterface) => ({
  ...(customer.id && {
    id: customer.id,
    customer_id: customer.id,
  }),
  personal_data: {
    name: {
      last_name: customer.lastName,
      first_name: customer.firstName,
    },
    language: customer.languageCode,
    title: customer.title,
    academic_title: customer.academicTitle,
    company_name: customer.companyName,
    preferences: {
      place: customer.preferences.place,
      food: customer.preferences.food,
    },
    date_of_birth:
      customer.birthdate && dayjs(customer.birthdate).format('YYYY-MM-DD'),
  },
  email_addresses: customer.emails,
  phone_numbers: customer.phoneNumbers.map(phoneNumber => ({
    id: phoneNumber.id,
    phone_number: phoneNumber.phone.replace('+', '00'),
    country_code: getCountryCode(phoneNumber.phone),
    national_number: getNationalNumber(phoneNumber.phone),
    verified: false,
  })),
  customer_addresses: customer.addresses.map(address => ({
    id: address.id,
    address_line1: address.street,
    postal_code: address.zip,
    city: address.city,
    country: address.countryCode,
  })),
  date_of_birth_day: customer.birthdate && dayjs(customer.birthdate).date(),
  date_of_birth_month:
    customer.birthdate && dayjs(customer.birthdate).month() + 1,
  date_of_birth_year: customer.birthdate && dayjs(customer.birthdate).year(),
  vip: customer.vip,
  blacklisted: customer.ban,
  comment: customer.comment,
})

export const emptyCustomer: CustomerInterface = {
  comment: '',
  notes: [],
  externalNotes: [],
  lastName: '',
  firstName: '',
  external: false,
  ban: false,
  academicTitle: '',
  addresses: [],
  birthdate: null,
  companyName: '',
  emails: [],
  id: 0,
  languageCode: '',
  newsletter: false,
  phoneNumbers: [],
  preferences: {
    food: '',
    place: '',
  },
  vip: false,
  title: '',
  type: 'actual',
}

export const createPlaceholderCustomer = (
  id: CustomerInterface['id'],
): CustomerInterface => ({
  ...emptyCustomer,
  id,
  firstName: 'Unknown',
  lastName: 'Unbekannt',
  companyName: 'Sconosciuto',
  type: 'fallback',
})

export const customerFromGuest = (
  guest: GuestInterface,
): CustomerInterface | FallbackCustomer => ({
  ...emptyCustomer,
  id: guest.customerId,
  firstName: guest.firstName,
  lastName: guest.lastName,
  companyName: guest.companyName,
  emails: guest.email
    ? [
        {
          email: guest.email,
          external: true,
          id: 0,
          newsletter: false,
          feedback: false,
        },
      ]
    : [],
  phoneNumbers: guest.phoneNumber
    ? [
        {
          phone: guest.phoneNumber,
          external: true,
          id: 0,
        },
      ]
    : [],
  comment: guest.comment,
  languageCode: guest.languageCode,
  preferences: guest.preferences,
  birthdate: guest.birthdate,
  vip: guest.vip,
  ban: guest.ban,
  externalNotes: guest.externalNotes,
  notes: guest.notes,
  type: 'virtual',
})
