import React, { type ComponentProps } from 'react'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { compareRanges } from 'src/shared/lib/range/services/timeRange'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import TimeRangePicker from './TimeRangePicker'

const ArrivalTimeCell = ({
  timeRange,
  onChange,
  serviceTimes,
  size = 'small',
}: {
  timeRange: DefinedRangeInterface<Date>
  onChange: (timeRange: DefinedRangeInterface<Date>) => void
  serviceTimes: ServiceTimeInterface[]
  size?: ComponentProps<typeof TimeRangePicker>['size']
}) => (
  <TimeRangePicker
    serviceTimes={serviceTimes}
    onChange={onChange}
    value={timeRange}
    size={size}
    fullWidth
  />
)

export default React.memo(
  ArrivalTimeCell,
  (prevProps, nextProps) =>
    compareRanges(prevProps.timeRange, nextProps.timeRange) &&
    prevProps.serviceTimes === nextProps.serviceTimes,
)
