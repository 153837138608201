import React, { type ReactElement, type ReactNode } from 'react'
import { FormControl, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import withLayoutBreakpoints from 'src/app/hoc/withLayoutBreakpoints'
import { type SizeableMenuItemProps } from 'src/shared/components/common/SizeableMenuItem'
import Drawer from 'src/shared/components/dialogs/Drawer'
import {
  SelectContainer,
  type SelectContainerProps,
} from 'src/shared/components/form/inputs/SelectContainer'
import ArrowLeftIcon from 'src/shared/components/icons/ArrowLeftIcon'
import { ChevronRightIcon } from 'src/shared/components/icons/ChevronIcon'

interface SelectOrDrawerProps<T>
  extends Omit<
    SelectContainerProps<T>,
    'onClose' | 'onOpen' | 'open' | 'onSelect' | 'label' | 'onChange'
  > {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  renderValue: (value?: T) => ReactNode
  onSelect: (selected: T) => void
  children: ReactNode
  label?: string
  hideBackdrop?: boolean
  drawerIcon?: ReactNode
}

const SelectOrDrawerXs = <T,>({
  children,
  isOpen,
  label,
  onClose,
  onOpen,
  onSelect,
  renderValue,
  value,
  hideBackdrop = true,
  drawerIcon = <ChevronRightIcon size="medium" />,
}: SelectOrDrawerProps<T>) => {
  const { t } = useTranslation()
  const childrenArray = React.Children.toArray(
    children,
  ) as ReactElement<SizeableMenuItemProps>[]

  const items = childrenArray.map(child =>
    React.cloneElement(child, {
      onClick: () => {
        onSelect(child.props.value as T)
        onClose()
      },
    }),
  )

  return (
    <>
      <Stack
        direction="column"
        spacing={0.5}
        onClick={onOpen}
        sx={{ cursor: 'pointer' }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {label && (
            <Typography variant="labelRegular" sx={{ pointerEvents: 'none' }}>
              {label}
            </Typography>
          )}
          {drawerIcon}
        </Stack>
        {renderValue(value)}
      </Stack>
      <Drawer
        title={t('common.restaurants', {
          tDescription: 'Restaurants',
          defaultValue: 'Restaurants',
        })}
        onClose={onClose}
        onOpen={onOpen}
        open={isOpen}
        hideBackdrop={hideBackdrop}
        closeIcon={<ArrowLeftIcon />}
      >
        <FormControl fullWidth size="medium" focused={isOpen}>
          {items}
        </FormControl>
      </Drawer>
    </>
  )
}

const SelectOrDrawerSm = <T,>({
  drawerIcon,
  hideBackdrop,
  ref,
  isOpen,
  ...rest
}: SelectOrDrawerProps<T>) => <SelectContainer {...rest} />

export default withLayoutBreakpoints({
  xs: SelectOrDrawerXs,
  sm: SelectOrDrawerSm,
})
