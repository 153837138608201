import type React from 'react'
import { Stack } from '@mui/material'

import ErrorIcon from '../../../icons/ErrorIcon'

interface FormErrorTextProps {
  error?: boolean
  errorText?: React.ReactNode
  helperText?: React.ReactNode
}

const FormErrorText = ({
  error,
  errorText,
  helperText,
}: FormErrorTextProps) => {
  if (!error && !errorText) return helperText ?? null

  return (
    <Stack
      component="span"
      direction="row"
      spacing={0.5}
      color="error.main"
      typography="body3"
    >
      <ErrorIcon variant="filled" size="small" component="span" />
      {errorText ?? helperText}
    </Stack>
  )
}

export default FormErrorText
