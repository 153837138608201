import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'

import { useBetterApiClient } from 'src/shared/lib/api/hooks/useBetterApiClient'
import { useRestaurantCacheKeyFactory } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import {
  getIntegrationFlow,
  startIntegrationFlow,
  type IntegrationFlow,
} from '../api/integrationFlowsApi'
import { type IntegrationName } from '../api/integrationsApi'

const useIntegrationFlowCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(['integration-flows'])

const useIntegrationFlowsCacheKey = (queryKey: string[]) =>
  useIntegrationFlowCacheKeyFactory()(queryKey)

const useIntegrationsApiClient = () =>
  useBetterApiClient({ prefix: 'integration-flows' })

export const useIntegrationFlowQuery = (integrationName: IntegrationName) => {
  const apiClient = useIntegrationsApiClient()
  const queryKey = useIntegrationFlowsCacheKey([integrationName])
  const queryClient = useQueryClient()
  const integrationFlowId =
    queryClient.getQueryData<IntegrationFlow>(queryKey)?.id ?? null

  return useSuspenseQuery({
    queryFn: integrationFlowId
      ? getIntegrationFlow(apiClient)({
          id: integrationFlowId,
          integrationName,
        })
      : () => null,
    queryKey,
    staleTime: Infinity,
    refetchInterval: Infinity,
  })
}

export const useStartIntegrationFlowMutation = () => {
  const apiClient = useIntegrationsApiClient()
  const cacheKeyFactory = useIntegrationFlowCacheKeyFactory()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: startIntegrationFlow(apiClient),
    onSuccess: flow =>
      queryClient.setQueryData(cacheKeyFactory([flow.integrationName]), flow),
  })
}
