import { type SlotInterface } from 'src/entities/availability/types/availability'
import { getShiftOfSlot } from 'src/entities/service-time/services/slot'
import { type Shift } from 'src/entities/shift-instance/types/shift'

export const formatSlotGroupLabel = (
  shifts: Pick<Shift, 'name' | 'timeRange'>[],
  slots: SlotInterface[],
) => {
  const [firstSlot] = slots
  const slotShift = getShiftOfSlot(shifts)(firstSlot)

  if (!slotShift) return undefined

  const [firstAvailableSlot] = slots.filter(s => !!s.availability)

  const firstAvailability = firstAvailableSlot?.availability

  if (!firstAvailability) return slotShift.name

  return `${slotShift.name} (${firstAvailability.takenShiftCapacity} / ${firstAvailability.totalShiftCapacity})`
}
