import type React from 'react'
import { Button, Collapse, Stack, type SxProps } from '@mui/material'

const CollapsibleButton = ({
  sx,
  onClick,
  collapsed = false,
  label,
  startIcon,
}: {
  sx?: SxProps
  onClick: () => void
  collapsed?: boolean
  label: React.ReactNode
  startIcon?: React.ReactNode
}) => (
  <Button
    size="small"
    color="neutral"
    variant="text"
    onClick={onClick}
    sx={{
      p: 0.5,
      height: 24,
      minWidth: 24,
      ...sx,
    }}
  >
    <Stack direction="row" spacing={1} textTransform="none" alignItems="center">
      {startIcon}
      <Collapse
        in={!collapsed}
        collapsedSize={0}
        unmountOnExit
        mountOnEnter
        orientation="horizontal"
        sx={{
          typography: 'labelTiny',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </Collapse>
    </Stack>
  </Button>
)
export default CollapsibleButton
