import { useCallback } from 'react'
import { FormControl } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { FakeLabel } from 'src/shared/components/form/common/FakeLabel/FakeLabel'
import SingleSelectDropdown from 'src/shared/components/form/inputs/SingleSelectDropdown'
import { formatDuration } from 'src/shared/lib/range/services/date'
import { isoDuration, type IsoDuration } from 'src/shared/lib/zod/zod'
import { useBaseFormController } from '../../../hooks/form'

const possibleIntervals = ['PT15M', 'PT30M'].map(isoDuration)
const Interval = () => {
  const { t } = useTranslation()

  const { field } = useBaseFormController('bookingSetup.timeInterval')

  const render = useCallback(
    (interval: IsoDuration) =>
      t(
        'schedule.shifts.general_section.interval.item',
        '{{ duration }} phase',
        { duration: formatDuration(interval) },
      ),
    [t],
  )

  return (
    <FormControl>
      <FakeLabel sx={{ mb: 1.5 }}>
        {t('schedule.shifts.general_section.interval.title', 'Time interval')}
      </FakeLabel>
      <SingleSelectDropdown
        options={possibleIntervals}
        value={field.value}
        onChange={field.onChange}
        renderValue={render}
        renderOption={render}
        sx={{ maxWidth: 360 }}
      />
    </FormControl>
  )
}

export default Interval
