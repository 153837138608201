import { useNewReservation } from 'src/entities/reservation/hooks/useNewReservation'
import Reservations from 'src/pages/Reservations/Reservations'
import {
  useClearSelectedReservation,
  useReservationChanged,
  useSelectedReservationField,
  useSetSelectedReservationField,
} from 'src/shared/lib/context/state/atoms/selectedReservation'
import { fromTime } from 'src/shared/lib/range/services/date'

interface AppProps {
  restaurantId: number
}

const App = ({ restaurantId }: AppProps) => {
  const set = useSetSelectedReservationField()

  const reservationChanged = useReservationChanged()

  const assignSerie = useSelectedReservationField('assignSerie')
  const onAssignSerieChange = (b: boolean) => set('assignSerie', b)

  const onReservationDrawerClose = useClearSelectedReservation()

  const createNewReservation = useNewReservation()

  return (
    <Reservations
      restaurantId={restaurantId}
      onCreateNewReservation={slot =>
        createNewReservation({ time: slot ? fromTime(slot) : undefined })
      }
      assignSerie={assignSerie}
      onAssignSerieChange={onAssignSerieChange}
      onReservationDrawerClose={onReservationDrawerClose}
      reservationChanged={reservationChanged}
    />
  )
}

export default App
