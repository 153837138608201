import { Stack } from '@mui/material'

import {
  type CustomerInterface,
  type FallbackCustomer,
} from 'src/entities/customer/types/customer'
import Birthday from './Birthday'
import Blocked from './Blocked'
import Language from './Language'
import Vip from './Vip'

const Pillboxes = ({
  customer,
}: {
  customer: CustomerInterface | FallbackCustomer
}) => (
  <Stack direction="row" gap={0.5} flexWrap="wrap">
    <Blocked customer={customer} />
    <Vip customer={customer} />
    <Language customer={customer} />
    <Birthday customer={customer} />
  </Stack>
)

export default Pillboxes
