import { Box, Stack } from '@mui/material'

interface ItemSectionProps {
  title: string
  children: React.ReactNode
}

const ItemSection = ({ title, children }: ItemSectionProps) => (
  <Stack direction="row" spacing={1}>
    <Box sx={{ color: '#24282C', flexShrink: 0 }}>{`${title}: `}</Box>
    <Box sx={{ color: 'rgba(0, 0, 0, 0.64)', flexGrow: 1 }}>{children}</Box>
  </Stack>
)

export default ItemSection
