import { forwardRef, type ForwardedRef } from 'react'
import { TableContainer, type TableContainerProps } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'

const VirtualTableContainer = (
  { sx, ...props }: TableContainerProps,
  ref: ForwardedRef<HTMLDivElement>,
) => (
  <TableContainer
    {...createTestIdProps('table-container')}
    sx={{
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      ...sx,
    }}
    ref={ref}
    {...props}
  />
)

export default forwardRef(VirtualTableContainer)
