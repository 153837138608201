import React from 'react'
import { any, equals, none } from 'ramda'

import { useOccupanciesMutation } from 'src/entities/reservation/queries/reservationsMutations'
import {
  addOccupancy,
  isAssignmentComplete,
  removeOccupancy,
  updateOccupancy,
} from 'src/entities/reservation/services/occupancy'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { occupies } from 'src/entities/table/services/table'
import type {
  TableInterface,
  TableOccupancyInterface,
} from 'src/entities/table/types/table'
import {
  isAbandoned,
  resumable,
  useResumable,
  type Step,
} from 'src/shared/lib/common/services/resumable/resumable'
import { usePristineOpenReservationOccupancies } from 'src/shared/lib/context/state/atoms/selectedReservation'

interface EditOptions {
  wholeSerie?: boolean | undefined
}

interface AddOptions extends EditOptions {
  overbook?: boolean | undefined
}

interface EditDeps {
  reservation: ReservationInterface
}

interface AddDeps extends EditDeps {
  table: TableInterface
}

interface UseEditOccupancy {
  onAssignSerie: () => void
  assignSerie: Required<EditOptions['wholeSerie']>
}

interface UseAddOccupancy extends UseEditOccupancy {
  getIntersectingOccupancies: (
    r: ReservationInterface,
  ) => TableOccupancyInterface[]
  tables: TableInterface[]
  isTableLocked: (table: TableInterface) => boolean
  onTableLocked: () => void
  onTableOccupied: () => void
  onReservationNotCreated: () => void
  reservationChanged: boolean
  onReservationDrawerClose: (force?: boolean, digest?: boolean) => void
  onReservationLocked: () => void
  onTablePickerClose: () => void
}

export const canBeSerial = <T extends EditOptions, U extends EditDeps>(
  opts: T,
  deps: U,
) => deps.reservation.serial && opts.wholeSerie === undefined

export const useAddOccupancy = ({
  getIntersectingOccupancies,
  tables,
  isTableLocked,
  onTableLocked,
  onReservationLocked,
  onTableOccupied,
  onAssignSerie,
  assignSerie,
  reservationChanged,
  onReservationDrawerClose,
  onTablePickerClose,
}: UseAddOccupancy) => {
  const { mutateAsync: setOccupancies } = useOccupanciesMutation()

  const isTableOccupied = React.useCallback(
    (options: AddOptions, { table, reservation }: AddDeps) => {
      const occupancies = getIntersectingOccupancies(reservation)

      return (
        options.overbook !== true &&
        any(occupies(table), occupancies) &&
        none(occupies(table), reservation.occupancies ?? [])
      )
    },
    [getIntersectingOccupancies],
  )

  const steps: Step<AddOptions, AddDeps>[] = React.useMemo(
    () => [
      [(_o, deps) => deps.reservation.locked, onReservationLocked],
      [(_o, deps) => isTableLocked(deps.table), onTableLocked],
      [isTableOccupied, onTableOccupied],
      [canBeSerial, onAssignSerie],
    ],
    [
      isTableLocked,
      isTableOccupied,
      onAssignSerie,
      onReservationLocked,
      onTableLocked,
      onTableOccupied,
    ],
  )

  const { init, resume, abandon } = useResumable(
    React.useMemo(
      () => resumable<AddOptions, AddDeps>(steps, { wholeSerie: assignSerie }),
      [steps, assignSerie],
    ),
  )

  const originalOccupancies = usePristineOpenReservationOccupancies()

  const initHandler = React.useCallback(
    async (reservation: ReservationInterface, table: TableInterface) => {
      const options = await init({ reservation, table })

      if (isAbandoned(options)) return

      const forceRestart =
        equals(originalOccupancies, reservation.occupancies) && !!reservation.id
      const changedReservation = addOccupancy(
        tables,
        table,
        forceRestart,
      )(reservation)

      const assignmentComplete = isAssignmentComplete(
        changedReservation,
        tables,
      )

      const autoClose = assignmentComplete && !reservationChanged

      void setOccupancies({
        reservation,
        occupancies: changedReservation.occupancies,
        wholeSerie: options.wholeSerie,
        immediate: autoClose,
      })

      if (!autoClose) return

      onReservationDrawerClose(true)
      onTablePickerClose()
    },
    [
      originalOccupancies,
      onTablePickerClose,
      init,
      tables,
      setOccupancies,
      reservationChanged,
      onReservationDrawerClose,
    ],
  )

  return { init: initHandler, resume, abandon }
}

const useEditResumable = ({ onAssignSerie, assignSerie }: UseEditOccupancy) =>
  useResumable(
    React.useMemo(
      () =>
        resumable(
          [[canBeSerial, onAssignSerie]] satisfies Step<
            EditOptions,
            EditDeps
          >[],
          { wholeSerie: assignSerie },
        ),
      [assignSerie, onAssignSerie],
    ),
  )

export const useRemoveOccupancy = ({
  onAssignSerie,
  assignSerie,
}: UseEditOccupancy) => {
  const { mutateAsync: setOccupancies } = useOccupanciesMutation()

  const { init, resume, abandon } = useEditResumable({
    onAssignSerie,
    assignSerie,
  })

  const initHandler = React.useCallback(
    async (
      reservation: ReservationInterface,
      occupancy: TableOccupancyInterface,
    ) => {
      const options = await init({ reservation })

      if (isAbandoned(options)) return

      const changedReservation = removeOccupancy(occupancy)(reservation)

      await setOccupancies({
        reservation,
        occupancies: changedReservation.occupancies,
        wholeSerie: options.wholeSerie,
      })
    },
    [init, setOccupancies],
  )

  return { init: initHandler, resume, abandon }
}

export const useUpdateOccupancy = ({
  onAssignSerie,
  assignSerie,
}: UseEditOccupancy) => {
  const { mutateAsync: setOccupancies } = useOccupanciesMutation()

  const { init, resume, abandon } = useEditResumable({
    onAssignSerie,
    assignSerie,
  })

  const initHandler = React.useCallback(
    async (
      reservation: ReservationInterface,
      table: TableInterface,
      seatCount: number,
    ) => {
      const options = await init({ reservation })

      if (isAbandoned(options)) return

      const changedReservation = updateOccupancy(table, seatCount)(reservation)

      await setOccupancies({
        reservation,
        occupancies: changedReservation.occupancies,
        wholeSerie: options.wholeSerie,
      })
    },
    [init, setOccupancies],
  )

  return { init: initHandler, resume, abandon }
}
