import { Typography, type SelectProps } from '@mui/material'

export const Placeholder = ({
  size,
  placeholder,
}: {
  size: SelectProps['size']
  placeholder: string
}) => (
  <Typography
    variant="body2"
    color="grey.400"
    sx={{
      ...(size === 'medium' && {
        pl: 1,
      }),
      ...(size === 'large' && {
        pl: 0.5,
      }),
    }}
  >
    {placeholder}
  </Typography>
)
