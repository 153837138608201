import { prop } from 'ramda'

import {
  type DefaultError,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { useConfigQuery } from '../../config/queries/config'
import {
  type ApiConfig,
  type PaymentProvider,
} from '../../config/types/configApi'

const paymentProviderQueryOptions: Partial<
  UseSuspenseQueryOptions<ApiConfig, DefaultError, PaymentProvider>
> = {
  select: prop('payment_gateway'),
}

export const usePaymentProviderQuery = () =>
  useConfigQuery(paymentProviderQueryOptions)
