import React from 'react'

import { DndContext, type DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'

import { useTablesOrderUpdate } from 'src/entities/table/queries/table'
import { order } from 'src/entities/table/types/tableApi'
import { useBasicDndSensors } from 'src/shared/lib/common/hooks/useDnd'
import { type FloorPlanTableInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'
import TablesGrid from 'src/widgets/TabularTables/TablesGrid'
import Item from './Item'

const TabularView = ({
  tables,
  onSelect,
  selectedTable,
}: {
  tables: FloorPlanTableInterface[]
  onSelect: (table?: FloorPlanTableInterface) => void
  selectedTable: FloorPlanTableInterface | undefined
}): React.ReactNode => {
  const [sortedTables, setSortedTables] = React.useState(tables)

  const hasNewTable = React.useMemo(
    () => sortedTables.some(t => !t.id),
    [sortedTables],
  )

  React.useEffect(() => {
    setSortedTables(tables)
  }, [tables])

  const { mutate: setTablesOrder } = useTablesOrderUpdate()

  const handleDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event

      if (!over || active.id === over.id) return

      setSortedTables(oldTables => {
        const oldIndex = oldTables.findIndex(
          table => table.id === active.id || table.uuid === active.id,
        )
        const newIndex = oldTables.findIndex(
          table => table.id === over.id || table.uuid === over.id,
        )

        const newTables = arrayMove(oldTables, oldIndex, newIndex)

        setTablesOrder(order(newTables))

        return newTables
      })
    },
    [setTablesOrder],
  )

  const sensors = useBasicDndSensors()

  const sortedIds = React.useMemo(
    () => sortedTables.map(t => t.id ?? t.uuid!),
    [sortedTables],
  )

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext items={sortedIds} disabled={hasNewTable}>
        <TablesGrid>
          {sortedTables.map(table => (
            <Item
              key={table.uuid ?? table.id}
              table={table}
              onSelect={() => onSelect(table)}
              isSelected={selectedTable === table}
              dragDisabled={hasNewTable}
            />
          ))}
        </TablesGrid>
      </SortableContext>
    </DndContext>
  )
}

export default TabularView
