import { Stack } from '@mui/material'
import { deepPurple, lightBlue, red } from '@mui/material/colors'

import {
  ReservationNotesEnum,
  type ReservationInterface,
} from 'src/entities/reservation/types/reservation'
import Label from './Label'

export const NOTES_COLOURS: { [key in ReservationNotesEnum]: string } = {
  [ReservationNotesEnum.GuestMessage]: red['500'],
  [ReservationNotesEnum.RestaurantNote]: lightBlue['500'],
  [ReservationNotesEnum.Choices]: deepPurple['500'],
}

const Labels = ({ reservation }: { reservation: ReservationInterface }) => (
  <Stack direction="column" spacing={0.1}>
    {!!reservation.guestMessage && (
      <Label color={NOTES_COLOURS[ReservationNotesEnum.GuestMessage]} />
    )}
    {!!reservation.restaurantNote && (
      <Label color={NOTES_COLOURS[ReservationNotesEnum.RestaurantNote]} />
    )}
    {!!reservation.choices?.length && (
      <Label color={NOTES_COLOURS[ReservationNotesEnum.Choices]} />
    )}
  </Stack>
)

export default Labels
