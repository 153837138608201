import { any, curry, map } from 'ramda'

import {
  areOnSameDay,
  getBoundaries,
  getNowInRestaurantTimezone,
} from 'src/shared/lib/range/services/date'
import * as timeRangeLib from 'src/shared/lib/range/services/timeRange'
import { fromDate } from 'src/shared/lib/range/services/timeRangeFactory'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import { type TimeRangeAware } from 'src/shared/lib/range/types/timeRange'
import { isNotFalsy } from 'src/shared/lib/zod/zod'

export const getServiceTimeRange =
  (date = getNowInRestaurantTimezone()) =>
  <T extends TimeRangeAware | undefined>(
    serviceTime: T,
  ): T extends TimeRangeAware ? DefinedRangeInterface<Date> : undefined => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
    if (!serviceTime?.timeRange) return undefined satisfies undefined as any

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return timeRangeLib.moveToDate(
      serviceTime.timeRange,
      date,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) satisfies DefinedRangeInterface<Date> as any
  }

export const includeTimeRange =
  (
    intersectionDetector: (
      timeRangeA: DefinedRangeInterface<Date>,
      timeRangeB: DefinedRangeInterface<Date>,
    ) => boolean,
  ) =>
  (serviceTimes: TimeRangeAware[], date = getNowInRestaurantTimezone()) =>
  (timeRange: DefinedRangeInterface<Date>): boolean => {
    const timeRanges = map(getServiceTimeRange(date), serviceTimes).filter(
      isNotFalsy,
    )

    return any(curry(intersectionDetector)(timeRange), timeRanges)
  }

export const overlapTimeRange = includeTimeRange(timeRangeLib.areOverlapping)
export const intersectTimeRange = includeTimeRange(timeRangeLib.areIntersecting)

export const includeTime =
  (
    intersectionDetector: (
      serviceTimes: TimeRangeAware[],
      time: Date,
    ) => (timeRange: DefinedRangeInterface<Date>) => boolean,
  ) =>
  (serviceTimes: TimeRangeAware[], time: Date): boolean =>
    intersectionDetector(serviceTimes, time)(fromDate(time))

export const overlapsTime = includeTime(overlapTimeRange)

export const isTimeIncludedBy = (time: Date) => (serviceTime: TimeRangeAware) =>
  overlapsTime([serviceTime], time)

export const getServiceTimeOfTime =
  <T extends TimeRangeAware>(serviceTimes: T[] = []) =>
  (time: Date | undefined) => {
    if (!time) return undefined

    const serviceTime = serviceTimes.find(isTimeIncludedBy(time))

    if (!serviceTime) return undefined

    if (!areOnSameDay(serviceTime.timeRange[0], time)) return undefined

    return serviceTime
  }

export const getServiceTimeSlots =
  (getSelectedServiceTime: () => TimeRangeAware | undefined) =>
  (date: Date) => {
    const timeRange = getSelectedServiceTime()?.timeRange
    const dayRange = getBoundaries(date)

    return timeRangeLib.interpolate(timeRange ?? dayRange, 'minute', 15)
  }
