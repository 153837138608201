import { type InfiniteData } from '@tanstack/react-query'

export const replacePaginatedData =
  <T>(replacer: (item: InfiniteData<T[]>) => T[][]) =>
  (collection: InfiniteData<T[]>): InfiniteData<T[]> => ({
    ...collection,
    pages: replacer(collection),
  })

export const reMapPaginatedData = <T>(mapper: (item: T) => T) =>
  replacePaginatedData<T>(collection =>
    collection.pages.map(p => p.map(mapper)),
  )

export const filterPaginatedData = <T>(predicate: (item: T) => boolean) =>
  replacePaginatedData<T>(collection =>
    collection.pages.map(p => p.filter(predicate)),
  )
