import { andThen, composeWith, map, prop } from 'ramda'

import { type ApiClient } from 'src/shared/lib/api/services/api'
import { fromApi } from '../services/notificationFactory'

export type NotificationStatusFilter = 'open' | 'done' | 'all'

export const getNotificationHistory =
  (httpClient: ApiClient) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async (status: NotificationStatusFilter | undefined) =>
    composeWith(andThen)([map(fromApi), prop('data'), httpClient])({
      method: 'POST',
      url: 'inboxnotifications/history',
      json: {
        descending: true,
        status: status !== 'all' ? status : undefined,
      },
    })
