import React from 'react'
import { Stack, type StackProps } from '@mui/material'

const ItemsContainer = (
  props: Omit<StackProps, 'ref'>,
  ref: React.ForwardedRef<HTMLDivElement>,
) => (
  <Stack
    direction="row"
    spacing={0.5}
    ref={ref}
    sx={{
      position: 'absolute',
    }}
    {...props}
  />
)

export default React.forwardRef(ItemsContainer)
