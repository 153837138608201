import React from 'react'
import {
  LoadingButton as MuiLoadingButton,
  type LoadingButtonProps,
} from '@mui/lab'

import debounce from 'debounce'

import { usePromiseLoading } from '../../../lib/api/hooks/usePromiseLoading'

const CLICK_DELAY = 150

interface LButtonProps extends Omit<LoadingButtonProps, 'onClick'> {
  onClick: () => Promise<unknown> | void
}

export const LoadingButton = ({ onClick, ...props }: LButtonProps) => {
  const { loading, handler: onClickHandler } = usePromiseLoading(
    onClick,
    CLICK_DELAY,
  )

  const debounceCallback = React.useMemo(
    () =>
      debounce((callback: typeof onClick) => callback(), CLICK_DELAY, {
        immediate: true,
      }),
    [],
  )

  return (
    <MuiLoadingButton
      {...props}
      onClick={() => debounceCallback(onClickHandler)}
      loading={loading || props.loading}
    />
  )
}
