import { Typography, type StackProps } from '@mui/material'

import ReservationDrawerSection from 'src/shared/components/common/ReservationDrawerSection'

interface StaticNoteProps extends StackProps {
  title: string
  accent: string
  note: string
}

export const StaticNote = ({ note, accent, ...props }: StaticNoteProps) => (
  <ReservationDrawerSection {...props}>
    <Typography
      sx={{
        px: 1.5,
        py: 0.75,
        backgroundColor: 'white',
        borderTop: `4px solid ${accent}`,
      }}
    >
      {note}
    </Typography>
  </ReservationDrawerSection>
)
