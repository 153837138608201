import { atom } from 'jotai'

import { type CustomerInterface } from 'src/entities/customer/types/customer'
import {
  type ReservationInterface,
  type ReservationPayment,
} from 'src/entities/reservation/types/reservation'
import { type PaymentPlan } from 'src/entities/schedule/types/scheduleApi'

interface PaymentWithPlan extends ReservationPayment {
  plan?: PaymentPlan
}

// TODO: Extract to separate atom / move to occupancies atom
export interface SelectedReservation extends ReservationInterface {
  invalidState?: boolean
  assignSerie?: boolean
  newCustomer?: Partial<CustomerInterface>
  payment: PaymentWithPlan | null
}

export const NO_RESERVATION = {} as SelectedReservation

export const baseSelectedReservationAtom = atom(NO_RESERVATION)

export type SelectedReservationUpdate =
  | SelectedReservation
  | ((prevValue: SelectedReservation) => SelectedReservation)

export type SelectedReservationFieldUpdate<
  T extends keyof SelectedReservation,
> =
  | SelectedReservation[T]
  | ((prevValue: SelectedReservation[T]) => SelectedReservation[T])

export const updateSelectedReservationField =
  <T extends keyof SelectedReservation>(
    key: T,
    update: SelectedReservationFieldUpdate<T>,
  ) =>
  (sr: SelectedReservation) =>
    ({
      ...sr,
      [key]: typeof update === 'function' ? update(sr[key]) : update,
    }) satisfies SelectedReservation
