import { Stack, type StackProps } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import CloseButton from 'src/shared/components/buttons/CloseButton'
import { drawerColors, type DrawerColor } from './drawerColor'

interface HeaderProps extends StackProps {
  onClose: () => void
  color?: DrawerColor
}

const SidebarHeader = ({
  onClose,
  sx,
  children,
  color,
  ...props
}: HeaderProps) => (
  <Stack
    direction="row"
    spacing={1}
    sx={mergeSx(sx, {
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: 63,
      px: 1,
      overflow: 'hidden',
      ...(color && {
        bgcolor: drawerColors[color].header,
        color: 'white',
        minHeight: 65,
      }),
    })}
    {...props}
  >
    <Stack direction="row" spacing={1} alignItems="center" overflow="hidden">
      {children}
    </Stack>
    <CloseButton
      onClick={onClose}
      sx={{ ...(color && { color: 'white !important' }) }}
    />
  </Stack>
)

export default SidebarHeader
