import { styled } from '@mui/material'

import Filled from './filled.svg'
import Outlined from './outlined.svg'
import { withIconContainer } from '../IconContainer'

const ErrorIcon = withIconContainer(Outlined, Filled)

export default ErrorIcon

const OutlinedInfo = styled(Outlined)({
  transform: 'rotate(180deg)',
})

const FilledInfo = styled(Filled)({
  transform: 'rotate(180deg)',
})

export const InfoIcon = withIconContainer(OutlinedInfo, FilledInfo)
