import React from 'react'

import debounce from 'debounce'

import useEventListenerEffect from './useEventListenerEffect'

export const useIdleEffect = (idleTimeout: number, onTimeout: () => void) => {
  const callback = React.useMemo(
    () => debounce(onTimeout, idleTimeout),
    [onTimeout, idleTimeout],
  )

  React.useEffect(() => () => callback.clear(), [callback])

  useEventListenerEffect(window, 'click', callback)
  useEventListenerEffect(window, 'keyup', callback)
}
