import { Button } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { LoadingButton } from 'src/shared/components/buttons/LoadingButton/LoadingButton'

interface ConflictResolutionModalActionsProps {
  onCancellation: () => void
  onConfirmation: () => Promise<void>
  isDisabled: boolean
  newElementCount: number
}

export const ConflictResolutionModalActions = ({
  onCancellation,
  onConfirmation,
  isDisabled,
  newElementCount,
}: ConflictResolutionModalActionsProps) => {
  const { t } = useTranslation()

  return (
    <>
      <LoadingButton
        variant="contained"
        color="primary"
        size="large"
        sx={{ minWidth: 'fit-content' }}
        fullWidth
        onClick={onConfirmation}
      >
        {t(`schedule_conflict_resolution.create_button_text`, {
          defaultValue: 'Create {{count}} common',
          count: newElementCount,
        })}
      </LoadingButton>
      <Button
        variant="outlined"
        color="neutral"
        size="large"
        sx={{ minWidth: 'fit-content' }}
        fullWidth
        onClick={onCancellation}
        disabled={isDisabled}
      >
        {t('modal.button.dismiss')}
      </Button>
    </>
  )
}
