import { Stack } from '@mui/material'

import { type AreaInterface } from 'src/entities/area/types/area'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import {
  type TableInterface,
  type TableOccupancyInterface,
} from 'src/entities/table/types/table'
import AreaSection from './AreaSection/AreaSection'

interface OccupanciesListProps {
  tables: TableInterface[]
  areas: AreaInterface[]
  onOccupancyRemoval: (occupancy: TableOccupancyInterface) => void
  onOccupancyChange: (table: TableInterface, seatCount: number) => void
  reservation: ReservationInterface
}

const OccupanciesList = ({
  reservation,
  tables,
  areas,
  onOccupancyRemoval,
  onOccupancyChange,
}: OccupanciesListProps) => (
  <Stack direction="column" spacing={0.5}>
    {areas.map(a => (
      <AreaSection
        key={a.id}
        area={a}
        tables={tables}
        onOccupancyRemoval={onOccupancyRemoval}
        onOccupancyChange={onOccupancyChange}
        reservation={reservation}
      />
    ))}
  </Stack>
)

export default OccupanciesList
