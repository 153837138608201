import { Stack, Typography } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { formatTime } from 'src/shared/lib/range/services/date'

export const ItemHeader = ({
  arrival,
  seatCount,
}: {
  arrival: Date
  seatCount: number
}) => (
  <Stack direction="column" alignItems="center" gap={0.5}>
    <Typography
      sx={{
        lineHeight: '1rem',
        fontSize: '0.8125rem',
      }}
      {...createTestIdProps('arrival')}
    >
      {formatTime(arrival)}
    </Typography>
    <Typography
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: 'rgba(0, 0, 0, 0.64)',
        color: 'white',
        height: 16,
        minWidth: 16,
        px: 0.5,
        borderRadius: 100,
        fontSize: '0.75rem',
        fontWeight: '500',
        lineHeight: '0.875rem',
      }}
      {...createTestIdProps('seat-count')}
    >
      {seatCount}
    </Typography>
  </Stack>
)
