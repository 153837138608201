import {
  type PhoneAcceptanceTimes,
  type PhoneAcceptanceTimesException,
} from 'src/entities/schedule/types/scheduleApi'
import { formatTime, fromTime } from 'src/shared/lib/range/services/date'
import {
  type PhoneConfirmationsExceptionFormValues,
  type PhoneConfirmationsFormValues,
} from './getPhoneConfirmationsFormSchema'

const fromApiAcceptanceTime = ({
  startTime,
  endTime,
}: {
  startTime: string
  endTime: string
}): Date[] => [fromTime(startTime), fromTime(endTime)]

export const fromPhoneConfirmationFormData = <
  T extends
    | PhoneConfirmationsFormValues
    | PhoneConfirmationsExceptionFormValues,
>(
  data: T,
) => {
  const { dateRange } = data as PhoneConfirmationsExceptionFormValues

  return {
    ...(dateRange
      ? {
          effectivePeriod: {
            start: dateRange[0],
            end: dateRange[1],
          } satisfies PhoneAcceptanceTimesException['effectivePeriod'],
        }
      : {}),

    monday:
      data.isActive && data.monday.isActive
        ? data.monday.acceptanceTimes.map(arr => ({
            startTime: formatTime(arr[0]),
            endTime: formatTime(arr[1]),
          }))
        : [],
    tuesday:
      data.isActive && data.tuesday.isActive
        ? data.tuesday.acceptanceTimes.map(arr => ({
            startTime: formatTime(arr[0]),
            endTime: formatTime(arr[1]),
          }))
        : [],
    wednesday:
      data.isActive && data.wednesday.isActive
        ? data.wednesday.acceptanceTimes.map(arr => ({
            startTime: formatTime(arr[0]),
            endTime: formatTime(arr[1]),
          }))
        : [],
    thursday:
      data.isActive && data.thursday.isActive
        ? data.thursday.acceptanceTimes.map(arr => ({
            startTime: formatTime(arr[0]),
            endTime: formatTime(arr[1]),
          }))
        : [],
    friday:
      data.isActive && data.friday.isActive
        ? data.friday.acceptanceTimes.map(arr => ({
            startTime: formatTime(arr[0]),
            endTime: formatTime(arr[1]),
          }))
        : [],
    saturday:
      data.isActive && data.saturday.isActive
        ? data.saturday.acceptanceTimes.map(arr => ({
            startTime: formatTime(arr[0]),
            endTime: formatTime(arr[1]),
          }))
        : [],
    sunday:
      data.isActive && data.sunday.isActive
        ? data.sunday.acceptanceTimes.map(arr => ({
            startTime: formatTime(arr[0]),
            endTime: formatTime(arr[1]),
          }))
        : [],
  } as T extends PhoneConfirmationsExceptionFormValues
    ? Omit<PhoneAcceptanceTimesException, 'id'>
    : Omit<PhoneAcceptanceTimes, 'id'>
}

const toAcceptanceTime = (d: PhoneAcceptanceTimes['monday']) =>
  d.length ? d.map(fromApiAcceptanceTime) : [[null, null]]

const hasAcceptanceTimes = (d: PhoneAcceptanceTimes) =>
  Boolean(
    d.monday.length ||
      d.tuesday.length ||
      d.wednesday.length ||
      d.thursday.length ||
      d.friday.length ||
      d.saturday.length ||
      d.sunday.length,
  )

export const toPhoneConfirmationsFormData = <
  T extends PhoneAcceptanceTimes | PhoneAcceptanceTimesException,
>(
  phoneConfirmations: T,
) => {
  const { effectivePeriod } =
    phoneConfirmations as PhoneAcceptanceTimesException

  return {
    ...(effectivePeriod
      ? {
          dateRange: [
            effectivePeriod.start,
            effectivePeriod.end,
          ] satisfies PhoneConfirmationsExceptionFormValues['dateRange'],
        }
      : {}),
    isActive: hasAcceptanceTimes(phoneConfirmations),
    monday: {
      isActive: !!phoneConfirmations.monday.length,
      acceptanceTimes: toAcceptanceTime(phoneConfirmations.monday),
    },
    tuesday: {
      isActive: !!phoneConfirmations.tuesday.length,
      acceptanceTimes: toAcceptanceTime(phoneConfirmations.tuesday),
    },
    wednesday: {
      isActive: !!phoneConfirmations.wednesday.length,
      acceptanceTimes: toAcceptanceTime(phoneConfirmations.wednesday),
    },
    thursday: {
      isActive: !!phoneConfirmations.thursday.length,
      acceptanceTimes: toAcceptanceTime(phoneConfirmations.thursday),
    },
    friday: {
      isActive: !!phoneConfirmations.friday.length,
      acceptanceTimes: toAcceptanceTime(phoneConfirmations.friday),
    },
    saturday: {
      isActive: !!phoneConfirmations.saturday.length,
      acceptanceTimes: toAcceptanceTime(phoneConfirmations.saturday),
    },
    sunday: {
      isActive: !!phoneConfirmations.sunday.length,
      acceptanceTimes: toAcceptanceTime(phoneConfirmations.sunday),
    },
  } as T extends PhoneAcceptanceTimesException
    ? PhoneConfirmationsExceptionFormValues
    : PhoneConfirmationsFormValues
}
