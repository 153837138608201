import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ComponentProps,
} from 'react'
import { Stack } from '@mui/material'

import { noop } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { useBatchEditSchedule } from 'src/entities/schedule/queries/schedule'
import { type ConflictResolution } from 'src/entities/schedule/types/scheduleApi'
import Modal from 'src/shared/components/common/Modal'
import { ConflictResolutionModalActions } from './components/ConflictResolutionModalActions'
import {
  NewConflictingEntitySection,
  type ScheduleFormAction,
  type ShiftLikeEntityType,
  type ShiftLikeFormEntity,
} from './components/NewConflictingEntitySection'
import { SelectableResolutionGrid } from './components/SelectableResolutionGrid'
import { SelectableResolutions } from './components/SelectableResolutions'

export interface ConflictResolutionModalProps
  extends Omit<
    ComponentProps<typeof Modal>,
    'actions' | 'title' | 'content' | 'color' | 'children'
  > {
  entity: ShiftLikeFormEntity
  entityType: ShiftLikeEntityType
  actionType: ScheduleFormAction
  resolutions: ConflictResolution[] | null
  onConfirmation?: () => Promise<void> | void
}

export const ConflictResolutionModal = (
  props: ConflictResolutionModalProps,
) => {
  const { t } = useTranslation()

  const { mutateAsync: batchEdit } = useBatchEditSchedule()

  const {
    onConfirmation = noop,
    onClose,
    resolutions,
    actionType,
    entity,
    entityType,
    ...rest
  } = props

  const [isDisabled, setIsDisabled] = useState(false)

  const [selectedResolutions, setSelectedResolutions] = useState<
    ConflictResolution['commands'] | null
  >(null)

  useEffect(() => {
    setSelectedResolutions(resolutions?.map(r => r.commands[0]!) ?? null)
  }, [resolutions])

  const newExceptionCount = useMemo(
    () => resolutions?.filter(r => r.conflict).length ?? 0,
    [resolutions],
  )

  const onConfirmSelection = useCallback(async () => {
    setIsDisabled(true)
    try {
      await batchEdit(
        selectedResolutions!.map(a => ({
          name: a.actionName,
          command: a.payload,
        })),
      )
      onClose()
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await onConfirmation()
    } finally {
      setIsDisabled(false)
    }
  }, [batchEdit, selectedResolutions, onClose, onConfirmation])

  return (
    <Modal
      title={t(
        'schedule.conflict_resolution.modal_title',
        'Conflicts Detected',
      )}
      subtitle={t(
        `schedule_conflict_resolution.modal_subtitles.${actionType}_${entityType}`,
        `Placeholder for schedule_conflict_resolution.modal_subtitles.${actionType}_${entityType}`,
      )}
      actions={
        <ConflictResolutionModalActions
          isDisabled={isDisabled}
          newElementCount={newExceptionCount}
          onCancellation={onClose}
          onConfirmation={onConfirmSelection}
        />
      }
      onClose={onClose}
      showCloseButton
      PaperProps={{
        sx: { maxWidth: 960 },
      }}
      {...rest}
    >
      <Stack gap={2}>
        <NewConflictingEntitySection
          entity={entity}
          entityType={entityType}
          actionType={actionType}
        />
        <SelectableResolutionGrid>
          <SelectableResolutions
            resolutions={resolutions}
            selectedResolutions={selectedResolutions}
            setSelectedResolutions={setSelectedResolutions}
          />
        </SelectableResolutionGrid>
      </Stack>
    </Modal>
  )
}
