import { useState } from 'react'
import { Alert, Box, Tab, tabClasses, Tabs, Typography } from '@mui/material'

import { type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type ScheduleLanguage } from 'src/entities/schedule/types/scheduleApi'
import { TextArea } from 'src/shared/components/form/inputs/TextArea'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import ErrorIcon from 'src/shared/components/icons/ErrorIcon'
import { ReservationQuestionOptions } from './ReservationQuestionOptions'
import FormSection from '../../FormSection'
import { translateLanguage } from '../../ShiftForm/components/MessageSection/components/MessageText/components/LanguageTabs/LanguageTabs'
import { type ReservationQuestionFormValues } from '../service/getReservationQuestionFormSchema'
import { getReservationQuestionTranslations } from '../service/getReservationQuestionTranslations'

interface ReservationQuestionInputsProps {
  hookFormProps: UseFormReturn<ReservationQuestionFormValues>
  language: ScheduleLanguage
}

export const ReservationQuestionInputs = ({
  hookFormProps,
  language,
}: ReservationQuestionInputsProps) => {
  const { t } = useTranslation()

  const languages = ['de', 'en', 'fr', 'it'] satisfies ScheduleLanguage[]

  const tr = getReservationQuestionTranslations(t)

  const [currentLang, setCurrentLang] = useState<ScheduleLanguage>(
    languages.find(l => l !== language)!,
  )

  const {
    register,
    watch,
    formState: { errors, defaultValues },
    getValues,
  } = hookFormProps

  const emptyErrorMessage = t(
    'common.validation.required',
    'This field cannot be empty',
  )

  const isQuestionError = !!errors.question?.[language]
  const questionErrorMessage = isQuestionError && emptyErrorMessage

  const sectionHasError = !!errors.question || !!errors.options || !!errors.hint

  const hintError = errors.hint?.[currentLang]
  const hintErrorMessage = hintError?.message?.includes('null')
    ? tr.form.mustBeEmpty
    : emptyErrorMessage

  const allQuestionsDefined = !Object.entries(watch('question')).some(
    ([lang, q]) => lang !== language && !q,
  )

  return (
    <FormSection
      title={tr.form.title}
      errorText={sectionHasError ? tr.form.errorWithSection : undefined}
    >
      {!allQuestionsDefined && (
        <Alert severity="warning">{tr.form.missingLanguageDisclaimer}</Alert>
      )}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr 1fr auto'],
          alignItems: 'start',
          rowGap: 0.5,
          columnGap: 5,
        }}
      >
        <Typography variant="body2" fontWeight="500" alignSelf="center">
          {translateLanguage(t)(language)} ({tr.form.default})
        </Typography>
        <Tabs
          value={currentLang}
          onChange={(_, value) => setCurrentLang(value as ScheduleLanguage)}
          sx={theme => ({
            alignSelf: 'center',
            gridColumn: [undefined, 'span 2'],
            borderBottom: `1px solid ${theme.palette.grey[100]}`,
          })}
          variant="scrollable"
          allowScrollButtonsMobile
        >
          {languages
            .filter(l => l !== language)
            .map(l => (
              <Tab
                iconPosition="start"
                icon={
                  !watch(`question.${l}`) ? (
                    <ErrorIcon
                      size="small"
                      variant="filled"
                      sx={theme => ({
                        color: theme.palette.warningPalette[500],
                      })}
                    />
                  ) : undefined
                }
                key={l}
                label={translateLanguage(t)(l)}
                value={l}
                sx={{ [`.${tabClasses.iconWrapper}`]: {}, minHeight: 48 }}
              />
            ))}
        </Tabs>
        <Typography
          variant="body3"
          fontWeight="500"
          mt={2}
          sx={{ gridColumn: [undefined, '1 / span 3'] }}
        >
          {tr.form.question}
        </Typography>
        <TextArea
          placeholder={tr.form.questionPlaceholder}
          error={isQuestionError}
          helperText={questionErrorMessage}
          {...register(`question.${language as 'en'}`, {
            setValueAs: (v: string) => v || null,
          })}
        />
        <TextArea
          key={`question-${currentLang}`}
          placeholder={translateLanguage(t)(currentLang)}
          defaultValue={getValues().question?.[currentLang as 'en']}
          {...register(`question.${currentLang as 'en'}`, {
            setValueAs: (v: string) => v || null,
          })}
        />
        <div />
        <Typography
          variant="body3"
          fontWeight="500"
          mt={2}
          sx={{ gridColumn: [undefined, '1 / span 3'] }}
        >
          {tr.form.hint}{' '}
          <Typography component="span" color="grey.500">
            ({tr.form.optional})
          </Typography>
        </Typography>
        <TextArea
          placeholder={tr.form.hintPlaceholder}
          {...register(`hint.${language as 'en'}`, {
            setValueAs: (v: string) => v || null,
          })}
        />
        <TextArea
          key={`hint-${currentLang}`}
          placeholder={translateLanguage(t)(currentLang)}
          defaultValue={getValues().hint?.[currentLang as 'en']}
          error={!!hintError}
          helperText={hintError && hintErrorMessage}
          {...register(`hint.${currentLang as 'en'}`, {
            setValueAs: (v: string) => v || null,
          })}
        />
        <div />
        <ReservationQuestionOptions
          hookFormProps={hookFormProps}
          currentLang={currentLang}
          restaurantLang={language}
        />
      </Box>

      <Toggle
        label={tr.form.isMandatory}
        defaultChecked={defaultValues?.isMandatory}
        {...register('isMandatory')}
      />
    </FormSection>
  )
}
