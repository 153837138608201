import React, { useDeferredValue } from 'react'

import useFeature from '../../info/hooks/useFeature'
import { useCommunicationTemplatesQuery } from '../../info/queries/info'
import { type ReservationInterface } from '../../reservation/types/reservation'
import { useCurrentServiceTime } from '../../service-time/queries/serviceTime'
import { useShiftsQuery } from '../../shift-instance/queries/shift'
import { type ShiftCommunicationTemplate } from '../../shift-instance/types/shift'
import {
  getCustomTemplatesFeature,
  getDefaultTemplate,
  getPossibleTemplates,
  noTemplate,
  type Templates,
} from '../services/communicationTemplates'
import { type CommunicationTemplate } from '../types/communicationTemplates'

export const useCommunicationTemplates = (
  communicationAction: CommunicationTemplate['action'] | undefined,
  reservation: ReservationInterface | undefined,
  communicationEnabled: boolean,
  channel: ShiftCommunicationTemplate['channel'],
) => {
  const { data: shifts } = useShiftsQuery(
    useDeferredValue(reservation?.dateRange[0]),
  )
  const reservationShift = useCurrentServiceTime(
    shifts,
    reservation?.dateRange[0],
  )

  const { data: allTemplates } = useCommunicationTemplatesQuery(channel)

  const customTemplatesEnabled = useFeature(getCustomTemplatesFeature(channel))

  const possibleTemplates = React.useMemo<Templates>(
    () =>
      getPossibleTemplates(
        allTemplates,
        customTemplatesEnabled,
      )(communicationAction),
    [allTemplates, customTemplatesEnabled, communicationAction],
  )

  const [selectedTemplate, setSelectedTemplate] =
    React.useState<CommunicationTemplate>(noTemplate)

  React.useEffect(() => {
    setSelectedTemplate(
      getDefaultTemplate(
        possibleTemplates,
        reservationShift,
        channel,
        communicationEnabled,
      )(communicationAction),
    )
  }, [
    communicationAction,
    possibleTemplates,
    reservationShift,
    channel,
    communicationEnabled,
  ])

  return {
    templates: possibleTemplates,
    selectedTemplate,
    setSelectedTemplate,
  }
}
