import { forwardRef, Suspense } from 'react'
import { Stack } from '@mui/material'

import { type ReactNode } from '@tanstack/react-router'

import Loader from 'src/pages/CustomerChoice/SearchResults/SummaryRow/Summary/Loader'

interface ConfigScrollProps {
  children: ReactNode
}

export const ConfigScroll = forwardRef<HTMLDivElement, ConfigScrollProps>(
  ({ children }: ConfigScrollProps, ref) => (
    <Stack ref={ref} sx={{ minHeight: 0, overflow: 'auto' }}>
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </Stack>
  ),
)
