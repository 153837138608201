import React, { useMemo } from 'react'
import { TableCell, TableRow } from '@mui/material'

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { type TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { useActivityLogQuery } from 'src/entities/activity-log/queries/activityLog'
import { type ActivityLogEntryInterface } from 'src/entities/activity-log/types/activityLog'
import VirtualTable from 'src/shared/components/common/VirtualTable/VirtualTable'
import VirtualTableContainer from 'src/shared/components/common/VirtualTable/VirtualTableContainer'

const activityLogColumnHelper = createColumnHelper<ActivityLogEntryInterface>()

const activityLogColumnsFactory = (
  t: TFunction,
): ColumnDef<ActivityLogEntryInterface, string>[] => [
  activityLogColumnHelper.accessor('date', {
    header: t('when'),
    size: 25,
  }),
  activityLogColumnHelper.accessor('who', {
    header: t('who'),
    size: 25,
  }),
  activityLogColumnHelper.accessor('message', {
    header: t('what'),
    size: 200,
  }),
  activityLogColumnHelper.accessor('action', {
    header: t('action'),
    size: 50,
  }),
  activityLogColumnHelper.accessor('reservation', {
    header: t('reservation'),
    size: 100,
  }),
]

const ActivityLog = ({ reservationId }: { reservationId?: number }) => {
  const { data } = useActivityLogQuery({ reservationId })

  const { t } = useTranslation('', { keyPrefix: 'activity_log' })

  const tableContainerRef = React.useRef(null)

  const { getVirtualItems, getTotalSize, measureElement } = useVirtualizer({
    count: data.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 64,
    overscan: 24,
  })

  const columns = useMemo(() => activityLogColumnsFactory(t), [t])
  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  })
  const { rows } = getRowModel()

  const items = getVirtualItems()
  return (
    <VirtualTableContainer ref={tableContainerRef}>
      <VirtualTable
        totalSize={getTotalSize()}
        items={items}
        headers={getHeaderGroups()}
      >
        {items.map(({ index, size }) => {
          const row = rows[index]!

          return (
            <TableRow
              hover
              key={row.id}
              sx={{ height: size }}
              ref={measureElement}
              data-index={index}
            >
              {row.getVisibleCells().map(cell => (
                <TableCell key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          )
        })}
      </VirtualTable>
    </VirtualTableContainer>
  )
}

export default ActivityLog
