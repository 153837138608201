import { lensPath, set, view } from 'ramda'
import { Typography } from '@mui/material'

import { LimitInput } from 'src/shared/components/form/inputs/LimitInput/LimitInput'
import { useBaseFormContext } from '../../../../../../hooks/form'
import type { BaseForm } from '../../../../../../services/formSchema'

type Pacing = BaseForm['customPacing']['pacings'][number]

const allocationLens = lensPath<Pacing, 'allocation'>(['allocation'])

interface SlotPacingProps {
  pacing: Pacing
  onChange: (slots: Pacing) => void
}

const SlotPacing = ({ pacing, onChange }: SlotPacingProps) => {
  const { watch } = useBaseFormContext()

  return (
    <>
      <Typography variant="labelSmall">{pacing.time}</Typography>
      <LimitInput
        onChange={newValue => onChange(set(allocationLens, newValue, pacing))}
        value={view(allocationLens, pacing)}
        maxValue={watch('shiftGuestCountLimit')}
      />
    </>
  )
}

export default SlotPacing
