import {
  Typography as MuiTypography,
  styled,
  type TypographyProps as MuiProps,
} from '@mui/material'

export interface TypographyProps extends MuiProps {
  disabled?: boolean
  error?: boolean
}

const Typography = styled(MuiTypography)<TypographyProps>(
  ({ disabled, error, theme }) => ({
    ...(disabled && {
      color: theme.palette.action.disabled,
    }),
    ...(error && {
      color: theme.palette.error.main,
    }),
  }),
)

export default Typography
