import { type PhoneCallNotificationInterface } from 'src/entities/notification/types/notification'
import createReducerServiceContext from './reducerServiceContextFactory'
import stackReducer, { type State } from '../../reducer/service/stack'

const initialState: State<PhoneCallNotificationInterface> = {
  stack: [],
}

const {
  useService: usePhoneCallNotificationsStackService,
  useState: usePhoneCallNotificationsStackState,
  withProvider: withPhoneCallNotificationsStackReducerProvider,
} = createReducerServiceContext(initialState, stackReducer)

export {
  usePhoneCallNotificationsStackService,
  usePhoneCallNotificationsStackState,
  withPhoneCallNotificationsStackReducerProvider,
}
