import {
  ascend,
  compose,
  descend,
  has,
  identity,
  ifElse,
  prop,
  sortWith,
  type Ord,
} from 'ramda'

import { type Row } from '@tanstack/react-table'

import { getCreatedAt } from 'src/shared/lib/api/services/audit'
import { toTimestamp } from 'src/shared/lib/range/services/date'
import {
  formatGuestName,
  getGuest,
  getNumber,
  getSeatCount,
  getStartDate,
} from './reservation'
import { type ReservationInterface } from '../types/reservation'

const isRow = (
  row: ReservationInterface | Row<ReservationInterface>,
): row is Row<ReservationInterface> => has('original', row)
const getRow = ifElse(isRow, prop('original'), identity)
const getRowTimestamp = compose(toTimestamp, getStartDate, getRow)
const getRowNumber = compose(getNumber, getRow)

export const dateRangeSorter = ifElse(
  compose(Boolean, ascend(getRowTimestamp)),
  ascend(getRowTimestamp),
  ascend(getRowNumber),
)

const getRowGuest = compose(formatGuestName, getGuest, getRow)

export const guestSorter = ascend(getRowGuest)

export const SORT_FIELDS = ['time', 'created', 'lastname', 'persons'] as const
export type SortField = (typeof SORT_FIELDS)[number]

const sortFieldToGetters: {
  [key in SortField]: ((a: ReservationInterface) => Ord)[]
} = {
  time: [getStartDate, getNumber],
  created: [getCreatedAt],
  lastname: [getRowGuest],
  persons: [getSeatCount],
}
const directionToSorter: {
  [key in 'asc' | 'desc']: typeof ascend<ReservationInterface>
} = {
  asc: ascend,
  desc: descend,
}

export const getSortingFn = (field?: SortField, direction?: 'asc' | 'desc') => {
  if (!field || !direction) return identity<ReservationInterface[]>

  const getters = sortFieldToGetters[field]
  const sorter = directionToSorter[direction]

  return sortWith(getters.map(getter => sorter(getter)))
}
