import { Fragment } from 'react'
import { assocPath } from 'ramda'
import { Button, Stack, Typography } from '@mui/material'

import { useController, type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type ScheduleLanguage } from 'src/entities/schedule/types/scheduleApi'
import TrashButton from 'src/shared/components/buttons/TrashButton'
import FormErrorText from 'src/shared/components/form/common/FormErrorText/FormErrorText'
import { TextArea } from 'src/shared/components/form/inputs/TextArea'
import PlusIcon from 'src/shared/components/icons/PlusIcon'
import { translateLanguage } from '../../ShiftForm/components/MessageSection/components/MessageText/components/LanguageTabs/LanguageTabs'
import { type ReservationQuestionFormValues } from '../service/getReservationQuestionFormSchema'
import { getReservationQuestionTranslations } from '../service/getReservationQuestionTranslations'

interface ReservationQuestionOptionsProps {
  hookFormProps: UseFormReturn<ReservationQuestionFormValues>
  currentLang: ScheduleLanguage
  restaurantLang: ScheduleLanguage
}

export const ReservationQuestionOptions = ({
  hookFormProps: { control },
  currentLang,
  restaurantLang: language,
}: ReservationQuestionOptionsProps) => {
  const { t } = useTranslation()

  const tr = getReservationQuestionTranslations(t)

  const {
    field: { value, onChange },
    formState: { errors },
  } = useController({ name: 'options', control })

  const emptyErrorMessage = t(
    'common.validation.required',
    'This field cannot be empty',
  )

  const noOptionsError = errors.options?.type === 'too_small'

  return (
    <>
      <Stack
        alignItems="start"
        gap={1}
        sx={{ gridColumn: [undefined, '1 / span 3'] }}
      >
        <Typography variant="body2" fontWeight="500" mt={2}>
          {tr.form.options}
        </Typography>
        <FormErrorText
          error={noOptionsError}
          helperText={noOptionsError && tr.form.noOptions}
        />
      </Stack>
      {value?.map((o, i) => {
        const isMainError = !!errors.options?.[i]?.[language]
        const isCurrentError = !!errors.options?.[i]?.[currentLang]

        const currentLangFieldValue = o[currentLang as 'en']

        return (
          <Fragment key={i}>
            <Stack useFlexGap gap={1} mt={1}>
              <TextArea
                placeholder={tr.form.optionPlaceholder}
                value={o[language as 'en'] ?? ''}
                onChange={e =>
                  onChange(
                    assocPath([i, language], e.target.value || null, value),
                  )
                }
                error={isMainError}
                helperText={isMainError && emptyErrorMessage}
              />
            </Stack>
            <TextArea
              sx={{ mt: 1 }}
              placeholder={translateLanguage(t)(currentLang)}
              value={currentLangFieldValue ?? ''}
              onChange={e =>
                onChange(
                  assocPath([i, currentLang], e.target.value || null, value),
                )
              }
              error={isCurrentError}
              helperText={
                isCurrentError &&
                (currentLangFieldValue === null
                  ? emptyErrorMessage
                  : tr.form.mustBeEmpty)
              }
            />
            <TrashButton
              sx={{
                mt: 1,
                justifySelf: 'center',
                visibility: value.length > 1 ? 'visible' : 'hidden',
              }}
              onClick={() => onChange(value.filter((_, idx) => idx !== i))}
            />
          </Fragment>
        )
      })}
      <Button
        startIcon={<PlusIcon />}
        sx={{ justifySelf: 'start' }}
        onClick={() =>
          onChange([
            ...(value ?? []),
            {
              de: null,
              en: null,
              fr: null,
              it: null,
            } satisfies ReservationQuestionFormValues['options'][number],
          ])
        }
      >
        {tr.form.addMoreOptions}
      </Button>
    </>
  )
}
