import { useMemo } from 'react'
import { Box, Stack } from '@mui/material'

import Color from 'color'

import CheckmarkIcon from '../../../../icons/CheckmarkIcon'

interface SwatchProps {
  color: string
  onClick?: (color: string) => void
  selected: boolean
}

const Swatch = ({ color, onClick, selected }: SwatchProps) => {
  const isLightColor = useMemo(() => Color(color).luminosity() > 0.5, [color])

  return (
    <Box
      role="button"
      sx={{
        p: 0.5,
        border: '1px solid',
        borderRadius: 2,
        borderColor: selected ? 'grey.200' : 'white',
      }}
    >
      <Stack
        sx={{
          backgroundColor: color,
          borderRadius: 2,
          border: `1px solid`,
          borderColor: isLightColor ? 'grey.200' : 'white',
          color: isLightColor ? 'grey.700' : 'white',
          cursor: 'pointer',
        }}
        onClick={() => onClick?.(color)}
      >
        <CheckmarkIcon
          size="small"
          sx={{ visibility: selected ? 'visible' : 'hidden' }}
        />
      </Stack>
    </Box>
  )
}

export default Swatch
