import {
  add,
  append,
  complement,
  compose,
  filter,
  find,
  findLast,
  flatten,
  identity,
  ifElse,
  indexOf,
  lensIndex,
  lensProp,
  map,
  max,
  min,
  over,
  propEq,
  reduce,
  set,
  T,
  unless,
  view,
  when,
} from 'ramda'

import { newOccupancyFromTable } from './occupancyFactory'
import { reservationExists } from './reservation'
import { type AreaInterface } from '../../area/types/area'
import {
  areOccupyingTable,
  getOccupancyTable,
  getTableArea,
  occupies,
} from '../../table/services/table'
import {
  type TableCheckInStatusEnum,
  type TableInterface,
  type TableOccupancyInterface,
} from '../../table/types/table'
import { type ReservationInterface } from '../types/reservation'

const occupanciesLens = lensProp<ReservationInterface, 'occupancies'>(
  'occupancies',
)

export const getOccupancies = compose(flatten, map(view(occupanciesLens)))

export const occupiesTable =
  (element: TableInterface | undefined) =>
  (reservation: ReservationInterface) =>
    areOccupyingTable(reservation.occupancies)(element)

const statusLens = lensProp<TableOccupancyInterface, 'checkInStatus'>(
  'checkInStatus',
)
const seatCountLens = lensProp<TableOccupancyInterface, 'seatCount'>(
  'seatCount',
)

const doesNotExistOrIsOccupied = (table: TableInterface | undefined) => {
  if (!table) return T

  return occupies(table)
}

const updateOccupancySeatCount = (table: TableInterface, seatCount: number) =>
  map(when(occupies(table), set(seatCountLens, seatCount)))

const appendNewOccupancy = (table: TableInterface, seatCount: number) =>
  append(newOccupancyFromTable(table, seatCount))

export const occupyTable = (table: TableInterface, seatCount: number) =>
  over(
    occupanciesLens,
    ifElse(
      r => r.some(occupies(table)),
      updateOccupancySeatCount(table, seatCount),
      appendNewOccupancy(table, seatCount),
    ),
  )

export const updateOccupancyStatus = (
  table: TableInterface | undefined,
  status: TableCheckInStatusEnum,
) =>
  over(
    occupanciesLens,
    map(when(doesNotExistOrIsOccupied(table), set(statusLens, status))),
  )

export const releaseTable = (tableId: TableInterface['id']) =>
  over(occupanciesLens, filter(complement(propEq(tableId, 'tableId'))))

export const getArea = (areas: AreaInterface[], tables: TableInterface[]) =>
  compose(getTableArea(areas), getOccupancyTable(tables))

export const sumSeatCount = (
  paxSum: number,
  seatCountAware: { seatCount: number },
) => seatCountAware.seatCount + paxSum

export const sumOccupanciesSeatCount = (res: ReservationInterface) =>
  reduce(sumSeatCount, 0, res.occupancies)

export const calcSeatCount =
  (table: TableInterface = { capacity: 0 } as TableInterface) =>
  (reservation: ReservationInterface) => {
    const assigned = sumOccupanciesSeatCount(reservation)
    const unassigned = Math.max(reservation.seatCount - assigned, 0)

    if (reservation.exactMode) return Math.min(table.capacity, unassigned)

    if (unassigned === 0) return reservation.seatCount

    return unassigned
  }

export const trimOccupancyToCapacity =
  (tables: TableInterface[]) => (occupancy: TableOccupancyInterface) => {
    const table = find(areOccupyingTable([occupancy]), tables)
    if (!table) return occupancy

    return over(seatCountLens, min(table.capacity), occupancy)
  }

export const trimOccupanciesToCapacities = (tables: TableInterface[]) =>
  over(occupanciesLens, map(trimOccupancyToCapacity(tables)))

export const addOccupancy = (
  tables: TableInterface[],
  table: TableInterface,
  forceRestart?: boolean,
) =>
  unless(
    (r: ReservationInterface) => r.occupancies.some(occupies(table)),
    compose(
      (r: ReservationInterface): ReservationInterface => {
        const seatCount = calcSeatCount(table)(r)

        const shouldStartClean =
          !r.exactMode && (seatCount === r.seatCount || forceRestart)

        return compose(
          over(occupanciesLens, appendNewOccupancy(table, seatCount)),
          shouldStartClean ? set(occupanciesLens, []) : identity,
        )(r)
      },
      unless(r => r.exactMode, trimOccupanciesToCapacities(tables)),
    ),
  )

const firstOccupancyLens = compose(occupanciesLens, lensIndex(0))

export const removeOccupancy = (occupancy: TableOccupancyInterface) =>
  compose((r: ReservationInterface) => {
    if (!view(firstOccupancyLens, r) || r.exactMode) return r

    return over(
      compose(firstOccupancyLens, seatCountLens),
      add(occupancy.seatCount),
      r,
    )
  }, releaseTable(occupancy.tableId))

export const updateOccupancy = (table: TableInterface, seatCount: number) =>
  over(occupanciesLens, updateOccupancySeatCount(table, seatCount))

export const getSeatCountDifference = (tables: TableInterface[]) => {
  const trimOccupancies = trimOccupanciesToCapacities(tables)

  return (reservation: ReservationInterface) =>
    compose(sumOccupanciesSeatCount, trimOccupancies)(reservation) -
    reservation.seatCount
}

export const overwriteOccupancies = set(occupanciesLens)

const removeEmptyOccupancies = over(occupanciesLens, os =>
  os.filter(o => o.seatCount > 0),
)

export const equalizeOverflowingSeatCount =
  (tables: TableInterface[]) =>
  (reservation: ReservationInterface): ReservationInterface => {
    const overflowingSeatCount =
      sumOccupanciesSeatCount(reservation) - reservation.seatCount

    const lastNonEmptyOccupancy = findLast(
      o => !!o.seatCount,
      reservation.occupancies,
    )

    const firstEmptyOccupancy =
      find(o => {
        const table = tables.find(t => t.id === o.tableId)
        if (!table) return false

        return table.capacity - o.seatCount > 0
      }, reservation.occupancies) ?? lastNonEmptyOccupancy

    const occupancy =
      overflowingSeatCount > 0 ? lastNonEmptyOccupancy : firstEmptyOccupancy

    if (overflowingSeatCount === 0 || !occupancy) return reservation

    const occupancySeatCountLens = compose(
      occupanciesLens,
      lensIndex(indexOf(occupancy, reservation.occupancies)),
      seatCountLens,
    )

    const equalizedReservation = compose(
      removeEmptyOccupancies,
      over(occupancySeatCountLens, seatCount =>
        max(seatCount - overflowingSeatCount, 0),
      ),
    )(reservation)

    return equalizeOverflowingSeatCount(tables)(equalizedReservation)
  }

export const isAssignmentComplete = (
  reservation: ReservationInterface,
  tables: TableInterface[],
) =>
  !getSeatCountDifference(tables)(reservation) &&
  !reservation.exactMode &&
  reservationExists(reservation)
