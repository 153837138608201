import { Suspense, useLayoutEffect } from 'react'

import { createFileRoute, Outlet, redirect } from '@tanstack/react-router'
import { z } from 'zod'

import { ensureMe } from 'src/entities/me/queries/me'
import { useReservationsSearchInvalidation } from 'src/entities/reservation/queries/reservation'
import { useSettingsQuery } from 'src/entities/setting/queries/settings'
import { getDefaultView } from 'src/entities/setting/services/settings'
import { RestaurantLayout } from 'src/pages/Restaurant/RestaurantLayout'
import { useResetPhoneCalls } from 'src/shared/lib/context/state/atoms/incomingPhoneCalls'
import { useResetResetvationFilters } from 'src/shared/lib/context/state/atoms/reservationFilters'
import { useSetReservationIdForTableAssignment } from 'src/shared/lib/context/state/atoms/reservationIdForTableAssignment'
import { useResetSelectedAreaAtom } from 'src/shared/lib/context/state/atoms/selectedArea'
import { useResetGlobalReservationState } from 'src/shared/lib/context/state/atoms/selectedReservation'
import { useSetSelectedServiceTime } from 'src/shared/lib/context/state/atoms/selectedServiceTime'
import { useResetSelectedTable } from 'src/shared/lib/context/state/atoms/selectedTable'
import { useSetSelectedView } from 'src/shared/lib/context/state/atoms/selectedView'
import {
  useClientFiltersService,
  withClientFiltersProvider,
} from 'src/shared/lib/reducer/service/reservationsFilter'

export const Route = createFileRoute('/restaurants/$restaurantId')({
  component: RouteComponent,
  params: z.object({
    restaurantId: z.coerce.number().int().min(1),
  }),
  beforeLoad: async ({
    context: { queryClient },
    params: { restaurantId },
    matches,
  }) => {
    const { restaurants, defaultRestaurant } = await ensureMe(queryClient)

    const isRootPath = matches.at(-1)?.fullPath === '/restaurants/$restaurantId'
    const isIdValid = restaurants.some(r => r.id === restaurantId)

    if (!isRootPath && isIdValid) return

    throw redirect({
      to: '/restaurants/$restaurantId/reservations',
      params: { restaurantId: isIdValid ? restaurantId : defaultRestaurant.id },
    })
  },
})

const RestaurantLayoutWithFilters = withClientFiltersProvider(RestaurantLayout)

function RouteComponent() {
  const { restaurantId } = Route.useParams()

  return (
    <RestaurantLayoutWithFilters restaurantId={restaurantId}>
      <Outlet />
      <Suspense>
        <ResetOnRestaurantChange />
      </Suspense>
    </RestaurantLayoutWithFilters>
  )
}

function ResetOnRestaurantChange() {
  const { restaurantId } = Route.useParams()

  const resetGlobalReservationState = useResetGlobalReservationState()
  const { clearAllFilters } = useClientFiltersService()
  const setSelectedView = useSetSelectedView()
  const { data: settings } = useSettingsQuery()
  const resetResetvationFilters = useResetResetvationFilters()
  const resetSelectedAreaAtom = useResetSelectedAreaAtom()
  const setReservationIdForTableAssignment =
    useSetReservationIdForTableAssignment()
  const setSelectedServiceTime = useSetSelectedServiceTime()
  const resetPhoneCalls = useResetPhoneCalls()
  const resetSelectedTable = useResetSelectedTable()
  const invalidateSearch = useReservationsSearchInvalidation()

  useLayoutEffect(() => {
    if (!restaurantId) return

    clearAllFilters()
    resetGlobalReservationState()
    resetResetvationFilters()
    resetSelectedAreaAtom()
    setReservationIdForTableAssignment(null)
    setSelectedServiceTime(null)
    resetPhoneCalls()
    resetSelectedTable()
    setSelectedView(getDefaultView(settings))
    void invalidateSearch()
  }, [restaurantId])

  return null
}
