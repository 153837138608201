import { compose } from 'ramda'
import { Grid2 } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  useSettingsQuery,
  useSettingsUpdateMutation,
} from 'src/entities/setting/queries/settings'
import {
  groupBySection,
  sortSections,
} from 'src/entities/setting/services/settings'
import { SectionEnum } from 'src/entities/setting/types/setting'
import Section from './Section'

const General = () => {
  const { t } = useTranslation('', { keyPrefix: 'settings.general' })
  const { data: settings } = useSettingsQuery()
  const { mutateAsync: updateItem } = useSettingsUpdateMutation()

  const sections = compose(sortSections, groupBySection)(settings)

  return (
    <Grid2
      container
      spacing={2}
      columns={[1, 2, 3, 4, 6]}
      p={[1, 2]}
      justifyContent="center"
    >
      {Object.values(SectionEnum).map(sectionName => (
        <Grid2 key={sectionName} size={{ xs: 1 }} sx={{ display: 'flex' }}>
          <Section
            settings={sections[sectionName]}
            label={t(sectionName)}
            onSettingChange={updateItem}
          />
        </Grid2>
      ))}
    </Grid2>
  )
}

export default General
