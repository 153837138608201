import { Stack } from '@mui/material'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { PRIMARY_TABLE_BG } from 'src/entities/floor-plan/services/floorPlanElement'
import { type FloorPlanTableInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'
import TableItem from 'src/widgets/TabularTables/TableItem'
import TableItemInfo from 'src/widgets/TabularTables/TableItemInfo'
import DragHandle from './DragHandle'

const Item = ({
  table,
  onSelect,
  isSelected,
  dragDisabled,
}: {
  table: FloorPlanTableInterface
  onSelect: () => void
  isSelected: boolean
  dragDisabled: boolean
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: table.id ?? table.uuid! })

  return (
    <TableItem
      table={table}
      onSelect={onSelect}
      isSelected={isSelected}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        bgcolor: isSelected ? PRIMARY_TABLE_BG : table.color,
        transform: CSS.Translate.toString(transform),
        transition,
      }}
      ref={setNodeRef}
    >
      <Stack
        direction="row"
        sx={{
          bgcolor: table.color,
        }}
      >
        <DragHandle disabled={dragDisabled} {...attributes} {...listeners} />
        <Stack sx={{ minHeight: 60, width: 100 }}>
          <TableItemInfo table={table} />
        </Stack>
      </Stack>
    </TableItem>
  )
}

export default Item
