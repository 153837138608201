import { type ComponentProps } from 'react'
import { Drawer } from '@mui/material'

import withLayoutBreakpoints from 'src/app/hoc/withLayoutBreakpoints'
import { mergeSx } from 'src/app/theme/helpers'
import SwipeDrawer from 'src/shared/components/common/SwipeDrawer/SwipeDrawer'
import { drawerColors, type DrawerColor } from './drawerColor'

const WIDTH = 255

type SidebarDrawerProps = Omit<ComponentProps<typeof SwipeDrawer>, 'color'> & {
  level?: number
  color?: DrawerColor
}

const Xs = ({ sx, ...props }: SidebarDrawerProps) => (
  <SwipeDrawer
    anchor="bottom"
    {...props}
    sx={mergeSx(sx, { zIndex: '1000' })} // lower than angular uibModal default z-index - 1050
  />
)

const Sm = ({ PaperProps, level = 0, sx, ...props }: SidebarDrawerProps) => (
  <Drawer
    anchor="left"
    hideBackdrop
    disableEnforceFocus
    disableAutoFocus
    PaperProps={{
      ...PaperProps,
      sx: mergeSx(
        {
          width: WIDTH,
          pointerEvents: props.open ? 'all' : 'none',
          left: level * WIDTH,
          top: 0,
          bottom: 0,
          height: 'auto',
        },
        PaperProps?.sx,
      ),
    }}
    sx={mergeSx({ pointerEvents: 'none', position: 'absolute' }, sx)}
    elevation={4}
    {...props}
  />
)

const DrawerComponent = withLayoutBreakpoints({
  xs: Xs,
  sm: Sm,
})

export const SidebarDrawer = ({
  sx,
  PaperProps,
  level = 0,
  color,
  ...props
}: SidebarDrawerProps) => (
  <DrawerComponent
    PaperProps={{
      ...PaperProps,
      sx: mergeSx(
        {
          ...(color && { bgcolor: drawerColors[color].body, border: 'none' }),
        },
        PaperProps?.sx,
      ),
    }}
    level={level}
    elevation={4}
    {...props}
  />
)
