import { Button } from '@mui/material'

import { useTranslation } from 'react-i18next'

import PencilIcon from 'src/shared/components/icons/PencilIcon'
import { useSelectedServiceTime } from 'src/shared/lib/context/state/atoms/selectedServiceTime'
import { useOnEditServiceTimeMessage } from '../../../hooks/useOnEditServiceTimeMessage'

const EditNoteButton = ({
  onOpenEditModal,
}: {
  onOpenEditModal: () => void
}) => {
  const { t } = useTranslation()
  const serviceTime = useSelectedServiceTime()
  const onEdit = useOnEditServiceTimeMessage(onOpenEditModal)

  if (!serviceTime) return null

  return (
    <Button
      variant="text"
      size="small"
      startIcon={<PencilIcon size="small" variant="filled" />}
      onClick={onEdit}
    >
      {t('service_time_bar.edit_message_button', 'Edit service note')}
    </Button>
  )
}

export default EditNoteButton
