import {
  type ApiAreaInterface,
  type AreaInterface,
  type AreasOrderUpdateInterface,
} from './area'

export interface EmptyArea extends Omit<ApiAreaInterface, 'id'> {
  id: null
}

export const emptyArea = (areas: AreaInterface[]): EmptyArea => ({
  id: null,
  name: '',
  position: areas.length,
  roomId: 0,
  capacity: 25,
  days: [1, 2, 3, 4, 5, 6, 7],
})

export const order = (areas: AreaInterface[]): AreasOrderUpdateInterface =>
  Object.fromEntries(areas.map((area, index) => [area.id, index]))
