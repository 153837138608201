import {
  Typography,
  type CheckboxProps,
  type TypographyProps,
} from '@mui/material'

import { type Room, type Shift } from 'src/entities/schedule/types/scheduleApi'

interface ShiftSelectLabelProps {
  shiftLike: Pick<Shift, 'name' | 'roomId'>
  rooms: Room[]
  size?: CheckboxProps['size']
}

const getVariant = (
  size: NonNullable<CheckboxProps['size']>,
): TypographyProps['variant'] => {
  switch (size) {
    case 'large':
      return 'body1'
    case 'medium':
      return 'body2'
    case 'small':
      return 'body3'
    default:
      size satisfies never
      return 'body2'
  }
}

export const ShiftSelectLabel = ({
  rooms,
  shiftLike: { name, roomId },
  size = 'medium',
}: ShiftSelectLabelProps) => {
  const room = roomId ? rooms.find(r => r.id === roomId) : null

  return (
    <Typography variant={getVariant(size)} component="span">
      {name}{' '}
      {room && (
        <Typography component="span" color="grey.500">
          ({room.name})
        </Typography>
      )}
    </Typography>
  )
}
