import { createFileRoute } from '@tanstack/react-router'

import Tables from 'src/pages/Settings/Tables'
import { ConfigScroll } from './-ConfigScroll'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/config/tables',
)({
  component: RouteComponent,
})

function RouteComponent() {
  return (
    <ConfigScroll>
      <Tables />
    </ConfigScroll>
  )
}
