import type React from 'react'
import { Divider, Stack, Typography } from '@mui/material'

import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import ExternalLinkIcon from 'src/shared/components/icons/ExternalLinkIcon'

const sections = [
  'general',
  'booking',
  'rooms',
  'channels',
  'limits',
  'confirmations',
  'guest_flow',
  'message',
  'notifications',
  'labels',
  'payments',
] as const

export type FormSection = (typeof sections)[number]

export const sectionKeyToLabel = (t: TFunction) => (section: FormSection) =>
  ({
    general: t('schedule.shifts.general_section.title', 'General information'),
    booking: t(
      'schedule.shifts.booking_section.booking_window.label',
      'Booking window',
    ),
    rooms: t('schedule.shifts.rooms_section.title', 'Rooms'),
    channels: t('schedule.shifts.channels_section.title', 'Booking channels'),
    limits: t('schedule.shifts.limits_section.title', 'Limits'),
    confirmations: t(
      'schedule.shifts.confirmations_section.title',
      'Reservation confirmation',
    ),
    guest_flow: t('schedule.shifts.guest_flow_section.title', 'Guest flow'),
    message: t('schedule.shifts.message_section.title', 'Shift message'),
    notifications: t(
      'schedule.shifts.notifications_section.title',
      'Notifications',
    ),
    labels: t('schedule.shifts.labels_section.title', 'Labels'),
    payments: t('schedule.shifts.payments_section.title', 'No show fee'),
  })[section]

interface NavigationItemProps {
  sectionKey: FormSection
  scrollToItem: (item: FormSection) => void
}

const NavigationItem = ({ sectionKey, scrollToItem }: NavigationItemProps) => {
  const { t } = useTranslation()

  return (
    <Typography
      variant="body3"
      color="grey.900"
      onClick={() => scrollToItem(sectionKey)}
      sx={{ cursor: 'pointer' }}
    >
      {sectionKeyToLabel(t)(sectionKey)}
    </Typography>
  )
}

interface FormNavigationProps {
  items: FormSection[]
  scrollToItem: (item: FormSection) => void
}

const NavigationSection = ({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) => (
  <Stack direction="column" spacing={0.5}>
    <Typography variant="labelTiny" color="grey.500">
      {title}
    </Typography>
    <Stack direction="column" spacing={1}>
      {children}
    </Stack>
  </Stack>
)

const FormNavigation = ({ items, scrollToItem }: FormNavigationProps) => {
  const { t } = useTranslation()

  if (!items.length) return null

  return (
    <Stack
      sx={{
        position: 'sticky',
        top: 0,
        height: 'min-content',
        p: 2,
        backgroundColor: 'primaryPalette.25',
      }}
      spacing={1.5}
      divider={<Divider sx={{ borderColor: 'primaryPalette.300' }} />}
    >
      <NavigationSection
        title={t('schedule.shifts.navigation.settings', 'Settings')}
      >
        {items.map(item => (
          <NavigationItem
            key={item}
            sectionKey={item}
            scrollToItem={scrollToItem}
          />
        ))}
      </NavigationSection>
      <NavigationSection
        title={t('schedule.shifts.navigation.help', 'Need help?')}
      >
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography
            component="a"
            target="_blank"
            rel="noopener"
            variant="labelSmall"
            color="primaryPalette.500"
            href="https://foratable.uservoice.com/knowledgebase/articles/2018303-schichten-ausnahmen-befristete-schichten-event"
          >
            {t('schedule.shifts.navigation.help_link', 'Read documentation')}
          </Typography>
          <ExternalLinkIcon color="primaryPalette.500" />
        </Stack>
      </NavigationSection>
    </Stack>
  )
}

export default FormNavigation
