import { forwardRef, useRef, useState } from 'react'
import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  outlinedInputClasses,
  Stack,
  type FormControlProps,
  type InputBaseComponentProps,
} from '@mui/material'

import useOnClickOutsideEffect from '../../dialogs/hooks/useOnClickOutsideEffect'

interface OutlinedListProps extends Omit<FormControlProps, 'error'> {
  label: React.ReactNode
  error?: string
}

const CustomStack = forwardRef<HTMLInputElement, InputBaseComponentProps>(
  (props, ref) => (
    <Stack
      ref={ref}
      component="section"
      gap={0.5}
      divider={<Divider sx={{ opacity: '0.6' }} />}
      direction="column"
      {...props}
    />
  ),
)

const OutlinedList = ({
  label,
  error,
  children,
  ...props
}: OutlinedListProps) => {
  const [focused, setFocused] = useState(false)

  const outlineRef = useRef<HTMLInputElement>()

  useOnClickOutsideEffect(outlineRef, () => setFocused(false))

  return (
    <FormControl fullWidth focused={focused} error={!!error} {...props}>
      <InputLabel shrink variant="outlined">
        {label}
      </InputLabel>
      <OutlinedInput
        onClick={() => setFocused(true)}
        label={label}
        ref={outlineRef}
        multiline
        notched
        sx={{
          [`.${outlinedInputClasses.input}`]: { py: '9px' },
          cursor: 'default',
          overflow: 'visible',
        }}
        inputComponent={CustomStack}
        inputProps={{
          children,
        }}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default OutlinedList
