import { andThen, composeWith, prop } from 'ramda'

import { z } from 'zod'

import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import { type IntegrationName } from './integrationsApi'

const ConfigurationSchemas = {
  lightspeed: z.object({
    authorizationUrl: z.string().url(),
    businessLocations: z.array(
      z.object({
        id: z.coerce.string(),
        name: z.string(),
      }),
    ),
  }),
} satisfies Record<IntegrationName, unknown>

const getIntegrationFlowSchema = (integrationName: IntegrationName) =>
  z.object({
    id: z.string().uuid(),
    status: z.enum(['unauthenticated', 'unauthorized', 'success']),
    integrationName: z.literal(integrationName),
    configuration: ConfigurationSchemas[integrationName],
  })

export type IntegrationFlow = z.infer<
  ReturnType<typeof getIntegrationFlowSchema>
>

export const startIntegrationFlow =
  (httpClient: ApiClient) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async (data: { integrationName: IntegrationName }) =>
    composeWith(andThen)([
      (d: unknown) => getIntegrationFlowSchema(data.integrationName).parse(d),
      prop('integrationFlow'),
      convertKeysToCamelCase,
      httpClient,
    ])({
      method: 'POST',
      url: '',
      json: convertKeysToSnakeCase(data),
    })

export const getIntegrationFlow =
  (httpClient: ApiClient) =>
  ({ integrationName, id }: Pick<IntegrationFlow, 'integrationName' | 'id'>) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async () =>
    composeWith(andThen)([
      (d: unknown) => getIntegrationFlowSchema(integrationName).parse(d),
      prop('integrationFlow'),
      convertKeysToCamelCase,
      httpClient,
    ])({
      method: 'GET',
      url: id,
    })
