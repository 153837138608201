import React from 'react'
import { reduce } from 'ramda'

import { type AreaInterface } from 'src/entities/area/types/area'
import { mapMap } from 'src/shared/lib/common/services/functional/functional'
import AreaTabs from '../../../../Settings/Tables/AreaTabs'
import {
  sumOccupancyPax,
  type ReservationOccupancy,
} from '../../../service/occupancy'
import { type Holiday } from '../../TopBar/service/holidays'

interface AreaPickerProps {
  areas: AreaInterface[]
  occupancies: Map<AreaInterface['id'], ReservationOccupancy[]>
  area: AreaInterface | undefined
  onAreaChange: (area: AreaInterface) => void
  currentDate: Date
  holidays: Holiday[]
}

const AreaPicker = ({
  areas,
  occupancies,
  area,
  onAreaChange,
  currentDate,
  holidays,
}: AreaPickerProps) => {
  const seatCountByArea = React.useMemo(
    () => mapMap(reduce(sumOccupancyPax, 0))(occupancies),
    [occupancies],
  )

  return (
    <AreaTabs
      areas={areas}
      selectedArea={area}
      setSelectedArea={onAreaChange}
      counters={seatCountByArea}
      currentDate={currentDate}
      holidays={holidays}
    />
  )
}

export default AreaPicker
