import { styled } from '@mui/material'

import MicroChip from 'src/shared/components/common/MicroChip'

export const ReservationSeatCount = styled(MicroChip)(({ theme }) =>
  theme.unstable_sx({
    typography: 'inherit',
    fontSize: 'inherit',
    pointerEvents: 'none',
    px: 0.5,
    flexShrink: 0,
  }),
)
