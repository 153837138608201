import { Chip, chipClasses, styled } from '@mui/material'

const MicroChip = styled(Chip)({
  height: '16px',
  minWidth: '16px',
  [`.${chipClasses.label}`]: {
    padding: '0',
  },
})

export default MicroChip
