import { filter } from 'ramda'

import { z } from 'zod'

import { createPaginatedResponseSchema } from 'src/shared/lib/api/types/api'
import { isValidPhoneNumber } from 'src/shared/lib/string/services/phoneNumber'
import {
  basicZodErrorHandler,
  fallbackZodErrorHandler,
} from 'src/shared/lib/zod/basicZodErrorHandler'

const emailSchema = z
  .object({
    email: z.string(),
    newsletter: z.boolean(),
    id: z.number().gt(0),
    isExternal: z.boolean(),
    feedback: z.boolean(),
  })
  .catch(basicZodErrorHandler)

export type ApiEmail = z.infer<typeof emailSchema>

const phoneSchema = z
  .object({
    phoneNumber: z.string().refine(isValidPhoneNumber),
    id: z.number().gt(0),
    isExternal: z.boolean(),
  })
  .catch(basicZodErrorHandler)

export type ApiPhone = z.infer<typeof phoneSchema>

export const addressSchema = z
  .object({
    id: z.number().gt(0),
    country: z.string().optional(),
    city: z.string().optional(),
    postalCode: z.string().optional(),
    addressLine1: z.string().optional(),
    isExternal: z.boolean(),
  })
  .refine(
    input =>
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      input.country || input.city || input.postalCode || input.addressLine1,
    input => ({
      message: `Address of ID ${input.id} does not have any address fields filled in.`,
    }),
  )
  .catch(basicZodErrorHandler)

export type ApiAddress = z.infer<typeof addressSchema>

const nameSchema = z.object({
  firstName: z.string().optional().default(''),
  lastName: z.string().optional().default(''),
})

const personalDataSchema = z.object({
  dateOfBirth: z.string().optional(),
  language: z.string().optional().default(''),
  title: z.string().optional().default(''),
  academicTitle: z.string().optional().default(''),
  name: nameSchema.optional().default({}),
  companyName: z.string().optional().default(''),
})

const noteSchema = z
  .object({
    type: z.enum(['general', 'food', 'seating']),
    note: z.string(),
    isExternal: z.boolean(),
  })
  .catch(basicZodErrorHandler)

export type ApiNote = z.infer<typeof noteSchema>

export const customerSchema = z.object({
  id: z.number(),
  title: z.string().optional(),
  academicTitle: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  companyName: z.string().optional(),
  emailAddresses: z
    .array(emailSchema)
    .optional()
    .default([])
    .transform(filter(Boolean)),
  phoneNumbers: z
    .array(phoneSchema)
    .optional()
    .default([])
    .transform(filter(Boolean)),
  addresses: z
    .array(addressSchema)
    .optional()
    .default([])
    .transform(filter(Boolean)),
  vip: z.boolean().optional().default(false),
  blacklisted: z.boolean().optional().default(false),
  personalData: personalDataSchema.optional().default({}),
  isExternal: z.boolean().default(false),
  notes: z.array(noteSchema).optional().default([]).transform(filter(Boolean)),
})

export type ApiCustomer = z.infer<typeof customerSchema>

const customerSearchDataSchema = z.array(z.number())

export const customerPaginatedSearchResponseSchema =
  createPaginatedResponseSchema(customerSearchDataSchema)

const zeroFallbackSchema = z.number().catch(fallbackZodErrorHandler(0))
export const statsSchema = z
  .object({
    reservations: z.object({
      appeared_until_now: zeroFallbackSchema,
      appeared: zeroFallbackSchema,
      cancelled: zeroFallbackSchema,
      noshow: zeroFallbackSchema,
      total_spent: zeroFallbackSchema,
    }),
    feedback: z.object({
      avg_performance: zeroFallbackSchema,
      avg_food: zeroFallbackSchema,
      avg_service: zeroFallbackSchema,
      avg_ambience: zeroFallbackSchema,
      avg_total: zeroFallbackSchema,
    }),
    customer_id: zeroFallbackSchema,
  })
  .transform(stats => ({
    customerId: stats.customer_id,
    past: stats.reservations.appeared_until_now,
    future: stats.reservations.appeared - stats.reservations.appeared_until_now,
    cancelled: stats.reservations.cancelled,
    noShow: stats.reservations.noshow,
    revenue: stats.reservations.total_spent,
    appeared: stats.reservations.appeared,
    feedbackStats: {
      performance: stats.feedback.avg_performance,
      food: stats.feedback.avg_food,
      service: stats.feedback.avg_service,
      ambience: stats.feedback.avg_ambience,
      average: stats.feedback.avg_total,
    },
  }))
