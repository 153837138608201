import { useCallback, useMemo, useState } from 'react'

import { type SimpleDialogProps } from './SimpleDialog'
import useDialog from '../hooks/useDialog'

export const useSimpleDialog = () => {
  const { handleClose, handleOpen, open } = useDialog()

  const [props, setProps] = useState<Partial<SimpleDialogProps>>()

  const showSimpleDialog = useCallback(
    (newProps?: Partial<SimpleDialogProps>) => {
      setProps(newProps)
      handleOpen()
    },
    [handleOpen],
  )

  return useMemo(
    () => ({
      showSimpleDialog,
      simpleDialogProps: { onClose: handleClose, open, ...props },
    }),
    [handleClose, open, props, showSimpleDialog],
  )
}
