/* eslint-disable react-hooks/rules-of-hooks */
import {
  compose,
  curry,
  dropLast,
  head,
  identity,
  last,
  lensPath,
  over,
  reduce,
  sortBy,
  useWith,
} from 'ramda'

import { type ManipulateType } from 'dayjs'

import dayjs, * as dateService from './date'
import * as rangeService from './range'
import { fromTimeRange } from './rangeFactory'
import { fromDate } from './timeRangeFactory'
import type { DefinedRangeInterface } from '../types/range'
import { type TimeRangeInterface } from '../types/timeRange'

const isComplete = (
  timeRange: TimeRangeInterface | null,
): timeRange is DefinedRangeInterface<Date> =>
  !!timeRange?.[0] && !!timeRange?.[1]

export const isValid = (
  timeRange: TimeRangeInterface | null,
): timeRange is DefinedRangeInterface<Date> =>
  isComplete(timeRange) && timeRange[0] < timeRange[1]

const withTimeRange = <T>(
  fn: (a: DefinedRangeInterface<number>, b: DefinedRangeInterface<number>) => T,
) => useWith(fn, [fromTimeRange, fromTimeRange])
export const areOverlapping = withTimeRange(rangeService.rangesOverlap)

export const overlapTime: (
  serviceTime: DefinedRangeInterface<Date>,
  date: Date,
) => boolean = useWith(areOverlapping, [identity, fromDate])

const fromTimestampRange = (
  range: DefinedRangeInterface<number> | null,
): DefinedRangeInterface<Date> | null => {
  if (!range) return null

  return [
    dateService.fromTimestamp(range[0]),
    dateService.fromTimestamp(range[1]),
  ]
}

export const areTouching = withTimeRange(rangeService.rangesTouch)

export const areIntersecting = withTimeRange(rangeService.rangesIntersect)

export const getOverlap = withTimeRange(rangeService.getOverlap)

export const getIntersection = compose(
  fromTimestampRange,
  withTimeRange(rangeService.getIntersection),
)

export const isSinglePointRange =
  (unit: ManipulateType) => (timeRange: TimeRangeInterface) =>
    compose(
      rangeService.isSinglePointRange,
      fromTimeRange,
    )(
      timeRange.map(d =>
        dateService.getStartOf(unit)(d),
      ) as DefinedRangeInterface<Date>,
    )

export const includesTime: (
  serviceTime: DefinedRangeInterface<Date>,
  date: Date,
) => boolean = useWith(areIntersecting, [identity, fromDate])

export const interpolate = (
  timeRange: DefinedRangeInterface<Date>,
  unit: ManipulateType,
  step = 1,
  includeEnd = false,
): Date[] => {
  const dayJsFrom = dayjs(timeRange[0])
  const dayjsUntil = dayjs(timeRange[1])

  const rangeLength = dayjsUntil.diff(dayJsFrom, unit) / step

  const lengthModifier = includeEnd ? 1 : 0

  return Array.from(
    {
      length: Math.abs(rangeLength) + lengthModifier,
    },
    (_, index) => dayJsFrom.add(index * step, unit).toDate(),
  )
}

export const getDuration = (
  timeRange: TimeRangeInterface | null,
  unit: ManipulateType = 'millisecond',
): number => {
  if (!isValid(timeRange)) return 0

  return dayjs(timeRange[1]).diff(dayjs(timeRange[0]), unit)
}

export const compareRanges = (
  rangeA: DefinedRangeInterface<Date>,
  rangeB: DefinedRangeInterface<Date>,
) => rangeService.areRangesEqual(dateService.areDatesEqual)(rangeA, rangeB)

export const moveToDate = (
  timeRange: DefinedRangeInterface<Date>,
  startDate: Date,
): DefinedRangeInterface<Date> => {
  const move = curry(dateService.combineDateWithTime)(startDate)

  return [move(timeRange[0]), move(timeRange[1])]
}

export const split = (
  timeRange: DefinedRangeInterface<Date>,
  nextTimeRange: DefinedRangeInterface<Date> | undefined,
) => {
  const includeLastSlot =
    !nextTimeRange || !areTouching(timeRange, nextTimeRange)

  return interpolate(timeRange, 'minute', 15, includeLastSlot)
}

export const toFormattedString = (
  formatter: (date: Date | null) => string,
  unit: ManipulateType = 'day',
) =>
  rangeService.rangeToString(
    formatter,
    date => date && dateService.getStartOf(unit)(date),
  )

export const timeRangeToString = toFormattedString(
  date => (date ? dateService.formatTime(date) : ''),
  'minute',
)

export const extendBy =
  (count: number, unit: ManipulateType = 'minute') =>
  (range: DefinedRangeInterface<Date>): DefinedRangeInterface<Date> => [
    range[0],
    dateService.addToDate(count, unit, range[1]),
  ]

const endDateLens = lensPath<DefinedRangeInterface<Date>, Date>([1])

export const trimTo =
  (count: number, unit: ManipulateType = 'day') =>
  (range: DefinedRangeInterface<Date>): DefinedRangeInterface<Date> =>
    over(endDateLens, endDate => {
      const start = range[0]
      const proposedEnd = dateService.addToDate(count, unit, start)

      return proposedEnd < endDate ? proposedEnd : endDate
    })(range)

export const mergeAdjecent = (
  ranges: DefinedRangeInterface<Date>[],
): DefinedRangeInterface<Date>[] => {
  const sortedRanges = sortBy(head, ranges)

  return reduce(
    (mergedRanges, range) => {
      const lastRange = last(mergedRanges)!
      const alreadyMerged = dropLast(1, mergedRanges)

      if (!areIntersecting(lastRange, range)) return [...mergedRanges, range]

      return [
        ...alreadyMerged,
        rangeService.joinRanges(lastRange, range),
      ] as DefinedRangeInterface<Date>[]
    },
    [sortedRanges[0]] as DefinedRangeInterface<Date>[],
    sortedRanges,
  )
}
