import { type ApiRoom } from '../../config/types/configApi'
import { type RestaurantInterface } from '../../restaurant/types/restaurant'
import { type RoomInterface } from '../types/room'

export const fromApi = (rawRoom: ApiRoom): RoomInterface => ({
  id: rawRoom.id,
  name: rawRoom.room_name,
  restaurantId: rawRoom.restaurant_hash,
})

export const createNoRoom = (
  restaurantId: RestaurantInterface['hash'],
): RoomInterface => ({
  id: 0,
  name: 'No Room',
  restaurantId,
})
