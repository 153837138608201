import React from 'react'
import { Box, type BoxProps } from '@mui/material'

import { GRID_STEP, MAX_DIMENSION } from 'src/entities/table/services/tableGrid'
import { useCanvasViewportContext } from 'src/shared/lib/context/state/CanvasViewportContext'
import {
  type FloorPlanElementInterface,
  type SizeInterface,
} from '../types/floorPlanElement'
import { useMaxPosition } from '../useMaxPosition'

export interface StageContainerProps extends BoxProps {
  elements: FloorPlanElementInterface[]
}

const StageContainer = (
  { elements, ...boxProps }: StageContainerProps,
  canvasContainerRef: React.ForwardedRef<Element>,
) => {
  const { zoom, size: minSize } = useCanvasViewportContext()

  const maxSize = useMaxPosition(elements, zoom)

  const size: SizeInterface = {
    height: Math.max(
      minSize.height,
      maxSize.height + MAX_DIMENSION * GRID_STEP,
    ),
    width: Math.max(minSize.width, maxSize.width + MAX_DIMENSION * GRID_STEP),
  }

  return (
    <Box
      ref={canvasContainerRef}
      sx={{
        ...size,
        overflow: 'hidden',
      }}
      {...boxProps}
    />
  )
}

export default React.forwardRef(StageContainer)
