import React from 'react'
import { Box, RadioGroup, Stack, Typography } from '@mui/material'

import { zodResolver } from '@hookform/resolvers/zod'
import { type TFunction } from 'i18next'
import { useController, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { usePaymentProviderQuery } from 'src/entities/payment/queries/paymentProvider'
import {
  useAddPaymentPlanMutation,
  useEditPaymentPlanMutation,
} from 'src/entities/schedule/queries/schedule'
import {
  paymentPlanSchema,
  type PaymentPlan,
} from 'src/entities/schedule/types/scheduleApi'
import { FakeLabel } from 'src/shared/components/form/common/FakeLabel/FakeLabel'
import FormErrorText from 'src/shared/components/form/common/FormErrorText/FormErrorText'
import Checkbox from 'src/shared/components/form/inputs/Checkbox'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'
import { RadioOption } from 'src/shared/components/form/inputs/RadioChoice'
import StepInput from 'src/shared/components/form/inputs/StepInput'
import TextInputWithUnit from 'src/shared/components/form/inputs/TextInputWithUnit'
import {
  fromIsoDuration,
  toIsoDuration,
} from 'src/shared/lib/range/services/date'
import FormContainer from '../FormContainer'
import FormSection from '../FormSection'
import { useDefaultMutationHandlers } from '../hooks/useDefaultMutationHandlers'

export interface PaymentPlanFormProps {
  onNavigateBack: () => void
  paymentPlan?: PaymentPlan
}

const createLabels = (t: TFunction) => ({
  formTitle: {
    create: t('schedule.payment_plan.create_title', {
      defaultValue: 'Create Payment Plan',
      tDescription: 'Title for the payment plan creation form',
    }),
    edit: t('schedule.payment_plan.edit_title', {
      defaultValue: 'Edit Payment Plan',
      tDescription: 'Title for the payment plan edit form',
    }),
  },
  generalSectionTitle: t('schedule.payment_plan.general_section_title', {
    defaultValue: 'General Settings',
    tDescription:
      'Title for the general settings section in the payment plan form',
  }),
  additionalSectionTitle: t('schedule.payment_plan.additional_section_title', {
    defaultValue: 'Additional Online Options',
    tDescription:
      'Title for the additional online options section in the payment plan form',
  }),
  nameInputLabel: t('schedule.payment_plan.general_section.name.label', {
    defaultValue: 'Name',
    tDescription: 'Label for the name fieldName in the payment plan form',
  }),
  nameInputPlaceholder: t(
    'schedule.payment_plan.general_section.name.placeholder',
    {
      defaultValue: 'Eg. Lorem Ipsum',
      tDescription:
        'Placeholder for the name fieldName in the payment plan form',
    },
  ),
  pricePerPersonInputLabel: t(
    'schedule.payment_plan.general_section.price_per_person.label',
    {
      defaultValue: 'Price per person',
      tDescription:
        'Label for the price per person fieldName in the payment plan form',
    },
  ),
  expireLinkAfterInputLabel: t(
    'schedule.payment_plan.general_section.expire_link_after.label',
    {
      defaultValue: 'Expire link after',
      tDescription:
        'Label for the expire link after fieldName in the payment plan form',
    },
  ),
  expireLinkAfterInputTooltip: t(
    'schedule.payment_plan.general_section.expire_link_after.tooltip',
    {
      defaultValue: 'TOOLTIP, WEEEE!!',
      tDescription:
        'Tooltip for the expire link after fieldName in the payment plan form',
    },
  ),
  minGroupSizeInputLabel: t(
    'schedule.payment_plan.additional_section.min_group_size.label',
    {
      defaultValue: 'Minimum group size',
      tDescription:
        'Label for the minimum group size fieldName in the payment plan form',
    },
  ),
  applyOnlyToNewCustomersInputLabel: t(
    'schedule.payment_plan.additional_section.apply_only_to_new_customers.label',
    {
      defaultValue: 'First time visitor only',
      tDescription:
        'Label for first time visitor only fieldName in the payment plan form',
    },
  ),
  excludeVipsInputLabel: t(
    'schedule.payment_plan.additional_section.exclude_vips.label',
    {
      defaultValue: 'Exclude VIPs',
      tDescription:
        'Label for the exclude VIPs fieldName in the payment plan form',
    },
  ),
})

export const paymentPlanFormSchema = paymentPlanSchema
  .omit({
    id: true,
    linkTtl: true,
  })
  .merge(z.object({ linkTtl: z.number().min(1) }))

export type PaymentPlanFormValues = z.infer<typeof paymentPlanFormSchema>

const PaymentPlanForm = ({
  onNavigateBack,
  paymentPlan,
}: PaymentPlanFormProps) => {
  const { t } = useTranslation()

  const { data: paymentProvider } = usePaymentProviderQuery()

  const {
    handleSubmit,
    register,
    control,
    formState: { errors, defaultValues },
  } = useForm<PaymentPlanFormValues>({
    resolver: zodResolver(paymentPlanFormSchema),
    defaultValues: {
      name: '',
      firstTimeVisitorsOnly: false,
      excludeVips: false,
      minimumGroupSize: 1,
      ...paymentPlan,
      linkTtl: paymentPlan
        ? fromIsoDuration(paymentPlan.linkTtl, 'minutes')
        : 60,
    },
  })

  const { mutateAsync: add } = useAddPaymentPlanMutation()
  const { mutateAsync: edit } = useEditPaymentPlanMutation()
  const handlers = useDefaultMutationHandlers(onNavigateBack)

  const labels = React.useMemo(() => createLabels(t), [t])

  const { field: minGroupSizeField } = useController({
    name: 'minimumGroupSize',
    control,
  })

  const { field: firstTimeVisitorsOnlyField } = useController({
    name: 'firstTimeVisitorsOnly',
    control,
  })

  const { field: excludeVipsField } = useController({
    name: 'excludeVips',
    control,
  })

  const { field: linkTtlField } = useController({
    name: 'linkTtl',
    control,
  })

  return (
    <FormContainer
      title={paymentPlan ? labels.formTitle.edit : labels.formTitle.create}
      onNavigateBack={onNavigateBack}
      onSave={handleSubmit(data => {
        const dto = {
          ...data,
          amountPerPerson: data.amountPerPerson,
          linkTtl: toIsoDuration(data.linkTtl, 'minutes'),
        } satisfies Omit<PaymentPlan, 'id'>
        return paymentPlan
          ? edit({ ...dto, id: paymentPlan.id }, handlers)
          : add(dto, handlers)
      })}
    >
      <FormSection title={labels.generalSectionTitle}>
        <RadioGroup
          sx={{
            gap: 1.5,
          }}
          defaultValue={defaultValues?.type}
          {...register('type')}
        >
          <FakeLabel sx={{ mb: 0 }}>
            {t('payment_plan.type_label', 'Type')}
          </FakeLabel>
          <Stack gap={0.5}>
            <RadioOption
              value={'no_show_fee' satisfies PaymentPlan['type']}
              label={t('payment_plan.type.no_show_fee.name', 'No-show fee')}
              {...register('type')}
            />
            <Typography ml={4.5}>
              {t(
                `payment_plan.type.no_show_fee.description`,
                'Guest will be charged only in case of no-show or late cancellation.',
              )}
            </Typography>
          </Stack>
          <Stack gap={0.5}>
            <RadioOption
              value={'pre_payment' satisfies PaymentPlan['type']}
              label={t('payment_plan.type.pre_payment.name', 'Pre-payment')}
              {...register('type')}
            />
            <Typography ml={4.5}>
              {t(
                `payment_plan.type.pre_payment.description`,
                'Guest will be charged when reservation is created.',
              )}
            </Typography>
          </Stack>
          <FormErrorText
            errorText={
              !!errors.type &&
              t(
                'schedule.payment_plan.errors.select_type',
                'Please select payment plan type.',
              )
            }
          />
        </RadioGroup>
        <FormTextInput
          {...register('name')}
          label={labels.nameInputLabel}
          placeholder={labels.nameInputPlaceholder}
          errorText={
            !!errors.name &&
            t('common.validation.required', 'This field cannot be empty')
          }
        />
        <Box sx={{ width: 160 }}>
          <TextInputWithUnit
            {...register('amountPerPerson', { valueAsNumber: true })}
            unit={paymentProvider?.currency ?? ''}
            label={labels.pricePerPersonInputLabel}
            inputMode="numeric"
            type="number"
            placeholder="0"
            defaultValue={defaultValues?.amountPerPerson ?? ''}
            errorText={
              !!errors.amountPerPerson &&
              t('common.validation.required', 'This field cannot be empty')
            }
          />
        </Box>
        <Box sx={{ width: 200 }}>
          <StepInput
            value={linkTtlField.value}
            onChange={linkTtlField.onChange}
            unit="min"
            sx={{ width: 168 }}
            maxValue={4320}
            label={labels.expireLinkAfterInputLabel}
            tooltipText={labels.expireLinkAfterInputTooltip}
            errorText={
              !!errors.linkTtl &&
              t('common.validation.required', 'This field cannot be empty')
            }
          />
        </Box>
      </FormSection>
      <FormSection title={labels.additionalSectionTitle}>
        <Box sx={{ width: 160 }}>
          <StepInput
            label={labels.minGroupSizeInputLabel}
            minValue={1}
            {...minGroupSizeField}
          />
        </Box>
        <Checkbox
          label={labels.applyOnlyToNewCustomersInputLabel}
          checked={firstTimeVisitorsOnlyField.value}
          {...firstTimeVisitorsOnlyField}
        />
        <Checkbox
          label={labels.excludeVipsInputLabel}
          checked={excludeVipsField.value}
          {...excludeVipsField}
        />
      </FormSection>
    </FormContainer>
  )
}

export default PaymentPlanForm
