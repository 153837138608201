import { useActivityLogSseEffect } from 'src/entities/activity-log/queries/activityLog'
import {
  useServiceTimeNoteUpdatedSseEffect,
  useShiftsChangedSseEffect,
} from 'src/entities/shift-instance/queries/shift'
import { useTableSseEffect } from 'src/entities/table/queries/table'
import { useReservationsSseEffect } from 'src/shared/lib/api/sse/useReservationsSseEffect'
import { useSetSentryUserEffect } from 'src/shared/lib/context/global/useSetSentryUserEffect'

const CacheManager = () => {
  useReservationsSseEffect()
  useTableSseEffect()
  useActivityLogSseEffect()
  useShiftsChangedSseEffect()
  useServiceTimeNoteUpdatedSseEffect()

  useSetSentryUserEffect()

  return null
}

export default CacheManager
