import { Box, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { isExpired } from 'src/entities/reservation/services/paymentStatus'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { type PaymentPlan } from 'src/entities/schedule/types/scheduleApi'
import CheckmarkIcon from 'src/shared/components/icons/CheckmarkIcon'
import MoneyIcon from 'src/shared/components/icons/MoneyIcon'
import PendingIcon from 'src/shared/components/icons/PendingIcon'
import { useHourMinuteDurationFormatter } from 'src/shared/lib/range/services/hourMinuteDuration'
import { useCurrencyFormatter } from 'src/shared/lib/string/hooks/useCurrencyFormatter'
import { useTickingCurrentTime } from '../../../Reservations/components/Timeline/hooks/useTickingCurrentTime'

interface PaymentPlanDisplayProps {
  paymentPlans: PaymentPlan[]
  reservation: ReservationInterface
}

const Status = ({ reservation }: { reservation: ReservationInterface }) => {
  const currentTime = useTickingCurrentTime()

  if (!reservation.payment) return null

  const { expirationDate } = reservation.payment

  if (isExpired(() => currentTime)(reservation.payment))
    return <PaymentExpiredMessage />

  if (reservation.payment.status === 'pending' && !!expirationDate)
    return (
      <PaymentPendingMessage
        type={reservation.payment.type}
        expirationDate={expirationDate}
        currentTime={currentTime}
      />
    )

  if (reservation.payment.status === 'provided')
    return <PaymentSuccessfulMessage />

  if (reservation.payment.status === 'charged')
    return <PaymentChargedMessage reservation={reservation} />

  return null
}

export const PaymentPlanDisplay = ({
  reservation,
  paymentPlans,
}: PaymentPlanDisplayProps) => {
  const { t } = useTranslation()

  const formatCurrency = useCurrencyFormatter()

  if (!reservation.payment) return null

  const { amountPerPerson, currency, paymentPlanId } = reservation.payment

  return (
    <Box>
      <Typography fontWeight="bold">
        {paymentPlans.find(p => p.id === paymentPlanId)?.name ??
          t('payment_plan.deleted_plan')}
      </Typography>
      <Typography>
        {t('payment_plan.amount_per_person', {
          price: formatCurrency(currency)(amountPerPerson),
          defaultValue: '{{price}}/person',
        })}
      </Typography>
      <Stack direction="row" gap={0.5} alignItems="flex-start">
        <Status reservation={reservation} />
      </Stack>
    </Box>
  )
}

const iconProps = {
  variant: 'outlined',
  size: 'small',
  mt: '1.5px',
} as const

function PaymentPendingMessage({
  type,
  expirationDate,
  currentTime,
}: {
  type: PaymentPlan['type']
  expirationDate: Date
  currentTime: Date
}) {
  const { t } = useTranslation()

  const format = useHourMinuteDurationFormatter()

  const message =
    type === 'no_show_fee'
      ? t('payment_plan.credit_card_not_provided', {
          remaining: format({
            from: currentTime,
            to: expirationDate,
          }),
          defaultValue: 'Credit card not provided ({{remaining}} left)',
        })
      : t('payment_plan.pre_payment_pending', {
          remaining: format({
            from: currentTime,
            to: expirationDate,
          }),
          defaultValue: 'Pre-payment pending ({{remaining}} left)',
        })

  return (
    <>
      <PendingIcon {...iconProps} />
      <Typography>{message}</Typography>
    </>
  )
}

function PaymentSuccessfulMessage() {
  const { t } = useTranslation()

  return (
    <>
      <CheckmarkIcon {...iconProps} />
      <Typography>
        {t('payment_plan.credit_card_provided', {
          defaultValue: 'Credit card provided',
        })}
      </Typography>
    </>
  )
}

function PaymentExpiredMessage() {
  const { t } = useTranslation()

  return (
    <Typography>
      {t('payment_plan.payment_link_expired', {
        defaultValue: 'Link expired',
      })}
    </Typography>
  )
}

function PaymentChargedMessage({
  reservation: { payment, seatCount },
}: {
  reservation: ReservationInterface
}) {
  const { t } = useTranslation()

  const currencyFormatter = useCurrencyFormatter()

  const message =
    payment?.type === 'pre_payment'
      ? t('payment_plan.pre_payment_charged', 'pre_payment paid ({{amount}})', {
          amount: currencyFormatter(payment.currency)(
            seatCount * payment.amountPerPerson,
          ),
        })
      : t('payment_plan.fee_charged', 'Fee charged')

  return (
    <>
      <MoneyIcon {...iconProps} />
      <Typography>{message}</Typography>
    </>
  )
}
