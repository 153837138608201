import { type CustomerInterface } from '../../customer/types/customer'
import { type GuestInterface } from '../types/reservation'

const nullGuest: GuestInterface = {
  customerId: null,
  firstName: '',
  lastName: 'Walk-In',
  companyName: '',
  phoneNumber: '',
  email: '',
  vip: false,
  ban: false,
  comment: '',
  preferences: {
    food: '',
    place: '',
  },
  languageCode: '',
  birthdate: null,
  notes: [],
  externalNotes: [],
}

export const guestFlagsFromCustomer = (
  customer: CustomerInterface,
): Pick<
  GuestInterface,
  | 'vip'
  | 'ban'
  | 'comment'
  | 'preferences'
  | 'languageCode'
  | 'birthdate'
  | 'notes'
  | 'externalNotes'
> => ({
  vip: customer.vip,
  ban: customer.ban,
  comment: customer.comment,
  preferences: customer.preferences,
  languageCode: customer.languageCode,
  birthdate: customer.birthdate,
  notes: customer.notes,
  externalNotes: customer.externalNotes,
})

export const guestFromCustomer = (
  customer?: CustomerInterface,
): GuestInterface => {
  if (!customer) return nullGuest
  return {
    ...guestFlagsFromCustomer(customer),
    customerId: customer.id,
    firstName: customer.firstName,
    lastName: customer.lastName,
    companyName: customer.companyName,
    phoneNumber: customer.phoneNumbers[0]?.phone ?? '',
    email: customer.emails[0]?.email ?? '',
  }
}
