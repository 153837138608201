import { createFileRoute } from '@tanstack/react-router'

import Areas from 'src/pages/Settings/Areas'
import { ConfigScroll } from './-ConfigScroll'

export const Route = createFileRoute('/restaurants/$restaurantId/config/areas')(
  {
    component: RouteComponent,
  },
)

function RouteComponent() {
  return (
    <ConfigScroll>
      <Areas />
    </ConfigScroll>
  )
}
