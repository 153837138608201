import React from 'react'
import { compose } from 'ramda'

import { type Dayjs, type ManipulateType } from 'dayjs'

import {
  changeSelection,
  selectionStateFromTimeRange,
  selectionTimeRangeFactory,
} from './service/dateRangePicker'
import { type SelectionState } from './types/selectionState'
import dayjs from '../../../../lib/range/services/date'
import { type DefinedRangeInterface } from '../../../../lib/range/types/range'

export const useDateRangePickerState = (
  value: DefinedRangeInterface<Date> | null,
  onChange: (newValue: DefinedRangeInterface<Date> | null) => void,
  unit: ManipulateType,
): {
  selectionState: SelectionState
  selectionHandler: (day: Dayjs | null) => void
  selectedValues: Dayjs[]
} => {
  const [selectionState, setSelectionState] = React.useState<SelectionState>(
    selectionStateFromTimeRange(unit)(value)(null),
  )

  React.useEffect(() => {
    setSelectionState(selectionStateFromTimeRange(unit)(value))
  }, [value, unit])

  const selectionHandler = React.useCallback(
    (day: Dayjs | null) => {
      compose(
        onChange,
        selectionTimeRangeFactory(unit),
        changeSelection(day, unit),
      )(selectionState)
    },
    [onChange, unit, selectionState],
  )

  const selectedValues = React.useMemo(() => (value ?? []).map(dayjs), [value])

  return {
    selectionState,
    selectionHandler,
    selectedValues,
  }
}
