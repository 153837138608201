import { andThen, composeWith, prop } from 'ramda'

import type { SetRequired } from 'type-fest'

import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import {
  rolesSchema,
  teamMemberSchema,
  type RoleDefinition,
  type TeamMember,
} from '../types/teamMember'

const LOGIN_API_PATH = 'checkPin'
const authorizeTeamMember =
  (httpClient: ApiClient) => (teamMemberId: number, pin: string) =>
    httpClient({
      url: LOGIN_API_PATH,
      json: {
        team_id: teamMemberId,
        pin,
      },
    })

export default authorizeTeamMember

export const getTeamMembers =
  (httpClient: ApiClient) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async () =>
    composeWith(andThen)([
      (d: unknown) => teamMemberSchema.array().parse(d),
      convertKeysToCamelCase,
      prop('data'),
      httpClient,
    ])({
      method: 'GET',
      url: `members`,
    })

interface AddEditDto extends TeamMember {
  newPin?: string
}

export const addTeamMember =
  (httpClient: ApiClient) => (teamMember: Omit<AddEditDto, 'id'>) =>
    httpClient({
      method: 'POST',
      url: 'members',
      json: convertKeysToSnakeCase(teamMember),
    })

export const editTeamMember =
  (httpClient: ApiClient) =>
  ({ id, ...data }: SetRequired<Partial<AddEditDto>, 'id'>) =>
    httpClient({
      method: 'PUT',
      url: `members/${id}`,
      json: convertKeysToSnakeCase(data),
    })

export const deleteTeamMember =
  (httpClient: ApiClient) =>
  ({ id }: TeamMember) =>
    httpClient({
      method: 'DELETE',
      url: `members/${id}`,
    })

export const getTeamRoles =
  (httpClient: ApiClient) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async () =>
    composeWith(andThen)([
      (d: unknown) => rolesSchema.parse(d),
      convertKeysToCamelCase,
      prop('data'),
      httpClient,
    ])({
      method: 'GET',
      url: `roles`,
    })

export const updateTeamRoles =
  (httpClient: ApiClient) => (roles: RoleDefinition[]) =>
    httpClient({
      method: 'PUT',
      url: 'roles',
      json: { roles },
    }) as Promise<{ status: string }>
