import { useCallback, useMemo, useState } from 'react'

import { type PermissionDialogProps } from './PermissionDialog'
import useDialog from '../hooks/useDialog'

export const usePermissionDialog = () => {
  const { handleClose, handleOpen, open } = useDialog()

  const [props, setProps] = useState<Partial<PermissionDialogProps>>()

  const showPermissionDialog = useCallback(
    (newProps?: Partial<PermissionDialogProps>) => {
      setProps(newProps)
      handleOpen()
    },
    [handleOpen],
  )

  return useMemo(
    () => ({
      showPermissionDialog,
      permissionDialogProps: { onClose: handleClose, open, ...props },
    }),
    [handleClose, open, props, showPermissionDialog],
  )
}
