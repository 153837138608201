import { type ComponentProps } from 'react'

import { useTranslation } from 'react-i18next'

import { LoadingButton } from 'src/shared/components/buttons/LoadingButton/LoadingButton'
import LoadingIndicator from './LoadingIndicator'

const SaveButton = (
  props: Omit<
    ComponentProps<typeof LoadingButton>,
    'variant' | 'color' | 'size' | 'loadingIndicatior'
  >,
) => {
  const { t } = useTranslation('', { keyPrefix: 'serial_reservations' })

  return (
    <LoadingButton
      {...props}
      variant="contained"
      color="primary"
      size="small"
      loadingIndicator={<LoadingIndicator />}
    >
      {t('saveReservations')}
    </LoadingButton>
  )
}

export default SaveButton
