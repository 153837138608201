import { type TFunction } from 'i18next'

import {
  type BookingLock,
  type Schedule,
} from 'src/entities/schedule/types/scheduleApi'
import { type useExceptionDateRangeFormatter } from '../service'
import { getShiftLikeWithRoomName } from '../ShiftSection/service'

interface GetScheduleLockPropertiesParams {
  lock: BookingLock
  schedule: Schedule
  formatDateRange: ReturnType<typeof useExceptionDateRangeFormatter>
  t: TFunction
}

export const getBookingLockProperties = ({
  lock: l,
  schedule,
  formatDateRange,
  t,
}: GetScheduleLockPropertiesParams) => {
  const shiftLike = [...schedule.shifts, ...schedule.events].find(
    s => s.id === l.shiftId,
  )

  if (!shiftLike) return []

  return [
    l.bookingChannels.includes('offline')
      ? t('schedule.booking_lock.online_and_offline')
      : t('schedule.booking_lock.online_only'),

    formatDateRange([l.day, l.day]),

    shiftLike &&
      getShiftLikeWithRoomName({
        shiftLike,
        schedule,
      }),
  ]
}
