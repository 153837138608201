import { always, when } from 'ramda'

import { TimeFiltersEnum } from 'src/entities/reservation/types/reservationSearchFilters'
import {
  getBoundaries,
  getNowInRestaurantTimezone,
} from 'src/shared/lib/range/services/date'
import { toFormattedString } from 'src/shared/lib/range/services/timeRange'
import {
  type DefinedRangeInterface,
  type RangeInterface,
} from 'src/shared/lib/range/types/range'
import {
  type CustomValueSelection,
  type DateSelection,
  type PredefinedValueSelection,
} from '../types/datePicker'

export const allPredefinedValue: PredefinedValueSelection = {
  type: 'predefined',
  value: 'all',
}

export const futurePredefinedValue: PredefinedValueSelection = {
  type: 'predefined',
  value: TimeFiltersEnum.Future,
}

export const pastPredefinedValue: PredefinedValueSelection = {
  type: 'predefined',
  value: TimeFiltersEnum.Past,
}

export const predefinedValues = [
  allPredefinedValue,
  futurePredefinedValue,
  pastPredefinedValue,
] as const

export const customValueFactory = (
  dateRange: DefinedRangeInterface<Date>,
): CustomValueSelection => ({
  type: 'custom',
  dateRange,
})

export const isPredefinedValue = (
  value: DateSelection,
): value is PredefinedValueSelection => value.type === 'predefined'

export const isCustomValue = (
  value: DateSelection,
): value is CustomValueSelection => value.type === 'custom'

export const isFuturePredefinedValue = (
  value: DateSelection,
): value is PredefinedValueSelection =>
  isPredefinedValue(value) && value.value === TimeFiltersEnum.Future

export const isPastPredefinedValue = (
  value: DateSelection,
): value is PredefinedValueSelection =>
  isPredefinedValue(value) && value.value === TimeFiltersEnum.Past

export const dateRangeFromCustomSelection = (
  selection: DateSelection,
): DefinedRangeInterface<Date> | null => {
  if (!isCustomValue(selection)) return null

  const range = selection.dateRange

  return [
    range[0] ? getBoundaries(range[0])[0] : range[0],
    range[1] ? getBoundaries(range[1])[1] : range[1],
  ]
}

export const futureFactory = (): RangeInterface<Date> => [
  getNowInRestaurantTimezone(),
  null,
]
export const pastFactory = (): RangeInterface<Date> => [
  null,
  getNowInRestaurantTimezone(),
]

export const dateRangeFromSelection = (
  selection: DateSelection,
): DefinedRangeInterface<Date> | 'future' | 'past' | null => {
  if (isFuturePredefinedValue(selection)) return 'future'
  if (isPastPredefinedValue(selection)) return 'past'
  if (isCustomValue(selection)) return dateRangeFromCustomSelection(selection)

  return null
}

export const predefinedValueFromDateSelection = when(
  isCustomValue,
  always(null),
)

export const formatValue =
  (
    dateFormatter: (date: Date | null) => string,
    predefinedValueFormatter: (
      value: PredefinedValueSelection['value'],
    ) => string,
  ) =>
  (selection: DateSelection) => {
    if (isCustomValue(selection))
      return toFormattedString(dateFormatter)(
        dateRangeFromCustomSelection(selection),
      )

    if (isPredefinedValue(selection))
      return predefinedValueFormatter(selection.value)

    return ''
  }

export const selectionFromRange = (
  range: RangeInterface<Date> | 'future' | 'past' | null,
) => {
  if (range === null) return allPredefinedValue
  if (range === 'future') return futurePredefinedValue
  if (range === 'past') return pastPredefinedValue
  if (range[0] === null) return pastPredefinedValue
  if (range[1] === null) return futurePredefinedValue

  return customValueFactory(range)
}
