import { curry, identity } from 'ramda'

import dayjs, { type Dayjs, type ManipulateType } from 'dayjs'

import {
  addToDate,
  getBoundaries,
  isInFuture,
} from 'src/shared/lib/range/services/date'
import {
  isSinglePointRange,
  isValid,
} from 'src/shared/lib/range/services/timeRange'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import { type TimeRangeInterface } from 'src/shared/lib/range/types/timeRange'
import {
  type FromSelection,
  type ModifiedRangeSelection,
  type NoSelection,
  type RangeSelection,
  type SelectionState,
} from '../types/selectionState'

const isNoSelection = (state: SelectionState): state is NoSelection =>
  state.type === 'noSelection'

export const isFromSelection = (
  state: SelectionState,
): state is FromSelection => state.type === 'firstSelection'

export const isRangeSelection = (
  state: SelectionState,
): state is RangeSelection => state.type === 'rangeSelection'

export const isModifiedRangeSelection = (
  state: SelectionState,
): state is ModifiedRangeSelection => state.type === 'modifiedRangeSelection'

export const noSelection: NoSelection = { type: 'noSelection' }

const initSelection = (day: Dayjs): FromSelection => ({
  type: 'firstSelection',
  from: day,
})

const changeFrom =
  (day: Dayjs) =>
  (state: RangeSelection): ModifiedRangeSelection => ({
    ...state,
    type: 'modifiedRangeSelection',
    from: day,
  })

const selectRange =
  (day: Dayjs) =>
  (state: FromSelection | ModifiedRangeSelection): RangeSelection => ({
    ...state,
    type: 'rangeSelection',
    to: day,
  })

const isChangeInFuture =
  (day: Dayjs, unit: ManipulateType) => (newDate: Dayjs) => {
    const isDayInFuture = curry(isInFuture(unit))(newDate.toDate())

    return isDayInFuture(day.toDate())
  }

export const changeSelection = (
  day: Dayjs | null,
  unit: ManipulateType = 'day',
) => {
  if (!day) return identity

  const isDayInFuture = isChangeInFuture(day, unit)

  return (state: SelectionState): SelectionState => {
    if (isNoSelection(state)) return initSelection(day)

    if (isFromSelection(state) || isModifiedRangeSelection(state)) {
      if (isDayInFuture(state.from)) return selectRange(day)(state)

      return initSelection(day)
    }
    if (isRangeSelection(state)) {
      if (isDayInFuture(state.to)) return initSelection(day)

      return changeFrom(day)(state)
    }

    return state
  }
}

const timeRangeFromIncompleteSelection =
  (unit: ManipulateType) =>
  (state: FromSelection): DefinedRangeInterface<Date> =>
    getBoundaries(state.from.toDate(), unit)

const timeRangeFromRangeSelection =
  (unit: ManipulateType) =>
  (
    state: RangeSelection | ModifiedRangeSelection,
  ): DefinedRangeInterface<Date> => [
    getBoundaries(state.from.toDate(), unit)[0],
    getBoundaries(state.to.toDate(), unit)[1],
  ]

export const selectionTimeRangeFactory =
  (unit: ManipulateType) => (state: SelectionState) => {
    if (isFromSelection(state))
      return timeRangeFromIncompleteSelection(unit)(state)
    if (isRangeSelection(state) || isModifiedRangeSelection(state))
      return timeRangeFromRangeSelection(unit)(state)

    return null
  }
const selectionFromSinglePointRange = (timeRange: TimeRangeInterface) =>
  initSelection(dayjs(timeRange[0]))

const selectionFromRange = (timeRange: TimeRangeInterface) =>
  selectRange(dayjs(timeRange[1]))

const modifiedSelectionFromRange = (timeRange: TimeRangeInterface) =>
  changeFrom(dayjs(timeRange[0]))

const selectionFromOldState =
  (timeRange: TimeRangeInterface) => (oldState: SelectionState | null) => {
    if (!oldState) return noSelection
    if (isNoSelection(oldState)) return selectionFromSinglePointRange(timeRange)
    if (isRangeSelection(oldState))
      return modifiedSelectionFromRange(timeRange)(oldState)

    return selectionFromRange(timeRange)(oldState)
  }

export const selectionStateFromTimeRange = (unit: ManipulateType) => {
  const isPointRange = isSinglePointRange(unit)

  return (range: TimeRangeInterface | null) => {
    if (!range) return () => noSelection
    if (isPointRange(range)) return () => selectionFromSinglePointRange(range)
    if (!isValid(range)) return () => noSelection

    return selectionFromOldState(range)
  }
}

export const modifyMonth = (offset: number) => (month: Dayjs) =>
  dayjs(addToDate(offset, 'month', month.toDate()))
