import { both, complement, compose, cond, lens, T, view } from 'ramda'

import type { TFunction } from 'i18next'

import type { Holiday } from 'src/entities/config/types/configApi'

const rangeLens = lens<Holiday, Holiday['range']>(
  h => h.range,
  (range, h) => ({ ...h, range }),
)
const getRange = view(rangeLens)

const roomIdsLens = lens<Holiday, Holiday['roomId']>(
  h => h.roomId,
  (roomId, h) => ({ ...h, roomId }),
)
const getRoomId = view(roomIdsLens)

type IsInWholeRestaurant = (holiday: Holiday) => boolean
const isInWholeRestaurant: IsInWholeRestaurant = complement(getRoomId)

interface TranslateHolidayMessageDeps {
  isSingleDayRange: (range: Holiday['range']) => boolean
}

type GetHolidayTranslationKey = (holiday: Holiday) => string
export const getHolidayTranslationKey = (
  d: TranslateHolidayMessageDeps,
): GetHolidayTranslationKey => {
  const isSingleDate = compose(d.isSingleDayRange, getRange)

  return cond([
    [both(isInWholeRestaurant, isSingleDate), () => 'angular.is_holiday_today'],
    [isInWholeRestaurant, () => 'angular.is_holiday_range'],
    [isSingleDate, () => 'angular.is_holiday_room_today'],
    [T, () => 'angular.is_holiday_room_range'],
  ])
}

interface GetCurrentHolidayDeps {
  isOnSelectedDate: (range: Holiday['range']) => boolean
  getSelectedRoomId: () => Holiday['roomId'] | undefined
}

type GetCurrentHoliday = (holidays: Holiday[]) => Holiday | undefined
export const getCurrentHoliday =
  (d: GetCurrentHolidayDeps): GetCurrentHoliday =>
  holidays => {
    const currentHolidays = holidays.filter(
      compose(d.isOnSelectedDate, getRange),
    )

    const wholeRestaurantHoliday = currentHolidays.find(isInWholeRestaurant)
    const selectedRoomHoliday = currentHolidays.find(
      h => getRoomId(h) === d.getSelectedRoomId(),
    )

    return wholeRestaurantHoliday ?? selectedRoomHoliday
  }

interface GetHolidaysMessageDeps {
  getCurrentHoliday: GetCurrentHoliday
  getTranslationKey: GetHolidayTranslationKey
  formatDate: (date: Date) => string
  t: TFunction
}

type GetHolidaysMessage = (holidays: Holiday[]) => string
export const getHolidaysMessage =
  (d: GetHolidaysMessageDeps): GetHolidaysMessage =>
  holidays => {
    const holiday = d.getCurrentHoliday(holidays)

    if (!holiday) return ''

    return d.t(d.getTranslationKey(holiday), {
      start: d.formatDate(holiday.range[0]),
      end: d.formatDate(holiday.range[1]),
    })
  }
