import React from 'react'
import { Box, Stack } from '@mui/material'

import { type TodoItemTypeEnum } from 'src/entities/notification/types/notification'
import DateFromNow from 'src/shared/components/common/DateFromNow'
import Details from './Details'
import Loader from './Loader'

interface Props {
  reservationId: number
  notificationTimestamp: Date
  notificationType: TodoItemTypeEnum
}

const Body = ({
  reservationId,
  notificationTimestamp,
  notificationType,
}: Props) => (
  <Stack direction="column" justifyContent="space-between">
    <Stack direction="column">
      <React.Suspense fallback={<Loader />}>
        <Details
          notificationType={notificationType}
          reservationId={reservationId}
        />
      </React.Suspense>
    </Stack>
    <Box sx={{ alignSelf: 'flex-end' }}>
      <DateFromNow date={notificationTimestamp} />
    </Box>
  </Stack>
)

export default Body
