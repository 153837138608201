import { z } from 'zod'

import {
  dateSchema,
  durationSchema,
  localizedDateSchema,
  timeSchema,
} from 'src/shared/lib/zod/zod'
import { labelSchema } from '../../config/types/configApi'

export const roomSchema = z.object({
  id: z.number(),
  name: z.string(),
  selectable: z.boolean(),
  position: z.number(),
  hash: z.string(),
})

export const scheduleFeaturesSchema = z.object({
  rooms: z.boolean(),
  paymentPlan: z.boolean(),
  phoneAcceptance: z.boolean(),
})

export const weekdaySchema = z.number().int().min(1).max(7)

export const phoneHourSchema = z.object({
  startTime: timeSchema,
  endTime: timeSchema,
})

export const asyncReservationConfirmationSchema = z.object({
  afterDeadline: z.boolean(),
  beyondPaxLimit: z.boolean(),
})

export const arrivalTimeSchema = z.object({
  firstArrivalTime: timeSchema,
  lastArrivalTime: timeSchema,
  interval: durationSchema,
})

export const nullableTranslatedSchema = z.object({
  de: z.string().nullable(),
  en: z.string().nullable(),
  fr: z.string().nullable(),
  it: z.string().nullable(),
})

const getTranslatedStringSchemaItem =
  (defaultLang?: ScheduleLanguage) => (lang: ScheduleLanguage) =>
    defaultLang === lang ? z.string().min(1) : z.string().nullable()

export const getTranslatedStringSchema = (defaultLang?: ScheduleLanguage) => {
  const getItem = getTranslatedStringSchemaItem(defaultLang)

  return z.object({
    en: getItem('en'),
    de: getItem('de'),
    fr: getItem('fr'),
    it: getItem('it'),
  })
}

export const emptyTranslatedSchema = z.object({
  de: z.unknown().transform(() => null),
  en: z.unknown().transform(() => null),
  fr: z.unknown().transform(() => null),
  it: z.unknown().transform(() => null),
})

export const channelGroupTypeSchema = z.enum(['online', 'offline'])

export const formSchema = z.object({
  question: nullableTranslatedSchema,
  hint: nullableTranslatedSchema,
  options: z.array(nullableTranslatedSchema),
})

export const phoneAcceptanceTimesSchema = z.object({
  monday: z.array(phoneHourSchema),
  tuesday: z.array(phoneHourSchema),
  wednesday: z.array(phoneHourSchema),
  thursday: z.array(phoneHourSchema),
  friday: z.array(phoneHourSchema),
  saturday: z.array(phoneHourSchema),
  sunday: z.array(phoneHourSchema),
})

export const PaymentTypes = ['no_show_fee', 'pre_payment'] as const

export const paymentPlanSchema = z.object({
  id: z.string().uuid(),
  name: z.string().nonempty(),
  type: z.enum(PaymentTypes),
  linkTtl: durationSchema,
  amountPerPerson: z.number(),
  minimumGroupSize: z.number(),
  excludeVips: z.boolean(),
  firstTimeVisitorsOnly: z.boolean(),
})

export const bookingOptionsSchema = z.object({
  bookingWindowStart: durationSchema,
  deadline: z.discriminatedUnion('type', [
    z.object({
      type: z.literal('day-of-reservation'),
      timeOrInterval: timeSchema,
    }),
    z.object({
      type: z.literal('day-before-reservation'),
      timeOrInterval: timeSchema,
    }),
    z.object({
      type: z.literal('time-before-reservation'),
      timeOrInterval: durationSchema,
    }),
  ]),
})

export const limitableChannelsSchema = z.enum(['online', 'google'])

const limitsSchema = z.object({
  minPaxPerReservation: z.number(),
  maxPaxPerReservation: z.number(),
  maxPaxPerShift: z.number(),
  paxLimitPerChannel: z.record(
    limitableChannelsSchema,
    z.object({
      active: z.boolean(),
      limit: z.number(),
    }),
  ),
})

export const shiftMessageSchema = nullableTranslatedSchema.extend({
  displayOnline: z.boolean(),
})

export const pacingSchema = z.object({
  time: timeSchema,
  limit: z.number(),
})

export const notificationValueSchema = z.object({
  type: z.string(),
  channel: z.string(),
  templateId: z.union([z.number(), z.literal('default')]),
})

export const effectivePeriodSchema = z.object({
  start: localizedDateSchema,
  end: localizedDateSchema,
})

export const customPacingSchema = z.object({
  setCustomPacing: z.boolean(),
  slots: z.array(pacingSchema),
})

export const notificationsSchema = z.array(notificationValueSchema)

const notificationTemplateSchema = notificationValueSchema
  .omit({ templateId: true })
  .extend({
    name: z.string().nullable(),
    id: notificationValueSchema.shape.templateId,
  })
  .transform(t => ({
    type: t.type,
    channel: t.channel,
    name: t.name,
    templateId:
      t.id === 'default'
        ? { type: 'default' as const }
        : {
            type: 'custom' as const,
            id: t.id,
          },
  }))

export const notificationTemplatesResponseSchema = z.object({
  templates: z.array(notificationTemplateSchema),
})

export const phoneAcceptanceTimesExceptionSchema =
  phoneAcceptanceTimesSchema.extend({
    id: z.string().uuid(),
    effectivePeriod: effectivePeriodSchema,
  })

export const shiftSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  isActive: z.boolean(),
  weekdays: z.array(weekdaySchema).nonempty(),
  arrivalTimes: arrivalTimeSchema,
  duration: durationSchema,
  bookingOptions: bookingOptionsSchema,
  limits: limitsSchema,
  asyncReservationConfirmation: asyncReservationConfirmationSchema,
  shiftMessage: shiftMessageSchema,
  customPacing: customPacingSchema,
  notifications: notificationsSchema,
  roomId: roomSchema.shape.id.nullable(),
  paymentPlanId: paymentPlanSchema.shape.id.nullable(),
  labelIds: z.array(labelSchema.shape.id),
})

export const shiftOverrideSchema = shiftSchema.omit({
  id: true,
  name: true,
  isActive: true,
  weekdays: true,
  roomId: true,
})

const baseShiftExceptionSchema = z.object({
  id: z.string(),
  effectivePeriod: effectivePeriodSchema,
  shiftId: shiftSchema.shape.id,
})

const disabledShiftExceptionSchema = baseShiftExceptionSchema.extend({
  shiftDisabled: z.literal(true),
  shiftConfig: z.unknown().transform(() => null),
})

const enabledShiftExceptionSchema = baseShiftExceptionSchema.extend({
  shiftDisabled: z.literal(false),
  shiftConfig: shiftOverrideSchema,
})

export const shiftExceptionSchema = z.discriminatedUnion('shiftDisabled', [
  disabledShiftExceptionSchema,
  enabledShiftExceptionSchema,
])

export const shiftScopeSchema = z.object({
  allShifts: z.boolean(),
  shifts: z.array(shiftSchema.shape.id),
  rooms: z.array(roomSchema.shape.id),
})

export const eventSchema = shiftSchema.extend({
  effectivePeriod: effectivePeriodSchema,
})

const validityScopeSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('all'),
  }),
  z.object({
    type: z.literal('room'),
    ids: z.array(roomSchema.shape.id).min(1),
  }),
  z.object({
    type: z.literal('shift'),
    ids: z.array(shiftSchema.shape.id).min(1),
  }),
])

export const reservationQuestionSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  active: z.boolean(),
  mandatory: z.boolean(),
  effectivePeriod: effectivePeriodSchema.nullable(),
  scope: validityScopeSchema,
  form: formSchema,
})

export const closureSchema = z.object({
  id: z.string().uuid(),
  descriptionText: z.string().nullable(),
  disablePhoneHours: z.boolean(),
  showInBook: z.boolean(),
  effectivePeriod: effectivePeriodSchema,
  scope: validityScopeSchema,
})

export const bookingLockSchema = z.object({
  id: z.string().uuid(),
  day: dateSchema,
  shiftId: shiftSchema.shape.id,
  bookingChannels: z.array(channelGroupTypeSchema),
})

const defaultSettingsSchema = z.object({
  ...shiftSchema.pick({
    asyncReservationConfirmation: true,
    duration: true,
  }).shape,
  ...arrivalTimeSchema.pick({ interval: true }).shape,
  ...bookingOptionsSchema.pick({ bookingWindowStart: true }).shape,
  ...limitsSchema.pick({
    minPaxPerReservation: true,
    maxPaxPerReservation: true,
  }).shape,
})

export const scheduleSchema = z.object({
  restaurantId: z.number(),
  defaultSettings: defaultSettingsSchema,
  phoneAcceptanceTimes: phoneAcceptanceTimesSchema,
  rooms: z.array(roomSchema),
  shifts: z.array(shiftSchema),
  shiftExceptions: z.array(shiftExceptionSchema),
  events: z.array(eventSchema),
  locks: z.array(bookingLockSchema),
  closures: z.array(closureSchema),
  bookingEnabled: z.boolean(),
  scheduleFeatures: scheduleFeaturesSchema,
  paymentPlans: z.array(paymentPlanSchema),
  phoneAcceptanceTimesExceptions: z.array(phoneAcceptanceTimesExceptionSchema),
  reservationQuestions: z.array(reservationQuestionSchema),
})

export const scheduleResponseSchema = z.object({
  state: z.string(),
  schedule: scheduleSchema,
})

const mandatoryConflictCommandSchema = z.object({
  actionName: z.string(),
  payload: z.record(z.unknown()),
})

const shortenShiftCommandSchema = mandatoryConflictCommandSchema.extend({
  displayName: z.literal('shorten_shift'),
  additionalDisplayData: z.object({
    id: z.string().uuid().nullable(),
    name: z.string(),
    startTime: timeSchema,
    endTime: timeSchema,
  }),
})

const disableShiftCommandSchema = mandatoryConflictCommandSchema.extend({
  displayName: z.literal('disable_shift'),
  additionalDisplayData: z.object({
    id: z.string().uuid().nullable(),
    name: z.string(),
  }),
})

const selectableConflictCommandSchema = z.discriminatedUnion('displayName', [
  shortenShiftCommandSchema,
  disableShiftCommandSchema,
])

const conflictSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  startDate: localizedDateSchema,
  endDate: localizedDateSchema,
  startTime: timeSchema,
  endTime: timeSchema,
})

const conflictResolutionSchema = z.union([
  z.object({
    conflict: z.null(),
    commands: z.array(mandatoryConflictCommandSchema).min(1),
  }),
  z.object({
    conflict: conflictSchema,
    commands: z.array(selectableConflictCommandSchema).min(1),
  }),
])

export const conflictResolutionResponseSchema = z.object({
  state: z.literal('conflict'),
  resolutions: z.array(conflictResolutionSchema),
})

export const scheduleActionSchema = z.enum([
  'booking_enabled',
  'booking_disabled',
  'event_created',
  'event_modified',
  'event_deleted',
  'restaurant_closure_created',
  'restaurant_closure_modified',
  'restaurant_closure_deleted',
  'shift_lock_created',
  'shift_lock_modified',
  'shift_lock_deleted',
  'payment_plan_created',
  'payment_plan_deleted',
  'payment_plan_feature_activated',
  'payment_plan_feature_deactivated',
  'payment_plan_modified',
  'phone_acceptance_time_exception_created',
  'phone_acceptance_time_exception_deleted',
  'phone_acceptance_time_exception_modified',
  'phone_acceptance_time_feature_activated',
  'phone_acceptance_time_feature_deactivated',
  'phone_acceptance_times_modified',
  'default_settings_modified',
  'room_created',
  'room_deleted',
  'room_feature_activated',
  'room_feature_deactivated',
  'room_modified',
  'schedule_created',
  'shift_created',
  'shift_deleted',
  'shift_exception_created',
  'shift_exception_deleted',
  'shift_exception_modified',
  'shift_modified',
  'reservation_question_created',
  'reservation_question_deleted',
  'reservation_question_modified',
  'bad_weather_tag_created',
  'bad_weather_tag_deleted',
  'bad_weather_mode_feature_activated',
  'bad_weather_mode_feature_deactivated',
])

export const scheduleChangeTargetSchema = z.object({
  type: z.enum(['all', 'room', 'shift', 'none']),
  names: z.array(z.string()).nullable(),
})

export const scheduleActivityLogEntrySchema = z.object({
  id: z.string(),
  when: localizedDateSchema,
  who: z.string(),
  team_member: z.string(),
  action: scheduleActionSchema,
  target: scheduleChangeTargetSchema,
  payload: z.string(),
})

// inferred types:
export type Room = z.infer<typeof roomSchema>

export type ScheduleFeatures = z.infer<typeof scheduleFeaturesSchema>

export type PhoneHour = z.infer<typeof phoneHourSchema>

export type ReservationConfirmation = z.infer<
  typeof asyncReservationConfirmationSchema
>

export type ArrivalTime = z.infer<typeof arrivalTimeSchema>

export type Message = z.infer<typeof nullableTranslatedSchema>

export type ShiftScope = z.infer<typeof shiftScopeSchema>

export type Form = z.infer<typeof formSchema>

export type PhoneAcceptanceTimes = z.infer<typeof phoneAcceptanceTimesSchema>

export type PaymentPlan = z.infer<typeof paymentPlanSchema>

export interface NoPaymentPlan {
  id: null
}

export type BookingOptions = z.infer<typeof bookingOptionsSchema>

export type ShiftMessage = z.infer<typeof shiftMessageSchema>

export type Pacing = z.infer<typeof pacingSchema>

export type Notification = z.infer<typeof notificationValueSchema>

export type NotificationTemplate = z.infer<typeof notificationTemplateSchema>

export type EffectivePeriod = z.infer<typeof effectivePeriodSchema>

export type CustomPacing = z.infer<typeof customPacingSchema>

export type ValidityScope = z.infer<typeof validityScopeSchema>

export type BookingLock = z.infer<typeof bookingLockSchema>

export type Closure = z.infer<typeof closureSchema>

export type PhoneAcceptanceTimesException = z.infer<
  typeof phoneAcceptanceTimesExceptionSchema
>

export type ReservationQuestion = z.infer<typeof reservationQuestionSchema>

export type LimitableChannel = z.infer<typeof limitableChannelsSchema>

export type ShiftOverride = z.infer<typeof shiftOverrideSchema>

export type Shift = z.infer<typeof shiftSchema>

export type DisabledShiftException = z.infer<
  typeof disabledShiftExceptionSchema
>

export type EnabledShiftException = z.infer<typeof enabledShiftExceptionSchema>

export type ShiftException = z.infer<typeof shiftExceptionSchema>

export type ScheduleEvent = z.infer<typeof eventSchema>

export type ScheduleDefaultSettings = z.infer<typeof defaultSettingsSchema>

export type Schedule = z.infer<typeof scheduleSchema>

export type ScheduleResponse = z.infer<typeof scheduleResponseSchema>

export type ScheduleLanguage = keyof Message

export type ConflictResolution = z.infer<typeof conflictResolutionSchema>

export type SelectableConflictCommand = z.infer<
  typeof selectableConflictCommandSchema
>

export type ScheduleActivityLogEntry = z.infer<
  typeof scheduleActivityLogEntrySchema
>

export type ScheduleAction = z.infer<typeof scheduleActionSchema>

export type ScheduleChangeTarget = z.infer<typeof scheduleChangeTargetSchema>
