import { type TFunction } from 'i18next'

export const getReservationQuestionTranslations = (t: TFunction) => ({
  title: {
    create: t('schedule.reservation_question.create_title', {
      defaultValue: 'Create Reservation Question',
      tDescription: 'Title for the reservation question creation form',
    }),
    edit: t('schedule.reservation_question.edit_title', {
      defaultValue: 'Edit Reservation Question',
      tDescription: 'Title for the reservation question creation form',
    }),
  },

  name: {
    label: t('schedule.reservation_question.name_label', {
      defaultValue: 'Name',
      tDescription:
        'Label for the name field in the reservation question creation form',
    }),
    placeholder: t('schedule.reservation_question.placeholder', {
      defaultValue: "Name won't be displayed on widget",
      tDescription:
        'Placeholder for the name field in the reservation question creation form',
    }),
  },

  settings: {
    title: t('schedule.reservation_question.settings_section_title', {
      defaultValue: 'Settings',
      tDescription:
        'Title for the settings section in the reservation question creation form',
    }),
    isActive: t('schedule.reservation_question.active_question_label', {
      defaultValue: 'Active question',
      tDescription:
        'Label for the `active question` switch in the reservation question creation form',
    }),

    validity: {
      title: t('schedule.reservation_question.validity_label', {
        defaultValue: 'Validity',
        tDescription:
          'Label for validity inputs in the reservation question creation form',
      }),
      always: t('schedule.reservation_question.always', {
        defaultValue: 'Always',
        tDescription:
          'Label for `Always` radio button in validity inputs in the reservation question creation form',
      }),
      dateRange: t('schedule.reservation_question.date_range', {
        defaultValue: 'Date Range',
        tDescription:
          'Label for `Always` radio button in validity inputs in the reservation question creation form',
      }),
    },

    validFor: {
      title: t('schedule.reservation_question.valid_shifts_label', {
        defaultValue: 'Valid for',
        tDescription:
          'Label for `valid for` inputs in the reservation question creation form',
      }),
      dateRange: t('schedule.reservation_question.date_range', {
        defaultValue: 'Date Range',
        tDescription:
          'Label for `Always` radio button in validity inputs in the reservation question creation form',
      }),
      selectRoom: t('schedule.reservation_question.room_select_label', {
        defaultValue: 'Select room',
        tDescription:
          'Label for `select room` inputs in the reservation question creation form',
      }),
    },
  },

  form: {
    title: t('schedule.reservation_question.form_section_title', {
      defaultValue: 'Form',
      tDescription:
        'Title for the `form` section in the reservation question creation form',
    }),

    default: t('common.default', {
      defaultValue: 'default',
    }),

    optional: t('common.optional', {
      defaultValue: 'optional',
    }),

    question: t('schedule.reservation_question.question', {
      defaultValue: 'Question',
      tDescription:
        'Label for the `Question` inputs in the reservation question creation form',
    }),

    questionPlaceholder: t(
      'schedule.reservation_question.question_placeholder',
      {
        defaultValue: 'Type your question here',
        tDescription:
          'Placeholder for the default language `Question` input in the reservation question creation form',
      },
    ),

    translationOf: (requiredLanguage: string) =>
      t('common:translation_of', {
        requiredLanguage,
        defaultValue: '{{requiredLanguage}} translation',
        tDescription:
          'Placeholder/label of an input that wants the user to type in the <arbitrary language> translation of something.',
      }),

    missingLanguageDisclaimer: t(
      'schedule.reservation_question.missing_language_disclaimer',
      'Missing translations will be replaced with default language.',
    ),

    hint: t('schedule.reservation_question.hint', {
      defaultValue: 'Hint',
      tDescription:
        'Label for the `Hint` inputs in the reservation question creation form',
    }),

    hintPlaceholder: t('schedule.reservation_question.hint_placeholder', {
      defaultValue:
        'Type additional information fieldName, which is displayed below the selection text.',
      tDescription:
        'Placeholder for the default language `Hint` input in the reservation question creation form',
    }),

    options: t('schedule.reservation_question.options', {
      defaultValue: 'Options',
      tDescription:
        'Label for the `Options` inputs in the reservation question creation form',
    }),

    optionPlaceholder: t('schedule.reservation_question.options_placeholder', {
      defaultValue: 'Option text',
      tDescription:
        'Placeholder for the `Options` input in the reservation question creation form',
    }),

    addMoreOptions: t('schedule.reservation_question.add_more_options', {
      defaultValue: 'Add more options',
      tDescription:
        'Text of the `Add more options` button in the reservation question creation form',
    }),

    noOptions: t('schedule.reservation_question.errors.no_options', {
      defaultValue: 'At least 1 option is required',
      tDescription:
        'Error above of the `Add more options` button in when there are no options',
    }),

    errorWithSection: t(
      'schedule.reservation_question.errors.error_with_section',
      {
        defaultValue: 'There is an error with this section',
        tDescription:
          'This message appears when there is an error with the reservation question and the respective options',
      },
    ),

    mustBeEmpty: t('schedule.reservation_question.errors.must_be_empty', {
      defaultValue: 'This field must be empty',
      tDescription: 'Error when an option is defined but the question is not',
    }),

    isMandatory: t('schedule.reservation_question.mandatory', {
      defaultValue: 'Make question mandatory',
      tDescription:
        'Label for the `Make question mandatory` switch in the reservation question creation form',
    }),
  },

  create: t('common.create', { defaultValue: 'Create' }),
  edit: t('common.edit', { defaultValue: 'Edit' }),
})
