import { Stack } from '@mui/material'

import CloseButton from 'src/shared/components/buttons/CloseButton'
import Title from './Title'

interface HeaderProps {
  onClose: () => void
}

const Header = ({ onClose }: HeaderProps) => (
  <Stack
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    sx={{ p: 1 }}
  >
    <Title />
    <CloseButton onClick={onClose} />
  </Stack>
)

export default Header
