import React from 'react'
import { alpha, Chip, chipClasses, type ChipProps } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import DeleteIcon from './deleteIcon.svg'
import { useColorPalette } from '../../../lib/style/hooks/useColorPalette'

interface LabelProps extends Omit<ChipProps, 'color'> {
  color: string
}

const Label = React.forwardRef<HTMLDivElement, LabelProps>(
  ({ sx, color, ...props }, ref) => {
    const colorPalette = useColorPalette(color)

    const emphasizedColor = alpha(colorPalette.contrastText, 0.8)
    const deleteIconColor = alpha(colorPalette.contrastText, 0.4)

    return (
      <Chip
        ref={ref}
        sx={mergeSx(
          {
            [`&.${chipClasses.outlined}`]: {
              outline: '1px solid',
              outlineColor: '#ffffff',
            },
            backgroundColor: color,
            color: emphasizedColor,
            [`&.${chipClasses.root}:hover`]: {
              backgroundColor: colorPalette.light,
            },
            [`.${chipClasses.deleteIcon}`]: {
              width: '1em',
              height: '1em',
              ml: 1,
              mr: -0.5,
              path: {
                color: deleteIconColor,
                stroke: deleteIconColor,
                strokeWidth: '1px',
              },
            },
          },
          sx,
        )}
        variant="filled"
        deleteIcon={<DeleteIcon />}
        {...props}
      />
    )
  },
)

export default Label
