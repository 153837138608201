import { Stack, Typography } from '@mui/material'

import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useFormattedDate } from 'src/shared/lib/range/hooks/useFormattedDate'
import StatusIndicator from 'src/widgets/Reservation/StatusIndicator'
import Labels from './Labels'

const Reservation = ({
  reservation,
}: {
  reservation: ReservationInterface
}) => (
  <Stack direction="row" spacing={1} alignItems="center">
    <Typography variant="body3">
      {useFormattedDate(reservation.dateRange[0])}
    </Typography>
    <StatusIndicator seatCount={reservation.seatCount} />
    <Labels reservation={reservation} />
  </Stack>
)

export default Reservation
