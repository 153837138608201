import { type ApiTable } from '../../config/types/configApi'
import {
  type ExactModeChangedPayloadInterface,
  type ReservationCreateInterface,
  type ReservationStatusChangeInterface,
  type ReservationUpdateInterface,
} from '../../reservation/types/reservation'
import {
  type OccupanciesUpdateInterface,
  type TableNoteUpdateInterface,
  type TableOccupancyStatusUpdateInterface,
} from '../../table/types/table'

export enum TodoItemTypeEnum {
  New = 'reservation_created',
  Mutated = 'reservation_updated',
  Cancelled = 'reservation_cancelled',
  Moved = 'reservation_moved',
  Reactivated = 'reservation_reactivated',
}

export enum TodoItemStatus {
  Done = 'done',
  Open = 'open',
}

export enum NotificationTypeEnum {
  TodoItemCreated = 'todo_item_created',
  TodoItemStatusChange = 'todo_item_status_changed',
  NotificationsRead = 'todo_items_displayed',
  PhoneCall = 'incoming_call',
  TableUpdated = 'add_table',
  TableRemoved = 'delete_table',
  TableNoteSet = 'table_note_set',
  ReservationCreated = 'new_reservation',
  ReservationUpdated = 'update_reservation',
  ReservationCancelled = 'cancel_reservation',
  ReservationNoShowed = 'noshow_reservation',
  ReservationReShowed = 'reshow_reservation',
  ReservationReactivated = 'reactivate_reservation',
  GuestCheckedOut = 'guest_check_out',
  GuestCheckedIn = 'guest_check_in',
  OccupanciesUpdated = 'reservation_placement_changed',
  ReservationExactModeChanged = 'exact_mode_changed',
  ScheduleUpdated = 'schedule_updated',
  ServiceTimeNoteUpdated = 'service_time_note_set',
}

export interface TodoItem {
  id: string
  reservationId: number
  status: TodoItemStatus
  type: TodoItemTypeEnum
}

export interface TodoItemStatusChange {
  id: string
  reservationId: number
  todoId: string
  status: TodoItemStatus
  type: TodoItemTypeEnum
  date: Date
  all: boolean
}

export interface TodoItemsRead {
  id: string
  date: Date
}

export interface PhoneCall {
  number: string
}

interface BaseNotificationInterface {
  id: string
  date: Date
}

export interface TodoItemNotificationInterface
  extends BaseNotificationInterface {
  type: NotificationTypeEnum.TodoItemCreated
  payload: TodoItem
}

export interface TodoItemStatusChangeNotificationInterface
  extends BaseNotificationInterface {
  type: NotificationTypeEnum.TodoItemStatusChange
  payload: TodoItemStatusChange
}

export interface TodoItemsReadNotificationInterface
  extends BaseNotificationInterface {
  type: NotificationTypeEnum.NotificationsRead
  payload: TodoItemsRead
}

export interface PhoneCallNotificationInterface
  extends BaseNotificationInterface {
  type: NotificationTypeEnum.PhoneCall
  payload: PhoneCall
}

export interface UpdateTableEventInterface extends BaseNotificationInterface {
  type: NotificationTypeEnum.TableUpdated
  payload: ApiTable
}

export interface RemoveTableEventInterface extends BaseNotificationInterface {
  type: NotificationTypeEnum.TableRemoved
  payload: Record<'id', ApiTable['id']>
}

export interface SetTableNoteEventInterface extends BaseNotificationInterface {
  type: NotificationTypeEnum.TableNoteSet
  payload: TableNoteUpdateInterface
}

export interface ReservationUpdateNotificationInterface
  extends BaseNotificationInterface {
  type: NotificationTypeEnum.ReservationUpdated
  payload: ReservationUpdateInterface
}

export interface ReservationStatusNotificationInterface
  extends BaseNotificationInterface {
  type:
    | NotificationTypeEnum.ReservationCancelled
    | NotificationTypeEnum.ReservationReactivated
    | NotificationTypeEnum.ReservationNoShowed
    | NotificationTypeEnum.ReservationReShowed
  payload: ReservationStatusChangeInterface
}

export interface ReservationCreateNotificationInterface
  extends BaseNotificationInterface {
  type: NotificationTypeEnum.ReservationCreated
  payload: ReservationCreateInterface
}

export interface GuestOccupancyStatusNotificationInterface
  extends BaseNotificationInterface {
  type:
    | NotificationTypeEnum.GuestCheckedOut
    | NotificationTypeEnum.GuestCheckedIn
  payload: TableOccupancyStatusUpdateInterface
}

export interface OccupanciesUpdatedInterface extends BaseNotificationInterface {
  type: NotificationTypeEnum.OccupanciesUpdated
  payload: OccupanciesUpdateInterface
}

export interface ExactModeChangedInterface extends BaseNotificationInterface {
  type: NotificationTypeEnum.ReservationExactModeChanged
  payload: ExactModeChangedPayloadInterface
}

export interface ScheduleUpdatedInterface extends BaseNotificationInterface {
  type: NotificationTypeEnum.ScheduleUpdated
  payload: unknown
}

export interface ServiceTimeNoteUpdatedInterface
  extends BaseNotificationInterface {
  type: NotificationTypeEnum.ServiceTimeNoteUpdated
  payload: unknown
}

export type NotificationInterface =
  | TodoItemNotificationInterface
  | TodoItemStatusChangeNotificationInterface
  | TodoItemsReadNotificationInterface
  | PhoneCallNotificationInterface
  | UpdateTableEventInterface
  | RemoveTableEventInterface
  | SetTableNoteEventInterface
  | ReservationCreateNotificationInterface
  | ReservationUpdateNotificationInterface
  | ReservationStatusNotificationInterface
  | GuestOccupancyStatusNotificationInterface
  | OccupanciesUpdatedInterface
  | ExactModeChangedInterface
  | ScheduleUpdatedInterface
  | ServiceTimeNoteUpdatedInterface

type ExtractUnion<
  Type,
  UnionFieldKey extends keyof Type,
> = Type[UnionFieldKey] extends string | number | symbol
  ? {
      [T in Type[UnionFieldKey]]: Type extends infer U
        ? T extends Type[UnionFieldKey]
          ? U
          : never
        : never
    }
  : never

export type SseEventMap = ExtractUnion<NotificationInterface, 'type'>
