import {
  areOnSameDay,
  getNowInRestaurantTimezone,
  roundToNearest,
} from 'src/shared/lib/range/services/date'
import { interpolate } from 'src/shared/lib/range/services/timeRange'
import { fromDay } from 'src/shared/lib/range/services/timeRangeFactory'

export const generateIntervals = (
  showAll: boolean,
  date: Date | undefined = getNowInRestaurantTimezone(),
) => {
  const nearestSlot = roundToNearest(
    'minutes',
    15,
    Math.ceil,
  )(getNowInRestaurantTimezone())
  const isToday = areOnSameDay(date, nearestSlot)
  const fromTime = !showAll && isToday ? nearestSlot : undefined

  return interpolate(fromDay(date, fromTime), 'minutes', 15, true)
}
