import { useCallback } from 'react'
import { equals } from 'ramda'
import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useReservationData } from 'src/entities/reservation/queries/reservation'
import { useOccupanciesMutation } from 'src/entities/reservation/queries/reservationsMutations'
import { getSeatCountDifference } from 'src/entities/reservation/services/occupancy'
import { formatGuestName } from 'src/entities/reservation/services/reservation'
import { useTablesQuery } from 'src/entities/table/queries/table'
import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import { type SelectedReservation } from 'src/shared/lib/context/state/atoms/baseSelectedReservation'
import {
  useClearSelectedReservation,
  usePristineOpenReservationOccupancies,
  useReservationChanged,
  useSelectedReservationField,
} from 'src/shared/lib/context/state/atoms/selectedReservation'
import { formatTime } from 'src/shared/lib/range/services/date'
import { useDrawerColor } from 'src/widgets/SidebarDrawer/drawerColor'
import SidebarHeader from 'src/widgets/SidebarDrawer/SidebarHeader'
import { ReservationSeatCount } from 'src/widgets/Tables/SlotInfo/ReservationSeatCount'
import {
  incompleteTableAssignmentDialog,
  reservationNotCreatedDialog,
  reservationNotSavedDialog,
} from './drawerCloseDialogs'
import EllipsisTypography from '../../Settings/Areas/EllipsisTypography'

export const ReservationDrawerHeader = ({
  reservation,
}: {
  reservation: SelectedReservation
}) => {
  const { data: tables } = useTablesQuery()

  const originalOccupancies = usePristineOpenReservationOccupancies()

  const isReservationModified = useReservationChanged()

  const assignSerie = useSelectedReservationField('assignSerie')

  const onClose = useClearSelectedReservation()

  const hasUnplacedPax =
    !!getSeatCountDifference(tables)(reservation) &&
    !equals(originalOccupancies, reservation.occupancies)

  const { mutateAsync: setOccupancies } = useOccupanciesMutation()

  const rollbackOccupancies = useCallback(
    () =>
      setOccupancies({
        reservation,
        occupancies: originalOccupancies,
        wholeSerie: assignSerie ?? false,
        immediate: true,
      }),
    [originalOccupancies, setOccupancies, reservation, assignSerie],
  )
  const closeWithRollback = useCallback(async () => {
    await rollbackOccupancies()
    onClose()
  }, [rollbackOccupancies, onClose])

  const getReservationData = useReservationData()

  const closeWithImmediateSave = useCallback(() => {
    const cachedReservation = getReservationData(reservation.id)

    if (!cachedReservation) return onClose()

    void setOccupancies({
      reservation: cachedReservation,
      occupancies: reservation.occupancies,
      wholeSerie: assignSerie,
      immediate: true,
    })

    return onClose()
  }, [assignSerie, getReservationData, onClose, reservation, setOccupancies])

  const { t } = useTranslation()

  const isNewReservation = !reservation.id

  const drawerColor = useDrawerColor()

  const { simpleDialogProps, showSimpleDialog } = useSimpleDialog()

  const handleClose = useCallback(() => {
    if (isReservationModified) {
      const dialog = isNewReservation
        ? reservationNotCreatedDialog(t)
        : reservationNotSavedDialog(t, closeWithRollback)

      return showSimpleDialog(dialog)
    }

    if (reservation.occupancies.length && hasUnplacedPax && !isNewReservation)
      return showSimpleDialog(incompleteTableAssignmentDialog(t))

    return closeWithImmediateSave()
  }, [
    isReservationModified,
    reservation.occupancies.length,
    hasUnplacedPax,
    isNewReservation,
    showSimpleDialog,
    t,
    closeWithImmediateSave,
    closeWithRollback,
  ])

  return (
    <>
      <SidebarHeader onClose={handleClose} color={drawerColor}>
        <Typography component="span">
          {formatTime(reservation.dateRange[0])}
        </Typography>
        <ReservationSeatCount color="light" label={reservation.seatCount} />
        <Stack overflow="hidden">
          <EllipsisTypography variant="labelSmall">
            {formatGuestName(reservation.guest)}
          </EllipsisTypography>
          <Typography component="span" lineHeight={1}>
            {reservation.number}
          </Typography>
        </Stack>
      </SidebarHeader>
      <SimpleDialog
        confirmButtonText={t('angular.yes')}
        cancelButtonText={t('angular.no')}
        onConfirmation={closeWithImmediateSave}
        {...simpleDialogProps}
      />
    </>
  )
}
