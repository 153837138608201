import React from 'react'
import { compose } from 'ramda'

import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import debounce from 'debounce'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { useRestaurantCacheKeyFactory } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import useSseEventListenerEffect, {
  type SseEvent,
} from 'src/shared/lib/api/sse/useSseEventListenerEffect'
import { NotificationTypeEnum } from '../../notification/types/notification'
import {
  getActivityLog,
  type GetActivityLogOptions,
} from '../api/activityLogApi'

const ACTIVITY_LOG_CACHE_KEY = ['activityLog']
const useCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(ACTIVITY_LOG_CACHE_KEY)

export const useActivityLogQuery = (options: GetActivityLogOptions) => {
  const apiClient = useApiClient()
  const getCacheKey = useCacheKeyFactory()

  return useSuspenseQuery({
    queryKey: getCacheKey([options.reservationId, options.limit ?? 1000]),
    queryFn: () => getActivityLog(apiClient.post)(options),
  })
}

const getIdFromReservationUpdate = (
  event: SseEvent<
    | NotificationTypeEnum.ReservationUpdated
    | NotificationTypeEnum.ReservationCancelled
    | NotificationTypeEnum.ReservationReactivated
    | NotificationTypeEnum.ReservationNoShowed
    | NotificationTypeEnum.ReservationReShowed
  >,
) => event.data.payload.id

const getIdFromOccupancyUpdate = (
  event: SseEvent<
    | NotificationTypeEnum.OccupanciesUpdated
    | NotificationTypeEnum.GuestCheckedOut
    | NotificationTypeEnum.GuestCheckedIn
  >,
) => event.data.payload.reservationId

export const useActivityLogSseEffect = () => {
  const getCacheKey = useCacheKeyFactory()
  const queryClient = useQueryClient()

  const invalidateActivityLog = React.useMemo(
    () =>
      debounce((reservationId: number) => {
        void queryClient.invalidateQueries({
          queryKey: getCacheKey([reservationId]),
        })
      }, 2000),
    [getCacheKey, queryClient],
  )

  const invalidateFromReservationUpdate = React.useMemo(
    () => compose(invalidateActivityLog, getIdFromReservationUpdate),
    [invalidateActivityLog],
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.ReservationUpdated,
    invalidateFromReservationUpdate,
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.ReservationCancelled,
    invalidateFromReservationUpdate,
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.ReservationReactivated,
    invalidateFromReservationUpdate,
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.ReservationNoShowed,
    invalidateFromReservationUpdate,
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.ReservationReShowed,
    invalidateFromReservationUpdate,
  )

  const invalidateFromOccupancyUpdate = React.useMemo(
    () => compose(invalidateActivityLog, getIdFromOccupancyUpdate),
    [invalidateActivityLog],
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.OccupanciesUpdated,
    invalidateFromOccupancyUpdate,
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.GuestCheckedOut,
    invalidateFromOccupancyUpdate,
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.GuestCheckedIn,
    invalidateFromOccupancyUpdate,
  )
}
