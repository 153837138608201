import { useMutation } from '@tanstack/react-query'

import { apiClient } from 'src/shared/lib/api/services/reservationsBookApiClient'
import { authenticate } from '../services/authentication'

interface Auth {
  username: string
  password: string
}

export const useAuthMutation = () =>
  useMutation({
    mutationFn: async (auth: Auth) =>
      authenticate(apiClient)(auth.username, auth.password),
  })
