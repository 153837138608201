import React from 'react'
import { Stack } from '@mui/material'

import { FormProvider, useForm } from 'react-hook-form'

import IconFormButtons from 'src/shared/components/buttons/IconFormButtons'
import { type FloorPlanTableInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'
import Capacity from '../FloorPlanTableEditForm/Capacity'
import Name from '../FloorPlanTableEditForm/Name'

const TabularTableEditForm = ({
  value,
  onChange,
  onCancel,
  onDelete,
}: {
  value: FloorPlanTableInterface
  onChange: (table: FloorPlanTableInterface) => void
  onDelete: (table: FloorPlanTableInterface) => void
  onCancel: () => void
}) => {
  const form = useForm<FloorPlanTableInterface>({
    defaultValues: value,
  })

  const { reset } = form

  React.useEffect(() => {
    reset(value)
  }, [value, reset])

  return (
    <FormProvider {...form}>
      <Stack spacing={2} direction="column">
        <Name onChange={onChange} />
        <Capacity onChange={onChange} autosize={false} />
        <IconFormButtons onDelete={() => onDelete(value)} onSave={onCancel} />
      </Stack>
    </FormProvider>
  )
}

export default TabularTableEditForm
