import { forwardRef, type RefObject } from 'react'

import { noop } from '@tanstack/react-table'
import type Konva from 'konva'
import { type KonvaEventObject } from 'konva/lib/Node'

import {
  dragElement,
  transformElement,
} from 'src/entities/table/services/tableGrid'
import { type FloorPlanElementProps } from 'src/widgets/FloorPlan/FloorPlanElement'
import TransformableElement from 'src/widgets/FloorPlan/TransformableElement'
import { type FloorPlanElementInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'

interface FurnitureElementProps extends FloorPlanElementProps {
  onChange: (element: FloorPlanElementInterface) => void
}

const FurnitureElement = forwardRef<Konva.Group, FurnitureElementProps>(
  ({ onChange, onSelect, element, ...props }, elementRef) => {
    const dragHandler = ({ target }: KonvaEventObject<DragEvent>) =>
      onChange(dragElement(element)(target))

    const { current: konvaElement } = elementRef as RefObject<Konva.Group>
    const transformHandler = () =>
      konvaElement ? onChange(transformElement(element, konvaElement)) : noop

    return (
      <TransformableElement
        ref={elementRef}
        element={element}
        onSelect={onSelect}
        onDragStart={onSelect}
        onDragEnd={dragHandler}
        onTransformEnd={transformHandler}
        {...props}
      />
    )
  },
)

export default FurnitureElement
