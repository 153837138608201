import { Suspense, useEffect, useState } from 'react'

import { createFileRoute, Outlet } from '@tanstack/react-router'
import { z } from 'zod'

import { useEditReservation } from 'src/entities/reservation/hooks/useEditReservation'
import { useReservation } from 'src/entities/reservation/queries/reservation'
import { ReservationsLayout } from 'src/pages/Reservations/ReservationsLayout'
import {
  useIsReservationSelectedAtom,
  useSelectedReservationField,
} from 'src/shared/lib/context/state/atoms/selectedReservation'

export const Route = createFileRoute('/restaurants/$restaurantId/reservations')(
  {
    component: RouteComponent,
    validateSearch: z.object({ r: z.number().optional() }),
  },
)

function RouteComponent() {
  const { restaurantId } = Route.useParams()

  return (
    <ReservationsLayout restaurantId={restaurantId}>
      <Outlet />
      <Suspense>
        <SyncUrlReservation />
      </Suspense>
    </ReservationsLayout>
  )
}

function SyncUrlReservation() {
  const { restaurantId } = Route.useParams()
  const { r } = Route.useSearch()
  const [routeWasLoaded, setRouteWasLoaded] = useState(false)

  const id = useSelectedReservationField('id')
  const isSelected = useIsReservationSelectedAtom()
  const editReservation = useEditReservation(restaurantId)

  const { data: reservation } = useReservation(r)

  const navigate = Route.useNavigate()

  useEffect(() => {
    if (id === 0) return
    void navigate({ to: '.', search: { r: id }, replace: true })
  }, [id, navigate])

  useEffect(() => {
    if (routeWasLoaded) return
    setRouteWasLoaded(true)
    if (!r) return
    if (!reservation) return
    if (isSelected) return

    void editReservation(reservation)
  }, [editReservation, isSelected, reservation, routeWasLoaded, r])

  return null
}
