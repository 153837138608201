import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { FakeLabel } from 'src/shared/components/form/common/FakeLabel/FakeLabel'
import MaxGuests from './components/MaxGuests/MaxGuests'
import MinGuests from './components/MinGuests/MinGuests'

const GuestRange = () => {
  const { t } = useTranslation()

  return (
    <Stack direction="column">
      <FakeLabel>
        {t(
          'schedule.shifts.limits_section.guest_range.label',
          'Guests per reservation (min - max)',
        )}
      </FakeLabel>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <MinGuests />
        <Typography variant="body2">-</Typography>
        <MaxGuests />
      </Stack>
    </Stack>
  )
}

export default GuestRange
