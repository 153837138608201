import { prop } from 'ramda'

import { useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { priorityComparator } from 'src/shared/lib/common/services/priorityComparator'
import { useInfoQuery } from '../../info/queries/info'
import {
  getCountriesList,
  getLanguagesList,
  getPhoneCodes,
} from '../api/localeApi'
import { type CountryInterface } from '../types/locale'

const LANGUAGES_CACHE_KEY = ['languages']

export const useLanguagesQuery = () => {
  const apiClient = useApiClient()

  return useQuery({
    queryKey: LANGUAGES_CACHE_KEY,
    queryFn: () => getLanguagesList(apiClient.get),
  })
}

const PHONE_CODES_CACHE_KEY = ['phoneCodes']

export const usePhoneCodesQuery = () => {
  const apiClient = useApiClient()

  const {
    data: { countryCode },
  } = useInfoQuery()

  return useSuspenseQuery({
    queryKey: PHONE_CODES_CACHE_KEY,
    queryFn: () => getPhoneCodes(apiClient.get),
    select: phoneCodes =>
      Object.values(phoneCodes)
        .slice()
        .sort(priorityComparator([countryCode], prop('countryCode'))),
  })
}

const COUNTRIES_CACHE_KEY = ['countries']

export const useCountriesQuery = () => {
  const apiClient = useApiClient()

  const {
    data: { countryCode },
  } = useInfoQuery()

  return useQuery({
    queryKey: COUNTRIES_CACHE_KEY,
    queryFn: () =>
      getCountriesList(apiClient.get) as Promise<CountryInterface[]>,
    select: countries =>
      countries.slice().sort(priorityComparator([countryCode], prop('code'))),
  })
}
