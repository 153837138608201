import { useTranslation } from 'react-i18next'

import { useDeleteClosureMutation } from 'src/entities/schedule/queries/schedule'
import {
  type Closure,
  type Schedule,
} from 'src/entities/schedule/types/scheduleApi'
import { useDefaultMutationHandlers } from '../../ShiftConfiguration/hooks/useDefaultMutationHandlers'
import { CreateScheduleButton } from '../components'
import { ScheduleCard, ScheduleCardWrapper } from '../components/ScheduleCard'
import { getValidityScopeProperty } from '../ReservationQuestionsSection/service'
import { useExceptionDateRangeFormatter } from '../service'

interface RestaurantClosureSectionProps {
  schedule: Schedule
  onEditClosure: (closure?: Closure) => void
}

export const RestaurantClosureSection = ({
  schedule,
  onEditClosure,
}: RestaurantClosureSectionProps) => {
  const { t } = useTranslation()

  const { mutateAsync: deleteClosure } = useDeleteClosureMutation()
  const { onError } = useDefaultMutationHandlers()

  const formatDateRange = useExceptionDateRangeFormatter()

  return (
    <>
      <ScheduleCardWrapper>
        {schedule.closures.map(c => (
          <ScheduleCard
            key={c.id}
            onEdit={() => onEditClosure(c)}
            onDelete={() => deleteClosure(c, { onError })}
            properties={[
              t('schedule.overview.closure.properties.closed', 'Closed'),
              formatDateRange([c.effectivePeriod.start, c.effectivePeriod.end]),
              getValidityScopeProperty({ scope: c.scope, schedule, t }),
              c.disablePhoneHours &&
                t(
                  'schedule.overview.closure.properties.phone_calls_disabled',
                  'Disable phone calls',
                ),
              c.descriptionText && `"${c.descriptionText}"`,
            ]}
          />
        ))}
      </ScheduleCardWrapper>
      <CreateScheduleButton onClick={() => onEditClosure()}>
        {t('schedule.overview.closure.create', 'Close Restaurant')}
      </CreateScheduleButton>
    </>
  )
}
