export const cycleArray =
  <T extends object, K extends keyof T>(
    array: { [P in K]: T[P] }[],
    getId: (item: T) => T[K],
    callback: (nextElement: T) => void,
  ) =>
  (currentId: T[K]) =>
  (moveBy: number) => {
    const idx = array.findIndex(p => getId(p as T) === currentId)

    const { length } = array

    const nextElement = array.at((idx + moveBy) % length)!

    return callback(nextElement as T)
  }
