import { Box } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import CakeIcon from 'src/shared/components/icons/CakeIcon'
import ClipboardIcon from 'src/shared/components/icons/ClipboardIcon'
import FoodIcon from 'src/shared/components/icons/FoodIcon'
import HotelClipboardIcon from 'src/shared/components/icons/HotelClipboardIcon'
import LocationIcon from 'src/shared/components/icons/LocationIcon'
import StarIcon from 'src/shared/components/icons/StarIcon'

interface ReservationListItemIndicatorsProps {
  showVipIcon: boolean
  showBirthdayIcon: boolean
  showCommentIcon: boolean
  showFoodIcon: boolean
  showPlaceIcon: boolean
  showExtNotesIcon: boolean
}

export const ReservationListItemIndicators = ({
  showBirthdayIcon,
  showCommentIcon,
  showExtNotesIcon,
  showFoodIcon,
  showPlaceIcon,
  showVipIcon,
}: ReservationListItemIndicatorsProps) => (
  <Box
    sx={{
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
    }}
    {...createTestIdProps('reservation-indicators')}
  >
    {showVipIcon && (
      <Box
        sx={{
          display: 'flex',
          padding: '0px .5px 1px .5px',
          background: 'white',
          color: 'warning.main',
          borderRadius: 100,
        }}
      >
        <StarIcon size="small" sx={{ '> svg': { fontSize: '.95rem' } }} />
      </Box>
    )}
    {showBirthdayIcon && <CakeIcon size="small" variant="filled" />}
    {showCommentIcon && <ClipboardIcon size="small" variant="filled" />}
    {showFoodIcon && <FoodIcon size="small" />}
    {showPlaceIcon && <LocationIcon size="small" variant="filled" />}
    {showExtNotesIcon && <HotelClipboardIcon size="small" variant="filled" />}
  </Box>
)
