import { Skeleton } from '@mui/material'

import { ReservationListItemWrapper } from './ReservationListItemWrapper'

export const ReservationListItemSkeleton = () => (
  <ReservationListItemWrapper
    redBgCondition={false}
    sx={{ position: 'relative', overflow: 'hidden' }}
  >
    <Skeleton
      sx={{ position: 'absolute', left: 0, top: -20, bottom: -20, right: 0 }}
    />
  </ReservationListItemWrapper>
)
