import { z } from 'zod'

import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import { formValidityScopeSchema } from '../ValidityScopeInput/service/schema'

export const formClosureSchema = z
  .object({
    descriptionText: z.string().nullable(),
    disablePhoneHours: z.boolean(),
    showInBook: z.boolean(),
    effectivePeriod: z
      .tuple([z.date(), z.date()])
      .transform(r => r as DefinedRangeInterface<Date>),
    scope: formValidityScopeSchema,
  })
  .transform(closure => ({
    ...closure,
    ...(closure.scope.type !== 'all' && {
      disablePhoneHours: false,
    }),
  }))

export type FormClosure = z.infer<typeof formClosureSchema>
