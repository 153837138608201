import type React from 'react'
import { styled, Typography, type TypographyProps } from '@mui/material'

const EllipsisTypography: React.FC<TypographyProps> = styled(Typography)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
})

export default EllipsisTypography
