import { Divider, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type PersistedLabelInterface } from 'src/entities/label/types/label'
import ReservationDrawerSection from 'src/shared/components/common/ReservationDrawerSection'
import LabelIcon from 'src/shared/components/icons/LabelIcon'
import { type DrawerColor } from 'src/widgets/SidebarDrawer/drawerColor'
import { SidebarDrawer } from 'src/widgets/SidebarDrawer/SidebarDrawer'
import SidebarHeader from 'src/widgets/SidebarDrawer/SidebarHeader'
import LabelsList from './LabelsList'
import LabelsPicker from './LabelsPicker'

interface LabelsProps {
  onChange: (labels: PersistedLabelInterface[]) => void
  value: PersistedLabelInterface[]
  labels: PersistedLabelInterface[]
  open: boolean
  onOpen: () => void
  onClose: () => void
  drawerColor: DrawerColor
}

const Labels = ({
  value = [],
  onChange,
  labels,
  open,
  onOpen: handleOpen,
  onClose: handleClose,
  drawerColor,
}: LabelsProps) => {
  const { t } = useTranslation()

  const label = t('angular.label')

  return (
    <>
      <ReservationDrawerSection
        title={label}
        icon={<LabelIcon variant="filled" size="small" />}
        onClick={handleOpen}
        onHeaderClick={handleOpen}
        sx={{ cursor: 'pointer' }}
      >
        {!!value.length && <LabelsList labels={value} />}
      </ReservationDrawerSection>
      <SidebarDrawer
        open={open}
        level={1}
        color={drawerColor}
        onClose={handleClose}
      >
        <Stack direction="column" divider={<Divider />} overflow="hidden">
          <SidebarHeader onClose={handleClose} color={drawerColor}>
            <Typography variant="labelBig">{label}</Typography>
          </SidebarHeader>
          <Stack direction="column" overflow="auto">
            <LabelsPicker onChange={onChange} value={value} labels={labels} />
          </Stack>
        </Stack>
      </SidebarDrawer>
    </>
  )
}

export default Labels
