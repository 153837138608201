import { useShortDateRangeFormatter } from 'src/shared/lib/range/hooks/useFormattedDate'

const options: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
}

export const useExceptionDateRangeFormatter = () =>
  useShortDateRangeFormatter(options)
