import { Box, Stack, Typography, type StackProps } from '@mui/material'

import IconButton from 'src/shared/components/buttons/IconButton'
import ArrowLeftIcon from 'src/shared/components/icons/ArrowLeftIcon'

interface ScheduleContainerProps extends StackProps {
  title: string
  onNavigateBack: () => void
}

export const ScheduleContainer = ({
  title,
  onNavigateBack,
  children,
  ...props
}: ScheduleContainerProps) => (
  <Stack direction="column" gap={3} {...props}>
    <Stack direction="row" alignItems="center">
      <IconButton
        onClick={onNavigateBack}
        size="medium"
        variant="text"
        color="neutral"
      >
        <ArrowLeftIcon />
      </IconButton>
      <Typography variant="labelHuge">{title}</Typography>
    </Stack>
    <Box px={5}>{children}</Box>
  </Stack>
)
