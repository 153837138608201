import React from 'react'
import { type Breakpoint } from '@mui/material'

import {
  useBreakpointCallback,
  type BreakpointsMap,
} from './useBreakpointCallback'

export const useBreakpointsCallback = <T>(
  callbacks: BreakpointsMap<T>,
  forcedBreakpoint?: Breakpoint | null,
) => {
  const breakpointsMap: BreakpointsMap<Breakpoint> = {
    xs: useBreakpointCallback(callbacks, 'xs'),
    sm: useBreakpointCallback(callbacks, 'sm'),
    md: useBreakpointCallback(callbacks, 'md'),
    lg: useBreakpointCallback(callbacks, 'lg'),
    xl: useBreakpointCallback(callbacks, 'xl'),
  }

  const currentBreakpoint =
    breakpointsMap.xl ??
    breakpointsMap.lg ??
    breakpointsMap.md ??
    breakpointsMap.sm ??
    breakpointsMap.xs

  return React.useMemo(
    () =>
      (forcedBreakpoint && callbacks[forcedBreakpoint]) ??
      (currentBreakpoint && callbacks[currentBreakpoint]),
    [currentBreakpoint, callbacks, forcedBreakpoint],
  )!
}
