import { useMemo } from 'react'

import { useCustomers } from 'src/entities/customer/queries/customer'
import { type CustomerInterface } from 'src/entities/customer/types/customer'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { isNotFalsy } from 'src/shared/lib/zod/zod'
import { combineWithCustomers } from './service/reservations'
import { useReservationsOfDate } from '../FloorPlanView/useReservationsOfDate'

export const useReservationsWithGuestData = (
  date: Date,
  selectedReservation?: ReservationInterface,
) => {
  const reservations = useReservationsOfDate(date, selectedReservation)
  const uniqueCustomerIds = useMemo(
    () => [
      ...new Set(reservations.map(r => r.guest.customerId).filter(isNotFalsy)),
    ],
    [reservations],
  )

  const customers = useCustomers(uniqueCustomerIds)

  return useMemo(
    () =>
      combineWithCustomers(
        reservations,
        customers.filter((c): c is CustomerInterface => !!c.id),
      ),
    [customers, reservations],
  )
}
