import { atom, useAtom, type Getter, type Setter } from 'jotai'

import {
  baseSelectedReservationAtom,
  NO_RESERVATION,
  updateSelectedReservationField,
} from './baseSelectedReservation'
import {
  addToDate,
  combineDateWithTime,
  getDifference,
  getNowInRestaurantTimezone,
  getStartOf,
  isDateSame,
} from '../../../range/services/date'

const updateReservationDate = (get: Getter, set: Setter) => (newDate: Date) => {
  const base = get(baseSelectedReservationAtom)

  if (base === NO_RESERVATION) return

  set(
    baseSelectedReservationAtom,
    updateSelectedReservationField('dateRange', dr => {
      const [arrivalTime, leaveTime] = dr

      const diff = getDifference('minutes')(arrivalTime, leaveTime)

      const newArrivalTime = combineDateWithTime(newDate, arrivalTime)
      const newLeaveTime = addToDate(diff, 'minutes', newArrivalTime)

      return [newArrivalTime, newLeaveTime]
    }),
  )
}

const currentDateTimeAtom = atom(getNowInRestaurantTimezone())

export const currentDateAtom = atom(
  get => getStartOf('day')(get(currentDateTimeAtom)),
  (get, set, newDate: Date | undefined) => {
    if (!newDate) return

    const dt = get(currentDateTimeAtom)

    const reservationDate = getStartOf('day')(newDate)
    const currentDate = get(currentDateAtom)

    if (isDateSame(reservationDate, currentDate)) return

    set(currentDateTimeAtom, combineDateWithTime(newDate, dt))

    updateReservationDate(get, set)(newDate)
  },
)

export const useCurrentDateAtom = () => useAtom(currentDateAtom)
export const useCurrentDate = () => useCurrentDateAtom()[0]
