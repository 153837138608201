import { Box, Popover, type TextFieldProps } from '@mui/material'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { usePopover } from 'src/shared/components/dialogs/hooks/usePopover'
import TimeRangeSelectInput from 'src/shared/components/form/inputs/TimeRangeSelectInput'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import Selection from './Selection'

const TimeRangePicker = ({
  value,
  onChange,
  serviceTimes,
  ...props
}: {
  value: DefinedRangeInterface<Date>
  onChange: (timeRange: DefinedRangeInterface<Date>) => void
  serviceTimes: ServiceTimeInterface[]
} & Partial<Omit<TextFieldProps, 'value' | 'onChange'>>) => {
  const { open, setAnchorEl, handleClose, anchorEl } = usePopover()

  return (
    <>
      <TimeRangeSelectInput
        timeRange={value}
        onClick={event => setAnchorEl(event.currentTarget)}
        focused={open}
        {...props}
      />
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Box sx={{ p: 3 }}>
          <Selection
            serviceTimes={serviceTimes}
            timeRange={value}
            onTimeRangeChange={onChange}
            onStartDateChange={handleClose}
          />
        </Box>
      </Popover>
    </>
  )
}

export default TimeRangePicker
