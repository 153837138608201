import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'

import { useRestaurantCacheKey } from './useRestaurantCacheKey'
import { useApiClient } from '../hooks/useApiClient'
import { type CrudApiInterface } from '../services/crudApiFactory'

export const crudQueriesFactory = <Entity>(
  cacheKey: string[],
  crudApi: CrudApiInterface<Entity>,
) => {
  const useItemsListQuery = () => {
    const apiClient = useApiClient()
    const restaurantCacheKey = useRestaurantCacheKey(cacheKey)

    return useSuspenseQuery({
      queryKey: restaurantCacheKey,
      queryFn: () => crudApi.getItemsList(apiClient.post),
    })
  }

  const useItemUpdateMutation = () => {
    const apiClient = useApiClient()
    const queryClient = useQueryClient()
    const restaurantCacheKey = useRestaurantCacheKey(cacheKey)

    return useMutation({
      mutationFn: (entity: Entity) => crudApi.setItem(apiClient.post)(entity),
      onMutate: () =>
        queryClient.cancelQueries({ queryKey: restaurantCacheKey }),
      onSettled: () =>
        queryClient.invalidateQueries({ queryKey: restaurantCacheKey }),
    })
  }

  const useItemDeleteMutation = () => {
    const apiClient = useApiClient()
    const queryClient = useQueryClient()
    const restaurantCacheKey = useRestaurantCacheKey(cacheKey)

    return useMutation({
      mutationFn: (entity: Entity) =>
        crudApi.deleteItem(apiClient.post)(entity),
      onMutate: () =>
        queryClient.cancelQueries({ queryKey: restaurantCacheKey }),
      onSettled: () =>
        queryClient.invalidateQueries({ queryKey: restaurantCacheKey }),
    })
  }

  return {
    useItemsListQuery,
    useItemUpdateMutation,
    useItemDeleteMutation,
  }
}
