import { useTranslation } from 'react-i18next'

import { isAdmin } from 'src/entities/team-member/services/teamMember'
import { type TeamMember } from 'src/entities/team-member/types/teamMember'
import Modal from 'src/shared/components/common/Modal'
import type useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import TeamMemberForm from './TeamMemberForm'

interface Deps {
  editedTeamMember: TeamMember | null
  currentTeamMember: TeamMember | undefined
  t: (key: string, defaultValue: string) => string
}

const getTeamMemberFormModalHeadline = ({
  editedTeamMember,
  currentTeamMember,
  t,
}: Deps) => {
  if (!editedTeamMember)
    return {
      title: t(
        'settings.team_members.form.add_member_title',
        'Add team member',
      ),
      subtitle: t(
        'settings.team_members.form.add_subtitle',
        'Create team member profile for your employee.',
      ),
    }

  const isCurrentUser = editedTeamMember.id === currentTeamMember?.id

  if (isCurrentUser) {
    return {
      title: t(
        'settings.team_members.form.my_profile_profile_title',
        'My profile',
      ),
      subtitle: t(
        'settings.team_members.form.my_profile_subtitle',
        'Manage profile.',
      ),
    }
  }

  const isAdminEdit = isAdmin(editedTeamMember)

  if (isAdminEdit) {
    return {
      title: t('settings.team_members.form.edit_admin_title', 'Edit admin'),
      subtitle: t(
        'settings.team_members.form.edit_admin_subtitle',
        'Edit admin settings.',
      ),
    }
  }

  return {
    title: t(
      'settings.team_members.form.edit_member_title',
      'Edit team member',
    ),
    subtitle: t(
      'settings.team_members.form.edit_subtitle',
      'Edit team member profile for your employee.',
    ),
  }
}

interface EditTeamMemberModalProps extends Omit<Deps, 't'> {
  dialogProps: ReturnType<typeof useDialog>
  disableDelete?: boolean
}

export const EditTeamMemberModal = ({
  dialogProps,
  currentTeamMember,
  editedTeamMember,
  disableDelete = false,
}: EditTeamMemberModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={dialogProps.open}
      onClose={dialogProps.handleClose}
      {...getTeamMemberFormModalHeadline({
        currentTeamMember,
        editedTeamMember,
        t,
      })}
      disablePortal={false}
    >
      <TeamMemberForm
        teamMember={editedTeamMember}
        closeModal={dialogProps.handleClose}
        disableDelete={disableDelete}
      />
    </Modal>
  )
}
