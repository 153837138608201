import { Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  useFeedbacks,
  useFeedbackSearchQuery,
} from 'src/entities/feedback/queries/feedback'
import useFeature from 'src/entities/info/hooks/useFeature'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import ReservationDrawerSection from 'src/shared/components/common/ReservationDrawerSection'
import ReservationDrawerCheckbox from 'src/shared/components/form/inputs/ReservationDrawerCheckbox'
import EnvelopeIcon from 'src/shared/components/icons/EnvelopeIcon'
import {
  getNowInRestaurantTimezone,
  isInFuture,
  isInPast,
} from 'src/shared/lib/range/services/date'
import { isRecent } from '../../CustomerPreview/service/feedback'

interface MessagesProps {
  reservation: ReservationInterface
  onReminderChange: (value: boolean) => void
  onFeedbackChange: (value: boolean) => void
}

const Messages = ({
  reservation,
  onReminderChange,
  onFeedbackChange,
}: MessagesProps) => {
  const { t } = useTranslation()

  const guestHasEmail = !!reservation.guest.email

  const reminderIsEnabled = useFeature('reminder')

  const now = getNowInRestaurantTimezone()

  const reminderIsAllowed =
    guestHasEmail &&
    reminderIsEnabled &&
    !!reservation.reminder &&
    isInFuture('day')(now, reservation.reminder.date) &&
    reservation.seatCount >= reservation.reminder.seatCount

  const { data: feedbackIds } = useFeedbackSearchQuery(
    reservation.guest.customerId,
  )

  const feedbacks = useFeedbacks(feedbackIds)
  const feedbackIsEnabled = useFeature('feedback')

  const feedbackIsAllowed =
    guestHasEmail &&
    feedbackIsEnabled &&
    !feedbacks.some(isRecent(now)) &&
    !isInPast('minute')(now, reservation.dateRange[0])

  if (!guestHasEmail || !(feedbackIsEnabled || reminderIsEnabled)) return null

  return (
    <ReservationDrawerSection
      title={t('angular.message_guest')}
      icon={<EnvelopeIcon variant="filled" size="small" />}
    >
      <Stack direction="row" gap={2}>
        {reminderIsEnabled && (
          <ReservationDrawerCheckbox
            label={t('angular.reminder')}
            disabled={!reminderIsAllowed}
            checked={reservation.sendReminder}
            onChange={(_e, checked) => onReminderChange(checked)}
          />
        )}
        {feedbackIsEnabled && (
          <ReservationDrawerCheckbox
            label={t('angular.feedback')}
            disabled={!feedbackIsAllowed}
            checked={reservation.sendFeedback}
            onChange={(_e, checked) => onFeedbackChange(checked)}
          />
        )}
      </Stack>
    </ReservationDrawerSection>
  )
}

export default Messages
