import { useCallback, useMemo } from 'react'
import { Stack, Typography } from '@mui/material'

import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useCurrentTeamMember from 'src/entities/team-member/hooks/useCurrentTeamMember'
import {
  useAddTeamMemberMutation,
  useDeleteTeamMemberMutation,
  useEditTeamMemberMutation,
  useTeamMembersQuery,
} from 'src/entities/team-member/queries/teamMember'
import { isAdmin } from 'src/entities/team-member/services/teamMember'
import {
  type TeamMember,
  type TeamMemberUpdateInterface,
} from 'src/entities/team-member/types/teamMember'
import CancelButton from 'src/shared/components/buttons/FormButtons/CancelButton'
import DeleteButton from 'src/shared/components/buttons/FormButtons/DeleteButton'
import SaveButton from 'src/shared/components/buttons/FormButtons/SaveButton'
import { difference } from 'src/shared/lib/common/services/difference'
import { useSetActiveTeamMemberAtom } from 'src/shared/lib/context/state/atoms/activeTeamMember'
import { Colors } from 'src/shared/lib/style/types/color'
import Color from './Color'
import FirstName from './FirstName'
import LastName from './LastName'
import { SecuritySection } from './SecuritySection'

const emptyTeamMember = {
  firstName: '',
  lastName: '',
  role: 'team_member',
  color: Colors.blue,
  pinRequired: false,
} as const

interface TeamMemberFormProps {
  teamMember: TeamMember | null
  closeModal: () => void
  disableDelete: boolean
}

const TeamMemberForm = ({
  teamMember,
  closeModal,
  disableDelete,
}: TeamMemberFormProps) => {
  const { t } = useTranslation()

  const currentTeamMember = useCurrentTeamMember()
  const setActiveTeamMemberId = useSetActiveTeamMemberAtom()

  const { data: teamMembers } = useTeamMembersQuery()

  const {
    mutate: add,
    isPending: isAddPending,
    isSuccess: isAddSuccess,
  } = useAddTeamMemberMutation()
  const { mutate: edit, isPending: isEditPending } = useEditTeamMemberMutation()
  const {
    mutate: remove,
    isPending: isDeletePending,
    isSuccess: isDeleteSuccess,
  } = useDeleteTeamMemberMutation()

  const isLoading =
    isAddPending ||
    isEditPending ||
    isDeletePending ||
    isAddSuccess ||
    isDeleteSuccess

  const form = useForm<TeamMemberUpdateInterface>({
    defaultValues: teamMember ?? emptyTeamMember,
  })
  const { handleSubmit } = form

  const onDelete = useCallback(
    (tm: TeamMember) => {
      remove(tm, {
        onSuccess: () => {
          closeModal()
          if (currentTeamMember && tm.id === currentTeamMember.id)
            setActiveTeamMemberId(null)
        },
      })
    },
    [closeModal, currentTeamMember, remove, setActiveTeamMemberId],
  )

  const handleSave = useMemo(
    () =>
      handleSubmit(tm => {
        if (!teamMember) return add(tm, { onSuccess: closeModal })

        const diff = difference(teamMember, tm)

        if (!diff) return closeModal()

        return edit({ id: tm.id, ...diff }, { onSuccess: closeModal })
      }),
    [add, closeModal, edit, handleSubmit, teamMember],
  )

  const isEdit = !!teamMember
  const isNotAdminEdit = isEdit && !isAdmin(teamMember)

  return (
    <FormProvider {...form}>
      <Stack direction="column" gap={2}>
        <Stack direction={['column', 'row']} gap={2}>
          <FirstName />
          <LastName />
        </Stack>

        <Stack gap={1}>
          <Typography variant="labelSmall">
            {t('settings.team_members.form.color_section', 'Color')}
          </Typography>
          <Color teamMembers={teamMembers} />
        </Stack>

        <SecuritySection />

        <Stack direction="row" gap={1}>
          <CancelButton
            fullWidth
            onClick={closeModal}
            text={t('settings.team_members.form.cancel_button', 'Dismiss')}
            disabled={isLoading}
          />
          {!disableDelete && isNotAdminEdit && (
            <DeleteButton
              fullWidth
              onClick={() => onDelete(teamMember)}
              disabled={isLoading}
            />
          )}
          <SaveButton
            fullWidth
            onClick={handleSave}
            text={
              isEdit
                ? t('settings.team_members.form.save_button', 'Save changes')
                : t('settings.team_members.form.create_button', 'Create member')
            }
            disabled={isLoading}
          />
        </Stack>
      </Stack>
    </FormProvider>
  )
}

export default TeamMemberForm
