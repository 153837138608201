import { hasIdRelationBy } from 'src/shared/lib/common/services/functional/functional'
import { areInsideRoom } from '../../room/services/room'
import { type RoomInterface } from '../../room/types/room'
import { type AreaInterface } from '../types/area'

export const areInsideArea = hasIdRelationBy('areaId')

export const getAreaRoom =
  (rooms: RoomInterface[]) => (area: AreaInterface | undefined) =>
    rooms.find(areInsideRoom([area]))

export const getAreaId = (area: AreaInterface) => area.id
