import AddRoundedIcon from '@mui/icons-material/AddRounded'
import { Button, type ButtonProps } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'

const CreateButton = (props: ButtonProps) => (
  <Button
    {...createTestIdProps('add-button')}
    color="primary"
    variant="contained"
    startIcon={<AddRoundedIcon />}
    {...props}
  />
)

export default CreateButton
