import { compose, map } from 'ramda'

import { z } from 'zod'

import { convertKeysToSnakeCase } from 'src/shared/lib/api/services/api'
import { type SelectedReservation } from 'src/shared/lib/context/state/atoms/baseSelectedReservation'
import * as dateService from 'src/shared/lib/range/services/date'
import { fromApi, type RawApiAssignableTable } from './occupancyFactory'
import type { IsReminderPossible } from './reservation'
import type { AreaInterface } from '../../area/types/area'
import type { CustomerInterface } from '../../customer/types/customer'
import { type PreferencesInterface } from '../../customer/types/preferences'
import { type RestaurantInterface } from '../../restaurant/types/restaurant'
import type { RoomInterface } from '../../room/types/room'
import {
  ReservationSourceEnum,
  ReservationStatusEnum,
  type ReservationChoiceInterface,
  type ReservationCreateInterface,
  type ReservationInterface,
  type ReservationStatusChangeInterface,
  type ReservationUpdateInterface,
} from '../types/reservation'
import {
  reservationCreateSchema,
  reservationStatusChangeSchema,
  reservationUpdateSchema,
  type ApiReservationCreate,
  type ApiReservationStatusChange,
  type ApiReservationUpdate,
  type ReservationLink,
  type ReservationPayment,
} from '../types/reservationApi'

const YES = 'ja'
const NO = 'nein'
const deutschBooleschConverter = (input: 'ja' | 'nein'): boolean =>
  ({
    [YES]: true,
    [NO]: false,
  })[input] || false

const isNoShow = (appeared: string): boolean => appeared === NO

export const preferencesFactory = (
  prefs: PreferencesInterface,
): PreferencesInterface => ({
  food: prefs?.food,
  place: prefs?.place,
})

const calculateStatus = (
  status: ReservationStatusEnum,
  noShow: boolean,
  cancelled = false,
) => {
  if (noShow) return ReservationStatusEnum.NoShow

  if (status === ReservationStatusEnum.Cancelled || cancelled)
    return ReservationStatusEnum.Cancelled

  return ReservationStatusEnum.Confirmed
}

const timeRangeFactory = (startDate: Date | null, duration: number) =>
  [startDate, dateService.addToDate(duration, 'minute', startDate)] as [
    Date,
    Date,
  ]

const choiceFactory = (
  rawChoice: ReservationChoiceInterface,
): ReservationChoiceInterface => ({
  question: rawChoice.question,
  answer: rawChoice.answer,
})

interface RawReservation {
  source: ReservationSourceEnum
  channel: ReservationSourceEnum
  appeared: 'ja' | 'nein'
  id: number
  simple_status: ReservationStatusEnum
  places: number
  tables: RawApiAssignableTable[]
  message: string
  notice: string
  options: ReservationChoiceInterface[]
  firstName: string
  lastName: string
  customers?: (number | null)[]
  companyName: string
  phone: string
  phoneNumber: string
  email: string
  comment: string
  language: string
  preferences: PreferencesInterface
  vip: number
  blacklist: number
  revenue: number
  date: string
  time: string
  duration: number
  labels: number[]
  res_number: number
  created_at: string | null | undefined
  serial_id: number
  room_id: number
  restaurant_hash: string
  feedback: boolean
  feedback_id: number
  links: ReservationLink[]
  reminder: boolean
  reminder_timestamp: string | null | undefined
  reminder_pax: string
  locked: number
  payment:
    | {
        payment_plan_id: string
        type: ReservationPayment['type']
        amount_per_person: number
        currency: string
        expiration_date: string | null | undefined
        status: ReservationPayment['status']
      }
    | null
    | undefined
  cancellation_deadline: string
  exact_mode: boolean
}

export const reservationFactory = (
  reservation: RawReservation,
): ReservationInterface => {
  const source = reservation.source || reservation.channel
  const noShow = isNoShow(reservation.appeared)

  return {
    id: reservation.id,
    status: calculateStatus(reservation.simple_status, noShow),
    appeared: deutschBooleschConverter(reservation.appeared),
    seatCount: reservation.places,
    occupancies: Object.values(reservation.tables || {}).map(fromApi),
    guestMessage: reservation.message,
    restaurantNote: reservation.notice,
    choices: (reservation.options || []).map(choiceFactory),
    source: Object.values(ReservationSourceEnum).includes(source)
      ? source
      : ReservationSourceEnum.Online,
    guest: {
      firstName: reservation.firstName,
      lastName: reservation.lastName,
      customerId: reservation.customers?.[0],
      companyName: reservation.companyName,
      phoneNumber: reservation.phone || reservation.phoneNumber,
      email: reservation.email,
      comment: reservation.comment,
      languageCode: reservation.language,
      preferences: preferencesFactory(reservation.preferences),
      vip: !!reservation.vip,
      ban: !!reservation.blacklist,
      birthdate: null,
      notes: [],
      externalNotes: [],
    },
    revenue: reservation.revenue,
    dateRange: timeRangeFactory(
      dateService.fromDateAndTime(reservation.date, reservation.time),
      reservation.duration,
    ),
    labels: reservation.labels,
    number: reservation.res_number || 0,
    audit: {
      created: reservation.created_at
        ? dateService.convertFromTimezone(new Date(reservation.created_at))
        : dateService.getNowInRestaurantTimezone(),
      updated: dateService.getNowInRestaurantTimezone(),
    },
    serial: !!reservation.serial_id,
    room: reservation.room_id || 0,
    restaurantId: reservation.restaurant_hash,
    sendFeedback: reservation.feedback,
    feedbackId: reservation.feedback_id,
    serialId: reservation.serial_id,
    links: reservation.links,
    sendReminder: reservation.reminder,
    reminder: reservation.reminder_timestamp
      ? {
          date: dateService.convertFromTimezone(
            new Date(reservation.reminder_timestamp),
          ),
          seatCount: Number(reservation.reminder_pax),
        }
      : undefined,
    locked: !!reservation.locked,
    payment: reservation.payment
      ? {
          paymentPlanId: reservation.payment.payment_plan_id,
          type: reservation.payment.type ?? 'pre_payment',
          amountPerPerson: reservation.payment.amount_per_person,
          currency: reservation.payment.currency,
          expirationDate: reservation.payment.expiration_date
            ? dateService.convertFromTimezone(
                new Date(reservation.payment.expiration_date),
              )
            : null,
          status: reservation.payment.status,
        }
      : null,
    sendEmail: false,
    sendSms: false,
    cancellationDeadline: dateService.convertFromTimezone(
      new Date(reservation.cancellation_deadline),
    ),
    exactMode: reservation.exact_mode,
  }
}

export const payloadFactory = (reservation: ReservationInterface) =>
  convertKeysToSnakeCase({
    restaurantHash: reservation.restaurantId,
    startTime: dateService
      .convertToTimezone(reservation.dateRange[0])
      .toISOString(),
    endTime: dateService
      .convertToTimezone(reservation.dateRange[1])
      .toISOString(),
    seatCount: reservation.seatCount,
    restaurantNote: reservation.restaurantNote,
    roomId: reservation.room,
    customerId: reservation.guest.customerId,
    labels: reservation.labels,
    tableOccupancies: [],
    ...(reservation.serialId && { serialId: reservation.serialId }),
  })

export const reservationIdFromEventData = (
  eventData: ApiReservationUpdate | ApiReservationStatusChange,
): ReservationInterface['id'] => Number(eventData.id)

export const reservationNotificationPayloadFactory = compose(
  (eventData: ApiReservationUpdate): ReservationUpdateInterface => ({
    id: reservationIdFromEventData(eventData),
    dateRange: timeRangeFactory(eventData.date, eventData.duration),
    seatCount: eventData.seats,
    guest: {
      firstName: eventData.firstName,
      lastName: eventData.lastName,
      companyName: eventData.companyName,
      customerId: eventData.customer,
    },
    room: eventData.room,
    labels: eventData.labels,
    restaurantNote: eventData.restaurantNote,
    guestMessage: eventData.guestNote,
    restaurantId: eventData.restaurantHash,
    links: eventData.links,
    payment: eventData.payment,
  }),
  (data: unknown) => reservationUpdateSchema.parse(data),
)

export const reservationStatusNotificationPayloadFactory = compose(
  (
    eventData: ApiReservationStatusChange,
  ): ReservationStatusChangeInterface => ({
    id: reservationIdFromEventData(eventData),
  }),
  (data: unknown) => reservationStatusChangeSchema.parse(data),
)

export const reservationCreateNotificationPayloadFactory = compose(
  (eventData: ApiReservationCreate): ReservationCreateInterface => ({
    guest: {
      firstName: eventData.firstName,
      lastName: eventData.lastName,
      companyName: eventData.companyName,
      customerId: eventData.customer,
    },
    dateRange: timeRangeFactory(eventData.date, eventData.duration),
    room: eventData.room,
  }),
  (data: unknown) => reservationCreateSchema.parse(data),
)

export const fromUpdate = (
  reservation: ReservationInterface,
  reservationUpdate: ReservationUpdateInterface,
): ReservationInterface => ({
  ...reservation,
  ...reservationUpdate,
  guest: {
    ...reservation.guest,
    ...reservationUpdate.guest,
  },
  room: reservationUpdate.room ?? reservation.room,
})

const exactModeChangedPayloadSchema = z
  .object({
    reservationId: z.number(),
    exactMode: z.coerce.boolean(),
  })
  .transform(payload => ({
    id: payload.reservationId,
    exactMode: payload.exactMode,
  }))

export const exactModeChangedPayloadFactory = (data: unknown) =>
  exactModeChangedPayloadSchema.parse(data)

const initReservation: Omit<
  ReservationInterface,
  'dateRange' | 'restaurantId'
> = {
  id: 0,
  number: 0,
  room: 0,
  seatCount: 2,
  payment: null,
  status: ReservationStatusEnum.Pending,
  serialId: undefined,
  locked: false,
  sendSms: false,
  sendEmail: false,
  sendFeedback: false,
  sendReminder: false,
  choices: [],
  guestMessage: '',
  labels: [],
  links: [],
  feedbackId: undefined,
  reminder: undefined,
  appeared: false,
  exactMode: false,
  occupancies: [],
  restaurantNote: '',
  revenue: 0,
  serial: false,
  source: ReservationSourceEnum.Self,
  guest: {
    customerId: null,
    notes: [],
    ban: false,
    phoneNumber: '',
    companyName: '',
    birthdate: null,
    comment: '',
    email: '',
    vip: false,
    firstName: '',
    lastName: '',
    externalNotes: [],
    languageCode: 'de',
    preferences: {
      food: '',
      place: '',
    },
  },
  audit: {
    created: dateService.getNowInRestaurantTimezone(),
    updated: dateService.getNowInRestaurantTimezone(),
  },
  cancellationDeadline: dateService.getNowInRestaurantTimezone(),
}

type GetStartTime = (selectedDate: Date, time?: Date) => Date

interface GetStartTimeDependencies {
  getPossibleTimeSlots: (dateTime: Date) => Date[]
  roundToNearestSlot: (time: Date) => Date
  moveToDate: (datePart: Date) => (timePart: Date) => Date
  getNow: () => Date
  isInFuture: (referenceDate: Date, testDate: Date) => boolean
}

export const getBestStartTime =
  (d: GetStartTimeDependencies): GetStartTime =>
  (selectedDate, time): Date => {
    const moveToSelectedDate = d.moveToDate(selectedDate)
    if (time) return moveToSelectedDate(time)

    const possibleTimeSlots = map(
      moveToSelectedDate,
      d.getPossibleTimeSlots(selectedDate),
    )

    const nowOnSelectedDate = moveToSelectedDate(d.getNow())

    const nextPossibleTimeSlot = possibleTimeSlots.find(timeSlot =>
      d.isInFuture(nowOnSelectedDate, timeSlot),
    )

    return (
      nextPossibleTimeSlot ??
      possibleTimeSlots[0] ??
      d.roundToNearestSlot(nowOnSelectedDate)
    )
  }

type GetDefaultRoom = () => RoomInterface

interface GetDefaultRoomDependencies {
  getAreaRoom: (area?: AreaInterface) => RoomInterface
  getDefaultArea: () => AreaInterface | undefined
  isRoomToGuestFeatureOn: () => boolean
}

export const getDefaultRoom = (
  d: GetDefaultRoomDependencies,
): GetDefaultRoom => {
  if (!d.isRoomToGuestFeatureOn()) return () => d.getAreaRoom()

  return () => d.getAreaRoom(d.getDefaultArea())
}

type GetInitialFeedback = (
  reservation: ReservationInterface,
  customer?: CustomerInterface,
) => Promise<boolean>

interface GetInitialFeedbackDependencies {
  isFeedbackFeatureOn: () => boolean
  hasFeedbackEnabled: (c?: CustomerInterface) => Promise<boolean>
  isFeedbackEmailEnabled: () => boolean
}

export const getInitialFeedback =
  (d: GetInitialFeedbackDependencies): GetInitialFeedback =>
  async (reservation, customer) => {
    if (!d.isFeedbackFeatureOn() || !d.isFeedbackEmailEnabled()) {
      return reservation.sendFeedback
    }

    return d.hasFeedbackEnabled(customer)
  }

type GetInitialReminder = (
  reservation: ReservationInterface,
  customer?: CustomerInterface,
) => boolean

interface GetInitialReminderDependencies {
  isReminderFeatureOn: () => boolean
  isReminderPossible: IsReminderPossible
  hasReminderEnabled: (c?: CustomerInterface) => boolean
  isReminderEmailEnabled: () => boolean
}

export const getInitialReminder =
  (d: GetInitialReminderDependencies): GetInitialReminder =>
  (reservation, customer) => {
    if (
      !d.isReminderFeatureOn() ||
      !d.hasReminderEnabled(customer) ||
      !d.isReminderPossible(reservation)
    )
      return reservation.sendReminder

    return d.isReminderEmailEnabled()
  }

interface InitializeReservation {
  customer?: CustomerInterface
  newCustomer?: Partial<CustomerInterface>
  time?: Date
}

type GuestFromCustomer = (
  c?: CustomerInterface,
) => ReservationInterface['guest']

interface InitializeReservationDependencies {
  proposeStartTime: GetStartTime
  proposeFeedback: GetInitialFeedback
  proposeRoom: GetDefaultRoom
  proposeReminder: GetInitialReminder
  createGuestFromCustomer: GuestFromCustomer
  getRestaurantHash: () => RestaurantInterface['hash']
  getSelectedDate: () => Date
  getDefaultDuration: () => number
}

interface SetCustomerToReservationDeps {
  createGuestFromCustomer: GuestFromCustomer
  proposeFeedback: GetInitialFeedback
  proposeReminder: GetInitialReminder
}

export const setCustomerToReservation =
  (d: SetCustomerToReservationDeps) =>
  async (reservation: ReservationInterface, customer?: CustomerInterface) => {
    const sendFeedback = await d.proposeFeedback(reservation, customer)

    return {
      ...reservation,
      guest: d.createGuestFromCustomer(customer),
      sendFeedback,
      sendReminder: d.proposeReminder(reservation, customer),
    }
  }

export const initializeReservation =
  (d: InitializeReservationDependencies) =>
  (p: InitializeReservation): Promise<SelectedReservation> => {
    const baseReservation: SelectedReservation = {
      ...initReservation,
      newCustomer: p.newCustomer,
      dateRange: timeRangeFactory(
        d.proposeStartTime(d.getSelectedDate(), p.time),
        d.getDefaultDuration(),
      ),
      restaurantId: d.getRestaurantHash(),
      room: d.proposeRoom().id,
    }

    return setCustomerToReservation(d)(baseReservation, p.customer)
  }
