import React from 'react'
import { filter } from 'ramda'
import { Stack, Typography } from '@mui/material'

import { type AreaInterface } from 'src/entities/area/types/area'
import { getArea } from 'src/entities/reservation/services/occupancy'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import {
  type TableInterface,
  type TableOccupancyInterface,
} from 'src/entities/table/types/table'
import OccupanciesItem from './OccupanciesItem/OccupanciesItem'

interface AreaSectionProps {
  area: AreaInterface
  tables: TableInterface[]
  onOccupancyRemoval: (occupancy: TableOccupancyInterface) => void
  onOccupancyChange: (table: TableInterface, seatCount: number) => void
  reservation: ReservationInterface
}

const AreaSection = ({
  area,
  tables,
  onOccupancyRemoval,
  onOccupancyChange,
  reservation,
}: AreaSectionProps) => {
  const areaOccupancies = React.useMemo(() => {
    const getOccupancyArea = getArea([area], tables)

    return filter(
      o => getOccupancyArea(o)?.id === area.id,
      reservation.occupancies,
    )
  }, [tables, area, reservation])

  if (!areaOccupancies.length) return null

  return (
    <Stack direction="column" spacing={0.5}>
      <Typography variant="labelExtrasmall" sx={{ color: 'grey.500' }}>
        {area.name}
      </Typography>
      <Stack direction="column" spacing={0.5}>
        {areaOccupancies.map(o => (
          <OccupanciesItem
            key={`${o.tableId}_${o.seatCount}`}
            occupancy={o}
            tables={tables}
            onOccupancyRemoval={onOccupancyRemoval}
            onOccupancyChange={onOccupancyChange}
            reservation={reservation}
          />
        ))}
      </Stack>
    </Stack>
  )
}

export default AreaSection
