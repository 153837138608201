import { createRouter } from '@tanstack/react-router'

import queryClient from 'src/shared/lib/api/queries/queryClient'
import { isApiError } from 'src/shared/lib/api/types/api'
import { routeTree } from './routeTree.gen'

const router = createRouter({
  routeTree,
  context: {
    queryClient,
  },
  defaultPreload: 'intent',
  defaultPreloadStaleTime: 0,
  defaultErrorComponent: ({ error }: { error: Error }) => {
    if (isApiError(error)) return null

    throw error
  },
  defaultPendingComponent: () => null,
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

export default router
