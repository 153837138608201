import React from 'react'
import { Stack } from '@mui/material'

import { type ScheduleLanguage } from 'src/entities/schedule/types/scheduleApi'
import LanguageTabs from './components/LanguageTabs/LanguageTabs'
import MessageInput from './components/MessageInput/MessageInput'

const MessageText = () => {
  const [selectedLanguage, setSelectedLanguage] =
    React.useState<ScheduleLanguage>('de')

  return (
    <Stack direction="column" spacing={1.5}>
      <LanguageTabs
        selectedLanguage={selectedLanguage}
        onLanguageChange={setSelectedLanguage}
      />
      <MessageInput selectedLanguage={selectedLanguage} />
    </Stack>
  )
}

export default MessageText
