import LocationIcon from 'src/shared/components/icons/LocationIcon'
import Preference from './Preference'

const Place = ({ preference }: { preference: string }) => (
  <Preference
    text={preference}
    icon={<LocationIcon size="small" variant="filled" />}
  />
)

export default Place
