import { useDeferredValue } from 'react'

import { useServiceTimeMessagesQuery } from 'src/entities/service-time/queries/serviceTime'
import { useCurrentDate } from 'src/shared/lib/context/state/atoms/currentDate'
import { useSelectedServiceTime } from 'src/shared/lib/context/state/atoms/selectedServiceTime'

export const useServiceTimeMessage = () => {
  const date = useDeferredValue(useCurrentDate())
  const { data: serviceTimeMessages } = useServiceTimeMessagesQuery(date)
  const serviceTime = useSelectedServiceTime()

  return serviceTimeMessages.find(stm => stm.id === serviceTime?.id)?.message
}
