import React from 'react'
import { compose, filter } from 'ramda'

import {
  useSuspenseQueries,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { useRestaurantCacheKeyFactory } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import { create5MinuteCronTime } from 'src/shared/lib/api/services/api'
import {
  convertToTimezone,
  getBoundaries,
} from 'src/shared/lib/range/services/date'
import { type TimeRangeInterface } from 'src/shared/lib/range/types/timeRange'
import { type CustomerInterface } from '../../customer/types/customer'
import useFeature from '../../info/hooks/useFeature'
import { type ReservationInterface } from '../../reservation/types/reservation'
import { fetchHotelStay, searchHotelStays } from '../api/hotelStayApi'
import {
  hasCustomer,
  isOnDate,
  splitHotelStay,
  type RoomStayInterface,
} from '../services/hotelRoomStay'
import { type HotelStayInterface } from '../types/hotelStay'

const HOTEL_STAY_CACHE_KEY = ['hotelStay', 'get']
const SEARCH_KEY = ['hotelStay', 'search']

const useHotelStayCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(HOTEL_STAY_CACHE_KEY)

const useHotelStaySearchCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(SEARCH_KEY)

export const useHotelStaysSearchQuery = ({
  customerId,
  date,
}: {
  customerId?: CustomerInterface['id']
  date?: Date
}) => {
  const apiClient = useApiClient()
  const keyFactory = useHotelStaySearchCacheKeyFactory()

  const hotelStaysEnabled = useFeature('hotelStay')

  const dateRange = React.useMemo(() => {
    if (!date) return undefined

    const boundaries = getBoundaries(date)

    return [
      convertToTimezone(boundaries[0]),
      convertToTimezone(boundaries[1]),
    ] as TimeRangeInterface
  }, [date])

  return useSuspenseQuery({
    queryKey: keyFactory([customerId, dateRange]),
    queryFn: () =>
      hotelStaysEnabled
        ? searchHotelStays(apiClient.post)({ customerId, dateRange })
        : [],
    staleTime: 5 * 60 * 1000,
    refetchInterval: 5 * 60 * 1000,
  })
}

const every5Minutes = create5MinuteCronTime()
const getRefetchInterval = () => every5Minutes.getTimeout()

export const useHotelStays = <T = HotelStayInterface>(
  hotelStayIds: HotelStayInterface['uuid'][],
  options: Partial<
    UseSuspenseQueryOptions<HotelStayInterface, unknown, T>
  > = {},
) => {
  const { post } = useApiClient()
  const keyFactory = React.useDeferredValue(useHotelStayCacheKeyFactory())

  return useSuspenseQueries({
    queries: React.useDeferredValue(hotelStayIds).map(id => ({
      queryKey: keyFactory([id]),
      queryFn: () => fetchHotelStay(post)(id),
      retryOnMount: false,
      refetchOnMount: false,
      refetchInterval: getRefetchInterval,
      staleTime: 300_000,
      ...options,
    })),
    combine: results => results.map(result => result.data).flat(),
  })
}

export const useRoomStays = (date: Date) => {
  const { data: hotelStaysIds } = useHotelStaysSearchQuery({ date })

  return useHotelStays(hotelStaysIds, {
    select: splitHotelStay,
  })
}

export const useRoomStaysPrefetch = useRoomStays

export const useReservationRoomStays = (
  reservation: ReservationInterface,
): RoomStayInterface[] => {
  const {
    dateRange: [startDate],
    guest: { customerId },
  } = reservation

  const roomStays = useRoomStays(React.useDeferredValue(startDate))

  return React.useMemo(() => {
    if (!customerId) return []

    return compose(
      hasCustomer(customerId),
      filter(isOnDate(startDate)),
    )(roomStays)
  }, [customerId, startDate, roomStays])
}
