import { ButtonBase, Card, Stack, Typography } from '@mui/material'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { formatTime } from 'src/shared/lib/range/services/date'

const ServiceTime = ({
  serviceTime,
  onClick,
}: {
  serviceTime: ServiceTimeInterface
  onClick: (timeRange: ServiceTimeInterface) => void
}) => {
  const startTime = formatTime(serviceTime.timeRange[0])
  const endTime = formatTime(serviceTime.timeRange[1])

  return (
    <ButtonBase>
      <Card
        sx={{ p: 0.5, flex: 1 }}
        variant="outlined"
        onClick={() => onClick(serviceTime)}
      >
        <Stack direction="column" spacing={1} sx={{ alignItems: 'flex-start' }}>
          <Typography variant="h6">{serviceTime.name}</Typography>
          <Typography variant="body2">
            {startTime} - {endTime}
          </Typography>
        </Stack>
      </Card>
    </ButtonBase>
  )
}

export default ServiceTime
