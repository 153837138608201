import { includes, prepend, unless } from 'ramda'

import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ANGLE_STEP } from 'src/entities/table/services/tableGrid'
import SingleSelectDropdown from 'src/shared/components/form/inputs/SingleSelectDropdown'
import { type FloorPlanTableInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'

const defaultAngles = new Array(165 / ANGLE_STEP + 1)
  .fill(1)
  .map((key, index) => index * ANGLE_STEP)

const size = 'medium'

const Rotation = ({
  onChange,
}: {
  onChange: (table: FloorPlanTableInterface) => void
}) => {
  const { t } = useTranslation('', { keyPrefix: 'settings.tables' })

  const { watch, handleSubmit } = useFormContext<FloorPlanTableInterface>()
  const { field } = useController<FloorPlanTableInterface, 'rotation'>({
    name: 'rotation',
    rules: {
      onChange: handleSubmit(onChange),
    },
  })

  const label = t('tableRotation')

  const currentValue = watch('rotation')

  const choices = unless(
    includes(currentValue),
    prepend(currentValue),
  )(defaultAngles)

  return (
    <SingleSelectDropdown
      size={size}
      label={label}
      {...field}
      options={choices}
      renderOption={Math.round}
    />
  )
}

export default Rotation
