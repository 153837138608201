/* eslint-disable react-hooks/rules-of-hooks */
import { assocPath, cond, differenceWith, identity, T } from 'ramda'

import { v4 } from 'uuid'

import {
  FloorPlanElementType,
  type FloorPlanElementInterface,
  type FloorPlanTableInterface,
  type FurnitureInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'
import { areInsideArea } from '../../area/services/area'
import { type AreaInterface } from '../../area/types/area'
import { setName } from '../../table/services/table'

const hasType =
  (type: FloorPlanElementType) => (element?: FloorPlanElementInterface) =>
    element?.type === type

export const isTable = (
  element?: FloorPlanElementInterface,
): element is FloorPlanTableInterface =>
  hasType(FloorPlanElementType.Table)(element)
export const isFurniture = (
  element?: FloorPlanElementInterface,
): element is FurnitureInterface =>
  hasType(FloorPlanElementType.Furniture)(element)

export const SECONDARY_TABLE_BG = '#e8e8e8'
export const PRIMARY_TABLE_BG = '#88d7c480'
export const TAKEN_TABLE_BG = '#f99100cc'
export const DEFAULT_FURNITURE_BG = '#a4a4a4'

interface GetTableColorsOptions {
  hasCheckedInOccupancies: boolean
  hasOccupancies: boolean
}

export const getTableColor = ({
  hasCheckedInOccupancies,
  hasOccupancies,
}: GetTableColorsOptions) =>
  (hasCheckedInOccupancies && TAKEN_TABLE_BG) ||
  (hasOccupancies && SECONDARY_TABLE_BG) ||
  PRIMARY_TABLE_BG

export const TABLE_BORDER = '#4D4D4D80'
export const SELECTED_TABLE_BORDER = '#3c3c3c'

const quantifyBool = (x: boolean) => (x ? 1 : 0)

export const getTableBorderWidth = (
  isSelected = false,
  isSemiSelected = false,
) => Math.min(1 + quantifyBool(isSelected) + quantifyBool(isSemiSelected), 2)

export const getTableBorderColor = (isSelected = false) =>
  isSelected ? SELECTED_TABLE_BORDER : TABLE_BORDER

export const getTableBorder = (isSelected = false, isSemiSelected = false) => ({
  stroke: getTableBorderColor(isSelected),
  strokeWidth: getTableBorderWidth(isSelected, isSemiSelected),
})

export const isInArea =
  (area?: AreaInterface) => (element: FloorPlanElementInterface) =>
    areInsideArea([element])(area)

export const setArea = (area?: AreaInterface) =>
  assocPath<number, FloorPlanElementInterface>(['areaId'], area?.id ?? 0)

export const assignUuid = <T extends FloorPlanElementInterface>(element: T) =>
  assocPath(['uuid'], v4(), element)

export const setTableName = (name: string) =>
  cond<FloorPlanElementInterface[], FloorPlanElementInterface>([
    [isTable, setName(name)],
    [T, identity],
  ])

const haveSameId = <T extends FloorPlanElementInterface>(x: T, y: T) =>
  x.id === y.id
const areIdentical = <T extends FloorPlanElementInterface>(x: T, y: T) =>
  x === y
export const getDeletedElements = differenceWith(haveSameId)
export const getModifiedElements = differenceWith(areIdentical)
