import React from 'react'
import { propEq } from 'ramda'

import {
  type TableInterface,
  type TableNoteInterface,
} from 'src/entities/table/types/table'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import NoteEditDialog from './NoteEditDialog/NoteEditDialog'
import NotePreview from './NotePreview/NotePreview'

interface NoteProps {
  tableNotes: TableNoteInterface[]
  table: TableInterface
  onSave: (tableNote: TableNoteInterface) => void
}

const Note = ({ tableNotes, table, onSave }: NoteProps) => {
  const note = React.useMemo(
    () => tableNotes.find(propEq(table.id, 'tableId'))?.note ?? '',
    [tableNotes, table.id],
  )

  const { handleClose, handleOpen, open } = useDialog()

  const saveHandler = (newValue: string) => {
    onSave({
      tableId: table.id!,
      note: newValue,
    })
  }

  const deleteHandler = () => saveHandler('')

  return (
    <>
      <NotePreview note={note} onClick={handleOpen} />
      <NoteEditDialog
        onSave={saveHandler}
        onDelete={deleteHandler}
        initValue={note}
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

export default Note
