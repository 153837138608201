import React from 'react'
import { type ChipProps } from '@mui/material'

import { type LabelInterface } from 'src/entities/label/types/label'
import Label from 'src/shared/components/common/Label'

const SmallLabel = (
  {
    label,
    sx,
    ...props
  }: {
    label: LabelInterface
  } & Partial<Omit<ChipProps, 'label'>>,
  ref: React.ForwardedRef<HTMLDivElement>,
) => (
  <Label
    color={label.color}
    label={label.name}
    size="small"
    ref={ref}
    sx={{
      height: 24,
      typography: 'labelExtrasmall',
      py: 0.5,
      px: 1.5,
      m: 0.25,
      ...sx,
    }}
    {...props}
  />
)

export default React.forwardRef(SmallLabel)
