import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { type ButtonProps } from '@mui/material'

import SquareIconButton from '../../SquareIconButton'

const DeleteButton = (props: ButtonProps) => (
  <SquareIconButton color="neutral" variant="outlined" {...props}>
    <DeleteRoundedIcon fontSize="medium" />
  </SquareIconButton>
)

export default DeleteButton
