import React, { useCallback } from 'react'

import { useLabelsQuery } from 'src/entities/label/queries/label'
import { type PersistedLabelInterface } from 'src/entities/label/types/label'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { type DrawerColor } from 'src/widgets/SidebarDrawer/drawerColor'
import Labels from './Labels'

interface LabelsViewProps {
  onChange: (labels: PersistedLabelInterface['id'][]) => void
  selectedReservation: ReservationInterface
  open: boolean
  onOpen: () => void
  onClose: () => void
  drawerColor: DrawerColor
}

const LabelsView = ({
  selectedReservation,
  onChange,
  ...props
}: LabelsViewProps) => {
  const { data: labels } = useLabelsQuery()

  const changeHandler = useCallback(
    (labelArray: PersistedLabelInterface[]) =>
      onChange(labelArray.map(l => l.id)),
    [onChange],
  )

  const selectedLabels = React.useMemo(
    () => labels.filter(l => selectedReservation.labels.includes(l.id)),
    [labels, selectedReservation.labels],
  )

  return (
    <Labels
      onChange={changeHandler}
      value={selectedLabels}
      labels={labels}
      {...props}
    />
  )
}

export { LabelsView }
