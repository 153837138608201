import { useCallback } from 'react'
import { Stack } from '@mui/material'

import { createFileRoute } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import CustomerMerge from 'src/pages/CustomerMerge'
import { FullScreenModalHeader } from 'src/shared/components/dialogs/FullScreenModal/components/FullScreenModalHeader'
import { withCustomerMergeReducerProvider } from 'src/shared/lib/context/reducer/customerMergeReducer'

export const Route = createFileRoute('/restaurants/$restaurantId/merge-guests')(
  {
    component: RouteComponent,
  },
)

const CustomerMergeWithReducer = withCustomerMergeReducerProvider(CustomerMerge)

function RouteComponent() {
  const { t } = useTranslation()
  const { restaurantId } = Route.useParams()
  const navigate = Route.useNavigate()

  const onClose = useCallback(
    () =>
      navigate({
        to: '/restaurants/$restaurantId/reservations',
        params: { restaurantId },
      }),
    [navigate, restaurantId],
  )

  return (
    <Stack width={1}>
      <FullScreenModalHeader
        title={t('angular.guests_merge', 'Merge guests')}
        onClose={onClose}
      />
      <CustomerMergeWithReducer />
    </Stack>
  )
}
