import { createFileRoute } from '@tanstack/react-router'

import Rooms from 'src/pages/Rooms/Rooms'
import { ConfigScroll } from './-ConfigScroll'

export const Route = createFileRoute('/restaurants/$restaurantId/config/rooms')(
  {
    component: RouteComponent,
  },
)

function RouteComponent() {
  return (
    <ConfigScroll>
      <Rooms />
    </ConfigScroll>
  )
}
