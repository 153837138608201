import { Tooltip, Typography, type TooltipProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type Room, type Shift } from 'src/entities/schedule/types/scheduleApi'
import CancelIcon from 'src/shared/components/icons/CancelIcon'
import LockedPadlockIcon from 'src/shared/components/icons/LockedPadlockIcon'
import LockOnlineIcon from 'src/shared/components/icons/LockOnlineIcon'
import {
  isClosed,
  isLocked,
  isLockedOnline,
  type TimelineShift,
} from '../../../../../../timelineShift'

const ShiftIconTooltip = (props: TooltipProps) => (
  <Tooltip enterTouchDelay={0} placement="right" arrow {...props} />
)

interface ShiftHeaderProps {
  shift: TimelineShift
  shifts: Shift[]
  rooms: Room[]
}

const getTextColor = (shift: TimelineShift) => {
  if (isLocked(shift)) return 'error.main'
  if (isClosed(shift)) return 'text.quaternary'

  return 'text.primary'
}

const ShiftIcon = ({ shift }: Pick<ShiftHeaderProps, 'shift'>) => {
  const { t } = useTranslation()

  if (isLockedOnline(shift))
    return (
      <ShiftIconTooltip title={t('schedule.booking_lock.online_only')}>
        <LockOnlineIcon color="error.main" size="small" />
      </ShiftIconTooltip>
    )
  if (isLocked(shift))
    return (
      <ShiftIconTooltip title={t('schedule.booking_lock.online_and_offline')}>
        <LockedPadlockIcon color="error.main" variant="filled" size="small" />
      </ShiftIconTooltip>
    )
  if (isClosed(shift))
    return (
      <ShiftIconTooltip
        title={t('schedule.overview.closure.properties.closed', 'Closed')}
      >
        <CancelIcon color="text.secondary" size="small" />
      </ShiftIconTooltip>
    )

  return null
}

const ShiftHeader = ({ shift, rooms, shifts }: ShiftHeaderProps) => {
  const roomId = shifts.find(s => s.id === shift.id)?.roomId
  const roomName = roomId && rooms.find(r => r.id === roomId)?.name

  const name = roomName ? `${shift.name} (${roomName})` : shift.name

  return (
    <>
      <Typography variant="labelTiny" sx={{ color: getTextColor(shift) }}>
        {name}
      </Typography>
      <ShiftIcon shift={shift} />
    </>
  )
}

export default ShiftHeader
