import type React from 'react'
import { Box, Typography } from '@mui/material'

import { timeRangeToString } from 'src/shared/lib/range/services/timeRange'
import type { TimeRangeInterface } from 'src/shared/lib/range/types/timeRange'

interface IntersectionLineProps {
  name: string
  dateOverlap: React.ReactNode
  timeOverlap: TimeRangeInterface
}

const IntersectionLineContainer = ({
  name,
  dateOverlap,
  timeOverlap,
}: IntersectionLineProps) => (
  <Box
    sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(0, 192px))',
      rowGap: 3,
    }}
    typography="body3"
    color="error.main"
  >
    <Typography variant="inherit">{name}</Typography>
    {dateOverlap}
    <Typography variant="inherit">{timeRangeToString(timeOverlap)}</Typography>
  </Box>
)

export default IntersectionLineContainer
