import { useCallback } from 'react'
import { identity } from 'ramda'

import { useQueryClient, type QueryKey } from '@tanstack/react-query'

export const useOptimisticUpdate = <U>() => {
  const queryClient = useQueryClient()

  return useCallback(
    <T extends U = U>(
      cacheKey: QueryKey,
      updater: (oldItem: T) => T,
      initValue?: T,
    ) =>
      queryClient.setQueryData<T>(cacheKey, oldItem => {
        const item = oldItem ?? initValue

        if (!item) return oldItem

        return updater(item)
      }),
    [queryClient],
  )
}

export const useCacheInsert = <T>() => {
  const update = useOptimisticUpdate<T>()

  return useCallback(
    (cacheKey: QueryKey, newItem: T) => update(cacheKey, identity<T>, newItem),
    [update],
  )
}

export const useCacheUpsert = <T>() => {
  const update = useOptimisticUpdate<T>()

  return useCallback(
    (cacheKey: QueryKey, newItem: T) =>
      update(cacheKey, () => newItem, newItem),
    [update],
  )
}
