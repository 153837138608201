import { Box, Divider, Tab, Tabs } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'

export enum SearchTabsEnum {
  Reservations = 'reservations',
  Customers = 'guests',
}

const SearchTabs = ({
  selectedView,
  setSelectedView,
}: {
  selectedView: SearchTabsEnum
  setSelectedView: (view: SearchTabsEnum) => void
}) => {
  const { t } = useTranslation('', { keyPrefix: 'search.tabs' })
  const changeHandler = (_e: unknown, value: SearchTabsEnum) => {
    setSelectedView(value)
  }

  return (
    <Box>
      <Tabs value={selectedView} onChange={changeHandler}>
        {Object.values(SearchTabsEnum).map(view => (
          <Tab
            {...createTestIdProps(`tab-${view}`)}
            value={view}
            label={t(view, {
              defaultValue: view,
              tDescription: `${view} search results tab label`,
            })}
            key={view}
          />
        ))}
      </Tabs>
      <Divider />
    </Box>
  )
}

export default SearchTabs
