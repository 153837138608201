import { useCallback } from 'react'
import { Box } from '@mui/material'

import { useNavigate } from '@tanstack/react-router'
import { PiHouseLineBold } from 'react-icons/pi'

import {
  useAllRestaurantsQuery,
  useCurrentRestaurant,
} from 'src/entities/restaurant/queries/restaurants'
import { type RestaurantInterface } from 'src/entities/restaurant/types/restaurant'
import SelectOrDrawer from 'src/pages/CustomerPreview/Reservations/Header/Filters/HotelStays/SelectOrDrawer'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import SelectDropdownItem from 'src/shared/components/form/inputs/SelectDropdownItem'
import { AutocompleteSelect } from '../AutocompleteSelect'
import { useRestaurantSelectFits } from '../hooks/useRestaurantSelectFits'
import RestaurantItem from '../RestaurantItem'

export const RestaurantSelect = () => {
  const { data: restaurants } = useAllRestaurantsQuery()
  const currentRestaurant = useCurrentRestaurant()

  const restaurantSelectFits = useRestaurantSelectFits()
  const { handleClose, handleOpen, open } = useDialog()
  const navigate = useNavigate()

  const onRestaurantSelect = useCallback(
    (restaurant: RestaurantInterface) =>
      navigate({
        to: '/restaurants/$restaurantId/reservations',
        params: { restaurantId: restaurant.id },
      }),
    [navigate],
  )

  if (restaurantSelectFits && restaurants.length >= 10) {
    return (
      <AutocompleteSelect
        onChange={onRestaurantSelect}
        options={restaurants}
        optionIdKey="id"
        optionToString={r => r.name}
        renderOption={r => <RestaurantItem restaurant={r} />}
        sx={{
          flex: 1,
          maxWidth: 320,
          height: 40,
        }}
        disableClearable
        value={currentRestaurant}
      />
    )
  }

  return (
    <SelectOrDrawer
      value={currentRestaurant}
      isOpen={open}
      onClose={handleClose}
      onOpen={handleOpen}
      onSelect={onRestaurantSelect}
      renderValue={r => (restaurantSelectFits ? r?.name : null)}
      hideBackdrop={false}
      drawerIcon={
        <Box sx={{ ml: 1.25, fontSize: '1.5em' }}>
          <PiHouseLineBold />
        </Box>
      }
    >
      {restaurants.map(r => (
        <SelectDropdownItem disableGutters key={r.id} value={r}>
          <RestaurantItem restaurant={r} />
        </SelectDropdownItem>
      ))}
    </SelectOrDrawer>
  )
}
