import { useTranslation } from 'react-i18next'

import { useDeleteBookingLockMutation } from 'src/entities/schedule/queries/schedule'
import {
  type BookingLock,
  type Schedule,
} from 'src/entities/schedule/types/scheduleApi'
import { getBookingLockProperties } from './service'
import { useDefaultMutationHandlers } from '../../ShiftConfiguration/hooks/useDefaultMutationHandlers'
import { CreateScheduleButton } from '../components'
import { ScheduleCard, ScheduleCardWrapper } from '../components/ScheduleCard'
import { useExceptionDateRangeFormatter } from '../service'

interface BookingLockSectionProps {
  schedule: Schedule
  onEditLock: (lock?: BookingLock) => void
}

export const BookingLockSection = ({
  schedule,
  onEditLock,
}: BookingLockSectionProps) => {
  const { t } = useTranslation()

  const { mutateAsync: deleteLock } = useDeleteBookingLockMutation()
  const { onError } = useDefaultMutationHandlers()

  const formatDateRange = useExceptionDateRangeFormatter()

  return (
    <>
      <ScheduleCardWrapper>
        {schedule.locks.map(l => (
          <ScheduleCard
            key={l.id}
            onEdit={() => onEditLock(l)}
            onDelete={() => deleteLock(l, { onError })}
            properties={getBookingLockProperties({
              schedule,
              lock: l,
              formatDateRange,
              t,
            })}
          />
        ))}
      </ScheduleCardWrapper>
      <CreateScheduleButton onClick={() => onEditLock()}>
        {t('schedule.overview.booking_lock.create', 'Create Lock')}
      </CreateScheduleButton>
    </>
  )
}
