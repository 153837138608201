import { useMemo } from 'react'

import { GRID_STEP } from 'src/entities/table/services/tableGrid'
import {
  type FloorPlanElementInterface,
  type SizeInterface,
} from './types/floorPlanElement'

export const useMaxPosition = (
  tables: readonly FloorPlanElementInterface[],
  zoom = 1,
): SizeInterface =>
  useMemo(() => {
    const elementsRightBorders = tables.map(t => t.position.x + t.size.width)
    const elementsBottomBorders = tables.map(t => t.position.y + t.size.height)

    return {
      width: Math.max(...elementsRightBorders) * zoom + GRID_STEP,
      height: Math.max(...elementsBottomBorders) * zoom + GRID_STEP,
    }
  }, [tables, zoom])
