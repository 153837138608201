import { z } from 'zod'

import {
  getBoundaries,
  getNowInRestaurantTimezone,
} from 'src/shared/lib/range/services/date'
import type { ServiceTimeInterface } from '../../config/types/configApi'

export const serviceTimeMessageSchema = z.object({
  id: z.number(),
  message: z.coerce.string(),
})

export type ServiceTimeMessage = z.infer<typeof serviceTimeMessageSchema>
export const serviceTimeMessagesSchema = z.array(serviceTimeMessageSchema)

export interface EmptyServiceTime
  extends Omit<ServiceTimeInterface, 'id' | 'timeRange'> {
  id: null
  timeRange: [null, null]
}

export const emptyServiceTime: EmptyServiceTime = {
  id: null,
  name: '',
  timeRange: [null, null],
}
export const createFallbackServiceTime = (): ServiceTimeInterface => {
  const [start, end] = getBoundaries(getNowInRestaurantTimezone())

  return {
    id: 0,
    name: '',
    timeRange: [start, end],
  }
}
