import { Fragment } from 'react'
import { TableRow as MuiTableRow, type TableRowProps } from '@mui/material'

import { flexRender, type Row } from '@tanstack/react-table'

import { createTestIdProps } from 'src/app/hoc/withDomId'

const TableRow = <T,>({
  row,
  ...props
}: {
  row: Row<T>
} & Partial<TableRowProps>) => (
  <MuiTableRow {...createTestIdProps(`row-${row.id}`)} {...props}>
    {row.getVisibleCells().map(cell => (
      <Fragment key={cell.id}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </Fragment>
    ))}
  </MuiTableRow>
)

export default TableRow
