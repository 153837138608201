import { Chip } from '@mui/material'

import { ReservationStatusEnum } from 'src/entities/reservation/types/reservation'

const StatusIndicator = ({
  status = ReservationStatusEnum.Cancelled,
  appeared = false,
  seatCount,
}: {
  status?: ReservationStatusEnum
  appeared?: boolean
  seatCount: number
}) => {
  const bgcolor =
    (status === ReservationStatusEnum.Cancelled && 'neutral') ||
    (appeared === false && 'error') ||
    'primary'

  return (
    <Chip
      color={bgcolor}
      variant="filled"
      label={seatCount}
      size="small"
      sx={{ p: 1 }}
    />
  )
}

export default StatusIndicator
