import { assocPath, dissocPath } from 'ramda'
import { Button, Stack, Typography } from '@mui/material'

import { useController, type Control, type FieldPath } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import TrashButton from 'src/shared/components/buttons/TrashButton'
import FormErrorText from 'src/shared/components/form/common/FormErrorText/FormErrorText'
import MinutesPicker from 'src/shared/components/form/inputs/MinutesPicker/MinutesPicker'
import PlusIcon from 'src/shared/components/icons/PlusIcon'
import {
  addToDate,
  getEndOf,
  getNowInRestaurantTimezone,
} from 'src/shared/lib/range/services/date'
import { type PhoneConfirmationsFormValues } from '../service/getPhoneConfirmationsFormSchema'

interface PhoneConfirmationsWeekdayProps {
  control: Control<PhoneConfirmationsFormValues>
  name: FieldPath<PhoneConfirmationsFormValues>
  disabled: boolean
}

const quaterToMidnight = addToDate(
  -15,
  'minutes',
  getEndOf('day')(getNowInRestaurantTimezone()),
)

export const PhoneConfirmationsWeekday = ({
  control,
  name,
  disabled,
}: PhoneConfirmationsWeekdayProps) => {
  const { t } = useTranslation()

  const { field, fieldState } = useController({
    name: name as 'monday.acceptanceTimes',
    control,
  })

  const nothingAddedError = Boolean(fieldState.error && !field.value.length)

  const setStartTime = (idx: number) => (newStartTime: Date) =>
    field.onChange(
      assocPath(
        [idx],
        [newStartTime, field.value[idx]?.[1] ?? null],
        field.value,
      ),
    )

  const setEndTime = (idx: number) => (newEndTime: Date) =>
    field.onChange(
      assocPath(
        [idx],
        [field.value[idx]?.[0] ?? null, newEndTime],
        field.value,
      ),
    )

  return (
    <Stack useFlexGap gap={1}>
      {field.value.map((timeRange, idx) => {
        const [start, end] = timeRange

        const hasError = !disabled && !!fieldState.error
        const hasStartError = hasError && timeRange[0] === null
        const hasEndError = hasError && timeRange[1] === null

        return (
          <Stack key={idx} direction="row" gap={1} alignItems="start">
            <MinutesPicker
              onChange={setStartTime(idx)}
              value={start}
              maxValue={quaterToMidnight}
              error={hasStartError}
              helperText={hasStartError && t('common.validation.required')}
              fullWidth
              sx={{ maxWidth: 220 }}
              disabled={disabled}
            />
            <Typography mt={1.25}>–</Typography>
            <MinutesPicker
              onChange={setEndTime(idx)}
              value={end}
              disabled={disabled || !start}
              minValue={addToDate(15, 'minutes', start)}
              error={hasEndError}
              helperText={hasEndError && t('common.validation.required')}
              fullWidth
              sx={{ maxWidth: 220 }}
            />
            {field.value.length > 1 && (
              <TrashButton
                onClick={() => field.onChange(dissocPath([idx], field.value))}
                disabled={disabled}
              />
            )}
          </Stack>
        )
      })}
      {!disabled && (
        <Stack>
          <Button
            startIcon={<PlusIcon />}
            sx={{ alignSelf: 'start', px: 0 }}
            onClick={() =>
              field.onChange([...(field.value ?? []), [null, null]])
            }
          >
            {t('schedule.phone_confirmations.add_range', {
              defaultValue: 'Add range',
              tDescription:
                'Label for the `Add range` button in the reservations confirmation form',
            })}
          </Button>
          <FormErrorText
            errorText={
              nothingAddedError &&
              t(
                'schedule.phone_confirmations.errors.no_range_selected',
                'Add at least one timerange',
              )
            }
          />
        </Stack>
      )}
    </Stack>
  )
}
