import type React from 'react'
import { Stack, Typography, type StackProps } from '@mui/material'

interface ReservationDrawerSectionProps extends StackProps {
  title: string
  icon?: React.ReactNode
  onHeaderClick?: () => void
}

const ReservationDrawerSection = ({
  onHeaderClick: onClick,
  title,
  icon,
  children,
  ...props
}: ReservationDrawerSectionProps) => (
  <Stack direction="column" gap={0.5} {...props}>
    <Stack
      direction="row"
      justifyContent="space-between"
      {...(onClick && { sx: { cursor: 'pointer' }, onClick })}
    >
      <Typography
        component="label"
        variant="labelTiny"
        {...(onClick && { sx: { cursor: 'pointer' } })}
      >
        {title}
      </Typography>
      {icon}
    </Stack>
    {children}
  </Stack>
)

export default ReservationDrawerSection
