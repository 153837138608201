import { compose, flip, mapObjIndexed, values } from 'ramda'

import { type Setting } from './setting'
import { type SettingsKeys } from '../../config/types/configApi'

const SETTING_ON = 1
const SETTING_OFF = 2

const fromValueNamePair = (value: boolean, name: SettingsKeys): Setting => ({
  name,
  value,
})

export const fromApi = compose(values, mapObjIndexed(fromValueNamePair))

export const toApi = (settings: Setting[]) =>
  Object.fromEntries(
    settings.map(setting => [
      setting.name,
      setting.value ? SETTING_ON : SETTING_OFF,
    ]),
  )

export const fromEnum = flip(fromValueNamePair)
