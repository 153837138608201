import { type Schedule } from 'src/entities/schedule/types/scheduleApi'
import { getDayOfWeek } from 'src/shared/lib/range/services/date'
import { includesTime } from 'src/shared/lib/range/services/timeRange'

const isOnWeekdays = (weekdays: number[], day: Date) =>
  weekdays.includes(getDayOfWeek(day))

export const getShiftsOnDay = (
  { shiftExceptions, shifts }: Pick<Schedule, 'shifts' | 'shiftExceptions'>,
  day: Date,
) =>
  shifts.filter(({ id, weekdays }) => {
    if (!isOnWeekdays(weekdays, day)) return false

    const hasExceptionThatDisablesShift = shiftExceptions.some(
      se =>
        se.shiftId === id &&
        se.shiftDisabled &&
        includesTime([se.effectivePeriod.start, se.effectivePeriod.end], day),
    )

    return !hasExceptionThatDisablesShift
  })

export const getEventsOnDay = (
  { events }: Pick<Schedule, 'events'>,
  day: Date,
) =>
  events.filter(
    ({ weekdays, effectivePeriod: { start, end } }) =>
      isOnWeekdays(weekdays, day) && includesTime([start, end], day),
  )
