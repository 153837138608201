import { useMemo } from 'react'

import { useMeQuery } from 'src/entities/me/queries/me'
import { useRestaurantHash } from 'src/shared/lib/context/global/useRestaurantHash'

export const useAllRestaurantsQuery = () =>
  useMeQuery({ select: r => r.restaurants })

export const useCurrentRestaurant = () => {
  const restaurantHash = useRestaurantHash()
  const { data: allRestaurants } = useAllRestaurantsQuery()

  return useMemo(
    () => allRestaurants.find(({ hash }) => hash === restaurantHash)!,
    [allRestaurants, restaurantHash],
  )
}

export const useRestaurantsInChain = () => {
  const { data: restaurants } = useAllRestaurantsQuery()
  const currentRestaurant = useCurrentRestaurant()

  return useMemo(
    () =>
      currentRestaurant.chaineId
        ? restaurants.filter(r => r.chaineId === currentRestaurant.chaineId)
        : [currentRestaurant],
    [currentRestaurant, restaurants],
  )
}

export const useIsAdminQuery = () => useMeQuery({ select: r => r.superadmin })

export const useChainRestaurantHashes = () => {
  const restaurants = useRestaurantsInChain()

  return useMemo(() => restaurants.map(r => r.hash), [restaurants])
}
