import {
  allPass,
  always,
  any,
  anyPass,
  complement,
  compose,
  either,
  filter,
  ifElse,
  intersection,
  isEmpty,
  isNil,
  keys,
  map,
  prop,
} from 'ramda'

import {
  areRangesEqual,
  isWithinRange,
} from 'src/shared/lib/range/services/range'
import {
  areOverlapping,
  includesTime,
} from 'src/shared/lib/range/services/timeRange'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import {
  FiltersEnum,
  type BooleanClientFilter,
  type ReservationClientFilters,
} from 'src/shared/lib/reducer/service/reservationsFilter'
import { ensureReferences } from 'src/shared/lib/state/service/stateSanitizer'
import { isNotFalsy } from 'src/shared/lib/zod/zod'
import { type FloorPlanTableInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'
import { hasCheckInStatus } from './checkInStatus'
import { getArea } from './occupancy'
import { hasSource, hasType } from './type'
import { type AreaInterface } from '../../area/types/area'
import { getLabelsByIds } from '../../label/services/labels'
import { type LabelInterface } from '../../label/types/label'
import { type TableInterface } from '../../table/types/table'
import {
  ReservationNotesEnum,
  ReservationSourceEnum,
  ReservationTypesEnum,
  type ReservationInterface,
} from '../types/reservation'

export const typeFilterFromClientFilters = ({
  [FiltersEnum.source]: sources,
  [FiltersEnum.serial]: isSerial,
}: ReservationClientFilters): ReservationTypesEnum[] => {
  const isSelf = sources.includes(ReservationSourceEnum.Self)

  const isExternal =
    sources.includes(ReservationSourceEnum.Online) ||
    sources.includes(ReservationSourceEnum.Google)

  return [
    ...(isSerial ? [ReservationTypesEnum.Serial] : []),
    ...(isSelf ? [ReservationTypesEnum.Self] : []),
    ...(isExternal ? [ReservationTypesEnum.External] : []),
  ]
}

export const clientFiltersFromTypeFilter = (
  typeFilter: ReservationTypesEnum[],
): Partial<ReservationClientFilters> => {
  const sources = [
    ...(typeFilter.includes(ReservationTypesEnum.Self)
      ? [ReservationSourceEnum.Self]
      : []),
    ...(typeFilter.includes(ReservationTypesEnum.External)
      ? [ReservationSourceEnum.Online, ReservationSourceEnum.Google]
      : []),
  ]

  return {
    [FiltersEnum.source]: sources,
    [FiltersEnum.serial]:
      typeFilter.includes(ReservationTypesEnum.Serial) || null,
  }
}

export const NONE_FILTER = { type: 'none' }
export const isNoneFilterValue = <T>(
  value: T | NoneFilter,
): value is NoneFilter => value === NONE_FILTER

const booleanFlagsIntoFilter = <
  T,
  U extends BooleanClientFilter,
>(filterNameToValue: { [key in U]: T }) => {
  const filterNames = keys(filterNameToValue)

  return (filters: ReservationClientFilters): (T | NoneFilter)[] => {
    const filterValues = filterNames.map(filterName => filters[filterName])
    const isAnyNull = filterValues.some(value => value === null)
    const isAnyFalse = filterValues.some(value => value === false)

    const columnFilterValues = filterNames
      .filter(filterName => filters[filterName])
      .map(filterName => filterNameToValue[filterName])

    return [
      ...columnFilterValues,
      ...(!isAnyNull && isAnyFalse ? [NONE_FILTER] : []),
    ]
  }
}

const filterToBooleanFlags = <
  T,
  U extends BooleanClientFilter,
  V = { [key in U]: ReservationClientFilters[U] },
>(filterNameToValue: { [key in U]: T }) => {
  const filterNames = keys(filterNameToValue)

  return (columnFiltersValue: (T | NoneFilter)[]): V => {
    const containsNoneFilter = columnFiltersValue.includes(NONE_FILTER)
    const negativeValue = containsNoneFilter ? false : null

    if (
      containsNoneFilter &&
      columnFiltersValue.length - 1 === filterNames.length
    ) {
      return filterNames.reduce(
        (clientFilters, filterName) => ({
          ...clientFilters,
          [filterName]: null,
        }),
        {} as V,
      )
    }

    const appendClientFilter = (clientFilters: V, filterName: U) => ({
      ...clientFilters,
      [filterName]:
        columnFiltersValue.includes(filterNameToValue[filterName]) ||
        negativeValue,
    })

    return filterNames.reduce(appendClientFilter, {} as V)
  }
}

export const CLIENT_FILTER_TO_NOTES = {
  [FiltersEnum.hasChoices]: ReservationNotesEnum.Choices,
  [FiltersEnum.hasRestaurantNote]: ReservationNotesEnum.RestaurantNote,
  [FiltersEnum.hasGuestNote]: ReservationNotesEnum.GuestMessage,
} as const

export const noteFilterFromClientFilters = booleanFlagsIntoFilter(
  CLIENT_FILTER_TO_NOTES,
)
export const clientFiltersFromNoteFilter = filterToBooleanFlags(
  CLIENT_FILTER_TO_NOTES,
)

export enum LensFiltersEnum {
  Vip = 'vip',
  Ban = 'ban',
  Comment = 'comment',
  FoodPreference = 'food',
  PlacePreference = 'place',
  Language = 'language',
}

const CLIENT_FILTER_TO_INFO = {
  [FiltersEnum.isGuestVip]: LensFiltersEnum.Vip,
  [FiltersEnum.isGuestBanned]: LensFiltersEnum.Ban,
  [FiltersEnum.hasGuestComment]: LensFiltersEnum.Comment,
  [FiltersEnum.hasGuestFoodPreference]: LensFiltersEnum.FoodPreference,
  [FiltersEnum.hasGuestPlacePreference]: LensFiltersEnum.PlacePreference,
  [FiltersEnum.hasGuestLanguagePreference]: LensFiltersEnum.Language,
} as const

export const infoFilterFromClientFilters = booleanFlagsIntoFilter(
  CLIENT_FILTER_TO_INFO,
)

export const clientFiltersFromInfoFilter = filterToBooleanFlags(
  CLIENT_FILTER_TO_INFO,
)

const switchNullToNoneFilter = <T>(value: T | null) => value ?? NONE_FILTER
const switchNoneFilterToNull = <T>(value: T | NoneFilter) =>
  isNoneFilterValue(value) ? null : value

export const PAX_RANGE_FILTERS: DefinedRangeInterface<number>[] = [
  [1, 2],
  [3, 3],
  [4, 9],
  [9, Infinity],
]

export const seatCountFromClientFilters = (
  ranges: DefinedRangeInterface<number>[],
) =>
  ranges
    .map(range =>
      PAX_RANGE_FILTERS.find(testRange => areRangesEqual()(range, testRange)),
    )
    .filter(isNotFalsy)

export const getItemId = <T extends { id: unknown }>(
  item: T | null | undefined,
) => (!item ? null : item.id)

export const areaFilterFromClientFilters =
  (areas: AreaInterface[]) =>
  ({ [FiltersEnum.area]: filterAreas }: ReservationClientFilters) =>
    ensureReferences(getItemId, [...areas, null])(filterAreas).map(
      switchNullToNoneFilter,
    )

export const labelFilterFromClientFilters =
  (labels: LabelInterface[]) =>
  ({ [FiltersEnum.label]: filterLabels }: ReservationClientFilters) =>
    ensureReferences(getItemId, [...labels, null])(filterLabels).map(
      switchNullToNoneFilter,
    )

export const convertNoneFilter = <T>(arr: (T | NoneFilter)[]) =>
  arr.map(switchNoneFilterToNull)

export type NoneFilter = typeof NONE_FILTER

export const appendNoneFilterValue = <T>(list: T[]) => [...list, NONE_FILTER]

const satisfiesEmptyFilter =
  <T, U>(filterValue: (null | T)[]) =>
  (values: U[]) =>
    filterValue.includes(null) && !values.length

type FilterFn<T> = (
  filterValue: T,
) => (reservation: ReservationInterface) => boolean

export const emptyFilterGuard = <T>(
  filterFn: (filterValue: T) => (reservation: ReservationInterface) => boolean,
): FilterFn<T> => ifElse(isEmpty, always(always(true)), filterFn)

export const nullFilterGuard =
  <T>(
    filterFn: (
      filterValue: T,
    ) => (reservation: ReservationInterface) => boolean,
  ): FilterFn<T> =>
  filterValue => {
    if (filterValue) return filterFn(filterValue)

    return () => false
  }

const booleanFilter = (
  getValue: (reservation: ReservationInterface) => boolean,
) =>
  nullFilterGuard(
    (filterValue: boolean | null) => reservation =>
      getValue(reservation) === filterValue,
  )

export const checkInStatusFilter = emptyFilterGuard(hasCheckInStatus)

export const typeFilter = emptyFilterGuard(hasType)

export const serialFilter = booleanFilter(prop('serial'))

export const sourceFilter = emptyFilterGuard(hasSource)

export const areaFilter = (tables: TableInterface[], areas: AreaInterface[]) =>
  emptyFilterGuard(
    (filterAreas: ReservationClientFilters[FiltersEnum.area]) =>
      (reservation: ReservationInterface) => {
        const occupiedAreas = map(
          getArea(areas, tables),
          reservation.occupancies,
        ).filter(isNotFalsy)

        return (
          !isEmpty(intersection(occupiedAreas, filterAreas)) ||
          satisfiesEmptyFilter(filterAreas)(occupiedAreas)
        )
      },
  )

export const labelFilter = (
  getLabels: (reservation: ReservationInterface) => LabelInterface[],
) =>
  emptyFilterGuard((labels: ReservationClientFilters[FiltersEnum.label]) =>
    compose(
      either(
        compose(complement(isEmpty), intersection(labels)),
        satisfiesEmptyFilter(labels),
      ),
      getLabels,
    ),
  )

export const paxFilter = emptyFilterGuard(
  (paxRanges: DefinedRangeInterface<number>[]) =>
    (reservation: ReservationInterface) =>
      any(isWithinRange(reservation.seatCount), paxRanges),
)

export const choicesFilter = booleanFilter(
  reservation => !!reservation.choices.length,
)

export const restaurantNoteFilter = booleanFilter(
  reservation => !!reservation.restaurantNote,
)

export const guestNoteFilter = booleanFilter(
  reservation => !!reservation.guestMessage,
)

export const vipFilter = booleanFilter(reservation => reservation.guest.vip)

export const banFilter = booleanFilter(reservation => reservation.guest.ban)

export const guestCommentFilter = booleanFilter(
  reservation => !!reservation.guest.comment,
)

export const guestFoodPreferenceFilter = booleanFilter(
  reservation => !!reservation.guest.preferences.food,
)

export const guestPlacePreferenceFilter = booleanFilter(
  reservation => !!reservation.guest.preferences.place,
)

export const guestLanguagePreferenceFilter = booleanFilter(
  reservation => !!reservation.guest.languageCode,
)

export const NOTES_LENS_FILTERS = [
  ReservationNotesEnum.GuestMessage,
  ReservationNotesEnum.RestaurantNote,
  ReservationNotesEnum.Choices,
]

export const INFO_LENS_FILTERS = [
  LensFiltersEnum.Vip,
  LensFiltersEnum.Ban,
  LensFiltersEnum.Comment,
  LensFiltersEnum.FoodPreference,
  LensFiltersEnum.PlacePreference,
  LensFiltersEnum.Language,
]

export const timeRangeFilter = (
  timeRange: DefinedRangeInterface<Date> | undefined,
) => {
  if (!timeRange) return always(true)

  return (reservation: ReservationInterface) =>
    areOverlapping(timeRange, reservation.dateRange) ||
    includesTime(timeRange, reservation.dateRange[0])
}

export const createFilterGroup =
  <T extends keyof Partial<ReservationClientFilters>>(filterPairsObject: {
    [key in T]: (
      filterValue: ReservationClientFilters[key],
    ) => (reservation: ReservationInterface) => boolean
  }) =>
  (filters: ReservationClientFilters) => {
    const filterPairs = Object.entries(filterPairsObject) as [
      T,
      FilterFn<ReservationClientFilters[T]>,
    ][]

    const filterValues = filterPairs.map(([filterKey]) => filters[filterKey])
    const areAllEmpty = filterValues.every(either(isEmpty, isNil))

    if (areAllEmpty) return always(true)

    return anyPass(
      filterPairs.reduce(
        (filterFns, [filterKey, filterFn]) => [
          ...filterFns,
          filterFn(filters[filterKey]),
        ],
        [] as ((reservation: ReservationInterface) => boolean)[],
      ),
    )
  }

export const createBooleanFilterGroup =
  <T extends BooleanClientFilter>(filterPairsObject: {
    [key in T]: (
      filterValue: ReservationClientFilters[key],
    ) => (reservation: ReservationInterface) => boolean
  }) =>
  (filters: ReservationClientFilters) => {
    const filterPairs = Object.entries(filterPairsObject) as [
      T,
      FilterFn<ReservationClientFilters[T]>,
    ][]

    const filterGroup = createFilterGroup(filterPairsObject)(filters)

    const filterValues = filterPairs.map(([filterKey]) => filters[filterKey])
    const filtersIncludeFalse = filterValues.some(value => value === false)

    return anyPass([
      filterGroup,
      ...(filtersIncludeFalse
        ? [
            complement(
              anyPass(filterPairs.map(([, filterFn]) => filterFn(true))),
            ),
          ]
        : []),
    ])
  }

export const filterReservations =
  (
    tables: FloorPlanTableInterface[],
    areas: AreaInterface[],
    labels: LabelInterface[],
  ) =>
  (filters: ReservationClientFilters) =>
    filter(
      allPass(
        [
          checkInStatusFilter(filters[FiltersEnum.checkInStatus]),
          areaFilter(tables, areas)(filters[FiltersEnum.area]),
          labelFilter(reservation =>
            getLabelsByIds(labels)(reservation.labels),
          )(filters[FiltersEnum.label]),
          paxFilter(filters[FiltersEnum.pax]),
          createFilterGroup({
            [FiltersEnum.serial]: serialFilter,
            [FiltersEnum.source]: sourceFilter,
          })(filters),
          createBooleanFilterGroup({
            [FiltersEnum.hasChoices]: choicesFilter,
            [FiltersEnum.hasRestaurantNote]: restaurantNoteFilter,
            [FiltersEnum.hasGuestNote]: guestNoteFilter,
          })(filters),
          createBooleanFilterGroup({
            [FiltersEnum.isGuestVip]: vipFilter,
            [FiltersEnum.isGuestBanned]: banFilter,
            [FiltersEnum.hasGuestComment]: guestCommentFilter,
            [FiltersEnum.hasGuestFoodPreference]: guestFoodPreferenceFilter,
            [FiltersEnum.hasGuestPlacePreference]: guestPlacePreferenceFilter,
            [FiltersEnum.hasGuestLanguagePreference]:
              guestLanguagePreferenceFilter,
          })(filters),
        ].filter(isNotFalsy),
      ),
    )
