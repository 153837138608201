import React from 'react'
import { compose, filter, map } from 'ramda'
import { Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type AreaInterface } from 'src/entities/area/types/area'
import { type LabelInterface } from 'src/entities/label/types/label'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { type RoomInterface } from 'src/entities/room/types/room'
import { occupies } from 'src/entities/table/services/table'
import { type TableInterface } from 'src/entities/table/types/table'
import ListSection from './ListSection/ListSection'
import {
  filterCheckedInOccupancies,
  filterPendingOccupancies,
  getOccupancy,
  getReservation,
  type ReservationOccupancy,
} from '../../../../service/occupancy'
import ReservationsItems from '../../../ReservationList/components/ReservationsItems'

interface OccupanciesProps {
  table: TableInterface
  occupancies: ReservationOccupancy[]
  areas: AreaInterface[]
  tables: TableInterface[]
  labels: LabelInterface[]
  rooms: RoomInterface[]
  onReservationButtonClick: (reservation: ReservationInterface) => void
  onReservationClick: (reservationId: ReservationInterface) => void
}

const Occupancies = ({ table, occupancies, ...props }: OccupanciesProps) => {
  const { t } = useTranslation()

  const tableOccupancies = React.useMemo(
    () => filter(compose(occupies(table), getOccupancy), occupancies),
    [occupancies, table],
  )

  const checkedInReservations = React.useMemo(
    () => map(getReservation, filterCheckedInOccupancies(tableOccupancies)),
    [tableOccupancies],
  )
  const pendingReservations = React.useMemo(
    () => map(getReservation, filterPendingOccupancies(tableOccupancies)),
    [tableOccupancies],
  )

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ bgcolor: 'grey.25', pt: 2, flex: 1 }}
    >
      <ListSection title={t('angular.currently_at_table')}>
        <ReservationsItems reservations={checkedInReservations} {...props} />
      </ListSection>
      <ListSection title={t('angular.next_reservation')}>
        <ReservationsItems reservations={pendingReservations} {...props} />
      </ListSection>
    </Stack>
  )
}

export default Occupancies
