import { andThen, composeWith, prop } from 'ramda'

import { type TFunction } from 'i18next'

import {
  convertKeysToCamelCase,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import {
  convertToTimezone,
  toApiDateString,
} from 'src/shared/lib/range/services/date'
import { shiftSchema, type Shift } from '../types/shift'

export const getShifts =
  (httpClient: ApiClient) =>
  (date: Date): Shift[] =>
    composeWith(andThen)([
      (d: unknown) =>
        shiftSchema
          .array()
          .parse(d)
          .filter(shift => !shift.isClosed),
      prop('shiftInstances'),
      convertKeysToCamelCase,
      httpClient,
    ])({
      method: 'GET',
      url: 'shift-instances',
      searchParams: { date: toApiDateString(date) },
    })

export const closeShift =
  (httpClient: ApiClient) =>
  (
    shiftId: Shift['id'],
    date: Date,
    lockStatus: Shift['lockStatus'],
    t: TFunction,
  ) => {
    const closed = lockStatus === 'locked' ? 1 : 0

    return httpClient({
      url: 'closeShift',
      json: {
        date: toApiDateString(date),
        shift_id: shiftId,
        close: closed,
        ...(closed && { info: t('angular.shift_closed_info') }),
      },
    })
  }

export const disableShift =
  (httpClient: ApiClient) =>
  (shiftId: Shift['id'], date: Date, lockStatus: Shift['lockStatus']) => {
    const disabled = lockStatus === 'locked_online' ? 1 : 0

    return httpClient({
      url: 'disableShift',
      json: {
        date: toApiDateString(date),
        shift_id: shiftId,
        disable: disabled,
      },
    })
  }

export const setBadWeather =
  (http: ApiClient) =>
  async ({
    shiftId,
    date,
    badWeather,
  }: {
    shiftId: Shift['id']
    date: Date
    badWeather: boolean
  }) =>
    http({
      url: 'bad_weather/resolve',
      json: {
        day: toApiDateString(convertToTimezone(date)),
        shift_id: shiftId,
        bad_weather_status: badWeather,
      },
    })
