import type { PropsWithChildren } from 'react'
import { Stack, Typography } from '@mui/material'

import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import type { RoomInterface } from 'src/entities/room/types/room'
import type { Shift } from 'src/entities/shift-instance/types/shift'
import { timeRangeToString } from 'src/shared/lib/range/services/timeRange'
import {
  formatShiftName,
  translateShiftLockStatus,
} from '../../../../../ShiftLockControls'

const lockedItemTextRenderer = (t: TFunction) => (shift: Shift) =>
  translateShiftLockStatus(t)(shift.lockStatus)

const badWeatherTextRenderer = (t: TFunction) => () =>
  t('service_time_info.bad_weather', 'Bad Weather')

const tooltipItem =
  (textRenderer: typeof lockedItemTextRenderer) =>
  ({
    shift,
    rooms,
  }: PropsWithChildren<{
    shift: Shift
    rooms: RoomInterface[]
  }>) => {
    const { t } = useTranslation()

    return (
      <Stack direction="column">
        <Stack direction="row" gap={5} justifyContent="space-between">
          <Typography variant="labelSmall">
            {formatShiftName(shift, rooms)}
          </Typography>
          <Typography variant="labelSmall">{textRenderer(t)(shift)}</Typography>
        </Stack>
        <Typography variant="labelTiny" sx={{ opacity: 0.64 }}>
          {timeRangeToString(shift.timeRange)}
        </Typography>
      </Stack>
    )
  }

export const LockedShiftItem = tooltipItem(lockedItemTextRenderer)
export const BadWeatherItem = tooltipItem(badWeatherTextRenderer)
