import { createFileRoute } from '@tanstack/react-router'

import App from 'src/app/apps/book/App'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/reservations/',
)({
  component: RouteComponent,
})

function RouteComponent() {
  return <App restaurantId={Route.useParams().restaurantId} />
}
