import React from 'react'
import { compose, lensProp, set, uniq } from 'ramda'

import { useAvailabilitiesInvalidation } from 'src/entities/availability/queries/availability'
import { useStatsInvalidation } from 'src/entities/customer/queries/customer'
import {
  NotificationTypeEnum,
  type GuestOccupancyStatusNotificationInterface,
} from 'src/entities/notification/types/notification'
import {
  useCheckInStatusOptimisticUpdate,
  useOccupanciesOptimisticUpdate,
  useReservationOptimisticUpdate,
  useReservationsSearchInvalidation,
} from 'src/entities/reservation/queries/reservation'
import {
  haveSameSeatCount,
  haveSameStart,
} from 'src/entities/reservation/services/reservation'
import { fromUpdate } from 'src/entities/reservation/services/reservationFactory'
import {
  ReservationStatusEnum,
  type ReservationInterface,
  type ReservationUpdateInterface,
} from 'src/entities/reservation/types/reservation'
import { useRestaurantsTables } from 'src/entities/table/queries/table'
import useSseEventListenerEffect from './useSseEventListenerEffect'
import { isNotFalsy } from '../../zod/zod'

const useReservationUpdate = () => {
  const optimisticUpdate = useReservationOptimisticUpdate()
  const invalidateSearch = useReservationsSearchInvalidation()
  const invalidateCustomerStats = useStatsInvalidation()
  const invalidateAvailabilities = useAvailabilitiesInvalidation()

  return React.useCallback(
    (reservationUpdate: ReservationUpdateInterface) => {
      void invalidateSearch()

      const update = (reservation: ReservationInterface) => {
        const customerIds = uniq(
          [
            reservation.guest?.customerId,
            reservationUpdate.guest?.customerId,
          ].filter(isNotFalsy),
        )
        customerIds.forEach(invalidateCustomerStats)

        const newReservation = fromUpdate(reservation, reservationUpdate)

        if (!reservationUpdate.dateRange) return newReservation

        const dateIsUnchanged = haveSameStart(reservation, newReservation)
        const seatCountIsUnchanged = haveSameSeatCount(
          reservation,
          newReservation,
        )

        if (dateIsUnchanged && seatCountIsUnchanged) return newReservation

        void invalidateAvailabilities(
          newReservation.dateRange[0],
          newReservation.room,
        )

        if (dateIsUnchanged) return newReservation

        void invalidateAvailabilities(
          reservation.dateRange[0],
          reservation.room,
        )

        return newReservation
      }

      return optimisticUpdate(reservationUpdate.id, update)
    },
    [
      invalidateAvailabilities,
      invalidateCustomerStats,
      invalidateSearch,
      optimisticUpdate,
    ],
  )
}

const exactModeLens = lensProp<ReservationInterface, 'exactMode'>('exactMode')
const statusLens = lensProp<ReservationUpdateInterface, 'status'>('status')
const useReservationStatusUpdate = () => {
  const updateReservation = useReservationUpdate()

  return React.useCallback(
    (status: ReservationStatusEnum) =>
      compose(updateReservation, set(statusLens, status)),
    [updateReservation],
  )
}

export const useReservationsSseEffect = () => {
  const invalidateSearch = useReservationsSearchInvalidation()
  const invalidateCustomerStats = useStatsInvalidation()
  const invalidateAvailabilities = useAvailabilitiesInvalidation()

  const updateReservation = useReservationUpdate()
  const updateCache = useReservationOptimisticUpdate()
  const updateReservationStatus = useReservationStatusUpdate()
  const updateOccupancies = useOccupanciesOptimisticUpdate()
  const updateCheckInStatus = useCheckInStatusOptimisticUpdate()

  useSseEventListenerEffect(
    NotificationTypeEnum.ReservationCreated,
    ({ data }) => {
      void invalidateSearch()
      void invalidateAvailabilities(
        data.payload.dateRange[0],
        data.payload.room,
      )
      if (!data.payload.guest?.customerId) return
      void invalidateCustomerStats(data.payload.guest.customerId)
    },
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.ReservationUpdated,
    ({ data }) => updateReservation(data.payload),
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.ReservationCancelled,
    ({ data }) =>
      updateReservationStatus(ReservationStatusEnum.Cancelled)(data.payload),
  )
  useSseEventListenerEffect(
    NotificationTypeEnum.ReservationReactivated,
    ({ data }) =>
      updateReservationStatus(ReservationStatusEnum.Confirmed)(data.payload),
  )
  useSseEventListenerEffect(
    NotificationTypeEnum.ReservationNoShowed,
    ({ data }) =>
      updateReservationStatus(ReservationStatusEnum.NoShow)(data.payload),
  )
  useSseEventListenerEffect(
    NotificationTypeEnum.ReservationReShowed,
    ({ data }) =>
      updateReservationStatus(ReservationStatusEnum.Confirmed)(data.payload),
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.OccupanciesUpdated,
    ({ data }) =>
      updateOccupancies(data.payload.reservationId, data.payload.occupancies),
  )

  const tables = useRestaurantsTables()
  const checkInStatusChangedCallback = React.useCallback(
    ({ data }: { data: GuestOccupancyStatusNotificationInterface }) => {
      const table = tables.find(t => t.id === data.payload.tableId)

      return updateCheckInStatus(
        data.payload.reservationId,
        table,
        data.payload.checkInStatus,
      )
    },
    [tables, updateCheckInStatus],
  )
  useSseEventListenerEffect(
    NotificationTypeEnum.GuestCheckedOut,
    checkInStatusChangedCallback,
  )
  useSseEventListenerEffect(
    NotificationTypeEnum.GuestCheckedIn,
    checkInStatusChangedCallback,
  )

  useSseEventListenerEffect(
    NotificationTypeEnum.ReservationExactModeChanged,
    ({ data }) =>
      updateCache(data.payload.id, r =>
        set(exactModeLens, data.payload.exactMode, r),
      ),
  )
}
