import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import SidebarHeader from 'src/widgets/SidebarDrawer/SidebarHeader'

interface TableAssignDrawerHeaderProps {
  onClose: () => void
}

export const TableAssignDrawerHeader = ({
  onClose,
}: TableAssignDrawerHeaderProps) => {
  const { t } = useTranslation()

  return (
    <SidebarHeader onClose={onClose} sx={{ p: 1, backgroundColor: 'white' }}>
      <Typography variant="labelBig" sx={{ pointerEvents: 'none' }}>
        {t('angular.table_selection')}
      </Typography>
    </SidebarHeader>
  )
}
