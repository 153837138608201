import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import NotificationBadge from 'src/shared/components/common/NotificationBadge'

const ReservationsCount = ({ count }: { count: number }) => {
  const { t } = useTranslation('', { keyPrefix: 'serial_reservations' })

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="labelBig">{t('reservations')}</Typography>
      <NotificationBadge>{count}</NotificationBadge>
    </Stack>
  )
}

export default ReservationsCount
