import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type TeamMemberUpdateInterface } from 'src/entities/team-member/types/teamMember'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'

const LastName = () => {
  const { t } = useTranslation()

  const {
    register,
    formState: { errors },
  } = useFormContext<TeamMemberUpdateInterface>()

  return (
    <FormTextInput
      {...register('lastName')}
      errorText={errors.lastName?.message}
      label={t('settings.team_members.form.lastName')}
      autoComplete="one-time-code"
    />
  )
}

export default LastName
