import { Button, Tooltip, type ButtonProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

const GroupButton = (props: ButtonProps) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      title={t('settings.labels.group_by_color_tooltip', {
        tDescription: 'Tooltip text for "group by color" button',
        defaultValue:
          'The order of the colors can be changed by dragging the labels.',
      })}
      arrow
      placement="bottom-start"
    >
      <Button variant="outlined" {...props}>
        {t('settings.labels.group_by_color', {
          defaultValue: 'Group by color',
          tDescription: 'Label for "group by color" button',
        })}
      </Button>
    </Tooltip>
  )
}

export default GroupButton
