import { path } from 'ramda'
import { useMediaQuery } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers'

import { useController, useFormContext, type FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { intersectTimeRange } from 'src/entities/service-time/services/serviceTime'
import IconButton from 'src/shared/components/buttons/IconButton'
import ClockIcon from 'src/shared/components/icons/ClockIcon'
import dayjs from 'src/shared/lib/range/services/date'
import { isValid } from 'src/shared/lib/range/services/timeRange'

const Time = ({
  name,
  label,
  serviceTimes,
}: {
  name: 'timeRange.0' | 'timeRange.1'
  label: string
  serviceTimes: ServiceTimeInterface[]
}) => {
  const { watch } = useFormContext<ServiceTimeInterface>()

  const { t } = useTranslation('', { keyPrefix: 'settings.service_times' })

  const timeRange = watch('timeRange')

  const {
    field: { onChange, value: dateValue, ...field },
    formState: { errors },
  } = useController<ServiceTimeInterface, 'timeRange.0' | 'timeRange.1'>({
    name,
    rules: {
      required: true,
      validate: {
        format: date => dayjs(date).isValid() || t('invalidFormat'),
        noTimeTravel: () => isValid(timeRange) || t('timeTravelError'),
        noOverlap: date => {
          if (intersectTimeRange(serviceTimes, date)(timeRange))
            return t('overlapError')

          return true
        },
        onlyQuarters: date =>
          dayjs(date).minute() % 15 === 0 || t('time_quarter_only'),
      },
    },
  })

  const error = path<FieldError>(name.split('.'), errors)

  const isTouchscreen = useMediaQuery('@media (pointer: fine)')

  return (
    <TimePicker
      label={label}
      slotProps={{
        textField: {
          required: true,
          error: !!error,
          helperText: error?.message,
        },
        openPickerButton: {
          color: 'neutral',
          sx: {
            mr: 0,
            p: 1,
            color: 'inherit',
          },
        },
      }}
      // timezone={getRestaurantTimeZone()}
      slots={{
        openPickerButton: IconButton,
        openPickerIcon: ClockIcon,
      }}
      disableOpenPicker={isTouchscreen}
      ampm={false}
      skipDisabled
      minutesStep={15}
      {...field}
      onChange={newValue => {
        onChange(newValue?.startOf?.('minute')?.toDate?.() ?? null)
      }}
      value={dayjs(dateValue)}
    />
  )
}

export default Time
