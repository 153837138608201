import React from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  type SelectProps,
} from '@mui/material'

export type SelectContainerProps<T> = Omit<
  SelectProps<T>,
  'onSelect' | 'onChange' | 'value'
> & {
  value?: T
  helperText?: string
} & (
    | {
        onSelect: (value: T) => void
        onChange?: undefined
      }
    | {
        onSelect?: undefined
        onChange: SelectProps<T>['onChange']
      }
  )

export const SelectContainer = React.forwardRef(
  <T,>(
    {
      open,
      label,
      onSelect,
      onChange,
      size = 'medium',
      fullWidth = true,
      helperText,
      ...rest
    }: SelectContainerProps<T>,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const changeHandler: typeof onChange = (event, target) => {
      if (onSelect) onSelect(event.target.value as T)
      if (onChange) onChange(event, target)
    }

    const hasHandler = !!(onSelect ?? onChange)

    return (
      <FormControl size={size} focused={open} fullWidth={fullWidth}>
        {label && <InputLabel>{label}</InputLabel>}
        <Select
          fullWidth
          displayEmpty
          onChange={hasHandler ? changeHandler : undefined}
          label={label}
          size={size}
          open={open}
          ref={ref}
          {...rest}
        />
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  },
)
