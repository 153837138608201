import { Grid2 } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type AreaInterface } from 'src/entities/area/types/area'
import { useRoomsQuery } from 'src/entities/room/queries/room'
import EllipsisTypography from '../../EllipsisTypography'

const Area = ({ area }: { area: AreaInterface }) => {
  const { t } = useTranslation('', { keyPrefix: 'settings.areas' })

  const { data: rooms } = useRoomsQuery()
  const areaRoom = rooms.find(room => room.id === area.roomId)

  return (
    <Grid2 container columns={2} alignItems="center" overflow="hidden">
      <Grid2 size={{ xs: 1 }}>
        <EllipsisTypography variant="body2">{area.name}</EllipsisTypography>
      </Grid2>
      <Grid2 size={{ xs: 1 }}>
        <EllipsisTypography variant="body3">
          {areaRoom?.name ?? t('emptyRoom')}
        </EllipsisTypography>
      </Grid2>
    </Grid2>
  )
}

export default Area
