import { useMemo, useRef } from 'react'
import { Button, TableCell, TableRow, Typography } from '@mui/material'

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  type ColumnDef,
} from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'
import { type TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { useScheduleActivityLogQuery } from 'src/entities/schedule/queries/scheduleActivityLog'
import {
  type ScheduleAction,
  type ScheduleActivityLogEntry,
} from 'src/entities/schedule/types/scheduleApi'
import VirtualTable from 'src/shared/components/common/VirtualTable/VirtualTable'
import VirtualTableContainer from 'src/shared/components/common/VirtualTable/VirtualTableContainer'
import { formatShortDatetime } from 'src/shared/lib/range/services/date'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'

const activityLogColumnHelper = createColumnHelper<ScheduleActivityLogEntry>()

const scheduleActivityLogColumnsFactory = (
  t: TFunction,
  onDetailsClick: (row: ScheduleActivityLogEntry) => void,
): ColumnDef<ScheduleActivityLogEntry, string>[] => [
  activityLogColumnHelper.accessor(
    (row: ScheduleActivityLogEntry) =>
      formatShortDatetime(new Date(row.when), { showWeekday: false }),
    {
      header: t('schedule.activity_log.when', 'When'),
      size: 110,
      sortingFn: 'datetime',
      enableSorting: true,
      cell: info => info.getValue() || '-',
    },
  ),
  activityLogColumnHelper.accessor((row: ScheduleActivityLogEntry) => row.who, {
    header: t('schedule.activity_log.who', 'Who'),
    cell: info => info.getValue() || '-',
  }),
  activityLogColumnHelper.accessor(
    (row: ScheduleActivityLogEntry) => row.team_member,
    {
      header: t('schedule.activity_log.team_member', 'Team Member'),
      cell: info => info.getValue() || '-',
    },
  ),
  activityLogColumnHelper.accessor(
    (row: ScheduleActivityLogEntry) => row.action as string,
    {
      header: t('schedule.activity_log.action', 'Action'),
      cell: info => (
        <Typography sx={{ wordBreak: 'break-word' }}>
          {info.getValue() || '-'}
        </Typography>
      ),
    },
  ),
  activityLogColumnHelper.accessor(
    (row: ScheduleActivityLogEntry) => {
      const { type, names } = row.target

      if (type === 'all') {
        return t(
          'schedule.activity_log.all_shifts_and_rooms',
          'All shifts and rooms',
        )
      }

      if (!names || names.length === 0) {
        return '-'
      }

      const typeMapping = {
        room: `${t('schedule.activity_log.rooms', 'Rooms')}: ${names?.join(', ')}`,
        shift: `${t('schedule.activity_log.shifts', 'Shifts')}: ${names?.join(', ')}`,
        none: names?.join(', '),
      }

      return typeMapping[type] ?? '-'
    },
    {
      header: t('schedule.activity_log.target', 'Target'),
      size: 100,
    },
  ),
  activityLogColumnHelper.display({
    id: 'details',
    cell: ({ row }) =>
      row.original.payload ? (
        <Button
          onClick={() => {
            onDetailsClick(row.original)
          }}
          sx={{
            color: '#2661D3',
            textDecoration: 'none',
            cursor: 'pointer',
            fontSize: '5',
          }}
        >
          {t('schedule.activity_log.details', 'Details')}
        </Button>
      ) : null,
    maxSize: 100,
  }),
]

interface TableProps {
  filterDateRange: DefinedRangeInterface<Date> | null
  filterEventTypes: ScheduleAction[]
  handleDetailsClick: (row: ScheduleActivityLogEntry) => void
}
const ScheduleActivityLogTable = ({
  filterEventTypes,
  filterDateRange,
  handleDetailsClick,
}: TableProps) => {
  const { t } = useTranslation()
  const tableContainerRef = useRef(null)

  const { data } = useScheduleActivityLogQuery({
    dateFrom: filterDateRange?.[0],
    dateTo: filterDateRange?.[1],
    eventTypes: filterEventTypes,
  })

  const { getVirtualItems, getTotalSize, measureElement } = useVirtualizer({
    count: data.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 64,
    overscan: 24,
  })

  const columns = useMemo(
    () => scheduleActivityLogColumnsFactory(t, handleDetailsClick),
    [handleDetailsClick, t],
  )

  const { getHeaderGroups, getRowModel } = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  })

  const { rows } = getRowModel()

  const items = getVirtualItems()

  return (
    <VirtualTableContainer ref={tableContainerRef}>
      <VirtualTable
        totalSize={getTotalSize()}
        items={items}
        headers={getHeaderGroups()}
      >
        {items.length === 0 ? (
          <TableRow>
            <TableCell colSpan={columns.length}>
              <Typography align="center">
                {t(
                  'schedule.activity_log.no_results_found',
                  'No results found',
                )}
              </Typography>
            </TableCell>
          </TableRow>
        ) : (
          items.map(({ index, size }) => {
            const row = rows[index]!

            return (
              <TableRow
                hover
                key={row.id}
                sx={{ height: size }}
                ref={measureElement}
                data-index={index}
              >
                {row.getVisibleCells().map(cell => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            )
          })
        )}
      </VirtualTable>
    </VirtualTableContainer>
  )
}

export default ScheduleActivityLogTable
