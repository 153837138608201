import React from 'react'

import { useTranslation } from 'react-i18next'

import { currencyFormatter } from '../services/text'

export const useCurrencyFormatter = () => {
  const {
    i18n: { language },
  } = useTranslation()

  return React.useCallback(
    (currency: string) => {
      const formatter = currencyFormatter(language, currency)

      return (amount: number) => formatter.format(amount)
    },
    [language],
  )
}
