import React from 'react'
import { Stack, type StackProps } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import { type TableInterface } from 'src/entities/table/types/table'

interface TableItemProps extends StackProps {
  table: TableInterface
  onSelect: () => void
  isSelected: boolean
}

const TableItem = (
  { table, onSelect, isSelected, sx, ...props }: TableItemProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) => (
  <Stack
    direction="row"
    sx={mergeSx(
      theme => ({
        cursor: 'pointer',
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundClip: 'content-box',
        borderRadius: 0.5,
        borderColor: isSelected ? theme.palette.neutral.dark : 'transparent',
        overflow: 'hidden',
        flex: 1,
      }),
      sx,
    )}
    onClick={onSelect}
    ref={ref}
    {...props}
  />
)

export default React.forwardRef(TableItem)
