import { forwardRef, useCallback, useEffect, useState } from 'react'
import { juxt } from 'ramda'
import { Box, Button, Collapse, keyframes, Stack } from '@mui/material'

import { type CustomerInterface } from 'src/entities/customer/types/customer'
import { useEditReservation } from 'src/entities/reservation/hooks/useEditReservation'
import { useNewReservation } from 'src/entities/reservation/hooks/useNewReservation'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { CustomerEdit } from 'src/pages/CustomerEdit'
import { CustomerPreviewModal } from 'src/pages/CustomerPreview/PreviewModal'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { usePopover } from 'src/shared/components/dialogs/hooks/usePopover'
import PhoneCallIcon from 'src/shared/components/icons/PhoneCallIcon'
import {
  usePhoneCallAtomGlobalEffect,
  usePhoneCallHandlers,
  usePhoneCalls,
  type PhoneCallAtomValue,
} from 'src/shared/lib/context/state/atoms/incomingPhoneCalls'
import { PhoneCallPopoverItem } from './PhoneCallPopoverItem'
import { PhoneCallPopoverWrapper } from './wrappers/PhoneCallPopoverWrapper'

const ringRing = keyframes`
  from {
    transform:rotate3d(0, 0, 1, 0deg);
  }
  20%, 32%, 44%, 56%, 68% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  23%, 35%, 47%, 59%, 71% {
    transform: rotate3d(0, 0, 1, 15deg);
  }
  26%, 38%, 50%, 62%, 74% {
    transform: rotate3d(0, 0, 1, 0deg);
  }
  29%, 41%, 53%, 65%, 77% {
    transform: rotate3d(0, 0, 1, -15deg);
  }
  80% {
    transform:rotate3d(0, 0, 1, 0deg);
  }
`

interface PhoneCallProps {
  item: PhoneCallAtomValue
  onReservationClick: (reservation: ReservationInterface) => void
  onShowAllClick: (phoneNumber: string) => void
  onCustomerClick: (customer: CustomerInterface) => void
}

const PhoneCall = forwardRef<HTMLDivElement, PhoneCallProps>(
  (
    {
      item,
      onReservationClick,
      onShowAllClick,
      onCustomerClick,
    }: PhoneCallProps,
    ref,
  ) => {
    const popoverProps = usePopover()

    const { onClose, onOpen } = usePhoneCallHandlers()

    useEffect(() => {
      if (popoverProps.open) onOpen(item.phoneCall)
      else onClose(item.phoneCall)
    }, [item.phoneCall, onClose, onOpen, popoverProps.open])

    const [hasEntered, setHasEntered] = useState(false)

    useEffect(() => {
      const timeout = setTimeout(() => setHasEntered(true), 500)

      return () => clearTimeout(timeout)
    }, [])

    const createNewReservation = useNewReservation()

    const { handleClose } = popoverProps

    const handleCreateButtonClick = useCallback(
      async (customer?: CustomerInterface) => {
        if (customer) await createNewReservation({ customer })
        else
          await createNewReservation({
            newCustomer: {
              phoneNumbers: [{ id: 0, phone: item.phoneCall.number }],
            },
          })

        handleClose()
      },
      [createNewReservation, handleClose, item.phoneCall.number],
    )

    return (
      <Box ref={ref}>
        <Button
          variant="color-outlined"
          color="success"
          aria-checked={!item.wasOpened}
          sx={{ borderRadius: 999 }}
          onClick={event => popoverProps.setAnchorEl(event.currentTarget)}
        >
          <PhoneCallIcon
            sx={
              item.wasOpened
                ? undefined
                : { animation: `${ringRing} 3s linear infinite` }
            }
          />
          <Collapse in={hasEntered} collapsedSize={0} orientation="horizontal">
            Call
          </Collapse>
        </Button>
        <PhoneCallPopoverWrapper
          popoverProps={popoverProps}
          createdAt={item.createdAt}
        >
          <PhoneCallPopoverItem
            phoneNumber={item.phoneCall.number}
            onReservationClick={juxt([onReservationClick, handleClose])}
            onCreateButtonClick={handleCreateButtonClick}
            onCustomerClick={juxt([onCustomerClick, handleClose])}
            onShowAllClick={() => {
              onShowAllClick(item.phoneCall.number)
              handleClose()
            }}
          />
        </PhoneCallPopoverWrapper>
      </Box>
    )
  },
)

interface Props {
  phoneCalls: PhoneCallAtomValue[]
  onShowAllClick: (phoneNumber: string) => void
  onCustomerClick: (customer: CustomerInterface) => void
  onReservationClick: (reservation: ReservationInterface) => void
}

const Component = ({
  phoneCalls,
  onReservationClick,
  onShowAllClick,
  onCustomerClick,
}: Props) => {
  usePhoneCallAtomGlobalEffect()

  return (
    <Box sx={{ flexShrink: 0, minWidth: 20 }}>
      <Stack gap={0.5} direction="row">
        {phoneCalls.map(p => (
          <Collapse
            key={p.phoneCall.number}
            orientation="horizontal"
            collapsedSize={0}
            unmountOnExit
            in={p.visible}
          >
            <PhoneCall
              item={p}
              onReservationClick={onReservationClick}
              onCustomerClick={onCustomerClick}
              onShowAllClick={onShowAllClick}
            />
          </Collapse>
        ))}
      </Stack>
    </Box>
  )
}

interface TopBarPhoneCallsProps {
  restaurantId: number
  onShowAllClick: (phoneNumber: string) => void
}

export const TopBarPhoneCalls = ({
  onShowAllClick,
  restaurantId,
}: TopBarPhoneCallsProps) => {
  const phoneCalls = usePhoneCalls()

  const [customer, setCustomer] = useState<CustomerInterface | null>(null)
  const previewDialog = useDialog()
  const editDialog = useDialog()

  const onCreateReservation = useNewReservation()
  const editReservation = useEditReservation(restaurantId)

  return (
    <>
      {!!phoneCalls.length && (
        <Component
          phoneCalls={phoneCalls}
          onCustomerClick={juxt([setCustomer, previewDialog.handleOpen])}
          onReservationClick={editReservation}
          onShowAllClick={onShowAllClick}
        />
      )}
      <CustomerPreviewModal
        isOpen={previewDialog.open}
        onClose={previewDialog.handleClose}
        customerId={customer?.id}
        hasBeenOpened={previewDialog.hasBeenOpened}
        onCustomerEdit={editDialog.handleOpen}
        onAddReservation={c => onCreateReservation({ customer: c })}
      />
      <CustomerEdit
        customerId={customer?.id}
        onCustomerSaved={editDialog.handleClose}
        hasBeenOpened={editDialog.hasBeenOpened}
        isOpen={editDialog.open}
        onClose={editDialog.handleClose}
      />
    </>
  )
}
