import type Konva from 'konva'

import { type TableInterface } from 'src/entities/table/types/table'
import { type UuidInterface } from 'src/shared/lib/api/types/uuid'

export interface PositionInterface {
  x: number
  y: number
}

export interface SizeInterface {
  width: number
  height: number
}

export enum FloorPlanElementShape {
  Elliptic = 'round',
  Rectangular = 'rectangle',
  Square = 'square',
  DeprecatedRectangular = 'rect',
}

export enum FloorPlanElementType {
  Table = 'table',
  Furniture = 'furniture',
}

export interface FloorPlanElementInterface extends Partial<UuidInterface> {
  id: number | null
  areaId: number
  position: PositionInterface
  size: SizeInterface
  color: string
  shape: FloorPlanElementShape
  type: FloorPlanElementType
  rotation: number
}

export interface FurnitureInterface extends FloorPlanElementInterface {
  type: FloorPlanElementType.Furniture
}

export interface FloorPlanTableInterface
  extends FloorPlanElementInterface,
    TableInterface {
  type: FloorPlanElementType.Table
}

export interface FloorPlanShapeComponentProps {
  element: FloorPlanElementInterface
  shapeProps?: Konva.ShapeConfig
}

export interface FloorPlanComponentProps {
  element: FloorPlanElementInterface
  onChange: (element: FloorPlanElementInterface) => void
  isSelected: boolean
  onSelect: () => void
}
