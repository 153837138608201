import { forwardRef } from 'react'

import Cancel from './cancel.svg'
import IconContainer, { type IconProps } from '../IconContainer'

const CancelIcon = forwardRef<HTMLDivElement, Omit<IconProps, 'ref'>>(
  (props, ref) => (
    <IconContainer ref={ref} {...props}>
      <Cancel />
    </IconContainer>
  ),
)

export default CancelIcon
