import React from 'react'

import { useReactivateReservationMutation } from 'src/entities/reservation/queries/reservationsMutations'
import { type ReactivationAction } from 'src/entities/reservation/services/reservationApi'
import type { ReservationInterface } from 'src/entities/reservation/types/reservation'
import {
  isAbandoned,
  resumable,
  useResumable,
  type Step,
} from 'src/shared/lib/common/services/resumable/resumable'
import { ReservationActions } from './reservationActions'
import { canBeSerial } from '../../Reservations/components/FloorPlanView/occupancies'

interface UseReservationReactivation {
  onAssignSerie: () => void
}

interface Options {
  wholeSerie?: boolean | undefined
}

interface Dependencies {
  reservation: ReservationInterface
  reservationAction: ReactivationAction
}

export const useReservationReactivation = ({
  onAssignSerie,
}: UseReservationReactivation) => {
  const { mutateAsync: reactivateReservation } =
    useReactivateReservationMutation()

  const steps: Step<Options, Dependencies>[] = React.useMemo(
    () => [
      [
        (o, d) =>
          d.reservationAction === ReservationActions.Reactivate &&
          canBeSerial(o, d),
        onAssignSerie,
      ],
    ],
    [onAssignSerie],
  )

  const { init, resume, abandon } = useResumable(
    React.useMemo(() => resumable(steps), [steps]),
  )

  const initHandler = React.useCallback(
    async (
      reservation: ReservationInterface,
      reservationAction: ReactivationAction,
    ) => {
      const options = await init({ reservation, reservationAction })

      if (isAbandoned(options)) return options

      await reactivateReservation({
        reservation,
        action: reservationAction,
      })

      return options
    },
    [init, reactivateReservation],
  )

  return { init: initHandler, resume, abandon }
}
