import { complement } from 'ramda'

import useFeature from 'src/entities/info/hooks/useFeature'
import { useTeamMembersQuery } from 'src/entities/team-member/queries/teamMember'
import { isAdmin } from 'src/entities/team-member/services/teamMember'

const useAvailableTeamMembers = () => {
  const { data: teamMembers } = useTeamMembersQuery()
  const pinFeatureEnabled = useFeature('pinEnabled')

  return pinFeatureEnabled
    ? teamMembers
    : teamMembers.filter(complement(isAdmin))
}

export default useAvailableTeamMembers
