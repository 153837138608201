import React from 'react'

import { NotificationTypeEnum } from 'src/entities/notification/types/notification'
import useSseEventListenerEffect from 'src/shared/lib/api/sse/useSseEventListenerEffect'
import { usePhoneCallNotificationsStackService } from 'src/shared/lib/context/reducer/phoneCallsNotificationsStackReducer'
import { useAddPhoneCall } from 'src/shared/lib/context/state/atoms/incomingPhoneCalls'

const usePhoneCallSseEffect = () => {
  const phoneCallsStackService = usePhoneCallNotificationsStackService()
  const addPhoneCall = useAddPhoneCall()

  useSseEventListenerEffect(
    NotificationTypeEnum.PhoneCall,
    React.useCallback(
      ({ data: notification }) => {
        phoneCallsStackService.addItem(notification)
        addPhoneCall(notification.payload)
      },
      [addPhoneCall, phoneCallsStackService],
    ),
  )
}

export default usePhoneCallSseEffect
