import { Button, type ButtonProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { SimpleDialog } from '../../../dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from '../../../dialogs/SimpleDialog/useSimpleDialog'

interface DeleteButtonProps extends ButtonProps {
  text?: string
  onClick: () => void
}

const DeleteButton = ({ text, onClick, ...props }: DeleteButtonProps) => {
  const { t } = useTranslation()

  const { simpleDialogProps, showSimpleDialog } = useSimpleDialog()

  return (
    <>
      <Button
        color="error"
        variant="outlined"
        onClick={() => showSimpleDialog()}
        {...props}
      >
        {text ?? t('common.form_buttons.deleteButtonLabel')}
      </Button>
      <SimpleDialog
        color="error"
        title={t('common.caution', 'Caution')}
        message={t(
          'common.delete_disclaimer',
          'Are you sure you want to delete this item? This action cannot be reversed.',
        )}
        onConfirmation={onClick}
        confirmButtonText={t('common.form_buttons.deleteButtonLabel', 'Delete')}
        {...simpleDialogProps}
      />
    </>
  )
}

export default DeleteButton
