import { andThen, compose, composeWith, prop } from 'ramda'

import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
  dateSerializer,
  numericSerializer,
  serializeValues,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import {
  groupCreatedSchema,
  groupSchema,
  type ReservationGroup,
} from './reservationGroupFactory'

export const addReservationGroup =
  // eslint-disable-next-line @typescript-eslint/require-await
  (httpClient: ApiClient) => async (payload: ReservationGroup) =>
    composeWith(andThen)([
      (d: unknown) => groupCreatedSchema.parse(d),
      convertKeysToCamelCase,
      prop('data'),
      httpClient,
    ])({
      url: 'reservationgroupsapi/add',
      json: compose(
        convertKeysToSnakeCase,
        serializeValues(numericSerializer, dateSerializer),
      )(payload),
    })

export const getReservationGroup =
  // eslint-disable-next-line @typescript-eslint/require-await
  (httpClient: ApiClient) => async (id: number) =>
    composeWith(andThen)([
      (d: unknown) => groupSchema.parse(d),
      convertKeysToCamelCase,
      httpClient,
    ])({
      url: 'reservationgroupsapi/get',
      json: {
        group_id: id,
      },
    })
