import { intersection, uniq } from 'ramda'

import { fromTime, getDayOfWeek } from 'src/shared/lib/range/services/date'
import * as timeRangeService from 'src/shared/lib/range/services/timeRange'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import { type IntersectableShift } from './shiftValidation'

export const getWeekdaysInRange = (
  dateRange: DefinedRangeInterface<Date> | null,
) => {
  if (!dateRange) return []

  const weekRange = timeRangeService.trimTo(1, 'week')(dateRange)

  return uniq(
    timeRangeService.interpolate(weekRange, 'day', 1, true).map(getDayOfWeek),
  )
}

export const getWeekdaysIntersection =
  (dateRange: DefinedRangeInterface<Date>) =>
  (weekdaysA: number[], weekdaysB: number[]) => {
    const weekdaysInRange = getWeekdaysInRange(dateRange)

    const commonWeekdays = intersection(weekdaysA, weekdaysB)

    return intersection(weekdaysInRange, commonWeekdays)
  }

export const shiftTimeRange = (shift: IntersectableShift) =>
  [
    fromTime(shift.arrivalTimes.firstArrivalTime),
    fromTime(shift.arrivalTimes.lastArrivalTime),
  ] as DefinedRangeInterface<Date>
