import { useTranslation } from 'react-i18next'

import { type Room } from 'src/entities/schedule/types/scheduleApi'
import ArrivalTime from './components/ArrivalTime/ArrivalTime'
import Duration from './components/Duration'
import GeneralSectionContainer from './components/GeneralSectionContainer'
import Interval from './components/Interval'
import IsActive from './components/IsActive'
import Name from './components/Name'
import Period from './components/Period'
import Weekdays from './components/Weekdays'
import type {
  EventOverlap,
  ExceptionOverlap,
} from '../../services/periodAwareValidation'
import GuestRange from '../LimitsSection/components/GuestRange/GuestRange'
import { RoomsSectionSelect } from '../RoomsSection/RoomsSectionSelect'

interface EventGeneralSectionProps {
  rooms: Room[]
  roomsEnabled: boolean
  intersections: (EventOverlap | ExceptionOverlap)[]
}

const EventGeneralSection = ({
  rooms,
  roomsEnabled,
  intersections,
}: EventGeneralSectionProps) => {
  const { t } = useTranslation()

  return (
    <GeneralSectionContainer>
      <IsActive
        label={t('schedule.events.general_section.is_active', 'Active event')}
      />
      <Name
        label={t('schedule.events.general_section.name.label', 'Event name')}
      />
      <RoomsSectionSelect rooms={rooms} roomsEnabled={roomsEnabled} />
      <Period
        label={t('schedule.events.general_section.period.label', 'Event dates')}
      />
      <Weekdays />
      <ArrivalTime intersections={intersections} />
      <Interval />
      <Duration />
      <GuestRange />
    </GeneralSectionContainer>
  )
}

export default EventGeneralSection
