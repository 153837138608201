import { Divider, useMediaQuery, type Theme } from '@mui/material'

import { useSelectedServiceTime } from 'src/shared/lib/context/state/atoms/selectedServiceTime'
import AddNoteButton from './components/AddNoteButton'
import ServiceTimeMessage from './components/ServiceTimeMessage'
import { useHolidayMessage } from './useHolidayMessage'
import { useServiceTimeMessage } from './useServiceTimeMessage'
import { useOnEditServiceTimeMessage } from '../../hooks/useOnEditServiceTimeMessage'

const ServiceTimeInfo = ({
  onOpenEditModal,
}: {
  onOpenEditModal: () => void
}) => {
  const serviceTime = useSelectedServiceTime()
  const serviceTimeMessage = useServiceTimeMessage()
  const holidayMessage = useHolidayMessage()

  const onEdit = useOnEditServiceTimeMessage(onOpenEditModal)

  const displayMessage = !!(holidayMessage || serviceTimeMessage)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))

  if (!serviceTime && !displayMessage) return null

  return (
    <>
      {!isMobile && !displayMessage && serviceTime && (
        <AddNoteButton onClick={onEdit} />
      )}
      <ServiceTimeMessage
        serviceTimeMessage={serviceTimeMessage}
        holidayMessage={holidayMessage}
        onEdit={onEdit}
      />
      {!isMobile && (
        <Divider orientation="vertical" variant="middle" flexItem />
      )}
    </>
  )
}

export default ServiceTimeInfo
