import { Suspense, useMemo } from 'react'
import { Alert, Button, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  useCustomers,
  useCustomersSearchQuery,
} from 'src/entities/customer/queries/customer'
import { type CustomerInterface } from 'src/entities/customer/types/customer'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import CircularProgress from 'src/shared/components/common/CircularProgress'
import CenteredBox from 'src/shared/components/containers/CenteredBox'
import { getFlatPaginatedItems } from 'src/shared/lib/api/services/api'
import { formatPhoneNumber } from 'src/shared/lib/string/services/phoneNumber'
import { ExistingCustomerItem } from './ExistingCustomerItem/ExistingCustomerItem'
import {
  PhoneCallNotificationItemWrapper,
  type PhoneCallNotificationItemWrapperProps,
} from './wrappers/PhoneCallNotificationItemWrapper'

interface NewCustomerItemProps extends PhoneCallNotificationItemWrapperProps {
  phoneNumber: string
}

const NewCustomerItem = ({
  onButtonClick,
  phoneNumber,
}: NewCustomerItemProps) => (
  <PhoneCallNotificationItemWrapper onButtonClick={onButtonClick}>
    <Typography variant="labelBig" lineHeight={1}>
      {formatPhoneNumber(phoneNumber)}
    </Typography>
  </PhoneCallNotificationItemWrapper>
)

interface PhoneCallPopoverItemProps {
  phoneNumber: string
  onReservationClick: (reservation: ReservationInterface) => void
  onCreateButtonClick: (customer?: CustomerInterface) => void
  onCustomerClick: (customer: CustomerInterface) => void
  onShowAllClick: () => void
}

export const PhoneCallPopoverItem = ({
  phoneNumber,
  onReservationClick,
  onCreateButtonClick,
  onCustomerClick,
  onShowAllClick,
}: PhoneCallPopoverItemProps) => {
  const { t } = useTranslation()

  const query = useCustomersSearchQuery(phoneNumber)

  const customerIds = useMemo(
    () => getFlatPaginatedItems(query.data.pages),
    [query.data],
  )
  const firstThreeCustomerIds = customerIds.slice(0, 3)

  const firstThreeCustomers = useCustomers(firstThreeCustomerIds).filter(
    c => c.type === 'actual',
  )

  const hasMore = customerIds.length > 3

  return (
    <>
      {hasMore && (
        <Alert severity="warning">
          {t(
            'caller_id.multiple_guests',
            'More than 3 guests are connected with this phone number.',
          )}
        </Alert>
      )}
      <Stack gap={0.5}>
        {firstThreeCustomers.map(c => (
          <Suspense
            key={c.id}
            fallback={
              <CenteredBox>
                <CircularProgress />
              </CenteredBox>
            }
          >
            <ExistingCustomerItem
              onCreateButtonClick={() => onCreateButtonClick(c)}
              onCustomerClick={() => onCustomerClick(c)}
              onReservationClick={onReservationClick}
              customer={c}
              phoneNumber={phoneNumber}
            />
          </Suspense>
        ))}
        {!firstThreeCustomers.length && (
          <NewCustomerItem
            onButtonClick={() => onCreateButtonClick()}
            phoneNumber={phoneNumber}
          />
        )}
        {hasMore && (
          <Button
            variant="text"
            sx={{ alignSelf: 'flex-start', px: 0 }}
            onClick={onShowAllClick}
          >
            {t('caller_id.view_all_profiles', 'View all profiles')}
          </Button>
        )}
      </Stack>
    </>
  )
}
