import type { TFunction } from 'i18next'

import type { SimpleDialogProps } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'

export const reservationNotSavedDialog = (
  t: TFunction,
): Partial<SimpleDialogProps> => ({
  message: t('angular.message_reservation_not_saved'),
  variant: 'dismissible',
})

export const tableLockedDialog = (
  t: TFunction,
): Partial<SimpleDialogProps> => ({
  title: t('angular.table_locked'),
  message: t('angular.message_table_closed'),
  variant: 'dismissible',
})

export const tableOccupiedDialog = (
  t: TFunction,
  onConfirmation: SimpleDialogProps['onConfirmation'],
): Partial<SimpleDialogProps> => ({
  title: t('table_occupied_modal.title', 'Table occupied'),
  message: t(
    'table_occupied_modal.body',
    'Do you really want to assign this table to this reservation?',
  ),
  cancelButtonText: t('angular.no'),
  confirmButtonText: t('table_occupied_modal.overbook_button', 'Yes, overbook'),
  color: 'error',
  onConfirmation,
})

export const notEveryoneSeatedDialog = (
  t: TFunction,
): Partial<SimpleDialogProps> => ({
  title: t(
    'incomplete_table_assignment_modal.title',
    'Incomplete table assignment',
  ),
  message: t(
    'incomplete_table_assignment_modal.message.not_everyone_seated',
    'The assigned tables do not fit all guests. Do you want to continue?',
  ),
  confirmButtonText: t(
    'incomplete_table_assignment_modal.confirm_button_label',
    'Yes, continue',
  ),
  color: 'error',
})

export const tooManySeatedDialog = (
  t: TFunction,
): Partial<SimpleDialogProps> => ({
  title: t(
    'incomplete_table_assignment_modal.title',
    'Incomplete table assignment',
  ),
  message: t(
    'incomplete_table_assignment_modal.message.too_many_seated',
    'There are too many guests seated at the assigned tables. Do you want to continue?',
  ),
  confirmButtonText: t(
    'incomplete_table_assignment_modal.confirm_button_label',
    'Yes, continue',
  ),
  color: 'error',
})
