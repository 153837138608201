import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import CircularProgress from 'src/shared/components/common/CircularProgress'

const LoadingIndicator = () => {
  const { t } = useTranslation('', { keyPrefix: 'serial_reservations' })

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={18} />
      <Typography>{t('saving')}</Typography>
    </Stack>
  )
}

export default LoadingIndicator
