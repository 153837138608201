import type React from 'react'
import { forwardRef } from 'react'
import { Box, type BoxProps } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import { svgPathSizeFactory, svgSizeFactory } from 'src/app/theme/svg'

export interface IconProps extends Omit<BoxProps, 'ref'> {
  component?: React.ElementType
  size?: 'small' | 'medium' | 'inherit' | 'large'
  variant?: 'filled' | 'outlined'
  disabled?: boolean
  error?: boolean
  OutlinedVariant?: React.FC
  FilledVariant?: React.FC
  smallVariants?: {
    filled?: React.FC
    outlined?: React.FC
  }
}

const IconContainer = forwardRef<Element, IconProps>(
  (
    {
      size = 'medium',
      variant = 'outlined',
      children,
      sx,
      disabled,
      error,
      OutlinedVariant,
      smallVariants,
      FilledVariant,
      ...props
    },
    ref,
  ) => {
    const icons = {
      small: {
        filled: FilledVariant,
        outlined: OutlinedVariant,
        ...smallVariants,
      },
      medium: {
        filled: FilledVariant,
        outlined: OutlinedVariant,
      },
      large: {
        filled: FilledVariant,
        outlined: OutlinedVariant,
      },
      inherit: {
        filled: FilledVariant,
        outlined: OutlinedVariant,
      },
    }

    const Component =
      icons[size]?.[variant] ??
      icons[size]?.outlined ??
      icons[size]?.filled ??
      icons.medium[variant] ??
      OutlinedVariant ??
      FilledVariant ??
      (() => null)

    const iconVariant = Component === FilledVariant ? 'filled' : variant

    return (
      <Box
        {...props}
        ref={ref}
        sx={mergeSx(
          {
            ...svgSizeFactory(size),
            ...(!smallVariants?.outlined &&
              svgPathSizeFactory(size, iconVariant)),
          },
          theme => ({
            display: 'flex',
            alignItems: 'center',
            ...(disabled && {
              color: theme.palette.action.disabled,
            }),
            ...(error && {
              color: theme.palette.error.main,
            }),
          }),
          sx,
        )}
      >
        {children ?? <Component />}
      </Box>
    )
  },
)

export const withIconContainer = (
  OutlinedVariant?: React.FC,
  FilledVariant?: React.FC,
  smallVariants?: {
    filled?: React.FC
    outlined?: React.FC
  },
) =>
  forwardRef<Element, IconProps>((props, ref) => (
    <IconContainer
      {...props}
      ref={ref}
      OutlinedVariant={OutlinedVariant}
      FilledVariant={FilledVariant}
      smallVariants={smallVariants}
    />
  ))

export default IconContainer
