import React, { useDeferredValue } from 'react'
import { Button, Stack, TextField } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useServiceTimeMessageMutation } from 'src/entities/service-time/queries/serviceTime'
import Modal from 'src/shared/components/common/Modal'
import type useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { useCurrentDate } from 'src/shared/lib/context/state/atoms/currentDate'
import { useSelectedServiceTime } from 'src/shared/lib/context/state/atoms/selectedServiceTime'
import { useStateTransaction } from 'src/shared/lib/state/hooks/useStateTransaction'
import { useServiceTimeMessage } from './components/ServiceTimeInfo/useServiceTimeMessage'

interface EditServiceTimeDialogProps {
  dialogProps: ReturnType<typeof useDialog>
}

export const ServiceTimeInfoDialog = ({
  dialogProps: { handleClose, open },
}: EditServiceTimeDialogProps) => {
  const { t } = useTranslation()

  const serviceTimeInfo = useServiceTimeMessage() ?? ''
  const serviceTime = useSelectedServiceTime()

  const date = useDeferredValue(useCurrentDate())

  const { mutate: setServiceTimeMessage } = useServiceTimeMessageMutation()

  const messageChangeHandler = React.useCallback(
    (info: string) => {
      if (!serviceTime) return

      setServiceTimeMessage({
        date,
        message: { message: info, id: serviceTime.id },
      })
    },
    [date, setServiceTimeMessage, serviceTime],
  )

  const { setState, state, commit, rollback } = useStateTransaction(
    serviceTimeInfo,
    messageChangeHandler,
  )

  const closeHandler = React.useCallback(() => {
    rollback()
    handleClose()
  }, [handleClose, rollback])

  const noteExists = !!serviceTimeInfo

  const modalTitle = noteExists
    ? t('angular.shift_message_modal_title')
    : t('service_time_info_dialog.title.add_note', 'Add note')

  const saveButtonText = noteExists
    ? t('service_time_info_dialog.button.update', 'Update note')
    : t('service_time_info_dialog.button.save', 'Save note')

  return (
    <Modal
      title={modalTitle}
      open={open}
      onClose={closeHandler}
      showCloseButton
      subtitle={t(
        'service_time_info_dialog.info_text',
        'Note for service time',
      )}
      actions={
        <>
          <Button fullWidth variant="outlined" onClick={closeHandler}>
            {t('modal.button.dismiss')}
          </Button>
          {noteExists && (
            <Button
              fullWidth
              variant="color-outlined"
              aria-checked
              onClick={() => {
                handleClose()
                messageChangeHandler('')
              }}
              color="error"
            >
              {t('service_time_info_dialog.button.remove', 'Remove note')}
            </Button>
          )}
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              handleClose()
              commit()
            }}
          >
            {saveButtonText}
          </Button>
        </>
      }
    >
      <Stack gap={3} direction="column">
        <TextField
          value={state}
          onChange={e => setState(e.target.value)}
          minRows={2}
          multiline
          fullWidth
        />
      </Stack>
    </Modal>
  )
}
