import { Stack, Typography, type StackProps } from '@mui/material'

interface ReservationDrawerSectionProps extends StackProps {
  title: string
}

const ReservationDrawerInlineSection = ({
  sx,
  title,
  children,
  ...props
}: ReservationDrawerSectionProps) => (
  <Stack
    direction={['column', 'row']}
    justifyContent="space-between"
    alignItems={['stretch', 'center']}
    sx={sx}
    gap={[0.125, 2]}
    {...props}
  >
    <Typography component="label" variant="labelTiny">
      {title}
    </Typography>
    {children}
  </Stack>
)

export default ReservationDrawerInlineSection
