import { compose, max } from 'ramda'

import { z } from 'zod'

import * as dateLib from 'src/shared/lib/range/services/date'
import * as dateRangeLib from 'src/shared/lib/range/services/timeRange'
import type { TimeRangeInterface } from 'src/shared/lib/range/types/timeRange'
import { localizedDateSchema } from 'src/shared/lib/zod/zod'

const timelineShiftSchema = z
  .object({
    id: z.string().uuid(),
    name: z.string(),
    status: z.union([
      z.literal('locked'),
      z.literal('locked_online'),
      z.literal('closed'),
      z.literal('open'),
    ]),
    startTime: localizedDateSchema,
    endTime: localizedDateSchema,
    occupiedPax: z.number(),
    totalPax: z.number(),
  })
  .transform(data => ({
    id: data.id,
    name: data.name,
    status: data.status ?? ('open' as const),
    timeRange: [data.startTime, data.endTime] as [Date, Date],
    occupiedPax: data.occupiedPax,
    totalPax: data.totalPax,
  }))

export const timelineShiftsSchema = z.array(timelineShiftSchema)

export type TimelineShift = z.infer<typeof timelineShiftSchema>

export const isLockedOnline = (shift: TimelineShift) =>
  shift.status === 'locked_online'

export const isLocked = (shift: TimelineShift) =>
  shift.status === 'locked' || isLockedOnline(shift)

export const isClosed = (shift: TimelineShift) => shift.status === 'closed'

export const COLUMN_MINUTES_WIDTH = 15

export const getRangeWidth = (range: TimeRangeInterface) => {
  const minutes = dateRangeLib.getDuration(range, 'minute')

  return Math.ceil(minutes / COLUMN_MINUTES_WIDTH)
}

const getRange = (shift: TimelineShift) => shift.timeRange
const getStart = (shift: TimelineShift) => getRange(shift)[0]

export const getColumnsWidth = compose(max<number>(1), getRangeWidth, getRange)

interface GetStartColumnDeps {
  getFirstHour: () => Date
}

export const getStartColumn =
  (d: GetStartColumnDeps) => (shift: TimelineShift) =>
    getRangeWidth([d.getFirstHour(), getStart(shift)]) + 1

interface GetGridBoundariesDeps {
  dailyShifts: TimelineShift[]
  weeklyShifts: TimelineShift[][]
}

export const getGridBoundaries = ({
  dailyShifts,
  weeklyShifts,
}: GetGridBoundariesDeps) => {
  if (!dailyShifts.length) return null

  const referenceDate = dailyShifts[0]!.timeRange[0]

  const flatTimeRanges = weeklyShifts
    .flat()
    .map(
      tr =>
        [
          dateLib.combineDateWithTime(referenceDate, tr.timeRange[0]),
          dateLib.combineDateWithTime(referenceDate, tr.timeRange[1]),
        ] as const,
    )

  if (!flatTimeRanges[0]) return null

  let minDate = flatTimeRanges[0][0]
  let maxDate = flatTimeRanges[0][1]

  flatTimeRanges.forEach(([start, end]) => {
    if (dateLib.isInPast()(minDate, start)) {
      minDate = start
    }
    if (dateLib.isInFuture()(maxDate, end)) {
      maxDate = end
    }
  })

  const minDateFloor = dateLib.roundToNearest('hour', 1, Math.floor)(minDate)
  const maxDateCeil = dateLib.roundToNearest('hour', 1, Math.ceil)(maxDate)

  return {
    minDate: dateLib.addToDate(-1, 'hour', minDateFloor),
    maxDate: dateLib.addToDate(1, 'hour', maxDateCeil),
  }
}
