import React from 'react'
import { InputAdornment } from '@mui/material'

import NumericInput, {
  type NumericInputElement,
  type NumericInputProps,
} from '../NumericInput/NumericInput'

export const LimitInput = React.forwardRef<
  NumericInputElement,
  Omit<NumericInputProps, 'endAdornment'>
>((props: NumericInputProps, ref) => (
  <NumericInput
    ref={ref}
    {...props}
    slotProps={{
      input: {
        endAdornment: (
          <InputAdornment position="end" sx={{ pr: 1.5 }}>
            /{props.maxValue}
          </InputAdornment>
        ),
      },
    }}
  />
))
