import { juxt } from 'ramda'

import { noop } from '@tanstack/react-table'

import withLayoutBreakpoints from 'src/app/hoc/withLayoutBreakpoints'
import { type MultiSelectDropdownProps } from './props'
import Sm from './sm'
import Xs from './xs'
import useDialog from '../../../dialogs/hooks/useDialog'

const Component = withLayoutBreakpoints({ xs: Xs, sm: Sm })

const MultiSelectDropdown = <T,>({
  onClose = noop,
  ...props
}: Omit<MultiSelectDropdownProps<T>, 'open' | 'onOpen'>) => {
  const { open, handleOpen, handleClose } = useDialog()

  return (
    <Component
      {...props}
      open={open}
      onClose={juxt([handleClose, onClose])}
      onOpen={handleOpen}
    />
  )
}

export default MultiSelectDropdown
