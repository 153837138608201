import { Typography, useMediaQuery } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useCurrentRestaurant } from 'src/entities/restaurant/queries/restaurants'
import { useCurrentDate } from 'src/shared/lib/context/state/atoms/currentDate'
import { useSelectedAreaAtom } from 'src/shared/lib/context/state/atoms/selectedArea'
import { useSelectedServiceTime } from 'src/shared/lib/context/state/atoms/selectedServiceTime'
import { useShortDateFormatter } from 'src/shared/lib/range/hooks/useFormattedDate'

export const PrintPreviewHeader = () => {
  const { t } = useTranslation()
  const currentRestaurant = useCurrentRestaurant()
  const serviceTime = useSelectedServiceTime()
  const displayDate = useCurrentDate()
  const [area] = useSelectedAreaAtom()

  const formatDate = useShortDateFormatter()

  const isPrintPreview = useMediaQuery('print')

  if (!isPrintPreview) return null

  return (
    <Typography variant="body1">{`${currentRestaurant?.name ?? ''} / ${
      area?.name ?? ''
    } | ${formatDate(displayDate)} ${
      serviceTime?.name ?? t('angular.all')
    }`}</Typography>
  )
}
