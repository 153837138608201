import React from 'react'
import { reduce } from 'ramda'

import { useFeatureCallback } from 'src/entities/info/hooks/useFeature'
import { sumSeatCount } from 'src/entities/reservation/services/occupancy'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import AsteriskIcon from 'src/shared/components/icons/AsteriskIcon'
import CheckedInIcon from 'src/shared/components/icons/CheckedInIcon'
import CheckedOutIcon from 'src/shared/components/icons/CheckedOutIcon'
import FilterIcon from 'src/shared/components/icons/FilterIcon'
import TableAssignedIcon from 'src/shared/components/icons/TableAssignedIcon'
import { mapMap } from 'src/shared/lib/common/services/functional/functional'
import { type FilterPreset } from '../../../service/filterPreset'
import CounterTabs from '../../CounterTabs'

interface FilterPresetPickerProps {
  onPresetChange: (preset: FilterPreset) => void
  preset: FilterPreset
  reservationsByPreset: Map<FilterPreset, ReservationInterface[]>
}

const presetToIcon: { [key in FilterPreset]: React.ReactNode } = {
  unplaced: <AsteriskIcon />,
  arriving: <TableAssignedIcon />,
  seated: <CheckedInIcon />,
  filter: <FilterIcon variant="filled" />,
  left: <CheckedOutIcon />,
}

const FilterPresetPicker = ({
  onPresetChange,
  preset,
  reservationsByPreset,
}: FilterPresetPickerProps) => {
  const isFeatureEnabled = useFeatureCallback()

  const paxByPreset = React.useMemo(
    () => mapMap(reduce(sumSeatCount, 0))(reservationsByPreset),
    [reservationsByPreset],
  )

  const availablePresets = React.useMemo<FilterPreset[]>(
    () => [
      'unplaced',
      'arriving',
      'seated',
      isFeatureEnabled('viewTable') ? 'filter' : 'left',
    ],
    [isFeatureEnabled],
  )

  React.useEffect(() => {
    if (!availablePresets.includes(preset)) {
      onPresetChange(availablePresets[0]!)
    }
  }, [availablePresets, preset, onPresetChange])

  return (
    <CounterTabs
      value={preset}
      onChange={onPresetChange}
      items={availablePresets}
      renderItem={item => presetToIcon[item]}
      getCount={item => paxByPreset.get(item) ?? 0}
      getKey={item => item}
      selectedColor="white"
    />
  )
}

export default FilterPresetPicker
