import { innerJoin } from 'ramda'

import type { TFunction } from 'i18next'

import { type LabelInterface } from '../types/label'

export const getLabelsByIds =
  (labels: LabelInterface[]) =>
  (ids: LabelInterface['id'][]): LabelInterface[] =>
    innerJoin((label, labelId) => label.id === labelId, labels, ids)

export const isSystemLabel = (labelName: string) =>
  labelName === 'bad_weather_mode'

export const getLabelName = (t: TFunction) => (labelName: string) => {
  if (isSystemLabel(labelName)) return t(`system_labels.${labelName}`)

  return labelName
}
