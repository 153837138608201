import { andThen, composeWith } from 'ramda'

import { type ApiClient } from './api'

export interface RequestUrlsInterface {
  list?: string
  delete: string
  set: string
}

export interface ResponseHandlersInterface<Entity, Response = unknown> {
  list?: (response: Response) => Entity[]
  set: (response: Response) => number | null
}

export interface CrudApiInterface<Entity> {
  getItemsList: (httpClient: ApiClient) => Entity[]
  setItem: (httpClient: ApiClient) => (entity: Entity) => Promise<number | null>
  deleteItem: (httpClient: ApiClient) => (entity: Entity) => Promise<void>
}

export const crudApiFactory = <Entity, Response>(
  urls: RequestUrlsInterface,
  handlers: ResponseHandlersInterface<Entity, Response>,
  payloadFactoryMethod: (entity: Entity) => unknown,
): CrudApiInterface<Entity> => ({
  getItemsList: httpClient =>
    handlers.list && urls.list
      ? composeWith(andThen)([handlers.list, httpClient])({
          url: urls.list,
        })
      : [],
  // eslint-disable-next-line @typescript-eslint/require-await
  setItem: httpClient => async (entity: Entity) =>
    composeWith(andThen)([handlers.set, httpClient])({
      url: urls.set,
      json: payloadFactoryMethod(entity) as object,
    }),
  deleteItem: httpClient => async entity => {
    await httpClient({
      url: urls.delete,
      json: payloadFactoryMethod(entity) as object,
    })
  },
})
