import { type CustomerInterface } from 'src/entities/customer/types/customer'
import createReducerServiceContext from './reducerServiceContextFactory'
import mergeReducer, { type State } from '../../reducer/service/merge'

const initialState: State<CustomerInterface> = {
  sources: [],
  target: null,
}

const {
  useService: useMergeActions,
  useState: useMergeStateContext,
  withProvider: withCustomerMergeReducerProvider,
} = createReducerServiceContext(initialState, mergeReducer)

export {
  useMergeActions,
  useMergeStateContext,
  withCustomerMergeReducerProvider,
}
