import { Stack } from '@mui/material'

import CircularProgress from 'src/shared/components/common/CircularProgress'

const Loader = () => (
  <Stack
    sx={{
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress />
  </Stack>
)

export default Loader
