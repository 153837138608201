import React, { useCallback, type UIEventHandler } from 'react'

import { type PositionInterface } from './types/floorPlanElement'

export const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = React.useState<PositionInterface>(
    {
      x: 0,
      y: 0,
    },
  )

  const scrollHandler: UIEventHandler<HTMLDivElement> = useCallback(event => {
    setScrollPosition({
      x: event.currentTarget.scrollLeft,
      y: event.currentTarget.scrollTop,
    })
  }, [])

  return {
    scrollPosition,
    scrollHandler,
  }
}
