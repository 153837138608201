import { useCallback, useEffect, useState } from 'react'
import { Box, Button, styled } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Modal from 'src/shared/components/common/Modal'
import { replaceBy } from 'src/shared/lib/common/services/functional/functional'
import SlotPacing from './components/SlotPacing/SlotPacing'
import { InputGroupColumnHeader } from '../../../../../InputGroupColumnHeader/InputGroupColumnHeader'
import { useBaseFormController } from '../../../../hooks/form'
import type { BaseForm } from '../../../../services/formSchema'

type Pacing = BaseForm['customPacing']['pacings'][number]

interface PacingModalContentProps {
  pacings: Pacing[]
  onChange: React.Dispatch<React.SetStateAction<Pacing[]>>
}

const ColumnHeader = styled(InputGroupColumnHeader)(({ theme }) =>
  theme.unstable_sx({
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
  }),
)

const PacingModalContent = ({ pacings, onChange }: PacingModalContentProps) => {
  const { t } = useTranslation()

  const onPacingChange = (newPacing: Pacing) => {
    onChange(prevPacings => replaceBy(p => p.time, newPacing, prevPacings))
  }

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 128px)',
        rowGap: 1,
        columnGap: 1,
        alignItems: 'center',
      }}
    >
      <ColumnHeader>
        {t(
          'schedule.shifts.guests_flow_section.custom_pacing_modal.slot_label',
          'Timeslot',
        )}
      </ColumnHeader>
      <ColumnHeader>
        {t(
          'schedule.shifts.guests_flow_section.custom_pacing_modal.max_guests_label',
          'Max number of guests',
        )}
      </ColumnHeader>
      {pacings.map(pacing => (
        <SlotPacing
          pacing={pacing}
          onChange={onPacingChange}
          key={pacing.time}
        />
      ))}
    </Box>
  )
}

interface PacingModalProps {
  open: boolean
  onClose: () => void
}

const PacingModal = ({ open, onClose }: PacingModalProps) => {
  const { t } = useTranslation()

  const {
    field: { value, onChange },
  } = useBaseFormController('customPacing.pacings')

  const [pacings, setPacings] = useState(value)

  useEffect(() => {
    if (!open) return
    setPacings(value)
  }, [open, value])

  const confirmHandler = useCallback(() => {
    onChange(pacings)
    onClose()
  }, [onChange, onClose, pacings])

  return (
    <Modal
      title={t(
        'schedule.shifts.guests_flow_section.custom_pacing_modal.title',
        'Set Guest Distribution',
      )}
      onClose={onClose}
      open={open}
      actions={
        <>
          <Button
            variant="outlined"
            color="neutral"
            size="large"
            sx={{ minWidth: 'fit-content' }}
            fullWidth
            onClick={onClose}
          >
            {t('modal.button.dismiss')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ minWidth: 'fit-content' }}
            fullWidth
            onClick={confirmHandler}
          >
            {t(
              'schedule.shifts.guests_flow_section.custom_pacing_modal.confirm_button_label',
              'Update pacing',
            )}
          </Button>
        </>
      }
    >
      <PacingModalContent pacings={pacings} onChange={setPacings} />
    </Modal>
  )
}

export default PacingModal
