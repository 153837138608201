import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useShortDateRangeFormatter } from 'src/shared/lib/range/hooks/useFormattedDate'
import { fromTime } from 'src/shared/lib/range/services/date'
import { timeRangeToString } from 'src/shared/lib/range/services/timeRange'
import { type TimeRangeInterface } from 'src/shared/lib/range/types/timeRange'
import Weekdays from '../../ShiftForm/components/GeneralSection/components/ArrivalTime/components/Weekdays'
import { type BaseForm } from '../../ShiftForm/services/formSchema'

export type ShiftLikeEntityType = 'shift' | 'exception' | 'event'
export type ScheduleFormAction = 'new' | 'edit'

export interface ShiftLikeFormEntity extends BaseForm {
  name: string
  effectivePeriod?: TimeRangeInterface
}

interface NewConflictingEntitySectionProps {
  entity: ShiftLikeFormEntity
  entityType: ShiftLikeEntityType
  actionType: ScheduleFormAction
}

export const NewConflictingEntitySection = ({
  entity,
  entityType,
  actionType,
}: NewConflictingEntitySectionProps) => {
  const { t } = useTranslation()

  const formatDateRange = useShortDateRangeFormatter({ weekday: undefined })

  return (
    <Stack gap={0.5} px={2}>
      <Typography component="p" variant="labelExtrasmall" color="grey.500">
        {t(
          `schedule_conflict_resolution.entity_headers.${actionType}_${entityType}`,
          `Placeholder for schedule_conflict_resolution.entity_headers.${actionType}_${entityType}`,
        )}
      </Typography>
      <Typography component="p" variant="labelRegular" color="grey.900">
        {entity.name}
      </Typography>
      <Typography
        component={Stack}
        gap={1}
        direction="row"
        variant="labelTiny"
        color="grey.700"
      >
        {entityType !== 'exception' && (
          <Weekdays activeWeekdays={entity.weekdays} overlappingWeekdays={[]} />
        )}
        <Typography>
          {entity.effectivePeriod && formatDateRange(entity.effectivePeriod)}
        </Typography>
        <Typography>
          {timeRangeToString([
            fromTime(entity.firstArrivalTime),
            fromTime(entity.lastArrivalTime),
          ])}
        </Typography>
      </Typography>
    </Stack>
  )
}
