import {
  PickersDay,
  pickersDayClasses,
  type PickersDayProps,
} from '@mui/x-date-pickers'

import { type Dayjs } from 'dayjs'

import { mergeSx } from 'src/app/theme/helpers'

export const DatePickerDay = ({ day, sx, ...rest }: PickersDayProps<Dayjs>) => (
  <PickersDay
    day={day}
    centerRipple={false}
    sx={mergeSx(
      {
        borderRadius: 1,
        typography: 'labelTiny',
        color: 'grey.600',
        '&:focus': { backgroundColor: 'transparent' },
        [`&.${pickersDayClasses.today}`]: { borderColor: 'grey.200' },
        [`&.${pickersDayClasses.dayOutsideMonth}`]: { color: 'grey.400' },
        [`&.${pickersDayClasses.disabled}`]: { color: 'grey.300' },
        '&:hover': {
          color: 'grey.800',
          backgroundColor: 'grey.300',
          borderRadius: 1,
          '&:focus': { backgroundColor: 'grey.300' },
          backgroundImage: 'none',
        },
        [`&.${pickersDayClasses.selected}, &.${pickersDayClasses.selected}:focus`]:
          {
            color: 'white',
            backgroundColor: 'primaryPalette.600',
          },
      },
      sx,
    )}
    {...rest}
  />
)
