import { Stack, Typography } from '@mui/material'

import { type RestaurantInterface } from 'src/entities/restaurant/types/restaurant'
import EllipsisTypography from '../../../Settings/Areas/EllipsisTypography'

interface RestaurantItemProps {
  restaurant: RestaurantInterface
}

const RestaurantItem = ({ restaurant }: RestaurantItemProps) => (
  <Stack direction="row" spacing={0.5} alignItems="end">
    <Typography variant="body2" lineHeight={1}>
      {restaurant.name}
    </Typography>
    <EllipsisTypography
      variant="body3"
      color="grey.500"
      lineHeight={1}
      sx={{ flexShrink: 1 }}
    >
      {restaurant.city}
    </EllipsisTypography>
  </Stack>
)

export default RestaurantItem
