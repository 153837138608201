import { compose } from 'ramda'
import { Button } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ReservationActions } from '../reservationActions'

interface TemplateChoiceActionsProps {
  onClose: () => void
  onSend: () => void
  onDismiss?: () => void
  action: ReservationActions
}

const TemplateChoiceButtons = ({
  onClose,
  onDismiss,
  onSend,
  action,
}: TemplateChoiceActionsProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Button
        variant="outlined"
        color="neutral"
        size="large"
        sx={{ minWidth: 'fit-content' }}
        fullWidth
        onClick={compose(() => onDismiss?.(), onClose)}
      >
        {t('common.form_buttons.cancelButtonLabel')}
      </Button>
      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{ minWidth: 'fit-content' }}
        fullWidth
        onClick={compose(onClose, onSend)}
      >
        {action === ReservationActions.Message
          ? t('angular.send')
          : t('angular.send_and_save')}
      </Button>
    </>
  )
}

export { TemplateChoiceButtons }
