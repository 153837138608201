import { toast } from 'sonner'

import { Toast, type Severity } from './Toast/Toast'

export const showToast = (
  message: string,
  severity: Severity = 'info',
  details?: string,
) =>
  toast.custom(t => (
    <Toast
      severity={severity}
      onClose={() => toast.dismiss(t)}
      message={message}
      details={details}
    />
  ))
