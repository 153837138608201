import { useEffect } from 'react'

export default <T>(
  items: (T | undefined)[],
  selectedItem: T | undefined,
  setSelectedItem: (value: T) => void,
) =>
  useEffect(() => {
    if (!items.includes(selectedItem)) setSelectedItem(items[0]!)
  }, [items, selectedItem, setSelectedItem])
