import React from 'react'

import { type SizeInterface } from './types/floorPlanElement'
import useResizeObserverRef from './useResizeObserverRef'

export const useSize = (
  itemRef: React.RefObject<Element | null>,
): SizeInterface => {
  const [itemSize, setItemSize] = React.useState<SizeInterface>({
    width: 0,
    height: 0,
  })

  const setParentSizeCallback = React.useCallback(
    (resizeObserverEntires: ResizeObserverEntry[]) => {
      const contentRect = resizeObserverEntires?.[0]?.contentRect
      if (!contentRect) return
      const { width, height } = contentRect
      setItemSize({
        width,
        height,
      })
    },
    [],
  )

  const resizeObserverRef = useResizeObserverRef(setParentSizeCallback)
  React.useLayoutEffect(() => {
    if (!itemRef.current) return

    resizeObserverRef.current.observe(itemRef.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resizeObserverRef])

  return itemSize
}
