import React from 'react'

import { useIntersectionObserverRef } from './useIntersectionObserverRef'
import {
  countVisibleItemsOnce,
  FULL_VISIBILITY_RATIO,
  observeItem,
} from '../service/intersectionObserver'

export const useNonIntersectingItemsCount = (
  containerRef: React.RefObject<HTMLElement>,
): { itemsCount: number; itemRef: React.RefCallback<HTMLElement> } => {
  const [itemsCount, setItemsCount] = React.useState<number>(0)

  const intersectionObserverRef = useIntersectionObserverRef(
    countVisibleItemsOnce(setItemsCount, FULL_VISIBILITY_RATIO),
    {
      root: containerRef.current,
      threshold: [FULL_VISIBILITY_RATIO],
    },
  )

  return {
    itemsCount,
    itemRef: observeItem(intersectionObserverRef.current),
  }
}
