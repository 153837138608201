import { Box, Typography, type BoxProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type ActivityLogEntryInterface } from 'src/entities/activity-log/types/activityLog'
import HistoryIcon from 'src/shared/components/icons/HistoryIcon'

interface LastActivityIndicatorProps extends BoxProps {
  latestAcivity: ActivityLogEntryInterface
}

export const LastActivityIndicator = ({
  latestAcivity: { who, action },
  ...props
}: LastActivityIndicatorProps) => {
  const { t } = useTranslation()
  return (
    <Box {...props} sx={{ cursor: 'pointer' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          sx={{ cursor: 'inherit' }}
          component="label"
          variant="labelTiny"
        >
          {t('angular.course')}
        </Typography>
        <HistoryIcon size="small" />
      </Box>
      <Typography fontWeight="bold">{who}</Typography>
      <Typography>{action}</Typography>
    </Box>
  )
}
