import { useCallback, useEffect, useRef, useState } from 'react'

import debounce from 'debounce'

export const usePromiseLoading = (
  promise: (() => Promise<unknown> | void) | undefined,
  delay = 0,
) => {
  const [loading, setLoading] = useState(false)
  const debouncedSetLoading = useRef(debounce(() => setLoading(true), delay))

  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])
  const handler = useCallback(async () => {
    debouncedSetLoading.current()
    try {
      await promise?.()
    } finally {
      debouncedSetLoading.current.clear()
      if (isMounted.current) setLoading(false)
    }
  }, [promise])

  return {
    handler,
    loading,
  }
}
