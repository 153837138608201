import { type PreferencesInterface } from 'src/entities/customer/types/preferences'
import BlockedIcon from 'src/shared/components/icons/BlockedIcon'
import CakeIcon from 'src/shared/components/icons/CakeIcon'
import ClipboardIcon from 'src/shared/components/icons/ClipboardIcon'
import FoodIcon from 'src/shared/components/icons/FoodIcon'
import LocationIcon from 'src/shared/components/icons/LocationIcon'
import StarIcon from 'src/shared/components/icons/StarIcon'

export interface IndicatorsProps {
  birthday?: boolean
  vip?: boolean
  comment?: boolean
  ban?: boolean
  preferences?: PreferencesInterface
  withColors?: boolean
}

const Indicators = ({
  birthday = false,
  vip = false,
  comment = false,
  ban = false,
  preferences,
  withColors = false,
}: IndicatorsProps) => (
  <>
    {birthday && <CakeIcon size="small" variant="filled" />}
    {vip && (
      <StarIcon
        size="small"
        color={withColors ? 'warningPalette.500' : undefined}
      />
    )}
    {comment && <ClipboardIcon size="small" variant="filled" />}
    {preferences?.food && <FoodIcon size="small" />}
    {preferences?.place && <LocationIcon size="small" variant="filled" />}
    {ban && (
      <BlockedIcon
        size="small"
        color={withColors ? 'errorPalette.500' : undefined}
      />
    )}
  </>
)

export default Indicators
