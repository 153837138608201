import React from 'react'

import { useUpdateReservationLinkMutation } from 'src/entities/reservation/queries/reservationsMutations'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import {
  emptyLink,
  type ReservationLink,
} from 'src/entities/reservation/types/reservationApi'
import LinkSection from './LinkSection'

interface LinkProps {
  reservation: ReservationInterface
}

const LinkView = ({ reservation }: LinkProps) => {
  const { mutateAsync } = useUpdateReservationLinkMutation()

  const [pendingLink, setPendingLink] = React.useState<ReservationLink>()

  const changeHandler = React.useCallback(
    async (newLink: ReservationLink) => {
      if (!reservation.id) {
        setPendingLink(newLink)
        return
      }

      await mutateAsync({
        reservationId: reservation.id,
        link: newLink,
      })
      setPendingLink(undefined)
    },
    [mutateAsync, reservation.id],
  )

  React.useEffect(() => {
    if (!pendingLink) return

    void changeHandler(pendingLink)
  }, [pendingLink, changeHandler])

  return (
    <LinkSection
      link={reservation?.links?.[0] ?? pendingLink ?? emptyLink}
      onLinkChange={changeHandler}
    />
  )
}

export { LinkView }
