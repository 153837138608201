import { Stack } from '@mui/material'

import { type PersistedLabelInterface } from 'src/entities/label/types/label'
import SmallLabel from 'src/widgets/Label/SmallLabel'

interface LabelsListProps {
  labels: PersistedLabelInterface[]
}

const LabelsList = ({ labels }: LabelsListProps) => (
  <Stack
    direction="row"
    sx={{
      pointerEvents: 'none',
      flexWrap: 'wrap',
    }}
  >
    {labels.map((label: PersistedLabelInterface) => (
      <SmallLabel label={label} key={label.id} />
    ))}
  </Stack>
)

export default LabelsList
