import type { TFunction } from 'i18next'

import { Colors } from 'src/shared/lib/style/types/color'
import { type LabelInterface, type PersistedLabelInterface } from './label'
import { type ApiLabel } from '../../config/types/configApi'
import { getLabelName, isSystemLabel } from '../services/labels'

export const fromApi =
  (t: TFunction) =>
  (rawLabel: ApiLabel): PersistedLabelInterface => ({
    id: rawLabel.id,
    name: getLabelName(t)(rawLabel.label),
    color: rawLabel.color,
    systemLabel: isSystemLabel(rawLabel.label),
  })

export const emptyLabel: LabelInterface = {
  id: null,
  name: '',
  color: Colors.blue,
  systemLabel: false,
}
