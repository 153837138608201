import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import useFeature from 'src/entities/info/hooks/useFeature'
import {
  hasEmail,
  hasPhoneNumber,
} from 'src/entities/reservation/services/reservation'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { type useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'

interface UseCheckPaymentPlanValidityOptions {
  reservation: ReservationInterface
  showSimpleDialog: ReturnType<typeof useSimpleDialog>['showSimpleDialog']
  onInvalid: () => void
}

export const useCheckPaymentPlanValidity = ({
  reservation,
  showSimpleDialog,
  onInvalid,
}: UseCheckPaymentPlanValidityOptions) => {
  const { t } = useTranslation()
  const paymentPlanSmsEnabled = useFeature('actionRequiredSms')

  useEffect(() => {
    if (!reservation.payment || !!reservation.id) return

    const {
      guest: { customerId },
      sendEmail,
      sendSms,
    } = reservation

    if (!customerId) {
      onInvalid()
      return
    }

    if (!paymentPlanSmsEnabled && !hasEmail(reservation)) {
      onInvalid()
      showSimpleDialog({
        title: t('payment_plan.plan_has_changed', 'Payment plan has changed'),
        message: t(
          'payment_plan.plan_changed_guest_email_required_sms_feature_disabled',
          'Payment plan has changed. A guest with an email address is required in order to send the Credit Card confirmation.',
        ),
      })
      return
    }

    if (!hasPhoneNumber(reservation) && !hasEmail(reservation)) {
      onInvalid()
      showSimpleDialog({
        title: t('payment_plan.plan_has_changed', 'Payment plan has changed'),
        message: t(
          'payment_plan.plan_changed_guest_contact_info_required',
          'Payment plan has changed. A guest email or phone number is required in order to send the Credit Card confirmation.',
        ),
      })
      return
    }

    if (!paymentPlanSmsEnabled && !sendEmail) {
      onInvalid()
      showSimpleDialog({
        title: t('payment_plan.plan_has_changed', 'Payment plan has changed'),
        message: t(
          'payment_plan.plan_changed_email_notification_required_sms_feature_disabled',
          'Payment plan has changed. E-mail notification must be selected to send the Credit Card confirmation.',
        ),
      })
      return
    }

    const noNotificationSelected = !sendSms && !sendEmail

    if (noNotificationSelected) {
      onInvalid()
      showSimpleDialog({
        title: t('payment_plan.plan_has_changed', 'Payment plan has changed'),
        message: t(
          'payment_plan.plan_changed_notification_required',
          'Payment plan has changed. E-mail or SMS notification must be selected to send the Credit Card confirmation.',
        ),
      })
    }
  }, [reservation, onInvalid, showSimpleDialog, paymentPlanSmsEnabled, t])
}
