import { type MouseEventHandler } from 'react'
import { Box, Button } from '@mui/material'

import { useTranslation } from 'react-i18next'

export const Footer = ({
  onApply,
}: {
  onApply: MouseEventHandler<HTMLButtonElement>
}) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ p: 2, background: 'white', position: 'sticky', bottom: 0 }}>
      <Button
        size="large"
        variant="contained"
        onClick={onApply}
        color="primary"
        fullWidth
      >
        {t('filters.apply', {
          tDescription: 'Mobile view filters apply button label',
          defaultValue: 'Apply',
        })}
      </Button>
    </Box>
  )
}
