import React from 'react'
import { identity, juxt } from 'ramda'
import { Box, FormControl, Stack, Typography } from '@mui/material'

import { noop } from '@tanstack/react-table'

import { type MultiSelectDropdownProps } from './props'
import { toggleBy } from '../../../../lib/common/services/functional/functional'
import { useStateTransaction } from '../../../../lib/state/hooks/useStateTransaction'
import Drawer from '../../../dialogs/Drawer'
import ArrowLeftIcon from '../../../icons/ArrowLeftIcon'
import { ChevronRightIcon } from '../../../icons/ChevronIcon'
import MultiselectDropdownItem from '../MultiselectDropdownItem'
import MultiSelectDropdownValue from '../MultiSelectDropdownValue'

const Xs = <T,>({
  open,
  onClose,
  onOpen,
  value: initialValue,
  availableSelection,
  itemToString,
  onChange,
  disabled,
  label,
  palette = 'primaryPalette',
}: MultiSelectDropdownProps<T>) => {
  const {
    state: value,
    setState: setValue,
    commit,
    rollback,
  } = useStateTransaction(initialValue, onChange)

  const applyHandler = React.useMemo(
    () => juxt([onClose ?? noop, commit]),
    [commit, onClose],
  )

  const closeHandler = React.useMemo(
    () => juxt([onClose ?? noop, rollback]),
    [onClose, rollback],
  )

  const toggleValue = React.useCallback(
    (item: T) => setValue(oldValue => toggleBy(identity, item, oldValue)),
    [setValue],
  )

  return (
    <>
      <Stack
        direction="column"
        spacing={0.5}
        onClick={onOpen}
        sx={{ cursor: 'pointer' }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="labelRegular" sx={{ pointerEvents: 'none' }}>
            {label}
          </Typography>
          <ChevronRightIcon size="medium" />
        </Stack>
        {!!initialValue.length && (
          <Box
            sx={{
              height: 30,
              pointerEvents: 'none',
              width: '100%',
            }}
          >
            <MultiSelectDropdownValue
              values={initialValue}
              size="medium"
              itemToString={itemToString}
              disabled={disabled}
              palette={palette}
            />
          </Box>
        )}
      </Stack>
      <Drawer
        onClose={closeHandler}
        onOpen={onOpen}
        open={open}
        hideBackdrop
        onReset={() => setValue([])}
        closeIcon={<ArrowLeftIcon />}
        onApply={applyHandler}
        title={label}
      >
        <FormControl fullWidth size="medium" focused={open}>
          {availableSelection.map(filterItem => (
            <MultiselectDropdownItem
              key={itemToString(filterItem)}
              value={filterItem}
              size="medium"
              onClick={() => toggleValue(filterItem)}
              selected={value.includes(filterItem)}
              disableGutters
            >
              <Typography variant="body2" color="grey.800">
                {itemToString(filterItem)}
              </Typography>
            </MultiselectDropdownItem>
          ))}
        </FormControl>
      </Drawer>
    </>
  )
}

export default Xs
