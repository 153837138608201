import { Box, useFormControl, useThemeProps } from '@mui/material'

import SizeableMenuItem, {
  type SizeableMenuItemProps,
} from '../../../common/SizeableMenuItem'
import CheckMark from '../../../icons/CheckmarkIcon'
import { type IconProps } from '../../../icons/IconContainer'

interface SelectDropdownItemProps extends SizeableMenuItemProps {
  Icon?: React.FC<IconProps>
}

const SelectDropdownItem = ({
  Icon = CheckMark,
  ...inProps
}: SelectDropdownItemProps) => {
  const formControlState = useFormControl() ?? {}

  const { children, ...props } = useThemeProps({
    props: { ...formControlState, ...inProps },
    name: 'SizeableMenuItem',
  })

  return (
    <SizeableMenuItem {...props} size={props.size ?? 'medium'} disableGutters>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flex: 1,
          gap: 1,
          justifyContent: 'space-between',
          maxWidth: 1,
        }}
      >
        <Box sx={{ maxWidth: 1 }}>{children}</Box>
        {props.selected && <Icon size="small" />}
      </Box>
    </SizeableMenuItem>
  )
}

export default SelectDropdownItem
