import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import SingleSelectDropdown from 'src/shared/components/form/inputs/SingleSelectDropdown'
import { useBaseFormController } from '../../../../../../hooks/form'
import { BookingDeadlineTypes } from '../../../../../../services/formSchema'

const options = Object.values(BookingDeadlineTypes)

const daysBeforeToLabel =
  (t: TFunction) => (deadlineType: (typeof options)[number]) => {
    switch (deadlineType) {
      case 'day-of-reservation':
        return t(
          'schedule.shifts.booking_section.deadline.same_day',
          'Day of reservation',
        )
      case 'day-before-reservation':
        return t(
          'schedule.shifts.booking_section.deadline.day_before',
          'Day before reservation',
        )
      case 'time-before-reservation':
        return t(
          'schedule.shifts.booking_section.deadline.time_before',
          'Time before reservation',
        )
      default:
        deadlineType satisfies never
        return ''
    }
  }

const DaysBeforeDeadline = () => {
  const { t } = useTranslation()

  const { field } = useBaseFormController('bookingSetup.bookingDeadline.type')

  return (
    <SingleSelectDropdown
      value={field.value}
      options={options}
      onSelect={field.onChange}
      renderOption={daysBeforeToLabel(t)}
    />
  )
}

export default DaysBeforeDeadline
