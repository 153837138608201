import React, { useCallback } from 'react'
import { juxt } from 'ramda'
import { Button, TextField } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Modal from 'src/shared/components/common/Modal'
import { withTargetValue } from 'src/shared/lib/common/services/helpers/helpers'
import { useStateTransaction } from 'src/shared/lib/state/hooks/useStateTransaction'

const MAX_CHARACTERS = 255
const NoteEditDialog = ({
  onSave,
  open,
  onClose,
  onDelete,
  initValue,
}: {
  onSave: (newValue: string) => void
  onDelete: () => void
  initValue: string
  open: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation()

  const { commit, state, setState, rollback } = useStateTransaction(
    initValue,
    onSave,
  )

  const changeHandler = useCallback(
    (value: string) => setState(value.substring(0, MAX_CHARACTERS)),
    [setState],
  )

  const noteExists = !!initValue

  const modalTitle = noteExists
    ? t('table_drawer.table_note.modal.title.edit', {
        defaultValue: 'Edit table note',
        tDescription: 'Table note edit modal title for existing note',
      })
    : t('table_drawer.table_note.modal.title.add', {
        defaultValue: 'Add table note',
        tDescription: 'Table note edit modal title for new note',
      })

  const helperText = t('table_drawer.table_note.modal.input.helper_text', {
    defaultValue: 'Characters left: {{charactersLeft}}',
    tDescription: 'Table note edit modal input helper text',
    charactersLeft: MAX_CHARACTERS - state.length,
  })

  React.useEffect(
    () => () => {
      if (open) return

      rollback()
    },
    [rollback, open],
  )

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={modalTitle}
      actions={
        <>
          <Button
            variant="outlined"
            color="neutral"
            size="large"
            fullWidth
            onClick={onClose}
          >
            {t('common.form_buttons.cancelButtonLabel')}
          </Button>
          {noteExists && (
            <Button
              variant="color-outlined"
              color="error"
              size="large"
              fullWidth
              onClick={juxt([onClose, onDelete])}
            >
              {t('common.form_buttons.deleteButtonLabel')}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={juxt([onClose, commit])}
          >
            {t('common.form_buttons.saveButtonLabel')}
          </Button>
        </>
      }
    >
      <TextField
        variant="outlined"
        fullWidth
        multiline
        label={t('table_drawer.table_note.modal.input.label', {
          defaultValue: 'Note',
          tDescription: 'Table not edit modal input label',
        })}
        helperText={helperText}
        rows={6}
        size="large"
        value={state}
        onChange={withTargetValue(changeHandler)}
      />
    </Modal>
  )
}

export default NoteEditDialog
