import { Stack } from '@mui/material'

import ReservationsCount from './ReservationsCount'
import SaveButton from './SaveButton'
import SavedButton from './SavedButton'

const TableSummary = ({
  reservationsCount,
  onSave,
  reservationsPending,
}: {
  reservationsCount: number
  onSave: () => void
  reservationsPending: boolean
}) => (
  <Stack direction="row" alignItems="center" justifyContent="space-between">
    <ReservationsCount count={reservationsCount} />
    {reservationsPending && <SaveButton onClick={onSave} />}
    {!reservationsPending && <SavedButton />}
  </Stack>
)

export default TableSummary
