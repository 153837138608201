import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useLanguageChange } from 'src/entities/locale/hooks/useLanguageChange'
import { useSupportedLanguages } from 'src/entities/locale/services/i18n'

export const LanguageToggle = () => {
  const { i18n } = useTranslation()
  const supportedLanguages = useSupportedLanguages()

  const changeLanguageHandler = useLanguageChange()

  return (
    <Stack
      direction="row"
      spacing={2}
      typography="labelTiny"
      sx={{
        textTransform: 'uppercase',
        px: 2,
        py: 1,
        justifyContent: 'space-between',
      }}
    >
      {supportedLanguages.map(language => (
        <Typography
          key={language}
          onClick={() => changeLanguageHandler(language)}
          color={
            i18n.language === language ? 'text.primary' : 'text.quaternary'
          }
          sx={{ cursor: 'pointer' }}
        >
          {language}
        </Typography>
      ))}
    </Stack>
  )
}
