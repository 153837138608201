import React, { useMemo, useState } from 'react'
import { both, complement, reject, when } from 'ramda'
import { Divider, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type AreaInterface } from 'src/entities/area/types/area'
import { getTableStatusFlags } from 'src/entities/reservation/services/checkInStatus'
import { getOccupancies } from 'src/entities/reservation/services/occupancy'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { areOccupyingTable } from 'src/entities/table/services/table'
import {
  type TableInterface,
  type TableLockInterface,
} from 'src/entities/table/types/table'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import { areOverlapping } from 'src/shared/lib/range/services/timeRange'
import { TableAssignDrawerHeader } from './components/TableAssignDrawerHeader'
import { TableAssignDrawerTable } from './components/TableAssignDrawerTable'
import { type ReservationOccupancy } from '../../service/occupancy'

interface TableAssignDrawerProps {
  areas: AreaInterface[]
  tables: TableInterface[]
  occupancies: ReservationOccupancy[]
  tableLocks: TableLockInterface[]
  selectedReservation: ReservationInterface | undefined
  onTableSelect: (table: TableInterface) => void
  onClose: () => void
}

export const TableAssignDrawer = ({
  areas,
  tables,
  onTableSelect,
  onClose,
  occupancies,
  tableLocks,
  selectedReservation,
}: TableAssignDrawerProps) => {
  const { t } = useTranslation()

  const overlappingOccupancies = React.useMemo(() => {
    if (!selectedReservation) return []

    return occupancies.filter(
      o =>
        o.reservation.id !== selectedReservation.id &&
        areOverlapping(selectedReservation.dateRange, o.reservation.dateRange),
    )
  }, [occupancies, selectedReservation])

  const reservations = useMemo(
    () => overlappingOccupancies.map(o => o.reservation),
    [overlappingOccupancies],
  )

  const getTableFlagsCallback = useMemo(
    () =>
      getTableStatusFlags(
        reservations,
        overlappingOccupancies,
        tableLocks,
        undefined,
        selectedReservation,
      ),
    [overlappingOccupancies, reservations, tableLocks, selectedReservation],
  )

  const everyOccupancy = useMemo(
    () => getOccupancies(reservations),
    [reservations],
  )

  const [showAvailable, setShowAvailable] = useState(false)

  const notInCurrentSelection = complement(
    areOccupyingTable(selectedReservation?.occupancies ?? []),
  )

  return (
    <>
      <TableAssignDrawerHeader onClose={onClose} />
      <Divider orientation="horizontal" />
      <Toggle
        label={t('table_picker_drawer.show_available_only', {
          defaultValue: 'Show available only',
          tDescription:
            'Label for "Show available only" switch in table picker drawer',
        })}
        labelProps={{
          sx: { py: 2, px: 1 },
        }}
        size="small"
        checked={showAvailable}
        onChange={(_e, checked) => setShowAvailable(checked)}
      />
      <Stack sx={{ overflow: 'auto', position: 'relative', pb: 4 }} gap={4}>
        {areas.map(a => {
          const tablesInsideArea = tables.filter(
            ({ areaId }) => a.id === areaId,
          )

          const getDisplayedTables = when(
            () => showAvailable,
            reject(
              both(notInCurrentSelection, areOccupyingTable(everyOccupancy)),
            ),
          )

          const filteredTablesInsideArea = getDisplayedTables(tablesInsideArea)

          if (!filteredTablesInsideArea.length) return null

          return (
            <Stack key={a.id}>
              <Typography
                pb={0.5}
                px={0.5}
                variant="labelExtrasmall"
                sx={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  width: '100%',
                  backgroundColor: 'grey.25',
                }}
              >
                {a.name}
              </Typography>
              <Stack gap={0.25}>
                {filteredTablesInsideArea.map(table => (
                  <TableAssignDrawerTable
                    key={table.id}
                    table={table}
                    onTableSelect={onTableSelect}
                    selectedReservation={selectedReservation}
                    flags={getTableFlagsCallback(table)}
                  />
                ))}
              </Stack>
            </Stack>
          )
        })}
      </Stack>
    </>
  )
}
