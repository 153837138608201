import { type TFunction } from 'i18next'

import {
  type Schedule,
  type ValidityScope,
} from 'src/entities/schedule/types/scheduleApi'
import { getShiftLikeWithRoomName } from '../ShiftSection/service'

interface GetPropertiesParams {
  scope: ValidityScope
  schedule: Schedule
  t: TFunction
}

export const getValidityScopeProperty = ({
  scope,
  schedule,
  t,
}: GetPropertiesParams) => {
  const roomsEnabled = schedule.scheduleFeatures.rooms

  if (scope.type === 'all')
    return roomsEnabled
      ? t('schedule.validity_scope.all', 'All shifts & rooms')
      : t('schedule.validity_scope.all_shifts', 'All shifts')

  if (scope.type === 'shift') {
    const { ids } = scope

    return [...schedule.shifts, ...schedule.events]
      .filter(s => ids.includes(s.id))
      .map(s => getShiftLikeWithRoomName({ shiftLike: s, schedule }))
      .join(', ')
  }

  if (scope.type === 'room') {
    return `(${schedule.rooms
      .filter(r => scope.ids.includes(r.id))
      .map(r => r.name)
      .join(',')})`
  }

  scope satisfies never

  return ''
}
