export const fetchVersion = async () => {
  try {
    const res = await fetch('./fat-version.json')
    const { version } = (await res.json()) as { version: string }

    return version
  } catch {
    return null
  }
}
