import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import Toggle from 'src/shared/components/form/inputs/Toggle'
import LabelsChoice from './components/LabelsChoice/LabelsChoice'
import FormSection from '../../../FormSection'
import { useBaseFormContext } from '../../hooks/form'
import { sectionKeyToLabel } from '../FormNavigation/FormNavigation'

const LabelsSection = () => {
  const { t } = useTranslation()

  const { watch, register, setValue } = useBaseFormContext()
  const labelsEnabled = watch('labels.isActive')

  useEffect(() => {
    if (labelsEnabled) return

    setValue('labels.labelIds', [])
  }, [labelsEnabled, setValue])

  return (
    <FormSection
      title={sectionKeyToLabel(t)('labels')}
      tooltipText={t(
        'schedule.shifts.labels_section.tooltipText',
        'Selected labels will be automatically added to reservations.',
      )}
    >
      <Toggle
        label={t(
          'schedule.shifts.labels_section.enabled_toggle_label',
          'Add selected labels to reservations',
        )}
        {...register('labels.isActive')}
      />
      {labelsEnabled && <LabelsChoice />}
    </FormSection>
  )
}

export default LabelsSection
