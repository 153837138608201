import { Box, Stack, Typography } from '@mui/material'

import CloseButton from '../../buttons/CloseButton'
import ErrorIcon, { InfoIcon } from '../../icons/ErrorIcon'
import SuccessIcon from '../../icons/SuccessIcon'

export type Severity = 'info' | 'success' | 'warning' | 'error'

const SeverityToIcon: { [key in Severity]: typeof InfoIcon } = {
  info: InfoIcon,
  success: SuccessIcon,
  error: ErrorIcon,
  warning: ErrorIcon,
}

const SeverityToColors: {
  [key in Severity]: {
    text: string
    icon: string
  }
} = {
  info: {
    icon: 'primaryPalette.500',
    text: 'primaryPalette.800',
  },
  success: {
    icon: 'successPalette.700',
    text: 'successPalette.900',
  },
  warning: {
    icon: 'warningPalette.500',
    text: 'warningPalette.900',
  },
  error: {
    icon: 'errorPalette.500',
    text: 'errorPalette.900',
  },
}

interface ToastProps {
  message: string
  details: string | undefined
  severity: Severity
  onClose: () => void
}

const Toast = ({ message, details, severity, onClose }: ToastProps) => {
  const Icon = SeverityToIcon[severity]
  const colors = SeverityToColors[severity]

  return (
    <Stack
      sx={theme => ({
        backgroundColor: 'white',
        minWidth: 360,
        display: 'flex',
        flexDirection: 'row',
        py: 2,
        pr: 1,
        pl: 3,
        border: '1px solid',
        borderRadius: 1,
        borderColor: 'border',
        boxShadow: theme.shadows[4],
        alignItems: 'center',
        justifyContent: 'space-between',
      })}
    >
      <Box>
        <Stack
          direction="row"
          sx={{ alignItems: 'center', color: colors.text }}
          spacing={2}
        >
          <Icon variant="filled" size="medium" sx={{ color: colors.icon }} />
          <Typography variant="labelSmall">{message}</Typography>
        </Stack>
        {details && (
          <Typography ml={5} color={colors.text} variant="labelTiny">
            {details}
          </Typography>
        )}
      </Box>
      <CloseButton size="small" onClick={onClose} />
    </Stack>
  )
}

export { Toast }
