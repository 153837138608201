import { Alert, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type IntegrationFlow } from 'src/entities/integration/api/integrationFlowsApi'
import { type IntegrationName } from 'src/entities/integration/api/integrationsApi'
import { IntegrationIcons } from '../constants/IntegrationIconMap'

interface InlayProps {
  integrationName: IntegrationName
  status: IntegrationFlow['status']
}

export const IntegrationErrorInlay = ({
  integrationName,
  status,
}: InlayProps) => {
  const { t } = useTranslation()

  const Icon = IntegrationIcons[integrationName]

  return (
    <Stack gap={2.5}>
      <Stack gap={1} direction="row" alignItems="center">
        <Icon />
        <Typography>
          {t(`settings.integrations.${integrationName}.title`, integrationName)}
        </Typography>
      </Stack>
      <Alert severity="warning">
        {t(
          `settings.integrations.${integrationName}.errors.${status}.alert_text`,
          `settings.integrations.${integrationName}.errors.${status}.alert_text`,
        )}
      </Alert>
      <Typography>
        {t(
          `settings.integrations.${integrationName}.errors.${status}.body_text`,
          `settings.integrations.${integrationName}.errors.${status}.body_text`,
        )}
      </Typography>
    </Stack>
  )
}
