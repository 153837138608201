import type React from 'react'
import { Box, Divider, Stack, type SwipeableDrawerProps } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import { Footer } from './Footer'
import { Header } from './Header'
import SwipeDrawer from '../../common/SwipeDrawer/SwipeDrawer'

const Drawer = ({
  title,
  closeIcon,
  onApply,
  onClose,
  onOpen,
  open,
  onReset,
  hideBackdrop,
  children,
  ...props
}: {
  title?: string
  closeIcon: React.ReactNode
  onApply?: () => void
  onReset?: () => void
  children: React.ReactNode
} & SwipeableDrawerProps) => (
  <SwipeDrawer
    onClose={onClose}
    open={open}
    onOpen={onOpen}
    anchor="right"
    hideBackdrop={hideBackdrop}
    sx={mergeSx(
      theme => ({
        zIndex: theme.zIndex.modal,
      }),
      props.sx,
    )}
    {...props}
  >
    <Stack
      direction="column"
      spacing={1}
      sx={{
        flexGrow: 1,
      }}
      divider={<Divider orientation="horizontal" />}
    >
      <Header
        title={title}
        onClose={onClose}
        onReset={onReset}
        closeIcon={closeIcon}
      />
      <Box
        sx={{
          pt: 1,
          px: 2,
          flexGrow: 1,
        }}
      >
        {children}
      </Box>
      {onApply && <Footer onApply={onApply} />}
    </Stack>
  </SwipeDrawer>
)

export default Drawer
