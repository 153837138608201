import { useTranslation } from 'react-i18next'

import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { tableOccupiedDialog } from '../../../ReservationDrawer/Occupancies/occupanciesDialogs'

interface TableOccupiedModalProps {
  open: boolean
  onClose: () => void
  onOverbook: () => void
}

const TableOccupiedModal = ({
  open,
  onClose,
  onOverbook,
}: TableOccupiedModalProps) => {
  const { t } = useTranslation()

  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      {...tableOccupiedDialog(t, onOverbook)}
    />
  )
}

export default TableOccupiedModal
