import { useMemo } from 'react'
import { Stack, Typography, type StackProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useRestaurantsLabels } from 'src/entities/label/queries/label'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { formatShortDatetime } from 'src/shared/lib/range/services/date'
import SmallLabel from 'src/widgets/Label/SmallLabel'
import { ReservationSeatCount } from 'src/widgets/Tables/SlotInfo/ReservationSeatCount'

interface UpcomingReservationItemProps extends StackProps {
  reservation: ReservationInterface
}

export const UpcomingReservationItem = ({
  reservation,
  ...props
}: UpcomingReservationItemProps) => {
  const { t } = useTranslation()

  const allLabels = useRestaurantsLabels()

  const labels = useMemo(
    () => allLabels.filter(l => reservation.labels.includes(l.id)),
    [allLabels, reservation.labels],
  )

  return (
    <Stack
      bgcolor="grey.25"
      border="1px solid"
      borderColor="grey.100"
      px={2}
      py={1.5}
      gap={0.75}
      {...props}
    >
      <Stack>
        <Typography component="p" variant="labelExtrasmall" color="grey.500">
          {t('caller_id.upcoming_reservation', 'Upcoming reservation')}
        </Typography>
        <Stack direction="row" alignItems="center" gap={1.5}>
          <Typography
            className="upcoming-reservation-date"
            component="p"
            variant="labelSmall"
          >
            {formatShortDatetime(reservation.dateRange[0])}
          </Typography>
          <ReservationSeatCount color="neutral" label={reservation.seatCount} />
        </Stack>
      </Stack>
      {!!labels.length && (
        <Stack direction="row" gap={0.125} flexWrap="wrap">
          {labels.map(l => (
            <SmallLabel key={l.id} label={l} />
          ))}
        </Stack>
      )}
    </Stack>
  )
}
