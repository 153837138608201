import { z } from 'zod'

import { type AuditInterface } from 'src/shared/lib/api/types/audit'
import { type UuidInterface } from 'src/shared/lib/api/types/uuid'
import { toDate } from 'src/shared/lib/range/services/date'
import {
  type DefinedRangeInterface,
  type RangeInterface,
} from 'src/shared/lib/range/types/range'
import { isNotFalsy, onlyValidArraySchema } from 'src/shared/lib/zod/zod'

const periodSchema = z.object({
  begin: z.string().datetime({ offset: true }).transform(toDate),
  end: z.string().datetime({ offset: true }).transform(toDate),
})

export const hotelStaySchema = z.object({
  id: z.string(),
  hotel_id: z.string(),
  period: periodSchema,
  status: z.string(),
  rooms_stays: z.array(
    z.object({
      room: z.object({
        number: z.string(),
        type: z.string(),
      }),
      guest_counts: z.array(
        z.object({
          age_category: z.enum([
            'infant',
            'child',
            'teenager',
            'adult',
            'junior',
          ]),
          count: z.number(),
        }),
      ),
      attributes: onlyValidArraySchema(z.object({ label: z.coerce.string() })),
      period: periodSchema,
    }),
  ),
  guests: z.array(
    z.object({
      last_name: z.string().nullish(),
      first_name: z.string().nullish(),
      company: z.string().nullish(),
      customer_id: z.number().nullish(),
    }),
  ),
  comments: z.array(z.string()),
  created_at: z.string().datetime({ offset: true }).transform(toDate),
  updated_at: z.string().datetime({ offset: true }).transform(toDate),
})

type RawData = z.infer<typeof hotelStaySchema>

interface MappedResponse
  extends Record<string, unknown>,
    UuidInterface,
    AuditInterface {}

type AgeGroupCategory =
  RawData['rooms_stays'][number]['guest_counts'][number]['age_category']

const AgeGroupOrder: Record<AgeGroupCategory, number> = {
  adult: 0,
  teenager: 1,
  junior: 2,
  child: 3,
  infant: 4,
}

export const ageGroupSorter = (
  a: keyof typeof AgeGroupOrder,
  b: keyof typeof AgeGroupOrder,
) => AgeGroupOrder[a] - AgeGroupOrder[b]

export const getMinMaxGuestCountGroupedByAgeGroup = (
  rawRoomStays: Pick<RawData['rooms_stays'][number], 'guest_counts'>[],
) => {
  const result = [] as {
    count: RangeInterface<number>
    ageGroup: AgeGroupCategory
  }[]

  rawRoomStays.forEach(rs => {
    rs.guest_counts.forEach(({ age_category, count }) => {
      const idx = result.findIndex(({ ageGroup }) => age_category === ageGroup)

      if (idx === -1) {
        result.push({ count: [count, count], ageGroup: age_category })
      } else {
        result[idx]!.count[0] = Math.min(result[idx]!.count[0]!, count)
        result[idx]!.count[1] = Math.max(result[idx]!.count[1]!, count)
      }
    })
  })

  return result.sort((a, b) => ageGroupSorter(a.ageGroup, b.ageGroup))
}

const categoriesFromAttributes = (
  attributes: RawData['rooms_stays'][number]['attributes'],
) => attributes.map(({ label }) => label)

export const mapRawHotelStays = (parsed: RawData) =>
  ({
    uuid: parsed.id,
    created: parsed.created_at,
    updated: parsed.updated_at,
    comment: parsed.comments.map(s => s.trim()).join('; '),
    dateRange: [
      parsed.period.begin,
      parsed.period.end,
    ] as DefinedRangeInterface<Date>,
    status: parsed.status,
    rooms: parsed.rooms_stays.map(roomStay => ({
      name: roomStay.room.number || roomStay.room.type,
      dateRange: [
        roomStay.period.begin,
        roomStay.period.end,
      ] as DefinedRangeInterface<Date>,
      guestCounts: getMinMaxGuestCountGroupedByAgeGroup([roomStay]),
      rateCategories: categoriesFromAttributes(roomStay.attributes),
    })),
    guestCounts: getMinMaxGuestCountGroupedByAgeGroup(parsed.rooms_stays),
    guests: parsed.guests.map(({ first_name, last_name, customer_id }) => ({
      name: [first_name, last_name].filter(isNotFalsy).join(' '),
      customerId: customer_id,
    })),
    rateCategories: parsed.rooms_stays.flatMap(({ attributes }) =>
      categoriesFromAttributes(attributes),
    ),
  }) satisfies MappedResponse
