import { useCallback } from 'react'

import { useMatch, useNavigate } from '@tanstack/react-router'

export const useNavigateToReservations = (restaurantId: number) => {
  const navigate = useNavigate()
  const shouldRedirect = !useMatch({
    from: '/restaurants/$restaurantId/reservations',
    shouldThrow: false,
  })

  return useCallback(() => {
    if (!shouldRedirect) return undefined
    return navigate({
      to: '/restaurants/$restaurantId/reservations',
      params: { restaurantId },
    })
  }, [navigate, restaurantId, shouldRedirect])
}
