import IntersectionLineContainer from './IntersectionLineContainer'
import Weekdays from './Weekdays'
import type { ShiftOverlap } from '../../../../../services/shiftValidation'

interface WeekdayOverlapProps {
  intersection: ShiftOverlap
}

const WeekdayOverlap = ({ intersection }: WeekdayOverlapProps) => {
  const overlappingWeekdays = intersection.conflictingWeekdays
  const activeWeekdays = intersection.shift.weekdays

  return (
    <IntersectionLineContainer
      name={intersection.shift.name}
      dateOverlap={
        <Weekdays
          activeWeekdays={activeWeekdays}
          overlappingWeekdays={overlappingWeekdays}
        />
      }
      timeOverlap={intersection.conflictingTimeRange}
    />
  )
}

export default WeekdayOverlap
