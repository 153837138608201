import { useEffect } from 'react'

const useEventListenerEffect = (
  target: EventTarget,
  ...[name, callback, options]: Parameters<EventTarget['addEventListener']>
) =>
  useEffect(() => {
    target?.addEventListener?.(name, callback, options)
    return () => target?.removeEventListener?.(name, callback, options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, target])

export default useEventListenerEffect
