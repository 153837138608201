import { TabPanel } from '@mui/lab'
import { styled } from '@mui/material'

const ListTab = styled(TabPanel)({
  padding: 0,
  overflow: 'hidden',
  flexGrow: 1,
})

export default ListTab
