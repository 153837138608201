import { useApiClientWithHash } from './useApiClientWithHash'
import { useRestaurantHash } from '../../context/global/useRestaurantHash'

export interface UseApiClient {
  withAuthentication?: boolean
  handleLogout?: boolean
}

export const useApiClient = (props: UseApiClient = {}) => {
  const restaurantHash = useRestaurantHash()

  return useApiClientWithHash({ ...props, hash: restaurantHash })
}
