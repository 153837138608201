import { Suspense, useCallback, useState } from 'react'
import { CircularProgress, Stack } from '@mui/material'

import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import {
  scheduleActionSchema,
  type ScheduleAction,
  type ScheduleActivityLogEntry,
} from 'src/entities/schedule/types/scheduleApi'
import CenteredBox from 'src/shared/components/containers/CenteredBox'
import { DateRangeInput } from 'src/shared/components/form/inputs/DateRangeInput/DateRangeInput'
import MultiSelectDropdown from 'src/shared/components/form/inputs/MultiSelectDropdown'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import DetailsModal from './components/DetailsModal'
import ScheduleActivityLogTable from './components/ScheduleActivityLogTable'
import { ScheduleContainer } from '../../ShiftConfiguration/FormContainer/ScheduleContainer'

interface ScheduleActivityLogProps {
  onNavigateBack: () => void
}

const scheduleDeployedDate = new Date('2024-08-03')

export const ScheduleActivityLog = ({
  onNavigateBack,
}: ScheduleActivityLogProps) => {
  const { t } = useTranslation()

  const [filterDateRange, setFilterDateRange] =
    useState<DefinedRangeInterface<Date> | null>(null)

  const [filterEventTypes, setFilterEventTypes] = useState<ScheduleAction[]>([])

  const [selectedPayload, setSelectedPayload] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleDetailsClick = useCallback((row: ScheduleActivityLogEntry) => {
    setSelectedPayload(row.payload)
    setIsModalOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
    setSelectedPayload(null)
  }, [])

  return (
    <ScheduleContainer
      title={t('schedule.activity_log.title')}
      onNavigateBack={onNavigateBack}
    >
      <Stack gap={3}>
        <Stack direction={['column', 'column', 'row']} gap={2}>
          <DateRangeInput
            label={t('schedule.activity_log.when', 'When')}
            placeholder={t('schedule.activity_log.pick_dates', 'Pick dates')}
            minValue={scheduleDeployedDate}
            value={filterDateRange}
            setValue={setFilterDateRange}
            sx={{ width: '100%' }}
          />

          <MultiSelectDropdown<ScheduleAction>
            {...createTestIdProps('actions-filter')}
            value={filterEventTypes}
            itemToString={option => option}
            availableSelection={scheduleActionSchema.options}
            onChange={setFilterEventTypes}
            label={t('schedule.activity_log.action', 'Action')}
          />
        </Stack>
        <ErrorBoundary fallbackRender={() => 'Something went wrong'}>
          <Suspense
            fallback={
              <CenteredBox>
                <CircularProgress />
              </CenteredBox>
            }
          >
            <ScheduleActivityLogTable
              filterEventTypes={filterEventTypes}
              filterDateRange={filterDateRange}
              handleDetailsClick={handleDetailsClick}
            />
          </Suspense>
        </ErrorBoundary>

        {selectedPayload && (
          <DetailsModal
            open={isModalOpen}
            onClose={closeModal}
            payload={selectedPayload}
          />
        )}
      </Stack>
    </ScheduleContainer>
  )
}
