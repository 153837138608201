import React from 'react'

import { useTranslation } from 'react-i18next'

import { type BreakpointsMap } from '../../style/hooks/useBreakpointCallback'
import { useBreakpointsCallback } from '../../style/hooks/useBreakpointsCallback'
import {
  formatDatetime,
  formatShortDatetime,
  shortDateFormatter,
} from '../services/date'
import { type RangeInterface } from '../types/range'

const formatDateCallbacks: BreakpointsMap<(date: Date) => string> = {
  xs: formatShortDatetime,
  md: formatDatetime,
}
export const useFormattedDate = (date: Date) =>
  useBreakpointsCallback(formatDateCallbacks)(date)

export const useShortDateFormatter = (options?: Intl.DateTimeFormatOptions) => {
  const [, { language }] = useTranslation()

  return React.useCallback(
    (date: Date | null) =>
      date ? shortDateFormatter(language, options).format(date) : '',
    [language, options],
  )
}

export const useShortDateRangeFormatter = (
  options?: Intl.DateTimeFormatOptions,
) => {
  const [, { language }] = useTranslation()

  return React.useCallback(
    (range: RangeInterface<Date>) =>
      shortDateFormatter(language, options).formatRange(range[0]!, range[1]!),
    [language, options],
  )
}
