import { Button, Stack, Typography } from '@mui/material'

import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import useFeature from 'src/entities/info/hooks/useFeature'
import { type RoomInterface } from 'src/entities/room/types/room'
import { useSetBadWeatherMutation } from 'src/entities/shift-instance/queries/shift'
import { type Shift } from 'src/entities/shift-instance/types/shift'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import CheckmarkIcon from 'src/shared/components/icons/CheckmarkIcon'
import RainyCloudIcon from 'src/shared/components/icons/RainyCloudIcon'
import { useCurrentDate } from 'src/shared/lib/context/state/atoms/currentDate'

interface ShiftLockControlsProps {
  shift: Shift
  rooms: RoomInterface[]
  disabled: boolean
  onShiftStatusChange: (
    shiftId: Shift['id'],
    status: Shift['lockStatus'],
  ) => void
}

const selectedProps = {
  'aria-selected': true,
  startIcon: <CheckmarkIcon />,
}

export const formatShiftName = (shift: Shift, rooms: RoomInterface[]) => {
  const shiftRooms = rooms.filter(room => shift.rooms.includes(room.id))
  const roomNames = shiftRooms.map(room => room.name)
  const displayRoomName = roomNames.length === 1 ? `(${roomNames[0]})` : ''

  return `${shift.name} ${displayRoomName}`
}

export const translateShiftLockStatus =
  (t: TFunction) => (lockStatus: Shift['lockStatus']) => {
    if (lockStatus === 'locked') {
      return t('angular.lock')
    }

    if (lockStatus === 'locked_online') {
      return t('angular.lock_online_only')
    }

    return t('angular.open')
  }

const ShiftLockControls = ({
  shift,
  rooms,
  disabled,
  onShiftStatusChange,
}: ShiftLockControlsProps) => {
  const { t } = useTranslation()

  const { id, lockStatus } = shift

  const badWeatherModeOn = useFeature('badWeatherMode')

  const { mutate: setBadWeather, isPending } = useSetBadWeatherMutation()
  const date = useCurrentDate()

  return (
    <Stack direction="column" gap={1}>
      <Stack
        direction="row"
        gap={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography>{formatShiftName(shift, rooms)}</Typography>
        {badWeatherModeOn && (
          <Toggle
            checked={shift.badWeatherMode}
            icon={RainyCloudIcon}
            disabled={isPending}
            onChange={(_, newBadWeather) =>
              setBadWeather({
                shiftId: shift.id,
                date,
                badWeather: newBadWeather,
              })
            }
          />
        )}
      </Stack>
      <Stack direction={['column', 'row']} sx={{ width: 1 }} gap={0.5}>
        <Button
          {...(lockStatus === 'open' && selectedProps)}
          variant="color-outlined"
          fullWidth
          onClick={() => onShiftStatusChange(id, 'open')}
          disabled={disabled}
        >
          {translateShiftLockStatus(t)('open')}
        </Button>
        <Button
          {...(lockStatus === 'locked' && selectedProps)}
          variant="color-outlined"
          fullWidth
          onClick={() => onShiftStatusChange(id, 'locked')}
          disabled={disabled}
        >
          {translateShiftLockStatus(t)('locked')}
        </Button>
        <Button
          {...(lockStatus === 'locked_online' && selectedProps)}
          variant="color-outlined"
          fullWidth
          onClick={() => onShiftStatusChange(id, 'locked_online')}
          disabled={disabled}
        >
          {translateShiftLockStatus(t)('locked_online')}
        </Button>
      </Stack>
    </Stack>
  )
}

export { ShiftLockControls }
