import React from 'react'

export interface UsePopover {
  anchorEl: HTMLElement | null
  setAnchorEl: (el: HTMLElement) => void
  handleClose: () => void
  open: boolean
}

export const usePopover = (): UsePopover => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handleClose = React.useCallback(() => setAnchorEl(null), [])

  return React.useMemo(
    () => ({
      anchorEl,
      setAnchorEl,
      handleClose,
      open: !!anchorEl,
    }),
    [anchorEl, handleClose],
  )
}
