import React from 'react'

import type { CommunicationTemplate } from 'src/entities/communication-template/types/communicationTemplates'
import { useCancelReservationMutation } from 'src/entities/reservation/queries/reservationsMutations'
import {
  couldBeCharged,
  type PaymentActions,
} from 'src/entities/reservation/services/paymentStatus'
import type { ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useSettingsObjectQuery } from 'src/entities/setting/queries/settings'
import {
  isAbandoned,
  resumable,
  useResumable,
  type Step,
} from 'src/shared/lib/common/services/resumable/resumable'
import { useSetOpenReservationOccupancies } from 'src/shared/lib/context/state/atoms/selectedReservation'
import { canBeSerial } from '../../Reservations/components/FloorPlanView/occupancies'

interface UseReservationCancellation {
  onConfirmation: () => void
  onAssignSerie: () => void
  showTemplateChoice: boolean
  onTemplateChoice: () => void
  defaultSmsTemplate?: CommunicationTemplate
  defaultEmailTemplate?: CommunicationTemplate
}

interface ReservationCancellationOptions {
  wholeSerie?: boolean | undefined
  confirmed?: boolean | undefined
  charge?: boolean | undefined
  smsTemplate?: CommunicationTemplate | undefined
  emailTemplate?: CommunicationTemplate | undefined
}

interface CancellationDeps {
  reservation: ReservationInterface
  reservationAction: PaymentActions
}

export const useReservationCancellation = ({
  onConfirmation,
  onAssignSerie,
  defaultSmsTemplate,
  defaultEmailTemplate,
  showTemplateChoice,
  onTemplateChoice,
}: UseReservationCancellation) => {
  const { mutateAsync: cancelReservation } = useCancelReservationMutation()
  const { data: settings } = useSettingsObjectQuery()
  const setOpenReservationOccupancies = useSetOpenReservationOccupancies()

  const steps: Step<ReservationCancellationOptions, CancellationDeps>[] =
    React.useMemo(
      () => [
        [o => !o.confirmed, onConfirmation],
        [canBeSerial, onAssignSerie],
        [
          o => showTemplateChoice && (!o.smsTemplate || !o.emailTemplate),
          onTemplateChoice,
        ],
      ],
      [onAssignSerie, onConfirmation, onTemplateChoice, showTemplateChoice],
    )

  const { init, resume, abandon } = useResumable(
    React.useMemo(
      () =>
        resumable<ReservationCancellationOptions, CancellationDeps>(steps, {
          ...(!showTemplateChoice && {
            smsTemplate: defaultSmsTemplate,
            emailTemplate: defaultEmailTemplate,
          }),
        }),
      [defaultEmailTemplate, defaultSmsTemplate, showTemplateChoice, steps],
    ),
  )

  const initHandler = React.useCallback(
    async (
      reservation: ReservationInterface,
      reservationAction: PaymentActions,
    ) => {
      const options = await init({ reservation, reservationAction })

      if (isAbandoned(options)) return options

      await cancelReservation({
        reservation,
        action: reservationAction,
        processPayments:
          (options.charge ?? false) &&
          couldBeCharged(reservationAction)(reservation),
        emailTemplate: options.emailTemplate,
        smsTemplate: options.smsTemplate,
        treatAsSerial: options.wholeSerie,
        notifyRestaurant: settings.cancelGastroEmail,
      })

      setOpenReservationOccupancies([])

      return options
    },
    [
      init,
      cancelReservation,
      settings.cancelGastroEmail,
      setOpenReservationOccupancies,
    ],
  )

  return { init: initHandler, resume, abandon }
}
