import type React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  type DialogProps,
} from '@mui/material'

import CloseButton from '../../buttons/CloseButton'

interface ModalProps extends Omit<DialogProps, 'title' | 'content'> {
  title: React.ReactNode
  subtitle?: React.ReactNode
  showCloseButton?: boolean
  actions?: React.ReactNode
  content?: React.ReactNode
  onClose: () => void
}

const Modal = ({
  title,
  subtitle,
  children,
  showCloseButton = false,
  actions,
  content,
  ...props
}: ModalProps) => (
  <Dialog disableEnforceFocus {...props}>
    <DialogTitle>
      <Stack direction="column" gap={1}>
        <Stack
          direction="row"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {title}
          {showCloseButton && <CloseButton onClick={props.onClose} />}
        </Stack>
        {subtitle && (
          <Typography variant="body2" color="grey.700">
            {subtitle}
          </Typography>
        )}
      </Stack>
    </DialogTitle>
    <DialogContent>{content ?? children}</DialogContent>
    {actions && <DialogActions>{actions}</DialogActions>}
  </Dialog>
)

export default Modal
