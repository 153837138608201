import { z } from 'zod'

import { isNotFalsy, localizedDateSchema } from 'src/shared/lib/zod/zod'

export const groupCreatedSchema = z
  .object({
    serialId: z.number(),
  })
  .transform(d => ({
    id: d.serialId,
  }))

export const groupSchema = z.object({
  startDate: localizedDateSchema,
  endDate: localizedDateSchema,
  pattern: z.discriminatedUnion('unit', [
    z.object({
      unit: z.literal('daily'),
      interval: z.number(),
    }),
    z.object({
      unit: z.literal('weekly'),
      weekdays: z
        .array(z.number().nullable())
        .transform(d => d.filter(isNotFalsy)),
      interval: z.number(),
    }),
    z.object({
      unit: z.literal('monthly'),
      monthday: z.union([z.literal(1), z.literal(2), z.literal(3)]),
      interval: z.number(),
    }),
  ]),
})

export type ReservationGroup = z.infer<typeof groupSchema>

export enum ReservationGroupPatternUnitEnum {
  Day = 'daily',
  Week = 'weekly',
  Month = 'monthly',
}

export enum MonthlyPatternEnum {
  DayOfMonth = 2,
  WeekFromStart = 1,
  WeekFromEnd = 3,
}
