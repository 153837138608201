import { languageToLocale } from 'src/entities/locale/services/i18n'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import { type HotelStayFilterPredefined } from './hooks/usePredefinedValues'
import {
  type FilterHotelStayInterface,
  type HotelStayFilterValue,
} from './types'

const dateFormatterFactory = (language: string) =>
  new Intl.DateTimeFormat(languageToLocale(language), {
    dateStyle: 'medium',
  })

export const dateRangeFormatter = (locale: string) => {
  const formatter = dateFormatterFactory(locale)

  return (dateRange: DefinedRangeInterface<Date>) =>
    formatter.formatRange(dateRange[0], dateRange[1])
}

export const renderValue =
  (
    formatter: ReturnType<typeof dateRangeFormatter>,
    predefinedValues: HotelStayFilterPredefined,
  ) =>
  (customerHotelStays: FilterHotelStayInterface[]) =>
  (value?: HotelStayFilterValue) => {
    if (!value) return null

    if (typeof value === 'string')
      return predefinedValues.find(({ value: pv }) => pv === value)!.name

    const { dateRange } =
      customerHotelStays.find(({ uuid }) => value.uuid === uuid) ?? {}

    if (!dateRange) return null

    return formatter(dateRange)
  }
