import { useTranslation } from 'react-i18next'

import SingleSelectDropdown from 'src/shared/components/form/inputs/SingleSelectDropdown'

const zoomChoices = new Array(3).fill(1).map((_, index) => 1 - index * 0.25)
const ZoomPicker = ({
  value,
  onChange,
}: {
  value: number
  onChange: (zoom: number) => void
}) => {
  const { t } = useTranslation('', { keyPrefix: 'common' })
  const label = t('zoom_picker.zoomLabel')

  return (
    <SingleSelectDropdown
      label={label}
      size="medium"
      value={value}
      onSelect={onChange}
      options={zoomChoices}
      renderOption={zoomChoice => `${zoomChoice * 100}%`}
      fullWidth={false}
    />
  )
}

export default ZoomPicker
