import React from 'react'
import { Alert, alertClasses, Stack, Typography } from '@mui/material'

import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import PhoneCheckIcon from 'src/shared/components/icons/PhoneCheckIcon'
import { hasActiveLimits } from './services/confirmations'
import { useBaseFormContext } from '../../hooks/form'

const createMessage =
  (t: TFunction) =>
  (deadlineActive: boolean, limitActive: boolean, limitEnabled: boolean) => {
    if (deadlineActive && limitActive)
      return t(
        'schedule.shifts.confirmations_section.summary.deadline_active_limit_active',
        'Bookings allowed after booking deadline or beyond channel’s guest limit, but need approval.',
      )
    if (deadlineActive && !limitActive)
      return t(
        'schedule.shifts.confirmations_section.summary.deadline_active_limit_inactive',
        'Bookings allowed after booking deadline with approval, unless channel’s guest limit reached.',
      )
    if (deadlineActive && !limitEnabled)
      return t(
        'schedule.shifts.confirmations_section.summary.deadline_active_limit_disabled',
        'Bookings allowed after booking deadline with approval.',
      )

    if (!deadlineActive && limitActive)
      return t(
        'schedule.shifts.confirmations_section.summary.deadline_inactive_limit_active',
        'Bookings allowed beyond channel’s guest limit with approval, unless booking deadline passed.',
      )
    if (!deadlineActive && !limitActive)
      return t(
        'schedule.shifts.confirmations_section.summary.deadline_inactive_limit_inactive',
        'No bookings allowed after booking deadline or beyond channel’s guest limit.',
      )
    if (!deadlineActive && !limitEnabled)
      return t(
        'schedule.shifts.confirmations_section.summary.deadline_inactive_limit_disabled',
        'No bookings allowed after booking deadline.',
      )

    return ''
  }

const ConfirmationSummary = ({
  variant = 'normal',
}: {
  variant?: 'template' | 'normal'
}) => {
  const { t } = useTranslation()

  const { watch } = useBaseFormContext()

  const deadlineActive = watch('reservationConfirmation.afterDeadline')
  const limitActive = watch('reservationConfirmation.beyondPaxLimit')

  const allocations = watch('channelAllocations')
  const limitsEnabled = allocations && hasActiveLimits(allocations)

  const message = React.useMemo(
    () => createMessage(t)(deadlineActive, limitActive, limitsEnabled),
    [deadlineActive, limitActive, limitsEnabled, t],
  )

  if (!message) return null

  if (variant === 'template')
    return (
      <Stack
        sx={{
          backgroundColor: 'white',
          border: '1px solid',
          borderColor: 'grey.100',
          gap: 0.5,
          px: 2,
          py: 1.5,
        }}
      >
        <Typography variant="labelSmall">
          {t('schedule.shift_template_settings.ivr.results_title', 'Results')}
        </Typography>
        <Typography variant="body3">{message}</Typography>
      </Stack>
    )

  return (
    <Alert
      severity="neutral"
      sx={{
        flex: 1,
        overflow: 'hidden',
        borderWidth: 0,
        [`.${alertClasses.icon}`]: {
          '&>div>svg': {
            width: 22,
            height: 22,
          },
        },
      }}
      icon={<PhoneCheckIcon />}
    >
      <Stack direction="column" spacing={0.5}>
        <Typography variant="labelRegular">
          {t(
            'schedule.shifts.confirmations_section.summary.title',
            'Switch Selection Results',
          )}
        </Typography>
        <Typography variant="body3">{message}</Typography>
      </Stack>
    </Alert>
  )
}

export default ConfirmationSummary
