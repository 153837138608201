import React from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import { withTargetValue } from 'src/shared/lib/common/services/helpers/helpers'

const NotesEditForm = ({
  onSave,
  onCancel,
  initValue,
}: {
  onSave: (newValue: string) => void
  onCancel: () => void
  initValue: string
}) => {
  const { t } = useTranslation()

  const [value, setValue] = React.useState(initValue)

  return (
    <>
      <DialogTitle>{t('serial_reservations.noteModalTitle')}</DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <DialogContentText>
            {t('serial_reservations.noteModalSubtitle')}
          </DialogContentText>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            label={t('serial_reservations.noteLabel')}
            rows={9}
            size="large"
            value={value}
            onChange={withTargetValue(setValue)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          size="large"
          fullWidth
          onClick={onCancel}
        >
          {t('common.form_buttons.cancelButtonLabel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          onClick={() => onSave(value)}
        >
          {t('serial_reservations.saveNote')}
        </Button>
      </DialogActions>
    </>
  )
}

export default NotesEditForm
