import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import OutlinedContainer from 'src/shared/components/containers/OutlinedContainer'
import ColorPicker from 'src/shared/components/form/inputs/ColorPicker'
import { Colors } from 'src/shared/lib/style/types/color'
import { type FurnitureInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'

const Color = ({
  value,
  onChange,
  furniture,
}: {
  furniture: FurnitureInterface[]
  value: string
  onChange: (color: string) => void
}) => {
  const { t } = useTranslation('', { keyPrefix: 'settings.tables' })

  const colors = useMemo(
    () => [
      ...Object.values(Colors),
      ...furniture.map(element => element.color),
    ],
    [furniture],
  )

  return (
    <OutlinedContainer label={t('colorLabel')}>
      <ColorPicker value={value} onChange={onChange} colors={colors} />
    </OutlinedContainer>
  )
}

export default Color
