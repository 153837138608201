import React, { useState } from 'react'

import { useQueryClient } from '@tanstack/react-query'

import { useNotificationsCacheKey } from 'src/entities/notification/queries/notification'
import {
  NotificationTypeEnum,
  TodoItemStatus,
} from 'src/entities/notification/types/notification'
import { useOptimisticUpdate } from 'src/shared/lib/api/queries/optimisticUpdate'
import useSseEventListenerEffect from 'src/shared/lib/api/sse/useSseEventListenerEffect'
import {
  filterOutNotificationById,
  prependInfiniteNotificationData,
  unfilteredNotificationCacheUpdater,
} from '../service/notifications'
import {
  filterPaginatedData,
  replacePaginatedData,
} from '../service/paginatedQueryCache'

const useTodoItemStatusChangeSseEffect = () => {
  const queryClient = useQueryClient()
  const updateCache = useOptimisticUpdate()
  const openCacheKey = useNotificationsCacheKey(TodoItemStatus.Open)
  const allCacheKey = useNotificationsCacheKey('all')

  const [isUpdatingOpenCache, setIsUpdatingOpenCache] = useState(false)
  const [isUpdatingAllCache, setIsUpdatingAllCache] = useState(false)

  useSseEventListenerEffect(
    NotificationTypeEnum.TodoItemStatusChange,
    React.useCallback(
      ({ data }) => {
        setIsUpdatingOpenCache(true)
        setIsUpdatingAllCache(true)

        queryClient
          .cancelQueries({ queryKey: openCacheKey })
          .then(() => {
            // clear open cache if all set to done
            if (data.payload.all) {
              updateCache(
                openCacheKey,
                filterPaginatedData(() => false),
              )

              return setIsUpdatingOpenCache(false)
            }

            // remove or add from/to open cache
            if (data.payload.status === TodoItemStatus.Done) {
              updateCache(
                openCacheKey,
                filterPaginatedData(filterOutNotificationById(data)),
              )

              return setIsUpdatingOpenCache(false)
            }

            if (data.payload.status === TodoItemStatus.Open) {
              updateCache(
                openCacheKey,
                replacePaginatedData(
                  prependInfiniteNotificationData({
                    ...data,
                    date: data.payload.date,
                    type: NotificationTypeEnum.TodoItemCreated,
                  }),
                ),
              )

              return setIsUpdatingOpenCache(false)
            }

            // if all are set to done, or something else happens we didn't account for, invalidate
            return queryClient.invalidateQueries({ queryKey: openCacheKey })
          })
          .catch(() => {})

        // update in all
        queryClient
          .cancelQueries({ queryKey: allCacheKey })
          .then(() => {
            updateCache(
              allCacheKey,
              unfilteredNotificationCacheUpdater(data.payload),
            )
            setIsUpdatingAllCache(false)
          })
          .catch(() => {})
      },
      [allCacheKey, openCacheKey, queryClient, updateCache],
    ),
  )

  return { isUpdatingCache: isUpdatingOpenCache || isUpdatingAllCache }
}

export default useTodoItemStatusChangeSseEffect
