import { useMemo, type ReactNode } from 'react'
import { Popover, Stack, type TextFieldProps } from '@mui/material'
import type { SelectInputProps } from '@mui/material/Select/SelectInput'

import { type Dayjs } from 'dayjs'
import { useTranslation } from 'react-i18next'

import { DatePickerCalendar } from './components/DatePickerCalendar'
import dayjs, { shortDateFormatter } from '../../../../lib/range/services/date'
import { usePopover } from '../../../dialogs/hooks/usePopover'
import CalendarIcon from '../../../icons/CalendarIcon'
import DropdownFooter from '../DropdownFooter'
import SelectInput from '../SelectInput'

interface DatePickerProps {
  value: Date | null
  setValue: (value: Date | null) => void
  minValue?: Date
  showFooter?: boolean
  error?: boolean
  helperText?: ReactNode
  size?: TextFieldProps['size']
  label?: string
  sx?: SelectInputProps['sx']
  inputRef?: SelectInputProps['inputRef']
  placeholder?: string
}

export const DatePicker = ({
  value,
  setValue,
  minValue,
  error,
  helperText,
  size = 'medium',
  label,
  placeholder,
  showFooter = true,
  sx,
  inputRef,
}: DatePickerProps) => {
  const {
    i18n: { language },
  } = useTranslation()

  const { open, setAnchorEl, handleClose, anchorEl } = usePopover()

  const pickerValue = useMemo(() => value && dayjs(value), [value])

  return (
    <>
      <SelectInput
        value={value ? shortDateFormatter(language).format(value) : ''}
        placeholder={placeholder}
        onClick={event => setAnchorEl(event.currentTarget)}
        IconComponent={CalendarIcon}
        error={error}
        helperText={helperText}
        size={size}
        label={label}
        sx={sx}
        inputRef={inputRef}
      />
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Stack direction="column">
          <Stack direction="column" sx={{ p: 2 }} spacing={1}>
            <DatePickerCalendar
              minDate={minValue ? dayjs(minValue) : undefined}
              value={pickerValue}
              onChange={(newValue: Dayjs | null) =>
                setValue(newValue ? newValue.toDate() : newValue)
              }
              slotProps={{
                day: {
                  onClick: handleClose,
                },
              }}
            />
          </Stack>
          {showFooter && (
            <DropdownFooter
              onClear={() => setValue(null)}
              onDone={handleClose}
            />
          )}
        </Stack>
      </Popover>
    </>
  )
}
