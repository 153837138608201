import { type TextFieldProps } from '@mui/material'

import { noop } from '@tanstack/react-table'

import { timeRangeToString } from '../../../../lib/range/services/timeRange'
import { type RangeInterface } from '../../../../lib/range/types/range'
import ClockIcon from '../../../icons/ClockIcon'
import SelectInput from '../SelectInput'

const TimeRangeSelectInput = ({
  timeRange,
  disabled,
  onClick,
  ...props
}: { timeRange: RangeInterface<Date> | null } & Partial<TextFieldProps>) => (
  <SelectInput
    value={timeRangeToString(timeRange)}
    IconComponent={ClockIcon}
    iconProps={{ variant: 'filled' }}
    disabled={disabled}
    onClick={disabled ? noop : onClick}
    {...props}
  />
)

export default TimeRangeSelectInput
