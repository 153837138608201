import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'

const Name = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<ServiceTimeInterface>()

  const { t } = useTranslation('', { keyPrefix: 'settings.service_times' })

  return (
    <FormTextInput
      required
      {...register('name', {
        required: true,
        validate: {
          noSpaceName: v => !!v.trim().length,
        },
      })}
      errorText={errors?.name?.message}
      label={t('nameLabel')}
    />
  )
}

export default Name
