import { z } from 'zod'

export const restaurantSchema = z
  .object({
    id: z.number(),
    hash: z.string(),
    restaurantName: z.string(),
    city: z.string(),
    chaineId: z.number().nullable(),
  })
  .transform(({ restaurantName, ...data }) => ({
    ...data,
    name: restaurantName,
  }))

export type RestaurantInterface = z.infer<typeof restaurantSchema>
