import { type ValidityScope } from 'src/entities/schedule/types/scheduleApi'
import { type FormValidityScope } from './schema'

export const fromFormValidityScope = (
  closure: FormValidityScope,
): ValidityScope => {
  if (closure.type === 'all') return closure
  if (closure.type === 'room') return { type: 'room', ids: closure.roomIds }
  return { type: 'shift', ids: closure.shiftIds }
}

export const toFormValidityScope = (
  closure: ValidityScope,
): FormValidityScope => {
  if (closure.type === 'all') return closure
  if (closure.type === 'room') return { type: 'room', roomIds: closure.ids }
  return { type: 'shift', shiftIds: closure.ids }
}
