import { isDateSame, toTimestamp } from 'src/shared/lib/range/services/date'
import { split } from 'src/shared/lib/range/services/timeRange'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import Item from './Item'

const TimeChoice = ({
  timeRange,
  nextTimeRange,
  selectedTime,
  onTimeSelect,
}: {
  timeRange: DefinedRangeInterface<Date>
  nextTimeRange: DefinedRangeInterface<Date> | undefined
  selectedTime: Date
  onTimeSelect: (date: Date) => void
}) => {
  const times = split(timeRange, nextTimeRange)
  const isTimeSelected = isDateSame(selectedTime)

  return (
    <>
      {times.map(time => {
        const selected = isTimeSelected(time)

        return (
          <Item
            key={toTimestamp(time)}
            time={time}
            onSelect={onTimeSelect}
            selected={selected}
          />
        )
      })}
    </>
  )
}

export default TimeChoice
