import { Stack } from '@mui/material'

import { zodResolver } from '@hookform/resolvers/zod'
import { useController, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  useAddClosureMutation,
  useEditClosureMutation,
} from 'src/entities/schedule/queries/schedule'
import {
  type Closure,
  type Schedule,
} from 'src/entities/schedule/types/scheduleApi'
import { FakeLabel } from 'src/shared/components/form/common/FakeLabel/FakeLabel'
import Checkbox from 'src/shared/components/form/inputs/Checkbox'
import { DateRangeInput } from 'src/shared/components/form/inputs/DateRangeInput/DateRangeInput'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'
import { fromClosureFormData, toClosureFormData } from './service/mappers'
import { formClosureSchema, type FormClosure } from './service/schema'
import { ValidityScopeInput } from './ValidityScopeInput/ValidityScopeInput'
import FormContainer from '../FormContainer'
import { useDefaultMutationHandlers } from '../hooks/useDefaultMutationHandlers'

type DefaultValues = NonNullable<
  Parameters<typeof useForm<FormClosure>>['0']
>['defaultValues']

interface ClosureFormProps {
  schedule: Schedule
  closure?: Closure
  onNavigateBack: () => void
}

export const ClosureForm = ({
  onNavigateBack,
  schedule,
  closure,
}: ClosureFormProps) => {
  const { t } = useTranslation()

  const { mutateAsync: add } = useAddClosureMutation()
  const { mutateAsync: edit } = useEditClosureMutation()

  const handlers = useDefaultMutationHandlers(onNavigateBack)

  const defaultValues: DefaultValues = closure
    ? toClosureFormData(closure)
    : {
        scope: {
          type: 'all',
        },
        showInBook: false,
      }

  const hookFormProps = useForm<FormClosure>({
    resolver: zodResolver(formClosureSchema),
    defaultValues,
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = hookFormProps

  const { field } = useController({ name: 'effectivePeriod', control })

  return (
    <FormContainer
      title={
        closure
          ? t('schedule.closure.title_edit', 'Edit Restaurant Closure')
          : t('schedule.closure.create', 'Schedule Restaurant Closure')
      }
      onNavigateBack={onNavigateBack}
      onSave={handleSubmit(data => {
        const dto = fromClosureFormData(data)

        return closure
          ? edit({ ...dto, id: closure.id }, handlers)
          : add(dto, handlers)
      })}
    >
      <Stack gap={3}>
        <DateRangeInput
          inputRef={field.ref}
          label={t('schedule.common.valid_dates', 'Valid dates')}
          placeholder={t('schedule.common.pick_dates', 'Pick dates')}
          minValue={new Date()}
          value={field.value ?? null}
          setValue={field.onChange}
          error={!!errors.effectivePeriod}
          helperText={
            !!errors.effectivePeriod &&
            t('common.validation.required', 'This field cannot be empty')
          }
        />
        <Stack gap={1}>
          <FakeLabel>
            {t('schedule.locks.validity_scope_radio_label', 'Close')}
          </FakeLabel>
          <ValidityScopeInput
            schedule={schedule}
            hookFormProps={hookFormProps}
            slots={{
              all: (
                <Checkbox
                  label={t(
                    'schedule.locks.disable_calls',
                    'Disable phone calls during closure',
                  )}
                  size="small"
                  defaultChecked={defaultValues.disablePhoneHours}
                  {...register('disablePhoneHours')}
                />
              ),
            }}
          />
        </Stack>
        <Stack gap={1.5}>
          <FormTextInput
            label={t('schedule.locks.description', 'Description (optional)')}
            placeholder={t(
              'schedule.locks.description_placeholder',
              'Type reason of locking. This information won’t be visible outside schedule settings.',
            )}
            {...register('descriptionText', {
              setValueAs: (s: string) => s?.trim() || null,
            })}
          />
        </Stack>
      </Stack>
    </FormContainer>
  )
}
