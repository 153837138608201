import {
  Alert,
  Stack,
  Typography,
  useMediaQuery,
  type Theme,
} from '@mui/material'

import { useSelectedServiceTime } from 'src/shared/lib/context/state/atoms/selectedServiceTime'
import EditNoteIconButton from './EditNoteIconButton'

const ServiceTimeMessage = ({
  serviceTimeMessage,
  holidayMessage,
  onEdit,
}: {
  serviceTimeMessage?: string
  holidayMessage?: string
  onEdit: () => void
}) => {
  const serviceTime = useSelectedServiceTime()

  const displayMessage = !!holidayMessage || !!serviceTimeMessage

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))

  if (!displayMessage) return null

  return (
    <Alert
      severity="error"
      sx={{
        border: 'none',
        mx: 1,
        flex: 1,
        alignSelf: ['stretch', 'center'],
        overflow: 'hidden',
        minWidth: 256,
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          sx={{
            typography: 'labelSmall',
            flexShrink: 1,
            width: 'fit-content',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            lineHeight: '1',
            wordBreak: 'break-word',
          }}
        >
          {serviceTimeMessage && (
            <>
              {serviceTimeMessage}
              <br />
            </>
          )}
          {holidayMessage}
        </Typography>
        {!isMobile && serviceTime && <EditNoteIconButton onClick={onEdit} />}
      </Stack>
    </Alert>
  )
}

export default ServiceTimeMessage
