import type React from 'react'

import AppsMenuIcon from 'src/shared/components/icons/AppsMenuIcon'
import { type IconProps } from 'src/shared/components/icons/IconContainer'
import ViewListIcon from 'src/shared/components/icons/ViewListIcon'
import ViewTablelistIcon from 'src/shared/components/icons/ViewTablelistIcon'
import ViewTimelineIcon from 'src/shared/components/icons/ViewTimelineIcon'
import CounterTabs from '../../../Reservations/components/CounterTabs'
import {
  PRESENTATION_VIEWS,
  type PresentationView,
} from '../../../Reservations/service/presentationView'

const VIEW_TO_LABEL_ICON: { [key in PresentationView]: React.FC<IconProps> } = {
  [PRESENTATION_VIEWS.timeline]: ViewTimelineIcon,
  [PRESENTATION_VIEWS.reservationsTable]: ViewListIcon,
  [PRESENTATION_VIEWS.tablesTable]: ViewTablelistIcon,
  [PRESENTATION_VIEWS.floorplan]: AppsMenuIcon,
}

const ViewTabs = ({
  views,
  view,
  onViewChange,
}: {
  views: PresentationView[]
  view: PresentationView
  onViewChange: (view: PresentationView) => void
}) => (
  <CounterTabs
    value={view}
    onChange={onViewChange}
    items={views}
    getKey={item => item}
    sx={{
      flexShrink: 0,
    }}
    renderItem={item => {
      const Icon = VIEW_TO_LABEL_ICON[item]

      return <Icon />
    }}
    selectedColor="white"
  />
)

export default ViewTabs
