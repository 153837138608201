import { equals, isEmpty, reject } from 'ramda'

import { diff } from 'deep-object-diff'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const difference = <T extends Record<string, any>>(
  o1: T,
  o2: T,
  stripUndefined = true,
) => {
  const editedFields = diff(o1, o2) as Partial<T>
  const finalDiff = stripUndefined
    ? reject(equals(undefined), editedFields)
    : editedFields

  if (isEmpty(finalDiff)) return null
  return finalDiff
}
