import { type InfiniteData } from '@tanstack/react-query'

import {
  type NotificationInterface,
  type TodoItemNotificationInterface,
  type TodoItemStatusChange,
} from 'src/entities/notification/types/notification'
import { reMapPaginatedData } from './paginatedQueryCache'

export type TodoCacheUpdaterThing = Pick<
  TodoItemStatusChange,
  'all' | 'status' | 'todoId'
>

export const updateTodoItemStatus =
  (payload: TodoCacheUpdaterThing) =>
  (item: TodoItemNotificationInterface) => ({
    ...item,
    payload: {
      ...item.payload,
      status: payload.status,
    },
  })

export const updateTodoItemStatusById =
  (payload: TodoCacheUpdaterThing) => (item: TodoItemNotificationInterface) =>
    item.payload.id === payload.todoId
      ? updateTodoItemStatus(payload)(item)
      : item

export const unfilteredNotificationCacheUpdater = (
  payload: TodoCacheUpdaterThing,
) => {
  if (payload.all) return reMapPaginatedData(updateTodoItemStatus(payload))
  return reMapPaginatedData(updateTodoItemStatusById(payload))
}

export const filterOutNotificationById =
  (notification: NotificationInterface) =>
  ({ id }: NotificationInterface) =>
    id !== notification.id

export const prependInfiniteNotificationData =
  (notification: NotificationInterface) =>
  ({ pages }: InfiniteData<NotificationInterface[]>) => [
    [notification, ...pages[0]!],
    ...pages.slice(1),
  ]
