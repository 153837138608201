import React from 'react'
import { always, cond, propEq, T } from 'ramda'

import type Konva from 'konva'
import { Group, type KonvaNodeEvents } from 'react-konva'

import type { ReservationInterface } from 'src/entities/reservation/types/reservation'
import { toggleTableCursor } from 'src/entities/table/services/tableGrid'
import EllipticElement from '../EllipticElement'
import RectangularElement from '../RectangularElement'
import {
  FloorPlanElementShape,
  type FloorPlanShapeComponentProps,
} from '../types/floorPlanElement'

export interface FloorPlanElementProps
  extends KonvaNodeEvents,
    FloorPlanShapeComponentProps,
    Pick<Konva.NodeConfig, 'draggable' | 'listening'> {
  onSelect?: () => void
  isLocked?: boolean
  selectedReservation?: ReservationInterface
}

const getElementShapeComponent = cond([
  [propEq(FloorPlanElementShape.Elliptic, 'shape'), always(EllipticElement)],
  [T, always(RectangularElement)],
])

const FloorPlanElement = (
  {
    onSelect,
    element,
    shapeProps,
    isLocked,
    selectedReservation,
    ...props
  }: FloorPlanElementProps,
  tableRef: React.ForwardedRef<Konva.Group>,
) => {
  const ShapeComponent = getElementShapeComponent(element)
  if (!ShapeComponent) return null

  const cursorDisabled =
    (!!selectedReservation && !!isLocked) || !!selectedReservation?.locked

  return (
    <Group
      ref={tableRef}
      id={element?.id?.toString?.()}
      onMouseEnter={toggleTableCursor(cursorDisabled)}
      onMouseLeave={toggleTableCursor(cursorDisabled)}
      onClick={() => onSelect?.()}
      onTap={() => onSelect?.()}
      rotation={element.rotation}
      {...element.position}
      {...element.size}
      {...props}
    >
      <ShapeComponent
        element={element}
        shapeProps={{
          fill: element.color,
          ...shapeProps,
        }}
      />
    </Group>
  )
}

export default React.forwardRef(FloorPlanElement)
