import { Children, type ReactNode } from 'react'
import { Box, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ScheduleCardActions } from './index'

interface ScheduleCardWrapperProps {
  children: ReactNode
}

const Placeholder = () => {
  const { t } = useTranslation()

  return (
    <Box sx={{ background: 'white', p: 1 }}>
      {t('schedule.common.no_entries', 'No entries present')}
    </Box>
  )
}

export const ScheduleCardWrapper = ({ children }: ScheduleCardWrapperProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      p: '1.5rem',
      background: '#E1E1E1',
      borderRadius: '.25rem',
    }}
  >
    {Children.count(children) ? children : <Placeholder />}
  </Box>
)

interface ScheduleCardProps {
  title?: string
  properties?: ReactNode[]
  children?: ReactNode
  isInactive?: boolean
  onEdit: () => void
  onDelete?: () => void
}

export const ScheduleCard = ({
  title,
  properties,
  isInactive,
  children,
  onEdit,
  onDelete,
}: ScheduleCardProps) => {
  const { t } = useTranslation()

  return (
    <Box sx={{ p: '.6rem', background: isInactive ? '#CDCDCD' : 'white' }}>
      <ScheduleCardActions onEdit={onEdit} onDelete={onDelete} />
      {(title ?? isInactive) && (
        <Typography component="h5" sx={{ fontSize: '.75rem', fontWeight: 700 }}>
          {isInactive
            ? t(
                'schedule.common.inactive_instance',
                '{{instanceName}} (inactive)',
                { instanceName: title },
              )
            : title}
        </Typography>
      )}
      {properties?.map((p, idx) => (
        <Typography component="div" key={idx}>
          {p}
        </Typography>
      ))}

      {children}
    </Box>
  )
}
