import { SlotStatus } from 'src/entities/availability/types/availability'

export const BackgroundColors = {
  [SlotStatus.Available]: '#FFFFFF33',
  [SlotStatus.NotInShift]: '#D4D4D499',
  [SlotStatus.Unavailable]: '#F4DADA',
  [SlotStatus.NotInServiceTime]: undefined,
  [SlotStatus.InClosedShift]: undefined,
}

export const TextColors = {
  [SlotStatus.Available]: '#40464D',
  [SlotStatus.NotInShift]: '#4C5258',
  [SlotStatus.Unavailable]: '#6F1515',
  [SlotStatus.NotInServiceTime]: undefined,
  [SlotStatus.InClosedShift]: undefined,
}
