import {
  fromEventData as notificationFromEventData,
  type RawEventData,
} from 'src/entities/notification/services/notificationFactory'
import { type NotificationTypeEnum } from 'src/entities/notification/types/notification'

export const fromEventData = <T extends NotificationTypeEnum>(
  name: T,
  data?: string,
) => ({
  data: notificationFromEventData(
    name,
    data ? (JSON.parse(data) as RawEventData) : ({} as RawEventData),
  ),
})
