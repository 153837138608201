import { useMemo } from 'react'
import { Divider, Stack, Typography } from '@mui/material'

import { useDeletePhoneAcceptanceTimesExceptionMutation } from 'src/entities/schedule/queries/schedule'
import {
  type Closure,
  type PhoneAcceptanceTimes,
  type PhoneAcceptanceTimesException,
  type Schedule,
} from 'src/entities/schedule/types/scheduleApi'
import CancelIcon from 'src/shared/components/icons/CancelIcon'
import { getWeekdayName } from 'src/shared/lib/range/services/date'
import { useDefaultMutationHandlers } from '../../ShiftConfiguration/hooks/useDefaultMutationHandlers'
import {
  CreateScheduleExceptionButton,
  ScheduleExceptionItem,
} from '../components'
import { ScheduleCard, ScheduleCardWrapper } from '../components/ScheduleCard'

interface PhoneConfirmationsSectionProps {
  schedule: Schedule
  onEditPhoneTimes: () => void
  onEditPhoneTimesException: (
    exception?: PhoneAcceptanceTimesException | PhoneAcceptanceTimes,
  ) => void
}

const isException = (
  maybeException: Closure | PhoneAcceptanceTimesException,
): maybeException is PhoneAcceptanceTimesException =>
  Array.isArray((maybeException as PhoneAcceptanceTimesException).monday)

export const PhoneConfirmationsSection = ({
  schedule,
  onEditPhoneTimes,
  onEditPhoneTimesException,
}: PhoneConfirmationsSectionProps) => {
  const { mutateAsync: deleteException } =
    useDeletePhoneAcceptanceTimesExceptionMutation()
  const { onError } = useDefaultMutationHandlers()

  const exceptions = useMemo(
    () =>
      [
        ...schedule.phoneAcceptanceTimesExceptions,
        ...schedule.closures.filter(c => c.disablePhoneHours),
      ].sort(
        (a, b) =>
          a.effectivePeriod.start.getTime() - b.effectivePeriod.start.getTime(),
      ),
    [schedule.closures, schedule.phoneAcceptanceTimesExceptions],
  )

  return (
    <ScheduleCardWrapper>
      <ScheduleCard
        onEdit={onEditPhoneTimes}
        properties={[
          <Stack gap={0.5}>
            {...Object.entries(schedule.phoneAcceptanceTimes).map(
              ([weekday, slots], idx) => (
                <Stack key={weekday} direction="row" gap={1}>
                  <Typography
                    component="span"
                    sx={{ display: 'inline-block', minWidth: 30 }}
                  >
                    {getWeekdayName(idx + 1)}
                  </Typography>
                  <Stack>
                    {slots.length
                      ? slots.map(h => (
                          <Stack key={h.startTime} direction="row" gap={0.5}>
                            <Typography component="span">
                              {h.startTime}
                            </Typography>
                            -
                            <Typography component="span">
                              {h.endTime}
                            </Typography>
                          </Stack>
                        ))
                      : '–'}
                  </Stack>
                </Stack>
              ),
            )}
          </Stack>,
        ]}
      >
        <Divider sx={{ my: 1 }} />
        {exceptions.map(e => {
          const asd = isException(e)

          return (
            <ScheduleExceptionItem
              key={e.id}
              icon={!asd ? CancelIcon : undefined}
              dateRange={[e.effectivePeriod.start, e.effectivePeriod.end]}
              onEdit={asd ? () => onEditPhoneTimesException(e) : undefined}
              onDelete={asd ? () => deleteException(e, { onError }) : undefined}
            />
          )
        })}
        <CreateScheduleExceptionButton
          onClick={() =>
            onEditPhoneTimesException(schedule.phoneAcceptanceTimes)
          }
        />
      </ScheduleCard>
    </ScheduleCardWrapper>
  )
}
