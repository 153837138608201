import React from 'react'

import { addProp } from '../../common/services/helpers/helpers'

export interface ActionPayload<K> {
  name: K
  args: unknown[]
}

export const useServiceHandler = <
  Service extends { [K in keyof Service]: (...args: unknown[]) => State },
  State,
>(
  handler: React.Dispatch<ActionPayload<keyof Service>>,
): Service =>
  React.useMemo(() => {
    const callHandler = (
      applyHandler: React.Dispatch<ActionPayload<keyof Service>>,
    ) => ({
      apply: (_method: unknown, _thisArg: unknown, args: unknown[]) =>
        applyHandler({ args } as ActionPayload<keyof Service>),
    })

    const getMethodHandler = {
      get: (_target: unknown, name: string) =>
        new Proxy(
          () => {},
          callHandler(
            addProp<string, ActionPayload<keyof Service>, void>(['name'])(name)(
              handler,
            ),
          ),
        ),
      apply: (method: () => void, _thisArg: unknown, args: unknown[]) =>
        handler({ name: method.name as keyof Service, args }),
    }

    return new Proxy(() => {}, getMethodHandler) as Service
  }, [handler])
