import { useRef } from 'react'
import { Box } from '@mui/material'

import { useVirtualizer } from '@tanstack/react-virtual'

import { type AreaInterface } from 'src/entities/area/types/area'
import { type LabelInterface } from 'src/entities/label/types/label'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { type RoomInterface } from 'src/entities/room/types/room'
import { type TableInterface } from 'src/entities/table/types/table'
import VirtualList from 'src/shared/components/common/VirtualList'
import { useIsDateDeferredContext } from 'src/shared/lib/context/global/useIsDateDeferredContext'
import { useReservationIdForTableAssignmentAtom } from 'src/shared/lib/context/state/atoms/reservationIdForTableAssignment'
import ReservationListItem from './ReservationListItem'
import { ReservationListItemSkeleton } from './ReservationListItemSkeleton'

interface ReservationListProps {
  reservations: ReservationInterface[]
  areas: AreaInterface[]
  tables: TableInterface[]
  labels: LabelInterface[]
  rooms: RoomInterface[]
  onReservationClick: (reservationId: ReservationInterface) => void
  onReservationButtonClick: (reservation: ReservationInterface) => void
}

const ReservationsItems = ({
  reservations,
  areas,
  tables,
  labels,
  rooms,
  onReservationClick,
  onReservationButtonClick,
}: ReservationListProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const isDeferred = useIsDateDeferredContext()

  const virtualizer = useVirtualizer({
    estimateSize: () => 50,
    paddingStart: 2,
    paddingEnd: 2,
    overscan: 10,
    count: isDeferred ? 4 : reservations.length,
    getScrollElement: () => containerRef.current,
  })

  const [activeReservationId] = useReservationIdForTableAssignmentAtom()

  return (
    <VirtualList
      ref={containerRef}
      virtualizer={virtualizer}
      render={idx => {
        const r = reservations[idx]!
        return (
          <Box pb={0.25}>
            {isDeferred ? (
              <ReservationListItemSkeleton />
            ) : (
              <ReservationListItem
                reservation={r}
                restaurantAreas={areas}
                restaurantTables={tables}
                restaurantLabels={labels}
                restaurantRooms={rooms}
                onCardClick={() => onReservationClick(r)}
                onStatusButtonClick={() => onReservationButtonClick(r)}
                isActive={activeReservationId === r.id}
              />
            )}
          </Box>
        )
      }}
    />
  )
}

export default ReservationsItems
