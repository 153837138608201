import { useCallback } from 'react'

import { atom, useAtom, useSetAtom } from 'jotai'

import type { AreaInterface } from 'src/entities/area/types/area'

const selectedAreaAtom = atom<AreaInterface | undefined>(undefined)

export const useSelectedAreaAtom = () => useAtom(selectedAreaAtom)

export const useResetSelectedAreaAtom = () => {
  const set = useSetAtom(selectedAreaAtom)

  return useCallback(() => set(undefined), [set])
}
