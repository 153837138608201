import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type TeamMemberUpdateInterface } from 'src/entities/team-member/types/teamMember'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'

const FirstName = () => {
  const { t } = useTranslation()

  const {
    register,
    formState: { errors },
  } = useFormContext<TeamMemberUpdateInterface>()

  return (
    <FormTextInput
      required
      {...register('firstName', {
        required: true,
      })}
      errorText={errors.firstName?.message}
      label={t('settings.team_members.form.firstName')}
      autoComplete="one-time-code"
    />
  )
}

export default FirstName
