import { Card } from '@mui/material'

import { useTranslation } from 'react-i18next'

import CenteredBox from '../containers/CenteredBox'

const EmptyView = ({ text }: { text?: string }) => {
  const { t } = useTranslation('', { keyPrefix: 'customer' })
  return (
    <CenteredBox>
      <Card
        sx={{
          p: 4,
          typography: 'body1',
        }}
        variant="outlined"
      >
        {text ?? t('noCustomersWarning')}
      </Card>
    </CenteredBox>
  )
}

export default EmptyView
