import { type PropsWithChildren } from 'react'
import { Stack } from '@mui/material'

import { withTargetValue } from 'src/shared/lib/common/services/helpers/helpers'

interface PickerProps {
  value: string
  onChange: (value: string) => void
}

const Picker = ({
  value,
  onChange,
  children,
}: PropsWithChildren<PickerProps>) => (
  <Stack
    component="label"
    direction="row"
    spacing={1}
    sx={{
      position: 'relative',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    {children}
    <input
      type="color"
      style={{
        position: 'absolute',
        visibility: 'hidden',
      }}
      value={value}
      onChange={withTargetValue(onChange)}
    />
  </Stack>
)

export default Picker
