import { ascend, mapObjIndexed, sort } from 'ramda'

import { type AreaInterface } from 'src/entities/area/types/area'
import { type CustomerInterface } from 'src/entities/customer/types/customer'
import { type LabelInterface } from 'src/entities/label/types/label'
import {
  type ReservationCheckInStatusEnum,
  type ReservationSourceEnum,
  type ReservationStatusEnum,
} from 'src/entities/reservation/types/reservation'
import { type RestaurantInterface } from 'src/entities/restaurant/types/restaurant'
import { type PaymentFilterType } from 'src/pages/Search/Reservation/Filters/Payment'
import createReducerServiceContext from '../../context/reducer/reducerServiceContextFactory'
import { type DefinedRangeInterface } from '../../range/types/range'

export enum FiltersEnum {
  checkInStatus = 'checkInStatus',
  status = 'status',
  area = 'area',
  label = 'label',
  pax = 'pax',
  source = 'source',
  hasChoices = 'hasChoices',
  hasRestaurantNote = 'hasRestaurantNote',
  hasGuestNote = 'hasGuestNote',
  hasFeedback = 'hasFeedback',
  isGuestVip = 'isGuestVip',
  isGuestBanned = 'isGuestBanned',
  hasGuestComment = 'hasGuestComment',
  hasGuestFoodPreference = 'hasGuestFoodPreference',
  hasGuestPlacePreference = 'hasGuestPlacePreference',
  hasGuestLanguagePreference = 'hasGuestLanguagePreference',
  time = 'time',
  dateTime = 'dateTime',
  restaurant = 'restaurant',
  searchPhrase = 'searchPhrase',
  hotelStay = 'hotelStay',
  customer = 'customer',
  serial = 'serial',
  wholeGroup = 'wholeGroup',
  payment = 'payment',
}

type None = null
type Ignoreable<T> = T | None
type IgnoreableBoolean = Ignoreable<boolean>

type DateFilter = Ignoreable<DefinedRangeInterface<Date> | 'future' | 'past'>

export const isPredefinedDateFilter = (
  dateFilter: DateFilter,
): dateFilter is 'future' | 'past' =>
  dateFilter === 'future' || dateFilter === 'past'

export interface ReservationApiFilters {
  [FiltersEnum.dateTime]: DateFilter
  [FiltersEnum.time]: Ignoreable<DefinedRangeInterface<Date>>
  [FiltersEnum.label]: LabelInterface[]
  [FiltersEnum.hasFeedback]: true | null
  [FiltersEnum.status]: ReservationStatusEnum[]
  [FiltersEnum.customer]: Ignoreable<CustomerInterface>
  [FiltersEnum.hotelStay]: Ignoreable<string>
  [FiltersEnum.searchPhrase]: string
  [FiltersEnum.serial]: Ignoreable<number>
  [FiltersEnum.pax]: Ignoreable<DefinedRangeInterface<number>>
  [FiltersEnum.restaurant]: RestaurantInterface[]
  [FiltersEnum.wholeGroup]: boolean
  [FiltersEnum.payment]: PaymentFilterType[]
}

export interface ReservationClientFilters {
  [FiltersEnum.checkInStatus]: ReservationCheckInStatusEnum[]
  [FiltersEnum.source]: ReservationSourceEnum[]
  [FiltersEnum.serial]: IgnoreableBoolean
  [FiltersEnum.area]: Ignoreable<AreaInterface>[]
  [FiltersEnum.label]: Ignoreable<LabelInterface>[]
  [FiltersEnum.pax]: DefinedRangeInterface<number>[]
  [FiltersEnum.hasChoices]: IgnoreableBoolean
  [FiltersEnum.hasRestaurantNote]: IgnoreableBoolean
  [FiltersEnum.hasGuestNote]: IgnoreableBoolean
  [FiltersEnum.isGuestVip]: IgnoreableBoolean
  [FiltersEnum.isGuestBanned]: IgnoreableBoolean
  [FiltersEnum.hasGuestComment]: IgnoreableBoolean
  [FiltersEnum.hasGuestFoodPreference]: IgnoreableBoolean
  [FiltersEnum.hasGuestPlacePreference]: IgnoreableBoolean
  [FiltersEnum.hasGuestLanguagePreference]: IgnoreableBoolean
}

export type PickByType<T, U> = {
  [P in keyof T as T[P] extends U ? P : never]: T[P]
}

export type BooleanClientFilter = keyof PickByType<
  ReservationClientFilters,
  IgnoreableBoolean
>

const sortableItems = [
  FiltersEnum.status,
  FiltersEnum.label,
  FiltersEnum.restaurant,
] as const

type SortableItem = ReservationApiFilters[(typeof sortableItems)[number]]
export const sortAscending = (
  items: SortableItem,
  toString: (item: SortableItem[number]) => string,
) => sort(ascend(toString))(items)

const isSortable = <
  T extends ReservationApiFilters[keyof ReservationApiFilters],
>(
  item: T | SortableItem,
  key: keyof ReservationApiFilters,
): item is SortableItem =>
  sortableItems
    .map(testKey => String(testKey))
    .includes(key as unknown as string)

const FALLBACK_SORT_VALUE = '0'

const sortItems = <T extends SortableItem>(
  items: T,
  key: keyof ReservationApiFilters,
) => {
  if (!isSortable(items, key)) return items

  return sortAscending(items, item => {
    if (item === null) return FALLBACK_SORT_VALUE
    if (typeof item === 'number') return String(item)
    if (typeof item === 'object') {
      if (Object.hasOwn(item, 'id')) return String(item.id)

      return FALLBACK_SORT_VALUE
    }

    return item
  })
}

export const normalizeFilters = (
  filters: Partial<ReservationApiFilters>,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
): Partial<ReservationApiFilters> => mapObjIndexed(sortItems, filters)

interface FiltersService<U extends Filters> {
  setFilter: <T extends keyof U>(filter: T, value: U[T]) => U
  setMultipleFilters: (filters: Partial<U>) => U
  clearFilter: <T extends keyof U>(filter: T) => U
  clearAllFilters: () => U
}

type Filters = ReservationClientFilters | ReservationApiFilters

export const filtersReducer =
  <T extends Filters>(defaultState: T) =>
  (currentState: T): FiltersService<T> => ({
    setFilter: (filter, value) => ({
      ...currentState,
      [filter]: value,
    }),
    setMultipleFilters: filters => ({
      ...currentState,
      ...filters,
    }),
    clearAllFilters: () => defaultState,
    clearFilter: filter => ({
      ...currentState,
      [filter]: defaultState[filter],
    }),
  })

export const defaultApiFilters: ReservationApiFilters = {
  [FiltersEnum.dateTime]: null,
  [FiltersEnum.time]: null,
  [FiltersEnum.label]: [],
  [FiltersEnum.hasFeedback]: null,
  [FiltersEnum.status]: [],
  [FiltersEnum.customer]: null,
  [FiltersEnum.hotelStay]: null,
  [FiltersEnum.searchPhrase]: '',
  [FiltersEnum.serial]: null,
  [FiltersEnum.pax]: null,
  [FiltersEnum.restaurant]: [],
  [FiltersEnum.wholeGroup]: false,
  [FiltersEnum.payment]: [],
}

const {
  useService: useApiFiltersService,
  useState: useApiFiltersState,
  withProvider: withApiFiltersProvider,
} = createReducerServiceContext(
  defaultApiFilters,
  filtersReducer(defaultApiFilters),
)

export const defaultClientFilters: ReservationClientFilters = {
  [FiltersEnum.checkInStatus]: [],
  [FiltersEnum.source]: [],
  [FiltersEnum.serial]: null,
  [FiltersEnum.area]: [],
  [FiltersEnum.label]: [],
  [FiltersEnum.pax]: [],
  [FiltersEnum.hasChoices]: null,
  [FiltersEnum.hasRestaurantNote]: null,
  [FiltersEnum.hasGuestNote]: null,
  [FiltersEnum.isGuestVip]: null,
  [FiltersEnum.isGuestBanned]: null,
  [FiltersEnum.hasGuestComment]: null,
  [FiltersEnum.hasGuestFoodPreference]: null,
  [FiltersEnum.hasGuestPlacePreference]: null,
  [FiltersEnum.hasGuestLanguagePreference]: null,
}

const {
  useService: useClientFiltersService,
  useState: useClientFiltersState,
  withProvider: withClientFiltersProvider,
} = createReducerServiceContext(
  defaultClientFilters,
  filtersReducer(defaultClientFilters),
)

export {
  useApiFiltersService,
  useApiFiltersState,
  withApiFiltersProvider,
  useClientFiltersService,
  useClientFiltersState,
  withClientFiltersProvider,
}
