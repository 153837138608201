import React, { useCallback } from 'react'

import {
  useQueryClient,
  useSuspenseInfiniteQuery,
  useSuspenseQueries,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
  type UseSuspenseQueryResult,
} from '@tanstack/react-query'
import { atom, useAtomValue, useSetAtom } from 'jotai'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import {
  useCacheInsert,
  useOptimisticUpdate,
} from 'src/shared/lib/api/queries/optimisticUpdate'
import {
  useRestaurantCacheKey,
  useRestaurantCacheKeyFactory,
} from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import { create1MinuteCronTime } from 'src/shared/lib/api/services/api'
import {
  defaultPaginationParams,
  getNextCursorPageParam,
  getPrevCursorPageParam,
  type SortDirection,
} from 'src/shared/lib/api/services/reservationsBookApiClient'
import {
  normalizeFilters,
  type ReservationApiFilters,
} from 'src/shared/lib/reducer/service/reservationsFilter'
import { SlotStatus } from '../../availability/types/availability'
import {
  type TableCheckInStatusEnum,
  type TableInterface,
  type TableOccupancyInterface,
} from '../../table/types/table'
import {
  overwriteOccupancies,
  updateOccupancyStatus,
} from '../services/occupancy'
import {
  fetchReservation,
  searchPaginatedReservations,
  searchReservations,
} from '../services/reservationApi'
import { type ReservationInterface } from '../types/reservation'

const reservationSlotStatus = atom(SlotStatus.Available)
export const useReservationSlotStatus = () =>
  useAtomValue(reservationSlotStatus)
export const useSetReservationSlotStatus = () =>
  useSetAtom(reservationSlotStatus)

export const RESERVATION_KEY = ['reservation']
const SEARCH_KEY = ['reservationsSearch']
const PAGINATED_SEARCH_KEY = [...SEARCH_KEY, 'paginated']

const useReservationCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(RESERVATION_KEY)

export const useReservationSearchKey = () => useRestaurantCacheKey(SEARCH_KEY)

export const useReservationsSearchInvalidation = () => {
  const queryClient = useQueryClient()
  const key = useReservationSearchKey()

  return React.useCallback(
    () => queryClient.invalidateQueries({ queryKey: key }),
    [queryClient, key],
  )
}

export const useReservationOptimisticUpdate = () => {
  const update = useOptimisticUpdate()
  const createReservationCacheKey = useReservationCacheKeyFactory()

  return useCallback(
    (
      itemId: ReservationInterface['id'],
      updater: (oldItem: ReservationInterface) => ReservationInterface,
      initItem?: ReservationInterface,
    ) => update(createReservationCacheKey([itemId]), updater, initItem),
    [createReservationCacheKey, update],
  )
}

const useReservationsSearchByPhraseKeyFactory = () =>
  useRestaurantCacheKeyFactory(SEARCH_KEY)

export const useCheckInStatusOptimisticUpdate = () => {
  const optimisticUpdate = useReservationOptimisticUpdate()

  return useCallback(
    (
      reservationId: number,
      table: TableInterface | undefined,
      status: TableCheckInStatusEnum,
    ) => {
      optimisticUpdate(reservationId, updateOccupancyStatus(table, status))
    },
    [optimisticUpdate],
  )
}

export const useInfiniteReservationsSearchQuery = (
  filters: ReservationApiFilters,
  sortDirection: SortDirection = 'desc',
) => {
  const apiClient = useApiClient()
  const keyFactory = useRestaurantCacheKeyFactory(PAGINATED_SEARCH_KEY)

  const normalizedFilters = React.useMemo(
    () => normalizeFilters(filters),
    [filters],
  )

  return useSuspenseInfiniteQuery({
    queryKey: keyFactory([normalizedFilters, sortDirection]),
    initialPageParam: defaultPaginationParams,
    queryFn: ({ pageParam }) =>
      searchPaginatedReservations(apiClient.post)(
        normalizedFilters,
        sortDirection,
        pageParam,
      ),
    getNextPageParam: getNextCursorPageParam,
    getPreviousPageParam: getPrevCursorPageParam,
  })
}

export const useReservationsSearchQuery = (
  filters: Partial<ReservationApiFilters>,
  options?: UseSuspenseQueryOptions<number[]>,
) => {
  const apiClient = useApiClient()
  const keyFactory = useReservationsSearchByPhraseKeyFactory()

  const normalizedFilters = React.useMemo(
    () => normalizeFilters(filters),
    [filters],
  )

  return useSuspenseQuery({
    queryKey: keyFactory([normalizedFilters]),
    queryFn: () => searchReservations(apiClient.post)(filters),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 5 * 60 * 1000,
    ...options,
  })
}

const everyMinute = create1MinuteCronTime()

const getReservationRefetchInterval = () => everyMinute.getTimeout()

export const useReservations = (reservationIds: number[]) => {
  const { post } = useApiClient()

  const createReservationCacheKey = React.useDeferredValue(
    useReservationCacheKeyFactory(),
  )

  return useSuspenseQueries({
    queries: React.useDeferredValue(reservationIds).map(id => ({
      queryKey: createReservationCacheKey([id]),
      queryFn: () => fetchReservation(post)(id),
      retryOnMount: false,
      refetchOnMount: false,
      refetchInterval: getReservationRefetchInterval,
      staleTime: 60_000,
    })),
    combine: results => results.map(result => result.data),
  })
}

export type MaybeNull<T, U> = T extends undefined | null ? null : U

export const useReservation = <
  T extends ReservationInterface['id'] | undefined | null,
>(
  reservationId: T,
): UseSuspenseQueryResult<MaybeNull<T, ReservationInterface>> => {
  const apiClient = useApiClient()
  const createReservationCacheKey = useReservationCacheKeyFactory()

  return useSuspenseQuery({
    queryKey: createReservationCacheKey([reservationId]),
    queryFn: () => {
      if (!reservationId) return Promise.resolve(null)

      return fetchReservation(apiClient.post)(reservationId)
    },
    retryOnMount: false,
    refetchOnMount: false,
    refetchInterval: getReservationRefetchInterval,
    staleTime: 60_000,
  })
}

export const useReservationData = () => {
  const createReservationCacheKey = useReservationCacheKeyFactory()
  const queryClient = useQueryClient()

  return React.useCallback(
    (reservationId: ReservationInterface['id'] | undefined) => {
      if (!reservationId) return undefined

      const query = queryClient.getQueryCache().find<ReservationInterface>({
        queryKey: createReservationCacheKey([reservationId]),
      })

      if (!query) return undefined

      return query.state.data
    },
    [queryClient, createReservationCacheKey],
  )
}

export const useOccupanciesOptimisticUpdate = () => {
  const optimisticUpdate = useReservationOptimisticUpdate()

  return React.useCallback(
    (
      reservationId: ReservationInterface['id'],
      occupancies: TableOccupancyInterface[],
    ) =>
      optimisticUpdate(reservationId, r =>
        overwriteOccupancies(occupancies, r),
      ),
    [optimisticUpdate],
  )
}

export const useReservationCachePopulation = () => {
  const createReservationCacheKey = useReservationCacheKeyFactory()

  const insertCache = useCacheInsert<ReservationInterface>()

  return React.useCallback(
    (reservation: ReservationInterface) =>
      insertCache(createReservationCacheKey([reservation.id]), reservation),
    [createReservationCacheKey, insertCache],
  )
}
