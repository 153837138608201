import { useCallback, useMemo } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { noop } from '@tanstack/react-table'

import { isBackoffice } from 'src/app/apps/backoffice/isBackoffice'
import { type UseApiClient } from './useApiClient'
import { useActiveTeamMemberAtom } from '../../context/state/atoms/activeTeamMember'
import {
  getAuthenticatedApiClient,
  getAuthenticatedMutationApiClient,
} from '../services/reservationsBookApiClient'
import { TokenRefreshError } from '../types/api'

interface UseApiClientWithHash extends UseApiClient {
  hash?: string
}

export const useApiClientWithHash = ({
  handleLogout = true,
  hash = '',
}: UseApiClientWithHash = {}) => {
  const [teamMemberId] = useActiveTeamMemberAtom()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = isBackoffice() ? noop : useNavigate()

  const tokenRefreshErrorHandler = useCallback(
    async (error: TokenRefreshError | Error) => {
      if (!(error instanceof TokenRefreshError)) throw error
      if (!handleLogout) return

      await navigate({ to: '/logout' })
    },
    [handleLogout, navigate],
  )

  return useMemo(
    () => ({
      post: getAuthenticatedMutationApiClient(
        tokenRefreshErrorHandler,
        hash,
        teamMemberId,
      ),
      get: getAuthenticatedApiClient(tokenRefreshErrorHandler),
    }),
    [hash, teamMemberId, tokenRefreshErrorHandler],
  )
}
