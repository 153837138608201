import React, { Suspense, useState } from 'react'
import { Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import CircularProgress from 'src/shared/components/common/CircularProgress'
import CenteredBox from 'src/shared/components/containers/CenteredBox'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import SearchBar from 'src/shared/components/form/inputs/SearchInput'
import { CustomerPreviewModal } from '../CustomerPreview/PreviewModal'

const MergeSearchResults = React.lazy(() => import('./SearchResults'))

const CustomerMerge = () => {
  const [searchPhrase, setSearchPhrase] = useState('')

  const { t } = useTranslation('', { keyPrefix: 'customer' })
  const [customerId, setCustomerId] = useState<number>()
  const { handleClose, handleOpen, hasBeenOpened, open } = useDialog()

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        px: 6,
        py: 3,
        overflow: 'hidden',
      }}
    >
      <SearchBar
        onSearch={setSearchPhrase}
        placeholder={t('searchHelperText')}
        initialValue={searchPhrase}
      />
      <Suspense
        fallback={
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        }
      >
        {searchPhrase && (
          <MergeSearchResults
            onCustomerClick={customer => {
              setCustomerId(customer.id)
              handleOpen()
            }}
            searchPhrase={searchPhrase}
          />
        )}
      </Suspense>
      <CustomerPreviewModal
        isOpen={open}
        onClose={handleClose}
        hasBeenOpened={hasBeenOpened}
        customerId={customerId}
      />
    </Stack>
  )
}

export default CustomerMerge
