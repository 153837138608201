import { type TFunction } from 'i18next'

import { type SimpleDialogProps } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'

export const reservationNotCreatedDialog = (
  t: TFunction,
): Partial<SimpleDialogProps> => ({
  title: t(
    'reservation_edit.errors.unsaved_changes.new.title',
    'Reservation Not Created',
  ),
  message: t(
    'reservation_edit.errors.unsaved_changes.new.message',
    'You are about to exit the reservation panel, but your reservation has not been created yet. If you leave now, any information entered will be lost.',
  ),
  cancelButtonText: t(
    'reservation_edit.errors.unsaved_changes.new.cancel',
    'Stay and create',
  ),
  confirmButtonText: t(
    'reservation_edit.errors.unsaved_changes.new.confirm',
    'Exit anyway',
  ),
})

export const reservationNotSavedDialog = (
  t: TFunction,
  onConfirmation: () => void,
): Partial<SimpleDialogProps> => ({
  title: t(
    'reservation_edit.errors.unsaved_changes.existing.title',
    'Unsaved changes',
  ),
  message: t(
    'reservation_edit.errors.unsaved_changes.existing.message',
    'You have unsaved changes. If you leave now, your updates will be lost. Would you like to continue editing and save your changes?',
  ),
  cancelButtonText: t(
    'reservation_edit.errors.unsaved_changes.existing.cancel',
    'Continue editing',
  ),
  confirmButtonText: t(
    'reservation_edit.errors.unsaved_changes.existing.confirm',
    'Discard Changes',
  ),
  onConfirmation,
})

export const incompleteTableAssignmentDialog = (
  t: TFunction,
): Partial<SimpleDialogProps> => ({
  title: t(
    'reservation_edit.errors.incomplete_table_assign.title',
    'Incomplete Table Assignment',
  ),
  message: t(
    'reservation_edit.errors.incomplete_table_assign.message',
    'Are your sure you want to resume? Not all people have assigned to a table yet!',
  ),
  confirmButtonText: t(
    'reservation_edit.errors.incomplete_table_assign.confirm',
    'Continue anyway',
  ),
})
