import { useRoomsQuery } from 'src/entities/room/queries/room'
import type { Shift } from 'src/entities/shift-instance/types/shift'
import RainyCloudIcon from 'src/shared/components/icons/RainyCloudIcon'
import ShiftsChip from './components/ShiftsChip/ShiftsChip'
import { BadWeatherItem } from './components/ShiftsTooltip/components/TooltipItem'
import ShiftsTooltip from './components/ShiftsTooltip/ShiftsTooltip'

const BadWeatherIndicator = ({ shifts }: { shifts: Shift[] }) => {
  const { data: rooms } = useRoomsQuery()

  if (!shifts) return null

  return (
    <ShiftsTooltip
      tooltipToggle={<ShiftsChip shifts={shifts} icon={RainyCloudIcon} />}
    >
      {shifts.map(shift => (
        <BadWeatherItem key={shift.id} shift={shift} rooms={rooms} />
      ))}
    </ShiftsTooltip>
  )
}

export default BadWeatherIndicator
