import React from 'react'
import { complement, equals, filter } from 'ramda'
import {
  FormControl,
  InputLabel,
  menuClasses,
  Select,
  Typography,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import { DropdownPaperProps } from 'src/app/theme/theme'
import { type MultiSelectDropdownProps } from './props'
import DropdownFooter from '../DropdownFooter'
import MultiselectDropdownItem from '../MultiselectDropdownItem'
import MultiSelectDropdownValue from '../MultiSelectDropdownValue'

const Sm = <T,>({
  open,
  onClose,
  onOpen,
  value,
  availableSelection,
  itemToString,
  renderItem,
  onChange: setState,
  disabled,
  label,
  palette = 'primaryPalette',
  ...props
}: MultiSelectDropdownProps<T>) => {
  const removeItem = React.useCallback(
    (item: T) => setState(filter(complement(equals(item)), value)),
    [value, setState],
  )
  const displayValueRef = React.useRef<HTMLDivElement>(null)

  const { t } = useTranslation()

  return (
    <FormControl fullWidth size="medium" focused={open} {...props}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        multiple
        fullWidth
        size="medium"
        onChange={e =>
          Array.isArray(e.target.value) && setState(e.target.value)
        }
        displayEmpty
        label={label}
        onOpen={event => {
          if (displayValueRef?.current?.contains?.(event.target as Node)) return

          onOpen()
        }}
        open={open}
        onClose={onClose}
        value={value}
        renderValue={() => (
          <MultiSelectDropdownValue
            values={value}
            size="medium"
            onRemoveValue={removeItem}
            itemToString={itemToString}
            placeholder={t('common.multi_select.placeholder', {
              tDescription: 'Placeholder for multi select dropdown',
              defaultValue: 'Select',
            })}
            ref={displayValueRef}
            disabled={disabled}
            palette={palette}
          />
        )}
        MenuProps={{
          PaperProps: DropdownPaperProps,
          sx: {
            [`.${menuClasses.list}`]: {
              pb: 0,
            },
          },
        }}
        disabled={disabled}
      >
        {availableSelection.map((item, index) => (
          <MultiselectDropdownItem
            key={`${itemToString(item)}_${index}`}
            value={item}
            size="medium"
          >
            {renderItem ? (
              renderItem(item)
            ) : (
              <Typography variant="body2" color="grey.800">
                {itemToString(item)}
              </Typography>
            )}
          </MultiselectDropdownItem>
        ))}
        <DropdownFooter onClear={() => setState([])} />
      </Select>
    </FormControl>
  )
}

export default Sm
