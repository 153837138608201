export enum SettingsScreensEnum {
  General = 'general',
  Labels = 'labels',
  Tables = 'tables',
  Areas = 'areas',
  ServiceTimes = 'serviceTimes',
  Rooms = 'rooms',
  Schedule = 'schedule',
  TeamMembers = 'teamMembers',
  Integrations = 'integrations',
  Permissions = 'permissions',
}
