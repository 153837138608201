import React from 'react'

import {
  FiltersEnum,
  useApiFiltersService,
  useApiFiltersState,
  type ReservationApiFilters,
} from 'src/shared/lib/reducer/service/reservationsFilter'

const createGetterSetterPair = <T extends keyof ReservationApiFilters>(
  filtersState: ReturnType<typeof useApiFiltersState>,
  filterName: T,
) => filtersState[filterName]

const setterFactory =
  <T extends keyof ReservationApiFilters>(
    filtersService: ReturnType<typeof useApiFiltersService>,
    filterName: T,
  ) =>
  (value: ReservationApiFilters[T]) =>
    filtersService.setFilter(filterName, value)

export const useReservationApiFilters = () => {
  const filtersState = useApiFiltersState()
  const filtersService = useApiFiltersService()

  const getterFactory = React.useCallback(
    <T extends keyof ReservationApiFilters>(key: T) =>
      createGetterSetterPair(filtersState, key),
    [filtersState],
  )

  const status = React.useMemo(
    () => getterFactory(FiltersEnum.status),
    [getterFactory],
  )
  const setStatus = React.useMemo(
    () => setterFactory(filtersService, FiltersEnum.status),
    [filtersService],
  )

  const hasFeedback = React.useMemo(
    () => getterFactory(FiltersEnum.hasFeedback),
    [getterFactory],
  )
  const setHasFeedback = React.useMemo(
    () => setterFactory(filtersService, FiltersEnum.hasFeedback),
    [filtersService],
  )

  const dateTimeFilter = React.useMemo(
    () => getterFactory(FiltersEnum.dateTime),
    [getterFactory],
  )
  const setDateTimeFilter = React.useMemo(
    () => setterFactory(filtersService, FiltersEnum.dateTime),
    [filtersService],
  )

  const timeRange = React.useMemo(
    () => getterFactory(FiltersEnum.time),
    [getterFactory],
  )
  const setTimeRange = React.useMemo(
    () => setterFactory(filtersService, FiltersEnum.time),
    [filtersService],
  )

  const personsRange = React.useMemo(
    () => getterFactory(FiltersEnum.pax),
    [getterFactory],
  )
  const setPersonsRange = React.useMemo(
    () => setterFactory(filtersService, FiltersEnum.pax),
    [filtersService],
  )

  const restaurants = React.useMemo(
    () => getterFactory(FiltersEnum.restaurant),
    [getterFactory],
  )
  const setRestaurants = React.useMemo(
    () => setterFactory(filtersService, FiltersEnum.restaurant),
    [filtersService],
  )

  const labels = React.useMemo(
    () => getterFactory(FiltersEnum.label),
    [getterFactory],
  )
  const setLabels = React.useMemo(
    () => setterFactory(filtersService, FiltersEnum.label),
    [filtersService],
  )

  const searchPhrase = React.useMemo(
    () => getterFactory(FiltersEnum.searchPhrase),
    [getterFactory],
  )
  const setSearchPhrase = React.useMemo(
    () => setterFactory(filtersService, FiltersEnum.searchPhrase),
    [filtersService],
  )

  const hotelStayId = React.useMemo(
    () => getterFactory(FiltersEnum.hotelStay),
    [getterFactory],
  )
  const setHotelStayId = React.useMemo(
    () => setterFactory(filtersService, FiltersEnum.hotelStay),
    [filtersService],
  )

  const payment = React.useMemo(
    () => getterFactory(FiltersEnum.payment),
    [getterFactory],
  )
  const setPayment = React.useMemo(
    () => setterFactory(filtersService, FiltersEnum.payment),
    [filtersService],
  )

  return {
    status,
    setStatus,
    hasFeedback,
    setHasFeedback,
    dateTimeFilter,
    setDateTimeFilter,
    timeRange,
    setTimeRange,
    personsRange,
    setPersonsRange,
    restaurants,
    setRestaurants,
    labels,
    setLabels,
    searchPhrase,
    setSearchPhrase,
    hotelStayId,
    setHotelStayId,
    payment,
    setPayment,
  }
}
