import React from 'react'

import {
  type useInfiniteQuery,
  type useSuspenseInfiniteQuery,
} from '@tanstack/react-query'

export const defaultItemCounter = <T>(
  pages: ReturnType<typeof useSuspenseInfiniteQuery<T>>['data']['pages'],
) => pages.flat().length

const useInfiniteQueryAutomaticLoader = <T>(
  query:
    | ReturnType<typeof useSuspenseInfiniteQuery<T>>
    | ReturnType<typeof useInfiniteQuery<T>>,
  itemCounter = defaultItemCounter<T>,
  paused = false,
) => {
  const [lastIndex, setLastIndex] = React.useState(0)

  const isLastVisibleIndexCloseToEnd = React.useCallback(() => {
    if (!query.data) return false

    return lastIndex >= itemCounter(query.data.pages) - 1
  }, [itemCounter, lastIndex, query.data])
  React.useEffect(() => {
    if (
      isLastVisibleIndexCloseToEnd() &&
      !query.isFetchingNextPage &&
      query.hasNextPage &&
      !paused
    )
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      query.fetchNextPage()
  }, [isLastVisibleIndexCloseToEnd, paused, query])

  return { setLastRenderedItemIndex: setLastIndex }
}

export default useInfiniteQueryAutomaticLoader
