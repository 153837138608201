import { Chip } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  type CustomerInterface,
  type FallbackCustomer,
} from 'src/entities/customer/types/customer'
import BlockedIcon from 'src/shared/components/icons/BlockedIcon'

const Blocked = ({
  customer,
}: {
  customer: CustomerInterface | FallbackCustomer
}) => {
  const { t } = useTranslation()
  if (!customer.ban) return null

  return (
    <Chip
      color="error"
      label={t('customer.blocked')}
      icon={<BlockedIcon size="small" />}
    />
  )
}

export default Blocked
