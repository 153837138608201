import type React from 'react'
import { Box, Stack, Typography } from '@mui/material'

const Preference = ({
  icon,
  text,
}: {
  icon: React.ReactNode
  text: string
}) => (
  <Stack
    direction="row"
    spacing={1}
    sx={{
      flexGrow: 1,
      alignItems: 'flex-start',
    }}
  >
    <Box mt={0.1875}>{icon}</Box>
    <Typography>{text}</Typography>
  </Stack>
)

export default Preference
