import { FormLabel, RadioGroup, Stack } from '@mui/material'

import {
  useController,
  type FieldError,
  type FieldErrorsImpl,
  type Merge,
  type UseFormReturn,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type Schedule } from 'src/entities/schedule/types/scheduleApi'
import { DateRangeInput } from 'src/shared/components/form/inputs/DateRangeInput/DateRangeInput'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'
import { RadioOption } from 'src/shared/components/form/inputs/RadioChoice'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import { ValidityScopeInput } from '../../ClosureForm/ValidityScopeInput/ValidityScopeInput'
import FormSection from '../../FormSection'
import { type ReservationQuestionFormValues } from '../service/getReservationQuestionFormSchema'
import { getReservationQuestionTranslations } from '../service/getReservationQuestionTranslations'

type ValidityType = ReservationQuestionFormValues['validity']['type']

type DateRangeError = Merge<
  FieldError,
  FieldErrorsImpl<
    NonNullable<{
      type: 'date-range'
      dateRange: Date[]
    }>
  >
>

interface ReservationQuestionSettingsProps {
  hookFormProps: UseFormReturn<ReservationQuestionFormValues>
  schedule: Schedule
}

export const ReservationQuestionSettings = ({
  hookFormProps,
  schedule,
}: ReservationQuestionSettingsProps) => {
  const { t } = useTranslation()
  const tr = getReservationQuestionTranslations(t)

  const {
    getValues,
    watch,
    register,
    control,
    formState: { errors, defaultValues },
  } = hookFormProps

  const validityType = watch('validity.type')

  const { field } = useController({
    name: register('validity.dateRange').name,
    control,
  })

  const validityDateRangeError = !!(errors.validity as DateRangeError)
    ?.dateRange

  return (
    <FormSection title={tr.settings.title}>
      <Toggle
        label={tr.settings.isActive}
        defaultChecked={defaultValues?.isActive ?? true}
        {...register('isActive')}
      />
      <FormTextInput
        {...register('name')}
        errorText={
          !!errors.name &&
          t('common.validation.required', 'This field cannot be empty')
        }
        defaultValue={getValues('name')}
        label={tr.name.label}
        placeholder={tr.name.placeholder}
      />
      <Stack>
        <FormLabel sx={{ typography: 'body3', fontWeight: '500', mb: 1.5 }}>
          {tr.settings.validity.title}
        </FormLabel>
        <RadioGroup
          sx={{ gap: 1 }}
          defaultValue={getValues('validity.type')}
          {...register('validity.type')}
        >
          <RadioOption
            value={'always' satisfies ValidityType}
            label={tr.settings.validity.always}
            {...register('validity.type')}
          />
          <RadioOption
            value={'date-range' satisfies ValidityType}
            label={tr.settings.validity.dateRange}
            {...register('validity.type')}
          />
          {validityType === 'date-range' && (
            <DateRangeInput
              sx={{ ml: 4.5 }}
              placeholder={t('schedule.common.pick_dates', 'Pick dates')}
              minValue={new Date()}
              value={field.value as DefinedRangeInterface<Date>}
              setValue={field.onChange}
              error={validityDateRangeError}
              helperText={
                validityDateRangeError &&
                t('common.validation.required', 'This field cannot be empty')
              }
            />
          )}
        </RadioGroup>
      </Stack>
      <Stack>
        <FormLabel sx={{ typography: 'body3', fontWeight: '500', mb: 1.5 }}>
          {tr.settings.validFor.title}
        </FormLabel>
        <ValidityScopeInput hookFormProps={hookFormProps} schedule={schedule} />
      </Stack>
    </FormSection>
  )
}
