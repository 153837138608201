import { Box, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Tooltip from 'src/shared/components/common/Tooltip'
import { FakeLabel } from 'src/shared/components/form/common/FakeLabel/FakeLabel'
import DaysBeforeDeadline from './components/DaysBeforeDeadline/DaysBeforeDeadline'
import DeadlineTime from './components/DeadlineTime/DeadlineTime'

const Deadline = () => {
  const { t } = useTranslation()

  return (
    <Stack direction="column">
      <FakeLabel>
        {t(
          'schedule.shifts.booking_section.deadline.label',
          'Booking deadline',
        )}
      </FakeLabel>
      <Stack direction="row" gap={0.5} maxWidth={480}>
        <DaysBeforeDeadline />
        <DeadlineTime />
        <Box pt={1}>
          <Tooltip
            text={t(
              'schedule.shifts.booking_section.deadline.help_text',
              'Specify whether you want to allow reservations at short notice and up to what time guests can book in advance.',
            )}
            placement="bottom-end"
          />
        </Box>
      </Stack>
    </Stack>
  )
}

export default Deadline
