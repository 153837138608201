import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { TodoItemTypeEnum } from 'src/entities/notification/types/notification'

export const TITLES = {
  [TodoItemTypeEnum.New]: 'notificationTitle.new',
  [TodoItemTypeEnum.Mutated]: 'notificationTitle.mutated',
  [TodoItemTypeEnum.Cancelled]: 'notificationTitle.cancelled',
  [TodoItemTypeEnum.Moved]: 'notificationTitle.moved',
  [TodoItemTypeEnum.Reactivated]: 'notificationTitle.reactivated',
} satisfies Record<TodoItemTypeEnum, string>

interface TitleProps {
  type: TodoItemTypeEnum
  source: string
}

const Title = ({ type, source }: TitleProps) => {
  const { t } = useTranslation('', { keyPrefix: 'inbox' })

  return (
    <Typography
      variant="body2"
      fontWeight={500}
      sx={{ textTransform: 'capitalize' }}
    >
      {t(TITLES[type], { source })}
    </Typography>
  )
}

export default Title
