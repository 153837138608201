/* eslint-disable react-hooks/rules-of-hooks */
import { type ManipulateType } from 'dayjs'

import { addToDate, combineDateWithTime, getBoundaries } from './date'
import { type DefinedRangeInterface } from '../types/range'

export const fromDay = (
  date: Date,
  startTime?: Date,
): DefinedRangeInterface<Date> => {
  const [start, end] = getBoundaries(date)

  return [startTime ? combineDateWithTime(start, startTime) : start, end]
}

export const fromDuration = (
  date: Date,
  duration: number,
  unit: ManipulateType = 'minute',
) => [date, addToDate(duration, unit, date)] as DefinedRangeInterface<Date>

export const fromDate = (date: Date): DefinedRangeInterface<Date> => [
  date,
  date,
]
