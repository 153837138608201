import { createFileRoute } from '@tanstack/react-router'

import ServiceTimes from 'src/pages/Settings/ServiceTimes'
import { ConfigScroll } from './-ConfigScroll'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/config/service-times',
)({
  component: RouteComponent,
})

function RouteComponent() {
  return (
    <ConfigScroll>
      <ServiceTimes />
    </ConfigScroll>
  )
}
