import { useCallback } from 'react'

import useEventListenerEffect from '../../common/hooks/useEventListenerEffect'
import { useSetIsOnline } from '../../context/state/atoms/isOnline'

const useIsOnlineEffect = (callback: (isOnline: boolean) => void) => {
  const onlineHandler = useCallback(() => callback(true), [callback])
  const offlineHandler = useCallback(() => callback(false), [callback])

  useEventListenerEffect(window, 'online', onlineHandler)
  useEventListenerEffect(window, 'offline', offlineHandler)
}

export const useIsOnlineAtomUpdaterEffect = () => {
  const setIsOnline = useSetIsOnline()
  useIsOnlineEffect(setIsOnline)
}
