import { type AuditInterface } from 'src/shared/lib/api/types/audit'
import { type ReservationLink, type ReservationPayment } from './reservationApi'
import { type CustomerNoteInterface } from '../../customer/types/customer'
import { type PreferencesInterface } from '../../customer/types/preferences'
import { type PersistedLabelInterface } from '../../label/types/label'
import { type RestaurantInterface } from '../../restaurant/types/restaurant'
import { type RoomInterface } from '../../room/types/room'
import { type TableOccupancyInterface } from '../../table/types/table'

export interface GuestInterface {
  customerId: number | null | undefined
  firstName: string
  lastName: string
  companyName: string
  phoneNumber: string
  email: string
  comment: string | undefined
  languageCode: string
  preferences: PreferencesInterface
  birthdate: Date | null
  vip: boolean
  ban: boolean
  externalNotes: CustomerNoteInterface[]
  notes: CustomerNoteInterface[]
}

export enum ReservationStatusEnum {
  Pending = 'pending',
  Confirmed = 'accepted',
  Cancelled = 'cancelled',
  Expired = 'expired',
  NoShow = 'noShow',
}

export enum ReservationSourceEnum {
  Google = 'google',
  Self = 'self',
  Online = 'online',
}

export enum ReservationTypesEnum {
  External = 'external',
  Self = 'self',
  Serial = 'serial',
}

export enum ReservationDisplayModesEnum {
  Time = 'time',
  RoomNumber = 'roomNumber',
  Name = 'name',
  FirstName = 'firstName',
  Labels = 'labels',
}

export const WALK_IN_NAME = 'Walk-In'

export interface ReservationChoiceInterface {
  question: string
  answer: string
}

export enum ReservationCheckInStatusEnum {
  New = 'new',
  Pending = 'pending',
  CheckedIn = 'checkedIn',
  CheckedOut = 'checkedOut',
  Cancelled = 'cancelled',
  NoShow = 'noShow',
}

interface ReminderInterface {
  seatCount: number
  date: Date
}

export { type ReservationPayment } from './reservationApi'

export interface ReservationInterface {
  id: number
  number: number
  status: ReservationStatusEnum
  appeared: boolean
  seatCount: number
  occupancies: TableOccupancyInterface[]
  guestMessage: string
  restaurantNote: string
  choices: ReservationChoiceInterface[]
  source: ReservationSourceEnum
  guest: GuestInterface
  revenue: number
  dateRange: [Date, Date]
  serial: boolean
  audit: AuditInterface
  labels: PersistedLabelInterface['id'][]
  room: RoomInterface['id']
  restaurantId: RestaurantInterface['hash']
  serialId?: number
  sendFeedback: boolean
  feedbackId?: number
  links: ReservationLink[]
  sendReminder: boolean
  reminder?: ReminderInterface
  locked: boolean
  payment: ReservationPayment | null
  sendSms: boolean
  sendEmail: boolean
  cancellationDeadline: Date
  exactMode: boolean
}

export enum ReservationNotesEnum {
  GuestMessage = 'guestMessage',
  RestaurantNote = 'restaurantNote',
  Choices = 'choices',
}

export enum ReservationColumnsIdsEnum {
  Selection = 'select',
  Action = 'action',
  Type = 'type',
  ArrivalTime = 'arrival',
  Number = 'number',
  Guest = 'guestName',
  Tables = 'tables',
  Areas = 'areas',
  Rooms = 'rooms',
  Labels = 'labels',
  SeatCount = 'seatCount',
  Notes = 'notes',
  Info = 'info',
  CheckInManager = 'checkInManager',
  StartDate = 'startDate',
  Payment = 'payment',
}

export interface ReservationUpdateInterface
  extends Partial<Omit<ReservationInterface, 'guest' | 'id' | 'room'>> {
  guest?: Partial<ReservationInterface['guest']>
  id: ReservationInterface['id']
  room?: RoomInterface['id'] | null
}

export interface ReservationStatusChangeInterface {
  id: ReservationInterface['id']
}

export interface ReservationCreateInterface
  extends Pick<ReservationInterface, 'dateRange' | 'room'> {
  guest?: Partial<ReservationInterface['guest']>
}

export interface ExactModeChangedPayloadInterface {
  id: ReservationInterface['id']
  exactMode: boolean
}
