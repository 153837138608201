import type React from 'react'
import { formControlLabelClasses } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import Checkbox from './Checkbox'

const ReservationDrawerCheckbox = ({
  sx,
  ...props
}: React.ComponentProps<typeof Checkbox>) => (
  <Checkbox
    size="small"
    labelProps={{
      disableTypography: true,
      sx: mergeSx(
        {
          fontSize: 12,
          [`&.${formControlLabelClasses.disabled}`]: {
            color: `text.disabled`,
          },
        },
        sx,
      ),
    }}
    {...props}
  />
)

export default ReservationDrawerCheckbox
