import React from 'react'
import { type ButtonProps } from '@mui/material'

import { Chevron } from '../../icons/ChevronIcon'
import IconButton from '../IconButton'

export const orientationToRotation = {
  left: -270,
  right: -90,
  up: -180,
  down: 0,
}

interface ChevronButtonProps extends ButtonProps {
  orientation?: 'left' | 'right' | 'down' | 'up'
}

const ChevronButton = React.forwardRef<HTMLButtonElement, ChevronButtonProps>(
  ({ orientation = 'left', ...props }, fwdRef) => (
    <IconButton
      size="small"
      iconSize="medium"
      color="neutral"
      variant="text"
      ref={fwdRef}
      {...props}
    >
      <Chevron
        sx={{
          transform: `rotate(${orientationToRotation[orientation]}deg)`,
        }}
      />
    </IconButton>
  ),
)

export default ChevronButton
