import { useCallback, type ReactNode } from 'react'
import { Stack } from '@mui/material'

import { Navigate, useNavigate } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import useCurrentTeamMember from 'src/entities/team-member/hooks/useCurrentTeamMember'
import { FullScreenModalHeader } from 'src/shared/components/dialogs/FullScreenModal/components/FullScreenModalHeader'
import { useSettingsHelpers } from './hooks/useSettingsHelpers'
import ScreensTabs from './ScreensTabs'
import useAvailableTeamMembers from '../SwitchTeamMemberModal/hooks/useAvailableTeamMembers'

interface SettingsLayoutProps {
  restaurantId: number
  children: ReactNode
}

const SettingsLayout = ({ restaurantId, children }: SettingsLayoutProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onClose = useCallback(
    () =>
      navigate({
        to: '/restaurants/$restaurantId/reservations',
        params: { restaurantId },
      }),
    [navigate, restaurantId],
  )

  const hasTeamMembers = useAvailableTeamMembers().length
  const teamMember = useCurrentTeamMember()
  const { shouldRedirect } = useSettingsHelpers()

  if (hasTeamMembers && !teamMember) return null

  if ((!hasTeamMembers || teamMember) && shouldRedirect()) {
    return (
      <Navigate
        to="/restaurants/$restaurantId/reservations"
        params={{ restaurantId }}
      />
    )
  }

  return (
    <Stack width={1}>
      <FullScreenModalHeader
        title={t('settings.tab_names.settingsTitle')}
        onClose={onClose}
      />
      <Stack
        direction="column"
        sx={{
          px: 6,
          py: 3,
          pb: 0,
          overflow: 'hidden',
          flexGrow: 1,
        }}
      >
        <ScreensTabs restaurantId={restaurantId} />
        {children}
      </Stack>
    </Stack>
  )
}

export default SettingsLayout
