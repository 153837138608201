import React from 'react'
import {
  complement,
  compose,
  cond,
  filter,
  identical,
  juxt,
  of,
  without,
} from 'ramda'
import { Box, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useAreasQuery } from 'src/entities/area/queries/area'
import { type AreaInterface } from 'src/entities/area/types/area'
import { useSetFloorPlanMutation } from 'src/entities/floor-plan/queries/floorPlan'
import { useFurnitureQuery } from 'src/entities/floor-plan/queries/furniture'
import {
  assignUuid,
  isFurniture,
  isInArea,
  isTable,
  setArea,
  setTableName,
} from 'src/entities/floor-plan/services/floorPlanElement'
import useFeature from 'src/entities/info/hooks/useFeature'
import { useTablesQuery } from 'src/entities/table/queries/table'
import { newRectangularTable } from 'src/entities/table/types/tableApi'
import CreateButton from 'src/shared/components/buttons/CreateButton'
import EmptyView from 'src/shared/components/common/EmptyView'
import { replaceBy } from 'src/shared/lib/common/services/functional/functional'
import {
  type FloorPlanElementInterface,
  type FloorPlanTableInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'
import AreaTabs from './AreaTabs'
import TableSelection from './ElementSelection'
import FloorPlan from './FloorPlan'
import GridTableEditForm from './FloorPlanTableEditForm'
import FurnitureEditForm from './FurnitureEditForm'
import TabularTableEditForm from './TabularTableEditForm'
import TabularView from './TabularView'
import ViewTabs from './ViewTabs'
import { TOP_BAR_BG } from '../../Reservations/components/CounterTabs'
import {
  PRESENTATION_VIEWS,
  type PresentationView,
} from '../../Reservations/service/presentationView'

const upsertFloorplanElement =
  <T extends FloorPlanElementInterface>(newEl: T) =>
  (items: T[]) =>
    replaceBy(
      i => (newEl.id ? i.id === newEl.id : i.uuid === newEl.uuid),
      newEl,
      items,
    )

const Tables = () => {
  const { t } = useTranslation('', { keyPrefix: 'settings.tables' })

  const { data: areas } = useAreasQuery()
  const [selectedArea, setSelectedArea] = React.useState<
    AreaInterface | undefined
  >(areas[0] as AreaInterface)

  const { data: initialTables } = useTablesQuery()
  const { data: initialFurniture } = useFurnitureQuery()

  const [originalTables] = React.useState(initialTables)
  const [originalFurniture] = React.useState(initialFurniture)

  const [tables, setTables] = React.useState(originalTables)
  const [furniture, setFurniture] = React.useState(originalFurniture)

  const { mutate: saveFloorPlan } = useSetFloorPlanMutation()

  const tablesRef = React.useRef(tables)
  const furnitureRef = React.useRef(furniture)

  React.useEffect(() => {
    tablesRef.current = tables
    furnitureRef.current = furniture
  }, [tables, furniture])

  React.useEffect(
    () => () => {
      saveFloorPlan({
        tables: tablesRef.current,
        furniture: furnitureRef.current,
        originalTables,
        originalFurniture,
      })
    },
    [originalFurniture, originalTables, saveFloorPlan],
  )
  const [selectedElement, setSelectedElement] =
    React.useState<FloorPlanElementInterface>()

  const upsertElement = React.useCallback(
    (newEl: FloorPlanElementInterface) => {
      setSelectedElement(oldEl => {
        if ((!oldEl || isTable(oldEl)) && isTable(newEl)) {
          setTables(upsertFloorplanElement(newEl))
        }
        if ((!oldEl || isFurniture(oldEl)) && isFurniture(newEl)) {
          setFurniture(upsertFloorplanElement(newEl))
        }

        return newEl
      })
    },
    [],
  )

  const createHandler = React.useMemo(
    () =>
      compose(
        upsertElement,
        setArea(selectedArea),
        setTableName(t('unnamedTable')),
        assignUuid,
      ),
    [upsertElement, selectedArea, t],
  )

  const handleClose = React.useCallback(() => setSelectedElement(undefined), [])

  const deleteElement = React.useMemo(
    () =>
      juxt([
        cond([
          [isTable, compose(setTables, without, of(Array))],
          [isFurniture, compose(setFurniture, without, of(Array))],
        ]),
        handleClose,
      ]),
    [handleClose],
  )

  const elements = React.useMemo(() => {
    const allElements = [...furniture, ...tables]

    if (!selectedElement) return allElements

    return [
      ...allElements.filter(complement(identical(selectedElement))),
      selectedElement,
    ]
  }, [furniture, tables, selectedElement])

  const deleteHandler = React.useMemo(
    () => compose(handleClose, deleteElement),
    [deleteElement, handleClose],
  )

  const areaChangeHandler = React.useCallback(
    (area: AreaInterface) => {
      setSelectedArea(area)
      handleClose()
    },
    [setSelectedArea, handleClose],
  )

  const displayedElements = React.useMemo(
    () => filter(isInArea(selectedArea), elements),
    [elements, selectedArea],
  )

  const viewsChoice = [
    useFeature('viewTable') && PRESENTATION_VIEWS.tablesTable,
    useFeature('viewVisuell') && PRESENTATION_VIEWS.floorplan,
  ].filter(Boolean) as PresentationView[]

  const [view, setView] = React.useState(
    viewsChoice.includes(PRESENTATION_VIEWS.floorplan)
      ? PRESENTATION_VIEWS.floorplan
      : viewsChoice[0]!,
  )

  React.useEffect(() => {
    if (view === PRESENTATION_VIEWS.floorplan || isTable(selectedElement))
      return

    handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  const showTableGridForm =
    view === PRESENTATION_VIEWS.floorplan && isTable(selectedElement)

  const showFurnitureGridForm =
    view === PRESENTATION_VIEWS.floorplan && isFurniture(selectedElement)

  const showTableTabularForm =
    view === PRESENTATION_VIEWS.tablesTable && isTable(selectedElement)

  const showNewTableSelection =
    view === PRESENTATION_VIEWS.floorplan && !selectedElement

  const showNewTableButton =
    view === PRESENTATION_VIEWS.tablesTable && !selectedElement

  const tablesInArea = React.useMemo(
    () => tables.filter(isInArea(selectedArea)),
    [tables, selectedArea],
  )
  const isElementPending = false

  if (!selectedArea) return <EmptyView text={t('noAreasWarning')} />

  return (
    <Stack
      direction="row"
      sx={{
        flexGrow: 1,
        overflow: 'hidden',
        height: 1,
      }}
    >
      <Box
        sx={{
          width: 250,
          flexShrink: 0,
          p: 1,
          pt: 2,
          backgroundColor: TOP_BAR_BG,
        }}
      >
        {showNewTableSelection && (
          <TableSelection
            isElementPending={isElementPending}
            onCreateNewTable={createHandler}
          />
        )}
        {showNewTableButton && (
          <CreateButton
            onClick={() => createHandler(newRectangularTable())}
            fullWidth
          >
            {t('addTable')}
          </CreateButton>
        )}
        {showTableTabularForm && (
          <TabularTableEditForm
            onCancel={handleClose}
            value={selectedElement}
            onChange={upsertElement}
            onDelete={deleteHandler}
          />
        )}
        {showTableGridForm && (
          <GridTableEditForm
            onCancel={handleClose}
            value={selectedElement}
            onChange={upsertElement}
            onDelete={deleteHandler}
          />
        )}
        {showFurnitureGridForm && (
          <FurnitureEditForm
            onCancel={handleClose}
            value={selectedElement}
            onChange={upsertElement}
            onDelete={deleteHandler}
            furniture={originalFurniture}
          />
        )}
      </Box>
      <Stack
        direction="column"
        sx={{
          flexGrow: 1,
          overflow: 'hidden',
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            borderBottom: 1,
            borderColor: 'border',
            backgroundColor: TOP_BAR_BG,
          }}
        >
          <AreaTabs
            areas={areas}
            selectedArea={selectedArea}
            setSelectedArea={areaChangeHandler}
          />
          {viewsChoice.length > 1 && (
            <ViewTabs views={viewsChoice} view={view} onViewChange={setView} />
          )}
        </Stack>
        {view === PRESENTATION_VIEWS.floorplan && (
          <FloorPlan
            elements={displayedElements}
            selectedElement={selectedElement}
            onSelect={setSelectedElement}
            onChange={upsertElement}
          />
        )}
        {view === PRESENTATION_VIEWS.tablesTable && (
          <TabularView
            tables={tablesInArea}
            onSelect={setSelectedElement}
            selectedTable={
              selectedElement as FloorPlanTableInterface | undefined
            }
          />
        )}
      </Stack>
    </Stack>
  )
}

export default Tables
