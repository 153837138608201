import React from 'react'
import { juxt } from 'ramda'
import { Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useReservationCustomerQuery } from 'src/entities/customer/queries/customer'
import { type CustomerInterface } from 'src/entities/customer/types/customer'
import ReservationDrawerSection from 'src/shared/components/common/ReservationDrawerSection'
import { FullScreenModal } from 'src/shared/components/dialogs/FullScreenModal/FullScreenModal'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { type SelectedReservation } from 'src/shared/lib/context/state/atoms/baseSelectedReservation'
import CustomerIndicators from 'src/widgets/Reservation/Table/CustomerIndicators'
import { NewGuestInputs } from './components/NewGuestInputs'
import GuestButton from './GuestButton/GuestButton'

const CustomerChoice = React.lazy(
  () => import('../../CustomerChoice/CustomerChoice'),
)

interface GuestProps {
  reservation: SelectedReservation
  onCustomerChange: (customer: CustomerInterface | undefined) => void
  onPreviewOpen: (customerId: CustomerInterface['id']) => void
  onEditCustomer: (id: CustomerInterface) => void
  onCreateNewCustomer: (initText: string) => void
}

const Guest = ({
  reservation,
  onCustomerChange,
  onPreviewOpen,
  onEditCustomer,
  onCreateNewCustomer,
}: GuestProps) => {
  const { data: customer } = useReservationCustomerQuery(reservation)
  const { t } = useTranslation()

  const guest = React.useMemo(
    () => ({
      ...reservation.guest,
      birthdate: customer.birthdate,
    }),
    [customer, reservation],
  )

  const { handleOpen, handleClose, open, hasBeenOpened } = useDialog()

  if (reservation.newCustomer) {
    return <NewGuestInputs />
  }

  return (
    <>
      <ReservationDrawerSection title={t('angular.guest')}>
        <Stack direction="column" spacing={2}>
          <GuestButton
            onChoiceOpen={handleOpen}
            onCustomerChange={onCustomerChange}
            onPreviewOpen={onPreviewOpen}
            guest={guest}
          />
          <CustomerIndicators
            customer={guest}
            date={reservation.dateRange[0]}
          />
        </Stack>
      </ReservationDrawerSection>
      <FullScreenModal
        isOpen={open}
        title={t('angular.crm')}
        onClose={handleClose}
      >
        {hasBeenOpened && (
          <CustomerChoice
            onChooseCustomer={juxt([onCustomerChange, handleClose])}
            onCreateNewCustomer={juxt([onCreateNewCustomer, handleClose])}
            onEditCustomer={juxt([onEditCustomer, handleClose])}
          />
        )}
      </FullScreenModal>
    </>
  )
}

export default Guest
