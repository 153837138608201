import { map } from 'ramda'

import { type CustomerInterface } from 'src/entities/customer/types/customer'
import {
  appendCustomer,
  getCustomerId,
} from 'src/entities/reservation/services/reservation'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'

export const combineWithCustomers = (
  reservations: ReservationInterface[],
  customers: CustomerInterface[],
) => {
  const customersById = new Map(
    customers.map(customer => [customer.id, customer]),
  )

  return map((reservation: ReservationInterface) => {
    const customerId = getCustomerId(reservation)

    if (!customerId) return reservation

    const customer = customersById.get(customerId)

    if (!customer) return reservation

    return appendCustomer(customer)(reservation)
  }, reservations)
}
