import { z } from 'zod'

import { convertKeysToCamelCase } from 'src/shared/lib/api/services/api'
import { convertFromTimezone } from 'src/shared/lib/range/services/date'

export const availabilitySchema = z
  .preprocess(
    convertKeysToCamelCase,
    z.object({
      date: z.string(),
      availableCovers: z.number(),
      totalCovers: z.number(),
      availableShiftCovers: z.number(),
      takenShiftCovers: z.number(),
      totalShiftCovers: z.number(),
    }),
  )
  .transform(rawAvailability => ({
    time: convertFromTimezone(new Date(rawAvailability.date)),
    remainingCapacity: rawAvailability.availableCovers,
    totalCapacity: rawAvailability.totalCovers,
    remainingShiftCapacity: rawAvailability.availableShiftCovers,
    takenShiftCapacity: rawAvailability.takenShiftCovers,
    totalShiftCapacity: rawAvailability.totalShiftCovers,
  }))

export type AvailabilityInterface = z.infer<typeof availabilitySchema>
