import {
  type DefaultError,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { useScheduleQuery } from '../../schedule/queries/schedule'
import {
  type PaymentPlan,
  type Schedule,
} from '../../schedule/types/scheduleApi'

const options = { select: s => s.paymentPlans } satisfies Partial<
  UseSuspenseQueryOptions<Schedule, DefaultError, PaymentPlan[]>
>

export const usePaymentPlanQuery = () => useScheduleQuery(options)
