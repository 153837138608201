import React from 'react'
import { append } from 'ramda'

import { useTranslation } from 'react-i18next'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { getCheckInStatus } from 'src/entities/reservation/services/checkInStatus'
import { sumSeatCount } from 'src/entities/reservation/services/occupancy'
import {
  ReservationCheckInStatusEnum,
  type ReservationInterface,
} from 'src/entities/reservation/types/reservation'
import { useServiceTimesQuery } from 'src/entities/service-time/queries/serviceTime'
import { mapMap } from 'src/shared/lib/common/services/functional/functional'
import {
  useSelectedServiceTime,
  useSetSelectedServiceTime,
} from 'src/shared/lib/context/state/atoms/selectedServiceTime'
import CounterTabs from '../CounterTabs'

interface ServiceTimePickerProps {
  reservations: Map<
    ServiceTimeInterface['id'] | null,
    ReservationInterface[]
  > | null
  selectedColor: string
}

const ServiceTimePicker = ({
  reservations,
  selectedColor,
}: ServiceTimePickerProps) => {
  const { t } = useTranslation()

  const { data: serviceTimes } = useServiceTimesQuery()

  const paxByServiceTime = React.useMemo(
    () =>
      reservations
        ? mapMap((serviceTimeReservations: ReservationInterface[]) =>
            serviceTimeReservations
              .filter(r =>
                [
                  ReservationCheckInStatusEnum.New,
                  ReservationCheckInStatusEnum.CheckedIn,
                  ReservationCheckInStatusEnum.Pending,
                ].includes(getCheckInStatus(r)),
              )
              .reduce(sumSeatCount, 0),
          )(reservations)
        : null,
    [reservations],
  )

  const setServiceTime = useSetSelectedServiceTime()
  const selectedServiceTime = useSelectedServiceTime()

  const items = React.useMemo(() => append(null, serviceTimes), [serviceTimes])
  const value =
    serviceTimes?.find(
      serviceTime => serviceTime.id === selectedServiceTime?.id,
    ) ?? null

  return (
    <CounterTabs
      value={value}
      onChange={setServiceTime}
      variant="scrollable"
      renderItem={serviceTime => serviceTime?.name ?? t('angular.all')}
      getCount={serviceTime =>
        paxByServiceTime
          ? (paxByServiceTime.get(serviceTime?.id ?? null) ?? 0)
          : undefined
      }
      getKey={serviceTime => serviceTime?.id ?? 'all'}
      items={items}
      selectedColor={selectedColor}
    />
  )
}

export default ServiceTimePicker
