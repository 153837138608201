import { useRef } from 'react'

import { createFileRoute } from '@tanstack/react-router'

import ScheduleRouter from 'src/pages/Schedule/ScheduleRouter'
import { ConfigScroll } from './-ConfigScroll'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/config/schedule',
)({
  component: RouteComponent,
})

function RouteComponent() {
  const ref = useRef<HTMLDivElement | null>(null)

  return (
    <ConfigScroll ref={ref}>
      <ScheduleRouter onRouteChange={() => ref.current?.scrollTo(0, 0)} />
    </ConfigScroll>
  )
}
