import { andThen, composeWith, map, prop } from 'ramda'

import { type ApiClient } from 'src/shared/lib/api/services/api'
import { type ActivityLogEntryInterface } from '../types/activityLog'
import { activityLogFactory } from '../types/activityLogApi'

export interface GetActivityLogOptions {
  reservationId?: number
  limit?: number
}

export const getActivityLog =
  (httpClient: ApiClient) =>
  ({
    reservationId,
    limit,
  }: GetActivityLogOptions): ActivityLogEntryInterface[] =>
    composeWith(andThen)([
      map(activityLogFactory),
      prop('activities'),
      httpClient,
    ])({
      url: 'getActivityLog',
      json: {
        reservation_id: reservationId,
        limit,
      },
    })
