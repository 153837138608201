import { useTranslation } from 'react-i18next'

import { useDeleteReservationQuestionMutation } from 'src/entities/schedule/queries/schedule'
import {
  type ReservationQuestion,
  type Schedule,
} from 'src/entities/schedule/types/scheduleApi'
import { getValidityScopeProperty } from './service'
import { useDefaultMutationHandlers } from '../../ShiftConfiguration/hooks/useDefaultMutationHandlers'
import { CreateScheduleButton } from '../components'
import { ScheduleCard, ScheduleCardWrapper } from '../components/ScheduleCard'
import { useExceptionDateRangeFormatter } from '../service'

interface ReservationQuestionsSectionProps {
  schedule: Schedule
  onEditQuestion: (question?: ReservationQuestion) => void
}

export const ReservationQuestionsSection = ({
  schedule,
  onEditQuestion,
}: ReservationQuestionsSectionProps) => {
  const { t } = useTranslation()

  const { mutateAsync: deleteQuestion } = useDeleteReservationQuestionMutation()
  const { onError } = useDefaultMutationHandlers()

  const formatDateRange = useExceptionDateRangeFormatter()

  return (
    <>
      <ScheduleCardWrapper>
        {schedule.reservationQuestions.map(q => (
          <ScheduleCard
            key={q.id}
            onEdit={() => onEditQuestion(q)}
            onDelete={() => deleteQuestion(q, { onError })}
            title={q.name}
            isInactive={!q.active}
            properties={[
              q.effectivePeriod
                ? formatDateRange([
                    q.effectivePeriod.start,
                    q.effectivePeriod.end,
                  ])
                : t('schedule.common.every_day', 'Every day'),
              getValidityScopeProperty({ scope: q.scope, schedule, t }),
            ]}
          />
        ))}
      </ScheduleCardWrapper>
      <CreateScheduleButton onClick={() => onEditQuestion()}>
        {t(
          'schedule.overview.reservation_question.create',
          'Create Widget Question',
        )}
      </CreateScheduleButton>
    </>
  )
}
