import React from 'react'

import type { CommunicationTemplate } from 'src/entities/communication-template/types/communicationTemplates'
import { useSendMessageMutation } from 'src/entities/reservation/queries/reservationsMutations'
import type { ReservationInterface } from 'src/entities/reservation/types/reservation'
import {
  isAbandoned,
  resumable,
  useResumable,
  type Step,
} from 'src/shared/lib/common/services/resumable/resumable'

interface UseSendMessage {
  onTemplateChoice: () => void
  showTemplateChoice: boolean
}

interface SendMessageOptions {
  smsTemplate?: CommunicationTemplate | undefined
  emailTemplate?: CommunicationTemplate | undefined
}

interface SendMessageDeps {
  reservation: ReservationInterface
}

export const useSendMessage = ({
  onTemplateChoice,
  showTemplateChoice,
}: UseSendMessage) => {
  const { mutateAsync: sendMessage } = useSendMessageMutation()

  const steps: Step<SendMessageOptions, SendMessageDeps>[] = React.useMemo(
    () => [
      [
        o => showTemplateChoice && (!o.emailTemplate || !o.smsTemplate),
        onTemplateChoice,
      ],
    ],
    [showTemplateChoice, onTemplateChoice],
  )

  const { init, resume, abandon } = useResumable(
    React.useMemo(() => resumable(steps), [steps]),
  )

  const initHandler = React.useCallback(
    async (reservation: ReservationInterface) => {
      const options = await init({ reservation })

      if (isAbandoned(options)) return

      await sendMessage({
        reservation,
        emailTemplate: options.emailTemplate,
        smsTemplate: options.smsTemplate,
      })
    },
    [init, sendMessage],
  )

  return { init: initHandler, resume, abandon }
}
