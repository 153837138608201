import { Chip } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  type CustomerInterface,
  type FallbackCustomer,
} from 'src/entities/customer/types/customer'
import StarIcon from 'src/shared/components/icons/StarIcon'

const Vip = ({
  customer,
}: {
  customer: CustomerInterface | FallbackCustomer
}) => {
  const { t } = useTranslation()

  if (!customer.vip) return null

  return (
    <Chip
      color="light"
      label={t('customer.vip')}
      icon={<StarIcon size="small" variant="filled" />}
    />
  )
}

export default Vip
