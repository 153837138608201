import { groupBy, includes, keys, mapObjIndexed, pickBy, sort } from 'ramda'

import {
  PRESENTATION_VIEWS,
  type PresentationView,
} from 'src/pages/Reservations/service/presentationView'
import { priorityComparator } from 'src/shared/lib/common/services/priorityComparator'
import { type SettingsKeys } from '../../config/types/configApi'
import {
  NotificationEnum,
  SectionEnum,
  type SectionCollectionType,
  type Setting,
} from '../types/setting'
import { fromEnum } from '../types/settingsApi'

const SettingsSections: { [key in SectionEnum]: SettingsKeys[] } = {
  [SectionEnum.CreateNotifications]: [
    'newReservationGuestEmail',
    'newReservationGuestSms',
    'newReservationGastroEmail',
  ],
  [SectionEnum.MutateNotifications]: [
    'mutationGuestEmail',
    'mutationGuestSms',
    'mutationGastroEmail',
  ],
  [SectionEnum.CancelNotifications]: [
    'cancelGuestEmail',
    'cancelGuestSms',
    'cancelGastroEmail',
  ],
  [SectionEnum.NoShowNotifications]: ['noshowGuestEmail', 'noshowGuestSms'],
  [SectionEnum.MoveNotifications]: [
    'changeRestaurantGuestEmail',
    'changeRestaurantGuestSms',
    'changeRestaurantGastroEmail',
  ],
  [SectionEnum.OtherNotifications]: ['reminderEmail', 'feedbackEmail'],
  [SectionEnum.Others]: ['autoLogout', 'slotPicker'],
  [SectionEnum.DefaultView]: [
    'defaultListPlan',
    'defaultVisuellPlan',
    'defaultListView',
    'defaultTimeline',
  ],
}

export const Labels: { [key in SettingsKeys]?: NotificationEnum } = {
  newReservationGuestEmail: NotificationEnum.GuestEmail,
  newReservationGuestSms: NotificationEnum.GuestSms,
  newReservationGastroEmail: NotificationEnum.RestaurantEmail,
  mutationGuestEmail: NotificationEnum.GuestEmail,
  mutationGuestSms: NotificationEnum.GuestSms,
  mutationGastroEmail: NotificationEnum.RestaurantEmail,
  cancelGuestEmail: NotificationEnum.GuestEmail,
  cancelGuestSms: NotificationEnum.GuestSms,
  cancelGastroEmail: NotificationEnum.RestaurantEmail,
  noshowGuestEmail: NotificationEnum.GuestEmail,
  noshowGuestSms: NotificationEnum.GuestSms,
  changeRestaurantGuestEmail: NotificationEnum.GuestEmail,
  changeRestaurantGuestSms: NotificationEnum.GuestSms,
  changeRestaurantGastroEmail: NotificationEnum.RestaurantEmail,
}

const getSettingsEnum = (setting: Setting): SettingsKeys => setting.name

const getItemSection = (setting: Setting): SectionEnum => {
  const includesSetting = includes(getSettingsEnum(setting))
  const section = pickBy<SectionCollectionType, SectionCollectionType>(
    includesSetting,
    SettingsSections,
  )

  return keys(section)[0]!
}
export const groupBySection = groupBy(getItemSection) as (
  list: readonly Setting[],
) => Record<SectionEnum, Setting[]>

const sectionOrderComparator = (sectionName: SectionEnum) =>
  priorityComparator(SettingsSections[sectionName] || [], getSettingsEnum)

const sortSection = (section: Setting[], sectionName: SectionEnum): Setting[] =>
  sort(sectionOrderComparator(sectionName))(section)

export const sortSections = mapObjIndexed(sortSection)

export const getItemLabel = (setting: Setting): string =>
  Labels[getSettingsEnum(setting)] ?? setting.name

export const setItemValue = (setting: Setting, value: boolean): Setting => ({
  ...setting,
  value,
})

const overwriteItem = (settings: Setting[], item: Setting) => [
  ...settings.filter(setting => item.name !== setting.name),
  item,
]

const getDisabledSection = (sectionName: SectionEnum): Setting[] =>
  SettingsSections[sectionName].map(settingEnum => fromEnum(settingEnum, false))

const SETTING_TO_VIEW_MAP: { [key in SettingsKeys]?: PresentationView } = {
  defaultListPlan: PRESENTATION_VIEWS.tablesTable,
  defaultVisuellPlan: PRESENTATION_VIEWS.floorplan,
  defaultListView: PRESENTATION_VIEWS.reservationsTable,
  defaultTimeline: PRESENTATION_VIEWS.timeline,
}

export const getDefaultView = (settings: Setting[]): PresentationView => {
  const viewSettingsNames = Object.keys(SETTING_TO_VIEW_MAP)
  const viewSettings = settings.filter(setting =>
    viewSettingsNames.includes(setting.name),
  )

  const defaultViewSetting = viewSettings.find(setting => setting.value)

  return defaultViewSetting
    ? SETTING_TO_VIEW_MAP[defaultViewSetting.name]!
    : PRESENTATION_VIEWS.timeline
}

export const updateSetting = (settings: Setting[], setting: Setting) => {
  if (
    SettingsSections[SectionEnum.DefaultView].includes(
      getSettingsEnum(setting),
    ) &&
    setting.value
  ) {
    const disabledViews = getDisabledSection(SectionEnum.DefaultView)
    return overwriteItem(disabledViews.reduce(overwriteItem, settings), setting)
  }

  return overwriteItem(settings, setting)
}
