import { useTranslation } from 'react-i18next'

import Toggle from 'src/shared/components/form/inputs/Toggle'
import { useBaseFormContext } from '../../../../hooks/form'

const MessageToggle = () => {
  const { t } = useTranslation()

  const { register } = useBaseFormContext()

  return (
    <Toggle
      label={t(
        'schedule.shifts.shift_message_section.message_enabled_toggle_label',
        'Set shift message',
      )}
      {...register('shiftMessage.isActive')}
    />
  )
}

export default MessageToggle
