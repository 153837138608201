import { useCallback, useEffect, useState } from 'react'

import { usePaymentProviderQuery } from 'src/entities/payment/queries/paymentProvider'
import { useScheduleQuery } from 'src/entities/schedule/queries/schedule'
import ScheduleOverview, { type RouteState } from './index'
import { ScheduleActivityLog } from './ScheduleActivityLog/ScheduleActivityLog'
import WeekOverview from './Timeline/WeekOverview'
import { BookingLockForm } from '../ShiftConfiguration/BookingLockForm/BookingLockForm'
import { ClosureForm } from '../ShiftConfiguration/ClosureForm/ClosureForm'
import PaymentPlanForm from '../ShiftConfiguration/PaymentPlanForm'
import { PhoneConfirmationsForm } from '../ShiftConfiguration/PhoneConfirmationsForm/PhoneConfirmationsForm'
import { ReservationQuestionForm } from '../ShiftConfiguration/ReservationQuestionForm/ReservationQuestionForm'
import EventForm from '../ShiftConfiguration/ShiftForm/EventForm'
import ExceptionForm from '../ShiftConfiguration/ShiftForm/ExceptionForm'
import ShiftForm from '../ShiftConfiguration/ShiftForm/ShiftForm'
import ShiftTemplateForm from '../ShiftConfiguration/ShiftTemplateForm/ShiftTemplateForm'

interface ScheduleRouterProps {
  onRouteChange?: (route: RouteState['route']) => void
}

const ScheduleRouter = ({ onRouteChange }: ScheduleRouterProps) => {
  const [routeState, setRouteState] = useState<RouteState>({
    route: 'overview',
  })

  const { data: schedule } = useScheduleQuery()
  const { data: paymentProvider } = usePaymentProviderQuery()

  const onNavigateBack = useCallback(
    () => setRouteState({ route: 'overview' }),
    [],
  )

  useEffect(
    () => onRouteChange?.(routeState.route),
    [onRouteChange, routeState.route],
  )

  switch (routeState.route) {
    case 'default-settings':
      return <ShiftTemplateForm onNavigateBack={onNavigateBack} />
    case 'shift':
      return (
        <ShiftForm onNavigateBack={onNavigateBack} shift={routeState.shift} />
      )
    case 'shift-exception':
      return (
        <ExceptionForm
          onNavigateBack={onNavigateBack}
          shift={routeState.shift}
          exception={routeState.exception}
        />
      )
    case 'event':
      return (
        <EventForm onNavigateBack={onNavigateBack} event={routeState.event} />
      )
    case 'booking-lock':
      return (
        <BookingLockForm
          onNavigateBack={onNavigateBack}
          schedule={schedule}
          lock={routeState.lock}
        />
      )
    case 'closure':
      return (
        <ClosureForm
          onNavigateBack={onNavigateBack}
          schedule={schedule}
          closure={routeState.closure}
        />
      )
    case 'overview':
      return (
        <ScheduleOverview
          setRouteState={setRouteState}
          schedule={schedule}
          currency={paymentProvider?.currency ?? ''}
        />
      )
    case 'payment-plan':
      return (
        <PaymentPlanForm
          onNavigateBack={onNavigateBack}
          paymentPlan={routeState.paymentPlan}
        />
      )
    case 'phone-confirmations':
    case 'phone-confirmations-exception':
      return (
        <PhoneConfirmationsForm
          onNavigateBack={onNavigateBack}
          phoneConfirmations={
            routeState.route === 'phone-confirmations-exception'
              ? routeState.phoneConfirmations
              : schedule.phoneAcceptanceTimes
          }
          isException={routeState.route === 'phone-confirmations-exception'}
        />
      )
    case 'reservation-question':
      return (
        <ReservationQuestionForm
          onNavigateBack={onNavigateBack}
          reservationQuestion={routeState.reservationQuestion}
          schedule={schedule}
        />
      )
    case 'week-overview':
      return <WeekOverview onNavigateBack={onNavigateBack} />
    case 'activity-log':
      return <ScheduleActivityLog onNavigateBack={onNavigateBack} />
    default:
      routeState satisfies never
      return null
  }
}

export default ScheduleRouter
