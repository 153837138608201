import { Box, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  type NoPaymentPlan,
  type PaymentPlan,
} from 'src/entities/schedule/types/scheduleApi'
import FirstTimeVisitorIcon from 'src/shared/components/icons/FirstTimeVisitorIcon'
import { type IconProps } from 'src/shared/components/icons/IconContainer'
import StarIcon from 'src/shared/components/icons/StarIcon'
import { useHourMinuteDurationFormatter } from 'src/shared/lib/range/services/hourMinuteDuration'
import { useCurrencyFormatter } from 'src/shared/lib/string/hooks/useCurrencyFormatter'

interface PaymentPlanItemProps {
  currency: string
  plan: PaymentPlan | NoPaymentPlan
  onPlanChange: (newPlan: PaymentPlan | NoPaymentPlan) => void
}

const Line = ({ label, value }: { label: string; value: string }) => (
  <>
    <Typography justifySelf="right" textAlign="right">
      {label}:
    </Typography>
    <Typography fontWeight="bold" justifySelf="left" textAlign="left">
      {value}
    </Typography>
  </>
)

const IconLine = ({
  icon: Icon,
  value,
}: {
  icon: React.FC<IconProps>
  value: string
}) => (
  <Stack
    direction="row"
    gap={1}
    justifyContent="center"
    alignItems="center"
    lineHeight={1}
  >
    <Icon size="small" />
    <Typography>{value}</Typography>
  </Stack>
)

export const PaymentPlanItem = ({
  currency,
  plan,
  onPlanChange,
}: PaymentPlanItemProps) => {
  const isNoPlan = plan.id === null

  const { t } = useTranslation()

  const formatCurrency = useCurrencyFormatter()
  const formatDuration = useHourMinuteDurationFormatter()

  if (isNoPlan)
    return (
      <Stack
        sx={{ textAlign: 'center', cursor: 'pointer', p: 1 }}
        onClick={() => onPlanChange(plan)}
      >
        <Typography variant="labelRegular" sx={{ cursor: 'pointer' }}>
          {t('payment_plan.no_plan', { defaultValue: 'No plan' })}
        </Typography>
      </Stack>
    )

  const {
    name,
    amountPerPerson,
    excludeVips: excludeVipCustomers,
    firstTimeVisitorsOnly: firstTimeGuestOnly,
    linkTtl,
    type,
  } = plan

  return (
    <Stack
      sx={{ textAlign: 'center', cursor: 'pointer', p: 1 }}
      onClick={() => onPlanChange(plan)}
    >
      <Typography variant="labelRegular" sx={{ cursor: 'pointer' }}>
        {name}
      </Typography>
      <Stack gap={0.5}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: 1,
          }}
        >
          <Line
            label={t('payment_plan.type_label', 'Type')}
            value={t(`payment_plan.type.${type}.name`, type)}
          />
          <Line
            label={t('payment_plan.price_per_person_label', 'Price per person')}
            value={t('payment_plan.price_per_person_amount', {
              price: formatCurrency(currency)(amountPerPerson),
              defaultValue: '{{price}}/person',
            })}
          />
          <Line
            label={t('link_expire_amount_label', 'Link expire time')}
            value={formatDuration({ duration: linkTtl })}
          />
        </Box>
        {firstTimeGuestOnly && (
          <IconLine
            icon={FirstTimeVisitorIcon}
            value={t(
              'payment_plan.first_time_visitor_only',
              'First time visitor only',
            )}
          />
        )}
        {excludeVipCustomers && (
          <IconLine
            icon={StarIcon}
            value={t('payment_plan.exclude_vips', 'Exclude VIPs')}
          />
        )}
      </Stack>
    </Stack>
  )
}
