import { useCallback, useDeferredValue, useEffect } from 'react'
import type { SxProps } from '@mui/material'

import {
  SlotStatus,
  type SlotInterface,
} from 'src/entities/availability/types/availability'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useRoomShiftsQuery } from 'src/entities/shift-instance/queries/shift'
import { createSlot } from './service/slotFactory'
import SlotChoices from './SlotChoices'
import { useAvailabilities } from './useAvailabilities'

const handleSlotStatus =
  <T, U, V>(notInShift: T, isUnavailable: U, defaultValue: V) =>
  (status: SlotStatus) => {
    if (status === SlotStatus.NotInShift) return notInShift
    if (status === SlotStatus.Unavailable) return isUnavailable
    return defaultValue
  }

export const ButtonStyles = {
  white: { backgroundColor: 'white', ':hover': { backgroundColor: 'white' } },
  red: { backgroundColor: '#f7d8d9', ':hover': { backgroundColor: '#f7d8d9' } },
  grey: {
    backgroundColor: '#dddddd',
    ':hover': { backgroundColor: '#dddddd' },
  },
} satisfies Record<string, SxProps>

interface SlotPickerProps {
  selectedSlot: Date
  currentDate: Date
  selectedReservation: ReservationInterface
  initialReservation: ReservationInterface
  onSlotStatusChange: (status: SlotStatus) => void
  onButtonStyleChange: (style: SxProps) => void
  onClose: () => void
  onReservationTimeChange: (time: Date) => void
  disabled: boolean
}

export const SlotPicker = ({
  selectedSlot,
  currentDate,
  selectedReservation: { room: nonDeferredRoom, seatCount },
  initialReservation,
  onSlotStatusChange,
  onButtonStyleChange,
  onClose,
  onReservationTimeChange,
  disabled,
}: SlotPickerProps) => {
  const room = useDeferredValue(nonDeferredRoom)

  const { data: shifts } = useRoomShiftsQuery(currentDate, room)

  const notDeferredAvailabilities = useAvailabilities(
    currentDate,
    room,
    initialReservation,
  )

  const availabilities = useDeferredValue(notDeferredAvailabilities)

  useEffect(() => {
    const { status } = createSlot(availabilities, seatCount)(selectedSlot)

    onSlotStatusChange(status)

    onButtonStyleChange(
      handleSlotStatus(
        ButtonStyles.grey,
        ButtonStyles.red,
        ButtonStyles.white,
      )(status),
    )
  }, [
    availabilities,
    onButtonStyleChange,
    onSlotStatusChange,
    seatCount,
    selectedSlot,
  ])

  const handleSlotChange = useCallback(
    ({ time }: SlotInterface) => {
      onClose()
      onReservationTimeChange(time)
    },
    [onClose, onReservationTimeChange],
  )

  return (
    <SlotChoices
      selectedSlotTime={selectedSlot}
      onSlotChange={handleSlotChange}
      shifts={shifts}
      availabilities={availabilities}
      seatCount={seatCount}
      disabled={disabled}
    />
  )
}
