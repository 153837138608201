import React, { useImperativeHandle } from 'react'
import { Typography, type SxProps, type Theme } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import ButtonAdornment from '../../../buttons/ButtonAdornment'
import { type IconProps } from '../../../icons/IconContainer'
import MinusIcon from '../../../icons/MinusIcon'
import PlusIcon from '../../../icons/PlusIcon'
import { type FormTextInputProps } from '../FormTextInput'
import NumericInput, {
  type NumericInputElement,
} from '../NumericInput/NumericInput'

export type StepInputProps = {
  value?: number
  unit?: React.ReactNode
  onChange: (newValue: number) => void
  step?: number
  minValue?: number
  maxValue?: number
  readOnly?: boolean
  PrevIcon?: React.FC<IconProps>
  NextIcon?: React.FC<IconProps>
  onClick?: () => void
  inputSx?: SxProps<Theme>
} & Omit<FormTextInputProps, 'onChange' | 'value' | 'onClick' | 'ref'>

const StepInput = React.forwardRef<NumericInputElement, StepInputProps>(
  (
    {
      value = 0,
      unit,
      onChange,
      size = 'medium',
      disabled,
      step = 1,
      minValue = 0,
      maxValue = Number.MAX_SAFE_INTEGER,
      readOnly = false,
      PrevIcon = MinusIcon,
      NextIcon = PlusIcon,
      onClick,
      inputSx,
      fullWidth = false,
      sx,
      ...props
    },
    ref,
  ) => {
    const readOnlyStyle = {
      ...(readOnly && {
        cursor: 'pointer',
      }),
    }

    const innerRef = React.useRef<NumericInputElement>(null)
    useImperativeHandle(ref, () => innerRef.current!, [])

    return (
      <NumericInput
        ref={innerRef}
        value={value}
        unit={unit}
        onChange={onChange}
        minValue={minValue}
        maxValue={maxValue}
        fullWidth={fullWidth}
        variant="outlined"
        disabled={disabled}
        step={step}
        textAlign="center"
        size={size}
        sx={mergeSx({ input: { px: 0 } }, sx)}
        slotProps={{
          input: {
            readOnly,
            sx: mergeSx(readOnlyStyle, inputSx),
            onClick,
            startAdornment: (
              <ButtonAdornment
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  innerRef?.current?.dec()
                }}
                position="start"
                disabled={disabled}
                size={size}
                sx={{ m: 0, flex: 1, ...(unit && { height: 0 }) }}
              >
                <PrevIcon size={size} />
              </ButtonAdornment>
            ),
            endAdornment: (
              <>
                {unit && (
                  <Typography sx={{ pointerEvents: 'none' }}>{unit}</Typography>
                )}
                <ButtonAdornment
                  onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                    innerRef?.current?.inc()
                  }}
                  position="end"
                  disabled={disabled}
                  size={size}
                  sx={{
                    m: 0,
                    flex: 1,
                    justifyContent: 'flex-end',
                    ...(unit && { height: 0 }),
                  }}
                >
                  <NextIcon size={size} />
                </ButtonAdornment>
              </>
            ),
          },
          htmlInput: { step, sx: readOnlyStyle },
        }}
        {...props}
      />
    )
  },
)

export default StepInput
