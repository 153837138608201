import { Box } from '@mui/material'

import { type Room, type Shift } from 'src/entities/schedule/types/scheduleApi'
import ShiftHeader from './components/ShiftHeader/ShiftHeader'
import ShiftItem from './components/ShiftItem/ShiftItem'
import type { TimelineShift } from '../../../../timelineShift'
import GridRow from '../GridRow/GridRow'

interface ShiftGridProps {
  columns: number
  gridStart: Date
  shift: TimelineShift
  shifts: Shift[]
  rooms: Room[]
}

const ShiftRow = ({
  columns,
  gridStart,
  shift,
  shifts,
  rooms,
}: ShiftGridProps) => (
  <Box sx={{ borderBottom: '1px solid', borderColor: 'border' }}>
    <GridRow
      columns={columns}
      header={<ShiftHeader shift={shift} shifts={shifts} rooms={rooms} />}
    >
      {Array.from({ length: Math.ceil(columns / 4) }, (_, i) => (
        <Box
          key={i}
          sx={{
            backgroundColor: 'grey.25',
            borderLeft: '1px solid',
            borderColor: 'border',
            gridColumn: `${i * 4 + 1} / span 4`,
            height: 1,
            gridRow: 1,
          }}
        />
      ))}
      <ShiftItem shift={shift} gridStart={gridStart} />
    </GridRow>
  </Box>
)

export default ShiftRow
