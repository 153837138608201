import { compose, type Path } from 'ramda'

import {
  authFailureHandler,
  refreshToken,
} from 'src/entities/auth/services/authentication'
import { getToken } from 'src/entities/auth/services/token'
import { rawApiClient, type ApiClient } from './api'
import { getApiUrl } from './apiUrl'
import { addProp } from '../../common/services/helpers/helpers'
import {
  type ApiRequest,
  type ApiResponse,
  type Page,
  type TokenRefreshError,
} from '../types/api'

const addApiProp = <T>(path: Path) => addProp<T, ApiRequest, ApiResponse>(path)

const addLimit = addApiProp<number>(['json', 'limit'])
const addSearchBefore = addApiProp<string>(['json', 'search_before'])
const addSearchAfter = addApiProp<string>(['json', 'search_after'])
const addCursorAfter = addApiProp<string>(['json', 'page', 'after'])
const addCursorBefore = addApiProp<string>(['json', 'page', 'before'])
const addCursorSize = addApiProp<number>(['json', 'page', 'size'])

export type SortDirection = 'asc' | 'desc'
export const addSortDirection = addApiProp<SortDirection>(['json', 'sort'])

export const addAbortSignal = addApiProp<AbortSignal>(['signal'])

const addToken = () =>
  addApiProp<string>(['headers', 'Authorization'])(`Bearer ${getToken()}`)

const addCredentials = () => addApiProp<string>(['credentials'])('include')

const withAuthentication = (client: ApiClient) =>
  compose(addToken(), addCredentials())(client)

export const addAuthentication =
  (client: ApiClient) => (config: ApiRequest) => {
    if (!getToken()) {
      return refreshToken(client)
        .then(() => withAuthentication(client)(config))
        .catch(authFailureHandler(withAuthentication(client)))
    }

    return withAuthentication(client)(config).catch((error: Error) =>
      authFailureHandler(withAuthentication(client))(error).then(() =>
        withAuthentication(client)(config),
      ),
    )
  }

const DEFAULT_LIMIT = 50

export const defaultPaginationParams: Page = {
  size: DEFAULT_LIMIT,
  before: null,
  after: null,
}

export const addCursorPagination = (pageParam: Page) => {
  const { before, after, size } = pageParam ?? {}

  return compose(
    addCursorBefore(before),
    addCursorAfter(after),
    addCursorSize(size),
  )
}

/** @deprecated */
interface CursorPaginationParamsOld {
  searchBefore?: string
  searchAfter?: string
}

/** @deprecated */
export const defaultPaginationParamsOld: CursorPaginationParamsOld = {
  searchBefore: undefined,
  searchAfter: undefined,
}

/** @deprecated */
export const addCursorPaginationOld = (
  pageParam: CursorPaginationParamsOld,
  resultsPerPage = DEFAULT_LIMIT,
) => {
  const { searchBefore, searchAfter } = pageParam || {}

  return compose(
    addSearchBefore(searchBefore),
    addSearchAfter(searchAfter),
    addLimit(resultsPerPage),
  )
}

type GetCursorPageParam = ({ page }: { page: Page }) => Page | undefined

export const getNextCursorPageParam: GetCursorPageParam = ({ page }) => {
  if (!page.after) return undefined

  return { ...page, before: null }
}

export const getPrevCursorPageParam: GetCursorPageParam = ({ page }) => {
  if (!page.before) return undefined

  return { ...page, after: null }
}

export const apiClient: ApiClient = ({ url, ...config }) =>
  rawApiClient(url, { prefixUrl: getApiUrl(), ...config })

export const getAuthenticatedApiClient =
  (
    tokenRefreshErrorHandler: (error: TokenRefreshError | Error) => void,
  ): ApiClient =>
  config =>
    addAuthentication(apiClient)(config).catch(tokenRefreshErrorHandler)

export const getMutationApiClient =
  (restaurantHash?: string, teamMemberId?: number | null): ApiClient =>
  ({ url, json, ...config }) => {
    const addons = {
      restaurant_hash: restaurantHash,
      team_id: teamMemberId,
      current_team_id: teamMemberId,
    }
    return rawApiClient(url, {
      prefixUrl: getApiUrl(),
      method: 'POST',
      json: typeof json === 'object' ? { ...addons, ...json } : addons,
      ...config,
    })
  }

export const getAuthenticatedMutationApiClient =
  (
    tokenRefreshErrorHandler: (error: TokenRefreshError | Error) => void,
    restaurantHash?: string,
    teamMemberId?: number | null,
  ): ApiClient =>
  config =>
    addAuthentication(getMutationApiClient(restaurantHash, teamMemberId))(
      config,
    ).catch(tokenRefreshErrorHandler)
