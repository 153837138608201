import { Typography, type TypographyProps } from '@mui/material'

const Note = ({ sx, ...props }: Partial<TypographyProps>) => (
  <Typography
    color="text.quaternary"
    sx={{
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      ...sx,
    }}
    {...props}
  />
)

export default Note
