import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { complement, compose } from 'ramda'
import { Typography, type SxProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { SlotStatus } from 'src/entities/availability/types/availability'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useServiceTimesQuery } from 'src/entities/service-time/queries/serviceTime'
import {
  getServiceTimeOfTime,
  isTimeIncludedBy,
} from 'src/entities/service-time/services/serviceTime'
import { useRoomShiftsQuery } from 'src/entities/shift-instance/queries/shift'
import ReservationDrawerInlineSection from 'src/shared/components/common/ReservationDrawerInlineSection'
import PickerGrid from 'src/shared/components/containers/PickerGrid'
import StepPicker from 'src/shared/components/form/inputs/StepPicker/StepPicker'
import {
  areOnSameDay,
  combineDateWithTime,
  formatTime,
  fromTimestamp,
  localToUtc,
  toTimestamp,
  utcToLocal,
} from 'src/shared/lib/range/services/date'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import { type DrawerColor } from 'src/widgets/SidebarDrawer/drawerColor'
import { SidebarDrawer } from 'src/widgets/SidebarDrawer/SidebarDrawer'
import SidebarHeader from 'src/widgets/SidebarDrawer/SidebarHeader'
import { ButtonStyles, SlotPicker } from './SlotPicker'
import { generateIntervals } from './SlotPicker/service/time'

interface TimeSelectionProps {
  open: boolean
  onOpen: () => void
  onClose: () => void
  currentDate: Date
  selectedReservation: ReservationInterface
  initialReservation: ReservationInterface
  isSlotPickerEnabled: boolean
  setReservationTime: (time: Date) => void
  onSlotStatusChange: (status: SlotStatus) => void
  drawerColor: DrawerColor
}

export const TimeSelection = ({
  open,
  onOpen,
  onClose,
  isSlotPickerEnabled,
  setReservationTime,
  currentDate: nonDeferredCurrentDate,
  selectedReservation,
  initialReservation,
  onSlotStatusChange,
  drawerColor,
}: TimeSelectionProps) => {
  const currentDate = useDeferredValue(nonDeferredCurrentDate)
  const isDateChanging = !areOnSameDay(currentDate, nonDeferredCurrentDate)

  const roomId = useDeferredValue(selectedReservation.room)

  const { t } = useTranslation()

  const { data: originalServiceTimes } = useServiceTimesQuery()
  const serviceTimes = useMemo(
    () =>
      originalServiceTimes.map(v => ({
        ...v,
        timeRange: v.timeRange.map(tr =>
          combineDateWithTime(currentDate, tr),
        ) as DefinedRangeInterface<Date>,
      })),
    [currentDate, originalServiceTimes],
  )

  const { data: shifts } = useRoomShiftsQuery(currentDate, roomId)

  const dailyTimes = useMemo(
    () => generateIntervals(true, currentDate),
    [currentDate],
  )

  const dailyTimestamps = useMemo(
    () => dailyTimes.map(dt => toTimestamp(localToUtc(dt))),
    [dailyTimes],
  )

  const selectedSlot = isDateChanging
    ? combineDateWithTime(currentDate, selectedReservation.dateRange[0])
    : selectedReservation.dateRange[0]

  const isNotInAnyServiceTime = useMemo(
    () => complement(getServiceTimeOfTime(serviceTimes)),
    [serviceTimes],
  )

  const isInLockedShift = useCallback(
    (time: Date) =>
      shifts.some(
        shift => shift.lockStatus === 'locked' && isTimeIncludedBy(time)(shift),
      ),
    [shifts],
  )

  const handleOldEdgeCases = useCallback(
    <T, U, V>(notInServiceTime: T, inClosedShift: U, defaultValue: V) =>
      (time: Date) => {
        if (isInLockedShift(time)) return inClosedShift
        if (isNotInAnyServiceTime(time)) return notInServiceTime
        return defaultValue
      },
    [isInLockedShift, isNotInAnyServiceTime],
  )

  const createOldPickerButtonStyle = useMemo(
    () => handleOldEdgeCases(ButtonStyles.grey, ButtonStyles.red, null),
    [handleOldEdgeCases],
  )

  const getSlotStatus = useMemo(
    () =>
      handleOldEdgeCases(
        SlotStatus.NotInServiceTime,
        SlotStatus.InClosedShift,
        SlotStatus.Available,
      ),
    [handleOldEdgeCases],
  )

  const [buttonStyles, setButtonStyles] = useState<SxProps>(null)

  useEffect(() => {
    if (isSlotPickerEnabled) return

    onSlotStatusChange(getSlotStatus(selectedSlot))

    setButtonStyles(createOldPickerButtonStyle(selectedSlot))
  }, [
    onSlotStatusChange,
    getSlotStatus,
    createOldPickerButtonStyle,
    isSlotPickerEnabled,
    selectedSlot,
  ])

  const onOldPickerClick = useCallback(
    (time: Date) => {
      onClose()
      setReservationTime(time)
    },
    [onClose, setReservationTime],
  )

  const timestamp = useMemo(
    () => toTimestamp(localToUtc(selectedSlot)),
    [selectedSlot],
  )

  const onStepChange = useMemo(
    () => compose(setReservationTime, utcToLocal, fromTimestamp),
    [setReservationTime],
  )

  return (
    <>
      <ReservationDrawerInlineSection title={t('angular.time')}>
        <StepPicker
          disabled={isDateChanging}
          onChange={onStepChange}
          value={timestamp}
          minValue={dailyTimestamps[0]}
          maxValue={dailyTimestamps.at(-1)}
          step={15 * 1000 * 60}
          sx={{ backgroundColor: 'white', maxWidth: ['none', 160] }}
          rendererFactory={() => ({
            buttonProps: {
              onClick: onOpen,
              children: formatTime(selectedReservation.dateRange[0]),
              sx: buttonStyles,
            },
          })}
        />
      </ReservationDrawerInlineSection>
      <SidebarDrawer
        open={open}
        onClose={onClose}
        level={1}
        color={drawerColor}
        keepMounted
        sx={{ overflow: 'hidden' }}
      >
        <SidebarHeader onClose={onClose} color={drawerColor}>
          <Typography variant="labelBig">{t('angular.time')}</Typography>
        </SidebarHeader>
        {isSlotPickerEnabled ? (
          <SlotPicker
            currentDate={currentDate}
            selectedReservation={selectedReservation}
            initialReservation={initialReservation}
            selectedSlot={selectedSlot}
            onSlotStatusChange={onSlotStatusChange}
            onClose={onClose}
            onButtonStyleChange={setButtonStyles}
            onReservationTimeChange={setReservationTime}
            disabled={isDateChanging}
          />
        ) : (
          <PickerGrid
            items={dailyTimes}
            onItemClick={onOldPickerClick}
            renderItem={formatTime}
            createButtonStyle={createOldPickerButtonStyle}
            disabled={isDateChanging}
          />
        )}
      </SidebarDrawer>
    </>
  )
}
