import { TableSortLabel, Typography } from '@mui/material'

import { type HeaderContext } from '@tanstack/react-table'
import { type WithTranslation } from 'react-i18next'

const SortHeader = <T,>({
  t,
  header: { column, id },
}: HeaderContext<T, unknown> & WithTranslation) => (
  <TableSortLabel
    active={!!column.getIsSorted()}
    direction={column.getIsSorted() || column.getAutoSortDir()}
    onClick={column.getToggleSortingHandler()}
  >
    <Typography>{t(id)}</Typography>
  </TableSortLabel>
)

export default SortHeader
