import { andThen, composeWith, map, prop } from 'ramda'

import { type ApiClient } from 'src/shared/lib/api/services/api'
import {
  convertToTimezone,
  getUtcDayStart,
  toApiDateString,
} from 'src/shared/lib/range/services/date'
import { tableLockSchema, type ApiTable } from '../../config/types/configApi'
import {
  type TableLockUpdateInterface,
  type TableNoteUpdateInterface,
  type TablesOrderUpdateInterface,
} from '../types/table'
import { tableNoteFromApi, tableToApiPayload } from '../types/tableApi'

export const setTablesOrder =
  (httpClient: ApiClient) => (areasOrder: TablesOrderUpdateInterface) =>
    httpClient({
      url: 'setPositions',
      json: {
        positions: areasOrder,
      },
    })

export const setTable = (httpClient: ApiClient) => (table: ApiTable) =>
  httpClient({
    url: 'addTable',
    json: tableToApiPayload(table),
  })

export const deleteTable = (httpClient: ApiClient) => (table: ApiTable) =>
  httpClient({
    url: 'deleteTable',
    json: tableToApiPayload(table),
  })

export const getNotes =
  (httpClient: ApiClient) =>
  (date: Date, serviceTimeId: number | null = 0) =>
    composeWith(andThen)([map(tableNoteFromApi), prop('data'), httpClient])({
      url: 'tables/getNotes',
      json: {
        date: getUtcDayStart(date).toISOString(),
        service_time_id: serviceTimeId,
      },
    })

export const setNote =
  (httpClient: ApiClient) =>
  ({
    tableNote: { note, tableId },
    date,
    serviceTimeId,
  }: TableNoteUpdateInterface) =>
    httpClient({
      url: 'tableNotice',
      json: {
        table_id: tableId,
        notice: note,
        date: toApiDateString(date),
        time_range_id: serviceTimeId,
      },
    })

export const setLock =
  (httpClient: ApiClient) =>
  async ({
    locked,
    date,
    serviceTimeId,
    tableId,
  }: TableLockUpdateInterface) => {
    await httpClient({
      url: 'lockTable',
      json: {
        table_id: tableId,
        lock: locked ? 'lock' : 'unlock',
        date: toApiDateString(convertToTimezone(date)),
        time_range_id: serviceTimeId,
      },
    })
  }

export const getLocks = (httpClient: ApiClient) => (date: Date) =>
  composeWith(andThen)([
    map(tl => tableLockSchema.parse(tl)),
    prop('data'),
    httpClient,
  ])({
    url: 'tables/getLocks',
    json: {
      date: getUtcDayStart(date),
    },
  })
