import React from 'react'
import { compose } from 'ramda'

import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseInfiniteQuery,
  useSuspenseQueries,
  useSuspenseQuery,
  type UseQueryOptions,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { useCacheUpsert } from 'src/shared/lib/api/queries/optimisticUpdate'
import { useRestaurantCacheKeyFactory } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import { create5MinuteCronTime } from 'src/shared/lib/api/services/api'
import {
  defaultPaginationParams,
  getNextCursorPageParam,
  getPrevCursorPageParam,
} from 'src/shared/lib/api/services/reservationsBookApiClient'
import { useRecentFeedbackFetcher } from '../../feedback/queries/feedback'
import { type ReservationInterface } from '../../reservation/types/reservation'
import {
  addCustomer,
  fetchCustomer,
  fetchStats,
  mergeCustomers,
  putCustomer,
  searchCustomers,
} from '../api/customerApi'
import { customerFromGuest } from '../services/customerFactory'
import {
  type CustomerInterface,
  type FallbackCustomer,
  type ReservationStatsInterface,
} from '../types/customer'

const CUSTOMER_CACHE_KEY = ['customers', 'get']
const useCustomerCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(CUSTOMER_CACHE_KEY)

const CUSTOMERS_SEARCH_CACHE_KEY = ['customers', 'search']
const useSearchCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(CUSTOMERS_SEARCH_CACHE_KEY)

const RESERVATION_STATS_CACHE_KEY = ['reservationStats']
const useStatsCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(RESERVATION_STATS_CACHE_KEY)

const every5Minutes = create5MinuteCronTime()

const getRefetchInterval = () => every5Minutes.getTimeout()

export const useCustomers = (customerIds: CustomerInterface['id'][]) => {
  const { post } = useApiClient()

  const createCustomerCacheKey = React.useDeferredValue(
    useCustomerCacheKeyFactory(),
  )

  return useSuspenseQueries({
    queries: React.useDeferredValue(customerIds).map(customerId => ({
      queryKey: createCustomerCacheKey([customerId]),
      queryFn: () => fetchCustomer(post)(customerId),
      retryOnMount: false,
      refetchOnMount: false,
      refetchInterval: getRefetchInterval(),
      staleTime: 5 * 60 * 1000,
    })),
    combine: results => results.map(result => result.data),
  })
}

export const useCustomersSearchQuery = (searchPhrase: string, all = true) => {
  const { post } = useApiClient()
  const keyFactory = useSearchCacheKeyFactory()

  return useSuspenseInfiniteQuery({
    queryKey: keyFactory([{ searchPhrase, all }]),
    initialPageParam: defaultPaginationParams,
    queryFn: ({ pageParam }) =>
      searchCustomers(post)(searchPhrase, all, pageParam),
    getNextPageParam: getNextCursorPageParam,
    getPreviousPageParam: getPrevCursorPageParam,
    staleTime: 60 * 1000,
    refetchInterval: 60 * 1000,
  })
}

export const useStatsInvalidation = () => {
  const queryClient = useQueryClient()
  const getStatsCacheKey = useStatsCacheKeyFactory()

  return React.useCallback(
    (customerId: CustomerInterface['id']) =>
      queryClient.invalidateQueries({
        queryKey: getStatsCacheKey([customerId]),
      }),
    [getStatsCacheKey, queryClient],
  )
}

export const useCustomerMergeMutation = () => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()
  const createCustomerCacheKey = useCustomerCacheKeyFactory()
  const createSearchCacheKey = useSearchCacheKeyFactory()
  const invalidateStats = useStatsInvalidation()

  return useMutation({
    mutationFn: ({
      target,
      sources,
    }: {
      target: CustomerInterface
      sources: CustomerInterface[]
    }) => mergeCustomers(apiClient.post)(target, sources),
    onMutate: async ({ target, sources }) => {
      const cancellations = [target, ...sources].map(customer =>
        queryClient.cancelQueries({
          queryKey: createCustomerCacheKey([customer.id]),
        }),
      )
      await Promise.all(cancellations)
      await queryClient.cancelQueries({ queryKey: createSearchCacheKey() })
    },
    onSettled: async (_data, _error, variables) => {
      await queryClient.invalidateQueries({
        queryKey: createCustomerCacheKey([variables.target.id]),
      })
      await queryClient.invalidateQueries({ queryKey: createSearchCacheKey() })
      void invalidateStats(variables.target.id)
    },
  })
}

const useCustomerQueryOptionsCallback = () => {
  const apiClient = useApiClient()
  const createCustomerCacheKey = useCustomerCacheKeyFactory()

  const queryClient = useQueryClient()

  return React.useCallback(
    (customerId: number) =>
      queryOptions({
        queryKey: createCustomerCacheKey([customerId]),
        queryFn: async () => {
          const cachedCustomer = queryClient.getQueryData<
            CustomerInterface | FallbackCustomer
          >(createCustomerCacheKey([customerId]))

          if (cachedCustomer && cachedCustomer.type === 'virtual')
            return cachedCustomer

          return fetchCustomer(apiClient.post)(customerId)
        },
        retryOnMount: false,
        refetchOnMount: false,
        refetchInterval: getRefetchInterval,
        staleTime: 5 * 60 * 1000,
      }),
    [queryClient, apiClient, createCustomerCacheKey],
  )
}

export const useFetchCustomer = () => {
  const optionsFactory = useCustomerQueryOptionsCallback()
  const queryClient = useQueryClient()

  return React.useMemo(
    () => compose(o => queryClient.fetchQuery(o), optionsFactory),
    [optionsFactory, queryClient],
  )
}

export const useCustomerQuery = (
  customerId: number,
  options: Partial<
    UseSuspenseQueryOptions<CustomerInterface | FallbackCustomer>
  > = {},
) =>
  useSuspenseQuery({
    ...useCustomerQueryOptionsCallback()(customerId),
    ...options,
  })

const useReservationCustomerQueryOptionsCallback = () => {
  const createCustomerQueryOptions = useCustomerQueryOptionsCallback()
  const apiClient = useApiClient()

  const upsertCache = useCacheUpsert()

  return React.useCallback(
    (reservation: ReservationInterface) => {
      const { customerId } = reservation.guest
      const { queryKey: qk, ...options } = createCustomerQueryOptions(
        customerId ?? 0,
      )

      const queryKey = customerId
        ? qk
        : [...qk.slice(0, qk.length - 1), 'fallback', reservation.id]

      return queryOptions({
        ...options,
        queryKey,
        queryFn: async () => {
          const guestCustomer = customerFromGuest(reservation.guest)

          if (!customerId) return guestCustomer

          const customer = await fetchCustomer(apiClient.post)(customerId)

          if (customer.type === 'fallback') return guestCustomer

          return customer
        },
        select: customer => {
          if (customer.type === 'fallback') {
            const guestCustomer = customerFromGuest(reservation.guest)

            upsertCache(queryKey, guestCustomer)

            return guestCustomer
          }

          return customer
        },
      })
    },
    [apiClient.post, createCustomerQueryOptions, upsertCache],
  )
}

export const useNonSuspenseReservationCustomerQuery = (
  reservation: ReservationInterface,
  options: Partial<UseQueryOptions<CustomerInterface | FallbackCustomer>> = {},
) =>
  useQuery({
    ...useReservationCustomerQueryOptionsCallback()(reservation),
    ...options,
  })

export const useReservationCustomerQuery = (
  reservation: ReservationInterface,
) => useSuspenseQuery(useReservationCustomerQueryOptionsCallback()(reservation))

export const useCustomerAddMutation = () => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()
  const createSearchCacheKey = useSearchCacheKeyFactory()

  return useMutation({
    mutationFn: (customer: CustomerInterface) =>
      addCustomer(apiClient.post)(customer),
    onMutate: () =>
      queryClient.cancelQueries({ queryKey: createSearchCacheKey() }),
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: createSearchCacheKey() }),
  })
}

export const useCustomerUpdateMutation = () => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()
  const createCustomerCacheKey = useCustomerCacheKeyFactory()
  const createSearchCacheKey = useSearchCacheKeyFactory()

  return useMutation({
    mutationFn: (customer: CustomerInterface) =>
      putCustomer(apiClient.post)(customer),
    onMutate: async customer => {
      await queryClient.cancelQueries({
        queryKey: createCustomerCacheKey([customer.id]),
      })
      await queryClient.cancelQueries({ queryKey: createSearchCacheKey() })
    },
    onSettled: async (_data, _error, customer) => {
      await queryClient.invalidateQueries({
        queryKey: createCustomerCacheKey([customer.id]),
      })
      await queryClient.invalidateQueries({ queryKey: createSearchCacheKey() })
    },
  })
}

export const useReservationStats = (
  customers: (CustomerInterface | FallbackCustomer)[],
  options: Partial<UseSuspenseQueryOptions> = {},
) => {
  const apiClient = useApiClient()
  const getStatsCacheKey = useStatsCacheKeyFactory()

  return useSuspenseQueries({
    queries: React.useDeferredValue(customers).map(customer => ({
      queryKey: getStatsCacheKey([customer.id]),
      queryFn: async () => {
        if (customer.type !== 'actual' || !customer.id) return null

        return fetchStats(apiClient.post)(customer.id)
      },
      retryOnMount: false,
      refetchOnMount: false,
      refetchInterval: getRefetchInterval,
      staleTime: 5 * 60 * 1000,
      ...options,
    })),
    combine: results =>
      results
        .map(result => result.data)
        .filter(Boolean) as ReservationStatsInterface[],
  })
}

export type HasFeedbackEnabled = (
  customer?: CustomerInterface,
) => Promise<boolean>

export const useFeedbackEnabledCallback = () => {
  const fetchRecentFeedback = useRecentFeedbackFetcher()

  return React.useCallback<HasFeedbackEnabled>(
    async customer => {
      if (!customer) return false

      const allowsFeedback = customer.emails.some(e => e.feedback)
      return allowsFeedback && !(await fetchRecentFeedback(customer.id))
    },
    [fetchRecentFeedback],
  )
}
