import { filter, reject } from 'ramda'

import { type AreaInterface } from 'src/entities/area/types/area'
import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { occupancyHasStatus } from 'src/entities/reservation/services/checkInStatus'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { TableCheckInStatusEnum } from 'src/entities/table/types/table'
import { reduceToMap } from 'src/shared/lib/common/services/functional/functional'
import { appendOrCreate } from './reservation'

export const exists = <T>(item: T | undefined): item is T => !!item

export const splitReservationIntoOccupancies = (
  reservation: ReservationInterface,
) => reservation.occupancies.map(occupancy => ({ reservation, occupancy }))

export type ReservationOccupancy = ReturnType<
  typeof splitReservationIntoOccupancies
>[number]

export const groupOccupanciesByArea = (
  getArea: (occupancy: ReservationOccupancy) => AreaInterface['id'] | undefined,
) => reduceToMap(appendOrCreate<ReservationOccupancy>, getArea, exists)

export const sumOccupancyPax = (
  paxSum: number,
  occupancy: ReservationOccupancy,
) => occupancy.occupancy.seatCount + paxSum

export const groupOccupanciesByServiceTime = (
  getServiceTimes: (
    reservation: ReservationOccupancy,
  ) => (ServiceTimeInterface | null)[],
) => reduceToMap(appendOrCreate<ReservationOccupancy>, getServiceTimes)

export const getOccupancy = (o: ReservationOccupancy) => o.occupancy
export const getReservation = (o: ReservationOccupancy) => o.reservation

export const hasStatus =
  (status: TableCheckInStatusEnum) => (occupancy: ReservationOccupancy) =>
    occupancyHasStatus(status)(getOccupancy(occupancy))

export const filterCheckedInOccupancies = filter<ReservationOccupancy>(
  hasStatus(TableCheckInStatusEnum.CheckedIn),
)
export const filterPendingOccupancies = filter<ReservationOccupancy>(
  hasStatus(TableCheckInStatusEnum.Pending),
)

export const rejectCheckedOutOccupancies = reject<ReservationOccupancy>(
  hasStatus(TableCheckInStatusEnum.CheckedOut),
)

export const getOccupancyServiceTimes =
  (
    getReservationServiceTimes: (
      reservation: ReservationInterface,
    ) => ServiceTimeInterface[],
  ) =>
  (occupancy: ReservationOccupancy) => [
    ...getReservationServiceTimes(occupancy.reservation),
    null,
  ]
