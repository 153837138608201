import React from 'react'
import { compose } from 'ramda'

import { RouterProvider } from '@tanstack/react-router'

import router from 'src/app/router/router'
import { withHelmet } from 'src/shared/components/common/Meta/Meta'
import {
  withAppContainer,
  withToaster,
} from 'src/shared/components/containers/AppContainer/AppContainer'
import { withAppProvider } from '../../hoc/withAppProvider'
import { withGlobalErrorBoundary } from '../../hoc/withGlobalErrorBoundary'

const MaintenanceMode = React.lazy(
  () => import('src/pages/Login/MaintenanceMode'),
)

const isMaintenanceMode = import.meta.env.PUBLIC_MAINTENANCE_MODE

const AppView = () => {
  if (isMaintenanceMode) return <MaintenanceMode />

  return <RouterProvider router={router} />
}

export default compose(
  withGlobalErrorBoundary,
  withHelmet,
  withAppProvider,
  withAppContainer,
  withToaster,
)(AppView)
