import {
  ListSubheader,
  styled,
  useThemeProps,
  type ListSubheaderProps,
} from '@mui/material'
import { type FormControlTypeMap } from '@mui/material/FormControl/FormControl'

export interface SizeableListSubheaderProps extends ListSubheaderProps {
  size?: FormControlTypeMap['props']['size']
}

const SizeableListSubheaderRoot = styled(ListSubheader, {
  name: 'SizeableListSubheader',
})({})

const SizeableListSubheader = (inProps: SizeableListSubheaderProps) => {
  const props = useThemeProps({
    props: inProps,
    name: 'SizeableListSubheader',
  })

  return <SizeableListSubheaderRoot {...props} />
}

export default SizeableListSubheader
