import { useMediaQuery, type Breakpoint, type Theme } from '@mui/material'

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

export type BreakpointsMap<T> = RequireAtLeastOne<{
  [key in Breakpoint]: T | null
}>

export const useBreakpointCallback = <T>(
  callbacks: BreakpointsMap<T>,
  breakpoint: Breakpoint,
) =>
  useMediaQuery((theme: Theme) => theme.breakpoints.up(breakpoint)) &&
  typeof callbacks[breakpoint] !== 'undefined'
    ? breakpoint
    : null
