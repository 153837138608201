import { useMemo } from 'react'
import { juxt, prop } from 'ramda'
import { Button, ButtonGroup, Divider, Stack, Typography } from '@mui/material'

import { noop } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import {
  type NoPaymentPlan,
  type PaymentPlan,
} from 'src/entities/schedule/types/scheduleApi'
import IconButton from 'src/shared/components/buttons/IconButton'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
} from 'src/shared/components/icons/ChevronIcon'
import { cycleArray } from 'src/shared/lib/common/services/array/array'
import { type DrawerColor } from 'src/widgets/SidebarDrawer/drawerColor'
import { SidebarDrawer } from 'src/widgets/SidebarDrawer/SidebarDrawer'
import SidebarHeader from 'src/widgets/SidebarDrawer/SidebarHeader'
import { PaymentPlanItem } from './PaymentPlanItem'

interface PaymentPlanSelectionProps {
  reservation: ReservationInterface
  currency: string
  paymentPlans: (PaymentPlan | NoPaymentPlan)[]
  onPaymentPlanChange: (newPlan: PaymentPlan | NoPaymentPlan) => void
  open: boolean
  onOpen: () => void
  onClose: () => void
  drawerColor: DrawerColor
}

export const PaymentPlanSelection = ({
  open,
  onClose,
  onOpen,
  reservation,
  paymentPlans,
  currency,
  onPaymentPlanChange,
  drawerColor,
}: PaymentPlanSelectionProps) => {
  const { t } = useTranslation()

  const cyclePlans = useMemo(
    () =>
      cycleArray(
        paymentPlans,
        prop('id'),
        onPaymentPlanChange,
      )(reservation.payment?.paymentPlanId ?? null),
    [onPaymentPlanChange, paymentPlans, reservation.payment?.paymentPlanId],
  )

  const { paymentPlanId } = reservation.payment ?? {}

  return (
    <>
      <ButtonGroup
        variant="outlined"
        sx={{ width: 1, backgroundColor: 'white' }}
      >
        <IconButton onClick={() => (reservation.id ? noop : cyclePlans(-1))}>
          <ChevronLeftIcon />
        </IconButton>
        <Button sx={{ flex: 1 }} onClick={reservation.id ? noop : onOpen}>
          <Typography
            component="span"
            sx={{
              overflow: 'hidden',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              lineHeight: '1',
              wordBreak: 'break-word',
            }}
          >
            {paymentPlanId
              ? (paymentPlans.find(
                  (p): p is PaymentPlan => p.id === paymentPlanId,
                )?.name ?? t('payment_plan.deleted_plan'))
              : t('payment_plan.no_plan', { defaultValue: 'No plan' })}
          </Typography>
        </Button>
        <IconButton onClick={() => (reservation.id ? noop : cyclePlans(1))}>
          <ChevronRightIcon />
        </IconButton>
      </ButtonGroup>
      <SidebarDrawer
        open={open}
        onClose={onClose}
        level={1}
        color={drawerColor}
        sx={{
          overflow: 'hidden',
        }}
      >
        <SidebarHeader onClose={onClose} color={drawerColor}>
          <Typography variant="labelBig">
            {t('payment_plan.payment_plan_selection', {
              defaultValue: 'Payment plan selection',
            })}
          </Typography>
        </SidebarHeader>
        <Stack
          sx={{ p: 1, overflow: 'auto' }}
          divider={<Divider sx={{ borderColor: 'white' }} />}
        >
          {paymentPlans.map(plan => (
            <PaymentPlanItem
              key={plan.id}
              plan={plan}
              currency={currency}
              onPlanChange={juxt([onPaymentPlanChange, onClose])}
            />
          ))}
        </Stack>
      </SidebarDrawer>
    </>
  )
}
