import { filter } from 'ramda'

import { type TFunction } from 'i18next'

import { toString } from 'src/shared/lib/range/services/range'
import { includesTime } from 'src/shared/lib/range/services/timeRange'
import { type CustomerInterface } from '../../customer/types/customer'
import { type HotelStayInterface } from '../types/hotelStay'

export const splitHotelStay = (hotelStay: HotelStayInterface) =>
  hotelStay.rooms.map(room => ({ room, hotelStay }))
export type RoomStayInterface = ReturnType<typeof splitHotelStay>[number]

export const isOnDate = (date: Date) => (roomStay: RoomStayInterface) =>
  includesTime(roomStay.room.dateRange, date)

export const formatGuestCount =
  (t: TFunction) => (guestCount: HotelStayInterface['guestCounts'][number]) => {
    const groupLabel = t(
      `customer.hotel_stay.body.guests.age_group.${guestCount.ageGroup}`,
      {
        tDescription: `Customer preview hotel stay guests age group - ${guestCount.ageGroup}`,
        defaultValue: guestCount.ageGroup,
      },
    )

    return `${groupLabel}: ${toString(guestCount.count)}`
  }

export const formatRoomStaysRoomName = (roomStays: RoomStayInterface[]) => {
  const [name, ...hiddenNames] = roomStays.map(roomStay => roomStay.room.name)

  if (!name) return ''

  const nameWithHash = name.startsWith('#') ? name : `#${name}`

  if (!hiddenNames.length) return nameWithHash

  return `${nameWithHash}+${hiddenNames.length}`
}

export const hasCustomer = (customerId: CustomerInterface['id']) =>
  filter(
    (roomStay: RoomStayInterface) =>
      !!roomStay.hotelStay.guests.find(
        guest => guest.customerId === customerId,
      ),
  )
