import React from 'react'
import { filter, propEq } from 'ramda'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import {
  useTableLockMutation,
  useTableLocksQuery,
} from 'src/entities/table/queries/table'
import { type TableInterface } from 'src/entities/table/types/table'

export const useLockTableCallback = (
  date: Date,
  serviceTime: ServiceTimeInterface | undefined | null,
) => {
  const { mutate: saveTableLock } = useTableLockMutation()

  return React.useCallback(
    (tableId: number, locked: boolean) => {
      saveTableLock({
        locked,
        date,
        tableId,
        serviceTimeId: serviceTime?.id ?? 0,
      })
    },
    [date, saveTableLock, serviceTime],
  )
}

export const useTableLocks = (
  date: Date,
  serviceTime: ServiceTimeInterface | undefined | null,
) => {
  const { data: tableLocks } = useTableLocksQuery(date)

  return React.useMemo(() => {
    if (!serviceTime) return tableLocks

    return filter(propEq(serviceTime.id, 'serviceTimeId'), tableLocks)
  }, [serviceTime, tableLocks])
}

export const useIsTableLockedCallback = (
  date: Date,
  serviceTime: ServiceTimeInterface | undefined | null,
) => {
  const locks = useTableLocks(date, serviceTime)

  return React.useCallback(
    (table: TableInterface) => locks.some(l => l.tableId === table.id),
    [locks],
  )
}
