import React from 'react'
import { TabContext } from '@mui/lab'

import { markDone } from 'src/entities/notification/api/todoApi'
import { type NotificationStatusFilter } from 'src/entities/notification/api/todoEventApi'
import { useNotificationsQuery } from 'src/entities/notification/queries/notification'
import {
  NotificationTypeEnum,
  type NotificationInterface,
} from 'src/entities/notification/types/notification'
import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import useInfiniteQueryAutomaticLoader, {
  defaultItemCounter,
} from 'src/shared/lib/api/hooks/useInfiniteQueryAutomaticLoader'
import List from './List'
import ListTab from './ListTab'
import MarkAllDone from './MarkAllDone'
import TabChoice, { LISTS } from './TabChoice'
import { useMarkAsReadEffect } from './useMarkAsReadEffect'
import { useNotifications } from './useNotifications'

interface FnParams {
  isCacheUpdating: boolean
  status: NotificationStatusFilter
}

const useNotificationList = ({ isCacheUpdating, status }: FnParams) => {
  const todoItemNotificationsQuery = useNotificationsQuery(status)

  const flatNotifications = React.useMemo(
    () => todoItemNotificationsQuery.data.pages.flat(),
    [todoItemNotificationsQuery.data],
  )

  const notifications = useNotifications(flatNotifications)

  const { setLastRenderedItemIndex } = useInfiniteQueryAutomaticLoader(
    todoItemNotificationsQuery,
    defaultItemCounter,
    isCacheUpdating,
  )

  const lastItemMountedHandler = React.useCallback(() => {
    setLastRenderedItemIndex(flatNotifications.length - 1)
  }, [flatNotifications, setLastRenderedItemIndex])

  return { notifications, onLastItemMount: lastItemMountedHandler }
}

const Lists = ({
  onNotificationClick,
  isCacheUpdating,
}: {
  onNotificationClick: (notification: NotificationInterface) => void
  isCacheUpdating: boolean
}) => {
  const [list, setList] = React.useState(LISTS.UNDONE)

  const openNotificationListProps = useNotificationList({
    isCacheUpdating,
    status: 'open',
  })
  const allNotificationListProps = useNotificationList({
    isCacheUpdating,
    status: 'all',
  })

  useMarkAsReadEffect(openNotificationListProps.notifications)

  const apiClient = useApiClient()
  const markAllDoneClickHandler = React.useCallback(() => {
    const latestId = openNotificationListProps.notifications
      .filter(({ type }) => type !== NotificationTypeEnum.PhoneCall)
      .find(({ id }) => !!id)?.id

    if (!latestId) return undefined

    return markDone(apiClient.post)(latestId, true)
  }, [apiClient, openNotificationListProps.notifications])

  return (
    <>
      <TabContext value={list}>
        <TabChoice currentItem={list} setItem={setList} />
        <ListTab value={LISTS.UNDONE}>
          <List
            onNotificationClick={onNotificationClick}
            {...openNotificationListProps}
          />
        </ListTab>
        <ListTab value={LISTS.ALL}>
          <List
            onNotificationClick={onNotificationClick}
            {...allNotificationListProps}
          />
        </ListTab>
      </TabContext>
      <MarkAllDone onClick={markAllDoneClickHandler} />
    </>
  )
}

export default Lists
