import { Stack, useMediaQuery, type Theme } from '@mui/material'

import CancelButton from './CancelButton'
import DeleteButton from './DeleteButton'
import SaveButton from './SaveButton'

const FormButtons = ({
  onCancel,
  onDelete,
  onSave,
  showDeleteButton,
}: {
  onCancel: () => void
  onDelete: () => void
  onSave: () => void
  showDeleteButton: boolean
}) => {
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.only('xs'),
  )

  const size = isSmallScreen ? 'small' : 'medium'
  return (
    <Stack direction="row" spacing={1} sx={{ alignSelf: 'flex-end' }}>
      <CancelButton onClick={onCancel} size={size} />
      {showDeleteButton && <DeleteButton onClick={onDelete} size={size} />}
      <SaveButton onClick={onSave} size={size} />
    </Stack>
  )
}

export default FormButtons
