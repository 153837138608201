import { useEffect, useState } from 'react'
import { paperClasses } from '@mui/material'

import { noop } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import useFeature from 'src/entities/info/hooks/useFeature'
import { useIsAdminQuery } from 'src/entities/restaurant/queries/restaurants'
import { isAdmin } from 'src/entities/team-member/services/teamMember'
import { type TeamMember } from 'src/entities/team-member/types/teamMember'
import Modal from 'src/shared/components/common/Modal'
import { useGlobalModalContext } from 'src/shared/lib/context/global/useGlobalModalContext'
import { useSetActiveTeamMemberAtom } from 'src/shared/lib/context/state/atoms/activeTeamMember'
import TeamMembersList from 'src/widgets/TeamMembers/List'
import { TeamMemberGrid } from 'src/widgets/TeamMembers/List/TeamMemberGrid'
import useAvailableTeamMembers from './hooks/useAvailableTeamMembers'
import { useTeamMembersDialogEffect } from './hooks/useTeamMembersDialogEffect'

interface SwitchTeamMemberModalProps {
  open: boolean
}

const getWidth = (items: unknown[]) => {
  const itemsCount = items.length

  if (itemsCount <= 6) return 420
  if (itemsCount <= 12) return 720

  return 1024
}

export const SwitchTeamMemberModal = ({ open }: SwitchTeamMemberModalProps) => {
  const { t } = useTranslation()

  useTeamMembersDialogEffect()

  const { closeTeamMembersModal } = useGlobalModalContext()

  const teamMembers = useAvailableTeamMembers()
  const setActiveTeammember = useSetActiveTeamMemberAtom()

  const [teamMember, setTeamMember] = useState<TeamMember | null>(null)

  useEffect(() => {
    if (open) return

    setTeamMember(null)
  }, [open, teamMembers])

  const { data: isSuperadmin } = useIsAdminQuery()
  const pinFeatureEnabled = useFeature('pinEnabled')

  const handleTeamMemberSelect = (newTeamMember: TeamMember) => {
    const requirePin =
      pinFeatureEnabled &&
      newTeamMember?.pinRequired &&
      (!isSuperadmin || !isAdmin(newTeamMember))

    if (!requirePin) {
      setActiveTeammember(newTeamMember.id ?? null)
      closeTeamMembersModal()
      return
    }

    setTeamMember(newTeamMember)
  }

  return (
    <Modal
      title={t('angular.employees')}
      open={open}
      onClose={noop}
      sx={{ [`.${paperClasses.root}`]: { maxWidth: getWidth(teamMembers) } }}
    >
      <TeamMemberGrid>
        <TeamMembersList
          members={teamMembers}
          selectedTeamMember={teamMember}
          onSelect={handleTeamMemberSelect}
        />
      </TeamMemberGrid>
    </Modal>
  )
}
