import { Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { ScheduleLimits } from './components/ScheduleLimits'
import FormSection from '../../../FormSection'
import { BookingChannelsSection } from '../BookingChannelsSection/BookingChannelsSection'
import { sectionKeyToLabel } from '../FormNavigation/FormNavigation'

const LimitsSection = () => {
  const { t } = useTranslation()

  return (
    <FormSection
      title={sectionKeyToLabel(t)('limits')}
      tooltipText={t(
        'schedule.shifts.limits_section.channels.tooltip',
        "Select your preferred reservation channels and set individual threshold levels. When the number of reservations reaches a channel's threshold, the channel will become unavailable for further bookings.",
      )}
    >
      <Stack gap={0.5}>
        <ScheduleLimits />
        <BookingChannelsSection />
      </Stack>
    </FormSection>
  )
}

export default LimitsSection
