import { type CustomerInterface } from 'src/entities/customer/types/customer'
import { type PreferencesInterface } from 'src/entities/customer/types/preferences'
import Comment from './Comment'
import Food from './Food'
import Language from './Language'
import Place from './Place'

const Preferences = ({
  languageCode,
  comment,
  preferences,
}: {
  languageCode: CustomerInterface['languageCode']
  comment: CustomerInterface['comment']
  preferences: PreferencesInterface
}) => (
  <>
    {languageCode && <Language preference={languageCode} />}
    {comment && <Comment preference={comment} />}
    {preferences.place && <Place preference={preferences.place} />}
    {preferences.food && <Food preference={preferences.food} />}
  </>
)

export default Preferences
