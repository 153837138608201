import { type TableInterface } from 'src/entities/table/types/table'
import EllipsisTypography from 'src/pages/Settings/Areas/EllipsisTypography'
import SidebarHeader from 'src/widgets/SidebarDrawer/SidebarHeader'
import TableCapacity from 'src/widgets/TabularTables/TableItemInfo/TableCapacity'

interface HeaderProps {
  table: TableInterface
  onClose: () => void
}

const Header = ({ table, onClose }: HeaderProps) => (
  <SidebarHeader onClose={onClose}>
    <TableCapacity capacity={table.capacity} size={28} />
    <EllipsisTypography variant="labelBig" sx={{ maxWidth: 1 }}>
      {table.name}
    </EllipsisTypography>
  </SidebarHeader>
)

export default Header
