import React from 'react'
import { equals } from 'ramda'

import { SlotStatus } from 'src/entities/availability/types/availability'
import type { CommunicationTemplate } from 'src/entities/communication-template/types/communicationTemplates'
import { useCreateReservationMutation } from 'src/entities/reservation/queries/reservationsMutations'
import { haveSameSeatCount } from 'src/entities/reservation/services/reservation'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useSettingsObjectQuery } from 'src/entities/setting/queries/settings'
import {
  isAbandoned,
  resumable,
  useResumable,
  type Step,
} from 'src/shared/lib/common/services/resumable/resumable'
import { type SelectedReservation } from 'src/shared/lib/context/state/atoms/baseSelectedReservation'

export interface UseReservationCreation {
  onSlotStatusConfirmation: (slotStatus: SlotStatus) => void
  onIncompleteTableAssignment: (overflow: number) => void
  showTemplateChoice: boolean
  onTemplateChoice: () => void
  defaultSmsTemplate?: CommunicationTemplate
  defaultEmailTemplate?: CommunicationTemplate
}

export interface ReservationCreationOptions {
  slotConfirmed?: boolean
  smsTemplate?: CommunicationTemplate
  emailTemplate?: CommunicationTemplate
  incompleteTableAssignmentConfirmed?: boolean
}

export interface CreationDeps {
  reservation: SelectedReservation
  originalReservation: ReservationInterface
  slotStatus: SlotStatus
  seatCountOverflow: number
}

type CreationStep = Step<ReservationCreationOptions, CreationDeps>

export type StepChecker = <
  O extends ReservationCreationOptions,
  D extends CreationDeps,
>(
  o: O,
  d: D,
) => boolean

export const statusIsAvailable = (status: SlotStatus) =>
  status === SlotStatus.Available

export const slotNeedsConfirmation: StepChecker = (o, d) =>
  !o.slotConfirmed && !statusIsAvailable(d.slotStatus)

export const isAssignmentIncomplete: StepChecker = (o, d) => {
  const hasTables = d.reservation.occupancies.length > 0

  if (!hasTables) return false

  const hasSeatCountOverflow = d.seatCountOverflow !== 0
  const occupanciesAreChanged = () =>
    !equals(d.reservation.occupancies, d.originalReservation.occupancies)
  const seatCountIsChanged = !haveSameSeatCount(
    d.reservation,
    d.originalReservation,
  )
  return (
    !o.incompleteTableAssignmentConfirmed &&
    hasSeatCountOverflow &&
    (seatCountIsChanged || occupanciesAreChanged())
  )
}

export const needsTemplateChoice =
  (showChoice: boolean): StepChecker =>
  o =>
    showChoice && (!o.smsTemplate || !o.emailTemplate)

export const useReservationCreation = ({
  onSlotStatusConfirmation,
  onTemplateChoice,
  onIncompleteTableAssignment,
  showTemplateChoice,
  defaultSmsTemplate,
  defaultEmailTemplate,
}: UseReservationCreation) => {
  const { mutateAsync: createReservation } = useCreateReservationMutation()

  const { data: settings } = useSettingsObjectQuery()

  const steps: CreationStep[] = React.useMemo(
    () => [
      [
        slotNeedsConfirmation,
        (_o, d) => onSlotStatusConfirmation(d.slotStatus),
      ],
      [
        isAssignmentIncomplete,
        (_o, d) => onIncompleteTableAssignment(d.seatCountOverflow),
      ],
      [needsTemplateChoice(showTemplateChoice), onTemplateChoice],
    ],
    [
      onIncompleteTableAssignment,
      onSlotStatusConfirmation,
      onTemplateChoice,
      showTemplateChoice,
    ],
  )

  const { init, resume, abandon } = useResumable(
    React.useMemo(
      () =>
        resumable(steps, {
          ...(!showTemplateChoice && {
            smsTemplate: defaultSmsTemplate,
            emailTemplate: defaultEmailTemplate,
          }),
        }),
      [steps, showTemplateChoice, defaultEmailTemplate, defaultSmsTemplate],
    ),
  )

  const initHandler = React.useCallback(
    async (deps: CreationDeps) => {
      const options = await init(deps)

      if (isAbandoned(options)) return Promise.resolve(undefined)

      return createReservation({
        reservation: deps.reservation,
        emailTemplate: options.emailTemplate,
        smsTemplate: options.smsTemplate,
        notifyRestaurant: settings.newReservationGastroEmail,
      })
    },
    [init, createReservation, settings.newReservationGastroEmail],
  )

  return { init: initHandler, resume, abandon }
}
