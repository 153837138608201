import React from 'react'

import { type QueryClient } from '@tanstack/react-query'
import {
  createRootRouteWithContext,
  Navigate,
  Outlet,
} from '@tanstack/react-router'
import { AblyProvider } from 'ably/react'

import { ErrorScreen } from 'src/app/hoc/withGlobalErrorBoundary'
import ablyClient from 'src/shared/lib/api/services/ablyClient'
import { captureException } from 'src/shared/lib/context/global/sentry'

const TanStackRouterDevtools =
  import.meta.env.MODE !== 'development'
    ? () => null
    : React.lazy(() =>
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools,
        })),
      )

const ReactQueryDevtools =
  import.meta.env.MODE !== 'development'
    ? () => null
    : React.lazy(() =>
        import('@tanstack/react-query-devtools').then(res => ({
          default: res.ReactQueryDevtools,
        })),
      )

export const Route = createRootRouteWithContext<{ queryClient: QueryClient }>()(
  {
    notFoundComponent: () => <Navigate to="/" replace />,
    errorComponent: ErrorScreen,
    component: RootComponent,
    onCatch: (error, errorInfo) => captureException(error, { data: errorInfo }),
  },
)

function RootComponent() {
  return (
    <AblyProvider client={ablyClient}>
      <Outlet />
      <TanStackRouterDevtools />
      <ReactQueryDevtools />
    </AblyProvider>
  )
}
