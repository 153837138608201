import DoneRoundedIcon from '@mui/icons-material/DoneRounded'
import { type ButtonProps } from '@mui/material'

import SquareIconButton from '../../SquareIconButton'

const SaveButton = (props: ButtonProps) => (
  <SquareIconButton color="primary" variant="contained" {...props}>
    <DoneRoundedIcon fontSize="medium" />
  </SquareIconButton>
)

export default SaveButton
