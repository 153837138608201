import { compose } from 'ramda'

import type {
  ScheduleEvent,
  Shift,
  ShiftException,
} from 'src/entities/schedule/types/scheduleApi'
import { fromTime } from 'src/shared/lib/range/services/date'
import * as timeRangeService from 'src/shared/lib/range/services/timeRange'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import { type FormEvent } from './eventFormSchema'
import { type FormException } from './exceptionFormSchema'
import { type IntersectableScheduleItemWithPeriod } from './intersectable'
import {
  getWeekdaysIntersection,
  shiftTimeRange,
} from './intersectionValidation'

export interface EventOverlap {
  type: 'event'
  event: ScheduleEvent
  conflictingDateRange: DefinedRangeInterface<Date>
  conflictingWeekdays: number[]
  conflictingTimeRange: DefinedRangeInterface<Date>
}

export interface ExceptionOverlap {
  type: 'exception'
  exception: ShiftException
  conflictingDateRange: DefinedRangeInterface<Date>
  conflictingWeekdays: number[]
  conflictingTimeRange: DefinedRangeInterface<Date>
}

export type PeriodAware = ScheduleEvent | ShiftException

export const isException = (
  periodAware: Omit<PeriodAware, 'id'>,
): periodAware is Omit<ShiftException, 'id'> =>
  Object.hasOwn(periodAware, 'shiftId')

export const isEvent = (
  periodAware: Omit<PeriodAware, 'id'>,
): periodAware is Omit<ScheduleEvent, 'id'> => !isException(periodAware)

const getPeriod = ({
  effectivePeriod: { start, end },
}: PeriodAware): DefinedRangeInterface<Date> => [start, end]

type GetShift = (
  shifts: Shift[],
) => (exception: Omit<ShiftException, 'id'>) => Shift | null

export const getShiftOfException: GetShift = shifts => exception =>
  shifts.find(shift => shift.id === exception.shiftId) ?? null

const getWeekdays =
  (getExceptionShift: ReturnType<GetShift>) =>
  (periodAware: Omit<PeriodAware, 'id'>) => {
    if (isException(periodAware)) {
      const shift = getExceptionShift(periodAware)
      if (!shift) return []

      return shift.weekdays
    }

    if (isEvent(periodAware)) {
      return periodAware.weekdays
    }

    return []
  }

export const getPeriodAwareWeekdays = compose(getWeekdays, getShiftOfException)

export const getTimeRange = (periodAware: Omit<PeriodAware, 'id'>) => {
  if (isException(periodAware) && periodAware.shiftConfig) {
    return [
      fromTime(periodAware.shiftConfig.arrivalTimes.firstArrivalTime),
      fromTime(periodAware.shiftConfig.arrivalTimes.lastArrivalTime),
    ] as DefinedRangeInterface<Date>
  }

  if (isEvent(periodAware)) {
    return shiftTimeRange(periodAware)
  }

  return null
}

const getTimeIntersection = (
  timeRangeA: DefinedRangeInterface<Date> | null,
  timeRangeB: DefinedRangeInterface<Date> | null,
) => {
  if (!timeRangeA || !timeRangeB) return null

  return timeRangeService.getIntersection(timeRangeA, timeRangeB)
}

type PartialFormData = Partial<FormException> | Partial<FormEvent>

const formDataToIntersectable = (
  fd: PartialFormData,
): IntersectableScheduleItemWithPeriod | null => {
  const { firstArrivalTime, lastArrivalTime, weekdays, effectivePeriod } = fd

  if (!firstArrivalTime || !lastArrivalTime || !weekdays || !effectivePeriod)
    return null

  if ((fd as Partial<FormException>).shiftDisabled) return null

  return {
    arrivalTimes: [fromTime(firstArrivalTime), fromTime(lastArrivalTime)],
    effectivePeriod,
    weekdays,
  }
}

export const getPeriodAwareIntersection =
  (shifts: Shift[]) =>
  <T extends PeriodAware>(testItem: PartialFormData) =>
  (item: T): EventOverlap | ExceptionOverlap | null => {
    const intersectable = formDataToIntersectable(testItem)

    if (!intersectable) return null

    const conflictingTimeRange = getTimeIntersection(
      intersectable.arrivalTimes,
      getTimeRange(item),
    )

    if (!conflictingTimeRange) return null

    const conflictingDateRange = timeRangeService.getIntersection(
      intersectable.effectivePeriod,
      getPeriod(item),
    )

    if (!conflictingDateRange) return null

    const conflictingWeekdays = getWeekdaysIntersection(conflictingDateRange)(
      intersectable.weekdays,
      getPeriodAwareWeekdays(shifts)(item),
    )

    if (!conflictingWeekdays.length) return null

    if (isException(item)) {
      return {
        type: 'exception',
        exception: item,
        conflictingDateRange,
        conflictingTimeRange,
        conflictingWeekdays,
      }
    }

    return {
      type: 'event',
      event: item,
      conflictingDateRange,
      conflictingTimeRange,
      conflictingWeekdays,
    }
  }
