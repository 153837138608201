/* eslint-disable react-hooks/rules-of-hooks */
import { assoc, compose, equals, filter, find, groupWith, useWith } from 'ramda'

import { hasIdRelationBy } from 'src/shared/lib/common/services/functional/functional'
import { areInsideArea, getAreaRoom } from '../../area/services/area'
import { type AreaInterface } from '../../area/types/area'
import { type RoomInterface } from '../../room/types/room'
import {
  type TableInterface,
  type TableOccupancyInterface,
} from '../types/table'

export const areOccupyingTable = hasIdRelationBy('tableId')

export const occupies =
  (table: TableInterface) =>
  <T extends { tableId: TableInterface['id'] }>(occupancy: T) =>
    table.id === occupancy.tableId

export const getOccupanciesTables =
  (tables: TableInterface[]) => (occupancies: TableOccupancyInterface[]) =>
    filter(areOccupyingTable(occupancies), tables)

export const getOccupancyTable =
  (tables: TableInterface[]) => (occupancy: TableOccupancyInterface) =>
    find(areOccupyingTable([occupancy]), tables)

export const getTableArea =
  (areas: AreaInterface[]) => (table: TableInterface | undefined) => {
    if (!table) return undefined

    return find(areInsideArea([table]), areas)
  }

export const areInSameRoom = (areas: AreaInterface[]) => {
  const getTableRoomId = (table: TableInterface | undefined) =>
    getTableArea(areas)(table)?.roomId

  return useWith(equals, [getTableRoomId, getTableRoomId])
}

export const groupByRoom =
  (areas: AreaInterface[]) =>
  (tables: TableInterface[]): TableInterface[][] =>
    groupWith(areInSameRoom(areas), tables)

export const getTableRoom = (areas: AreaInterface[], rooms: RoomInterface[]) =>
  compose(getAreaRoom(rooms), getTableArea(areas))

export const areInSameRestaurant = (
  areas: AreaInterface[],
  rooms: RoomInterface[],
) => {
  const getTableRestaurantId = (table: TableInterface | undefined) =>
    getTableRoom(areas, rooms)(table)?.restaurantId

  return useWith(equals, [getTableRestaurantId, getTableRestaurantId])
}

export const groupByRestaurant =
  (areas: AreaInterface[], rooms: RoomInterface[]) =>
  (tables: TableInterface[]): TableInterface[][] =>
    groupWith(areInSameRestaurant(areas, rooms), tables)

export const setName = assoc('name')
