import type React from 'react'
import { useEffect, useState } from 'react'

import type Konva from 'konva'

import { type SizeInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'

export const useKonvaShapeSize = (
  ref: React.RefObject<Konva.Shape | undefined>,
  displayedValue: unknown,
): SizeInterface => {
  const [textSize, setTextSize] = useState<SizeInterface>({
    width: 0,
    height: 0,
  })
  useEffect(() => {
    if (ref.current)
      setTextSize({
        width: ref.current.width(),
        height: ref.current.height(),
      })
  }, [ref, displayedValue])

  return textSize
}
