import { createFileRoute, isRedirect, redirect } from '@tanstack/react-router'

import { ensureMe } from 'src/entities/me/queries/me'

export const Route = createFileRoute('/restaurants')({
  beforeLoad: async ({ context: { queryClient }, location, matches }) => {
    try {
      const {
        defaultRestaurant: { id },
      } = await ensureMe(queryClient)

      if (matches.at(-1)?.fullPath !== '/restaurants') return

      throw redirect({
        to: '/restaurants/$restaurantId/reservations',
        params: { restaurantId: id },
      })
    } catch (maybeRedirect) {
      if (isRedirect(maybeRedirect)) throw maybeRedirect

      throw redirect({ to: '/login', search: { redirect: location.href } })
    }
  },
})
