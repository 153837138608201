import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type LabelInterface } from 'src/entities/label/types/label'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'

const Name = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<LabelInterface>()

  const { t } = useTranslation('', { keyPrefix: 'settings.labels' })

  return (
    <FormTextInput
      required
      {...register('name', { required: true })}
      errorText={errors?.name?.message}
      label={t('nameLabel')}
    />
  )
}

export default Name
