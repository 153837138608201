import * as dateLib from 'src/shared/lib/range/services/date'
import { isInFuture } from 'src/shared/lib/range/services/date'
import * as guestFactory from './guestFactory'
import * as reservationLib from './reservation'
import * as reservationFactory from './reservationFactory'
import type { AreaInterface } from '../../area/types/area'
import type { ServiceTimeInterface } from '../../config/types/configApi'
import type { HasFeedbackEnabled } from '../../customer/queries/customer'
import { type IsFeatureOn } from '../../info/hooks/useFeature'
import type { RestaurantInfo } from '../../info/types/info'
import { createNoRoom } from '../../room/services/roomFactory'
import { type RoomInterface } from '../../room/types/room'
import { getServiceTimeSlots } from '../../service-time/services/serviceTime'
import type { IsSettingOn } from '../../setting/queries/settings'

interface ProposeFeedback {
  hasFeedbackEnabled: HasFeedbackEnabled
  isFeatureOn: IsFeatureOn
  isSettingOn: IsSettingOn
  getNow: () => Date
}

const proposeFeedback = (d: ProposeFeedback) =>
  reservationFactory.getInitialFeedback({
    hasFeedbackEnabled: d.hasFeedbackEnabled,
    isFeedbackFeatureOn: () => d.isFeatureOn('feedback'),
    isFeedbackEmailEnabled: () => d.isSettingOn('feedbackEmail'),
  })

interface ProposeReminder {
  isSettingOn: IsSettingOn
  isFeatureOn: IsFeatureOn
  getNow: () => Date
  getReminderSettings: () => RestaurantInfo['reminderData']
}

const proposeReminder = (d: ProposeReminder) =>
  reservationFactory.getInitialReminder({
    isReminderEmailEnabled: () => d.isSettingOn('reminderEmail'),
    isReminderFeatureOn: () => d.isFeatureOn('reminder'),
    hasReminderEnabled: c => !!c?.emails?.length,
    isReminderPossible: reservationLib.isReminderPossible(
      d.getNow,
      d.getReminderSettings,
    ),
  })

interface ChangeCustomer {
  hasFeedbackEnabled: HasFeedbackEnabled
  isFeatureOn: IsFeatureOn
  isSettingOn: IsSettingOn
  getNow: () => Date
  getReminderSettings: () => RestaurantInfo['reminderData']
}

export const changeCustomer = (d: ChangeCustomer) =>
  reservationFactory.setCustomerToReservation({
    createGuestFromCustomer: guestFactory.guestFromCustomer,
    proposeFeedback: proposeFeedback(d),
    proposeReminder: proposeReminder(d),
  })

interface InitializeReservation {
  hasFeedbackEnabled: HasFeedbackEnabled
  isFeatureOn: IsFeatureOn
  isSettingOn: IsSettingOn
  getNow: () => Date
  getSelectedDate: () => Date
  getReminderSettings: () => RestaurantInfo['reminderData']
  getSelectedServiceTime: () => ServiceTimeInterface | undefined
  getSelectedArea: () => AreaInterface | undefined
  getAreaRoom: (a: AreaInterface) => RoomInterface | undefined
  getRestaurantHash: () => string
  getDefaultDuration: () => number
}

export const initializeReservation = (d: InitializeReservation) =>
  reservationFactory.initializeReservation({
    proposeStartTime: reservationFactory.getBestStartTime({
      getNow: d.getNow,
      moveToDate: datePart => timePart =>
        dateLib.combineDateWithTime(datePart, timePart),
      roundToNearestSlot: dateLib.roundToNearest('minutes', 15, Math.ceil),
      getPossibleTimeSlots: getServiceTimeSlots(d.getSelectedServiceTime),
      isInFuture: isInFuture('minute'),
    }),
    proposeFeedback: proposeFeedback(d),
    proposeReminder: proposeReminder(d),
    proposeRoom: reservationFactory.getDefaultRoom({
      getDefaultArea: d.getSelectedArea,
      getAreaRoom: a => {
        const noRoom = createNoRoom(d.getRestaurantHash())

        if (!a) return noRoom

        return d.getAreaRoom(a) ?? noRoom
      },
      isRoomToGuestFeatureOn: () => d.isFeatureOn('roomToGuest'),
    }),
    createGuestFromCustomer: guestFactory.guestFromCustomer,
    getRestaurantHash: d.getRestaurantHash,
    getSelectedDate: d.getSelectedDate,
    getDefaultDuration: d.getDefaultDuration,
  })
