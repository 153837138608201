import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useScheduleQuery } from 'src/entities/schedule/queries/schedule'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import PaymentPlanSelect from './components/PaymentPlanSelect/PaymentPlanSelect'
import FormSection from '../../../FormSection'
import { useBaseFormContext } from '../../hooks/form'
import { sectionKeyToLabel } from '../FormNavigation/FormNavigation'

const PaymentsSection = () => {
  const { t } = useTranslation()
  const {
    data: { paymentPlans },
  } = useScheduleQuery()

  const { watch, register, getValues, setValue } = useBaseFormContext()
  const noShowFeeEnabled = watch('noShowFee.isActive')

  useEffect(() => {
    if (
      !noShowFeeEnabled ||
      !!getValues('noShowFee.paymentPlanId') ||
      !paymentPlans[0]
    )
      return

    setValue('noShowFee.paymentPlanId', paymentPlans[0].id)
  }, [getValues, noShowFeeEnabled, paymentPlans, setValue])

  return (
    <FormSection
      title={sectionKeyToLabel(t)('payments')}
      tooltipText={t(
        'schedule.shifts.payments_section.tooltipText',
        'Pick payment plan to charge guest when they don’t appear.',
      )}
    >
      <Toggle
        label={t(
          'schedule.shifts.payments_section.enabled_toggle_label',
          'Charge guests for not showing',
        )}
        {...register('noShowFee.isActive')}
      />
      {noShowFeeEnabled && <PaymentPlanSelect />}
    </FormSection>
  )
}

export default PaymentsSection
