import { useMutation } from '@tanstack/react-query'

import authorizeTeamMember from 'src/entities/team-member/api/teamMemberApi'
import { type TeamMember } from 'src/entities/team-member/types/teamMember'
import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'

interface MutationProps {
  teamMember: TeamMember
  pinCode: string
}

export const useLoginTeamMemberWithPinMutation = () => {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: ({ pinCode, teamMember }: MutationProps) =>
      authorizeTeamMember(apiClient.post)(teamMember.id, pinCode),
  })
}
