import React, { Suspense, useEffect, useState } from 'react'
import { Stack } from '@mui/material'

import { useNavigate } from '@tanstack/react-router'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { type CustomerInterface } from 'src/entities/customer/types/customer'
import CircularProgress from 'src/shared/components/common/CircularProgress'
import CenteredBox from 'src/shared/components/containers/CenteredBox'
import { useReservationApiFilters } from './context/searchFilters'
import { type CustomerSearchProps } from './Customer'
import Header from './Header'
import { type ReservationSearchProps } from './Reservation'
import SearchTabs, { SearchTabsEnum } from './SearchTabs'

const Customer = React.lazy(() => import('./Customer'))
const Reservation = React.lazy(() => import('./Reservation'))
const Filters = React.lazy(() => import('./Reservation/Filters'))

const Search = ({
  restaurantId,
  searchPhrase: initialSearchPhrase = '',
  searchTab: initialSearchTab = SearchTabsEnum.Reservations,
  onCreateReservation,
  onCreateNewCustomer,
  onCustomerClick,
  onReservationEdit,
  onClose,
}: Omit<CustomerSearchProps, 'onCreateReservation'> &
  ReservationSearchProps & {
    restaurantId: number
    onClose: () => void
    searchTab: SearchTabsEnum
    onCreateReservation: (customer: CustomerInterface) => void
  }) => {
  const [selectedView, setSelectedView] = useState(initialSearchTab)
  const navigate = useNavigate()

  const [searchPhrase, setSearchPhrase] = useState(initialSearchPhrase)
  const { setSearchPhrase: setGlobalSearchPhrase } = useReservationApiFilters()

  useEffect(() => {
    setGlobalSearchPhrase(searchPhrase)
    void navigate({
      replace: true,
      to: '/restaurants/$restaurantId/search',
      params: { restaurantId },
      search: { tab: selectedView, ...(searchPhrase && { q: searchPhrase }) },
    })
  }, [
    navigate,
    restaurantId,
    searchPhrase,
    selectedView,
    setGlobalSearchPhrase,
  ])

  return (
    <Stack
      {...createTestIdProps('search')}
      direction="column"
      spacing={2}
      sx={{
        px: 6,
        py: 3,
        pb: 0,
        overflow: 'hidden',
        width: 1,
      }}
    >
      <Header
        onSearchPhraseChange={setSearchPhrase}
        initialSearchPhrase={initialSearchPhrase}
        onClose={onClose}
      />
      <SearchTabs
        selectedView={selectedView}
        setSelectedView={setSelectedView}
      />
      {selectedView === SearchTabsEnum.Reservations && (
        <Stack
          spacing={1}
          direction="column"
          sx={{ overflow: 'hidden' }}
          {...createTestIdProps('reservations')}
        >
          <Suspense
            fallback={
              <CenteredBox>
                <CircularProgress />
              </CenteredBox>
            }
          >
            <Filters />
            <Suspense
              fallback={
                <CenteredBox>
                  <CircularProgress />
                </CenteredBox>
              }
            >
              <Reservation onReservationEdit={onReservationEdit} />
            </Suspense>
          </Suspense>
        </Stack>
      )}
      {selectedView === SearchTabsEnum.Customers && (
        <Suspense
          fallback={
            <CenteredBox>
              <CircularProgress />
            </CenteredBox>
          }
        >
          {searchPhrase && (
            <Customer
              onCustomerClick={onCustomerClick}
              onCreateReservation={onCreateReservation}
              searchPhrase={searchPhrase}
              onCreateNewCustomer={onCreateNewCustomer}
            />
          )}
        </Suspense>
      )}
    </Stack>
  )
}

export default Search
