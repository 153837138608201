import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type AreaInterface } from 'src/entities/area/types/area'
import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'

const AreaName = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<AreaInterface>()

  const { t } = useTranslation('', { keyPrefix: 'settings.areas' })

  return (
    <FormTextInput
      required
      {...register('name', { required: true })}
      errorText={errors?.name?.message}
      label={t('areaNameInput')}
    />
  )
}

export default AreaName
