import React, { type ComponentProps } from 'react'
import { compose } from 'ramda'
import { Box, Drawer } from '@mui/material'

import withLayoutBreakpoints from 'src/app/hoc/withLayoutBreakpoints'
import {
  NotificationTypeEnum,
  type NotificationInterface,
} from 'src/entities/notification/types/notification'
import { useEditReservation } from 'src/entities/reservation/hooks/useEditReservation'
import { useReservationData } from 'src/entities/reservation/queries/reservation'
import SwipeDrawer from 'src/shared/components/common/SwipeDrawer/SwipeDrawer'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { withPhoneCallNotificationsStackReducerProvider } from 'src/shared/lib/context/reducer/phoneCallsNotificationsStackReducer'
import Inbox from 'src/widgets/Inbox'
import BellButton from 'src/widgets/Inbox/BellButton'
import usePhoneCallSseEffect from './hooks/usePhoneCallSseEffect'
import useTodoItemCreatedSseEffect from './hooks/useTodoItemCreatedSseEffect'
import useTodoItemStatusChangeSseEffect from './hooks/useTodoItemStatusChangeSseEffect'
import useUnreadCount from './hooks/useUnreadCount'

interface NotificationsInboxProps {
  restaurantId: number
  onPhoneCallClick: (phoneNumber: string) => void
}

const SmDrawer = ({
  children,
  ...props
}: ComponentProps<typeof SwipeDrawer>) => (
  <Drawer
    anchor="right"
    hideBackdrop
    disableEnforceFocus
    disableAutoFocus
    PaperProps={{ sx: { pointerEvents: 'all' } }}
    sx={{ pointerEvents: 'none' }}
    {...props}
  >
    <Box sx={{ width: 400, height: '100%' }}>{children}</Box>
  </Drawer>
)

const XsDrawer = (props: ComponentProps<typeof SwipeDrawer>) => (
  <SwipeDrawer anchor="bottom" {...props} />
)

const DrawerComponent = withLayoutBreakpoints({
  xs: XsDrawer,
  sm: SmDrawer,
})

const NotificationsInbox = ({
  restaurantId,
  onPhoneCallClick,
}: NotificationsInboxProps) => {
  useTodoItemCreatedSseEffect()
  const { isUpdatingCache } = useTodoItemStatusChangeSseEffect()
  usePhoneCallSseEffect()

  const unreadCount = useUnreadCount()

  const { open, handleOpen, handleClose } = useDialog()

  const getReservationData = useReservationData()
  const editReservation = useEditReservation(restaurantId)

  const notificationClickHandler = React.useCallback(
    async (notification: NotificationInterface) => {
      if (notification.type === NotificationTypeEnum.TodoItemCreated) {
        const reservation = getReservationData(
          notification.payload.reservationId,
        )!

        if (reservation) await editReservation(reservation)
      }

      if (notification.type === NotificationTypeEnum.PhoneCall) {
        onPhoneCallClick(notification.payload.number)
        handleClose()
      }
    },
    [editReservation, onPhoneCallClick, getReservationData, handleClose],
  )

  return (
    <>
      <BellButton notificationsCount={unreadCount} onClick={handleOpen} />
      <DrawerComponent onClose={handleClose} open={open}>
        <Inbox
          onClose={handleClose}
          onNotificationClick={notificationClickHandler}
          isCacheUpdating={isUpdatingCache}
        />
      </DrawerComponent>
    </>
  )
}

export default compose(withPhoneCallNotificationsStackReducerProvider)(
  NotificationsInbox,
)
