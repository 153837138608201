import React from 'react'

import { useTranslation } from 'react-i18next'

import { useRoomsQuery } from 'src/entities/room/queries/room'
import { type RoomInterface } from 'src/entities/room/types/room'
import SingleSelectDropdown, {
  type SingleSelectDropdownProps,
} from 'src/shared/components/form/inputs/SingleSelectDropdown'

interface RoomChoiceProps
  extends Omit<
    SingleSelectDropdownProps<RoomInterface, string>,
    'onChange' | 'value' | 'renderOption' | 'options'
  > {
  onChange: (value: number) => void
  value: number
}

const RoomChoice = React.forwardRef<HTMLInputElement, RoomChoiceProps>(
  ({ value, onChange, ...props }, ref) => {
    const { t } = useTranslation('', { keyPrefix: 'settings.areas' })
    const { data: rooms = [] } = useRoomsQuery()

    const label = t('roomChoice')

    const selectedRoom = React.useMemo(
      () => rooms.find(room => room.id === value),
      [rooms, value],
    )
    const changeHandler = React.useCallback(
      (room: (typeof rooms)[number]) => {
        onChange(room.id)
      },
      [onChange],
    )

    return (
      <SingleSelectDropdown
        {...props}
        label={label}
        value={selectedRoom}
        onSelect={changeHandler}
        ref={ref}
        options={rooms}
        renderOption={room => (room.id === 0 ? t('emptyRoom') : room.name)}
      />
    )
  },
)

export default RoomChoice
