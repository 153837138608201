import { useTranslation } from 'react-i18next'

import FormTextInput from 'src/shared/components/form/inputs/FormTextInput'
import { useEventShiftFormContext } from '../../../hooks/form'

interface NameProps {
  label: string
}

const Name = ({ label }: NameProps) => {
  const { t } = useTranslation()

  const { register, formState } = useEventShiftFormContext()

  return (
    <FormTextInput
      {...register('name')}
      errorText={formState.errors.name?.message}
      label={label}
      placeholder={t(
        'schedule.shifts.general_section.name.placeholder',
        'eg. Lunch',
      )}
    />
  )
}

export default Name
