import React from 'react'
import { ascend, compose, descend, sortWith } from 'ramda'

import {
  useReservations,
  useReservationsSearchQuery,
} from 'src/entities/reservation/queries/reservation'
import {
  getNumber,
  getStartDate,
} from 'src/entities/reservation/services/reservation'
import { toTimestamp } from 'src/shared/lib/range/services/date'
import {
  FiltersEnum,
  type ReservationApiFilters,
} from 'src/shared/lib/reducer/service/reservationsFilter'

export const useCustomerReservations = (
  filters: Partial<ReservationApiFilters>,
) => {
  const { data: reservationsIds } = useReservationsSearchQuery({
    ...filters,
    [FiltersEnum.wholeGroup]: true,
  })

  const reservations = useReservations(reservationsIds)

  return React.useMemo(
    () =>
      sortWith(
        [descend(compose(toTimestamp, getStartDate)), ascend(getNumber)],
        reservations,
      ),
    [reservations],
  )
}
