import { Typography } from '@mui/material'

import type { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { type NotificationTemplate } from 'src/entities/schedule/types/scheduleApi'
import NotificationSelect from './components/NotificationSelect/NotificationSelect'
import type { BaseForm } from '../../../../../../services/formSchema'

type Notification = BaseForm['notifications']['templates'][number]

interface NotificationItemProps {
  type: Notification['type']
  notifications: Notification[]
  templates: NotificationTemplate[]
  channels: Notification['channel'][]
  onNotificationChange: (
    channel: Notification['channel'],
    type: Notification['type'],
    notification: Notification | null,
  ) => void
}

const translateType = (t: TFunction) => (type: Notification['type']) =>
  t(`schedule.shifts.notifications_section.notification_type.${type}`, type)

const isOfType = (type: Notification['type']) => (notification: Notification) =>
  notification.type === type

const NotificationItem = ({
  type,
  notifications,
  templates,
  channels,
  onNotificationChange,
}: NotificationItemProps) => {
  const { t } = useTranslation()

  const typeNotifications = notifications.filter(isOfType(type))
  const typeTemplates = templates.filter(isOfType(type))

  return (
    <>
      <Typography variant="body2" color="grey.900">
        {translateType(t)(type)}
      </Typography>
      {channels.map(channel => (
        <NotificationSelect
          key={channel}
          channel={channel}
          notifications={typeNotifications}
          onNotificationChange={n => onNotificationChange(channel, type, n)}
          templates={typeTemplates}
        />
      ))}
    </>
  )
}

export default NotificationItem
