import React from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type CustomerInterface } from 'src/entities/customer/types/customer'
import { type GuestInterface } from 'src/entities/reservation/types/reservation'
import CloseButton from 'src/shared/components/buttons/CloseButton'
import Badge from 'src/shared/components/common/Badge'
import GuestName from './GuestName/GuestName'
import { GuestsButton } from './GuestsButton/GuestsButton'

interface GuestButtonProps {
  onChoiceOpen: () => void
  onCustomerChange: (customer: undefined) => void
  onPreviewOpen: (customerId: CustomerInterface['id']) => void
  guest: GuestInterface
}

const GuestButton = ({
  onChoiceOpen,
  onCustomerChange,
  onPreviewOpen,
  guest,
}: GuestButtonProps) => {
  const [disableMainRipple, setDisableMainRipple] = React.useState(false)

  const guestExists = !!guest.customerId

  const hasAnyData = guest.firstName || guest.lastName || guest.companyName
  const isWalkIn =
    guest.lastName === 'Walk-In' && (!guest.firstName || !guest.companyName)

  const guestIsSelected = guestExists || (hasAnyData && !isWalkIn)

  const { t } = useTranslation()

  return (
    <Button
      variant="color-outlined"
      endIcon={
        guestIsSelected ? (
          <CloseButton
            component={Box}
            onMouseEnter={() => setDisableMainRipple(true)}
            onMouseLeave={() => setDisableMainRipple(false)}
            onClick={e => {
              e.stopPropagation()
              onCustomerChange(undefined)
            }}
          />
        ) : (
          <GuestsButton />
        )
      }
      fullWidth
      disableRipple={disableMainRipple}
      onClick={() => {
        if (!guestIsSelected) {
          onChoiceOpen()
          return
        }

        if (!guest.customerId) return

        onPreviewOpen(guest.customerId)
      }}
      sx={{
        bgcolor: 'white',
        justifyContent: 'space-between',
        height: 1,
        p: 1,
      }}
    >
      {guestIsSelected ? (
        <Stack gap={0.5}>
          <GuestName guest={guest} />
          {!guestExists && (
            <Badge size="small" maxWidth="max-content">
              {t('customer.no_crm_profile', 'No CRM Profile')}
            </Badge>
          )}
        </Stack>
      ) : (
        <Typography variant="body3">
          {t('angular.enter_select_guest')}
        </Typography>
      )}
    </Button>
  )
}

export default GuestButton
