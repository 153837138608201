import React from 'react'

const useResizeObserverRef = (callback: ResizeObserverCallback) => {
  const observerRef = React.useRef(new ResizeObserver(callback))

  React.useEffect(() => {
    const ro = observerRef.current
    return () => ro.disconnect()
  }, [])

  return observerRef
}

export default useResizeObserverRef
