import React, { Fragment } from 'react'
import { lensPath, set, view } from 'ramda'

import { type TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import NumericInput from 'src/shared/components/form/inputs/NumericInput/NumericInput'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import type { FormChannelAllocation } from '../../../services/formSchema'

export const translateChannelName =
  (t: TFunction) => (channel: FormChannelAllocation['channel']) =>
    ({
      book: t('allocation.channel_name.book', 'Manual'),
      online: t('allocation.channel_name.online', 'Widget/API'),
      google: t('allocation.channel_name.google', 'Google'),
    })[channel]

const channelLimitLens = lensPath<FormChannelAllocation, number>(['limit'])
const channelActiveLens = lensPath<FormChannelAllocation, boolean>(['active'])

interface ChannelLimitProps {
  allocation: FormChannelAllocation
  onAllocationChange: (newAllocation: FormChannelAllocation) => void
  limit: number
}

const ChannelLimit = ({
  allocation,
  onAllocationChange,
  limit,
}: ChannelLimitProps) => {
  const { t } = useTranslation()
  const channelName = translateChannelName(t)(allocation.channel)

  return (
    <Fragment key={allocation.channel}>
      <Toggle
        label={channelName}
        checked={allocation.active}
        onChange={e =>
          onAllocationChange(
            set(channelActiveLens, e.target.checked, allocation),
          )
        }
      />
      <NumericInput
        minValue={0}
        sx={{
          maxWidth: 80,
          visibility: allocation.active ? 'visible' : 'hidden',
        }}
        maxValue={limit}
        value={view(channelLimitLens, allocation)}
        onChange={React.useCallback(
          newLimit => {
            onAllocationChange(set(channelLimitLens, newLimit, allocation))
          },
          [onAllocationChange, allocation],
        )}
      />
    </Fragment>
  )
}

export default ChannelLimit
