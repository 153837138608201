import React from 'react'
import { type ChipProps } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import { type LabelInterface } from 'src/entities/label/types/label'
import Label from 'src/shared/components/common/Label'

interface MediumLabelProps extends Omit<ChipProps, 'label'> {
  label: LabelInterface
}

const MediumLabel = React.forwardRef<HTMLDivElement, MediumLabelProps>(
  ({ label, sx, ...props }, fwdRef) => (
    <Label
      color={label.color}
      label={label.name}
      ref={fwdRef}
      sx={mergeSx(
        {
          typography: 'labelSmall',
          py: 1,
          px: 2,
          m: 0.25,
          height: 32,
        },
        sx,
      )}
      {...props}
    />
  ),
)

export default MediumLabel
