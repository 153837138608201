import { useChannel } from 'ably/react'

import {
  NotificationTypeEnum,
  type SseEventMap,
} from 'src/entities/notification/types/notification'
import { fromEventData } from './sseMessageNotificationFactory'
import { captureException } from '../../context/global/sentry'
import { useRestaurantHash } from '../../context/global/useRestaurantHash'

export interface SseEvent<T extends NotificationTypeEnum> {
  data: SseEventMap[T]
}

type SseEventListener<T extends NotificationTypeEnum> = (
  eventName: T,
  handler: (event: SseEvent<T>) => void,
) => void

export const useAblyChannelName = () => {
  const restaurantHash = useRestaurantHash()

  return `${import.meta.env.PUBLIC_ABLY_CHANNEL_NAMESPACE}:${restaurantHash}`
}

const useSseEventListenerEffect = <K extends NotificationTypeEnum>(
  ...[eventName, handler]: Parameters<SseEventListener<K>>
) =>
  useChannel(useAblyChannelName(), eventName, msg => {
    try {
      const { name, data } = msg

      if (!Object.values(NotificationTypeEnum).includes(name)) return

      handler(fromEventData(name, data))
    } catch (error) {
      captureException(error)
    }
  })

export default useSseEventListenerEffect
