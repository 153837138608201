import React, { useCallback } from 'react'

import {
  useQueryClient,
  useSuspenseInfiniteQuery,
  useSuspenseQuery,
} from '@tanstack/react-query'

import { prependInfiniteNotificationData } from 'src/pages/Reservations/components/TopBar/components/NotificationsInbox/service/notifications'
import { replacePaginatedData } from 'src/pages/Reservations/components/TopBar/components/NotificationsInbox/service/paginatedQueryCache'
import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { useOptimisticUpdate } from 'src/shared/lib/api/queries/optimisticUpdate'
import { useRestaurantCacheKey } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import {
  addCursorPaginationOld,
  defaultPaginationParamsOld,
} from 'src/shared/lib/api/services/reservationsBookApiClient'
import { getUnreadCount } from '../api/todoApi'
import {
  getNotificationHistory,
  type NotificationStatusFilter,
} from '../api/todoEventApi'
import {
  TodoItemStatus,
  type TodoItemNotificationInterface,
} from '../types/notification'

export const NOTIFICATIONS_CACHE_KEY = ['notifications']
export const useNotificationsCacheKey = (status: NotificationStatusFilter) =>
  useRestaurantCacheKey(
    React.useMemo(() => [...NOTIFICATIONS_CACHE_KEY, status], [status]),
  )

export const useNotificationsQuery = (status?: NotificationStatusFilter) => {
  const apiClient = useApiClient()
  const cacheKey = useNotificationsCacheKey(status ?? 'all')

  return useSuspenseInfiniteQuery({
    queryKey: cacheKey,
    initialPageParam: defaultPaginationParamsOld,
    queryFn: ({ pageParam }) =>
      getNotificationHistory(addCursorPaginationOld(pageParam)(apiClient.post))(
        status,
      ),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 5 * 60 * 1000,
    getNextPageParam: lastPage =>
      Array.isArray(lastPage) && lastPage.length > 0
        ? {
            searchAfter: lastPage.at(-1)?.id,
          }
        : undefined,
    getPreviousPageParam: firstPage =>
      Array.isArray(firstPage) && firstPage.length > 0
        ? {
            searchBefore: firstPage.at(0)?.id,
          }
        : undefined,
  })
}

export const useHandleIncomingNotification = () => {
  const queryClient = useQueryClient()
  const allCacheKey = useNotificationsCacheKey('all')
  const openCacheKey = useNotificationsCacheKey('open')

  const updateCache = useOptimisticUpdate()

  return useCallback(
    (notification: TodoItemNotificationInterface) => {
      const openEventsFetched = queryClient.getQueryData(openCacheKey)
      const allEventsFetched = queryClient.getQueryData(allCacheKey)

      if (!openEventsFetched && !allEventsFetched) return

      if (
        notification.payload &&
        'status' in notification.payload &&
        notification.payload.status === TodoItemStatus.Open
      ) {
        if (!openEventsFetched) return

        updateCache(
          openCacheKey,
          replacePaginatedData(prependInfiniteNotificationData(notification)),
        )
      }

      if (!allEventsFetched) return

      updateCache(
        allCacheKey,
        replacePaginatedData(prependInfiniteNotificationData(notification)),
      )
    },
    [allCacheKey, openCacheKey, queryClient, updateCache],
  )
}
export const UNREAD_COUNT_CACHE_KEY = ['unreadNotificationsCount']
export const useUnreadCountCacheKey = () =>
  useRestaurantCacheKey(UNREAD_COUNT_CACHE_KEY)

export const useUnreadCountQuery = () => {
  const apiClient = useApiClient()
  const cacheKey = useUnreadCountCacheKey()

  return useSuspenseQuery({
    queryKey: cacheKey,
    queryFn: getUnreadCount(apiClient.post),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 5 * 60 * 1000,
    refetchOnMount: 'always',
  })
}
