import { createContext, useContext } from 'react'

import { noop } from '@tanstack/react-table'

import { type useSimpleDialog } from '../../../components/dialogs/SimpleDialog/useSimpleDialog'

interface GlobalModalContextFunctions {
  showTeamMembersModal: () => void
  closeTeamMembersModal: () => void
  showPermissionModal: ReturnType<typeof useSimpleDialog>['showSimpleDialog']
}

export const GlobalModalContext = createContext<GlobalModalContextFunctions>({
  showTeamMembersModal: noop,
  closeTeamMembersModal: noop,
  showPermissionModal: noop,
})

export const useGlobalModalContext = () => useContext(GlobalModalContext)
