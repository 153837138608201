import { Box } from '@mui/material'

import { getColumnNumber } from 'src/pages/ReservationDrawer/TimeSelection/SlotPicker/SlotChoices/TimeRangeSlots/TimeslotChoiceItem'
import SelectionButton from 'src/shared/components/buttons/SelectionButton'
import { formatTime } from 'src/shared/lib/range/services/date'

interface ItemProps {
  time: Date
  onSelect: (date: Date) => void
  selected: boolean
}

const Item = ({ time, onSelect, selected }: ItemProps) => (
  <Box
    sx={{
      gridColumn: getColumnNumber(time),
    }}
  >
    <SelectionButton
      label={formatTime(time)}
      onClick={() => onSelect(time)}
      selected={selected}
    />
  </Box>
)

export default Item
