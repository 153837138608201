import { Box, Typography, type ButtonProps } from '@mui/material'

import error from 'src/app/theme/colors/error'
import neutral from 'src/app/theme/colors/neutral'
import primary from 'src/app/theme/colors/primary'
import success from 'src/app/theme/colors/success'
import warning from 'src/app/theme/colors/warning'
import { mergeSx } from 'src/app/theme/helpers'
import { generateColorPalette } from 'src/app/theme/palette/generateColorPalette'
import { type TeamMember } from 'src/entities/team-member/types/teamMember'
import LockedPadlockIcon from 'src/shared/components/icons/LockedPadlockIcon'
import { getEmoji } from 'src/shared/lib/string/services/text'

const mapEnumColorToPalette = (color: string) => {
  switch (color) {
    case 'red':
      return error
    case 'blue':
      return primary
    case 'pink':
      return {
        100: '#EFDCF5',
        300: '#F4ACFB',
        500: '#E249EC',
        700: '#B921BC',
        900: '#4D054C',
      }
    case 'yellow':
      return warning
    case 'green':
      return success
    case 'white':
      return neutral
    default:
      return primary
  }
}

const getInitials = ({ firstName, lastName }: TeamMember) => {
  const emoji = getEmoji(`${firstName} ${lastName}`)
  if (emoji) return emoji

  const firstNameFirstLetter = firstName.slice(0, 1)

  if (!lastName) return firstNameFirstLetter
  const lastNameFirstLetter = lastName?.slice(0, 1)

  return firstNameFirstLetter + lastNameFirstLetter
}

interface TeamMemberCircleProps extends Omit<ButtonProps, 'color'> {
  teamMember: TeamMember
  showPadlock?: boolean
  small?: boolean
}

export const TeamMemberCircle = ({
  teamMember,
  small,
  showPadlock,
  children,
  ...props
}: TeamMemberCircleProps) => {
  const { color } = teamMember

  const palette = color.startsWith('#')
    ? generateColorPalette(color)
    : mapEnumColorToPalette(color)

  return (
    <Box
      component="button"
      variant="contained"
      {...props}
      sx={mergeSx(
        {
          cursor: 'pointer',
          background: 'none',
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: small ? 0 : 2,
          transition: theme =>
            theme.transitions.create(['background-color'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.standard,
            }),
          ':hover': {
            backgroundColor: small ? 'transparent' : 'grey.25',
          },
          '.team-member-circle': {
            borderRadius: 100,
            width: small ? 36 : 64,
            height: small ? 36 : 64,
            p: small ? 0 : 2,
            background: palette['100'],
            color: palette['700'],
            transition: theme =>
              theme.transitions.create(['background-color', 'padding'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.standard,
              }),
          },
          ':hover .team-member-circle': {
            background: palette['300'],
          },
          ':active .team-member-circle': {
            background: palette['500'],
          },
        },
        props.sx,
      )}
    >
      <Box
        className="team-member-circle"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <Typography
          variant={small ? 'labelSmall' : 'labelBig'}
          sx={{
            cursor: 'pointer',
            textAlign: 'center',
            lineHeight: 1,
            textTransform: 'uppercase',
          }}
        >
          {getInitials(teamMember)}
        </Typography>
        {showPadlock && (
          <LockedPadlockIcon
            variant="filled"
            size="small"
            sx={{
              position: 'absolute',
              bottom: small ? -2.5 : -2,
              right: small ? -2.5 : -2,
              backgroundColor: 'white',
              padding: small ? 0 : 0.5,
              borderRadius: '50%',
            }}
          />
        )}
      </Box>
      {children}
    </Box>
  )
}
