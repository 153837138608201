import React from 'react'
import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { usePaymentProviderQuery } from 'src/entities/payment/queries/paymentProvider'
import { useScheduleQuery } from 'src/entities/schedule/queries/schedule'
import type { PaymentPlan } from 'src/entities/schedule/types/scheduleApi'
import SingleSelectDropdown from 'src/shared/components/form/inputs/SingleSelectDropdown'
import { useCurrencyFormatter } from 'src/shared/lib/string/hooks/useCurrencyFormatter'
import { useBaseFormController } from '../../../../hooks/form'

const usePaymentAmount = (currency: string) => {
  const { t } = useTranslation()

  const currencyFormatter = useCurrencyFormatter()

  return React.useCallback(
    (p: PaymentPlan) =>
      t('schedule.shifts.payments_section.per_person', {
        amount: currencyFormatter(currency)(p.amountPerPerson),
        defaultValue: '{{amount}} per person',
      }),
    [t, currencyFormatter, currency],
  )
}

const PaymentPlanSelect = () => {
  const { t } = useTranslation()

  const { field } = useBaseFormController('noShowFee.paymentPlanId')

  const { data: paymentProvider } = usePaymentProviderQuery()

  const {
    data: { paymentPlans },
  } = useScheduleQuery()

  const paymentPlan = paymentPlans.find(p => p.id === field.value)

  const formatPaymentAmount = usePaymentAmount(paymentProvider?.currency ?? '')

  return (
    <SingleSelectDropdown
      value={paymentPlan ?? { id: null }}
      onSelect={p => field.onChange(p.id)}
      options={paymentPlans}
      disabled={!paymentPlans.length}
      fullWidth
      label={t(
        'schedule.shifts.payments_section.payment_select.label',
        'Payment plan',
      )}
      renderOption={p => {
        if (!p.id) {
          return t(
            'schedule.shifts.payments_section.payment_select.no_payment',
            'None',
          )
        }

        return (
          <Stack direction="row" spacing={1}>
            <Typography>{p.name}</Typography>
            <Typography color="grey.500">
              {formatPaymentAmount(p)} •{' '}
              {t(`payment_plan.type.${p.type}.name`, p.type)}
            </Typography>
          </Stack>
        )
      }}
      renderKey={p => p.id ?? 'none'}
    />
  )
}

export default PaymentPlanSelect
