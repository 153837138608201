import { Fragment } from 'react'
import { Button, Divider, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { type RoomInterface } from 'src/entities/room/types/room'
import CheckmarkIcon from 'src/shared/components/icons/CheckmarkIcon'

interface RoomSelectionSectionProps {
  rooms: RoomInterface[]
  selectedRoomId: number | undefined
  onRoomSelect: (room: RoomInterface) => void
}

export const RoomSelectionSection = ({
  rooms,
  selectedRoomId,
  onRoomSelect,
}: RoomSelectionSectionProps) => {
  const { t } = useTranslation()

  return (
    <Stack gap={0.5}>
      <Typography variant="labelExtrasmall" color="grey.500">
        {t('reservations_table.rooms')}
      </Typography>
      <Stack>
        {rooms.map(room => {
          const { id, name } = room

          const isSelected = selectedRoomId === id

          return (
            <Fragment key={id}>
              {rooms.length > 1 && id === 0 && (
                <Divider sx={{ borderColor: 'white' }} />
              )}
              <Button
                color="neutral"
                sx={{
                  textAlign: 'left',
                  justifyContent: 'space-between',
                  fontWeight: 400,
                  color: 'grey.900',
                  ':hover': {
                    backgroundColor: 'rgba(0,0,0,0.05)',
                  },
                }}
                onClick={() => onRoomSelect(room)}
                endIcon={isSelected && <CheckmarkIcon />}
              >
                <Typography
                  component="span"
                  sx={{
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    lineHeight: '1',
                    wordBreak: 'break-word',
                  }}
                >
                  {name ?? t('angular.no_room')}
                </Typography>
              </Button>
            </Fragment>
          )
        })}
      </Stack>
    </Stack>
  )
}
