import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { FakeLabel } from 'src/shared/components/form/common/FakeLabel/FakeLabel'
import FormErrorText from 'src/shared/components/form/common/FormErrorText/FormErrorText'
import MinutesPicker from 'src/shared/components/form/inputs/MinutesPicker/MinutesPicker'
import {
  addToDate,
  formatTime,
  fromTime,
  getEndOf,
  getNowInRestaurantTimezone,
} from 'src/shared/lib/range/services/date'
import Intersections from './components/Intersections'
import { useBaseFormController } from '../../../../hooks/form'
import type {
  EventOverlap,
  ExceptionOverlap,
} from '../../../../services/periodAwareValidation'
import type { ShiftOverlap } from '../../../../services/shiftValidation'

interface ArrivalTimeProps {
  intersections: (ShiftOverlap | EventOverlap | ExceptionOverlap)[]
}

const quaterToMidnight = addToDate(
  -15,
  'minutes',
  getEndOf('day')(getNowInRestaurantTimezone()),
)

const ArrivalTime = ({ intersections }: ArrivalTimeProps) => {
  const { t } = useTranslation()

  const {
    field: { value: firstArrivalTime, onChange: setFirstArrivalTime },
    fieldState: { error: firstArrivalTimeError },
  } = useBaseFormController('firstArrivalTime')

  const {
    field: { value: lastArrivalTime, onChange: setLastArrivalTime },
    fieldState: { error: lastArrivalTimeError },
  } = useBaseFormController('lastArrivalTime')

  const firstError =
    firstArrivalTimeError && firstArrivalTimeError.type !== 'custom'

  const lastError =
    lastArrivalTimeError && lastArrivalTimeError.type !== 'custom'

  const overlapError =
    firstArrivalTimeError?.type === 'custom' &&
    !!intersections.length &&
    firstArrivalTimeError

  return (
    <Stack spacing={0.5}>
      <FakeLabel>
        {t('schedule.shifts.general_section.arrival_time', 'Arrival time')}
      </FakeLabel>
      <Stack direction="row" gap={1}>
        <MinutesPicker
          onChange={newValue => {
            setFirstArrivalTime(newValue ? formatTime(newValue) : null)
          }}
          value={firstArrivalTime ? fromTime(firstArrivalTime) : null}
          maxValue={quaterToMidnight}
          error={firstError}
          helperText={firstError && t('common.validation.required')}
          fullWidth
          sx={{ maxWidth: 168 }}
        />
        <Typography mt={1.25}>–</Typography>
        <MinutesPicker
          onChange={newValue => {
            setLastArrivalTime(newValue ? formatTime(newValue) : null)
          }}
          value={lastArrivalTime ? fromTime(lastArrivalTime) : null}
          disabled={!firstArrivalTime}
          minValue={fromTime(firstArrivalTime)}
          error={lastError}
          helperText={lastError && t('common.validation.required')}
          fullWidth
          sx={{ maxWidth: 168 }}
        />
      </Stack>
      {overlapError && (
        <Stack gap={1.5}>
          <FormErrorText
            error={!!overlapError}
            errorText={
              overlapError.message?.startsWith('schedule.') &&
              t(overlapError.message, 'Overlap error')
            }
          />
          <Intersections intersections={intersections} />
        </Stack>
      )}
    </Stack>
  )
}

export default ArrivalTime
