import { Suspense } from 'react'
import { Button, Skeleton, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useCurrentDateAtom } from 'src/shared/lib/context/state/atoms/currentDate'
import {
  addToDate,
  areOnSameDay,
  getNowInRestaurantTimezone,
} from 'src/shared/lib/range/services/date'
import { TopBarDatePicker } from './TopBarDatePicker'

export const TopBarDate = () => {
  const { t } = useTranslation()

  const [date, setDate] = useCurrentDateAtom()

  return (
    <Stack
      direction="row"
      gap={1}
      sx={{
        flex: '1 1 30%',
      }}
    >
      <Stack
        direction="row"
        gap={0.25}
        sx={{ display: ['none', 'none', 'flex'] }}
      >
        <Button
          variant="color-outlined"
          onClick={() => setDate(getNowInRestaurantTimezone())}
          aria-selected={areOnSameDay(date, getNowInRestaurantTimezone())}
          sx={{ fontWeight: 'normal' }}
        >
          {t('angular.today')}
        </Button>
        <Button
          variant="color-outlined"
          onClick={() =>
            setDate(addToDate(1, 'day', getNowInRestaurantTimezone()))
          }
          aria-selected={areOnSameDay(
            date,
            addToDate(1, 'day', getNowInRestaurantTimezone()),
          )}
          sx={{ fontWeight: 'normal' }}
        >
          {t('angular.tomorrow')}
        </Button>
      </Stack>
      <Suspense
        fallback={<Skeleton variant="rectangular" width={284} height={40} />}
      >
        <TopBarDatePicker />
      </Suspense>
    </Stack>
  )
}
