import { useTranslation } from 'react-i18next'

import ToggleWithSubtitle from './components/ToggleWithSubtitle'
import { hasActiveLimits } from './services/confirmations'
import { useBaseFormContext } from '../../hooks/form'

const AfterLimit = ({ neverDisable }: { neverDisable?: boolean }) => {
  const { t } = useTranslation()

  const { watch } = useBaseFormContext()

  const allocations = watch('channelAllocations')

  const hasLimits = !neverDisable && hasActiveLimits(allocations)

  return (
    <ToggleWithSubtitle
      fieldName="reservationConfirmation.beyondPaxLimit"
      disabled={!neverDisable && !hasLimits}
      label={t(
        'schedule.shifts.confirmations_section.confirmations.after_limit.label',
        'Allow bookings with acceptance after channel’s guest limit is reached.',
      )}
      subtitle=""
    />
  )
}

export default AfterLimit
