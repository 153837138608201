import { Box } from '@mui/material'

import { type TodoItemNotificationInterface } from 'src/entities/notification/types/notification'
import Body from './Body'
import Button from './Button'

const TodoNotification = ({
  notification,
  onBodyClick,
}: {
  notification: TodoItemNotificationInterface
  onBodyClick: (notification: TodoItemNotificationInterface) => void
}) => (
  <Box sx={{ display: 'flex' }}>
    <Box
      sx={{
        flexGrow: 1,
        alignSelf: 'stretch',
        p: 1,
        cursor: 'pointer',
      }}
      onClick={() => onBodyClick(notification)}
    >
      <Body
        reservationId={notification.payload.reservationId}
        notificationTimestamp={notification.date}
        notificationType={notification.payload.type}
      />
    </Box>
    <Button
      notificationId={notification.id}
      doneStatus={notification.payload.status}
    />
  </Box>
)

export default TodoNotification
