import { andThen, composeWith, map, prop } from 'ramda'

import { type ApiClient } from 'src/shared/lib/api/services/api'
import { createBatchedFetch } from 'src/shared/lib/api/services/createBatcher'
import { type CustomerInterface } from '../../customer/types/customer'
import { fromApi } from '../types/feedbackApi'

export const fetchAllFeedbacks =
  // eslint-disable-next-line @typescript-eslint/require-await
  (httpClient: ApiClient) => async (feedbackIds: number[]) =>
    composeWith(andThen)([map(fromApi), prop('data'), httpClient])({
      url: 'feedbacksapi/getAll',
      json: {
        feedback_ids: feedbackIds,
      },
    })

export const fetchFeedback = createBatchedFetch(fetchAllFeedbacks, 'id')

export const searchFeedbacks =
  // eslint-disable-next-line @typescript-eslint/require-await
  (httpClient: ApiClient) => async (customerId: CustomerInterface['id']) =>
    composeWith(andThen)([prop('data')<{ data: number[] }>, httpClient])({
      url: 'feedbacksapi/search',
      json: {
        customer_id: customerId,
      },
    })
