import { Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useShiftsQuery } from 'src/entities/schedule/queries/schedule'
import DateRangeOverlap from './DateRangeOverlap'
import WeekdayOverlap from './WeekdayOverlap'
import {
  getShiftOfException,
  type EventOverlap,
  type ExceptionOverlap,
} from '../../../../../services/periodAwareValidation'
import type { ShiftOverlap } from '../../../../../services/shiftValidation'

interface IntersectionsProps {
  intersections: (ShiftOverlap | EventOverlap | ExceptionOverlap)[]
}

const Intersections = ({ intersections }: IntersectionsProps) => {
  const { t } = useTranslation()

  const { data: shifts } = useShiftsQuery()

  return (
    <Stack direction="column" spacing={0.5} sx={{ ml: 2.5 }}>
      {intersections.map(i => {
        if (i.type === 'shift') {
          return <WeekdayOverlap intersection={i} key={i.shift.id} />
        }

        if (i.type === 'event') {
          return (
            <DateRangeOverlap
              key={i.event.id}
              intersection={i}
              name={i.event.name}
              weekdays={i.event.weekdays}
            />
          )
        }

        const exceptionShift = getShiftOfException(shifts)(i.exception)

        if (!exceptionShift) return null

        return (
          <DateRangeOverlap
            key={i.exception.id}
            intersection={i}
            weekdays={exceptionShift.weekdays}
            name={t(
              'schedule.exceptions.general_section.arrival_time.error_exception_name',
              {
                defaultValue: '{{ shiftName }} exception',
                shiftName: exceptionShift?.name,
              },
            )}
          />
        )
      })}
    </Stack>
  )
}

export default Intersections
