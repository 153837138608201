import { Typography, type TypographyProps } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import dayjs from '../../lib/range/services/date'

interface DateFromNowProps extends TypographyProps {
  date: Date
}

const DateFromNow = ({ date, sx, ...props }: DateFromNowProps) => {
  const dayjsDate = dayjs(date)

  return (
    <Typography
      variant="body3"
      sx={mergeSx(theme => ({ color: theme.palette.neutral.main }), sx)}
      {...props}
    >
      {`${dayjsDate.fromNow()} (${dayjsDate.format('llll')})`}
    </Typography>
  )
}

export default DateFromNow
