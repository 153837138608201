import {
  type FloorPlanElementInterface,
  type PositionInterface,
  type SizeInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'

const getRadians = (angle: number) => angle * (Math.PI / 180)

const getAngle = (radians: number) => Math.round(radians / (Math.PI / 180))

const solvePythagorasForHypotenuse = (legA: number, legB: number): number =>
  Math.sqrt(legA * legA + legB * legB)

const getRotatedPosition = (
  currentPosition: PositionInterface,
  rotation: number,
) => {
  const radianRotation = getRadians(rotation)

  return {
    x:
      currentPosition.x * Math.cos(radianRotation) -
      currentPosition.y * Math.sin(radianRotation),
    y:
      currentPosition.y * Math.cos(radianRotation) +
      currentPosition.x * Math.sin(radianRotation),
  }
}

export const getRelativeCenter = (size: SizeInterface): PositionInterface => ({
  x: size.width / 2,
  y: size.height / 2,
})

export const getAbsoluteCenter = (
  table: FloorPlanElementInterface,
): PositionInterface => {
  const relativeCenter = getRelativeCenter(table.size)

  const rotatedPosition = getRotatedPosition(relativeCenter, table.rotation)

  return {
    x: table.position.x + rotatedPosition.x,
    y: table.position.y + rotatedPosition.y,
  }
}

export const getRotatedAroundCenterPosition = (
  table: FloorPlanElementInterface,
  angle: number,
): PositionInterface => {
  const absoluteCenter = getAbsoluteCenter(table)

  const relativePosition = {
    x: table.position.x - absoluteCenter.x,
    y: table.position.y - absoluteCenter.y,
  } satisfies PositionInterface

  const rotatedPosition = getRotatedPosition(relativePosition, angle)

  return {
    x: absoluteCenter.x + rotatedPosition.x,
    y: absoluteCenter.y + rotatedPosition.y,
  }
}

export const getEllipseRadius = (size: SizeInterface, angle = 0): number => {
  const { width, height } = size
  const radianAngle = getRadians(angle)

  const legA = width * Math.cos(radianAngle)
  const legB = height * Math.sin(radianAngle)

  const hypotenuse = solvePythagorasForHypotenuse(legA, legB)

  return Math.abs((width * height) / hypotenuse / 2)
}

export const getRectRadius = (size: SizeInterface) =>
  Math.abs(solvePythagorasForHypotenuse(size.width, size.height)) / 2

export const getNearestDiagonalAngle = (
  size: SizeInterface,
  angle: number,
): number => {
  const radianAngle = getRadians(angle + 90)

  const center = getRelativeCenter(size)
  const firstDiagonalAngle = Math.atan(center.x / center.y)

  const diagonalAngles = [
    firstDiagonalAngle,
    Math.PI - firstDiagonalAngle,
    firstDiagonalAngle - Math.PI,
    -firstDiagonalAngle,
  ]

  return getAngle(
    diagonalAngles.reduce((prev, curr) =>
      Math.sin(curr - radianAngle) < Math.sin(prev - radianAngle) ? curr : prev,
    ),
  )
}

export const getLineEndOffset = (
  length: number,
  angle: number,
): PositionInterface => {
  const radianAngle = getRadians(angle)

  return {
    x: length * Math.sin(radianAngle),
    y: length * Math.cos(radianAngle),
  }
}
