import React from 'react'
import { descend, prop, sort } from 'ramda'

import { type NotificationInterface } from 'src/entities/notification/types/notification'
import { usePhoneCallNotificationsStackState } from 'src/shared/lib/context/reducer/phoneCallsNotificationsStackReducer'

export const useNotifications = (
  apiNotifications: NotificationInterface[],
): NotificationInterface[] => {
  const { stack: phoneCallNotifications } =
    usePhoneCallNotificationsStackState()

  return React.useMemo(
    () =>
      sort(descend(prop('date')), [
        ...phoneCallNotifications,
        ...apiNotifications,
      ]),
    [phoneCallNotifications, apiNotifications],
  )
}
