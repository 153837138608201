import React, { type PropsWithChildren } from 'react'
import {
  Box,
  Divider,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  type TooltipProps,
} from '@mui/material'

const WideTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 360,
  },
})

const ShiftsTooltip = ({
  children,
  tooltipToggle,
}: PropsWithChildren<{
  tooltipToggle: React.ReactNode
}>) => {
  if (!React.Children.count(children)) return null

  return (
    <WideTooltip
      title={
        <Stack
          direction="column"
          gap={1}
          divider={<Divider orientation="horizontal" />}
        >
          {children}
        </Stack>
      }
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      arrow
      placement="bottom-end"
    >
      <Box>{tooltipToggle}</Box>
    </WideTooltip>
  )
}

export default ShiftsTooltip
