import { Box } from '@mui/material'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import {
  type LabelInterface,
  type PersistedLabelInterface,
} from 'src/entities/label/types/label'
import MediumLabel from 'src/widgets/Label/MediumLabel'

interface ItemProps {
  label: PersistedLabelInterface
  onLabelClick: (label: LabelInterface) => void
}

const Item = ({ label, onLabelClick }: ItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: label.id })

  return (
    <Box
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      sx={{
        transform: CSS.Translate.toString(transform),
        transition,
      }}
    >
      <MediumLabel
        label={label}
        onClick={() => onLabelClick(label)}
        sx={{ width: '120px' }}
      />
    </Box>
  )
}

export default Item
