import React from 'react'
import { Button, Divider, Stack, Tab, Tabs, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  useDailyPaxQuery,
  usePaxQueries,
  useRoomsQuery,
  useShiftsQuery,
} from 'src/entities/schedule/queries/schedule'
import { useShortDateFormatter } from 'src/shared/lib/range/hooks/useFormattedDate'
import {
  addToDate,
  getNowInRestaurantTimezone,
} from 'src/shared/lib/range/services/date'
import { interpolate } from 'src/shared/lib/range/services/timeRange'
import ShiftGrid from './components/ShiftGrid/ShiftGrid'
import { CustomScheduleTypography } from '../components/CustomScheduleTypography'

const now = getNowInRestaurantTimezone()

export const dates = interpolate([now, addToDate(7, 'days', now)], 'days', 1)

const formatOptions = {
  day: 'numeric',
  weekday: 'short',
  month: 'short',
} satisfies Intl.DateTimeFormatOptions

const Timeline = ({
  onWeekOverviewOpen,
}: {
  onWeekOverviewOpen: () => void
}) => {
  const { t } = useTranslation()

  const [date, setDate] = React.useState(dates[0]!)
  const changeHandler = (_e: unknown, value: Date) =>
    React.startTransition(() => setDate(value))

  const formatDate = useShortDateFormatter(formatOptions)

  const { data: dailyShifts } = useDailyPaxQuery(date)
  const { data: shifts } = useShiftsQuery()
  const { data: rooms } = useRoomsQuery()

  const weeklyShifts = usePaxQueries(dates)

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <CustomScheduleTypography>
          {t('schedule.timeline.title', 'Your reservation management')}
        </CustomScheduleTypography>
        <Button variant="text" onClick={onWeekOverviewOpen}>
          {t('schedule.week_overview.title', 'Week overview')}
        </Button>
      </Stack>
      <Tabs value={date} onChange={changeHandler}>
        {dates.map(d => (
          <Tab
            key={d.toISOString()}
            value={d}
            label={
              <Typography variant="labelSmall" sx={{ cursor: 'pointer' }}>
                {formatDate(d)}
              </Typography>
            }
          />
        ))}
      </Tabs>
      <Divider />
      <ShiftGrid
        shifts={shifts}
        rooms={rooms}
        dailyShifts={dailyShifts}
        weeklyShifts={weeklyShifts}
      />
    </Stack>
  )
}

export default Timeline
