import { CircularProgress, Stack } from '@mui/material'

export const SpinnerOverlay = () => (
  <Stack
    justifyContent="center"
    alignItems="center"
    sx={{
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      background: 'rgba(0,0,0,0.4)',
      zIndex: 1,
    }}
  >
    <CircularProgress />
  </Stack>
)
