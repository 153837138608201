import { andThen, composeWith, filter, map, prop } from 'ramda'

import type { ApiClient } from 'src/shared/lib/api/services/api'
import { priorityComparator } from 'src/shared/lib/common/services/priorityComparator'
import { isCodeComplete } from '../services/localeApi'
import { phoneCodeFactory, type RawPhoneCodes } from '../types/localeApi'

const PRIORITY_LANGUAGES_CODES = ['de', 'fr', 'it', 'en']

type RawLanguages = Record<string, { name: string; nativeName: string }>

const languageFactory = ([key, { name, nativeName }]: [
  keyof RawLanguages,
  RawLanguages[keyof RawLanguages],
]) => ({
  code: key,
  name,
  nativeName,
})

type Language = ReturnType<typeof languageFactory>

const languageListFactory = (languages: RawLanguages) =>
  Object.entries(languages)
    .map(languageFactory)
    .sort(
      priorityComparator<Language, 'code'>(
        PRIORITY_LANGUAGES_CODES,
        prop('code'),
      ),
    )

const extractUniqueCodes = (codes: RawPhoneCodes[]) =>
  codes.reduce(
    (unique, code) =>
      unique.find(testCode => testCode.desc === code.desc)
        ? unique
        : [...unique, code],
    [] as RawPhoneCodes[],
  )

const LANGUAGE_LIST_API_PATH = 'getLanguageList'
export const getLanguagesList = (httpClient: ApiClient) =>
  composeWith(andThen)([languageListFactory, httpClient])({
    url: LANGUAGE_LIST_API_PATH,
  })
const PHONE_CODES_API_PATH = 'getCountryCode'
export const getPhoneCodes = (httpClient: ApiClient) =>
  composeWith(andThen)([
    filter(isCodeComplete),
    map(phoneCodeFactory),
    extractUniqueCodes,
    httpClient,
  ])({
    url: PHONE_CODES_API_PATH,
  })
const COUNTRIES_API_PATH = 'getCountryList'
export const getCountriesList = (httpClient: ApiClient) =>
  composeWith(andThen)([httpClient])({
    url: COUNTRIES_API_PATH,
  })
