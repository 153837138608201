import { andThen, composeWith, prop } from 'ramda'

import type { ApiClient } from 'src/shared/lib/api/services/api'
import {
  convertToTimezone,
  toApiDateString,
} from 'src/shared/lib/range/services/date'
import {
  serviceTimeMessagesSchema,
  type ServiceTimeMessage,
} from '../types/serviceTime'

export const getServiceTimesMessages =
  (httpClient: ApiClient) => (date: Date) =>
    composeWith(andThen)([
      (data: unknown) => serviceTimeMessagesSchema.parse(data),
      prop('data'),
      httpClient,
    ])({
      url: 'floorplanapi/getServiceTimeMessages',
      json: { date: convertToTimezone(date) },
    })

interface SetServiceTimeMessagePayload {
  message: ServiceTimeMessage
  date: Date
}

export const setServiceTimeMessage =
  (httpClient: ApiClient) => (payload: SetServiceTimeMessagePayload) =>
    httpClient({
      url: 'setTimeRangeInfo',
      json: {
        date: toApiDateString(payload.date),
        time_range_id: payload.message.id,
        info: payload.message.message,
      },
    })
