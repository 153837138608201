import { Alert, Button, Divider, Stack, type StackProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import SaveButton from './SaveButton'
import { ScheduleContainer } from './ScheduleContainer'

interface FormContainerProps extends StackProps {
  title: string
  onNavigateBack: () => void
  onSave: () => Promise<void>
  error?: boolean
  onAdvancedFormButtonClick?: () => void
}

const FormContainer = ({
  onSave,
  children,
  error,
  onAdvancedFormButtonClick,
  ...props
}: FormContainerProps) => {
  const { t } = useTranslation()

  return (
    <ScheduleContainer {...props}>
      <Stack direction="column" gap={3} divider={<Divider />}>
        <Stack direction="column" gap={7}>
          {error && (
            <Alert severity="error">
              {t(
                'common.validation.form_has_errors',
                'The form contains errors',
              )}
            </Alert>
          )}
          {children}
        </Stack>
        <Stack direction="row" gap={0.5}>
          <SaveButton onClick={onSave} />
          {onAdvancedFormButtonClick && (
            <Button variant="outlined" onClick={onAdvancedFormButtonClick}>
              {t(
                'schedule.common.go_to_advanced_form',
                'Go to advanced settings',
              )}
            </Button>
          )}
        </Stack>
      </Stack>
    </ScheduleContainer>
  )
}

export default FormContainer
