import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  useDailyPaxQuery,
  usePaxQueries,
  useRoomsQuery,
  useShiftsQuery,
} from 'src/entities/schedule/queries/schedule'
import IconButton from 'src/shared/components/buttons/IconButton'
import ArrowLeftIcon from 'src/shared/components/icons/ArrowLeftIcon'
import { useShortDateFormatter } from 'src/shared/lib/range/hooks/useFormattedDate'
import ShiftGrid from './components/ShiftGrid/ShiftGrid'
import { dates } from './Timeline'

const DateTimeline = ({ date }: { date: Date }) => {
  const { data: dailyShifts } = useDailyPaxQuery(date)
  const weeklyShifts = usePaxQueries(dates)
  const { data: shifts } = useShiftsQuery()
  const { data: rooms } = useRoomsQuery()

  const formatDate = useShortDateFormatter()

  return (
    <Stack direction="column" gap={1.5}>
      <Typography variant="labelRegular">{formatDate(date)}</Typography>
      <ShiftGrid
        shifts={shifts}
        rooms={rooms}
        dailyShifts={dailyShifts}
        weeklyShifts={weeklyShifts}
      />
    </Stack>
  )
}

const Header = ({ onNavigateBack }: { onNavigateBack: () => void }) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" alignItems="center">
      <IconButton
        onClick={onNavigateBack}
        size="medium"
        variant="text"
        color="neutral"
      >
        <ArrowLeftIcon />
      </IconButton>
      <Typography variant="labelHuge">
        {t('schedule.week_overview.title', 'Week overview')}
      </Typography>
    </Stack>
  )
}

const WeekOverview = ({ onNavigateBack }: { onNavigateBack: () => void }) => (
  <Stack direction="column" sx={{ pr: 4 }} gap={3}>
    <Header onNavigateBack={onNavigateBack} />
    <Stack direction="column" sx={{ pl: 4 }} gap={3}>
      {dates.map(d => (
        <DateTimeline key={d.toISOString()} date={d} />
      ))}
    </Stack>
  </Stack>
)

export default WeekOverview
