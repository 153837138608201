import { forwardRef, useEffect, useState } from 'react'
import { InputAdornment } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { usePhoneCodesQuery } from 'src/entities/locale/queries/locale'
import { type PhoneCode } from 'src/entities/locale/types/localeApi'
import FormTextInput, {
  type FormTextInputProps,
} from 'src/shared/components/form/inputs/FormTextInput'
import { withTargetValue } from 'src/shared/lib/common/services/helpers/helpers'
import {
  getCountryCode,
  getNationalNumber,
  isValidPhoneNumber,
  stripNonDigits,
} from 'src/shared/lib/string/services/phoneNumber'
import PhoneCodeSelect from './PhoneCodeSelect'

type PhoneInputProps = {
  onChange: (value: string) => void
  value: string
} & Omit<FormTextInputProps, 'onChange' | 'value'>

const getInitialState = (phoneCodes: PhoneCode[]) => (input: string) => {
  if (isValidPhoneNumber(input))
    return {
      code: phoneCodes.find(c => c.code === `+${getCountryCode(input)}`),
      number: String(getNationalNumber(input) ?? ''),
    }

  const possiblyCountyCode = input.match(/^\+\d{2}/)?.[0]

  if (!possiblyCountyCode) return { code: undefined, number: input }

  return {
    code: phoneCodes.find(c => c.code === possiblyCountyCode),
    number: input.substring(3, input.length),
  }
}

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ onChange, value: input, ...props }, ref) => {
    const { data: phoneCodes } = usePhoneCodesQuery()

    const initialState = getInitialState(phoneCodes)(input)

    const [phoneCode, setPhoneCode] = useState(initialState.code)
    const [number, setNumber] = useState<number | string | undefined>(
      initialState.number,
    )

    useEffect(
      () => {
        if (!phoneCode?.code || !number) onChange('')
        else onChange(`${phoneCode.code}${number}`)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [phoneCode, number],
    )

    const phoneNumberChangeHandler = (phoneNumber: string) =>
      setNumber(stripNonDigits(phoneNumber))

    useEffect(() => {
      if (!input) setNumber('')
      if (!isValidPhoneNumber(input)) return

      setNumber(getNationalNumber(input))
      setPhoneCode(phoneCodes.find(c => c.code === `+${getCountryCode(input)}`))
    }, [input, phoneCodes])

    return (
      <FormTextInput
        {...createTestIdProps(`phone-input`)}
        value={number}
        onChange={withTargetValue(phoneNumberChangeHandler)}
        sx={{ overflow: 'visible' }}
        slotProps={{
          input: {
            sx: { overflow: 'visible' },
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ height: 1, maxHeight: 1, mr: 0 }}
              >
                <PhoneCodeSelect
                  onChange={setPhoneCode}
                  value={phoneCode}
                  options={phoneCodes}
                />
              </InputAdornment>
            ),
          },
        }}
        ref={ref}
        {...props}
      />
    )
  },
)

export default PhoneInput
