import { useMemo } from 'react'

import { useIsReservationMutating } from 'src/entities/reservation/queries/reservationsMutations'
import { isExpired } from 'src/entities/reservation/services/paymentStatus'
import {
  ReservationStatusEnum,
  type ReservationInterface,
} from 'src/entities/reservation/types/reservation'
import { useTickingCurrentTime } from 'src/pages/Reservations/components/Timeline/hooks/useTickingCurrentTime'
import { useSelectedReservationField } from 'src/shared/lib/context/state/atoms/selectedReservation'

export type DrawerColor = 'blue' | 'green' | 'grey' | 'red'

export const drawerColors: {
  [key in DrawerColor]: { header: `#${string}`; body: `#${string}` }
} = {
  blue: {
    header: '#037cda',
    body: '#ceeeff',
  },
  green: {
    header: '#00af83',
    body: '#cdede7',
  },
  grey: {
    header: '#737373',
    body: '#b6b1b1',
  },
  red: {
    header: '#cc5344',
    body: '#f0c2c2',
  },
}

const redStatuses: ReservationStatusEnum[] = [
  ReservationStatusEnum.Cancelled,
  ReservationStatusEnum.NoShow,
]

interface ReservationLike {
  id: ReservationInterface['id']
  status: ReservationInterface['status']
  payment: ReservationInterface['payment']
}

const getDrawerColor = <T extends ReservationLike>(
  reservationLike: T,
  isLoading: boolean,
  getNow: () => Date,
): DrawerColor => {
  if (isLoading) return 'grey'

  if (
    redStatuses.includes(reservationLike.status) ||
    isExpired(getNow)(reservationLike.payment)
  )
    return 'red'

  if (!reservationLike.id) return 'green'

  return 'blue'
}

export const useDrawerColor = () => {
  const currentTime = useTickingCurrentTime()
  const isLoading = useIsReservationMutating()

  const id = useSelectedReservationField('id')
  const status = useSelectedReservationField('status')
  const payment = useSelectedReservationField('payment')

  const reservationLike = useMemo(
    () => ({ id, status, payment }),
    [id, status, payment],
  )

  return getDrawerColor(reservationLike, isLoading, () => currentTime)
}
