import React from 'react'
import { Box } from '@mui/material'
import {
  PickersDay,
  pickersDayClasses,
  type PickersDayProps,
} from '@mui/x-date-pickers'

import { type Dayjs } from 'dayjs'

import { mergeSx } from 'src/app/theme/helpers'
import { overlapTime } from '../../../../lib/range/services/timeRange'
import { type DefinedRangeInterface } from '../../../../lib/range/types/range'

export const inRangeClass = 'inRange'

export type RangePickerItemProps = PickersDayProps<Dayjs> & {
  selectedTimeRange?: DefinedRangeInterface<Date> | null
}

const RangePickerItem = ({
  selectedTimeRange,
  sx,
  ...props
}: RangePickerItemProps) => {
  const inRange = React.useMemo(
    () =>
      selectedTimeRange && overlapTime(selectedTimeRange, props.day.toDate()),
    [selectedTimeRange, props.day],
  )

  return (
    <Box
      sx={mergeSx(
        {
          '&:hover': {
            ...(inRange &&
              !props.selected &&
              !props.outsideCurrentMonth && {
                backgroundColor: 'primaryPalette.200',
              }),
          },
        },
        sx,
      )}
    >
      <PickersDay
        {...props}
        disableMargin
        classes={{
          root: [pickersDayClasses.root, inRange && inRangeClass]
            .filter(Boolean)
            .join(' '),
        }}
        disableRipple
        sx={{
          borderRadius: '2px',
          typography: 'labelTiny',
          color: 'grey.600',
          width: '100%',
          height: '100%',
          '&:focus': {
            backgroundColor: 'transparent',
          },
          [`&.${pickersDayClasses.today}`]: {
            borderColor: 'grey.200',
          },
          ...(inRange &&
            !props.selected && {
              '&:focus': {
                backgroundColor: 'primaryPalette.200',
              },
              backgroundColor: 'primaryPalette.200',
              borderRadius: 0,
            }),
          [`&.${pickersDayClasses.disabled}`]: {
            color: 'grey.300',
          },
          '&:hover': {
            color: 'grey.800',
            backgroundColor: 'grey.300',
            borderRadius: '2px',
            '&:focus': {
              backgroundColor: 'grey.300',
            },
            backgroundImage: 'none',
            ...(inRange &&
              !props.selected && {
                '&:focus': {
                  backgroundColor: 'primaryPalette.200',
                },
                backgroundColor: 'primaryPalette.200',
                border: '1px solid',
                borderRadius: '2px',
                borderColor: 'primaryPalette.300',
              }),
          },
          [`&.${pickersDayClasses.selected}, &.${pickersDayClasses.selected}:focus`]:
            {
              color: 'white',
              backgroundColor: 'primaryPalette.600',
            },
        }}
      />
    </Box>
  )
}

export default RangePickerItem
