import FlagIcon from 'src/shared/components/icons/FlagIcon'
import Preference from './Preference'

const Language = ({ preference }: { preference: string }) => (
  <Preference
    text={preference}
    icon={<FlagIcon size="small" variant="filled" />}
  />
)

export default Language
