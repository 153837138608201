import { useMemo } from 'react'
import { append, compose, flatten, map } from 'ramda'

import { useAreasQuery } from 'src/entities/area/queries/area'
import { type ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { useLabelsQuery } from 'src/entities/label/queries/label'
import { getArea } from 'src/entities/reservation/services/occupancy'
import { filterReservations } from 'src/entities/reservation/services/reservationTableFilter'
import { useServiceTimesQuery } from 'src/entities/service-time/queries/serviceTime'
import { useTablesQuery } from 'src/entities/table/queries/table'
import { type SelectedReservation } from 'src/shared/lib/context/state/atoms/baseSelectedReservation'
import { useSelectedServiceTime } from 'src/shared/lib/context/state/atoms/selectedServiceTime'
import { useClientFiltersState } from 'src/shared/lib/reducer/service/reservationsFilter'
import { useReservationsWithGuestData } from '../components/ReservationsTable/useReservationsWithGuestData'
import {
  getOccupancy,
  getOccupancyServiceTimes,
  groupOccupanciesByArea,
  rejectCheckedOutOccupancies,
  splitReservationIntoOccupancies,
  type ReservationOccupancy,
} from '../service/occupancy'
import {
  filterActiveReservations,
  getReservationServiceTimes,
  groupReservationsByFilters,
  groupReservationsByServiceTime,
} from '../service/reservation'

export const useGroupedReservations = (
  date: Date,
  selectedReservation: SelectedReservation | undefined,
) => {
  const { data: areas } = useAreasQuery()
  const { data: tables } = useTablesQuery()
  const { data: labels } = useLabelsQuery()
  const { data: serviceTimes } = useServiceTimesQuery()

  const reservations = useReservationsWithGuestData(date, selectedReservation)
  const serviceTime = useSelectedServiceTime()
  const filters = useClientFiltersState()

  const activeReservations = useMemo(
    () => filterActiveReservations(reservations),
    [reservations],
  )

  const activeOccupancies = useMemo(
    () =>
      compose(
        rejectCheckedOutOccupancies,
        flatten<ReservationOccupancy[][]>,
        map(splitReservationIntoOccupancies),
      )(activeReservations),
    [activeReservations],
  )

  const groupReservations = useMemo(
    () =>
      groupReservationsByServiceTime(
        compose(
          append<ServiceTimeInterface['id'] | null>(null),
          compose(
            map(st => st.id),
            getReservationServiceTimes(serviceTimes),
          ),
        ),
      ),
    [serviceTimes],
  )

  const activeReservationsByServiceTime = useMemo(
    () => groupReservations(activeReservations),
    [activeReservations, groupReservations],
  )

  const shownOccupancies = useMemo(() => {
    const activeServiceTimeId = serviceTime?.id ?? null
    const getServiceTimes = compose(
      getOccupancyServiceTimes,
      getReservationServiceTimes,
    )(serviceTimes)

    const isInServiceTime = (o: ReservationOccupancy) =>
      getServiceTimes(o)
        .map(st => (st ? st.id : st))
        .includes(activeServiceTimeId)

    return activeOccupancies.filter(isInServiceTime)
  }, [activeOccupancies, serviceTime, serviceTimes])

  const shownOccupanciesByAreaId = useMemo(() => {
    const getOccupancyAreaId = compose(
      a => a?.id,
      getArea(areas, tables),
      getOccupancy,
    )

    return groupOccupanciesByArea(getOccupancyAreaId)(shownOccupancies)
  }, [shownOccupancies, areas, tables])

  const reservationsByFilterPreset = useMemo(
    () =>
      compose(
        groupReservationsByFilters(filterReservations(tables, areas, labels))(
          filters,
        ),
        resMap => resMap.get(serviceTime?.id ?? null) ?? [],
        groupReservations,
      )(reservations),
    [
      tables,
      areas,
      labels,
      filters,
      groupReservations,
      reservations,
      serviceTime,
    ],
  )

  return {
    activeOccupancies,
    shownOccupancies,
    shownOccupanciesByAreaId,
    activeReservations,
    activeReservationsByServiceTime,
    reservationsByFilterPreset,
  }
}
