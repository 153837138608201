import { type DeepPartial } from 'react-hook-form'

import { type ReservationQuestion } from 'src/entities/schedule/types/scheduleApi'
import { convertToTimezone } from 'src/shared/lib/range/services/date'
import { type ReservationQuestionFormValues } from './getReservationQuestionFormSchema'
import {
  fromFormValidityScope,
  toFormValidityScope,
} from '../../ClosureForm/ValidityScopeInput/service/mappers'

export const emptyReservationQuestion = {
  isActive: true,
  isMandatory: false,
  validity: { type: 'always' },
  scope: { type: 'all' },
  question: { de: null, en: null, fr: null, it: null },
  hint: { de: null, en: null, fr: null, it: null },
  options: [{ de: null, en: null, fr: null, it: null }],
} satisfies DeepPartial<ReservationQuestionFormValues>

export const questionToFormData = (
  reservationQuestion: ReservationQuestion | undefined,
) =>
  reservationQuestion &&
  ({
    isActive: reservationQuestion.active,
    name: reservationQuestion.name,
    isMandatory: reservationQuestion.mandatory,
    question: reservationQuestion.form.question,
    validity: reservationQuestion.effectivePeriod
      ? {
          type: 'date-range',
          dateRange: [
            reservationQuestion.effectivePeriod.start,
            reservationQuestion.effectivePeriod.end,
          ],
        }
      : { type: 'always' },
    scope: toFormValidityScope(reservationQuestion.scope),
    options: reservationQuestion.form.options,
    hint: reservationQuestion.form.hint,
  } satisfies ReservationQuestionFormValues)

export const formDataToQuestion = (data: ReservationQuestionFormValues) =>
  ({
    active: data.isActive,
    mandatory: data.isMandatory,
    effectivePeriod:
      data.validity.type === 'date-range'
        ? {
            start: convertToTimezone(data.validity.dateRange[0]!),
            end: convertToTimezone(data.validity.dateRange[1]!),
          }
        : null,
    form: {
      question: data.question,
      hint: data.hint,
      options: data.options,
    },
    name: data.name,
    scope: fromFormValidityScope(data.scope),
  }) satisfies Omit<ReservationQuestion, 'id'>
