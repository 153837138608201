import { useTranslation } from 'react-i18next'

import { useDeletePaymentPlanMutation } from 'src/entities/schedule/queries/schedule'
import {
  type PaymentPlan,
  type Schedule,
} from 'src/entities/schedule/types/scheduleApi'
import { currencyFormatter } from 'src/shared/lib/string/services/text'
import { useDefaultMutationHandlers } from '../../ShiftConfiguration/hooks/useDefaultMutationHandlers'
import { CreateScheduleButton } from '../components'
import { ScheduleCard, ScheduleCardWrapper } from '../components/ScheduleCard'

interface PaymentPlanSectionProps {
  schedule: Schedule
  currency: string
  onEditPaymentPlan: (plan?: PaymentPlan) => void
}

export const PaymentPlanSection = ({
  schedule,
  currency,
  onEditPaymentPlan,
}: PaymentPlanSectionProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { mutateAsync: deletePlan } = useDeletePaymentPlanMutation()
  const { onError } = useDefaultMutationHandlers()

  return (
    <>
      <ScheduleCardWrapper>
        {schedule.paymentPlans.map(p => (
          <ScheduleCard
            key={p.id}
            onEdit={() => onEditPaymentPlan(p)}
            onDelete={() => deletePlan(p, { onError })}
            title={p.name}
            properties={[
              t(`payment_plan.type.${p.type}.name`, p.type),
              t('payment_plan.amount_per_person', {
                price: currencyFormatter(language, currency).format(
                  p.amountPerPerson,
                ),
                defaultValue: '{{price}}/person',
              }),
            ]}
          />
        ))}
      </ScheduleCardWrapper>
      <CreateScheduleButton onClick={() => onEditPaymentPlan()}>
        {t('schedule.overview.payment_plan.create', 'Create Payment Plan')}
      </CreateScheduleButton>
    </>
  )
}
