import { Button, type ButtonProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

interface CancelButtonProps extends ButtonProps {
  text?: string
}

const CancelButton = ({ text, ...props }: CancelButtonProps) => {
  const { t } = useTranslation()

  return (
    <Button color="neutral" variant="outlined" {...props}>
      {text ?? t('common.form_buttons.cancelButtonLabel')}
    </Button>
  )
}

export default CancelButton
