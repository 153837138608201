import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'

import { useBetterApiClient } from 'src/shared/lib/api/hooks/useBetterApiClient'
import { useRestaurantCacheKey } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import {
  disconnectIntegration,
  getIntegrations,
  setupIntegration,
} from '../api/integrationsApi'

const useIntegrationsCacheKey = () => useRestaurantCacheKey(['integrations'])

const useIntegrationsApiClient = () =>
  useBetterApiClient({ prefix: 'integrations' })

export const useIntegrationsQuery = () => {
  const apiClient = useIntegrationsApiClient()
  const queryKey = useIntegrationsCacheKey()

  return useSuspenseQuery({
    queryFn: getIntegrations(apiClient),
    queryKey,
    staleTime: Infinity,
    refetchInterval: Infinity,
  })
}

export const useSetupIntegrationMutation = () => {
  const apiClient = useIntegrationsApiClient()
  const queryKey = useIntegrationsCacheKey()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: setupIntegration(apiClient),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })
}

export const useDisconnectIntegrationMutation = () => {
  const apiClient = useIntegrationsApiClient()
  const queryKey = useIntegrationsCacheKey()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: disconnectIntegration(apiClient),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })
}
