import { ReservationCheckInStatusEnum } from 'src/entities/reservation/types/reservation'
import {
  defaultClientFilters,
  FiltersEnum,
  type ReservationClientFilters,
} from 'src/shared/lib/reducer/service/reservationsFilter'

const FILTER_PRESET = {
  unplaced: 'unplaced',
  arriving: 'arriving',
  seated: 'seated',
  left: 'left',
  filter: 'filter',
} as const

export type FilterPreset = (typeof FILTER_PRESET)[keyof typeof FILTER_PRESET]

export const PREDEFINED_FILTERS = new Map<
  FilterPreset,
  ReservationClientFilters | undefined
>([
  [
    FILTER_PRESET.unplaced,
    {
      ...defaultClientFilters,
      [FiltersEnum.checkInStatus]: [ReservationCheckInStatusEnum.New],
    },
  ],
  [
    FILTER_PRESET.arriving,
    {
      ...defaultClientFilters,
      [FiltersEnum.checkInStatus]: [ReservationCheckInStatusEnum.Pending],
    },
  ],
  [
    FILTER_PRESET.seated,
    {
      ...defaultClientFilters,
      [FiltersEnum.checkInStatus]: [ReservationCheckInStatusEnum.CheckedIn],
    },
  ],
  [
    FILTER_PRESET.left,
    {
      ...defaultClientFilters,
      [FiltersEnum.checkInStatus]: [ReservationCheckInStatusEnum.CheckedOut],
    },
  ],
  [FILTER_PRESET.filter, undefined],
])
