import React, { useMemo } from 'react'
import { Divider, Skeleton, Stack } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { type AreaInterface } from 'src/entities/area/types/area'
import { getLabelsByIds } from 'src/entities/label/services/labels'
import { type LabelInterface } from 'src/entities/label/types/label'
import {
  ReservationStatusEnum,
  type ReservationInterface,
} from 'src/entities/reservation/types/reservation'
import { type RoomInterface } from 'src/entities/room/types/room'
import { getOccupanciesTables } from 'src/entities/table/services/table'
import { type TableInterface } from 'src/entities/table/types/table'
import CheckInStatusButton from 'src/widgets/Reservation/Table/CheckInStatusButton'
import { GuestData } from './GuestData'
import { ItemHeader } from './ItemHeader'
import { ReservationDetails } from './ReservationDetails'
import { ReservationListItemWrapper } from './ReservationListItemWrapper'

interface ReservationListItemProps {
  reservation: ReservationInterface
  restaurantLabels: LabelInterface[]
  restaurantRooms: RoomInterface[]
  restaurantTables: TableInterface[]
  restaurantAreas: AreaInterface[]
  isActive: boolean
  onCardClick: () => void
  onStatusButtonClick: () => void
}

const ReservationListItem = ({
  reservation,
  restaurantLabels,
  restaurantRooms,
  restaurantTables,
  restaurantAreas,
  isActive,
  onCardClick,
  onStatusButtonClick,
}: ReservationListItemProps) => {
  const {
    status,
    dateRange: [arrival],
    seatCount,
    room: roomId,
    occupancies,
    restaurantNote,
    labels: labelIds,
    choices,
    guestMessage,
  } = reservation

  const labels = useMemo(
    () => getLabelsByIds(restaurantLabels)(labelIds),
    [labelIds, restaurantLabels],
  )

  const room = restaurantRooms?.find(({ id }) => id === roomId)

  const redBgCondition =
    status === ReservationStatusEnum.NoShow ||
    status === ReservationStatusEnum.Cancelled

  const reservationTables = useMemo(
    () => getOccupanciesTables(restaurantTables)(occupancies),
    [restaurantTables, occupancies],
  )

  return (
    <ReservationListItemWrapper
      redBgCondition={redBgCondition}
      onClick={onCardClick}
      {...createTestIdProps('reservation-list-item')}
    >
      <Stack
        direction="row"
        overflow="hidden"
        alignItems="center"
        sx={{
          p: 0.5,
          gap: 1,
          flex: 1,
        }}
        divider={
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: '#ADADAD' }}
          />
        }
      >
        <ItemHeader arrival={arrival} seatCount={seatCount} />
        <Stack gap={0.5} overflow="hidden" flex={1} height={1}>
          <React.Suspense
            fallback={<Skeleton variant="text" sx={{ width: 1 }} />}
          >
            <GuestData reservation={reservation} arrival={arrival} />
          </React.Suspense>
          <ReservationDetails
            room={room}
            tables={reservationTables}
            restaurantAreas={restaurantAreas}
            labels={labels}
            displayLink={!!reservation.links.length}
            choices={choices}
            restaurantNote={restaurantNote}
            guestMessage={guestMessage}
          />
        </Stack>
      </Stack>
      <CheckInStatusButton
        reservation={reservation}
        onClick={onStatusButtonClick}
        isActive={isActive}
      />
    </ReservationListItemWrapper>
  )
}

export default ReservationListItem
