import { createContext, useContext, useEffect, useState } from 'react'

export const IsDateDeferredContext = createContext<boolean>(false)

export const useIsDateDeferredContext = () => {
  const isDeferred = useContext(IsDateDeferredContext)

  const [delayedIsDeferred, setDelayedIsDeferred] = useState(isDeferred)

  useEffect(() => {
    if (!isDeferred) return setDelayedIsDeferred(isDeferred)

    const timeout = setTimeout(() => setDelayedIsDeferred(isDeferred), 100)

    return () => clearTimeout(timeout)
  }, [isDeferred])

  return delayedIsDeferred
}
