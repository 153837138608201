import { useReservationCustomerQuery } from 'src/entities/customer/queries/customer'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { isBirthday } from '../../../lib/range/services/date'
import CakeIcon from '../../icons/CakeIcon'

const BirthdayIndicator = ({
  birthdate,
  date,
}: {
  birthdate: Date
  date: Date
}) => {
  if (!isBirthday(birthdate, date)) return null

  return <CakeIcon size="small" />
}

export const CustomerBirthdayIndicator = ({
  date,
  reservation,
}: {
  date: Date
  reservation: ReservationInterface
}) => {
  const { data: customer } = useReservationCustomerQuery(reservation)

  if (!customer?.birthdate) return null

  return <BirthdayIndicator birthdate={customer.birthdate} date={date} />
}

export default BirthdayIndicator
