import { type SettingsKeys } from '../../config/types/configApi'

export enum SectionEnum {
  CreateNotifications = 'createNotifications',
  MutateNotifications = 'mutateNotifications',
  CancelNotifications = 'cancelNotifications',
  NoShowNotifications = 'noshowNotifications',
  MoveNotifications = 'moveNotifications',
  OtherNotifications = 'otherNotifications',
  Others = 'others',
  DefaultView = 'defaultView',
}

export enum NotificationEnum {
  GuestEmail = 'guestEmail',
  GuestSms = 'guestSms',
  RestaurantEmail = 'restaurantEmail',
}

export interface Setting {
  name: SettingsKeys
  value: boolean
}

export type SectionCollectionType = {
  [key in SectionEnum]: SettingsKeys[]
}
