import { useCallback, useMemo } from 'react'
import {
  DateCalendar,
  type DateCalendarProps,
  type DateCalendarSlotProps,
} from '@mui/x-date-pickers'

import { type Dayjs } from 'dayjs'

import { type Holiday } from 'src/entities/config/types/configApi'
import dayjs, {
  convertFromTimezone,
  convertToTimezone,
  getRestaurantTimeZone,
} from 'src/shared/lib/range/services/date'
import { DatePickerDay } from './DatePickerDay'
import IconButton from '../../../../buttons/IconButton'

interface SlotProps extends DateCalendarSlotProps<Dayjs> {
  day: DateCalendarSlotProps<Dayjs>['day'] & {
    globalHolidays?: Holiday[]
    roomHolidays?: Holiday[]
  }
}

interface DatePickerCalendarProps extends DateCalendarProps<Dayjs> {
  slotProps: SlotProps
}

export const DatePickerCalendar = ({
  value,
  onChange,
  slots,
  slotProps,
  ...rest
}: DatePickerCalendarProps) => {
  const valueInRestaurantTimezone = useMemo(
    () => value && dayjs(convertToTimezone(value?.toDate())),
    [value],
  )

  const handleChange = useCallback(
    (d: Dayjs | null) =>
      onChange?.(d && dayjs(convertFromTimezone(d?.toDate()))),
    [onChange],
  )

  return (
    <DateCalendar<Dayjs>
      showDaysOutsideCurrentMonth
      value={valueInRestaurantTimezone}
      views={['year', 'month', 'day']}
      onChange={handleChange}
      slotProps={{
        nextIconButton: { color: 'neutral' },
        previousIconButton: { color: 'neutral' },
        switchViewButton: { color: 'neutral' },
        ...slotProps,
      }}
      timezone={getRestaurantTimeZone()}
      slots={{
        day: DatePickerDay,
        previousIconButton: IconButton,
        nextIconButton: IconButton,
        switchViewButton: IconButton,
        switchViewIcon: () => null,
        ...slots,
      }}
      {...rest}
    />
  )
}
