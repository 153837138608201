export interface RawPhoneCodes {
  desc: string
  index: number
  long: string
  short: string
}

export const phoneCodeFactory = ({ desc, long }: RawPhoneCodes) => {
  const phoneRegex = /(?<code>\+\d*) \((?<country>.*?)\)/
  const { country, code } = desc.match(phoneRegex)?.groups ?? {}
  const { country: countryCode } = long.match(phoneRegex)?.groups ?? {}

  return {
    code: code ?? '',
    country: country ?? '',
    countryCode: countryCode ?? '',
  }
}
export type PhoneCode = ReturnType<typeof phoneCodeFactory>
