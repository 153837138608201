import { z } from 'zod'

const actionSchema = z.enum([
  'update',
  'create',
  'cancel',
  'message',
  'noshow',
  'change_restaurant',
  'all',
])

type TemplateAction = z.infer<typeof actionSchema>

const baseTemplateSchema = z.object({
  id: z.number(),
  name: z.coerce.string(),
  standard: z.coerce.boolean(),
})

const smsEventSchema = z.enum([
  'update_reservation_by_gastro',
  'new_reservation_by_gastro',
  'message_by_gastro',
  'cancel_reservation_by_gastro',
  'change_restaurant_by_gastro',
])

const smsEventToActionMap: {
  [key in z.infer<typeof smsEventSchema>]: TemplateAction
} = {
  update_reservation_by_gastro: 'update',
  new_reservation_by_gastro: 'create',
  message_by_gastro: 'message',
  cancel_reservation_by_gastro: 'cancel',
  change_restaurant_by_gastro: 'change_restaurant',
}

export const smsTemplateSchema = baseTemplateSchema
  .extend({ event: smsEventSchema })
  .transform(t => ({
    id: t.id,
    name: t.name,
    default: t.standard,
    action: smsEventToActionMap[t.event],
  }))

const emailTypeSchema = z.enum([
  'mutation',
  'confirmation',
  'not_appeared',
  'notification',
  'cancel',
])

const emailEventToActionMap: {
  [key in z.infer<typeof emailTypeSchema>]: TemplateAction
} = {
  mutation: 'update',
  confirmation: 'create',
  not_appeared: 'noshow',
  notification: 'message',
  cancel: 'cancel',
}

export const emailTemplateSchema = baseTemplateSchema
  .extend({ type: emailTypeSchema })
  .transform(t => ({
    id: t.id,
    name: t.name,
    default: t.standard,
    action: emailEventToActionMap[t.type],
  }))

const templateSchema = z.union([smsTemplateSchema, emailTemplateSchema])
export type CommunicationTemplate = z.infer<typeof templateSchema>
