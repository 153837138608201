import { createContext, useContext, useMemo, type HTMLAttributes } from 'react'
import { append, join } from 'ramda'

const DomIdContext = createContext<string[]>([])

const joinIds = join('_')

const createDomProps = (
  fieldName: keyof HTMLAttributes<HTMLElement>,
  ids: string[],
) => ({
  [fieldName]: joinIds(ids),
})

export const createTestIdProps = (id: string) => {
  if (import.meta.env.MODE === 'production') return null

  return createDomProps('data-qa', [id])
}

const useAddNewDomId = (id: string) => {
  const ids = useContext(DomIdContext)

  return useMemo(() => append(id, ids), [ids, id])
}

export const useDomIdProps = (id: string) => {
  const ids = useAddNewDomId(id)

  return useMemo(() => createDomProps('id', ids), [ids])
}

const withDomId =
  (id: string) =>
  <T extends object>(
    Component: React.FunctionComponent<T>,
  ): React.FunctionComponent<T> =>
  props => {
    const ids = useAddNewDomId(id)

    return (
      <DomIdContext.Provider value={ids}>
        <Component {...props} />
      </DomIdContext.Provider>
    )
  }

export default withDomId
