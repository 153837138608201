import React from 'react'
import { Box, useMediaQuery, type Theme } from '@mui/material'

import { type AreaInterface } from 'src/entities/area/types/area'
import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import { useFurnitureQuery } from 'src/entities/floor-plan/queries/furniture'
import { useLabelsQuery } from 'src/entities/label/queries/label'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useRoomsQuery } from 'src/entities/room/queries/room'
import { useServiceTimesQuery } from 'src/entities/service-time/queries/serviceTime'
import {
  type TableInterface,
  type TableLockInterface,
} from 'src/entities/table/types/table'
import CircularProgress from 'src/shared/components/common/CircularProgress'
import { DisabledOverlay } from 'src/shared/components/common/DisabledOverlay/DisabledOverlay'
import CenteredBox from 'src/shared/components/containers/CenteredBox'
import { useIsDateDeferredContext } from 'src/shared/lib/context/global/useIsDateDeferredContext'
import { useSelectedServiceTime } from 'src/shared/lib/context/state/atoms/selectedServiceTime'
import { type FloorPlanTableInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'
import {
  useAreaFurniture,
  useAreaTables,
} from './FloorPlanView/useAreaElements'
import { type FilterPreset } from '../service/filterPreset'
import { type ReservationOccupancy } from '../service/occupancy'
import {
  isListDisplayed,
  type PresentationView,
} from '../service/presentationView'

const FloorPlanView = React.lazy(() => import('./FloorPlanView/FloorplanView'))
const ReservationsTable = React.lazy(
  () => import('./ReservationsTable/ReservationsTable'),
)
const TabularView = React.lazy(() => import('./TabularView/TabularView'))
const Timeline = React.lazy(() => import('./Timeline/Timeline'))
const ReservationList = React.lazy(
  () => import('./ReservationList/ReservationList'),
)

interface ReservationsContainerProps {
  reservations: Map<ServiceTimeInterface['id'] | null, ReservationInterface[]>
  date: Date
  onReservationButtonClick: (reservation: ReservationInterface) => void
  currentView: PresentationView
  selectedReservation: ReservationInterface | undefined
  tableLocks: TableLockInterface[]
  onReservationClick: (reservationId: ReservationInterface) => void
  onCreateNewReservation: (table: TableInterface, slot?: string) => void
  area: AreaInterface | undefined
  tables: FloorPlanTableInterface[]
  areas: AreaInterface[]
  occupancies: ReservationOccupancy[]
  filteredReservations: ReservationInterface[]
  filterPreset: FilterPreset
  onTableSelect: (table: TableInterface | undefined) => void
  selectedTableId: TableInterface['id'] | undefined
}

const ReservationsContainer = ({
  reservations,
  date,
  onReservationClick,
  onReservationButtonClick,
  currentView,
  selectedReservation,
  tableLocks,
  onCreateNewReservation,
  area,
  areas,
  tables,
  occupancies,
  filteredReservations,
  filterPreset,
  onTableSelect,
  selectedTableId,
}: ReservationsContainerProps) => {
  const { data: labels } = useLabelsQuery()
  const { data: rooms } = useRoomsQuery()
  const { data: serviceTimes } = useServiceTimesQuery()
  const { data: furniture } = useFurnitureQuery()

  const areaTables = useAreaTables(tables, area)
  const areaFurniture = useAreaFurniture(furniture, area)

  const selectedTable = React.useMemo(
    () => tables.find(table => table.id === selectedTableId),
    [selectedTableId, tables],
  )

  const [openAreas, setOpenAreas] = React.useState<AreaInterface[]>([])

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'))
  const showList = isMobile || isListDisplayed(currentView)

  const selectedServiceTime = useSelectedServiceTime()
  const shownReservations = React.useMemo(
    () => reservations.get(selectedServiceTime?.id ?? null) ?? [],
    [reservations, selectedServiceTime],
  )

  const isDeferred = useIsDateDeferredContext()

  return (
    <>
      {showList && (
        <Box
          sx={{
            gridColumn: '1',
            borderRight: 1,
            borderColor: 'divider',
            overflow: 'hidden',
          }}
        >
          <ReservationList
            reservations={filteredReservations}
            areas={areas}
            tables={tables}
            labels={labels}
            rooms={rooms}
            onReservationClick={onReservationClick}
            onReservationButtonClick={onReservationButtonClick}
          />
        </Box>
      )}
      {!isMobile && (
        <DisabledOverlay
          disabled={isDeferred}
          sx={{ display: 'contents' }}
          innerBoxSx={{
            overflow: 'hidden',
            gridColumn: currentView === 'reservations_table' ? '1 / -1' : '2',
          }}
        >
          <React.Suspense
            fallback={
              <CenteredBox>
                <CircularProgress />
              </CenteredBox>
            }
          >
            {currentView === 'floorplan' && (
              <FloorPlanView
                tables={areaTables}
                furniture={areaFurniture}
                occupancies={occupancies}
                reservations={shownReservations}
                selectedReservation={selectedReservation}
                selectedTable={selectedTable}
                onTableSelect={onTableSelect}
                tableLocks={tableLocks}
                labels={labels}
              />
            )}
            {currentView === 'reservations_table' && (
              <ReservationsTable
                reservations={filteredReservations}
                onReservationButtonClick={onReservationButtonClick}
                onReservationClick={onReservationClick}
                selectedReservation={selectedReservation}
                labels={labels}
                tables={tables}
                rooms={rooms}
                areas={areas}
                filterPreset={filterPreset}
              />
            )}
            {currentView === 'tables_table' && (
              <TabularView
                date={date}
                occupancies={occupancies}
                reservations={shownReservations}
                tables={areaTables}
                tableLocks={tableLocks}
                selectedTable={selectedTable}
                selectedReservation={selectedReservation}
                onTableSelect={onTableSelect}
                onCreateNewReservation={onCreateNewReservation}
                labels={labels}
              />
            )}
            {currentView === 'tables_timeline' && (
              <Timeline
                reservations={shownReservations}
                areas={areas}
                tables={tables}
                date={date}
                onReservationClick={onReservationClick}
                onCreateNewReservation={onCreateNewReservation}
                selectedReservation={selectedReservation}
                tableLocks={tableLocks}
                onTableSelect={onTableSelect}
                openAreas={openAreas}
                onOpenAreasChange={setOpenAreas}
                serviceTimes={serviceTimes}
                labels={labels}
              />
            )}
          </React.Suspense>
        </DisabledOverlay>
      )}
    </>
  )
}

export default ReservationsContainer
