import { complement, compose, length } from 'ramda'

import { z, type CustomErrorParams } from 'zod'

import { type PhoneAcceptanceTimesException } from 'src/entities/schedule/types/scheduleApi'
import { getIntersection } from 'src/shared/lib/range/services/timeRange'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import { zodAlwaysRefine } from 'src/shared/lib/zod/zod'

const acceptanceSchema = z.discriminatedUnion('isActive', [
  z.object({
    isActive: z.literal(false),
  }),
  z.object({
    isActive: z.literal(true),
    acceptanceTimes: z
      .array(
        z
          .tuple([z.date(), z.date()])
          .transform(d => d as DefinedRangeInterface<Date>),
      )
      .min(1),
  }),
])

const weekdaysSchema = z.object({
  monday: acceptanceSchema,
  tuesday: acceptanceSchema,
  wednesday: acceptanceSchema,
  thursday: acceptanceSchema,
  friday: acceptanceSchema,
  saturday: acceptanceSchema,
  sunday: acceptanceSchema,
})

export const phoneConfirmationsFormSchema = z
  .discriminatedUnion('isActive', [
    z.object({
      isActive: z.literal(false),
    }),
    weekdaysSchema.extend({
      isActive: z.literal(true),
    }),
  ])
  .refine(
    val =>
      !val.isActive ||
      Object.values(val).some(v => typeof v === 'object' && v.isActive),
    {
      path: ['isActive'],
      message: 'At least 1 should be checked',
    },
  )

const getIntersections =
  (otherExceptions: PhoneAcceptanceTimesException[]) =>
  ({ dateRange }: { dateRange: DefinedRangeInterface<Date> | undefined }) =>
    dateRange
      ? otherExceptions.filter(ex =>
          getIntersection(
            [ex.effectivePeriod.start, ex.effectivePeriod.end],
            dateRange,
          ),
        )
      : []

const createOverlapErrorMessage =
  (translationKey: string) => (): CustomErrorParams => ({
    path: ['dateRange' satisfies keyof PhoneConfirmationsExceptionFormValues],
    message: translationKey,
    params: {
      i18n: translationKey,
    },
  })

export const phoneConfirmationsExceptionFormSchema = (
  otherExceptions: PhoneAcceptanceTimesException[],
) =>
  zodAlwaysRefine(
    z.discriminatedUnion('isActive', [
      z.object({
        isActive: z.literal(false),
        dateRange: z
          .array(z.date())
          .length(2)
          .transform(d => d as DefinedRangeInterface<Date>),
      }),
      weekdaysSchema.extend({
        isActive: z.literal(true),
        dateRange: z
          .array(z.date())
          .length(2)
          .transform(d => d as DefinedRangeInterface<Date>),
      }),
    ]),
  )
    .refine(
      val =>
        !val.isActive ||
        Object.values(val).some(
          v => typeof v === 'object' && !Array.isArray(v) && v.isActive,
        ),
      {
        path: ['isActive'],
        message: 'At least 1 should be checked',
      },
    )
    .refine(
      compose(complement(Boolean), length, getIntersections(otherExceptions)),
      createOverlapErrorMessage('schedule.phone_confirmations.errors.overlap'),
    )

export type PhoneConfirmationsFormValues = z.infer<
  typeof phoneConfirmationsFormSchema
>

export type PhoneConfirmationsExceptionFormValues = z.infer<
  ReturnType<typeof phoneConfirmationsExceptionFormSchema>
>
