import React from 'react'
import { append, compose, map, prop } from 'ramda'

import { type UseSuspenseQueryOptions } from '@tanstack/react-query'

import { useRestaurantHash } from 'src/shared/lib/context/global/useRestaurantHash'
import {
  useConfigQuery,
  useConfigRestaurantsQueries,
} from '../../config/queries/config'
import { type ApiConfig } from '../../config/types/configApi'
import { type RestaurantInterface } from '../../restaurant/types/restaurant'
import { createNoRoom, fromApi } from '../services/roomFactory'
import { type RoomInterface } from '../types/room'

const roomsQueryOptionsFactory = (restaurantId: RestaurantInterface['hash']) =>
  ({
    select: compose(
      append(createNoRoom(restaurantId)),
      map(fromApi),
      prop('rooms'),
    ),
  }) satisfies Partial<
    UseSuspenseQueryOptions<ApiConfig, unknown, RoomInterface[]>
  >

export const useRoomsQuery = () => {
  const restaurantHash = useRestaurantHash()
  const queryOptions = React.useMemo(
    () => roomsQueryOptionsFactory(restaurantHash),
    [restaurantHash],
  )

  return useConfigQuery(queryOptions)
}

export const useRestaurantsRooms = () =>
  useConfigRestaurantsQueries(roomsQueryOptionsFactory)
