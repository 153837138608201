import React, { type ReactNode } from 'react'
import { prop, uniqBy } from 'ramda'
import {
  InputLabel,
  Select,
  useFormControl,
  type SelectProps,
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import { areInsideRestaurant } from 'src/entities/restaurant/services/restaurant'
import { type RestaurantInterface } from 'src/entities/restaurant/types/restaurant'
import { type RoomInterface } from 'src/entities/room/types/room'
import SizeableListSubheader from 'src/shared/components/common/SizeableListSubheader'
import SelectDropdownItem from 'src/shared/components/form/inputs/SelectDropdownItem'
import { withTargetValue } from 'src/shared/lib/common/services/helpers/helpers'

const RoomSelect = ({
  selectedRoom,
  onRoomSelect,
  rooms,
  restaurants,
  ...props
}: {
  selectedRoom: RoomInterface | undefined
  onRoomSelect: (newRoom: RoomInterface) => void
  rooms: RoomInterface[]
  restaurants: RestaurantInterface[]
} & Partial<Pick<SelectProps, 'size' | 'label' | 'fullWidth'>>) => {
  const { t } = useTranslation('', { keyPrefix: 'serial_reservations' })

  const formControlState = useFormControl()
  const size = formControlState?.size ?? props?.size

  const restaurant = restaurants.find(r =>
    areInsideRestaurant([selectedRoom])(r),
  )

  const roomsRestaurants = React.useMemo(
    () => Array.from(new Set(rooms.map(room => room.restaurantId))),
    [rooms],
  )

  return (
    <>
      {!!props.label && <InputLabel>{props.label}</InputLabel>}
      <Select
        value={selectedRoom}
        renderValue={room =>
          [restaurant?.name, room?.name || t('noRoom')]
            .filter(Boolean)
            .join(' / ')
        }
        onChange={withTargetValue(onRoomSelect)}
        size={size}
        {...props}
      >
        {roomsRestaurants.reduce((selectItems, restaurantId) => {
          const restaurantRooms = uniqBy(
            prop('id'),
            rooms.filter(r => r.restaurantId === restaurantId),
          )

          if (!restaurantRooms.length) return selectItems

          const restaurantName = restaurants.find(
            r => r.hash === restaurantId,
          )?.name

          return [
            ...selectItems,
            restaurantName && (
              <SizeableListSubheader
                key={restaurantName}
                size={size}
                disableGutters
              >
                {restaurantName}
              </SizeableListSubheader>
            ),
            ...restaurantRooms.map(room => (
              <SelectDropdownItem
                key={`${room.id}_${restaurantId}`}
                size={size}
                value={room}
              >
                {room.name || t('noRoom')}
              </SelectDropdownItem>
            )),
          ]
        }, [] as ReactNode[])}
      </Select>
    </>
  )
}

export default RoomSelect
