import { type Closure } from 'src/entities/schedule/types/scheduleApi'
import { type FormClosure } from './schema'
import {
  fromFormValidityScope,
  toFormValidityScope,
} from '../ValidityScopeInput/service/mappers'

export const toClosureFormData = (closure: Closure) => {
  const { id, scope, effectivePeriod, ...rest } = closure

  return {
    ...rest,
    effectivePeriod: [effectivePeriod.start, effectivePeriod.end],
    scope: toFormValidityScope(scope),
  } satisfies FormClosure
}

export const fromClosureFormData = ({
  effectivePeriod,
  scope,
  ...rest
}: FormClosure) =>
  ({
    ...rest,
    effectivePeriod: { start: effectivePeriod[0], end: effectivePeriod[1] },
    scope: fromFormValidityScope(scope),
  }) satisfies Omit<Closure, 'id'>
