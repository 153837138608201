import React from 'react'

import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import {
  ReservationColumnsIdsEnum,
  type ReservationInterface,
} from 'src/entities/reservation/types/reservation'
import { type IsoDateString } from 'src/shared/lib/range/services/date'
import VirtualTable from 'src/widgets/Reservation/Table/VirtualTable'
import { useColumns } from './useColumns'

interface ReservationsTableProps {
  serialDates: IsoDateString[]
  datesDeselection: string[]
  updateReservation: (
    reservationIndex: number,
    value: Partial<ReservationInterface>,
  ) => void
  reservations: ReservationInterface[]
  toggleDateSelection: (IsoDateString: string) => void
}

const ReservationsTable = ({
  serialDates,
  datesDeselection,
  updateReservation,
  reservations,
  toggleDateSelection,
}: ReservationsTableProps) => {
  const columns = useColumns(
    updateReservation,
    serialDates,
    toggleDateSelection,
  )

  const deselection = React.useMemo(
    () =>
      datesDeselection
        .map(date => serialDates.indexOf(date))
        .reduce(
          (newDeselection, index) => ({
            ...newDeselection,
            [index]: true,
          }),
          {},
        ),
    [datesDeselection, serialDates],
  )

  const table = useReactTable({
    data: reservations,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [
        {
          id: ReservationColumnsIdsEnum.StartDate,
          desc: false,
        },
      ],
    },
    state: {
      rowSelection: deselection,
    },
    defaultColumn: {
      enableSorting: false,
      minSize: 0,
      size: 0,
    },
    enableSortingRemoval: false,
  })

  const { rows } = table.getSortedRowModel()

  return <VirtualTable rows={rows} headerGroups={table.getHeaderGroups()} />
}

export default ReservationsTable
