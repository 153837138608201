import { Stack, styled, type StackProps } from '@mui/material'

const Container = styled(Stack)(({ theme }) =>
  theme.unstable_sx({
    p: 1,
    backgroundColor: 'black',
    color: 'white',
    flexDirection: 'row',
  }),
)
const FooterContainer = (props: StackProps) => <Container {...props} />

export { FooterContainer }
