import { andThen, composeWith, prop } from 'ramda'

import {
  convertKeysToCamelCase,
  type ApiClient,
} from 'src/shared/lib/api/services/api'
import { restaurantInfoSchema } from '../types/info'

export const getInfo =
  // eslint-disable-next-line @typescript-eslint/require-await
  (httpClient: ApiClient) => async (restaurantHash?: string) =>
    composeWith(andThen)([
      (d: unknown) => restaurantInfoSchema.parse(d),
      convertKeysToCamelCase,
      prop('info'),
      httpClient,
    ])({
      url: 'restaurants/info',
      ...(restaurantHash ? { json: { restaurant_hash: restaurantHash } } : {}),
    })
