/* eslint-disable react-hooks/rules-of-hooks */
import { intersection, useWith } from 'ramda'

import type { Shift } from 'src/entities/schedule/types/scheduleApi'
import * as timeRangeService from 'src/shared/lib/range/services/timeRange'
import type { TimeRangeInterface } from 'src/shared/lib/range/types/timeRange'
import { shiftTimeRange } from './intersectionValidation'
import { type FormShift } from './shiftFormSchema'

export const getTimeRangeIntersection = useWith(
  timeRangeService.getIntersection,
  [shiftTimeRange, shiftTimeRange],
)

export interface ShiftOverlap {
  type: 'shift'
  shift: Shift
  conflictingWeekdays: number[]
  conflictingTimeRange: TimeRangeInterface
}

export interface IntersectableShift extends Pick<Shift, 'weekdays'> {
  arrivalTimes: Pick<
    Shift['arrivalTimes'],
    'firstArrivalTime' | 'lastArrivalTime'
  >
}

const formShiftToIntersectable = ({
  firstArrivalTime,
  lastArrivalTime,
  weekdays,
}: Partial<FormShift>): IntersectableShift | null => {
  if (!firstArrivalTime || !lastArrivalTime || !weekdays) return null

  return { arrivalTimes: { firstArrivalTime, lastArrivalTime }, weekdays }
}

export const getShiftIntersection =
  (testShift: Partial<FormShift>) =>
  (shift: Shift): ShiftOverlap | null => {
    const intersectableShift = formShiftToIntersectable(testShift)

    if (!intersectableShift) return null

    const conflictingTimeRange = getTimeRangeIntersection(
      intersectableShift,
      shift,
    )

    if (!conflictingTimeRange) return null

    const conflictingWeekdays = intersection(
      intersectableShift.weekdays,
      shift.weekdays,
    )

    if (!conflictingWeekdays.length) return null

    return {
      type: 'shift',
      shift,
      conflictingWeekdays,
      conflictingTimeRange,
    }
  }

interface GetOtherRelevantShiftLikesDeps<
  T extends Pick<Shift, 'id' | 'isActive' | 'roomId'>,
> {
  others: T[]
  original: T | undefined
}

export const getOtherRelevantShiftLikesFactory =
  <T extends Pick<Shift, 'id' | 'isActive' | 'roomId'>>({
    others,
    original,
  }: GetOtherRelevantShiftLikesDeps<T>) =>
  <U extends Pick<FormShift, 'enabled' | 'roomId'>>({
    enabled,
    roomId: rawRoomId,
  }: U) => {
    if (!enabled) return []

    const otherActive = others.filter(
      e => e.isActive && (!original || e.id !== original.id),
    )

    const roomId = Number(rawRoomId)

    if (!roomId) return otherActive

    return otherActive.filter(s => !s.roomId || s.roomId === roomId)
  }
