import { type StackProps } from '@mui/material'

import {
  ReservationNotesEnum,
  type ReservationChoiceInterface,
} from 'src/entities/reservation/types/reservation'
import { NOTES_COLOURS } from 'src/widgets/Inbox/Lists/List/TodoNotification/Body/Details/Reservation/Labels'
import { StaticNote } from '../StaticNote/StaticNote'

interface ReservationOptionProps extends Omit<StackProps, 'title'> {
  options?: ReservationChoiceInterface[]
}

export const ReservationOptions = ({
  options,
  ...props
}: ReservationOptionProps) => {
  if (!options?.length) return null

  return options.map(({ question, answer }, idx) => (
    <StaticNote
      key={idx}
      title={question}
      note={answer}
      accent={NOTES_COLOURS[ReservationNotesEnum.Choices]}
      {...props}
    />
  ))
}
