import { Box, Tab, Tabs } from '@mui/material'

import {
  useMatches,
  useNavigate,
  type RegisteredRouter,
  type RoutePaths,
} from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import { SettingsScreensEnum } from 'src/entities/setting/types/settings'
import { useSettingsHelpers } from '../hooks/useSettingsHelpers'

const routes: Record<
  SettingsScreensEnum,
  RoutePaths<RegisteredRouter['routeTree']>
> = {
  areas: '/restaurants/$restaurantId/config/areas',
  general: '/restaurants/$restaurantId/config/general',
  integrations: '/restaurants/$restaurantId/config/integrations',
  labels: '/restaurants/$restaurantId/config/labels',
  permissions: '/restaurants/$restaurantId/config/permissions',
  rooms: '/restaurants/$restaurantId/config/rooms',
  schedule: '/restaurants/$restaurantId/config/schedule',
  serviceTimes: '/restaurants/$restaurantId/config/service-times',
  tables: '/restaurants/$restaurantId/config/tables',
  teamMembers: '/restaurants/$restaurantId/config/employees',
}

interface ScreenTabsProps {
  restaurantId: number
}

const ScreensTabs = ({ restaurantId }: ScreenTabsProps) => {
  const { t } = useTranslation('', { keyPrefix: 'settings.tab_names' })
  const navigate = useNavigate()
  const matches = useMatches()

  const { shouldShowTab } = useSettingsHelpers()

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      <Tabs
        value={matches.at(-1)?.fullPath}
        sx={{ flexGrow: 1 }}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {Object.values(SettingsScreensEnum).map(screen => {
          if (!shouldShowTab(screen)) return null

          return (
            <Tab
              id={`settings_${screen}`}
              label={t(screen)}
              value={routes[screen]}
              key={screen}
              onClick={() =>
                navigate({ to: routes[screen], params: { restaurantId } })
              }
            />
          )
        })}
      </Tabs>
    </Box>
  )
}

export default ScreensTabs
