import React from 'react'

import { read } from 'src/entities/notification/api/todoApi'
import {
  NotificationTypeEnum,
  type NotificationInterface,
} from 'src/entities/notification/types/notification'
import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'

export const useMarkAsReadEffect = (notifications: NotificationInterface[]) => {
  const [lastReadId, setLastReadId] = React.useState('')

  const apiClient = useApiClient()

  React.useEffect(() => {
    const lastMeaningfulNotificationId = notifications.filter(
      n => n.type === NotificationTypeEnum.TodoItemCreated,
    )[0]?.id

    if (
      lastReadId === lastMeaningfulNotificationId ||
      !lastMeaningfulNotificationId
    )
      return

    void read(apiClient.post)(lastMeaningfulNotificationId)
    setLastReadId(lastMeaningfulNotificationId)
  }, [apiClient.post, lastReadId, notifications])
}
