import { useEffect } from 'react'
import { Stack, Typography } from '@mui/material'

import { useController, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useFeature from 'src/entities/info/hooks/useFeature'
import useCurrentTeamMember from 'src/entities/team-member/hooks/useCurrentTeamMember'
import { isAdmin } from 'src/entities/team-member/services/teamMember'
import { type TeamMemberUpdateInterface } from 'src/entities/team-member/types/teamMember'
import Tooltip from 'src/shared/components/common/Tooltip'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import Pin from './Pin'

export const SecuritySection = () => {
  const { t } = useTranslation()

  const {
    unregister,
    control,
    formState: { defaultValues },
    getValues,
    setValue,
  } = useFormContext<TeamMemberUpdateInterface>()

  const currentTeamMember = useCurrentTeamMember()

  const {
    field: { value: role, onChange: setRole },
  } = useController({ name: 'role', control })

  const {
    field: { value: pinRequired, onChange: setPinRequired },
  } = useController({ name: 'pinRequired', control })

  const pinFeatureEnabled = useFeature('pinEnabled')

  useEffect(() => {
    if (pinRequired) return

    unregister('newPin')
  }, [pinRequired, unregister])

  useEffect(() => {
    if (getValues('pinRequired')) return
    if (role === 'team_member') return

    setValue('pinRequired', true)
  }, [getValues, role, setValue])

  if (!pinFeatureEnabled) return null

  const isEditorAdmin = isAdmin(currentTeamMember)
  const isEdit = !!defaultValues?.id
  const isAdminEdit = isEdit && isAdmin(defaultValues)

  return (
    <Stack gap={1.5}>
      <Typography variant="labelSmall">
        {t('security_section_title', 'Security')}
      </Typography>
      {isEditorAdmin && !isAdminEdit && (
        <Stack gap={1} direction="row" alignItems="center">
          <Toggle
            checked={role === 'manager'}
            onChange={e =>
              setRole(e.target.checked ? 'manager' : 'team_member')
            }
            label={t('settings.team_members.form.manager_role', 'Manager role')}
          />
          <Tooltip
            text={t(
              'settings.team_members.form.manager_role_tooltip',
              'Manager role - Users with this role can perform actions defined by admin',
            )}
          />
        </Stack>
      )}

      <Toggle
        label={t(
          'settings.team_members.form.add_pin_protection',
          'Add Pin protection',
        )}
        checked={pinRequired}
        onChange={setPinRequired}
        sx={{ marginRight: '15px' }}
        disabled={role === 'manager' || isAdminEdit}
      />

      {pinRequired && (
        <Stack pl={6} gap={3}>
          <Pin
            required={pinRequired}
            label={t('settings.team_members.form.newPin', 'New PIN')}
            name="newPin"
          />
        </Stack>
      )}
    </Stack>
  )
}
