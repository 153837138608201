import { z } from 'zod'

import {
  getTranslatedStringSchema,
  type ScheduleLanguage,
} from 'src/entities/schedule/types/scheduleApi'
import { formValidityScopeSchema } from '../../ClosureForm/ValidityScopeInput/service/schema'

const getDependentTranslatedSchemaItem =
  (
    question: ReservationQuestionFormValues['question'],
    defaultLang: ScheduleLanguage,
    requiredWhenDefined: boolean,
  ) =>
  (lang: ScheduleLanguage) => {
    const defaultReturn = requiredWhenDefined
      ? z.string().min(1)
      : z.string().nullable()

    if (lang === defaultLang) return defaultReturn

    const isQuestionSet = Object.entries(question).some(
      ([key, value]) => key === lang && value,
    )

    if (!isQuestionSet) return z.null()

    return defaultReturn
  }

const getDependentTranslatedSchema = (
  question: ReservationQuestionFormValues['question'],
  defaultLang: ScheduleLanguage,
  requiredWhenDefined = true,
) => {
  const getItem = getDependentTranslatedSchemaItem(
    question,
    defaultLang,
    requiredWhenDefined,
  )

  return z.object({
    en: getItem('en'),
    de: getItem('de'),
    fr: getItem('fr'),
    it: getItem('it'),
  })
}

const validitySchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('always'),
  }),
  z.object({
    type: z.literal('date-range'),
    dateRange: z.array(z.date()).length(2),
  }),
])

const getSchema = (defaultLang: ScheduleLanguage) =>
  z.object({
    id: z.string().optional(),
    name: z.string().trim().min(1),
    isActive: z.boolean(),
    validity: validitySchema,
    scope: formValidityScopeSchema,
    question: getTranslatedStringSchema(defaultLang),
    hint: getTranslatedStringSchema(),
    options: z.array(getTranslatedStringSchema(defaultLang)).min(1),
    isMandatory: z.boolean(),
  })

export const getReservationQuestionFormSchema = (
  defaultLang: ScheduleLanguage,
  question: ReservationQuestionFormValues['question'],
) =>
  getSchema(defaultLang)
    .omit({ options: true, hint: true })
    .extend({
      options: z
        .array(getDependentTranslatedSchema(question, defaultLang))
        .min(1),
      hint: getDependentTranslatedSchema(question, defaultLang, false),
    })

export type ReservationQuestionFormValues = z.infer<
  ReturnType<typeof getSchema>
>
