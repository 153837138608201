import { Stack } from '@mui/material'

import { FormProvider, useForm } from 'react-hook-form'

import { type LabelInterface } from 'src/entities/label/types/label'
import FormButtons from 'src/shared/components/buttons/FormButtons'
import Color from './Color'
import Name from './Name'

const EditForm = ({
  label,
  labels,
  onSubmit,
  onDelete,
  onCancel,
}: {
  label: LabelInterface
  labels: LabelInterface[]
  onSubmit: (label: LabelInterface) => void
  onDelete: (label: LabelInterface) => void
  onCancel: () => void
}) => {
  const form = useForm<LabelInterface>({
    defaultValues: label,
  })

  const { handleSubmit } = form

  return (
    <FormProvider {...form}>
      <Stack direction="column" spacing={2}>
        <Name />
        <Color labels={labels} />
        <FormButtons
          onCancel={onCancel}
          onDelete={() => onDelete(label)}
          onSave={handleSubmit(onSubmit)}
          showDeleteButton={!!label.id}
        />
      </Stack>
    </FormProvider>
  )
}

export default EditForm
