import { z } from 'zod'

import { type ScheduleDefaultSettings } from 'src/entities/schedule/types/scheduleApi'
import {
  formBookingSetupSchema,
  formReservationConfirmationSchema,
  formReservationGuestCountLimitSchema,
} from '../ShiftForm/services/formSchema'

export const defaultSettingsFormSchema = z.object({
  reservationGuestCountLimit: formReservationGuestCountLimitSchema,
  bookingSetup: formBookingSetupSchema.pick({
    timeInterval: true,
    bookingDuration: true,
    earliestTime: true,
  }),
  reservationConfirmation: formReservationConfirmationSchema,
})

export type FormDefaultSettings = z.infer<typeof defaultSettingsFormSchema>

export const toFormDefaultSettings = (
  ds: ScheduleDefaultSettings,
): FormDefaultSettings => ({
  bookingSetup: {
    bookingDuration: ds.duration,
    timeInterval: ds.interval,
    earliestTime: ds.bookingWindowStart,
  },
  reservationConfirmation: ds.asyncReservationConfirmation,
  reservationGuestCountLimit: {
    min: ds.minPaxPerReservation,
    max: ds.maxPaxPerReservation,
  },
})
export const fromFormDefaultSettings = (
  fds: FormDefaultSettings,
): ScheduleDefaultSettings => ({
  asyncReservationConfirmation: fds.reservationConfirmation,
  interval: fds.bookingSetup.timeInterval,
  duration: fds.bookingSetup.bookingDuration,
  minPaxPerReservation: fds.reservationGuestCountLimit.min,
  maxPaxPerReservation: fds.reservationGuestCountLimit.max,
  bookingWindowStart: fds.bookingSetup.earliestTime,
})
