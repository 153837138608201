import React from 'react'
import { ascend, compose, identity, prop, sortWith } from 'ramda'

import {
  useReservations,
  useReservationsSearchQuery,
} from 'src/entities/reservation/queries/reservation'
import {
  getNumber,
  getStartDate,
} from 'src/entities/reservation/services/reservation'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { replaceBy } from 'src/shared/lib/common/services/functional/functional'
import { getBoundaries } from 'src/shared/lib/range/services/date'
import { FiltersEnum } from 'src/shared/lib/reducer/service/reservationsFilter'

const updateWithReservation =
  (selectedReservation: ReservationInterface) =>
  (reservations: ReservationInterface[]) =>
    replaceBy(prop('id'), selectedReservation, reservations)

export const useReservationsOfDate = (
  date: Date,
  selectedReservation?: ReservationInterface,
): ReservationInterface[] => {
  const { data: ids } = useReservationsSearchQuery(
    React.useMemo(
      () => ({
        [FiltersEnum.dateTime]: getBoundaries(date),
      }),
      [date],
    ),
  )

  const reservations = useReservations(ids)

  return React.useMemo(
    () =>
      compose(
        sortWith([ascend(getStartDate), ascend(getNumber)]),
        selectedReservation
          ? updateWithReservation(selectedReservation)
          : identity,
      )(reservations),
    [reservations, selectedReservation],
  )
}
