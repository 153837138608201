import { type SxProps, type Theme } from '@mui/material'

import { type LabelInterface } from 'src/entities/label/types/label'
import Label from 'src/shared/components/common/Label'
import { getEmoji } from 'src/shared/lib/string/services/text'

const getLabelInitials = (label: LabelInterface) =>
  getEmoji(label.name) ?? label.name.substring(0, 2)

const ExtraSmallLabel = ({
  label,
  sx,
}: {
  label: LabelInterface
  sx?: SxProps<Theme>
}) => (
  <Label
    color={label.color}
    label={getLabelInitials(label)}
    size="small"
    variant="filled"
    sx={{
      py: 0.25,
      px: 0.5,
      height: 20,
      typography: 'labelExtrasmall',
      borderRadius: 4,
      ...sx,
    }}
  />
)

export default ExtraSmallLabel
