import { useEffect } from 'react'
import { sortBy } from 'ramda'
import { Stack, Typography } from '@mui/material'

import { Trans } from 'react-i18next'

import Tooltip from 'src/shared/components/common/Tooltip'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import {
  formatTime,
  fromIsoDuration,
  fromTime,
} from 'src/shared/lib/range/services/date'
import { interpolate } from 'src/shared/lib/range/services/timeRange'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import PacingButton from './components/PacingButton/PacingButton'
import PacingChart from './components/PacingChart/PacingChart'
import PacingModal from './components/PacingModal/PacingModal'
import PacingToggle from './components/PacingToggle/PacingToggle'
import { useBaseFormContext } from '../../hooks/form'

const useInitializePacingsEffect = () => {
  const { watch, setValue, getValues } = useBaseFormContext()

  const pacingsEnabled = watch('customPacing.isActive')
  const firstArrivalTime = watch('firstArrivalTime')
  const lastArrivalTime = watch('lastArrivalTime')
  const interval = watch('bookingSetup.timeInterval')
  const limit = watch('shiftGuestCountLimit')

  useEffect(() => {
    if (!pacingsEnabled || !firstArrivalTime || !lastArrivalTime) return

    const timeRange = [
      fromTime(firstArrivalTime),
      fromTime(lastArrivalTime),
    ] as DefinedRangeInterface<Date>

    const timeSlots = interpolate(
      timeRange,
      'minutes',
      fromIsoDuration(interval, 'minutes'),
      true,
    ).map(formatTime)

    const oldPacings = getValues('customPacing.pacings')

    const preservedPacings = oldPacings.filter(pacing =>
      timeSlots.includes(pacing.time),
    )
    const newSlots = timeSlots.filter(
      time => !preservedPacings.some(pacing => pacing.time === time),
    )
    const newPacings = newSlots.map(time => ({
      time,
      allocation: limit,
    }))

    const sortedNewPacings = sortBy(
      p => p.time,
      [...preservedPacings, ...newPacings],
    )

    setValue('customPacing.pacings', sortedNewPacings)
  }, [
    firstArrivalTime,
    getValues,
    interval,
    lastArrivalTime,
    limit,
    pacingsEnabled,
    setValue,
  ])
}

const useResetPacingsEffect = () => {
  const { watch, setValue } = useBaseFormContext()

  const pacingsEnabled = watch('customPacing.isActive')

  useEffect(() => {
    if (pacingsEnabled) return

    setValue('customPacing.pacings', [])
  }, [pacingsEnabled, setValue])
}

const useClampPacingsToLimitEffect = () => {
  const { watch, setValue, getValues } = useBaseFormContext()

  const limit = watch('shiftGuestCountLimit')

  useEffect(() => {
    const pacings = getValues('customPacing.pacings')

    if (!pacings) return

    setValue(
      'customPacing.pacings',
      pacings.map(p => ({ ...p, allocation: Math.min(p.allocation, limit) })),
    )
  }, [limit, getValues, setValue])
}

const GuestFlowSection = () => {
  const { watch } = useBaseFormContext()

  const pacingsEnabled = watch('customPacing.isActive')

  const { open, handleOpen, handleClose } = useDialog()

  useInitializePacingsEffect()
  useResetPacingsEffect()
  useClampPacingsToLimitEffect()

  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1}>
          <PacingToggle />
          <Tooltip
            text={
              <Trans
                i18nKey="schedule.shifts.guests_flow_section.tooltip_text"
                defaults="<p>Control how guests are distributed throughout the shift to ensure a smooth service.</p><p>This setting allows you to set a maximum number of guests or reservations for each time slot.</p><p>Once this limit is reached, the specific time slot will no longer be available for new bookings.</p>"
                components={{ p: <Typography variant="body3" /> }}
              />
            }
          />
        </Stack>
        <PacingButton
          onClick={handleOpen}
          sx={{
            ...(!pacingsEnabled && { visibility: 'hidden' }),
          }}
        />
      </Stack>
      <PacingChart
        show={pacingsEnabled}
        pacing={watch('customPacing.pacings')}
        limit={watch('shiftGuestCountLimit')}
      />
      {pacingsEnabled && <PacingModal open={open} onClose={handleClose} />}
    </Stack>
  )
}

export default GuestFlowSection
