import React from 'react'
import { equals } from 'ramda'
import { type BoxProps, type Theme } from '@mui/material'

import { type PersistedLabelInterface } from 'src/entities/label/types/label'
import LabelPicker from './LabelPicker'

const toggleProps: BoxProps = {
  sx: {
    transition: (theme: Theme) => theme.transitions.create('opacity'),
    opacity: 1,
    [`tr:hover &`]: {
      opacity: 1,
    },
  },
}

const LabelsCell = ({
  labels,
  selectedLabelsIds,
  onChange,
}: {
  labels: PersistedLabelInterface[]
  selectedLabelsIds: PersistedLabelInterface['id'][]
  onChange: (labels: PersistedLabelInterface[]) => void
}) => {
  const selectedLabels = selectedLabelsIds
    .map(labelId => labels.find(l => l.id === labelId))
    .filter(Boolean) as PersistedLabelInterface[]

  return (
    <LabelPicker
      selectedLabels={selectedLabels}
      labels={labels}
      onSelectedLabelsChange={onChange}
      toggleButtonContainerProps={toggleProps}
    />
  )
}

export default React.memo(
  LabelsCell,
  (prevProps, nextProps) =>
    prevProps.labels === nextProps.labels &&
    equals(nextProps.selectedLabelsIds, prevProps.selectedLabelsIds),
)
