import { isNil, map } from 'ramda'

import {
  adjustCapacityByShiftReservation,
  adjustCapacityBySlotReservation,
  hasEnoughSeats,
  partitionByShift,
  partitionByTime,
} from 'src/entities/availability/services/availability'
import {
  SlotStatus,
  type AvailabilityInterface,
} from 'src/entities/availability/types/availability'
import {
  getReservationShift,
  getStartDate,
} from 'src/entities/reservation/services/reservation'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { type TimeRangeAware } from 'src/shared/lib/range/types/timeRange'

export const compensatePristineReservation =
  (reservation: ReservationInterface, shifts: TimeRangeAware[]) =>
  (availabilities: AvailabilityInterface[]): AvailabilityInterface[] => {
    if (!reservation?.id) return availabilities

    const reservationShift = getReservationShift(shifts)(reservation)

    if (!reservationShift) return availabilities

    const reservationTime = getStartDate(reservation)

    const [shiftAvailabilities, nonShiftAvailabilities] =
      partitionByShift(reservationShift)(availabilities)

    const [reservationAvailabilities, remainingShiftAvailabilities] =
      partitionByTime(reservationTime)(shiftAvailabilities)

    return [
      ...map(
        adjustCapacityBySlotReservation(reservation),
        reservationAvailabilities,
      ),
      ...map(
        adjustCapacityByShiftReservation(reservation),
        remainingShiftAvailabilities,
      ),
      ...nonShiftAvailabilities,
    ]
  }

export const getAvailabilityStatus =
  (seatCount: number) =>
  (availability: AvailabilityInterface | null | undefined): SlotStatus => {
    if (isNil(availability)) return SlotStatus.NotInShift
    if (hasEnoughSeats(seatCount)(availability)) return SlotStatus.Available

    return SlotStatus.Unavailable
  }
