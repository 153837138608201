import { type LoadingButtonProps } from '@mui/lab'

import { useTranslation } from 'react-i18next'

import { LoadingButton } from 'src/shared/components/buttons/LoadingButton/LoadingButton'

interface SaveButtonProps extends Omit<LoadingButtonProps, 'onClick'> {
  onClick: () => Promise<void>
}

const SaveButton = (props: SaveButtonProps) => {
  const { t } = useTranslation()

  return (
    <LoadingButton variant="contained" color="primary" {...props}>
      {t('schedule.payment_plan.save_button_label', {
        defaultValue: 'Save',
        tDescription: 'Label for the save button in payment plan forms',
      })}
    </LoadingButton>
  )
}

export default SaveButton
