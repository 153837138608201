import { type ComponentProps } from 'react'

import { useTranslation } from 'react-i18next'

import { LoadingButton } from '../../LoadingButton/LoadingButton'

interface SaveButtonProps extends ComponentProps<typeof LoadingButton> {
  text?: string
}

const SaveButton = ({ text, ...props }: SaveButtonProps) => {
  const { t } = useTranslation()

  return (
    <LoadingButton color="primary" variant="contained" {...props}>
      {text ?? t('common.form_buttons.saveButtonLabel')}
    </LoadingButton>
  )
}

export default SaveButton
