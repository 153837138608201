import { useEffect, useMemo } from 'react'
import { difference, intersection } from 'ramda'

import { useTranslation } from 'react-i18next'

import { ChipChoice } from 'src/shared/components/form/inputs/ChipChoice/ChipChoice'
import {
  getWeekdayName,
  weekdayNumbers,
} from 'src/shared/lib/range/services/date'
import {
  useBaseFormController,
  useEventShiftFormContext,
} from '../../../hooks/form'
import { getWeekdaysInRange } from '../../../services/intersectionValidation'

interface WeekdaysProps {
  checkEffectivePeriod?: boolean
  disabled?: boolean
}

const Weekdays = ({
  disabled = false,
  checkEffectivePeriod,
}: WeekdaysProps) => {
  const { t } = useTranslation()

  const { watch, getValues } = useEventShiftFormContext()
  const {
    field: { value: weekdays, onChange: setWeekdays },
    fieldState,
  } = useBaseFormController('weekdays')

  const effectivePeriod = checkEffectivePeriod ? watch('effectivePeriod') : null

  const weekdaysInEffectivePeriod = useMemo(
    () =>
      checkEffectivePeriod
        ? getWeekdaysInRange(effectivePeriod)
        : weekdayNumbers,
    [checkEffectivePeriod, effectivePeriod],
  )

  useEffect(() => {
    if (!weekdaysInEffectivePeriod) return

    setWeekdays(intersection(getValues('weekdays'), weekdaysInEffectivePeriod))
  }, [setWeekdays, getValues, weekdaysInEffectivePeriod])

  return (
    <ChipChoice
      value={weekdays}
      onChange={setWeekdays}
      itemToString={getWeekdayName}
      availableSelection={weekdayNumbers}
      disabledItems={difference(weekdayNumbers, weekdaysInEffectivePeriod)}
      label={t('schedule.shifts.general_section.weekdays', 'Valid weekdays')}
      disabled={disabled}
      error={fieldState.error?.message}
      sx={{ maxWidth: 'max-content' }}
      chipSx={{ minWidth: 68 }}
    />
  )
}

export default Weekdays
