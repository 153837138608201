import { Box } from '@mui/material'

import { addToDate, getHours } from 'src/shared/lib/range/services/date'

interface GridTicksProps {
  columns: number
  gridStart: Date
}

const GridTicks = ({ columns, gridStart }: GridTicksProps) => {
  const hours = Math.floor(columns / 4 - 0.5)

  const ticks = Array.from({ length: hours }, (_, i) =>
    addToDate(i + 1, 'h', gridStart),
  )

  return (
    <>
      {ticks.map((tick, i) => (
        <Box
          key={tick.toISOString()}
          sx={{
            gridColumn: `${i * 4 + 3} / span 4`,
            height: 1,
            gridRow: 1,
            justifyContent: 'center',
            display: 'flex',
            typography: 'labelExtrasmall',
            color: 'text.quaternary',
          }}
        >
          {getHours(tick)}
        </Box>
      ))}
    </>
  )
}

export default GridTicks
