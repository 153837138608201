import {
  ReservationSourceEnum,
  ReservationTypesEnum,
  type ReservationInterface,
} from '../types/reservation'

export const getType = (
  reservation: ReservationInterface,
): ReservationTypesEnum => {
  if (reservation.serial) return ReservationTypesEnum.Serial
  if (reservation.source === ReservationSourceEnum.Self)
    return ReservationTypesEnum.Self

  return ReservationTypesEnum.External
}

export const hasType =
  (types: ReservationTypesEnum[]) =>
  (reservation: ReservationInterface): boolean =>
    types.includes(getType(reservation))

export const hasSource =
  (sources: ReservationSourceEnum[]) =>
  (reservation: ReservationInterface): boolean =>
    sources.includes(reservation.source)
