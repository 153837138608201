import { type SlotInterface } from 'src/entities/availability/types/availability'
import ChoiceGrid from 'src/shared/components/containers/ChoiceGrid'
import { isDateSame, toTimestamp } from 'src/shared/lib/range/services/date'
import TimeslotChoiceItem from './TimeslotChoiceItem'

const TimeRangeSlots = ({
  slots,
  selectedSlotTime,
  onSlotSelect,
  label,
}: {
  slots: SlotInterface[]
  selectedSlotTime: Date
  onSlotSelect: (slot: SlotInterface) => void
  label?: string | null
}) => {
  const isTimeSlotSelected = isDateSame(selectedSlotTime)

  return (
    <ChoiceGrid label={label} typography="labelSmall" color="text.primary">
      {slots.map(slot => (
        <TimeslotChoiceItem
          key={toTimestamp(slot.time)}
          time={slot.time}
          onSelect={() => onSlotSelect(slot)}
          selected={isTimeSlotSelected(slot.time)}
          status={slot.status}
        />
      ))}
    </ChoiceGrid>
  )
}

export default TimeRangeSlots
