import { useMemo, useState } from 'react'

import {
  MonthlyPatternEnum,
  ReservationGroupPatternUnitEnum,
  type ReservationGroup,
} from 'src/entities/reservation/services/reservationGroupFactory'
import {
  dropDst,
  getSerialDates,
  isoWeekdayToRrule,
} from 'src/entities/reservation/services/serialReservations'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { combineDateWithTime } from 'src/shared/lib/range/services/date'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'

export const useSerialReservations = (
  selectedReservation: ReservationInterface,
) => {
  const [date, setDate] = useState<DefinedRangeInterface<Date>>([
    selectedReservation.dateRange[0],
    selectedReservation.dateRange[0],
  ])
  const [timeRange, setTimeRange] = useState(selectedReservation.dateRange)
  const [interval, setInterval] = useState(1)
  const [unit, setUnit] = useState(ReservationGroupPatternUnitEnum.Day)
  const [monthday, setMonthday] = useState(MonthlyPatternEnum.DayOfMonth)
  const [selectedWeekdays, setSelectedWeekdays] = useState<number[]>([])

  const isDateSelected = !!date?.[0] && !!date[1]

  const rruleWeekdays = useMemo(
    () => selectedWeekdays.map(isoWeekdayToRrule),
    [selectedWeekdays],
  )

  const dates = useMemo(
    () =>
      isDateSelected
        ? getSerialDates(unit)(
            dropDst(date[0]),
            dropDst(date[1]),
            interval,
            rruleWeekdays,
            monthday,
          )
        : [],
    [date, interval, isDateSelected, monthday, rruleWeekdays, unit],
  )

  const reservationTemplate: typeof selectedReservation = useMemo(
    () => ({
      ...selectedReservation,
      dateRange: [
        combineDateWithTime(selectedReservation.dateRange[0], timeRange[0]),
        combineDateWithTime(selectedReservation.dateRange[1], timeRange[1]),
      ],
    }),
    [selectedReservation, timeRange],
  )

  const serialTemplate = useMemo(
    () =>
      ({
        startDate: date[0],
        endDate: date[1],
        pattern: {
          monthday,
          interval,
          weekdays: rruleWeekdays,
          unit,
        },
      }) satisfies ReservationGroup,
    [date, interval, monthday, rruleWeekdays, unit],
  )

  return {
    date,
    setDate,
    timeRange,
    setTimeRange,
    interval,
    setInterval,
    unit,
    setUnit,
    monthday,
    setMonthday,
    selectedWeekdays,
    setSelectedWeekdays,
    dates,
    reservationTemplate,
    serialTemplate,
  }
}
