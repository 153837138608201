import { useTranslation } from 'react-i18next'

import ReservationDrawerCheckbox from 'src/shared/components/form/inputs/ReservationDrawerCheckbox'

interface LockProps {
  locked: boolean
  onLockedChange: (newLocked: boolean) => void
}

const Lock = ({ locked, onLockedChange }: LockProps) => {
  const { t } = useTranslation()

  return (
    <ReservationDrawerCheckbox
      label={t('angular.blocked')}
      sx={{ textTransform: 'capitalize' }}
      checked={locked}
      onChange={(_e, checked) => onLockedChange(checked)}
    />
  )
}

export default Lock
