import { paperClasses, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useRoomsQuery } from 'src/entities/room/queries/room'
import { type Shift } from 'src/entities/shift-instance/types/shift'
import Modal from 'src/shared/components/common/Modal'
import type useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { ShiftLockControls } from './ShiftLockControls'

export interface LockShiftDialogProps {
  dialogProps: ReturnType<typeof useDialog>
  shifts: Shift[]
  isPending: boolean
  onShiftStatusChange: (
    shiftId: Shift['id'],
    status: Shift['lockStatus'],
  ) => void
}

export const ShiftManagementDialog = ({
  dialogProps: { open, handleClose },
  shifts,
  isPending,
  onShiftStatusChange,
}: LockShiftDialogProps) => {
  const { t } = useTranslation()

  const { data: rooms } = useRoomsQuery()

  return (
    <Modal
      title={t('angular.lock_shift')}
      open={open}
      onClose={handleClose}
      showCloseButton
      subtitle={t(
        'manage_shifts_dialog.info_text',
        'Lock channels on selected shifts.',
      )}
      sx={{ [`.${paperClasses.root}`]: { maxWidth: 640 } }}
    >
      <Stack gap={5}>
        {shifts.map(shift => (
          <ShiftLockControls
            key={shift.id}
            shift={shift}
            rooms={rooms}
            onShiftStatusChange={onShiftStatusChange}
            disabled={isPending}
          />
        ))}
      </Stack>
    </Modal>
  )
}
