/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './../../routes/__root'
import { Route as RestaurantsImport } from './../../routes/restaurants'
import { Route as LogoutImport } from './../../routes/logout'
import { Route as LoginImport } from './../../routes/login'
import { Route as IndexImport } from './../../routes/index'
import { Route as RestaurantsRestaurantIdImport } from './../../routes/restaurants/$restaurantId'
import { Route as RestaurantsRestaurantIdSearchImport } from './../../routes/restaurants/$restaurantId/search'
import { Route as RestaurantsRestaurantIdReservationsImport } from './../../routes/restaurants/$restaurantId/reservations'
import { Route as RestaurantsRestaurantIdMergeGuestsImport } from './../../routes/restaurants/$restaurantId/merge-guests'
import { Route as RestaurantsRestaurantIdConfigImport } from './../../routes/restaurants/$restaurantId/config'
import { Route as RestaurantsRestaurantIdActivityLogImport } from './../../routes/restaurants/$restaurantId/activity-log'
import { Route as RestaurantsRestaurantIdReservationsIndexImport } from './../../routes/restaurants/$restaurantId/reservations/index'
import { Route as RestaurantsRestaurantIdConfigIndexImport } from './../../routes/restaurants/$restaurantId/config/index'
import { Route as RestaurantsRestaurantIdReservationsVisualTablePlanImport } from './../../routes/restaurants/$restaurantId/reservations/visual-table-plan'
import { Route as RestaurantsRestaurantIdReservationsTimelineImport } from './../../routes/restaurants/$restaurantId/reservations/timeline'
import { Route as RestaurantsRestaurantIdReservationsTablePlanImport } from './../../routes/restaurants/$restaurantId/reservations/table-plan'
import { Route as RestaurantsRestaurantIdReservationsListImport } from './../../routes/restaurants/$restaurantId/reservations/list'
import { Route as RestaurantsRestaurantIdConfigTablesImport } from './../../routes/restaurants/$restaurantId/config/tables'
import { Route as RestaurantsRestaurantIdConfigServiceTimesImport } from './../../routes/restaurants/$restaurantId/config/service-times'
import { Route as RestaurantsRestaurantIdConfigScheduleImport } from './../../routes/restaurants/$restaurantId/config/schedule'
import { Route as RestaurantsRestaurantIdConfigRoomsImport } from './../../routes/restaurants/$restaurantId/config/rooms'
import { Route as RestaurantsRestaurantIdConfigPermissionsImport } from './../../routes/restaurants/$restaurantId/config/permissions'
import { Route as RestaurantsRestaurantIdConfigLabelsImport } from './../../routes/restaurants/$restaurantId/config/labels'
import { Route as RestaurantsRestaurantIdConfigIntegrationsImport } from './../../routes/restaurants/$restaurantId/config/integrations'
import { Route as RestaurantsRestaurantIdConfigGeneralImport } from './../../routes/restaurants/$restaurantId/config/general'
import { Route as RestaurantsRestaurantIdConfigEmployeesImport } from './../../routes/restaurants/$restaurantId/config/employees'
import { Route as RestaurantsRestaurantIdConfigAreasImport } from './../../routes/restaurants/$restaurantId/config/areas'

// Create/Update Routes

const RestaurantsRoute = RestaurantsImport.update({
  id: '/restaurants',
  path: '/restaurants',
  getParentRoute: () => rootRoute,
} as any)

const LogoutRoute = LogoutImport.update({
  id: '/logout',
  path: '/logout',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const RestaurantsRestaurantIdRoute = RestaurantsRestaurantIdImport.update({
  id: '/$restaurantId',
  path: '/$restaurantId',
  getParentRoute: () => RestaurantsRoute,
} as any)

const RestaurantsRestaurantIdSearchRoute =
  RestaurantsRestaurantIdSearchImport.update({
    id: '/search',
    path: '/search',
    getParentRoute: () => RestaurantsRestaurantIdRoute,
  } as any)

const RestaurantsRestaurantIdReservationsRoute =
  RestaurantsRestaurantIdReservationsImport.update({
    id: '/reservations',
    path: '/reservations',
    getParentRoute: () => RestaurantsRestaurantIdRoute,
  } as any)

const RestaurantsRestaurantIdMergeGuestsRoute =
  RestaurantsRestaurantIdMergeGuestsImport.update({
    id: '/merge-guests',
    path: '/merge-guests',
    getParentRoute: () => RestaurantsRestaurantIdRoute,
  } as any)

const RestaurantsRestaurantIdConfigRoute =
  RestaurantsRestaurantIdConfigImport.update({
    id: '/config',
    path: '/config',
    getParentRoute: () => RestaurantsRestaurantIdRoute,
  } as any)

const RestaurantsRestaurantIdActivityLogRoute =
  RestaurantsRestaurantIdActivityLogImport.update({
    id: '/activity-log',
    path: '/activity-log',
    getParentRoute: () => RestaurantsRestaurantIdRoute,
  } as any)

const RestaurantsRestaurantIdReservationsIndexRoute =
  RestaurantsRestaurantIdReservationsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => RestaurantsRestaurantIdReservationsRoute,
  } as any)

const RestaurantsRestaurantIdConfigIndexRoute =
  RestaurantsRestaurantIdConfigIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => RestaurantsRestaurantIdConfigRoute,
  } as any)

const RestaurantsRestaurantIdReservationsVisualTablePlanRoute =
  RestaurantsRestaurantIdReservationsVisualTablePlanImport.update({
    id: '/visual-table-plan',
    path: '/visual-table-plan',
    getParentRoute: () => RestaurantsRestaurantIdReservationsRoute,
  } as any)

const RestaurantsRestaurantIdReservationsTimelineRoute =
  RestaurantsRestaurantIdReservationsTimelineImport.update({
    id: '/timeline',
    path: '/timeline',
    getParentRoute: () => RestaurantsRestaurantIdReservationsRoute,
  } as any)

const RestaurantsRestaurantIdReservationsTablePlanRoute =
  RestaurantsRestaurantIdReservationsTablePlanImport.update({
    id: '/table-plan',
    path: '/table-plan',
    getParentRoute: () => RestaurantsRestaurantIdReservationsRoute,
  } as any)

const RestaurantsRestaurantIdReservationsListRoute =
  RestaurantsRestaurantIdReservationsListImport.update({
    id: '/list',
    path: '/list',
    getParentRoute: () => RestaurantsRestaurantIdReservationsRoute,
  } as any)

const RestaurantsRestaurantIdConfigTablesRoute =
  RestaurantsRestaurantIdConfigTablesImport.update({
    id: '/tables',
    path: '/tables',
    getParentRoute: () => RestaurantsRestaurantIdConfigRoute,
  } as any)

const RestaurantsRestaurantIdConfigServiceTimesRoute =
  RestaurantsRestaurantIdConfigServiceTimesImport.update({
    id: '/service-times',
    path: '/service-times',
    getParentRoute: () => RestaurantsRestaurantIdConfigRoute,
  } as any)

const RestaurantsRestaurantIdConfigScheduleRoute =
  RestaurantsRestaurantIdConfigScheduleImport.update({
    id: '/schedule',
    path: '/schedule',
    getParentRoute: () => RestaurantsRestaurantIdConfigRoute,
  } as any)

const RestaurantsRestaurantIdConfigRoomsRoute =
  RestaurantsRestaurantIdConfigRoomsImport.update({
    id: '/rooms',
    path: '/rooms',
    getParentRoute: () => RestaurantsRestaurantIdConfigRoute,
  } as any)

const RestaurantsRestaurantIdConfigPermissionsRoute =
  RestaurantsRestaurantIdConfigPermissionsImport.update({
    id: '/permissions',
    path: '/permissions',
    getParentRoute: () => RestaurantsRestaurantIdConfigRoute,
  } as any)

const RestaurantsRestaurantIdConfigLabelsRoute =
  RestaurantsRestaurantIdConfigLabelsImport.update({
    id: '/labels',
    path: '/labels',
    getParentRoute: () => RestaurantsRestaurantIdConfigRoute,
  } as any)

const RestaurantsRestaurantIdConfigIntegrationsRoute =
  RestaurantsRestaurantIdConfigIntegrationsImport.update({
    id: '/integrations',
    path: '/integrations',
    getParentRoute: () => RestaurantsRestaurantIdConfigRoute,
  } as any)

const RestaurantsRestaurantIdConfigGeneralRoute =
  RestaurantsRestaurantIdConfigGeneralImport.update({
    id: '/general',
    path: '/general',
    getParentRoute: () => RestaurantsRestaurantIdConfigRoute,
  } as any)

const RestaurantsRestaurantIdConfigEmployeesRoute =
  RestaurantsRestaurantIdConfigEmployeesImport.update({
    id: '/employees',
    path: '/employees',
    getParentRoute: () => RestaurantsRestaurantIdConfigRoute,
  } as any)

const RestaurantsRestaurantIdConfigAreasRoute =
  RestaurantsRestaurantIdConfigAreasImport.update({
    id: '/areas',
    path: '/areas',
    getParentRoute: () => RestaurantsRestaurantIdConfigRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/logout': {
      id: '/logout'
      path: '/logout'
      fullPath: '/logout'
      preLoaderRoute: typeof LogoutImport
      parentRoute: typeof rootRoute
    }
    '/restaurants': {
      id: '/restaurants'
      path: '/restaurants'
      fullPath: '/restaurants'
      preLoaderRoute: typeof RestaurantsImport
      parentRoute: typeof rootRoute
    }
    '/restaurants/$restaurantId': {
      id: '/restaurants/$restaurantId'
      path: '/$restaurantId'
      fullPath: '/restaurants/$restaurantId'
      preLoaderRoute: typeof RestaurantsRestaurantIdImport
      parentRoute: typeof RestaurantsImport
    }
    '/restaurants/$restaurantId/activity-log': {
      id: '/restaurants/$restaurantId/activity-log'
      path: '/activity-log'
      fullPath: '/restaurants/$restaurantId/activity-log'
      preLoaderRoute: typeof RestaurantsRestaurantIdActivityLogImport
      parentRoute: typeof RestaurantsRestaurantIdImport
    }
    '/restaurants/$restaurantId/config': {
      id: '/restaurants/$restaurantId/config'
      path: '/config'
      fullPath: '/restaurants/$restaurantId/config'
      preLoaderRoute: typeof RestaurantsRestaurantIdConfigImport
      parentRoute: typeof RestaurantsRestaurantIdImport
    }
    '/restaurants/$restaurantId/merge-guests': {
      id: '/restaurants/$restaurantId/merge-guests'
      path: '/merge-guests'
      fullPath: '/restaurants/$restaurantId/merge-guests'
      preLoaderRoute: typeof RestaurantsRestaurantIdMergeGuestsImport
      parentRoute: typeof RestaurantsRestaurantIdImport
    }
    '/restaurants/$restaurantId/reservations': {
      id: '/restaurants/$restaurantId/reservations'
      path: '/reservations'
      fullPath: '/restaurants/$restaurantId/reservations'
      preLoaderRoute: typeof RestaurantsRestaurantIdReservationsImport
      parentRoute: typeof RestaurantsRestaurantIdImport
    }
    '/restaurants/$restaurantId/search': {
      id: '/restaurants/$restaurantId/search'
      path: '/search'
      fullPath: '/restaurants/$restaurantId/search'
      preLoaderRoute: typeof RestaurantsRestaurantIdSearchImport
      parentRoute: typeof RestaurantsRestaurantIdImport
    }
    '/restaurants/$restaurantId/config/areas': {
      id: '/restaurants/$restaurantId/config/areas'
      path: '/areas'
      fullPath: '/restaurants/$restaurantId/config/areas'
      preLoaderRoute: typeof RestaurantsRestaurantIdConfigAreasImport
      parentRoute: typeof RestaurantsRestaurantIdConfigImport
    }
    '/restaurants/$restaurantId/config/employees': {
      id: '/restaurants/$restaurantId/config/employees'
      path: '/employees'
      fullPath: '/restaurants/$restaurantId/config/employees'
      preLoaderRoute: typeof RestaurantsRestaurantIdConfigEmployeesImport
      parentRoute: typeof RestaurantsRestaurantIdConfigImport
    }
    '/restaurants/$restaurantId/config/general': {
      id: '/restaurants/$restaurantId/config/general'
      path: '/general'
      fullPath: '/restaurants/$restaurantId/config/general'
      preLoaderRoute: typeof RestaurantsRestaurantIdConfigGeneralImport
      parentRoute: typeof RestaurantsRestaurantIdConfigImport
    }
    '/restaurants/$restaurantId/config/integrations': {
      id: '/restaurants/$restaurantId/config/integrations'
      path: '/integrations'
      fullPath: '/restaurants/$restaurantId/config/integrations'
      preLoaderRoute: typeof RestaurantsRestaurantIdConfigIntegrationsImport
      parentRoute: typeof RestaurantsRestaurantIdConfigImport
    }
    '/restaurants/$restaurantId/config/labels': {
      id: '/restaurants/$restaurantId/config/labels'
      path: '/labels'
      fullPath: '/restaurants/$restaurantId/config/labels'
      preLoaderRoute: typeof RestaurantsRestaurantIdConfigLabelsImport
      parentRoute: typeof RestaurantsRestaurantIdConfigImport
    }
    '/restaurants/$restaurantId/config/permissions': {
      id: '/restaurants/$restaurantId/config/permissions'
      path: '/permissions'
      fullPath: '/restaurants/$restaurantId/config/permissions'
      preLoaderRoute: typeof RestaurantsRestaurantIdConfigPermissionsImport
      parentRoute: typeof RestaurantsRestaurantIdConfigImport
    }
    '/restaurants/$restaurantId/config/rooms': {
      id: '/restaurants/$restaurantId/config/rooms'
      path: '/rooms'
      fullPath: '/restaurants/$restaurantId/config/rooms'
      preLoaderRoute: typeof RestaurantsRestaurantIdConfigRoomsImport
      parentRoute: typeof RestaurantsRestaurantIdConfigImport
    }
    '/restaurants/$restaurantId/config/schedule': {
      id: '/restaurants/$restaurantId/config/schedule'
      path: '/schedule'
      fullPath: '/restaurants/$restaurantId/config/schedule'
      preLoaderRoute: typeof RestaurantsRestaurantIdConfigScheduleImport
      parentRoute: typeof RestaurantsRestaurantIdConfigImport
    }
    '/restaurants/$restaurantId/config/service-times': {
      id: '/restaurants/$restaurantId/config/service-times'
      path: '/service-times'
      fullPath: '/restaurants/$restaurantId/config/service-times'
      preLoaderRoute: typeof RestaurantsRestaurantIdConfigServiceTimesImport
      parentRoute: typeof RestaurantsRestaurantIdConfigImport
    }
    '/restaurants/$restaurantId/config/tables': {
      id: '/restaurants/$restaurantId/config/tables'
      path: '/tables'
      fullPath: '/restaurants/$restaurantId/config/tables'
      preLoaderRoute: typeof RestaurantsRestaurantIdConfigTablesImport
      parentRoute: typeof RestaurantsRestaurantIdConfigImport
    }
    '/restaurants/$restaurantId/reservations/list': {
      id: '/restaurants/$restaurantId/reservations/list'
      path: '/list'
      fullPath: '/restaurants/$restaurantId/reservations/list'
      preLoaderRoute: typeof RestaurantsRestaurantIdReservationsListImport
      parentRoute: typeof RestaurantsRestaurantIdReservationsImport
    }
    '/restaurants/$restaurantId/reservations/table-plan': {
      id: '/restaurants/$restaurantId/reservations/table-plan'
      path: '/table-plan'
      fullPath: '/restaurants/$restaurantId/reservations/table-plan'
      preLoaderRoute: typeof RestaurantsRestaurantIdReservationsTablePlanImport
      parentRoute: typeof RestaurantsRestaurantIdReservationsImport
    }
    '/restaurants/$restaurantId/reservations/timeline': {
      id: '/restaurants/$restaurantId/reservations/timeline'
      path: '/timeline'
      fullPath: '/restaurants/$restaurantId/reservations/timeline'
      preLoaderRoute: typeof RestaurantsRestaurantIdReservationsTimelineImport
      parentRoute: typeof RestaurantsRestaurantIdReservationsImport
    }
    '/restaurants/$restaurantId/reservations/visual-table-plan': {
      id: '/restaurants/$restaurantId/reservations/visual-table-plan'
      path: '/visual-table-plan'
      fullPath: '/restaurants/$restaurantId/reservations/visual-table-plan'
      preLoaderRoute: typeof RestaurantsRestaurantIdReservationsVisualTablePlanImport
      parentRoute: typeof RestaurantsRestaurantIdReservationsImport
    }
    '/restaurants/$restaurantId/config/': {
      id: '/restaurants/$restaurantId/config/'
      path: '/'
      fullPath: '/restaurants/$restaurantId/config/'
      preLoaderRoute: typeof RestaurantsRestaurantIdConfigIndexImport
      parentRoute: typeof RestaurantsRestaurantIdConfigImport
    }
    '/restaurants/$restaurantId/reservations/': {
      id: '/restaurants/$restaurantId/reservations/'
      path: '/'
      fullPath: '/restaurants/$restaurantId/reservations/'
      preLoaderRoute: typeof RestaurantsRestaurantIdReservationsIndexImport
      parentRoute: typeof RestaurantsRestaurantIdReservationsImport
    }
  }
}

// Create and export the route tree

interface RestaurantsRestaurantIdConfigRouteChildren {
  RestaurantsRestaurantIdConfigAreasRoute: typeof RestaurantsRestaurantIdConfigAreasRoute
  RestaurantsRestaurantIdConfigEmployeesRoute: typeof RestaurantsRestaurantIdConfigEmployeesRoute
  RestaurantsRestaurantIdConfigGeneralRoute: typeof RestaurantsRestaurantIdConfigGeneralRoute
  RestaurantsRestaurantIdConfigIntegrationsRoute: typeof RestaurantsRestaurantIdConfigIntegrationsRoute
  RestaurantsRestaurantIdConfigLabelsRoute: typeof RestaurantsRestaurantIdConfigLabelsRoute
  RestaurantsRestaurantIdConfigPermissionsRoute: typeof RestaurantsRestaurantIdConfigPermissionsRoute
  RestaurantsRestaurantIdConfigRoomsRoute: typeof RestaurantsRestaurantIdConfigRoomsRoute
  RestaurantsRestaurantIdConfigScheduleRoute: typeof RestaurantsRestaurantIdConfigScheduleRoute
  RestaurantsRestaurantIdConfigServiceTimesRoute: typeof RestaurantsRestaurantIdConfigServiceTimesRoute
  RestaurantsRestaurantIdConfigTablesRoute: typeof RestaurantsRestaurantIdConfigTablesRoute
  RestaurantsRestaurantIdConfigIndexRoute: typeof RestaurantsRestaurantIdConfigIndexRoute
}

const RestaurantsRestaurantIdConfigRouteChildren: RestaurantsRestaurantIdConfigRouteChildren =
  {
    RestaurantsRestaurantIdConfigAreasRoute:
      RestaurantsRestaurantIdConfigAreasRoute,
    RestaurantsRestaurantIdConfigEmployeesRoute:
      RestaurantsRestaurantIdConfigEmployeesRoute,
    RestaurantsRestaurantIdConfigGeneralRoute:
      RestaurantsRestaurantIdConfigGeneralRoute,
    RestaurantsRestaurantIdConfigIntegrationsRoute:
      RestaurantsRestaurantIdConfigIntegrationsRoute,
    RestaurantsRestaurantIdConfigLabelsRoute:
      RestaurantsRestaurantIdConfigLabelsRoute,
    RestaurantsRestaurantIdConfigPermissionsRoute:
      RestaurantsRestaurantIdConfigPermissionsRoute,
    RestaurantsRestaurantIdConfigRoomsRoute:
      RestaurantsRestaurantIdConfigRoomsRoute,
    RestaurantsRestaurantIdConfigScheduleRoute:
      RestaurantsRestaurantIdConfigScheduleRoute,
    RestaurantsRestaurantIdConfigServiceTimesRoute:
      RestaurantsRestaurantIdConfigServiceTimesRoute,
    RestaurantsRestaurantIdConfigTablesRoute:
      RestaurantsRestaurantIdConfigTablesRoute,
    RestaurantsRestaurantIdConfigIndexRoute:
      RestaurantsRestaurantIdConfigIndexRoute,
  }

const RestaurantsRestaurantIdConfigRouteWithChildren =
  RestaurantsRestaurantIdConfigRoute._addFileChildren(
    RestaurantsRestaurantIdConfigRouteChildren,
  )

interface RestaurantsRestaurantIdReservationsRouteChildren {
  RestaurantsRestaurantIdReservationsListRoute: typeof RestaurantsRestaurantIdReservationsListRoute
  RestaurantsRestaurantIdReservationsTablePlanRoute: typeof RestaurantsRestaurantIdReservationsTablePlanRoute
  RestaurantsRestaurantIdReservationsTimelineRoute: typeof RestaurantsRestaurantIdReservationsTimelineRoute
  RestaurantsRestaurantIdReservationsVisualTablePlanRoute: typeof RestaurantsRestaurantIdReservationsVisualTablePlanRoute
  RestaurantsRestaurantIdReservationsIndexRoute: typeof RestaurantsRestaurantIdReservationsIndexRoute
}

const RestaurantsRestaurantIdReservationsRouteChildren: RestaurantsRestaurantIdReservationsRouteChildren =
  {
    RestaurantsRestaurantIdReservationsListRoute:
      RestaurantsRestaurantIdReservationsListRoute,
    RestaurantsRestaurantIdReservationsTablePlanRoute:
      RestaurantsRestaurantIdReservationsTablePlanRoute,
    RestaurantsRestaurantIdReservationsTimelineRoute:
      RestaurantsRestaurantIdReservationsTimelineRoute,
    RestaurantsRestaurantIdReservationsVisualTablePlanRoute:
      RestaurantsRestaurantIdReservationsVisualTablePlanRoute,
    RestaurantsRestaurantIdReservationsIndexRoute:
      RestaurantsRestaurantIdReservationsIndexRoute,
  }

const RestaurantsRestaurantIdReservationsRouteWithChildren =
  RestaurantsRestaurantIdReservationsRoute._addFileChildren(
    RestaurantsRestaurantIdReservationsRouteChildren,
  )

interface RestaurantsRestaurantIdRouteChildren {
  RestaurantsRestaurantIdActivityLogRoute: typeof RestaurantsRestaurantIdActivityLogRoute
  RestaurantsRestaurantIdConfigRoute: typeof RestaurantsRestaurantIdConfigRouteWithChildren
  RestaurantsRestaurantIdMergeGuestsRoute: typeof RestaurantsRestaurantIdMergeGuestsRoute
  RestaurantsRestaurantIdReservationsRoute: typeof RestaurantsRestaurantIdReservationsRouteWithChildren
  RestaurantsRestaurantIdSearchRoute: typeof RestaurantsRestaurantIdSearchRoute
}

const RestaurantsRestaurantIdRouteChildren: RestaurantsRestaurantIdRouteChildren =
  {
    RestaurantsRestaurantIdActivityLogRoute:
      RestaurantsRestaurantIdActivityLogRoute,
    RestaurantsRestaurantIdConfigRoute:
      RestaurantsRestaurantIdConfigRouteWithChildren,
    RestaurantsRestaurantIdMergeGuestsRoute:
      RestaurantsRestaurantIdMergeGuestsRoute,
    RestaurantsRestaurantIdReservationsRoute:
      RestaurantsRestaurantIdReservationsRouteWithChildren,
    RestaurantsRestaurantIdSearchRoute: RestaurantsRestaurantIdSearchRoute,
  }

const RestaurantsRestaurantIdRouteWithChildren =
  RestaurantsRestaurantIdRoute._addFileChildren(
    RestaurantsRestaurantIdRouteChildren,
  )

interface RestaurantsRouteChildren {
  RestaurantsRestaurantIdRoute: typeof RestaurantsRestaurantIdRouteWithChildren
}

const RestaurantsRouteChildren: RestaurantsRouteChildren = {
  RestaurantsRestaurantIdRoute: RestaurantsRestaurantIdRouteWithChildren,
}

const RestaurantsRouteWithChildren = RestaurantsRoute._addFileChildren(
  RestaurantsRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/restaurants': typeof RestaurantsRouteWithChildren
  '/restaurants/$restaurantId': typeof RestaurantsRestaurantIdRouteWithChildren
  '/restaurants/$restaurantId/activity-log': typeof RestaurantsRestaurantIdActivityLogRoute
  '/restaurants/$restaurantId/config': typeof RestaurantsRestaurantIdConfigRouteWithChildren
  '/restaurants/$restaurantId/merge-guests': typeof RestaurantsRestaurantIdMergeGuestsRoute
  '/restaurants/$restaurantId/reservations': typeof RestaurantsRestaurantIdReservationsRouteWithChildren
  '/restaurants/$restaurantId/search': typeof RestaurantsRestaurantIdSearchRoute
  '/restaurants/$restaurantId/config/areas': typeof RestaurantsRestaurantIdConfigAreasRoute
  '/restaurants/$restaurantId/config/employees': typeof RestaurantsRestaurantIdConfigEmployeesRoute
  '/restaurants/$restaurantId/config/general': typeof RestaurantsRestaurantIdConfigGeneralRoute
  '/restaurants/$restaurantId/config/integrations': typeof RestaurantsRestaurantIdConfigIntegrationsRoute
  '/restaurants/$restaurantId/config/labels': typeof RestaurantsRestaurantIdConfigLabelsRoute
  '/restaurants/$restaurantId/config/permissions': typeof RestaurantsRestaurantIdConfigPermissionsRoute
  '/restaurants/$restaurantId/config/rooms': typeof RestaurantsRestaurantIdConfigRoomsRoute
  '/restaurants/$restaurantId/config/schedule': typeof RestaurantsRestaurantIdConfigScheduleRoute
  '/restaurants/$restaurantId/config/service-times': typeof RestaurantsRestaurantIdConfigServiceTimesRoute
  '/restaurants/$restaurantId/config/tables': typeof RestaurantsRestaurantIdConfigTablesRoute
  '/restaurants/$restaurantId/reservations/list': typeof RestaurantsRestaurantIdReservationsListRoute
  '/restaurants/$restaurantId/reservations/table-plan': typeof RestaurantsRestaurantIdReservationsTablePlanRoute
  '/restaurants/$restaurantId/reservations/timeline': typeof RestaurantsRestaurantIdReservationsTimelineRoute
  '/restaurants/$restaurantId/reservations/visual-table-plan': typeof RestaurantsRestaurantIdReservationsVisualTablePlanRoute
  '/restaurants/$restaurantId/config/': typeof RestaurantsRestaurantIdConfigIndexRoute
  '/restaurants/$restaurantId/reservations/': typeof RestaurantsRestaurantIdReservationsIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/restaurants': typeof RestaurantsRouteWithChildren
  '/restaurants/$restaurantId': typeof RestaurantsRestaurantIdRouteWithChildren
  '/restaurants/$restaurantId/activity-log': typeof RestaurantsRestaurantIdActivityLogRoute
  '/restaurants/$restaurantId/merge-guests': typeof RestaurantsRestaurantIdMergeGuestsRoute
  '/restaurants/$restaurantId/search': typeof RestaurantsRestaurantIdSearchRoute
  '/restaurants/$restaurantId/config/areas': typeof RestaurantsRestaurantIdConfigAreasRoute
  '/restaurants/$restaurantId/config/employees': typeof RestaurantsRestaurantIdConfigEmployeesRoute
  '/restaurants/$restaurantId/config/general': typeof RestaurantsRestaurantIdConfigGeneralRoute
  '/restaurants/$restaurantId/config/integrations': typeof RestaurantsRestaurantIdConfigIntegrationsRoute
  '/restaurants/$restaurantId/config/labels': typeof RestaurantsRestaurantIdConfigLabelsRoute
  '/restaurants/$restaurantId/config/permissions': typeof RestaurantsRestaurantIdConfigPermissionsRoute
  '/restaurants/$restaurantId/config/rooms': typeof RestaurantsRestaurantIdConfigRoomsRoute
  '/restaurants/$restaurantId/config/schedule': typeof RestaurantsRestaurantIdConfigScheduleRoute
  '/restaurants/$restaurantId/config/service-times': typeof RestaurantsRestaurantIdConfigServiceTimesRoute
  '/restaurants/$restaurantId/config/tables': typeof RestaurantsRestaurantIdConfigTablesRoute
  '/restaurants/$restaurantId/reservations/list': typeof RestaurantsRestaurantIdReservationsListRoute
  '/restaurants/$restaurantId/reservations/table-plan': typeof RestaurantsRestaurantIdReservationsTablePlanRoute
  '/restaurants/$restaurantId/reservations/timeline': typeof RestaurantsRestaurantIdReservationsTimelineRoute
  '/restaurants/$restaurantId/reservations/visual-table-plan': typeof RestaurantsRestaurantIdReservationsVisualTablePlanRoute
  '/restaurants/$restaurantId/config': typeof RestaurantsRestaurantIdConfigIndexRoute
  '/restaurants/$restaurantId/reservations': typeof RestaurantsRestaurantIdReservationsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/login': typeof LoginRoute
  '/logout': typeof LogoutRoute
  '/restaurants': typeof RestaurantsRouteWithChildren
  '/restaurants/$restaurantId': typeof RestaurantsRestaurantIdRouteWithChildren
  '/restaurants/$restaurantId/activity-log': typeof RestaurantsRestaurantIdActivityLogRoute
  '/restaurants/$restaurantId/config': typeof RestaurantsRestaurantIdConfigRouteWithChildren
  '/restaurants/$restaurantId/merge-guests': typeof RestaurantsRestaurantIdMergeGuestsRoute
  '/restaurants/$restaurantId/reservations': typeof RestaurantsRestaurantIdReservationsRouteWithChildren
  '/restaurants/$restaurantId/search': typeof RestaurantsRestaurantIdSearchRoute
  '/restaurants/$restaurantId/config/areas': typeof RestaurantsRestaurantIdConfigAreasRoute
  '/restaurants/$restaurantId/config/employees': typeof RestaurantsRestaurantIdConfigEmployeesRoute
  '/restaurants/$restaurantId/config/general': typeof RestaurantsRestaurantIdConfigGeneralRoute
  '/restaurants/$restaurantId/config/integrations': typeof RestaurantsRestaurantIdConfigIntegrationsRoute
  '/restaurants/$restaurantId/config/labels': typeof RestaurantsRestaurantIdConfigLabelsRoute
  '/restaurants/$restaurantId/config/permissions': typeof RestaurantsRestaurantIdConfigPermissionsRoute
  '/restaurants/$restaurantId/config/rooms': typeof RestaurantsRestaurantIdConfigRoomsRoute
  '/restaurants/$restaurantId/config/schedule': typeof RestaurantsRestaurantIdConfigScheduleRoute
  '/restaurants/$restaurantId/config/service-times': typeof RestaurantsRestaurantIdConfigServiceTimesRoute
  '/restaurants/$restaurantId/config/tables': typeof RestaurantsRestaurantIdConfigTablesRoute
  '/restaurants/$restaurantId/reservations/list': typeof RestaurantsRestaurantIdReservationsListRoute
  '/restaurants/$restaurantId/reservations/table-plan': typeof RestaurantsRestaurantIdReservationsTablePlanRoute
  '/restaurants/$restaurantId/reservations/timeline': typeof RestaurantsRestaurantIdReservationsTimelineRoute
  '/restaurants/$restaurantId/reservations/visual-table-plan': typeof RestaurantsRestaurantIdReservationsVisualTablePlanRoute
  '/restaurants/$restaurantId/config/': typeof RestaurantsRestaurantIdConfigIndexRoute
  '/restaurants/$restaurantId/reservations/': typeof RestaurantsRestaurantIdReservationsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/login'
    | '/logout'
    | '/restaurants'
    | '/restaurants/$restaurantId'
    | '/restaurants/$restaurantId/activity-log'
    | '/restaurants/$restaurantId/config'
    | '/restaurants/$restaurantId/merge-guests'
    | '/restaurants/$restaurantId/reservations'
    | '/restaurants/$restaurantId/search'
    | '/restaurants/$restaurantId/config/areas'
    | '/restaurants/$restaurantId/config/employees'
    | '/restaurants/$restaurantId/config/general'
    | '/restaurants/$restaurantId/config/integrations'
    | '/restaurants/$restaurantId/config/labels'
    | '/restaurants/$restaurantId/config/permissions'
    | '/restaurants/$restaurantId/config/rooms'
    | '/restaurants/$restaurantId/config/schedule'
    | '/restaurants/$restaurantId/config/service-times'
    | '/restaurants/$restaurantId/config/tables'
    | '/restaurants/$restaurantId/reservations/list'
    | '/restaurants/$restaurantId/reservations/table-plan'
    | '/restaurants/$restaurantId/reservations/timeline'
    | '/restaurants/$restaurantId/reservations/visual-table-plan'
    | '/restaurants/$restaurantId/config/'
    | '/restaurants/$restaurantId/reservations/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/login'
    | '/logout'
    | '/restaurants'
    | '/restaurants/$restaurantId'
    | '/restaurants/$restaurantId/activity-log'
    | '/restaurants/$restaurantId/merge-guests'
    | '/restaurants/$restaurantId/search'
    | '/restaurants/$restaurantId/config/areas'
    | '/restaurants/$restaurantId/config/employees'
    | '/restaurants/$restaurantId/config/general'
    | '/restaurants/$restaurantId/config/integrations'
    | '/restaurants/$restaurantId/config/labels'
    | '/restaurants/$restaurantId/config/permissions'
    | '/restaurants/$restaurantId/config/rooms'
    | '/restaurants/$restaurantId/config/schedule'
    | '/restaurants/$restaurantId/config/service-times'
    | '/restaurants/$restaurantId/config/tables'
    | '/restaurants/$restaurantId/reservations/list'
    | '/restaurants/$restaurantId/reservations/table-plan'
    | '/restaurants/$restaurantId/reservations/timeline'
    | '/restaurants/$restaurantId/reservations/visual-table-plan'
    | '/restaurants/$restaurantId/config'
    | '/restaurants/$restaurantId/reservations'
  id:
    | '__root__'
    | '/'
    | '/login'
    | '/logout'
    | '/restaurants'
    | '/restaurants/$restaurantId'
    | '/restaurants/$restaurantId/activity-log'
    | '/restaurants/$restaurantId/config'
    | '/restaurants/$restaurantId/merge-guests'
    | '/restaurants/$restaurantId/reservations'
    | '/restaurants/$restaurantId/search'
    | '/restaurants/$restaurantId/config/areas'
    | '/restaurants/$restaurantId/config/employees'
    | '/restaurants/$restaurantId/config/general'
    | '/restaurants/$restaurantId/config/integrations'
    | '/restaurants/$restaurantId/config/labels'
    | '/restaurants/$restaurantId/config/permissions'
    | '/restaurants/$restaurantId/config/rooms'
    | '/restaurants/$restaurantId/config/schedule'
    | '/restaurants/$restaurantId/config/service-times'
    | '/restaurants/$restaurantId/config/tables'
    | '/restaurants/$restaurantId/reservations/list'
    | '/restaurants/$restaurantId/reservations/table-plan'
    | '/restaurants/$restaurantId/reservations/timeline'
    | '/restaurants/$restaurantId/reservations/visual-table-plan'
    | '/restaurants/$restaurantId/config/'
    | '/restaurants/$restaurantId/reservations/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  LoginRoute: typeof LoginRoute
  LogoutRoute: typeof LogoutRoute
  RestaurantsRoute: typeof RestaurantsRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  LoginRoute: LoginRoute,
  LogoutRoute: LogoutRoute,
  RestaurantsRoute: RestaurantsRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/login",
        "/logout",
        "/restaurants"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/logout": {
      "filePath": "logout.tsx"
    },
    "/restaurants": {
      "filePath": "restaurants.tsx",
      "children": [
        "/restaurants/$restaurantId"
      ]
    },
    "/restaurants/$restaurantId": {
      "filePath": "restaurants/$restaurantId.tsx",
      "parent": "/restaurants",
      "children": [
        "/restaurants/$restaurantId/activity-log",
        "/restaurants/$restaurantId/config",
        "/restaurants/$restaurantId/merge-guests",
        "/restaurants/$restaurantId/reservations",
        "/restaurants/$restaurantId/search"
      ]
    },
    "/restaurants/$restaurantId/activity-log": {
      "filePath": "restaurants/$restaurantId/activity-log.tsx",
      "parent": "/restaurants/$restaurantId"
    },
    "/restaurants/$restaurantId/config": {
      "filePath": "restaurants/$restaurantId/config.tsx",
      "parent": "/restaurants/$restaurantId",
      "children": [
        "/restaurants/$restaurantId/config/areas",
        "/restaurants/$restaurantId/config/employees",
        "/restaurants/$restaurantId/config/general",
        "/restaurants/$restaurantId/config/integrations",
        "/restaurants/$restaurantId/config/labels",
        "/restaurants/$restaurantId/config/permissions",
        "/restaurants/$restaurantId/config/rooms",
        "/restaurants/$restaurantId/config/schedule",
        "/restaurants/$restaurantId/config/service-times",
        "/restaurants/$restaurantId/config/tables",
        "/restaurants/$restaurantId/config/"
      ]
    },
    "/restaurants/$restaurantId/merge-guests": {
      "filePath": "restaurants/$restaurantId/merge-guests.tsx",
      "parent": "/restaurants/$restaurantId"
    },
    "/restaurants/$restaurantId/reservations": {
      "filePath": "restaurants/$restaurantId/reservations.tsx",
      "parent": "/restaurants/$restaurantId",
      "children": [
        "/restaurants/$restaurantId/reservations/list",
        "/restaurants/$restaurantId/reservations/table-plan",
        "/restaurants/$restaurantId/reservations/timeline",
        "/restaurants/$restaurantId/reservations/visual-table-plan",
        "/restaurants/$restaurantId/reservations/"
      ]
    },
    "/restaurants/$restaurantId/search": {
      "filePath": "restaurants/$restaurantId/search.tsx",
      "parent": "/restaurants/$restaurantId"
    },
    "/restaurants/$restaurantId/config/areas": {
      "filePath": "restaurants/$restaurantId/config/areas.tsx",
      "parent": "/restaurants/$restaurantId/config"
    },
    "/restaurants/$restaurantId/config/employees": {
      "filePath": "restaurants/$restaurantId/config/employees.tsx",
      "parent": "/restaurants/$restaurantId/config"
    },
    "/restaurants/$restaurantId/config/general": {
      "filePath": "restaurants/$restaurantId/config/general.tsx",
      "parent": "/restaurants/$restaurantId/config"
    },
    "/restaurants/$restaurantId/config/integrations": {
      "filePath": "restaurants/$restaurantId/config/integrations.tsx",
      "parent": "/restaurants/$restaurantId/config"
    },
    "/restaurants/$restaurantId/config/labels": {
      "filePath": "restaurants/$restaurantId/config/labels.tsx",
      "parent": "/restaurants/$restaurantId/config"
    },
    "/restaurants/$restaurantId/config/permissions": {
      "filePath": "restaurants/$restaurantId/config/permissions.tsx",
      "parent": "/restaurants/$restaurantId/config"
    },
    "/restaurants/$restaurantId/config/rooms": {
      "filePath": "restaurants/$restaurantId/config/rooms.tsx",
      "parent": "/restaurants/$restaurantId/config"
    },
    "/restaurants/$restaurantId/config/schedule": {
      "filePath": "restaurants/$restaurantId/config/schedule.tsx",
      "parent": "/restaurants/$restaurantId/config"
    },
    "/restaurants/$restaurantId/config/service-times": {
      "filePath": "restaurants/$restaurantId/config/service-times.tsx",
      "parent": "/restaurants/$restaurantId/config"
    },
    "/restaurants/$restaurantId/config/tables": {
      "filePath": "restaurants/$restaurantId/config/tables.tsx",
      "parent": "/restaurants/$restaurantId/config"
    },
    "/restaurants/$restaurantId/reservations/list": {
      "filePath": "restaurants/$restaurantId/reservations/list.tsx",
      "parent": "/restaurants/$restaurantId/reservations"
    },
    "/restaurants/$restaurantId/reservations/table-plan": {
      "filePath": "restaurants/$restaurantId/reservations/table-plan.tsx",
      "parent": "/restaurants/$restaurantId/reservations"
    },
    "/restaurants/$restaurantId/reservations/timeline": {
      "filePath": "restaurants/$restaurantId/reservations/timeline.tsx",
      "parent": "/restaurants/$restaurantId/reservations"
    },
    "/restaurants/$restaurantId/reservations/visual-table-plan": {
      "filePath": "restaurants/$restaurantId/reservations/visual-table-plan.tsx",
      "parent": "/restaurants/$restaurantId/reservations"
    },
    "/restaurants/$restaurantId/config/": {
      "filePath": "restaurants/$restaurantId/config/index.tsx",
      "parent": "/restaurants/$restaurantId/config"
    },
    "/restaurants/$restaurantId/reservations/": {
      "filePath": "restaurants/$restaurantId/reservations/index.tsx",
      "parent": "/restaurants/$restaurantId/reservations"
    }
  }
}
ROUTE_MANIFEST_END */
