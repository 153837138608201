import {
  useMutation,
  useSuspenseQuery,
  type UseSuspenseQueryResult,
} from '@tanstack/react-query'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { useRestaurantCacheKeyFactory } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import type { MaybeNull } from './reservation'
import {
  addReservationGroup,
  getReservationGroup,
} from '../services/reservationGroupApi'
import type { ReservationGroup } from '../services/reservationGroupFactory'

const CACHE_KEY = ['reservation-group']

const useCacheKeyFactory = () => useRestaurantCacheKeyFactory(CACHE_KEY)

export const useAddReservationGroupMutation = () => {
  const apiClient = useApiClient()

  return useMutation({
    mutationFn: addReservationGroup(apiClient.post),
  })
}

export const useReservationGroupQuery = <T extends number | undefined>(
  id: T,
): UseSuspenseQueryResult<MaybeNull<T, ReservationGroup>> => {
  const apiClient = useApiClient()
  const keyFactory = useCacheKeyFactory()

  return useSuspenseQuery({
    queryKey: keyFactory([id]),
    queryFn: () => {
      if (!id) return Promise.resolve(null)

      return getReservationGroup(apiClient.post)(id)
    },
    retryOnMount: false,
    refetchOnMount: false,
    staleTime: 30 * 60 * 1000,
  })
}
