import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { dateRangeFormatter } from 'src/pages/CustomerPreview/Reservations/Header/Filters/HotelStays/services'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import IntersectionLineContainer from './IntersectionLineContainer'
import Weekdays from './Weekdays'
import type {
  EventOverlap,
  ExceptionOverlap,
} from '../../../../../services/periodAwareValidation'

interface DateRangeOverlapProps {
  intersection: EventOverlap | ExceptionOverlap
  name: string
  weekdays: number[]
}

const DateRange = ({
  dateRange,
  weekdaysOverlap,
  weekdays,
}: {
  dateRange: DefinedRangeInterface<Date>
  weekdaysOverlap: number[]
  weekdays: number[]
}) => {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <Stack direction="column" spacing={0.5}>
      <Typography variant="inherit">
        {dateRangeFormatter(language)(dateRange)}
      </Typography>
      <Weekdays
        activeWeekdays={weekdays}
        overlappingWeekdays={weekdaysOverlap}
      />
    </Stack>
  )
}

const DateRangeOverlap = ({
  intersection,
  name,
  weekdays,
}: DateRangeOverlapProps) => (
  <IntersectionLineContainer
    name={name}
    dateOverlap={
      <DateRange
        weekdays={weekdays}
        weekdaysOverlap={intersection.conflictingWeekdays}
        dateRange={intersection.conflictingDateRange}
      />
    }
    timeOverlap={intersection.conflictingTimeRange}
  />
)

export default DateRangeOverlap
