export interface State<T> {
  stack: T[]
}

export interface StackService<T> {
  addItem: (item: T) => State<T>
  clearItems: () => State<T>
}

export type StackReducer = <T>(state: State<T>) => StackService<T>

const stackReducer: StackReducer = state => ({
  addItem: newItem => ({ stack: [newItem, ...state.stack] }),
  clearItems: () => ({ stack: [] }),
})

export default stackReducer
