import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import { useNotificationsTemplatesQuery } from 'src/entities/schedule/queries/schedule'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import Notifications from './components/Notifications'
import FormSection from '../../../FormSection'
import { useBaseFormContext } from '../../hooks/form'
import { createDefaultNotificationTemplates } from '../../services/shiftFormSchema'
import { sectionKeyToLabel } from '../FormNavigation/FormNavigation'

const NotificationsSection = () => {
  const { t } = useTranslation()

  const { watch, register, getValues, setValue } = useBaseFormContext()
  const notificationsEnabled = watch('notifications.isActive')

  const { data: templates } = useNotificationsTemplatesQuery()

  useEffect(() => {
    if (notificationsEnabled) return

    setValue(
      'notifications.templates',
      createDefaultNotificationTemplates(templates),
    )
  }, [getValues, notificationsEnabled, setValue, templates])

  return (
    <FormSection
      title={sectionKeyToLabel(t)('notifications')}
      tooltipText={t(
        'schedule.shifts.notifications_section.tooltipText',
        'Pick templates for each notification category.',
      )}
    >
      <Toggle
        label={t(
          'schedule.shifts.notifications_section.enabled_toggle_label',
          'Use custom settings',
        )}
        {...register('notifications.isActive')}
      />
      {notificationsEnabled && <Notifications />}
    </FormSection>
  )
}

export default NotificationsSection
