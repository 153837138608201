import { lt } from 'ramda'

export const isIndicatorRequired: (
  visibleItemsCount: number,
  totalItemsCount: number,
) => boolean = lt

export const calculateVisibleItemsCount = (
  totalItemsCount: number,
  visibleItemsCount: number,
  indicatorIsVisible: boolean,
) => {
  const indicatorIsRequired = isIndicatorRequired(
    visibleItemsCount,
    totalItemsCount,
  )

  if (indicatorIsVisible || !indicatorIsRequired) return visibleItemsCount

  return Math.max(0, visibleItemsCount - 1)
}
