import { Suspense } from 'react'
import { Stack } from '@mui/material'

import { type NotificationInterface } from 'src/entities/notification/types/notification'
import CircularProgress from 'src/shared/components/common/CircularProgress'
import CenteredBox from 'src/shared/components/containers/CenteredBox'
import Header from './Header'
import Lists from './Lists'

interface InboxProps {
  onClose: () => void
  onNotificationClick: (notification: NotificationInterface) => void
  isCacheUpdating: boolean
}

const Inbox = ({
  onClose,
  onNotificationClick,
  isCacheUpdating,
}: InboxProps) => (
  <Stack
    direction="column"
    sx={{
      height: '100%',
      justifyContent: 'space-between',
    }}
  >
    <Header onClose={onClose} />
    <Suspense
      fallback={
        <CenteredBox>
          <CircularProgress />
        </CenteredBox>
      }
    >
      <Lists
        onNotificationClick={onNotificationClick}
        isCacheUpdating={isCacheUpdating}
      />
    </Suspense>
  </Stack>
)

export default Inbox
