import { useTranslation } from 'react-i18next'

import { useDeleteEventMutation } from 'src/entities/schedule/queries/schedule'
import {
  type Schedule,
  type ScheduleEvent,
} from 'src/entities/schedule/types/scheduleApi'
import { getScheduleEventProperties } from './service'
import { useDefaultMutationHandlers } from '../../ShiftConfiguration/hooks/useDefaultMutationHandlers'
import { CreateScheduleButton } from '../components'
import { ScheduleCard, ScheduleCardWrapper } from '../components/ScheduleCard'
import { useExceptionDateRangeFormatter } from '../service'
import { getShiftLikeWithRoomName } from '../ShiftSection/service'

interface EventSectionProps {
  schedule: Schedule
  onEditEvent: (event?: ScheduleEvent) => void
}

export const EventSection = ({ schedule, onEditEvent }: EventSectionProps) => {
  const { t } = useTranslation()

  const { mutateAsync: deleteEvent } = useDeleteEventMutation()
  const { onError } = useDefaultMutationHandlers()

  const formatDateRange = useExceptionDateRangeFormatter()

  return (
    <>
      <ScheduleCardWrapper>
        {schedule.events.map(e => (
          <ScheduleCard
            key={e.id}
            title={getShiftLikeWithRoomName({ shiftLike: e, schedule })}
            onEdit={() => onEditEvent(e)}
            onDelete={() => deleteEvent(e, { onError })}
            properties={getScheduleEventProperties({
              shiftLike: e,
              schedule,
              formatDateRange,
              t,
            })}
            isInactive={!e.isActive}
          />
        ))}
      </ScheduleCardWrapper>
      <CreateScheduleButton onClick={() => onEditEvent()}>
        {t('schedule.overview.event.create', 'Create event')}
      </CreateScheduleButton>
    </>
  )
}
