import { useCallback, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { showToast } from 'src/shared/components/common/toast'
import { ApiError } from 'src/shared/lib/api/types/api'

export const useDefaultMutationHandlers = (onSuccessCallback?: () => void) => {
  const { t } = useTranslation()

  const onSuccess = useCallback(() => {
    onSuccessCallback?.()
    showToast(t('common.save_successful', 'Save successful'), 'success')
  }, [onSuccessCallback, t])

  const onError = useCallback(
    (e: Error) => {
      const message = (e instanceof ApiError && e.message) || undefined

      showToast(
        t('common.something_went_wrong', 'Something went wrong'),
        'error',
        message &&
          t(
            `schedule.api_errors.${message}`,
            `Placeholder for schedule.api_errors.${message}`,
          ),
      )
    },
    [t],
  )

  return useMemo(() => ({ onSuccess, onError }), [onError, onSuccess])
}
