import { type ApiClient } from 'src/shared/lib/api/services/api'
import { type PersistedLabelInterface } from '../types/label'

export const setLabelsOrder =
  (httpClient: ApiClient) => async (labels: PersistedLabelInterface[]) => {
    await httpClient({
      url: 'labels/reorder',
      json: { order: labels.map(l => l.id) },
    })
  }
