import { Ellipse } from 'react-konva'

import { getRelativeCenter } from 'src/entities/floor-plan/services/geometry'
import { GRID_STEP } from 'src/entities/table/services/tableGrid'
import { type FloorPlanShapeComponentProps } from '../types/floorPlanElement'

const EllipticElement = ({
  element,
  shapeProps,
}: FloorPlanShapeComponentProps) => {
  const center = getRelativeCenter(element.size)
  return (
    <Ellipse
      radiusX={Math.max(center.x, GRID_STEP / 2)}
      radiusY={Math.max(center.y, GRID_STEP / 2)}
      strokeScaleEnabled={false}
      {...center}
      {...shapeProps}
    />
  )
}

export default EllipticElement
