import { complement, compose, groupWith, length } from 'ramda'

import { type DefaultValues } from 'react-hook-form'
import { z } from 'zod'

import {
  type NotificationTemplate,
  type ScheduleDefaultSettings,
  type Shift,
} from 'src/entities/schedule/types/scheduleApi'
import { toIsoDuration } from 'src/shared/lib/range/services/date'
import { isNotFalsy, zodAlwaysRefine } from 'src/shared/lib/zod/zod'
import {
  baseFormSchema,
  channelLimitMaximumEnforcer,
  createArrivalTimeMessage,
  fromBase,
  toBase,
  type BaseFormSchemaDeps,
  type DefaultNotificationTemplate,
} from './formSchema'
import { getShiftIntersection } from './shiftValidation'

const shiftFormSchema = (d: BaseFormSchemaDeps) =>
  baseFormSchema(d).extend({
    name: z
      .string()
      .nonempty(d.t('form_validation.required', 'Field is required.')),
    enabled: z.boolean(),
    roomId: z.coerce.number(),
  })

export type FormShift = z.infer<ReturnType<typeof shiftFormSchema>>

export const createDefaultNotificationTemplates = (
  templates: NotificationTemplate[],
) => {
  /**
   * Ramda `groupWith` only works with adjacent items, so it needs
   * to be sorted
   */
  const sortedTemplates = templates.slice().sort((t1, t2) => {
    if (t1.type !== t2.type) return t1.type.localeCompare(t2.type)
    if (t1.channel !== t2.channel) return t1.channel.localeCompare(t2.channel)
    return t1.templateId.type === 'default' ? -1 : 0
  })

  const templateGroups = groupWith(
    (t1, t2) => t1.type === t2.type && t1.channel === t2.channel,
    sortedTemplates,
  )

  return templateGroups
    .map(group => group[0])
    .filter(isNotFalsy) as DefaultNotificationTemplate[]
}

export const toShift = (shift: FormShift): Omit<Shift, 'id'> => ({
  ...toBase(shift),
  name: shift.name,
  weekdays: shift.weekdays,
  isActive: shift.enabled,
  roomId: shift.roomId || null,
})

export const fromShift = (
  shift: Shift,
  templates: NotificationTemplate[],
): FormShift => ({
  ...fromBase(shift.weekdays, templates)(shift),
  name: shift.name,
  enabled: shift.isActive,
  roomId: shift.roomId ?? 0,
})

export interface ShiftLikeDefaultSettings extends ScheduleDefaultSettings {
  defaultGuestLimit?: number
}

export const newShift = (
  templates: NotificationTemplate[],
  {
    defaultGuestLimit = 0,
    asyncReservationConfirmation: { afterDeadline, beyondPaxLimit },
    bookingWindowStart,
    duration,
    interval,
    maxPaxPerReservation,
    minPaxPerReservation,
  }: ShiftLikeDefaultSettings,
) =>
  ({
    name: '',
    enabled: true,
    firstArrivalTime: undefined,
    lastArrivalTime: undefined,
    weekdays: [],
    bookingSetup: {
      timeInterval: interval,
      bookingDuration: duration,
      bookingDeadline: {
        type: 'day-of-reservation' as 'time-before-reservation',
        interval: toIsoDuration(0, 'hours'),
      },
      earliestTime: bookingWindowStart,
    },
    reservationGuestCountLimit: {
      min: minPaxPerReservation,
      max: maxPaxPerReservation,
    },
    shiftGuestCountLimit: defaultGuestLimit,
    channelAllocations: [
      {
        channel: 'online',
        limit: defaultGuestLimit,
        active: true,
      },
      {
        channel: 'google',
        limit: defaultGuestLimit,
        active: true,
      },
    ],
    reservationConfirmation: { afterDeadline, beyondPaxLimit },
    customPacing: { isActive: false, pacings: [] },
    labels: { isActive: false, labelIds: [] },
    shiftMessage: {
      isActive: false,
      message: {
        de: null,
        en: null,
        it: null,
        fr: null,
      },
    },
    roomId: 0,
    noShowFee: { isActive: false, paymentPlanId: null },
    notifications: {
      isActive: false,
      templates: createDefaultNotificationTemplates(templates),
    },
  }) satisfies DefaultValues<FormShift>

export const getIntersections =
  (shifts: Shift[]) => (formData: Partial<FormShift>) =>
    shifts.map(getShiftIntersection(formData)).filter(isNotFalsy)

interface ValidatedShiftSchemaDeps extends BaseFormSchemaDeps {
  shifts: Shift[]
}

export const validatedShiftSchema = ({
  shifts,
  ...baseDeps
}: ValidatedShiftSchemaDeps) =>
  zodAlwaysRefine(shiftFormSchema(baseDeps))
    .refine(
      compose(complement(Boolean), length, getIntersections(shifts)),
      compose(
        createArrivalTimeMessage(
          'schedule.shifts.general_section.validation.shift_overlap',
        ),
        getIntersections(shifts),
      ),
    )
    .transform(channelLimitMaximumEnforcer)
