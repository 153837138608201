import { useTranslation } from 'react-i18next'

import Toggle from 'src/shared/components/form/inputs/Toggle'
import { withTargetChecked } from 'src/shared/lib/common/services/helpers/helpers'
import { useExceptionEventFormController } from '../../../hooks/form'

const ShiftDisable = () => {
  const { t } = useTranslation()

  const {
    field: { value: checked, onChange: setChecked },
  } = useExceptionEventFormController('shiftDisabled')

  return (
    <Toggle
      label={t(
        'schedule.exceptions.general_section.shift_disabled',
        'Disable shift completely',
      )}
      checked={checked}
      onChange={withTargetChecked(setChecked)}
    />
  )
}

export default ShiftDisable
