import React from 'react'

import {
  getTableBorder,
  PRIMARY_TABLE_BG,
} from 'src/entities/floor-plan/services/floorPlanElement'
import CapacityIndicator from 'src/widgets/FloorPlan/CapacityIndicator'
import {
  FloorPlanElementType,
  type FloorPlanComponentProps,
  type FloorPlanTableInterface,
} from 'src/widgets/FloorPlan/types/floorPlanElement'
import NameLabel from './NameLabel'
import TableElement from './TableElement'
import TableTransformer from './TableTransformer'
import { useKonvaTransformerEffect } from '../useKonvaTransformerEffect'

const Table = ({
  element,
  onSelect,
  isSelected,
  onChange,
}: FloorPlanComponentProps) => {
  const { transformerRef, elementRef } = useKonvaTransformerEffect(isSelected)

  const [isTransforming, setIsTransforming] = React.useState(false)

  const startTransforming = () => setIsTransforming(true)
  const stopTransforming = () => setIsTransforming(false)

  return (
    <>
      <TableElement
        ref={elementRef}
        element={element}
        onSelect={onSelect}
        onChange={onChange}
        onTransformStart={startTransforming}
        onTransformEnd={stopTransforming}
        shapeProps={{
          fill:
            isSelected && element.type === FloorPlanElementType.Table
              ? PRIMARY_TABLE_BG
              : element.color,
          ...getTableBorder(isSelected),
        }}
      />
      {isSelected && (
        <TableTransformer element={element} ref={transformerRef} />
      )}
      {!isTransforming && (
        <CapacityIndicator table={element as FloorPlanTableInterface} />
      )}
      {!isTransforming && (
        <NameLabel table={element as FloorPlanTableInterface} />
      )}
    </>
  )
}

export default Table
