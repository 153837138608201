import React from 'react'

import { useInfoQuery } from '../queries/info'
import { type FeatureName } from '../types/info'

export type IsFeatureOn = (featureName: FeatureName) => boolean

export const useFeatureCallback = () => {
  const {
    data: { feature },
  } = useInfoQuery()

  return React.useCallback<IsFeatureOn>(
    featureName => feature[featureName],
    [feature],
  )
}

const useFeature = (featureName: FeatureName): boolean =>
  useFeatureCallback()(featureName)

export default useFeature
