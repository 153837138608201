import { Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import Tooltip from 'src/shared/components/common/Tooltip'
import PhoneCheckIcon from 'src/shared/components/icons/PhoneCheckIcon'
import { sectionKeyToLabel } from '../../FormNavigation/FormNavigation'

const SectionTitle = () => {
  const { t } = useTranslation()

  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <PhoneCheckIcon size="medium" />
        <Typography variant="labelBig">
          {sectionKeyToLabel(t)('confirmations')}
        </Typography>
        <Tooltip
          text={t(
            'schedule.shift_template_settings.ivr.tooltip',
            'Placeholder for schedule.shift_template_settings.ivr.tooltip',
          )}
        />
      </Stack>
      <Typography ml={4}>
        {t(
          'schedule.shift_template_settings.ivr.subtitle',
          'Receive phone call in order to confirm reservations created after any of the limit is reached.',
        )}
      </Typography>
    </Stack>
  )
}

export default SectionTitle
