import { createFileRoute, Outlet } from '@tanstack/react-router'

import SettingsLayout from 'src/pages/Settings/SettingsLayout'

export const Route = createFileRoute('/restaurants/$restaurantId/config')({
  component: RouteComponent,
})

function RouteComponent() {
  const { restaurantId } = Route.useParams()

  return (
    <SettingsLayout restaurantId={restaurantId}>
      <Outlet />
    </SettingsLayout>
  )
}
