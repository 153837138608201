import React from 'react'
import { juxt } from 'ramda'

import { noop } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { type CustomerInterface } from 'src/entities/customer/types/customer'
import { FullScreenModal } from 'src/shared/components/dialogs/FullScreenModal/FullScreenModal'

const Add = React.lazy(() => import('./Add'))
const Update = React.lazy(() => import('./Update'))

interface CustomerEditProps {
  customerId: number | undefined
  initPhrase?: string
  initCustomer?: Partial<CustomerInterface>
  onSubmit?: (customer: CustomerInterface) => void
  onCustomerSaved?: (customer: CustomerInterface) => void
  onCustomerCreated?: (customer: CustomerInterface) => void
  onCustomerEdited?: (customer: CustomerInterface) => void
  isOpen: boolean
  onClose: () => void
  hasBeenOpened?: boolean
}

export const CustomerEdit = ({
  customerId,
  onSubmit,
  onCustomerSaved = noop,
  onCustomerCreated = noop,
  onCustomerEdited = noop,
  isOpen,
  onClose,
  initPhrase,
  initCustomer,
  hasBeenOpened,
}: CustomerEditProps) => {
  const { t } = useTranslation()

  return (
    <FullScreenModal
      isOpen={isOpen}
      onClose={onClose}
      title={
        customerId
          ? t('customer.editTitle', { defaultValue: 'Edit guest' })
          : t('customer.createTitle', { defaultValue: 'Create new guest' })
      }
    >
      {hasBeenOpened &&
        (customerId ? (
          <Update
            customerId={customerId}
            onSubmit={onSubmit}
            onCustomerEdited={juxt([onCustomerSaved, onCustomerEdited])}
          />
        ) : (
          <Add
            initPhrase={initPhrase}
            onSubmit={onSubmit}
            onCustomerCreated={juxt([onCustomerSaved, onCustomerCreated])}
            initCustomer={initCustomer}
          />
        ))}
    </FullScreenModal>
  )
}
