import { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import { useHasPermission } from 'src/entities/team-member/hooks/useCheckPermissions'
import { useGlobalModalContext } from 'src/shared/lib/context/global/useGlobalModalContext'

export const useOnEditServiceTimeMessage = (onOpen: () => void) => {
  const { t } = useTranslation()

  const canSetMessage = useHasPermission('manage_service_time_message')
  const { showPermissionModal } = useGlobalModalContext()

  return useCallback(() => {
    if (canSetMessage) return onOpen()

    return showPermissionModal({
      message: t(
        'permissions.error.edit_service_time_message',
        "You don't have permission to edit service time message.",
      ),
    })
  }, [canSetMessage, onOpen, showPermissionModal, t])
}
