import { SwipeableDrawer, type SwipeableDrawerProps } from '@mui/material'

import { noop } from '@tanstack/react-table'

import { mergeSx } from 'src/app/theme/helpers'

const SwipeDrawer = ({
  PaperProps: { sx: paperPropsSx, ...paperProps } = { sx: {} },
  ...props
}: Omit<SwipeableDrawerProps, 'onOpen'> & {
  onOpen?: SwipeableDrawerProps['onOpen']
}) => (
  <SwipeableDrawer
    disableBackdropTransition
    disableDiscovery
    disableSwipeToOpen
    keepMounted={false}
    ModalProps={{ keepMounted: false }}
    onOpen={noop}
    {...props}
    PaperProps={{
      ...paperProps,
      sx: mergeSx(
        {
          height: '92vh',
          maxHeight: '92vh',
          marginTop: 'auto',
          top: 'initial',
          bottom: 0,
          width: '100vw',
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        },
        paperPropsSx,
      ),
    }}
  />
)

export default SwipeDrawer
