import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

const Title = () => {
  const { t } = useTranslation('', { keyPrefix: 'inbox' })

  return <Typography variant="labelHuge">{t('notifications')}</Typography>
}

export default Title
