import { __, compose, includes, update } from 'ramda'

import { type TFunction } from 'i18next'
import { RRule, Weekday } from 'rrule'

import {
  getDayOfWeek,
  getUtcDayStart,
  getWeekdayOccurrenceInMonth,
  weekdayFormatter,
} from 'src/shared/lib/range/services/date'
import { getIsoDate } from './reservation'
import {
  MonthlyPatternEnum,
  ReservationGroupPatternUnitEnum,
} from './reservationGroupFactory'
import { type ReservationInterface } from '../types/reservation'

export const generateSerialReservations = (
  template: ReservationInterface,
  dates: string[],
  persistedReservations: ReservationInterface[],
) => {
  const newReservations = Array<ReservationInterface>(dates.length).fill(
    template,
  )

  return persistedReservations.reduce(
    (allReservations, persistedReservation) => {
      const reservationDate = getIsoDate(persistedReservation)
      const reservationIndex = dates.indexOf(reservationDate)

      if (reservationIndex === -1) return allReservations

      return update(reservationIndex, persistedReservation, allReservations)
    },
    newReservations,
  )
}

export const includesReservationDate = (dates: string[]) =>
  compose(includes(__, dates), getIsoDate)

export const isoWeekdayToRrule = (isoWeekday: number): number =>
  ({
    1: 0,
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 5,
    7: 6,
  })[isoWeekday]!

const getMonthlyWeekdayDates =
  (searchFromEnd: boolean) =>
  (startDate: Date, endDate: Date, interval: number) => {
    const weekOfMonth = getWeekdayOccurrenceInMonth(startDate, searchFromEnd)
    const weekday = new Weekday(isoWeekdayToRrule(getDayOfWeek(startDate)))

    return new RRule({
      freq: RRule.MONTHLY,
      interval,
      dtstart: startDate,
      until: endDate,
      byweekday: [weekday],
      bysetpos: [weekOfMonth],
    }).all()
  }

const MONTHLY_TYPE_TO_RULE = {
  [MonthlyPatternEnum.DayOfMonth]: (
    startDate: Date,
    endDate: Date,
    interval: number,
  ) => {
    const fallbackMonthDays = Array.from(
      { length: startDate.getDate() },
      (i, k) => k + 1,
    )

    return new RRule({
      freq: RRule.MONTHLY,
      interval,
      dtstart: startDate,
      until: endDate,
      bysetpos: -1,
      bymonthday: fallbackMonthDays,
    }).all()
  },
  [MonthlyPatternEnum.WeekFromStart]: getMonthlyWeekdayDates(false),
  [MonthlyPatternEnum.WeekFromEnd]: getMonthlyWeekdayDates(true),
}

const FREQUENCY_TO_RULE = {
  [ReservationGroupPatternUnitEnum.Day]: (
    startDate: Date,
    endDate: Date,
    interval: number,
  ) =>
    new RRule({
      freq: RRule.DAILY,
      interval,
      dtstart: startDate,
      until: endDate,
    }).all(),
  [ReservationGroupPatternUnitEnum.Week]: (
    startDate: Date,
    endDate: Date,
    interval: number,
    weekdays?: number[],
  ) => {
    if (!weekdays?.length) return []

    return new RRule({
      freq: RRule.WEEKLY,
      interval,
      dtstart: startDate,
      until: endDate,
      byweekday: weekdays,
    }).all()
  },
  [ReservationGroupPatternUnitEnum.Month]: (
    startDate: Date,
    endDate: Date,
    interval: number,
    _weekdays: number[],
    type: MonthlyPatternEnum,
  ) => MONTHLY_TYPE_TO_RULE[type](startDate, endDate, interval),
}

export const dropDst = getUtcDayStart

export const getSerialDates = (frequency: ReservationGroupPatternUnitEnum) =>
  FREQUENCY_TO_RULE[frequency]

export const translateGroupPattern = (
  pattern: ReservationGroupPatternUnitEnum,
  t: TFunction,
) => {
  switch (pattern) {
    case ReservationGroupPatternUnitEnum.Day:
      return t('angular.days')
    case ReservationGroupPatternUnitEnum.Week:
      return t('angular.weeks')
    case ReservationGroupPatternUnitEnum.Month:
      return t('angular.months')
    default:
      return ''
  }
}

export const translateMonthlyPattern = (
  pattern: MonthlyPatternEnum,
  currentDate: Date,
  locale: string,
  t: TFunction,
) => {
  const weekdayName = weekdayFormatter(locale).format(currentDate)
  const notGerman = !locale.startsWith('de')

  switch (pattern) {
    case MonthlyPatternEnum.DayOfMonth:
      return t('angular.series.every_day_of_month', {
        count: currentDate.getDate(),
        ordinal: true,
      })
    case MonthlyPatternEnum.WeekFromStart:
      return t('angular.series.every_weekday_of_month', {
        count: getWeekdayOccurrenceInMonth(currentDate),
        weekday: weekdayName,
        ordinal: true,
      })
    case MonthlyPatternEnum.WeekFromEnd:
      return t('angular.series.every_last_weekday_of_month', {
        count: -getWeekdayOccurrenceInMonth(currentDate, true),
        weekday: weekdayName,
        ordinal: notGerman,
      })
    default:
      return ''
  }
}
