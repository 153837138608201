import { complement, compose, length } from 'ramda'

import { type DefaultValues } from 'react-hook-form'
import { z } from 'zod'

import {
  type NotificationTemplate,
  type ScheduleEvent,
  type Shift,
} from 'src/entities/schedule/types/scheduleApi'
import { type DefinedRangeInterface } from 'src/shared/lib/range/types/range'
import { isNotFalsy, zodAlwaysRefine } from 'src/shared/lib/zod/zod'
import {
  baseFormSchema,
  channelLimitMaximumEnforcer,
  createArrivalTimeMessage,
  fromBase,
  toBase,
  type BaseFormSchemaDeps,
} from './formSchema'
import {
  getPeriodAwareIntersection,
  type PeriodAware,
} from './periodAwareValidation'
import { newShift, type ShiftLikeDefaultSettings } from './shiftFormSchema'

const eventFormSchema = (d: BaseFormSchemaDeps) =>
  baseFormSchema(d).extend({
    effectivePeriod: z
      .tuple([z.date(), z.date()])
      .transform(r => r as DefinedRangeInterface<Date>),
    name: z
      .string()
      .nonempty(d.t('form_validation.required', 'Field is required.')),
    enabled: z.boolean(),
    roomId: z.coerce.number(),
  })

export type FormEvent = z.infer<ReturnType<typeof eventFormSchema>>

export const toEvent = (event: FormEvent): Omit<ScheduleEvent, 'id'> => ({
  ...toBase(event),
  name: event.name,
  isActive: event.enabled,
  roomId: event.roomId || null,
  effectivePeriod: {
    start: event.effectivePeriod[0],
    end: event.effectivePeriod[1],
  },
  weekdays: event.weekdays,
})

export const fromEvent = (
  event: ScheduleEvent,
  templates: NotificationTemplate[],
): FormEvent => ({
  ...fromBase(event.weekdays, templates)(event),
  name: event.name,
  enabled: event.isActive,
  roomId: event.roomId ?? 0,
  effectivePeriod: [event.effectivePeriod.start, event.effectivePeriod.end],
})

export const newEvent = (
  templates: NotificationTemplate[],
  defaultSettings: ShiftLikeDefaultSettings,
) =>
  ({
    ...newShift(templates, defaultSettings),
    effectivePeriod: undefined,
  }) satisfies DefaultValues<FormEvent>

export const getIntersections =
  (shifts: Shift[]) =>
  (periodAwares: PeriodAware[]) =>
  (formData: Partial<FormEvent>) =>
    periodAwares
      .map(getPeriodAwareIntersection(shifts)(formData))
      .filter(isNotFalsy)

export const validatedEventSchema =
  (d: BaseFormSchemaDeps) => (shifts: Shift[]) => (events: PeriodAware[]) =>
    zodAlwaysRefine(eventFormSchema(d))
      .refine(
        compose(complement(Boolean), length, getIntersections(shifts)(events)),
        compose(
          createArrivalTimeMessage(
            'schedule.events.general_section.validation.event_overlap',
          ),
          getIntersections(shifts)(events),
        ),
      )
      .transform(channelLimitMaximumEnforcer)
