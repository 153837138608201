import { Stack, type StackProps } from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'

interface ReservationListItemWrapperProps extends StackProps {
  redBgCondition: boolean
}

export const ReservationListItemWrapper = ({
  redBgCondition,
  sx,
  ...props
}: ReservationListItemWrapperProps) => (
  <Stack
    direction="row"
    alignItems="stretch"
    justifyContent="space-between"
    sx={mergeSx(
      {
        minHeight: 48,
        cursor: 'pointer',
        typography: 'body3',
        gap: 0.5,
        lineHeight: 1,
        borderRadius: 2,
        background: redBgCondition ? '#F19EA0' : '#EEEEEE',
      },
      sx,
    )}
    {...props}
  />
)
