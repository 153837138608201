import { Stack } from '@mui/material'

import DeleteButton from './DeleteButton'
import SaveButton from './SaveButton'

const IconFormButtons = ({
  onDelete,
  onSave,
}: {
  onDelete: () => void
  onSave: () => void
}) => (
  <Stack direction="row" spacing={1} sx={{ alignSelf: 'flex-end' }}>
    <DeleteButton onClick={onDelete} />
    <SaveButton onClick={onSave} />
  </Stack>
)

export default IconFormButtons
