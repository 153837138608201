import { compose, filter, includes } from 'ramda'

import { type RoomInterface } from '../../room/types/room'
import { type Shift } from '../types/shift'

export const getRoomShifts =
  (roomId?: RoomInterface['id']) => (shifts: Shift[]) => {
    if (!roomId) return shifts

    const includesRoom = compose(includes(roomId), (s: Shift) => s.rooms)

    return filter(includesRoom, shifts)
  }
