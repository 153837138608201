import { forwardRef, type ComponentRef } from 'react'
import {
  MenuItem,
  styled,
  useFormControl,
  useThemeProps,
  type MenuItemProps,
} from '@mui/material'
import { type FormControlTypeMap } from '@mui/material/FormControl/FormControl'

export interface SizeableMenuItemProps extends Omit<MenuItemProps, 'value'> {
  size?: FormControlTypeMap['props']['size']
  value: unknown
}

const SizeableMenuItemRoot = styled(MenuItem, {
  name: 'SizeableMenuItem',
  overridesResolver: (_, styles) => [styles.root],
})({})

const SizeableMenuItem = forwardRef<
  ComponentRef<typeof SizeableMenuItemRoot>,
  SizeableMenuItemProps
>((inProps, ref) => {
  const formControlState = useFormControl()

  const { value: rawValue, ...rest } = useThemeProps({
    props: { ...(formControlState as MenuItemProps), ...inProps },
    name: 'SizeableMenuItem',
  })

  const value =
    typeof rawValue === 'string' || typeof rawValue === 'number'
      ? rawValue
      : undefined

  return <SizeableMenuItemRoot value={value} ref={ref} {...rest} />
})

export default SizeableMenuItem
