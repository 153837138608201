import { compose } from 'ramda'

const dropMissingItems = <T, U>(
  identifier: (item: T) => U,
  referenceItems: T[],
) => {
  const referenceIdentifiers = referenceItems.map(identifier)

  return (items: T[] | undefined) => {
    if (!items) return []

    return items.filter(item => referenceIdentifiers.includes(identifier(item)))
  }
}

const replaceWithReferenceEquivalent = <T, U>(
  identifier: (item: T) => U,
  referenceItems: T[],
) => {
  const referenceItemsMap = new Map(
    referenceItems.map(item => [identifier(item), item]),
  )

  return (items: T[] | undefined) => {
    if (!items) return []

    return items.map(item => referenceItemsMap.get(identifier(item)) ?? item)
  }
}

export const ensureReferences = <T, U>(
  identifier: (item: T) => U,
  referenceItems: T[],
) =>
  compose(
    replaceWithReferenceEquivalent(identifier, referenceItems),
    dropMissingItems(identifier, referenceItems),
  )

export const ensureReference = <T, U>(
  identifier: (item: T) => U,
  referenceItems: T[],
) => {
  const referenceItemsMap = new Map(
    referenceItems.map(item => [identifier(item), item]),
  )

  return (item: T | undefined) => {
    if (!item) return item

    return referenceItemsMap.get(identifier(item)) ?? referenceItems[0]
  }
}
