import { useEffect, useMemo, useRef, useState } from 'react'
import { isEmpty } from 'ramda'
import {
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { diff } from 'deep-object-diff'
import { create } from 'mutative'
import { useTranslation } from 'react-i18next'

import {
  useTeamRolesQuery,
  useUpdateTeamRolesMutation,
} from 'src/entities/team-member/queries/teamMember'
import {
  PERMISSIONS,
  ROLES,
  type Permission,
  type TeamMemberRole,
} from 'src/entities/team-member/types/teamMember'
import CheckmarkIcon from 'src/shared/components/icons/CheckmarkIcon'
import { withTargetChecked } from 'src/shared/lib/common/services/helpers/helpers'

export interface PermissionTableRow {
  permission: Permission
  teamMember: boolean
  manager: boolean
}

interface TableCheckboxProps {
  permission: Permission
  role: TeamMemberRole
  rolePermissionsMap: Map<TeamMemberRole, Set<Permission>>
  onToggle: (isEnabled: boolean) => void
}

const ADMIN_ONLY_PERMISSIONS: Permission[] = [
  'permissions_tab',
  'manage_integrations',
]

const MANAGER_ONLY_PERMISSIONS: Permission[] = ['manage_team_members']

const TableCheckbox = ({
  permission,
  role,
  rolePermissionsMap,
  onToggle,
}: TableCheckboxProps) => {
  if (role === 'admin') return <CheckmarkIcon />

  return (
    <Checkbox
      checked={!!rolePermissionsMap.get(role)?.has(permission)}
      onChange={withTargetChecked(onToggle)}
    />
  )
}

const Permissions = () => {
  const { t } = useTranslation()

  const { data: originalRoleDefinitions } = useTeamRolesQuery()

  const [roleDefinitions, setRoleDefinitions] = useState(
    originalRoleDefinitions,
  )
  const rolesRef = useRef(roleDefinitions)

  const { mutate: updateRolesAndPermissions } = useUpdateTeamRolesMutation()

  const rolePermissionMap = useMemo(
    () =>
      new Map(roleDefinitions.map(rd => [rd.role, new Set(rd.permissions)])),
    [roleDefinitions],
  )

  useEffect(() => {
    rolesRef.current = roleDefinitions
  }, [roleDefinitions])

  useEffect(
    () => () => {
      if (!rolesRef.current) return
      if (isEmpty(diff(rolesRef.current, originalRoleDefinitions))) return

      updateRolesAndPermissions(rolesRef.current)
    },
    [originalRoleDefinitions, updateRolesAndPermissions],
  )

  return (
    <Stack gap={3} py={4}>
      <Stack gap={0.5}>
        <Typography variant="labelBig" gutterBottom>
          {t('settings.permissions.title', 'Manage permissions')}
        </Typography>
        <Typography variant="body3" color="grey.700">
          {t(
            'settings.permissions.subtitle',
            'Select which action are possible to perform by managers roles only.',
          )}
        </Typography>
      </Stack>

      <TableContainer>
        <Table stickyHeader sx={{ td: { verticalAlign: 'middle' } }}>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('settings.permissions.headers.permission', 'Permission')}
              </TableCell>
              {ROLES.map(role => (
                <TableCell key={role} sx={{ minWidth: 128 }}>
                  {t(`settings.permissions.headers.${role}`, role)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {PERMISSIONS.map(permission => (
              <TableRow key={permission}>
                <TableCell>
                  <Stack maxWidth={268} sx={{ wordBreak: 'break-word' }}>
                    <Typography variant="labelSmall">
                      {t(`settings.permissions.permission.${permission}.title`)}
                    </Typography>
                    <Typography variant="labelTiny" color="grey.500" mt={1}>
                      {t(
                        `settings.permissions.permission.${permission}.description`,
                      )}
                    </Typography>
                  </Stack>
                </TableCell>
                {ROLES.map(role => {
                  if (
                    role === 'team_member' &&
                    [
                      ...ADMIN_ONLY_PERMISSIONS,
                      ...MANAGER_ONLY_PERMISSIONS,
                    ].includes(permission)
                  ) {
                    return <TableCell key={role} />
                  }

                  if (
                    role === 'manager' &&
                    ADMIN_ONLY_PERMISSIONS.includes(permission)
                  ) {
                    return <TableCell key={role} />
                  }

                  return (
                    <TableCell key={role}>
                      <TableCheckbox
                        role={role}
                        permission={permission}
                        rolePermissionsMap={rolePermissionMap}
                        onToggle={() =>
                          setRoleDefinitions(
                            create(dr => {
                              const thisRole = dr.find(rd => rd.role === role)
                              if (!thisRole) return

                              if (thisRole.permissions.includes(permission)) {
                                thisRole.permissions =
                                  thisRole.permissions.filter(
                                    p => p !== permission,
                                  )
                              } else thisRole.permissions.push(permission)
                            }),
                          )
                        }
                      />
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default Permissions
