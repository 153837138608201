import { useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import MessageText from './components/MessageText/MessageText'
import MessageToggle from './components/MessageToggle/MessageToggle'
import FormSection from '../../../FormSection'
import { useBaseFormContext } from '../../hooks/form'
import { sectionKeyToLabel } from '../FormNavigation/FormNavigation'

const MessageSection = () => {
  const { t } = useTranslation()

  const { watch, setValue } = useBaseFormContext()
  const messageEnabled = watch('shiftMessage.isActive')

  useEffect(() => {
    if (messageEnabled) return

    setValue('shiftMessage.message', { de: null, en: null, fr: null, it: null })
  }, [messageEnabled, setValue])

  return (
    <FormSection
      title={sectionKeyToLabel(t)('message')}
      tooltipText={t(
        'schedule.shifts.message_section.tooltip_text',
        'Message displayed on the widget after selecting a timeslot.',
      )}
    >
      <MessageToggle />
      {messageEnabled && <MessageText />}
    </FormSection>
  )
}

export default MessageSection
