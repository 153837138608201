import React from 'react'

import type Konva from 'konva'
import { Transformer } from 'react-konva'

import { type SizeInterface } from 'src/widgets/FloorPlan/types/floorPlanElement'

const FurnitureTransformer = (
  _: unknown,
  transformerRef: React.ForwardedRef<Konva.Transformer>,
) => (
  <Transformer
    ref={transformerRef}
    rotateEnabled={false}
    boundBoxFunc={(oldBox, newBox) => {
      const newBoxSize: SizeInterface = {
        width: newBox.width,
        height: newBox.height,
      }
      if (newBoxSize.height < 10 || newBoxSize.width < 10) return oldBox

      return newBox
    }}
  />
)

export default React.forwardRef(FurnitureTransformer)
