import type React from 'react'
import {
  badgeClasses,
  Box,
  Tab,
  Tabs,
  tabsClasses,
  type TabProps,
  type TabsProps,
} from '@mui/material'

import { mergeSx } from 'src/app/theme/helpers'
import { SmallBadge } from 'src/shared/components/common/SmallBadge'

interface CounterTabProps extends Omit<TabProps, 'label'> {
  counter: number | string | undefined
  label: React.ReactNode
  selected?: boolean
}

const CounterTab = ({
  counter,
  label,
  selected = false,
  ...props
}: CounterTabProps) => (
  <Tab
    sx={{
      minWidth: 40,
      flexGrow: 1,
      minHeight: 40,
      p: 0,
      px: 1.25,
      zIndex: 1,
      color: selected ? 'grey.900' : 'grey.700',
      height: 1,
      overflow: 'visible',
      maxWidth: 'none',
    }}
    label={
      <SmallBadge
        {...(typeof counter !== 'undefined' && { badgeContent: counter })}
        color="paper"
        overlap="rectangular"
        showZero
        max={Infinity}
        sx={{
          position: 'initial',
          [`& .${badgeClasses.badge}`]: {
            color: counter === 0 ? 'grey.500' : 'grey.900',
            transform: 'none',
            transformOrigin: 'none',
            right: 4,
            top: -5,
          },
        }}
      >
        <Box sx={{ typography: 'labelSmall' }}>{label}</Box>
      </SmallBadge>
    }
    {...props}
  />
)

interface CounterTabsProps<T> extends Omit<TabsProps, 'value' | 'onChange'> {
  renderItem: (item: T) => React.ReactNode
  getCount?: (item: T) => number | undefined
  items: T[]
  getKey: (item: T) => string | number
  value: T
  onChange: (newValue: T) => void
  selectedColor?: string
}

export const TOP_BAR_BG = 'grey.50'

const CounterTabs = <T,>({
  renderItem,
  getCount,
  getKey,
  items,
  sx,
  value,
  onChange,
  selectedColor = TOP_BAR_BG,
  ...props
}: CounterTabsProps<T>) => {
  const changeHandler = (_event: unknown, newValue: T) => {
    onChange(newValue)
  }

  return (
    <Tabs
      value={value}
      onChange={changeHandler}
      sx={mergeSx(
        {
          width: [1, 'auto'],
          mb: '-1px',
          minHeight: 48,
          [`.${tabsClasses.flexContainer}`]: {
            mb: '1px',
            px: [1, 0.5],
            flexShrink: 0,
            flexGrow: 1,
          },
          [`.${tabsClasses.scroller}`]: {
            display: 'flex',
            alignItems: 'end',
          },
          [`.${tabsClasses.indicator}`]: {
            height: 41,
            backgroundColor: selectedColor,
            border: '1px solid',
            borderTopLeftRadius: 1,
            borderTopRightRadius: 1,
            borderBottom: 'none',
            borderColor: 'border',
          },
        },
        sx,
      )}
      {...props}
    >
      {items.map(item => (
        <CounterTab
          key={getKey(item)}
          value={item}
          label={renderItem(item)}
          counter={getCount?.(item)}
          selected={item === value}
        />
      ))}
    </Tabs>
  )
}

export default CounterTabs
