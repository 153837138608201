import { complement, compose, filter, reduce, useWith } from 'ramda'

import type { ServiceTimeInterface } from 'src/entities/config/types/configApi'
import {
  hasCheckInStatus,
  occupancyHasStatus,
} from 'src/entities/reservation/services/checkInStatus'
import {
  getOccupancies,
  sumSeatCount,
} from 'src/entities/reservation/services/occupancy'
import { getDateRange } from 'src/entities/reservation/services/reservation'
import { timeRangeFilter } from 'src/entities/reservation/services/reservationTableFilter'
import {
  ReservationCheckInStatusEnum,
  type ReservationInterface,
} from 'src/entities/reservation/types/reservation'
import { getServiceTimeRange } from 'src/entities/service-time/services/serviceTime'
import { TableCheckInStatusEnum } from 'src/entities/table/types/table'
import {
  mapMap,
  reduceToMap,
} from 'src/shared/lib/common/services/functional/functional'
import { areOverlapping } from 'src/shared/lib/range/services/timeRange'
import { type ReservationClientFilters } from 'src/shared/lib/reducer/service/reservationsFilter'
import { PREDEFINED_FILTERS } from './filterPreset'

export const appendOrCreate = <T>(items: T[] | undefined, item: T) => [
  ...(items ?? []),
  item,
]

export const groupReservationsByServiceTime = (
  getServiceTimes: (
    reservation: ReservationInterface,
  ) => (ServiceTimeInterface['id'] | null)[],
) => reduceToMap(appendOrCreate<ReservationInterface>, getServiceTimes)

// eslint-disable-next-line react-hooks/rules-of-hooks
export const overlapDateRange = useWith(areOverlapping, [
  getDateRange,
  getDateRange,
])

export const intersectsServiceTime =
  (reservation: ReservationInterface) => (serviceTime: ServiceTimeInterface) =>
    timeRangeFilter(getServiceTimeRange(reservation.dateRange[0])(serviceTime))(
      reservation,
    )

export const getReservationServiceTimes =
  (serviceTimes: ServiceTimeInterface[]) =>
  (reservation: ReservationInterface) =>
    filter(intersectsServiceTime(reservation), serviceTimes)

export const groupReservationsByFilters =
  (
    filterReservations: (
      filters: ReservationClientFilters,
    ) => (reservations: ReservationInterface[]) => ReservationInterface[],
  ) =>
  (customFilters: ReservationClientFilters) =>
  (reservations: ReservationInterface[]) =>
    mapMap((filters: ReservationClientFilters | undefined) =>
      filterReservations(filters ?? customFilters)(reservations),
    )(PREDEFINED_FILTERS)

export const filterActiveReservations = filter(
  complement(
    hasCheckInStatus([
      ReservationCheckInStatusEnum.Cancelled,
      ReservationCheckInStatusEnum.NoShow,
    ]),
  ),
)

export const sumCheckedOutPax = compose(
  reduce(sumSeatCount, 0),
  filter(occupancyHasStatus(TableCheckInStatusEnum.CheckedOut)),
  getOccupancies,
)
