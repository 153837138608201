import { useCallback, useState } from 'react'

import { useTranslation } from 'react-i18next'

import {
  type Integration,
  type IntegrationName,
} from 'src/entities/integration/api/integrationsApi'
import { useStartIntegrationFlowMutation } from 'src/entities/integration/queries/integrationFlows'
import { useDisconnectIntegrationMutation } from 'src/entities/integration/queries/integrations'
import { showToast } from 'src/shared/components/common/toast'
import { SimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/SimpleDialog'
import { useSimpleDialog } from 'src/shared/components/dialogs/SimpleDialog/useSimpleDialog'
import { IntegrationCard } from './IntegrationCard'
import { useIntegrationFlowEffect } from '../hooks/useIntegrationFlowEffect'

interface IntegrationItemProps {
  integrationName: IntegrationName
  status: Integration['status']
}

export const IntegrationItem = ({
  integrationName,
  status,
}: IntegrationItemProps) => {
  const { t } = useTranslation()

  const [popup, setPopup] = useState<WindowProxy | null>(null)

  const { mutate: connect, isPending: isConnectPending } =
    useStartIntegrationFlowMutation()
  const { mutate: disconnect, isPending: isDisconnectPending } =
    useDisconnectIntegrationMutation()

  const setupDialog = useSimpleDialog()

  const { isLoading: isFlowLoading } = useIntegrationFlowEffect({
    integrationName,
    popup,
    setPopup,
    setupDialog,
  })

  const isLoading =
    !!popup || isFlowLoading || isConnectPending || isDisconnectPending

  const onConnect = useCallback(() => {
    connect(
      { integrationName },
      {
        onSuccess: ({ configuration: { authorizationUrl } }) =>
          setPopup(window.open(authorizationUrl, '_blank', 'popup')),
      },
    )
  }, [connect, integrationName])

  const onDisconnect = useCallback(
    () =>
      disconnect(integrationName, {
        onSuccess: () =>
          showToast(
            t(
              'settings.integrations.successes.successful_disconnected',
              'Successful disconnected!',
            ),
            'success',
          ),
        onError: () =>
          showToast(
            t(
              'settings.integrations.errors.error_while_disconnecting',
              'An error occurred while disconnecting.',
            ),
            'error',
          ),
      }),
    [disconnect, integrationName, t],
  )

  return (
    <>
      <IntegrationCard
        integrationName={integrationName}
        status={status}
        isLoading={isLoading}
        onConnect={onConnect}
        onDisconnect={onDisconnect}
      />
      <SimpleDialog
        title={t(
          'settings.integrations.setup_modal_title',
          'Set up integration',
        )}
        {...setupDialog.simpleDialogProps}
      />
    </>
  )
}
