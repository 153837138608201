import React from 'react'

import { useTranslation } from 'react-i18next'

import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import Checkbox from 'src/shared/components/form/inputs/Checkbox'
import { currencyFormatter } from 'src/shared/lib/string/services/text'

interface PaymentConfirmationProps {
  reservation: ReservationInterface
  onChargeChange: (charge: boolean) => void
  charge: boolean
}

const PaymentConfirmation = ({
  reservation,
  charge,
  onChargeChange,
}: PaymentConfirmationProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const { payment } = reservation

  const formatCurrency = React.useMemo(
    () => currencyFormatter(language, payment?.currency),
    [payment, language],
  )

  if (!payment) return null

  return (
    <Checkbox
      label={t('message_payment_fee', {
        amount: formatCurrency.format(
          payment.amountPerPerson * reservation.seatCount,
        ),
        defaultValue: 'Charge {{ amount }}',
      })}
      checked={charge}
      onChange={e => onChargeChange(e.target.checked)}
    />
  )
}

export { PaymentConfirmation }
