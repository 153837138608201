import type { ButtonProps } from '@mui/material'

import IconButton from 'src/shared/components/buttons/IconButton'
import PencilIcon from 'src/shared/components/icons/PencilIcon'

const EditNoteIconButton = (props: ButtonProps) => (
  <IconButton
    size="small"
    sx={{ p: 0, height: 24, width: 24, minWidth: 24 }}
    color="neutral"
    {...props}
  >
    <PencilIcon size="small" variant="filled" />
  </IconButton>
)

export default EditNoteIconButton
