import React from 'react'

import withReducerServiceProvider from './withReducerServiceProvider'

const createReducerServiceContext = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Service extends { [K in keyof Service]: (...args: any[]) => State },
  State,
>(
  initialState: State,
  reducer: (state: State) => Service,
) => {
  const StateContext = React.createContext(initialState)
  const ActionContext = React.createContext(reducer(initialState))

  const useStateContext = () => React.useContext(StateContext)
  const useActionContext = () => React.useContext(ActionContext)

  return {
    useState: useStateContext,
    useService: useActionContext,
    withProvider: withReducerServiceProvider(
      ActionContext,
      StateContext,
      reducer,
      initialState,
    ),
  }
}

export default createReducerServiceContext
