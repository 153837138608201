import { styled } from '@mui/material'

import ChevronIcon from './chevronDown.svg'
import { withIconContainer } from '../IconContainer'

export default ChevronIcon

export const Chevron = styled(withIconContainer(ChevronIcon))(({ theme }) => ({
  transition: theme.transitions.create('transform'),
}))

export const ChevronRightIcon = styled(Chevron)({
  transform: 'rotate(-90deg)',
})

export const ChevronLeftIcon = styled(Chevron)({
  transform: 'rotate(90deg)',
})
