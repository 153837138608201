import React from 'react'

import {
  availabilitiesSlotStatuses,
  type SlotStatus,
} from 'src/entities/availability/types/availability'
import { useUpdateReservationMutation } from 'src/entities/reservation/queries/reservationsMutations'
import {
  haveSameSeatCount,
  haveSameStart,
} from 'src/entities/reservation/services/reservation'
import { useSettingsObjectQuery } from 'src/entities/setting/queries/settings'
import {
  isAbandoned,
  resumable,
  useResumable,
  type Step,
} from 'src/shared/lib/common/services/resumable/resumable'
import {
  isAssignmentIncomplete,
  needsTemplateChoice,
  statusIsAvailable,
  type CreationDeps,
  type ReservationCreationOptions,
  type StepChecker,
  type UseReservationCreation,
} from './useReservationCreation'

interface UseReservationUpdate extends UseReservationCreation {
  onTreatAsSerie: () => void
}

interface ReservationUpdateOptions extends ReservationCreationOptions {
  wholeSerie?: boolean
}

type UpdateDeps = CreationDeps

type UpdateStep = Step<ReservationUpdateOptions, UpdateDeps>

const isTreatedAsSerial: UpdateStep[0] = (o, d) =>
  o.wholeSerie === undefined && d.reservation.serial

const isAvailabilityBased = (status: SlotStatus) =>
  availabilitiesSlotStatuses.includes(status)

export const slotNeedsConfirmation: StepChecker = (o, d) => {
  const arrivalTimeIsChanged = !haveSameStart(
    d.reservation,
    d.originalReservation,
  )
  const seatCountIsChanged = !haveSameSeatCount(
    d.reservation,
    d.originalReservation,
  )
  const seatCountNeedsConfirmation =
    seatCountIsChanged && isAvailabilityBased(d.slotStatus)

  return (
    !o.slotConfirmed &&
    !statusIsAvailable(d.slotStatus) &&
    (arrivalTimeIsChanged || seatCountNeedsConfirmation)
  )
}

export const useReservationUpdate = ({
  onSlotStatusConfirmation,
  onTemplateChoice,
  onIncompleteTableAssignment,
  showTemplateChoice,
  onTreatAsSerie,
  defaultSmsTemplate,
  defaultEmailTemplate,
}: UseReservationUpdate) => {
  const { mutateAsync: updateReservation } = useUpdateReservationMutation()

  const { data: settings } = useSettingsObjectQuery()

  const steps: UpdateStep[] = React.useMemo(
    () => [
      [
        slotNeedsConfirmation,
        (_o, d) => onSlotStatusConfirmation(d.slotStatus),
      ],
      [
        isAssignmentIncomplete,
        (_o, d) => onIncompleteTableAssignment(d.seatCountOverflow),
      ],
      [isTreatedAsSerial, onTreatAsSerie],
      [needsTemplateChoice(showTemplateChoice), onTemplateChoice],
    ],
    [
      onTreatAsSerie,
      onIncompleteTableAssignment,
      onSlotStatusConfirmation,
      onTemplateChoice,
      showTemplateChoice,
    ],
  )

  const { init, resume, abandon } = useResumable(
    React.useMemo(
      () =>
        resumable<ReservationUpdateOptions, UpdateDeps>(steps, {
          ...(!showTemplateChoice && {
            smsTemplate: defaultSmsTemplate,
            emailTemplate: defaultEmailTemplate,
          }),
        }),
      [defaultEmailTemplate, defaultSmsTemplate, showTemplateChoice, steps],
    ),
  )

  const initHandler = React.useCallback(
    async (deps: UpdateDeps) => {
      const options = await init(deps)

      if (isAbandoned(options)) return Promise.resolve(undefined)

      return updateReservation({
        reservation: deps.reservation,
        emailTemplate: options.emailTemplate,
        smsTemplate: options.smsTemplate,
        notifyRestaurant: settings.mutationGastroEmail,
        treatAsSerie: options.wholeSerie,
      })
    },
    [init, updateReservation, settings.mutationGastroEmail],
  )

  return { init: initHandler, resume, abandon }
}
