import { Box, Button, Divider, dividerClasses, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

interface DropdownFooterProps {
  onClear?: () => void
  onDone?: () => void
}

const DropdownFooter = ({ onClear, onDone }: DropdownFooterProps) => {
  const { t } = useTranslation()

  const label = t('filters.clear_single', {
    tDescription: 'Clear single filter text button label',
    defaultValue: 'Clear',
  })

  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'background.paper',
        pb: 0.5,
        zIndex: 10,
      }}
    >
      <Divider sx={{ [`&.${dividerClasses.root}`]: { my: 0, mb: 0.5 } }} />
      <Stack direction="row" justifyContent="space-between">
        {onClear && (
          <Button
            onClick={onClear}
            variant="text"
            color="primary"
            size="medium"
          >
            {label}
          </Button>
        )}
        {onDone && (
          <Button onClick={onDone} variant="text" color="primary" size="medium">
            {t('common.form_buttons.saveButtonLabel')}
          </Button>
        )}
      </Stack>
    </Box>
  )
}

export default DropdownFooter
