import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type LabelInterface } from 'src/entities/label/types/label'
import OutlinedContainer from 'src/shared/components/containers/OutlinedContainer'
import ColorPicker from 'src/shared/components/form/inputs/ColorPicker'
import { Colors } from 'src/shared/lib/style/types/color'

const Color = ({ labels }: { labels: LabelInterface[] }) => {
  const { t } = useTranslation('', { keyPrefix: 'settings.labels' })

  const {
    control,
    formState: { errors },
  } = useFormContext<LabelInterface>()

  const colors = [
    ...Object.values(Colors),
    ...labels.map(member => member.color),
  ]

  return (
    <OutlinedContainer label={t('colorLabel')} error={errors.color?.message}>
      <Controller
        name="color"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <ColorPicker
            value={field.value}
            onChange={field.onChange}
            colors={colors}
          />
        )}
      />
    </OutlinedContainer>
  )
}

export default Color
