import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute(
  '/restaurants/$restaurantId/reservations/table-plan',
)({
  component: RouteComponent,
})

function RouteComponent() {
  return 'Hello /restaurants/$restaurantId/reservations/table-plan!'
}
