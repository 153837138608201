import { equals } from 'ramda'

import { useTranslation } from 'react-i18next'

import { type NotificationTemplate } from 'src/entities/schedule/types/scheduleApi'
import { type BaseForm } from 'src/pages/ShiftConfiguration/ShiftForm/services/formSchema'
import SingleSelectDropdown from 'src/shared/components/form/inputs/SingleSelectDropdown'

type Notification = BaseForm['notifications']['templates'][number]

interface NotificationSelectProps {
  notifications: Notification[]
  onNotificationChange: (notification: Notification | null) => void
  templates: NotificationTemplate[]
  channel: Notification['channel']
}

const isOfChannel =
  (channel: Notification['channel']) => (notification: Notification) =>
    notification.channel === channel

const noneTemplate = { templateId: null }

const NotificationSelect = ({
  notifications,
  onNotificationChange,
  templates,
  channel,
}: NotificationSelectProps) => {
  const { t } = useTranslation()

  const channelNotification = notifications.find(isOfChannel(channel))

  const channelTemplates = templates.filter(isOfChannel(channel))

  const notificationTemplate =
    channelTemplates.find(ct =>
      equals(ct.templateId, channelNotification?.templateId),
    ) ?? noneTemplate

  return (
    <SingleSelectDropdown
      disabled={!channelTemplates.length}
      options={[...channelTemplates, noneTemplate]}
      value={notificationTemplate}
      onSelect={v => onNotificationChange(v.templateId ? v : null)}
      renderOption={v => {
        if (!v.templateId)
          return t(
            'schedule.shifts.notifications_section.notification.none',
            'None',
          )

        if (v.templateId.type === 'default')
          return t(
            'schedule.shifts.notifications_section.notification.default',
            'Default',
          )

        return v.name ?? ''
      }}
    />
  )
}

export default NotificationSelect
