import React from 'react'

import type Konva from 'konva'

export const useKonvaTransformerEffect = (isSelected: boolean) => {
  const transformerRef = React.useRef<Konva.Transformer>(null)
  const elementRef = React.useRef<Konva.Group>(null)

  React.useEffect(() => {
    if (!isSelected) return

    if (!elementRef.current) return

    transformerRef.current?.nodes([elementRef.current])
    transformerRef.current?.getLayer()?.batchDraw()
  }, [isSelected])

  return {
    transformerRef,
    elementRef,
  }
}
