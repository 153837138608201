import React from 'react'

import {
  useSuspenseQueries,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from '@tanstack/react-query'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { useRestaurantCacheKeyFactory } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import { useChainRestaurantHashes } from '../../restaurant/queries/restaurants'
import { getInfo } from '../api/info'

export const INFO_CACHE_KEY = ['info']

const useInfoCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(INFO_CACHE_KEY)

const useCreateQueryOptionsFactory = () => {
  const apiClient = useApiClient()

  const cacheKeyFactory = useInfoCacheKeyFactory()

  return React.useCallback(
    (restaurantHash?: string) =>
      ({
        queryKey: cacheKeyFactory([], restaurantHash),
        queryFn: () => getInfo(apiClient.post)(restaurantHash),
        staleTime: 5 * 60 * 1000,
        refetchInterval: 5 * 60 * 1000,
      }) satisfies UseSuspenseQueryOptions,
    [apiClient.post, cacheKeyFactory],
  )
}

export const useInfoQuery = (restaurantHash?: string) => {
  const optionsFactory = useCreateQueryOptionsFactory()

  return useSuspenseQuery(optionsFactory(restaurantHash))
}

export const useCommunicationTemplatesQuery = (
  communicationChannel: 'sms' | 'email',
  restaurantHash?: string,
) => {
  const optionsFactory = useCreateQueryOptionsFactory()

  return useSuspenseQuery({
    ...optionsFactory(restaurantHash),
    select: config =>
      communicationChannel === 'sms'
        ? config.smsCommunicationTemplates
        : config.emailCommunicationTemplates,
  })
}

export const useChainInfoQuery = () => {
  const restaurantHashes = useChainRestaurantHashes()
  const optionsFactory = useCreateQueryOptionsFactory()

  return useSuspenseQueries({
    queries: restaurantHashes.map(optionsFactory),
    combine: results => results.flatMap(query => query.data),
  })
}
