import type { ComponentProps } from 'react'

import { useTranslation } from 'react-i18next'

import FormSection from '../../../../FormSection'
import { sectionKeyToLabel } from '../../FormNavigation/FormNavigation'

const GeneralSectionContainer = ({
  children,
  ...props
}: Omit<ComponentProps<typeof FormSection>, 'title'>) => {
  const { t } = useTranslation()

  return (
    <FormSection {...props} title={sectionKeyToLabel(t)('general')}>
      {children}
    </FormSection>
  )
}

export default GeneralSectionContainer
