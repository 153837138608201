import { type ReactNode } from 'react'
import { RadioGroup, Stack } from '@mui/material'

import {
  type FieldError,
  type FieldErrorsImpl,
  type Merge,
  type UseFormReturn,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { type Schedule } from 'src/entities/schedule/types/scheduleApi'
import FormErrorText from 'src/shared/components/form/common/FormErrorText/FormErrorText'
import Checkbox from 'src/shared/components/form/inputs/Checkbox'
import { RadioOption } from 'src/shared/components/form/inputs/RadioChoice'
import { type FormValidityScope } from './service/schema'
import { ShiftSelectLabel } from './ShiftSelectLabel'

interface ScopeAware {
  scope: FormValidityScope
}

interface ValidityScopeInputProps<T extends ScopeAware> {
  hookFormProps: UseFormReturn<T>
  schedule: Schedule
  slots?: {
    all?: ReactNode
  }
}

type ScopeType = FormValidityScope['type']

type ScopeError<T extends string> = Merge<
  FieldError,
  FieldErrorsImpl<
    NonNullable<{
      [K in T]: unknown[]
    }>
  >
>

export const ValidityScopeInput = <T extends ScopeAware>({
  hookFormProps,
  schedule,
  slots,
}: ValidityScopeInputProps<T>) => {
  const { t } = useTranslation()

  const {
    register,
    watch,
    formState: { defaultValues, errors },
  } = hookFormProps as unknown as UseFormReturn<ScopeAware>

  const { scope } = defaultValues ?? {}

  const shiftIds =
    (scope?.type === 'shift' && (scope.shiftIds as string[])) || []
  const roomIds = (scope?.type === 'room' && (scope.roomIds as number[])) || []

  const roomIdsError = !!(errors.scope as ScopeError<'roomIds'>)?.roomIds
  const shiftIdsError = !!(errors.scope as ScopeError<'shiftIds'>)?.shiftIds

  const type = watch('scope.type')

  const hasNoShiftsOrEvents = !schedule.shifts.length && !schedule.events.length
  const hasNoRooms = !schedule.rooms.length

  const roomsEnabled = schedule.scheduleFeatures.rooms

  return (
    <RadioGroup
      sx={{ gap: 1.5 }}
      defaultValue={scope?.type}
      {...register('scope.type')}
    >
      <RadioOption
        value={'all' satisfies ScopeType}
        label={
          roomsEnabled
            ? t('schedule.validity_scope.all', 'All shifts & rooms')
            : t('schedule.validity_scope.all_shifts', 'All shifts')
        }
        {...register('scope.type')}
      />
      {slots?.all && (
        <Stack pl={4.5} gap={1} display={type !== 'all' ? 'none' : undefined}>
          {slots?.all}
        </Stack>
      )}
      <RadioOption
        value={'shift' satisfies ScopeType}
        label={t(
          'schedule.validity_scope.shifts_events',
          'Select shifts/events',
        )}
        sx={{ display: hasNoShiftsOrEvents ? 'none' : undefined }}
        {...register('scope.type')}
      />
      <Stack pl={4.5} gap={1} display={type !== 'shift' ? 'none' : undefined}>
        {schedule.shifts.map(s => (
          <Checkbox
            key={s.id}
            label={
              <ShiftSelectLabel
                shiftLike={s}
                rooms={schedule.rooms}
                size="small"
              />
            }
            value={s.id}
            defaultChecked={shiftIds.includes(s.id)}
            size="small"
            labelProps={{
              sx: { typography: 'labelExtraSmall' },
            }}
            {...register('scope.shiftIds')}
          />
        ))}
        {schedule.events.map(e => (
          <Checkbox
            key={e.id}
            label={
              <ShiftSelectLabel
                shiftLike={e}
                rooms={schedule.rooms}
                size="small"
              />
            }
            value={e.id}
            defaultChecked={shiftIds.includes(e.id)}
            size="small"
            labelProps={{
              sx: { typography: 'labelExtraSmall' },
            }}
            {...register('scope.shiftIds')}
          />
        ))}
        <FormErrorText
          errorText={
            shiftIdsError &&
            t(
              'schedule.common.errors.no_shift_event_selected',
              'Select at least one shift or event',
            )
          }
        />
      </Stack>
      <RadioOption
        value={'room' satisfies ScopeType}
        label={t('schedule.validity_scope.rooms', 'Selected rooms')}
        sx={{ display: hasNoRooms ? 'none' : undefined }}
        {...register('scope.type')}
      />
      <Stack pl={4.5} gap={1} display={type !== 'room' ? 'none' : undefined}>
        {schedule.rooms.map(({ id, name }) => (
          <Checkbox
            key={id}
            label={name}
            value={id}
            defaultChecked={roomIds.includes(id)}
            size="small"
            {...register('scope.roomIds')}
          />
        ))}
        <FormErrorText
          errorText={
            roomIdsError &&
            t(
              'schedule.common.errors.no_room_selected',
              'Select at least one room',
            )
          }
        />
      </Stack>
    </RadioGroup>
  )
}
