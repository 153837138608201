import { Badge, badgeClasses, styled } from '@mui/material'

export const SmallBadge = styled(Badge)(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    padding: '0 4px',
    height: '16px',
    minWidth: '16px',
    boxShadow: theme.shadows[2],
  },
}))
