import { buttonClasses, Stack } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { getCheckInStatus } from 'src/entities/reservation/services/checkInStatus'
import {
  ReservationCheckInStatusEnum,
  type ReservationInterface,
} from 'src/entities/reservation/types/reservation'
import SquareIconButton from 'src/shared/components/buttons/SquareIconButton'
import CheckedInIcon from 'src/shared/components/icons/CheckedInIcon'
import CheckedOutIcon from 'src/shared/components/icons/CheckedOutIcon'
import EyeSlashIcon from 'src/shared/components/icons/EyeSlashIcon'
import QuestionmarkIcon from 'src/shared/components/icons/QuestionmarkIcon'
import TableAssignedIcon from 'src/shared/components/icons/TableAssignedIcon'
import UndoIcon from 'src/shared/components/icons/UndoIcon'
import { withStoppedPropagation } from 'src/shared/lib/common/services/helpers/helpers'
import {
  getNowInRestaurantTimezone,
  isInFuture,
} from 'src/shared/lib/range/services/date'

const getCheckInIcon = (checkInStatus: ReservationCheckInStatusEnum) => {
  switch (checkInStatus) {
    case ReservationCheckInStatusEnum.New:
      return TableAssignedIcon
    case ReservationCheckInStatusEnum.Pending:
      return CheckedInIcon
    case ReservationCheckInStatusEnum.CheckedIn:
      return CheckedOutIcon
    case ReservationCheckInStatusEnum.NoShow:
      return EyeSlashIcon
    case ReservationCheckInStatusEnum.Cancelled:
    case ReservationCheckInStatusEnum.CheckedOut:
      return UndoIcon
    default:
      checkInStatus satisfies never
      return QuestionmarkIcon
  }
}

const CheckInStatusButton = ({
  reservation,
  onClick,
  isActive,
}: {
  reservation: ReservationInterface
  onClick: () => void
  isActive?: boolean
}) => {
  const checkInStatus = getCheckInStatus(reservation)
  const Icon = getCheckInIcon(checkInStatus)

  return (
    <Stack alignItems="flex-end">
      <SquareIconButton
        sx={{
          height: '100%',
          borderRadius: 2,
          [`&.${buttonClasses.disabled}`]: {
            pointerEvents: 'all',
            cursor: 'not-allowed',
          },
        }}
        onClick={withStoppedPropagation(onClick)}
        color={isActive ? 'lunchgateSuccess' : 'neutral'}
        variant="contained"
        disabled={
          checkInStatus === ReservationCheckInStatusEnum.Pending &&
          isInFuture('day')(
            getNowInRestaurantTimezone(),
            reservation.dateRange[0],
          )
        }
        {...createTestIdProps('check-in-status-button')}
      >
        <Icon />
      </SquareIconButton>
    </Stack>
  )
}

export default CheckInStatusButton
