/* eslint-disable react-hooks/rules-of-hooks */
import {
  __,
  all,
  compose,
  curry,
  curryN,
  filter,
  groupWith,
  includes,
  lens,
  lensProp,
  map,
  mergeLeft,
  over,
  prop,
  uniq,
  useWith,
  view,
} from 'ramda'

import type { ManipulateType } from 'dayjs'

import { replaceBy } from 'src/shared/lib/common/services/functional/functional'
import * as dateService from 'src/shared/lib/range/services/date'
import { overwriteFrom } from 'src/shared/lib/range/services/range'
import { moveToDate as moveTimeRange } from 'src/shared/lib/range/services/timeRange'
import { type TimeRangeAware } from 'src/shared/lib/range/types/timeRange'
import { guestFlagsFromCustomer } from './guestFactory'
import { type CustomerInterface } from '../../customer/types/customer'
import {
  formatRoomStaysRoomName,
  type RoomStayInterface,
} from '../../hotel-stay/services/hotelRoomStay'
import type { RestaurantInfo } from '../../info/types/info'
import { type LabelInterface } from '../../label/types/label'
import { getServiceTimeOfTime } from '../../service-time/services/serviceTime'
import {
  ReservationDisplayModesEnum,
  WALK_IN_NAME,
  type GuestInterface,
  type ReservationInterface,
} from '../types/reservation'

export const getDateRange = (reservation: ReservationInterface) =>
  reservation.dateRange

const startDateLens = lens<Pick<ReservationInterface, 'dateRange'>, Date>(
  r => r.dateRange[0],
  (date, r) => ({
    ...r,
    dateRange: overwriteFrom(date)(r.dateRange),
  }),
)

const seatCountLens = lens<Pick<ReservationInterface, 'seatCount'>, number>(
  r => r.seatCount,
  (seatCount, r) => ({ ...r, seatCount }),
)

export const getStartDate = view(startDateLens)
export const getNumber = (reservation: ReservationInterface) =>
  reservation?.number
export const getGuest = (reservation: ReservationInterface) =>
  reservation?.guest
export const getSeatCount = view(seatCountLens)
export const getCustomerId = compose(prop('customerId'), getGuest)

export const formatGuestName = (
  guest?: Pick<GuestInterface, 'lastName' | 'companyName' | 'firstName'>,
): string =>
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  [guest?.lastName, guest?.companyName || guest?.firstName]
    .filter(Boolean)
    .join(' ')

export const formatCustomerName = (customer: CustomerInterface) =>
  formatGuestName({
    firstName: customer?.firstName,
    lastName: customer?.lastName,
    companyName: customer?.companyName,
  } as GuestInterface)

const guestLabels = {
  [ReservationDisplayModesEnum.Name]: (guest: GuestInterface) =>
    guest.lastName || guest.companyName || guest.firstName,
  [ReservationDisplayModesEnum.FirstName]: (
    guest: GuestInterface,
    displayMode: ReservationDisplayModesEnum[],
  ) =>
    displayMode.includes(ReservationDisplayModesEnum.Name)
      ? guest.firstName || guest.companyName || guest.lastName
      : guest.firstName || guest.lastName || guest.companyName,
}

interface FormatTableReservationInfoParams {
  reservation: ReservationInterface
  displayMode: ReservationDisplayModesEnum[]
  roomStays: RoomStayInterface[]
}

interface TextSlotLabel {
  type: 'text'
  content: string
}

interface LabelsSlotLabel {
  type: 'labels'
  content: LabelInterface['id'][]
}

type SlotLabel = LabelsSlotLabel | TextSlotLabel

export const isTextLabel = (label: SlotLabel): label is TextSlotLabel =>
  label.type === 'text'
const areTextLabels = (labels: SlotLabel[]): labels is TextSlotLabel[] =>
  all(isTextLabel, labels)

export const isLabelsLabel = (label: SlotLabel): label is LabelsSlotLabel =>
  label.type === 'labels'

const isNotEmpty = (label: SlotLabel): boolean => {
  if (isTextLabel(label)) return !!label.content

  return !!label.content.length
}

const createTextLabel = (content: string): TextSlotLabel => ({
  type: 'text',
  content,
})

const reservationLabels: {
  [key in ReservationDisplayModesEnum]: (
    data: FormatTableReservationInfoParams,
  ) => SlotLabel
} = {
  [ReservationDisplayModesEnum.Time]: compose(
    createTextLabel,
    dateService.formatTime,
    getStartDate,
    d => d.reservation,
  ),
  [ReservationDisplayModesEnum.RoomNumber]: compose(
    createTextLabel,
    formatRoomStaysRoomName,
    d => d.roomStays,
  ),
  [ReservationDisplayModesEnum.Name]: compose(
    createTextLabel,
    guestLabels[ReservationDisplayModesEnum.Name],
    d => d.reservation.guest,
  ),
  [ReservationDisplayModesEnum.FirstName]: compose(createTextLabel, d =>
    guestLabels[ReservationDisplayModesEnum.FirstName](
      d.reservation.guest,
      d.displayMode,
    ),
  ),
  [ReservationDisplayModesEnum.Labels]: d => ({
    type: 'labels',
    content: d.reservation.labels,
  }),
}

const predefinedOrder = [
  ReservationDisplayModesEnum.Time,
  ReservationDisplayModesEnum.RoomNumber,
  ReservationDisplayModesEnum.Name,
  ReservationDisplayModesEnum.FirstName,
  ReservationDisplayModesEnum.Labels,
]

export const mergeLabels = (labels: SlotLabel[]): SlotLabel[] => {
  if (!areTextLabels(labels)) return labels

  return [
    labels.reduce(
      (merged, label) => ({
        type: 'text',
        content: `${merged.content} ${label.content}`,
      }),
      createTextLabel(''),
    ),
  ]
}

export const groupByType = groupWith(
  (a: SlotLabel, b: SlotLabel) => a.type === b.type,
)

export const formatTableReservationInfo = (
  labelData: FormatTableReservationInfoParams,
): SlotLabel[] => {
  const isInDisplayMode = includes(__, labelData.displayMode)
  const createSlotLabel = (m: ReservationDisplayModesEnum) =>
    reservationLabels[m](labelData)

  const labels = compose(
    uniq<SlotLabel>,
    filter(isNotEmpty),
    map(createSlotLabel),
    filter(isInDisplayMode),
  )(predefinedOrder)

  if (labels.length) return labels

  const defaultLabel = labelData.reservation.guest.customerId
    ? reservationLabels[ReservationDisplayModesEnum.Name](labelData)
    : createTextLabel(WALK_IN_NAME)

  return [defaultLabel]
}

export const moveToDate = (
  reservation: ReservationInterface,
  startDate: Date,
): ReservationInterface => ({
  ...reservation,
  dateRange: moveTimeRange(reservation.dateRange, startDate) as [Date, Date],
})

export const happensOnDay =
  (day: Date) =>
  (reservation: ReservationInterface): boolean => {
    const reservationStartDate = getStartDate(reservation)

    return dateService.areOnSameDay(day, reservationStartDate)
  }

export const appendSerialId =
  (serialId: number) =>
  (reservation: ReservationInterface): ReservationInterface => ({
    ...reservation,
    serial: true,
    serialId,
  })

export const replaceByStartDate = curryN(
  3,
  replaceBy<ReservationInterface, string>,
)(compose(dateService.formatDate, getStartDate))

export const getIsoDate = (reservation: ReservationInterface) =>
  dateService.getUtcDayStart(getStartDate(reservation)).toISOString()

export const getReservationShift = (shifts: TimeRangeAware[]) =>
  compose(getServiceTimeOfTime(shifts), getStartDate)

export const isInFuture = (
  reservation: ReservationInterface,
  unit: ManipulateType = 'millisecond',
  offset = 0,
  referenceDate: Date | undefined = undefined,
): boolean =>
  compose<[ReservationInterface], Date, boolean>(
    curry(dateService.isInFuture(unit))(
      dateService.addToDate(
        offset,
        unit,
        referenceDate ?? dateService.getNowInRestaurantTimezone(),
      ),
    ),
    getStartDate,
  )(reservation)

const guestLens = lensProp<ReservationInterface, 'guest'>('guest')

export const appendCustomer: (
  customer: CustomerInterface,
) => (reservation: ReservationInterface) => ReservationInterface = customer =>
  over(guestLens, guest => mergeLeft(guestFlagsFromCustomer(customer), guest))

export const hasEmail = (reservation: ReservationInterface): boolean =>
  !!getGuest(reservation)?.email

export const hasPhoneNumber = (reservation: ReservationInterface): boolean =>
  !!getGuest(reservation)?.phoneNumber

export const reservationExists = (
  r: ReservationInterface,
): r is ReservationInterface => !!r.id

const withOptionalReservation =
  <T, U extends Partial<ReservationInterface>>(fn: (r1: U, r2: U) => T) =>
  (r1: U, r2?: U) => {
    if (!r2) return false

    return fn(r1, r2)
  }

export const haveSameStart = withOptionalReservation(
  useWith(dateService.areOnSame('minute'), [getStartDate, getStartDate]),
)

export const haveSameSeatCount = withOptionalReservation(
  useWith((x, y) => x === y, [getSeatCount, getSeatCount]),
)

export type IsReminderPossible = (reservation: ReservationInterface) => boolean

export const isReminderPossible =
  (
    getNow: () => Date,
    getReminderConfig: () => RestaurantInfo['reminderData'],
  ): IsReminderPossible =>
  reservation => {
    const reminderConfig = getReminderConfig()

    return (
      isInFuture(reservation, 'day', -reminderConfig.days, getNow()) &&
      reservation.seatCount >= reminderConfig.pax
    )
  }
