import React, { useDeferredValue, useState } from 'react'
import { curry, find, identity, update } from 'ramda'

import {
  happensOnDay,
  moveToDate,
  replaceByStartDate,
} from 'src/entities/reservation/services/reservation'
import {
  MonthlyPatternEnum,
  ReservationGroupPatternUnitEnum,
  type ReservationGroup,
} from 'src/entities/reservation/services/reservationGroupFactory'
import { generateSerialReservations } from 'src/entities/reservation/services/serialReservations'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { toggleBy } from 'src/shared/lib/common/services/functional/functional'
import { type IsoDateString } from 'src/shared/lib/range/services/date'

export const useTableReservations = (
  reservationTemplate: ReservationInterface,
  serialDates: IsoDateString[],
  serialTemplate: ReservationGroup,
) => {
  const [deselectedDates, setDeselectedDates] = React.useState<IsoDateString[]>(
    [],
  )

  const [modifiedReservations, setModifiedReservations] = useState<
    ReservationInterface[]
  >([])
  const [reservations, setReservations] = React.useState<
    ReservationInterface[]
  >([])

  React.useEffect(() => {
    setReservations(() =>
      generateSerialReservations(
        reservationTemplate,
        serialDates,
        modifiedReservations,
      ),
    )
  }, [serialDates, reservationTemplate, modifiedReservations])

  React.useEffect(() => {
    setDeselectedDates(() => {
      if (serialTemplate.pattern.unit !== ReservationGroupPatternUnitEnum.Month)
        return []
      if (serialTemplate.pattern.monthday !== MonthlyPatternEnum.DayOfMonth)
        return []

      const serialTemplateDayOfMonth = serialTemplate.startDate.getDate()

      return serialDates.filter(
        date => new Date(date).getDate() !== serialTemplateDayOfMonth,
      )
    })
  }, [serialTemplate, serialDates])

  const saveReservationChanges = React.useCallback(
    (
      reservationIndex: number,
      reservationChanges: Partial<ReservationInterface>,
    ) =>
      setModifiedReservations(oldReservations => {
        const reservationDate = serialDates[reservationIndex]

        if (!reservationDate) return oldReservations

        const oldReservation = find(
          happensOnDay(new Date(reservationDate)),
          oldReservations,
        )

        const newReservation: ReservationInterface = {
          ...moveToDate(reservationTemplate, new Date(reservationDate)),
          ...oldReservation,
          ...reservationChanges,
        }

        const newModifiedReservations = replaceByStartDate(
          newReservation,
          oldReservations,
        )

        setReservations(update(reservationIndex, newReservation))

        return newModifiedReservations
      }),
    [reservationTemplate, serialDates],
  )

  const toggleDateSelection = React.useCallback(
    (date: IsoDateString) =>
      setDeselectedDates(curry(toggleBy)(identity, date)),
    [],
  )

  return {
    updateReservation: useDeferredValue(saveReservationChanges),
    reservations: useDeferredValue(reservations),
    toggleDateSelection,
    deselectedDates: useDeferredValue(deselectedDates),
    modifiedReservations: useDeferredValue(modifiedReservations),
  }
}
