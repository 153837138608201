import React from 'react'

import {
  useServiceHandler,
  type ActionPayload,
} from '../../state/hooks/useServiceHandler'

type ReducerServiceFactory = <
  Service extends { [K in keyof Service]: (...args: unknown[]) => State },
  State,
>(
  serviceFactory: (state: State) => Service,
) => React.Reducer<State, ActionPayload<keyof Service>>

const createReducerService: ReducerServiceFactory =
  reducer =>
  (state, { name, args }) =>
    reducer(state)[name](...args)

const useReducerService = <
  Service extends { [K in keyof Service]: (...args: unknown[]) => State },
  State,
>(
  reducer: (state: State) => Service,
  initialState: State,
) => {
  const reducerService = createReducerService<Service, State>(reducer)
  const [state, dispatch] = React.useReducer(reducerService, initialState)

  const actions = useServiceHandler<Service, State>(dispatch)

  return { state, actions }
}

export default useReducerService
