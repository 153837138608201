import React from 'react'

import { mergeSx } from 'src/app/theme/helpers'
import Typography from '../../../common/Typography'
import FormTextInput, { type FormTextInputProps } from '../FormTextInput'

type TextInputWithUnitProps = {
  unit: string
} & FormTextInputProps

const TextInputWithUnit = React.forwardRef<
  HTMLDivElement,
  TextInputWithUnitProps
>(({ unit, sx, ...props }, ref) => (
  <FormTextInput
    ref={ref}
    {...props}
    slotProps={{
      input: {
        ...props.slotProps?.input,
        endAdornment: (
          <Typography
            disabled={props.disabled}
            variant="body2"
            mr={1.5}
            sx={{
              pointerEvents: 'none',
            }}
          >
            {unit}
          </Typography>
        ),
      },
    }}
    sx={mergeSx(
      {
        input: {
          '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            appearance: 'none',
            margin: 0,
          },
          MozAppearance: 'textfield',
        },
      },
      sx,
    )}
  />
))

export default TextInputWithUnit
