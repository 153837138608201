import React from 'react'
import { compose, groupWith, map, not } from 'ramda'
import { Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import {
  type AvailabilityInterface,
  type SlotInterface,
} from 'src/entities/availability/types/availability'
import { overlapsTime } from 'src/entities/service-time/services/serviceTime'
import { haveTheSameShift } from 'src/entities/service-time/services/slot'
import { type Shift } from 'src/entities/shift-instance/types/shift'
import { DisabledOverlay } from 'src/shared/components/common/DisabledOverlay/DisabledOverlay'
import Toggle from 'src/shared/components/form/inputs/Toggle'
import { toTimestamp } from 'src/shared/lib/range/services/date'
import TimeRangeSlots from './TimeRangeSlots'
import { formatSlotGroupLabel } from '../service/slot'
import { createSlot } from '../service/slotFactory'
import { generateIntervals } from '../service/time'

interface SlotChoicesProps {
  selectedSlotTime: Date
  onSlotChange: (slot: SlotInterface) => void
  shifts: Shift[]
  availabilities: AvailabilityInterface[]
  seatCount: number
  disabled: boolean
}

const SlotChoices = ({
  selectedSlotTime,
  onSlotChange,
  shifts,
  availabilities,
  seatCount,
  disabled,
}: SlotChoicesProps) => {
  const { t } = useTranslation('', { keyPrefix: 'reservation_drawer.slot' })

  const [showOutOfShifts, setShowOutOfShifts] = React.useState(false)
  const toggleOutOfShiftsVisibility = () => setShowOutOfShifts(not)

  const timeChoice = React.useMemo(() => {
    const dailyTimes = generateIntervals(showOutOfShifts, selectedSlotTime)

    if (showOutOfShifts) return dailyTimes

    return dailyTimes.filter(dt => overlapsTime(shifts, dt))
  }, [shifts, showOutOfShifts, selectedSlotTime])

  const slotGroups = React.useMemo(
    () =>
      compose(
        groupWith(haveTheSameShift(shifts)),
        map(createSlot(availabilities, seatCount)),
      )(timeChoice),
    [timeChoice, shifts, seatCount, availabilities],
  )

  return (
    <DisabledOverlay overflow="auto" disabled={disabled} flex="1" showSpinner>
      <Stack
        direction="column"
        spacing={1}
        sx={{
          alignItems: 'center',
          py: 1,
        }}
      >
        <Toggle
          onChange={toggleOutOfShiftsVisibility}
          checked={showOutOfShifts}
          color="primary"
          labelProps={{
            labelPlacement: 'end',
            disableTypography: true,
            sx: {
              typography: 'body3',
              alignSelf: 'start',
              p: 0.5,
            },
          }}
          label={t('showAllSlots')}
        />
        <Stack direction="column" spacing={2}>
          {slotGroups.map(slots => {
            const [firstSlot] = slots

            return (
              <TimeRangeSlots
                key={toTimestamp(firstSlot!.time)}
                slots={slots}
                selectedSlotTime={selectedSlotTime}
                onSlotSelect={onSlotChange}
                label={formatSlotGroupLabel(shifts, slots)}
              />
            )
          })}
        </Stack>
      </Stack>
    </DisabledOverlay>
  )
}

export default SlotChoices
