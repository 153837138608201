import { type ComponentProps } from 'react'
import { compose } from 'ramda'

import { useTranslation } from 'react-i18next'

import Modal from 'src/shared/components/common/Modal'
import { TemplateChoiceButtons } from './TemplateChoiceButtons'
import { type ReservationActions } from '../reservationActions'

interface TemplateChoiceModalProps
  extends Omit<ComponentProps<typeof Modal>, 'title' | 'actions'> {
  onSend: () => void
  action: ReservationActions
  onDismiss?: () => void
}

const TemplateChoiceModal = ({
  onClose,
  open,
  action,
  onSend,
  onDismiss,
  ...props
}: TemplateChoiceModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal
      open={open}
      onClose={compose(() => onDismiss?.(), onClose)}
      showCloseButton
      title={t('reservation.communication.templateChoice')}
      actions={
        <TemplateChoiceButtons
          onDismiss={onDismiss}
          onClose={onClose}
          action={action}
          onSend={onSend}
        />
      }
      {...props}
    />
  )
}

export { TemplateChoiceModal }
