import { Box, Stack, type StackProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { mergeSx } from 'src/app/theme/helpers'
import {
  formatGuestCount,
  type RoomStayInterface,
} from 'src/entities/hotel-stay/services/hotelRoomStay'
import HotelIcon from 'src/shared/components/icons/HotelIcon'
import ItemSection from './RoomStayItem/ItemSection'

interface RoomStayItemProps extends StackProps {
  roomStay: RoomStayInterface
}

const RoomStayItem = ({ roomStay, sx, ...props }: RoomStayItemProps) => {
  const { t } = useTranslation()

  return (
    <Stack
      direction="column"
      spacing={0.5}
      {...props}
      sx={mergeSx({ cursor: 'pointer' }, sx)}
    >
      <ItemSection
        title={t('reservation_edit.hotel_stay.status', {
          tDescription: 'Hotel stay - title of status',
          defaultValue: 'Status',
        })}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{ justifyContent: 'space-between' }}
        >
          <Box>{roomStay.hotelStay.status}</Box>
          <HotelIcon size="small" />
        </Stack>
      </ItemSection>
      <ItemSection
        title={t('reservation_edit.hotel_stay.room', {
          tDescription: 'Hotel stay - title of room',
          defaultValue: 'Room',
        })}
      >
        {roomStay.room.name}
      </ItemSection>
      <ItemSection
        title={t('customer.hotel_stay.body.guest_counts.title', {
          tDescription: 'Customer preview hotel stay guest counts title',
          defaultValue: 'Guests',
        })}
      >
        {roomStay.room.guestCounts.map(formatGuestCount(t)).join(', ')}
      </ItemSection>
      <ItemSection
        title={t('customer.hotel_stay.body.rate.title', {
          tDescription: 'Customer preview hotel stay rate title',
          defaultValue: 'Rate Category/Plan',
        })}
      >
        {roomStay.room.rateCategories.join(', ')}
      </ItemSection>
    </Stack>
  )
}

export default RoomStayItem
