import { Suspense, type ReactNode } from 'react'
import { Popover, Stack, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

import CloseButton from 'src/shared/components/buttons/CloseButton'
import CircularProgress from 'src/shared/components/common/CircularProgress'
import DateFromNow from 'src/shared/components/common/DateFromNow'
import CenteredBox from 'src/shared/components/containers/CenteredBox'
import { type usePopover } from 'src/shared/components/dialogs/hooks/usePopover'
import PhoneCallIcon from 'src/shared/components/icons/PhoneCallIcon'

interface PhoneCallPopoverWrapperProps {
  popoverProps: ReturnType<typeof usePopover>
  createdAt: Date
  children: ReactNode
}

export const PhoneCallPopoverWrapper = ({
  popoverProps,
  createdAt,
  children,
}: PhoneCallPopoverWrapperProps) => {
  const { t } = useTranslation()

  const { open, anchorEl, handleClose } = popoverProps

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      slotProps={{ paper: { sx: { maxHeight: '80vh' } } }}
    >
      <Stack gap={2} p={2} width="min(90vw, 448px)">
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" alignItems="flex-start" gap={1}>
            <PhoneCallIcon />
            <Stack>
              <Typography variant="labelRegular">
                {t('caller_id.incoming_call', 'Incoming call')}
              </Typography>
              <DateFromNow date={createdAt} variant="inherit" />
            </Stack>
          </Stack>
          <CloseButton onClick={handleClose} />
        </Stack>
        <Suspense
          fallback={
            <CenteredBox>
              <CircularProgress />
            </CenteredBox>
          }
        >
          {children}
        </Suspense>
      </Stack>
    </Popover>
  )
}
