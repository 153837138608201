import { useTranslation } from 'react-i18next'

import StepInput from 'src/shared/components/form/inputs/StepInput'
import { useBaseFormController } from '../../../../hooks/form'

const ShiftLimit = () => {
  const { t } = useTranslation()

  const { field } = useBaseFormController('shiftGuestCountLimit')

  return (
    <StepInput
      value={field.value}
      onChange={field.onChange}
      minValue={0}
      sx={{ maxWidth: 168 }}
      label={t(
        'schedule.shifts.limits_section.shift_limit.label',
        'Guests limit per shift',
      )}
    />
  )
}

export default ShiftLimit
