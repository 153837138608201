import { type ApiClient } from 'src/shared/lib/api/services/api'
import { type ApiFurniture } from '../../config/types/configApi'
import { furnitureToApiPayload } from '../../table/types/tableApi'

export const setFurniture =
  (httpClient: ApiClient) => (furniture: ApiFurniture) =>
    httpClient({
      url: 'addRoomElement',
      json: furnitureToApiPayload(furniture),
    })

export const deleteFurniture =
  (httpClient: ApiClient) => (furniture: ApiFurniture) =>
    httpClient({
      url: 'deleteRoomElement',
      json: furnitureToApiPayload(furniture),
    })
