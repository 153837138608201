import { type ButtonProps } from '@mui/material'

import IconButton from 'src/shared/components/buttons/IconButton'
import LockedPadlockIcon from 'src/shared/components/icons/LockedPadlockIcon'
import UnlockedPadlockIcon from 'src/shared/components/icons/UnlockedPadlockIcon'

interface LockButtonProps extends ButtonProps {
  locked: boolean
}

const LockButton = ({ locked, ...props }: LockButtonProps) => {
  const LockButtonIcon = locked ? LockedPadlockIcon : UnlockedPadlockIcon

  return (
    <IconButton
      color="error"
      variant="color-outlined"
      aria-checked={locked}
      {...props}
    >
      <LockButtonIcon variant="filled" />
    </IconButton>
  )
}

export default LockButton
