import React from 'react'

import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/shared/lib/api/hooks/useApiClient'
import { useRestaurantCacheKeyFactory } from 'src/shared/lib/api/queries/useRestaurantCacheKey'
import { getUtcDayStart } from 'src/shared/lib/range/services/date'
import { type RoomInterface } from '../../room/types/room'
import { getAvailabilities } from '../api/availabilityApi'

export const AVAILABILITY_CACHE_KEY = ['availabilities']
const useAvailabilityCacheKeyFactory = () =>
  useRestaurantCacheKeyFactory(AVAILABILITY_CACHE_KEY)

export const useAvailabilitiesQuery = (
  date: Date,
  roomId?: RoomInterface['id'],
) => {
  const apiClient = useApiClient()
  const cacheKeyFactory = useAvailabilityCacheKeyFactory()
  const dateStart = getUtcDayStart(date)

  return useSuspenseQuery({
    queryKey: cacheKeyFactory([dateStart, roomId]),
    queryFn: () => getAvailabilities(apiClient.post)(dateStart, roomId),
  })
}

export const useAvailabilitiesInvalidation = () => {
  const queryClient = useQueryClient()
  const cacheKeyFactory = useAvailabilityCacheKeyFactory()

  return React.useCallback(
    (date: Date, roomId?: RoomInterface['id'] | null) =>
      queryClient.invalidateQueries({
        queryKey: cacheKeyFactory([getUtcDayStart(date), roomId]),
      }),
    [cacheKeyFactory, queryClient],
  )
}
