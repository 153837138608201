import { Stack } from '@mui/material'

import ShiftLimit from './ShiftLimit/ShiftLimit'
import GuestFlowSection from '../../GuestsFlowSection/GuestFlowSection'

export const ScheduleLimits = () => (
  <Stack gap={3}>
    <ShiftLimit />
    <GuestFlowSection />
  </Stack>
)
