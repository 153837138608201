import { Typography, type ButtonProps } from '@mui/material'

import { useTranslation } from 'react-i18next'

import useFeature from 'src/entities/info/hooks/useFeature'
import { type TeamMember } from 'src/entities/team-member/types/teamMember'
import { TeamMemberCircle } from './TeamMemberCircle'
import { TeamMemberPinInput } from './TeamMemberPinInput'

interface TeamMemberButtonProps
  extends Omit<ButtonProps, 'color' | 'children'> {
  teamMember: TeamMember
  showPin?: boolean
  small?: boolean
}

const TeamMemberButton = ({
  teamMember,
  small,
  showPin,
  ...props
}: TeamMemberButtonProps) => {
  const { t } = useTranslation()

  const { firstName, lastName, pinRequired, role } = teamMember
  const pinFeatureEnabled = useFeature('pinEnabled')

  if (showPin) return <TeamMemberPinInput teamMember={teamMember} />

  return (
    <TeamMemberCircle
      teamMember={teamMember}
      showPadlock={pinRequired && pinFeatureEnabled}
      {...props}
    >
      <Typography
        variant="labelSmall"
        sx={{
          mt: 1,
          textAlign: 'center',
          overflow: 'hidden',
          cursor: 'pointer',
          width: 1,
          lineHeight: 1,
          flexShrink: 0,
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
        }}
      >
        {firstName} {lastName}
      </Typography>
      {role !== 'team_member' && (
        <Typography
          variant="labelTiny"
          color="grey.500"
          textTransform="capitalize"
        >
          {t(`permissions.roles.${role}`, role)}
        </Typography>
      )}
    </TeamMemberCircle>
  )
}

export default TeamMemberButton
