import { andThen, composeWith, prop } from 'ramda'

import { type ApiClient } from 'src/shared/lib/api/services/api'

export const getUnreadCount = (httpClient: ApiClient) => () =>
  composeWith(andThen)([
    prop('count')<{ count: number }>,
    prop('data'),
    httpClient,
  ])({
    method: 'POST',
    url: 'inboxnotifications/count',
  })

export const read = (httpClient: ApiClient) => (id: string) =>
  httpClient({
    method: 'POST',
    url: 'inboxnotifications/read',
    json: {
      last_displayed_item_id: id,
    },
  })

export const markDone =
  (httpClient: ApiClient) =>
  (id: string, includeHistory = false) =>
    httpClient({
      method: 'POST',
      url: 'inboxnotifications/done',
      json: {
        item_id: id,
        include_history: includeHistory,
      },
    })

export const markOpen = (httpClient: ApiClient) => (id: string) =>
  httpClient({
    method: 'POST',
    url: 'inboxnotifications/open',
    json: {
      item_id: id,
    },
  })
