import { useEffect, useMemo, useRef } from 'react'
import { Box, Popover, type TextFieldProps } from '@mui/material'

import dayjs from 'dayjs'

import { getColumnNumber } from 'src/pages/ReservationDrawer/TimeSelection/SlotPicker/SlotChoices/TimeRangeSlots/TimeslotChoiceItem'
import {
  formatTime,
  getDifference,
  getNowInRestaurantTimezone,
} from '../../../../lib/range/services/date'
import { interpolate } from '../../../../lib/range/services/timeRange'
import { fromDay } from '../../../../lib/range/services/timeRangeFactory'
import { usePopover } from '../../../dialogs/hooks/usePopover'
import ClockIcon from '../../../icons/ClockIcon'
import RangePickerItem from '../RangePickerItem'
import SelectInput from '../SelectInput'

interface MinutesPickerProps
  extends Omit<TextFieldProps, 'onChange' | 'value'> {
  value: Date | null
  onChange: (newValue: Date) => void
  minValue?: Date
  maxValue?: Date
}

const [defaultMinValue, defaultMaxValue] = fromDay(getNowInRestaurantTimezone())

const MinutesPicker = ({
  value,
  onChange,
  minValue = defaultMinValue,
  maxValue = defaultMaxValue,
  ...props
}: MinutesPickerProps) => {
  const { open, setAnchorEl, handleClose, anchorEl } = usePopover()

  const timeOptions = useMemo(
    () => interpolate([minValue, maxValue], 'minutes', 15, true),
    [maxValue, minValue],
  )

  useEffect(() => {
    if (value && minValue && value < minValue) onChange(minValue)
  }, [minValue, onChange, value])

  const inputRef = useRef<HTMLDivElement>(null)
  return (
    <>
      <SelectInput
        value={value ? formatTime(value) : ''}
        placeholder="hh:mm"
        onClick={event => setAnchorEl(event.currentTarget)}
        IconComponent={ClockIcon}
        iconProps={{ variant: 'filled' }}
        inputRef={inputRef}
        {...props}
      />
      <Popover open={open} anchorEl={anchorEl} onClose={handleClose}>
        <Box
          sx={{
            display: 'grid',
            gridAutoColumns: 'min-content',
            gridTemplateColumns: 'repeat(4, 1fr)',
          }}
        >
          {timeOptions.map(time => (
            <RangePickerItem
              sx={{
                width: 64,
                height: 46,
                gridColumn: getColumnNumber(time),
              }}
              key={time.getTime()}
              selected={!!value && getDifference('minutes')(time, value) === 0}
              day={dayjs(time)}
              isFirstVisibleCell={false}
              isLastVisibleCell={false}
              outsideCurrentMonth={false}
              onDaySelect={d => {
                onChange(d.toDate())
                handleClose()
              }}
            >
              {formatTime(time)}
            </RangePickerItem>
          ))}
        </Box>
      </Popover>
    </>
  )
}

export default MinutesPicker
