import React from 'react'

import { type HeaderGroup, type Row } from '@tanstack/react-table'
import { useVirtualizer } from '@tanstack/react-virtual'

import Table from 'src/pages/SerialReservations/ReservationsTable/Table'
import TableRow from 'src/pages/SerialReservations/ReservationsTable/TableRow'
import VirtualTableContainer from 'src/shared/components/common/VirtualTable/VirtualTableContainer'

const VirtualTable = <T,>({
  rows,
  headerGroups,
  estimatedSize = 65,
  onLastItemIndexChange,
}: {
  rows: Row<T>[]
  headerGroups: HeaderGroup<T>[]
  estimatedSize?: number
  onLastItemIndexChange?: (lastIndex: number) => void
}) => {
  const tableContainerRef = React.useRef<HTMLDivElement>(null)

  const { getVirtualItems, getTotalSize } = useVirtualizer<
    HTMLDivElement,
    HTMLTableRowElement
  >({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => estimatedSize,
    overscan: 10,
  })

  const virtualItems = getVirtualItems()
  const totalSize = getTotalSize()
  const lastItemIndex = virtualItems.at(-1)?.index ?? 0

  React.useEffect(() => {
    onLastItemIndexChange?.(lastItemIndex)
  }, [onLastItemIndexChange, lastItemIndex])

  return (
    <VirtualTableContainer ref={tableContainerRef}>
      <Table totalSize={totalSize} items={virtualItems} headers={headerGroups}>
        {virtualItems.map(({ index, key }) => (
          <TableRow row={rows[index]!} key={key} />
        ))}
      </Table>
    </VirtualTableContainer>
  )
}

export default VirtualTable
