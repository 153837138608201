import { createFileRoute, redirect } from '@tanstack/react-router'

import { awaitedLogout } from 'src/entities/auth/api/authApi'
import { removeToken } from 'src/entities/auth/services/token'
import { apiClient } from 'src/shared/lib/api/services/reservationsBookApiClient'

export const Route = createFileRoute('/logout')({
  component: RouteComponent,
  beforeLoad: async ({ context: { queryClient } }) => {
    await awaitedLogout(apiClient)()
    removeToken()
    queryClient.clear()
    throw redirect({ to: '/login' })
  },
})

function RouteComponent() {
  return 'Hello /logout!'
}
