import { Stack, Typography } from '@mui/material'

import {
  getWeekdayName,
  weekdayNumbers,
} from 'src/shared/lib/range/services/date'

interface WeekdaysProps {
  activeWeekdays: number[]
  overlappingWeekdays: number[]
}

const Weekdays = ({ activeWeekdays, overlappingWeekdays }: WeekdaysProps) => (
  <Stack direction="row" spacing={0.5}>
    {weekdayNumbers.map(w => {
      const weekdayColor =
        (overlappingWeekdays.includes(w) && 'currentColor') ||
        (activeWeekdays.includes(w) && 'grey.700') ||
        'grey.300'

      return (
        <Typography variant="inherit" color={weekdayColor} key={w}>
          {getWeekdayName(w)[0]}
        </Typography>
      )
    })}
  </Stack>
)

export default Weekdays
