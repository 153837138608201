import { type ZodError } from 'zod'

import { captureException } from '../context/global/sentry'

export const fallbackZodErrorHandler =
  <T>(fallbackValue: T) =>
  (ctx: { error: ZodError }) => {
    try {
      captureException(ctx.error)
    } catch {
      /* empty */
    }

    return fallbackValue
  }

export const basicZodErrorHandler = <T>(ctx: { error: ZodError; input: T }) =>
  fallbackZodErrorHandler(ctx.input)(ctx)
