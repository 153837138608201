import React from 'react'
import { andThen, ascend, compose, composeWith, sortWith, uniq } from 'ramda'
import { Stack } from '@mui/material'

import { DndContext, type DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext } from '@dnd-kit/sortable'
import { useTranslation } from 'react-i18next'

import {
  useLabelDeleteMutation,
  useLabelsOrderUpdateMutation,
  useLabelsQuery,
  useLabelUpdateMutation,
} from 'src/entities/label/queries/label'
import { type LabelInterface } from 'src/entities/label/types/label'
import { emptyLabel } from 'src/entities/label/types/labelApi'
import CreateButton from 'src/shared/components/buttons/CreateButton'
import Modal from 'src/shared/components/common/Modal'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import { useBasicDndSensors } from 'src/shared/lib/common/hooks/useDnd'
import { priorityComparator } from 'src/shared/lib/common/services/priorityComparator'
import EditForm from './EditForm'
import GroupButton from './GroupButton/GroupButton'
import LabelsGrid from './LabelsGrid/LabelsGrid'

const Labels = () => {
  const { t } = useTranslation()

  const [editedLabel, setEditedLabel] = React.useState<LabelInterface>()

  const { handleClose, handleOpen, open } = useDialog()

  const { data: allLabels } = useLabelsQuery()
  const labels = React.useMemo(
    () => allLabels.filter(l => !l.systemLabel),
    [allLabels],
  )

  const { mutateAsync: updateLabel } = useLabelUpdateMutation()
  const { mutate: deleteLabel } = useLabelDeleteMutation()

  const handleSubmit = composeWith(andThen)([handleClose, updateLabel])
  const handleEdit = compose(handleOpen, setEditedLabel)
  const handleDelete = compose(handleClose, deleteLabel)

  const [orderedLabels, setOrderedLabels] = React.useState(labels)

  React.useEffect(() => {
    setOrderedLabels(oldLabels =>
      sortWith(
        [
          priorityComparator(
            oldLabels.map(l => l.id),
            l => l.id,
          ),
        ],
        labels,
      ),
    )
  }, [labels])
  const { mutate: updateLabelsOrder } = useLabelsOrderUpdateMutation()

  const orderedLabelsRef = React.useRef(orderedLabels)
  orderedLabelsRef.current = orderedLabels

  React.useEffect(
    () => () => {
      if (orderedLabelsRef.current === labels) return

      updateLabelsOrder(orderedLabelsRef.current)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const groupByColor = React.useCallback(
    () =>
      setOrderedLabels(oldLabels => {
        const colors = uniq(oldLabels.map(l => l.color))

        return sortWith([ascend(l => colors.indexOf(l.color))], oldLabels)
      }),
    [],
  )

  const handleDragEnd = React.useCallback((event: DragEndEvent) => {
    const { active, over } = event

    if (!over || active.id === over.id) return

    setOrderedLabels(oldLabels => {
      const oldIndex = oldLabels.findIndex(label => label.id === active.id)
      const newIndex = oldLabels.findIndex(label => label.id === over.id)

      return arrayMove(oldLabels, oldIndex, newIndex)
    })
  }, [])

  const sensors = useBasicDndSensors()

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        p: 2,
        alignItems: 'baseline',
      }}
    >
      <Stack direction="row" justifyContent="space-between" sx={{ width: 1 }}>
        <CreateButton onClick={() => handleEdit(emptyLabel)}>
          {t('settings.labels.addLabel')}
        </CreateButton>
        <GroupButton onClick={groupByColor} />
      </Stack>
      <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
        <SortableContext items={orderedLabels}>
          <LabelsGrid labels={orderedLabels} onLabelClick={handleEdit} />
        </SortableContext>
      </DndContext>
      <Modal
        open={open}
        onClose={handleClose}
        title={t('settings.labels.modalTitle')}
      >
        {editedLabel && (
          <EditForm
            label={editedLabel}
            labels={labels}
            onCancel={handleClose}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
          />
        )}
      </Modal>
    </Stack>
  )
}

export default Labels
