import React, { useEffect, useState, type ComponentProps } from 'react'
import { Box, Stack, Typography, useTheme } from '@mui/material'

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import type { BaseForm } from '../../../../services/formSchema'

type Pacing = BaseForm['customPacing']['pacings'][number]

ChartJS.register(CategoryScale, LinearScale, BarElement)

interface PacingChartProps {
  pacing: Pacing[]
  limit: number
  show: boolean
}

interface DataPoint {
  x: string
  y: number
}

const chart = (
  mainColor: string,
  tickColor: string,
  serieColor: string,
  data: DataPoint[],
  maxY: number,
): ComponentProps<typeof Bar> => {
  const firstPoint = data.at(0)
  const lastPoint = data.at(-1)

  if (!firstPoint || !lastPoint) return { data: { datasets: [] } }

  const slotsPerHours = data.find(
    p => p.x.endsWith(':15') || p.x.endsWith(':45'),
  )
    ? 4
    : 2

  const firstFullHourPoint = data.findIndex(p => p.x.endsWith(':00'))

  return {
    data: {
      datasets: [
        {
          backgroundColor: serieColor,
          borderColor: serieColor,
          borderWidth: 1,
          data,
        },
      ],
    },
    options: {
      animation: {
        duration: 0,
      },
      maintainAspectRatio: false,
      scales: {
        x: {
          type: 'category',
          ticks: {
            autoSkip: false,
            autoSkipPadding: 12,
            callback: (_v, index) => {
              const noTick = null

              const point = data[index]
              if (!point || point.x.startsWith('-')) return noTick

              const labeledTick = point.x

              const isEveryHour =
                (index - firstFullHourPoint) % slotsPerHours === 0
              if (isEveryHour) return labeledTick

              return noTick
            },
            padding: 0,
            color: tickColor,
            minRotation: 90,
            font: {
              size: 10,
              weight: 600,
              family: 'Roboto',
            },
          },
          border: {
            width: 0,
          },
          grid: {
            display: false,
          },
        },
        y: {
          min: 0,
          max: maxY,
          ticks: {
            padding: 12,
            color: tickColor,
            maxTicksLimit: 4,
            labelOffset: 1,
            font: {
              size: 10,
              weight: 600,
              family: 'Roboto',
            },
          },
          border: {
            width: 0,
          },
          grid: {
            color: mainColor,
            drawTicks: false,
          },
        },
      },
    },
  }
}

const PacingChart = ({ pacing, limit, show }: PacingChartProps) => {
  const { t } = useTranslation()

  const theme = useTheme()

  const mainColor = theme.palette.primaryPalette['100']!
  const tickColor = theme.palette.primaryPalette['400']!
  const seriesColor = theme.palette.primaryPalette['700']!

  const chartProps = React.useMemo(
    () =>
      chart(
        mainColor,
        tickColor,
        seriesColor,
        pacing.map(p => ({ x: p.time, y: p.allocation })),
        limit,
      ),
    [limit, mainColor, pacing, seriesColor, tickColor],
  )

  const [showChart, setShowChart] = useState(false)

  useEffect(() => {
    setShowChart(show)

    return () => setShowChart(false)
  }, [show])

  if (!show) return null

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="labelSmall" color="primaryPalette.400">
        {t(
          'schedule.shifts.pacing.chart_title',
          'Guests per time slot arrival flow',
        )}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          height: 166,
          width: 1,
          position: 'relative',
          paddingBottom: 4,
        }}
      >
        {showChart && (
          <Bar
            {...chartProps}
            style={{
              margin: '-12px',
              marginLeft: '-10px',
              marginRight: '-4px',
              marginBottom: '0px',
              border: 'none',
            }}
          />
        )}
      </Box>
    </Stack>
  )
}

export default PacingChart
