import { type BookingLock } from 'src/entities/schedule/types/scheduleApi'
import { type FormBookingLock } from './schema'

export const toBookingLockFormData = (lock: BookingLock) => {
  const { id, bookingChannels, ...rest } = lock

  return {
    ...rest,
    bookingChannels: bookingChannels.includes('offline')
      ? 'online-offline'
      : 'online',
  } satisfies FormBookingLock
}

export const fromBookingLockFormData = (lock: FormBookingLock) =>
  ({
    ...lock,
    bookingChannels:
      lock.bookingChannels === 'online' ? ['online'] : ['online', 'offline'],
  }) satisfies Omit<BookingLock, 'id'>
