import React from 'react'
import { compose } from 'ramda'
import { Dialog, Stack } from '@mui/material'

import { useTranslation } from 'react-i18next'

import CollapsibleButton from 'src/shared/components/buttons/CollapsibleButton'
import useDialog from 'src/shared/components/dialogs/hooks/useDialog'
import PencilIcon from 'src/shared/components/icons/PencilIcon'
import Note from 'src/widgets/Reservation/Note'
import NotesEditForm from './NotesEditForm'

const NotesCell = ({
  note,
  onChange,
}: {
  note: string
  onChange: (note: string) => void
}) => {
  const { t } = useTranslation('', { keyPrefix: 'serial_reservations' })

  const dialog = useDialog()

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {!!note && <Note>{note}</Note>}
        <CollapsibleButton
          onClick={dialog.handleOpen}
          label={t('addNote')}
          collapsed={!!note}
          startIcon={<PencilIcon size="small" />}
        />
      </Stack>
      <Dialog open={dialog.open} onClose={dialog.handleClose}>
        <NotesEditForm
          onSave={compose(dialog.handleClose, onChange)}
          onCancel={dialog.handleClose}
          initValue={note}
        />
      </Dialog>
    </>
  )
}

export default React.memo(
  NotesCell,
  (prevProps, nextProps) => prevProps.note === nextProps.note,
)
