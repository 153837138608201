import React from 'react'
import { map } from 'ramda'

import { useAreasQuery } from 'src/entities/area/queries/area'
import { type AreaInterface } from 'src/entities/area/types/area'
import { getArea } from 'src/entities/reservation/services/occupancy'
import { type ReservationInterface } from 'src/entities/reservation/types/reservation'
import { useTablesQuery } from 'src/entities/table/queries/table'

export const useAreaChangeEffect = (
  reservation: ReservationInterface | undefined,
  onAreaChange: (area: AreaInterface) => void,
) => {
  const { data: areas } = useAreasQuery()
  const { data: tables } = useTablesQuery()

  React.useEffect(() => {
    if (!reservation) return

    const reservationAreas = map(
      getArea(areas, tables),
      reservation.occupancies,
    ).filter(Boolean)
    const reservationArea = reservationAreas[0]

    if (!reservationArea) return

    onAreaChange(reservationArea)
  }, [reservation?.id, areas, tables, onAreaChange])
}
