import {
  useController,
  useFormContext,
  type FieldPath,
  type UseControllerProps,
} from 'react-hook-form'

import { type FormEvent } from '../services/eventFormSchema'
import { type FormException } from '../services/exceptionFormSchema'
import { type BaseForm } from '../services/formSchema'
import { type FormShift } from '../services/shiftFormSchema'

export const useBaseFormContext = useFormContext<BaseForm>
export const useEventShiftFormContext = useFormContext<FormShift | FormEvent>
export const useExceptionFormContext = useFormContext<FormException>

const createFormController =
  <U extends BaseForm>() =>
  <T extends FieldPath<U>>(
    path: T,
    opts: Partial<Omit<UseControllerProps<U, T>, 'name'>> = {},
  ) =>
    useController<U, T>({ ...opts, name: path })

export const useBaseFormController = createFormController<BaseForm>()
export const useShiftEventFormController = createFormController<
  FormShift | FormEvent
>()
export const useExceptionEventFormController = createFormController<
  FormEvent | FormException
>()
