import React from 'react'
import { debounce, InputAdornment, TextField } from '@mui/material'

import { createTestIdProps } from 'src/app/hoc/withDomId'
import { useImperativeHandleFocus } from '../../../../lib/common/hooks/useImperativeHandleFocus'
import { withTargetValue } from '../../../../lib/common/services/helpers/helpers'
import IconButton from '../../../buttons/IconButton'
import CloseIcon from '../../../icons/CloseIcon'
import SearchIcon from '../../../icons/SearchIcon'

interface SearchInputProps {
  onSearch: (searchPhrase: string) => void
  initialValue?: string
  placeholder: string
}

const MIN_LENGTH = 3

const SearchInput = React.forwardRef<HTMLDivElement, SearchInputProps>(
  ({ onSearch, initialValue, placeholder }, ref) => {
    const [error, setError] = React.useState(false)
    const [value, setValue] = React.useState<string>(initialValue ?? '')

    const changeHandler = (searchPhrase: string) => {
      const isLongEnough = searchPhrase.length >= MIN_LENGTH
      const isEmpty = !searchPhrase?.length
      setError(!isLongEnough && !isEmpty)
      onSearch(isLongEnough ? searchPhrase : '')
    }

    const inputRef = React.useRef<HTMLInputElement>(null)

    useImperativeHandleFocus(ref, inputRef)

    const debouncedChangeHandler = React.useRef(debounce(changeHandler, 500))
    React.useEffect(() => {
      debouncedChangeHandler.current(value)
    }, [value])

    return (
      <TextField
        {...createTestIdProps('search-phrase-input')}
        inputRef={inputRef}
        variant="outlined"
        size="large"
        autoFocus
        onChange={withTargetValue(setValue)}
        value={value}
        fullWidth
        placeholder={placeholder}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="end" disablePointerEvents>
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  size="small"
                  variant="text"
                  color="neutral"
                  onClick={() => setValue('')}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
        error={error}
      />
    )
  },
)

export default SearchInput
