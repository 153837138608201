import StepInput from 'src/shared/components/form/inputs/StepInput'
import {
  useBaseFormContext,
  useBaseFormController,
} from '../../../../../../hooks/form'

const MaxGuests = () => {
  const { field } = useBaseFormController('reservationGuestCountLimit.min')
  const { watch } = useBaseFormContext()

  return (
    <StepInput
      onChange={field.onChange}
      value={field.value}
      minValue={1}
      maxValue={watch('reservationGuestCountLimit.max')}
      step={1}
      sx={{ maxWidth: 168 }}
    />
  )
}

export default MaxGuests
